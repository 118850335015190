import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useSplashAd } from '@/hooks/queries/useLandingSettingsQuery'
import constants from '@/constants'
import useTrack from '@/hooks/useTrack'

/**
 *
 * @param {{
 * onClose: function
 * }} props
 * @returns
 */
export default function SplashAdImage (props) {
  const { onClose } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { sendEvent } = useTrack()
  const splashAd = useSplashAd()

  const handleClick = () => {
    sendEvent(constants.track.EVENT.VIEW_PROMOTION, {
      type: splashAd?.type,
      name: splashAd?.name,
      slot: 'cover',
    })

    switch (splashAd?.action) {
      case 'MERCHANT': {
        const merchantId = splashAd?.payload
        history.push('/restaurant/' + merchantId)
        break
      }

      case 'CATEGORY': {
        const categoryName = splashAd?.payload
        dispatch(actions.landing.redirectToCategory(categoryName))
        break
      }

      case 'CUISINE': {
        const cuisineName = splashAd?.payload
        dispatch(actions.landing.redirectToCuisine(cuisineName))
        break
      }

      case 'SEARCH': {
        const searchText = splashAd?.payload
        dispatch(actions.landing.updateSearchText(searchText))
        history.push('/search')
        break
      }

      default:
        break
    }
    onClose()
  }

  if (!splashAd?.splashUrl) return null
  return (
    <img
      src={splashAd?.splashUrl}
      alt='splash-ad-image'
      className={classes.image}
      onClick={handleClick}
    />
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
  },
}))
