import { BANNER, transformation } from '@/libs/cloudinary'
import { makeStyles } from '@material-ui/core/styles'
import { useMode } from '@/hooks/useWindowSize'
import React from 'react'

/**
 * @typedef ImageBannerProps
 * @property {IBannerItem} item
 * @param {ImageBannerProps} props
 * @returns
 */
export default function ImageBanner (props) {
  const { item } = props
  const classes = useStyles(props)
  const { isMobile } = useMode()

  if (!item.image) return null
  const imageUrl = transformation(item.image.url, { width: isMobile ? BANNER.sm.width : BANNER.md.width })

  return (
    <figure
      className={classes.bannerImage}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  bannerImage: {
    maxWidth: 1024, // 最寬就是 1024px 畫面更寬就置中
    width: '100%',
    borderRadius: 12,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginLeft: 16,
    marginRight: 16,
    aspectRatio: 2.7,
  },
}))
