import { DEFAULT_PER_PAGE, useBaseQueryParams } from '@/hooks/queries/params'
import { useDatetime, useDeliveryType } from '../app'
import { useQuery } from '@tanstack/react-query'
import { useRestaurantsRefetchInterval, useRestaurantsStaleTime } from './options'
import { useSelector } from '@/redux'
import _ from 'lodash'
import customerApi from '@/libs/api/customer'

/**
 * 用於收藏頁面的 InfiniteQueryList
 * 根據用戶顯示屬於該用戶收藏的餐廳
 * @param {number} [perPage = 25]
 * @returns
 */
export function useFavoriteRestaurantsQueryOptions (perPage = DEFAULT_PER_PAGE) {
  const { date, time, isImmediate } = useDatetime()
  const { deliveryType } = useDeliveryType()
  const userId = useSelector(state => state.user.member?.id) ?? 'guest'
  const queryKey = isImmediate
    ? ['landing', 'favorites', userId, 'restaurants', 'paginated', { deliveryType, isImmediate }]
    : ['landing', 'favorites', userId, 'restaurants', 'paginated', { deliveryType, date, time }]
  const queryParams = useBaseQueryParams({ limit: perPage, type: deliveryType, deliveryType }) // 覆蓋 baseParams.type 和 baseParams.deliveryType，他們預設是 redux 的 deliveryType 但這裡要用的是傳入的 deliveryType
  const queryFn = ({ pageParam = 1 }) => fetchFn(pageParam)
  async function fetchFn (page) {
    const response = await customerApi.fetchFavoriteRestaurants({ ...queryParams, page: page })
    const pageData = response?.[0]
    const meta = _.chain(pageData).omit('restaurants').assign({ page, perPage }).value()
    const restaurants = pageData?.restaurants ?? []
    const total = pageData?.total ?? 0
    return {
      meta,
      data: restaurants,
      total,
      perPage,
      nextCursor: page + 1,
      prevCursor: Math.max(0, page - 1),
    }
  }
  const staleTime = useRestaurantsStaleTime()
  const refetchInterval = useRestaurantsRefetchInterval()
  return { queryKey, queryFn, staleTime, refetchInterval }
}

/**
 *
 * @returns
 */
export function useFavoriteRestaurantsQuery () {
  const options = useFavoriteRestaurantsQueryOptions()
  const query = useQuery(options)
  return query
}
