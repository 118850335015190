import React from 'react'

import Corner from './Corner'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Corders (props) {
  return (
    <>
      <Corner vertical='top' horizontal='left' />
      <Corner vertical='top' horizontal='right' />
      <Corner vertical='bottom' horizontal='left' />
      <Corner vertical='bottom' horizontal='right' />
    </>
  )
}
