
import PropTypes from 'prop-types'
import React from 'react'

import DineinButtonCheckout from './DineinButtonCheckout'
import DineinButtonServiceRequest from './DineinButtonServiceRequest'

DineinButton.propTypes = {
  variant: PropTypes.oneOf(['checkout', 'serviceRequest']),
}

/**
 *
 * @param {{
 * variant: 'checkout' | 'serviceRequest'
 * }} props
 * @returns
 */
export default function DineinButton (props) {
  const { variant } = props

  switch (variant) {
    case 'checkout':
      return <DineinButtonCheckout />

    case 'serviceRequest':
      return <DineinButtonServiceRequest />

    default:
      return null
  }
}
