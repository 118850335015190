import { Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Card from '@/components/Cards/Card'

function SkeletonPaymentSummaryCard (props) {
  const classes = useStyles(props)

  return (
    <Card>
      <div className={classes.row}>
        <Skeleton animation='wave' variant='text' width={100} height={24} />
        <Skeleton animation='wave' variant='text' width={60} height={24} />
      </div>
      <div className={classes.row}>
        <Skeleton animation='wave' variant='text' width={80} height={24} />
        <Skeleton animation='wave' variant='text' width={50} height={24} />
      </div>

      <div className={classes.divider}>
        <Divider />
      </div>

      <div className={classes.totalRow}>
        <Skeleton animation='wave' variant='text' width={80} height={30} />
        <Skeleton animation='wave' variant='text' width={100} height={30} />
      </div>

    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  row: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1),
  },
  totalRow: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
}))

export default React.memo(SkeletonPaymentSummaryCard)
