import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Integrations } from '@sentry/tracing'
import semver from 'semver'
import * as NativeSentry from '@sentry/capacitor'
import * as ReactSentry from '@sentry/react'

import config from '@/config'
import history from '@/libs/history'

const sentryConfig = {
  dsn: 'https://5ee0641a77634f6a842f8d364bb48ebf@o951143.ingest.sentry.io/5900127',
  release: `customer-app@${config.version}`,
  dist: config.commit,
  environment: config.env,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: ReactSentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0, // 用 1.0 app 會 crash

  _metadata: {
    sdk: {
      name: ReactSentry.SDK_NAME,
      version: ReactSentry.SDK_VERSION,
    },
  },
}

if (Capacitor.getPlatform() === 'web') {
  ReactSentry.init(sentryConfig)
} else {
  ;(async () => {
    const appInfo = await App.getInfo()
    const isNativeSentryVersion = semver.gte(appInfo.version, '1.8.0')
    if (isNativeSentryVersion) {
      console.log('init native sentry')
      NativeSentry.init(sentryConfig, ReactSentry.init)
    } else {
      console.log('init react sentry')
      ReactSentry.init(sentryConfig)
    }
  })()
}
