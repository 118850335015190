import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import { useMember } from '@/hooks/user'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethodCard from '@/components/Cards/PaymentMethodCard'
import PaymentMethods, { tokenType2c2p } from '@/constants/paymentMethod'

export default function RecentPaymentMethodList2c2p ({
  isRetryPayment,
  order,
  closeDrawer,
}) {
  const classes = useStyles()
  const member = useMember()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // the default card comes first
  const paymentCards = [...member.payments]
    // 2c2p does not support ae at the moment
    .filter(payment => payment.payment_scheme !== PaymentMethods.AE)
    .sort((a, b) => {
      if (a.default) {
        return -1
      }

      if (b.default) {
        return 1
      }

      return 0
    })
    .slice(0, 3)

  return (
    <>
      {paymentCards.map((payment, paymentIdx) => {
        const {
          payment_scheme: paymentScheme,
          card_token: cardToken,
          card_no: cardNo,
        } = payment

        const text = payment.default
          ? `**** ${cardNo.slice(-4)} ${t('app.constant.paymentMethod.creditcard2c2precent')}`
          : `**** ${cardNo.slice(-4)}`

        return (
          <PaymentMethodCard
            key={payment.id}
            className={clsx({ [classes.paymentMethodCardRecent]: paymentIdx !== paymentCards.length - 1 })}
            paymentMethod={paymentScheme}
            displayText={text}
            onClick={() => {
              dispatch(actions.payment.updatePaymentMethod(PaymentMethods.RECENT))
              dispatch(actions.payment.updatePaymentGateway(PaymentGateway.PAYMENT_GATEWAY_2C2P))
              dispatch(actions.payment.update2c2pToken({
                token: cardToken,
                last4: cardNo.slice(-4),
                cardType: paymentScheme,
                tokenType: tokenType2c2p.CARD_TOKEN,
              }))

              if (isRetryPayment) {
                dispatch(actions.payment.payOrder(order))
              }

              closeDrawer()
            }}
          />
        )
      })}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  paymentMethodCardRecent: {
    marginBottom: theme.spacing(1),
  },
}))
