import { useBaseQueryParams } from '@/hooks/queries/params'
import { useDatetime, useDeliveryType } from '../app'
import { useMerchant } from '../merchant'
import { useQuery } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'
import landingApi from '@/libs/api/landing'

/**
 *
 * @param {string} merchantId
 * @returns
 */
export function useRestaurantQueryOptions (merchantId) {
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const queryKey = isImmediate
    ? ['landing', 'restaurant', merchantId, { deliveryType, isImmediate }]
    : ['landing', 'restaurant', merchantId, { deliveryType, date, time }]
  const queryParams = useBaseQueryParams()
  const queryFn = () => fetchFn()
  async function fetchFn () {
    const data = await landingApi.fetchRestaurants({ ...queryParams, merchantId })
    return data?.[0]
  }
  return { queryKey, queryFn }
}

/**
 * 目前只能用於 cache image 這種不太會改變的資料
 * @returns
 */
export function useRestaurantQuery () {
  const match = useRouteMatch()
  const merchant = useMerchant()
  const merchantId = match.params.id || merchant?.id
  const options = useRestaurantQueryOptions(merchantId)
  const query = useQuery(options)
  return query
}
