import _ from 'lodash'
import moment from 'moment'

import constants from '@/constants'
import dimorderApi from '@/libs/api/dimorder'
import i18n from '@/i18n'
import store from '@/redux/store'

/* eslint-disable no-unused-vars */
import { Time, UIDate, UITime } from 'dimorder-orderapp-lib/dist/types/Timeslot'
/* eslint-enable no-unused-vars */

const { TABLE } = constants.deliveryType

/** @type {() => IRootState} */
const getState = store.getState

/**
  * @function
  * @param {TDeliveryType} deliveryType
  * @param {boolean} forAll //不指定merchant
  * @returns {array}
  */
export async function getDateRange (deliveryType, forAll) {
  if (!deliveryType) return []
  const merchantId = getState().merchant?.data?.id ?? ''
  const shippingTime = getState().order.shipping?.shippingTime ?? 0
  const data = await dimorderApi.timeslot.getDates({ shippingTime, deliveryType, merchantId, forAll })
  const formatDates = data.dates.map((date) => formatToUIDate(date))
  return formatDates
}

/**
  * @function
  * @param {TDeliveryType} deliveryType
  * @param {String} selectedDate
  * @param {boolean} forAll //不指定merchant
  * @returns {array}
  */
export async function getTimeRange (deliveryType, selectedDate, forAll) {
  const date = moment(selectedDate)
  if (!deliveryType || !selectedDate || !date.isValid()) return []
  const merchantId = getState().merchant?.data?.id ?? ''
  const shippingTime = getState().order.shipping?.shippingTime ?? 0
  const data = await dimorderApi.timeslot.getTimes({ date: date.toISOString(), shippingTime, deliveryType, merchantId, forAll })
  const formatTimes = data.map(formatToUITime)
  return formatTimes
}

/**
 * @param {'moon'} tag // 'moon' = 中秋盆菜
 * @param {string?} testDay
 * @returns
 */
export async function getSpecialDateRange (tag = 'moon', testDay) {
  const data = await dimorderApi.timeslot.getSpecialDates({ tag, testDay })
  return data
}

/**
 *
 * @param {'moon'} tag // 'moon' = 中秋盆菜
 * @param {string} selectedDate
 * @returns
 */
export async function getSpecialTimeRange (tag = 'moon', selectedDate) {
  const date = moment(selectedDate).toISOString()
  const data = await dimorderApi.timeslot.getSpecialTimes({ tag, date })
  return data
}

export function getCurrentOpening () {
  const openings = getState().merchant.data.openingsWithSurcharge
  const datetime = getState().app.params.datetime
  const deliveryType = getState().app.params.deliveryType
  const datetimeMoment = datetime.isImmediate
    ? moment()
    : moment(`${datetime.date} ${datetime.time}`)
  const weekday = datetimeMoment.weekday()
  if (!openings) { return }

  const currentDay = openings[weekday]
  const result = _.find(currentDay, opening => {
    const is24Hr = opening.start === opening.end && opening.startMin === opening.endMin
    const start = datetimeMoment.hours(opening.start).minutes(opening.startMin)
    const end = is24Hr
      ? datetimeMoment.hours(opening.end).minutes(opening.endMin).add(1, 'days')
      : datetimeMoment.hours(opening.end).minutes(opening.endMin)
    if (deliveryType !== TABLE && !opening[deliveryType]) return false // 不管 type 不符的
    if (datetimeMoment.isBetween(start, end, 'hours', [])) return true
  })
  return result
}

/**
 *
 * @param {string} date
 * @returns {UIDate}
 */
export function formatToUIDate (date) {
  const momentDate = moment(date)
  return {
    key: momentDate.format('YYYY-MM-DD'),
    date: momentDate.format(i18n.t('app.common.date_time.date_format')),
    week: moment().isSame(momentDate, 'day')
      ? i18n.t('app.common.date_time.today')
      : moment().add(1, 'days').isSame(momentDate, 'day')
        ? i18n.t('app.common.date_time.tmr')
        : momentDate.format(i18n.t('app.common.date_time.format_week')),
  }
}

/**
 *
 * @param {Time} apiResponseTime
 * @returns {UITime}
 */
export function formatToUITime ({ time, isImmediate }) {
  return {
    key: time,
    time: isImmediate ? i18n.t('app.common.date_time.immediate') : time,
    isImmediate,
  }
}
