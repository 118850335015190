import { ButtonBase, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useRef } from 'react'

import { actions, useDispatch } from '@/redux'
import { formatDatetimeToMoment } from '@/libs/date'
import MemberAvatar from '@/components/MemberAvatar'
import hooks from '@/hooks'

import CurrentLocationTooFarPopover from './CurrentLocationTooFarPopover'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Member (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const member = hooks.user.useMember()
  const address = hooks.user.useAddress()
  const datetime = hooks.app.useDatetime()
  const popoverAnchorRef = useRef(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  React.useEffect(() => {
    setAnchorEl(popoverAnchorRef)
  }, [popoverAnchorRef.current])

  const datetimeText = datetime?.isImmediate
    ? t('app.common.date_time.immediate')
    : formatDatetimeToMoment(datetime).format(`${t('app.common.date_time.format_week')} ${t('app.common.date_time.format_time')}`)

  return (
    <ListItem classes={{ root: classes.listItemRoot }}>
      <ButtonBase onClick={() => {
        if (!member?.id) {
          return dispatch(actions.app.toggleLoginDrawer(true))
        }
        history.push('/member')
      }}
      >
        <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
          <MemberAvatar />
        </ListItemAvatar>
      </ButtonBase>
      <ButtonBase onClick={() => dispatch(actions.app.toggleDrawer('schedule', true))}>
        <ListItemText
          className={classes.text}
          ref={popoverAnchorRef}
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.primary,
            secondary: classes.secondary,
          }}
          primary={
            address.id === 'CURRENT'
              ? t('app.page.setting_address.current_location.title')
              : address.name
          }
          secondary={datetimeText}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ButtonBase>
      {
        anchorEl &&
          <CurrentLocationTooFarPopover anchorEl={anchorEl.current} />
      }
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    flexShrink: 0,
    width: 'unset',
    cursor: 'pointer',
    padding: 0,
    marginLeft: theme.spacing(0.5),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
  },
  listItemTextRoot: {
    maxWidth: 70,
    overflow: 'hidden',
  },
  listItemAvatarRoot: {
    display: 'grid',
    placeContent: 'center',
    minWidth: 40,
  },
  primary: {
    fontSize: '1.4rem',
    width: '100%',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  secondary: {
    fontSize: '1.2rem',
    width: '100%',
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
}))
