import { AppsFlyer } from 'appsflyer-capacitor-plugin'
import { Capacitor } from '@capacitor/core'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import { findPlacesByLatLng, getCountryInGeocodeResult } from '@/libs/googleMap'
import { gaInstance } from '@root/src/hooks/useTrack'
import { getCurrentPosition, getDistanceFromLatLonInKm } from '@/libs/geolocation'
import DeliveryType from '@/constants/deliveryType'
import OrderStatus from '@/constants/orderStatus'
import OrderTakeawayStatus from '@/constants/orderTakeawayStatus'
import customerApi from '@/libs/api/customer'
import deliveryOption from '@/constants/deliveryOption'
import dimorderApi from '@/libs/api/dimorder'
import getToken from '@/libs/getToken'
import getUniqueAddressName from '@/libs/getUniqueAddressName'
import handleAxiosError from '@/libs/handleAxiosError'
import i18n from '@/i18n'
import logger, { flush, logId, setUserId as setLoggerUserId } from '@/libs/logger'
import theme from '@/theme'

import ActionTypes from './ActionTypes'
import actions from '../actions'

/** @typedef {import('@/redux/landing/Landing.d').IAppPromoCode} IAppPromoCode */

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    dispatch(restoreAddress())
    dispatch(restorePromoCodes())
    dispatch(getCurrentAddress())

    const logoutCallback = (errorMessage) => {
      dispatch(actions.app.toggleAlert({
        title: process.env.NODE_ENV === 'production' ? '' : i18n.t(errorMessage),
        message: i18n.t('app.component.alert.logout_alert'),
        dialogProps: {
          disableBackdropClick: true,
        },
        button: {
          text: i18n.t('app.common.confirm'),
          onClick: () => {
            dispatch(logout())
          },
        },
      }))
    }

    // 設定 refresh token 的 callback
    dimorderApi.setResetUserCallback((errorMessage) => {
      logger.error('[dimorderApi] trigger logoutCallback', {
        errorMessage,
      })
      logoutCallback(errorMessage)
    })
    dimorderApi.setRefreshCustomerApiCallback(async () => {
      // dimorder api 使用 customer token 需要 refresh 時，使用這個 function 來處理
      const { data: auth } = await customerApi.refresh()
      // update localstorage
      await dispatch(updateCustomerApiAuth(auth))
    })
    dimorderApi.setUpdateUserCallback((response) => {
      localStorage.setItem('anonymousAuth', JSON.stringify(response))
    })
    customerApi.setRefreshTokenCallback((auth) => dispatch(updateCustomerApiAuth(auth)))
    customerApi.setLogoutCallback((errorMessage) => {
      logoutCallback(errorMessage)
    })

    // 嘗試從 localstorage 還原 customer
    await dispatch(restoreCustomer())
    // 如果沒有還原到 customer
    if (!getState().user.member?.id) {
      // 還原匿名登入
      await dispatch(restoreAnonymousUser())
    }

    dispatch({ type: ActionTypes.INIT })
  }
}

/**
 * @param {CustomerApiAuthData} _auth
 * @returns {ThunkFunction}
 */
export function updateCustomerApiAuth (auth) {
  return async (dispatch, getState) => {
    localStorage.setItem('customerAuth', JSON.stringify(auth))
    dimorderApi.setToken(auth.accessToken, auth.refreshToken)
  }
}

/**
 * login customer anonymous user
 * @returns {ThunkFunction}
 */
export function loginCustomerAnonymous () {
  return async (dispatch, getState) => {
    const { data: auth } = await customerApi.loginAnonymous(uuid())

    // 更新到 redux 和 localstorage
    await dispatch(updateCustomerApiAuth(auth))
    await dispatch(getCustomerInfo())
  }
}

/**
 * register orderapp anonymous user 臨時抓菜單用
 * @returns {ThunkFunction}
 */
export function restoreAnonymousUser () {
  return async (dispatch, getState) => {
    try {
      const anonymousAuthJson = localStorage.getItem('anonymousAuth')
      if (!anonymousAuthJson) {
        throw new Error('anonymous auth not store')
      }

      const anonymousAuth = JSON.parse(anonymousAuthJson)
      if (anonymousAuth.token == null) {
        throw new Error('anonymous auth parse fail')
      }

      dimorderApi.setToken(anonymousAuth.token, anonymousAuth.refresh)
    } catch (error) {
      console.log('restoreAnonymousUser error:', error.message)

      // 還原失敗，註冊新的匿名 user
      await dispatch(registerAnonymousUser())
    }
  }
}

/**
 * register orderapp anonymous user 臨時抓菜單用
 * @returns {ThunkFunction}
 */
export function registerAnonymousUser (payload, hash, client) {
  return async (dispatch, getState) => {
    try {
      // orderapp create token
      const response = await dimorderApi.auth.register(payload, hash, client)
      localStorage.setItem('anonymousAuth', JSON.stringify(response))

      // orderapp create customer
      await dimorderApi.auth.createCustomer()
    } catch (error) {
      console.log('registerAnonymousUser error', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function deleteCustomerAccount () {
  return async (dispatch, getState) => {
    const orders = getState().orderHistory.orders
    // 尋找未完成的訂單
    const hasPendingOrder = orders.some(order => {
      switch (order.status) {
        case OrderStatus.CANCELLED: {
          // 取消的訂單一定不影響刪除帳號
          return false
        }
        case OrderStatus.PENDING: {
          // 待付款的訂單，如果已經有點餐則會影響刪除帳號
          return order.batches.length > 0
        }
        case OrderStatus.PAID: {
          switch (order.deliveryType) {
            case DeliveryType.TABLE: {
              // 如果是內用就不影響刪除帳號
              return false
            }
            case DeliveryType.TAKEAWAY: {
              // 外帶需要 takeawayStatus 為 completed 就結束了
              return order.takeawayStatus !== OrderTakeawayStatus.COMPLETED
            }
            case DeliveryType.STORE_DELIVERY: {
              // 外送 takeawayStatus 到 delivered 就結束了
              return order.takeawayStatus !== OrderTakeawayStatus.DELIVERED
            }
          }
        }
      }
      return false
    })

    if (hasPendingOrder) {
      // 還有沒完成的訂單，顯示待訂單完成後再試
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.page.member_details.delete_account.order_not_complete_alert.title'),
        message: i18n.t('app.page.member_details.delete_account.order_not_complete_alert.message'),
      }))
      return
    }

    // 訂單狀態沒有 pending，詢問是否真的刪除
    dispatch(actions.app.toggleAlert({
      title: i18n.t('app.page.member_details.delete_account.delete_alert.title'),
      message: i18n.t('app.page.member_details.delete_account.delete_alert.message'),
      buttons: [
        { text: i18n.t('app.common.cancel') },
        {
          text: i18n.t('app.common.confirm'),
          onClick: async () => {
            try {
              // 刪除並登出
              await customerApi.deleteCustomerAccount()
              dispatch(actions.user.logout())
            } catch (error) {
              handleAxiosError(error, {
                loggerPrefix: '[deleteCustomerAccount] deleteCustomerAccount',
                responseErrorHandler: (errorMessage) => {
                  dispatch(actions.app.toggleAlert({
                    title: i18n.t('app.component.alert.error_please_retry.title') + ` (${logId})`,
                  }))
                },
              })
            }
          },
        },
      ],
    }))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getCustomerInfo () {
  return async (dispatch, getState) => {
    try {
      const address = getState().user.address
      const customerInfo = await customerApi.getCustomerInfo()

      setLoggerUserId(customerInfo.data.id)
      gaInstance.gtag('set', '&uid', customerInfo.data.id)

      try {
        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
          await AppsFlyer.setCustomerUserId({ cuid: customerInfo.data.id })
        }
      } catch (error) {
        console.log(error)
      }
      dispatch({
        type: ActionTypes.UPDATE_MEMBER,
        payload: { member: customerInfo.data },
      })

      dispatch(getAddresses())
      dispatch(getCoupons())

      if (!address) {
        dispatch({
          type: ActionTypes.UPDATE_ADDRESS,
          payload: { address: customerInfo.data.address },
        })
      }

      dispatch(actions.payment.restoreSavePayment(Boolean(customerInfo.data.options?.savePayment)))
      dispatch(actions.orderHistory.getOrders())
    } catch (error) {
      console.log('getCustomerInfo error', error)
    }
  }
}

/**
 * @param {CustomerApiCustomerInfo} updateFields
 * @returns {ThunkFunction}
 */
export function updateCustomerInfo (updateFields) {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('backdrop', true))
    try {
      await customerApi.updateCustomerInfo(updateFields)
      dispatch(getCustomerInfo())
    } catch (error) {
      console.log('updateCustomerInfo error', error)
    }
    dispatch(actions.app.updateLoading('backdrop', false))
  }
}

/**
 * @param {File} file
 * @returns {ThunkFunction}
 */
export function uploadCustomerAvatar (file) {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('backdrop', true))
    try {
      const response = await customerApi.uploadCustomerAvatar(file)
      dispatch({
        type: ActionTypes.UPDATE_MEMBER,
        payload: { member: response.data },
      })
    } catch (error) {
      console.log('uploadCustomerAvatar error', error)
    }
    dispatch(actions.app.updateLoading('backdrop', false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getAddresses () {
  return async (dispatch, getState) => {
    const addressList = await customerApi.getAllAddresses()

    dispatch({
      type: ActionTypes.UPDATE_ADDRESS_LIST,
      payload: { addressList },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function createAddress () {
  return async (dispatch, getState) => {
    const address = getState().user.addressTemp

    dispatch(actions.app.updateLoading('backdrop', true))
    try {
      const result = await customerApi.createAddress(address)
      await dispatch(getAddresses())

      await dispatch(selectAddress(result))
    } catch (error) {
      console.log('createAddress error', error)
    }
    dispatch(actions.app.updateLoading('backdrop', false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateAddress () {
  return async (dispatch, getState) => {
    const updatedAddress = getState().user.addressTemp
    const currentAddress = getState().user.address
    dispatch(actions.app.updateLoading('backdrop', true))
    try {
      await customerApi.updateAddress(updatedAddress) // 更新一個地址
      await dispatch(getAddresses()) // 重抓地址列表
      if (updatedAddress.id === currentAddress.id) {
        // 若更新當前選擇的地址則需要更新所選的地址
        dispatch(selectAddress(updatedAddress))
      }
    } catch (error) {
      console.log('updateAddress error', error)
    }
    dispatch(actions.app.updateLoading('backdrop', false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function deleteAddress () {
  return async (dispatch, getState) => {
    const address = getState().user.addressTemp

    if (!address || !address.id) return

    dispatch(actions.app.updateLoading('backdrop', true))
    try {
      await customerApi.deleteAddress(address.id)
      await dispatch(getAddresses())
    } catch (error) {
      console.log('updateAddresses error', error)
    }
    dispatch(actions.app.updateLoading('backdrop', false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function logout () {
  return async (dispatch, getState) => {
    const params = getState().app.params
    const merchantId = getState().merchant?.data?.id
    const deviceId = getState().app.deviceId

    logger.log('[logout]', { deviceId })

    try {
      if (deviceId) {
        // 登出時刪除後端 fcm token，不再接收推播
        const response = await customerApi.deleteFcmToken(deviceId)
        logger.log('[logout] delete fcm token success', { deviceId, response })
      }
    } catch (error) {
      logger.error(`[logout] delete fcm token error ${error.message || String(error)}`, { error, deviceId })
    }

    dispatch({ type: 'LOGOUT' })
    const address = localStorage.getItem('address')
    const debugMode = localStorage.getItem('DEBUG_MODE')
    const overwriteEnv = localStorage.getItem('OVERWRITE_ENV')
    const overwriteDeployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')

    // clear location storage, 除了 debugMode, env 和 deployment
    localStorage.clear()

    // 還原地址 localStorage
    localStorage.setItem('address', address)

    // 還原其他需要的 localStorage
    if (debugMode) {
      localStorage.setItem('DEBUG_MODE', debugMode)
    }
    if (overwriteEnv) {
      localStorage.setItem('OVERWRITE_ENV', overwriteEnv)
    }
    if (overwriteDeployment) {
      localStorage.setItem('OVERWRITE_DEPLOYMENT', overwriteDeployment)
    }

    // 餐廳模式保留原本的首頁按鈕開放設定
    if (params.isD2CWeb) {
      const newParams = {
        isD2CWeb: params.isD2CWeb,
        merchantId: params.merchantId,
        takeaway: params.takeaway,
        storeDelivery: params.storeDelivery,
        dineIn: params.dineIn,
      }
      localStorage.setItem('params', JSON.stringify(newParams))
    }

    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
    await flush()

    // 重新載入頁面至首頁
    document.location.href = params.isD2CWeb ? `/d2c/${merchantId}` : '/'
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restoreCustomer () {
  return async (dispatch, getState) => {
    const customerAuth = JSON.parse(localStorage.getItem('customerAuth'))
    if (customerAuth) {
      // 設定給 customer api
      customerApi.setToken(customerAuth)
      // init fcm
      dispatch(actions.app.initPushNotification())
      // 更新到 redux
      await dispatch(updateCustomerApiAuth(customerAuth))
      await dispatch(getCustomerInfo())
      // 針對早期未設定行銷用戶跳出提示
      dispatch(updateSMSMarketingAgreement())
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restoreAddress () {
  return (dispatch, getState) => {
    const address = JSON.parse(localStorage.getItem('address'))
    dispatch({
      type: ActionTypes.UPDATE_ADDRESS,
      payload: { address },
    })
  }
}

/**
 * @param {IAddress} address
 * @returns {ThunkFunction}
 */
export function selectAddress (address) {
  return (dispatch, getState) => {
    localStorage.setItem('address', JSON.stringify(address))
    dispatch({
      type: ActionTypes.UPDATE_ADDRESS,
      payload: { address },
    })
  }
}

/**
 * @param {String?} id
 * @returns {ThunkFunction}
 */
export function createAddressTemp (id) {
  return (dispatch, getState) => {
    const addressList = getState().user.addressList
    const name = getUniqueAddressName(addressList)

    const defaultAddress = {
      id: id,
      name: name,
      address: '',
      building: '',
      remark: '',
      deliveryOption: deliveryOption.MEET_AT_DOOR,
      latlng: null,
      default: false,
    }
    dispatch({
      type: ActionTypes.ADD_ADDRESS_TEMP,
      payload: { address: defaultAddress },
    })
  }
}

/**
 * @param {IAddress} address
 * @returns {ThunkFunction}
 */
export function addAddressTemp (address) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_ADDRESS_TEMP,
      payload: { address },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetAddressTemp () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REST_ADDRESS_TEMP,
      payload: { },
    })
  }
}

/**
 * @param {PropertyPath} path
 * @param {any} value
 * @returns {ThunkFunction}
 */
export function updateAddressTemp (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ADDRESS_TEMP,
      payload: { path, value },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function dummyMemberLogin () {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('backdrop', true))

    try {
      await dispatch(loginCustomerAnonymous())

      await customerApi.updateCustomerInfo({
        name: 'test',
        avatar: 'https://placeimg.com/40/40/people',
      })

      await customerApi.createAddress({
        name: '公司',
        address: '香港西九龍 Austin Rd W, 1號環球貿易廣場(ICC)',
        latlng: { lat: 22.3014545, lng: 114.1593735 },
        floorRoom: '五樓之三',
        building: '環貿大樓',
        remark: '',
        deliveryOption: 'LEAVE_AT_DOOR',
        default: true,
      })

      await customerApi.createAddress({
        name: '半島酒店',
        address: '香港尖沙咀梳士巴利道',
        latlng: { lat: 22.2936678, lng: 114.1739041 },
        floorRoom: '三樓',
        building: 'B棟',
        remark: '',
        deliveryOption: '',
        default: false,
      })

      await dispatch(getCustomerInfo())
    } catch (error) {

    }

    dispatch(actions.app.updateLoading('backdrop', false))
  }
}

/**
 * 抓使用者當前位置
 * 如果當前位置跟之前的位置差太遠就跳視窗問要不要改地址
 * 選是的話轉頁到選地址的地圖
 * @returns {ThunkFunction}
 */
export function getCurrentAddress () {
  return async (dispatch, getState) => {
    const address = getState().user.address
    if (!address) return
    // 如果之前沒有設定過地址不繼續
    // App init 之後會轉頁到 /restaurants
    // /restaurants init 會檢查有沒有設定過地址
    // 沒有設定過地址的話會轉到 /settings/address/map 用地圖選地址

    try {
      const position = await getCurrentPosition({ enableHighAccuracy: false })

      try {
        const latLng = { lat: position.coords.latitude, lng: position.coords.longitude }
        const request = { location: latLng }
        const geocodeResults = await findPlacesByLatLng(request)
        const country = getCountryInGeocodeResult(geocodeResults[0])

        if (_.isEmpty(geocodeResults)) return // 定位失敗 繼續用之前的地址
        if (country !== 'HK') return // 定位不在香港 繼續用之前的地址

        // 定位成功 檢查新地址的位置跟舊地址的距離
        const prevLatLng = address.latlng
        const distanceInKm = getDistanceFromLatLonInKm(prevLatLng.lat, prevLatLng.lng, latLng.lat, latLng.lng)
        console.log('distanceInKm', distanceInKm)
        if (distanceInKm > 5) {
          dispatch(actions.app.togglePopover('currentLocationTooFar', true, { distanceInKm }))
        }
      } catch (error) {
        console.log('findPlacesByLatLng error', error)
      }
      return
    } catch (error) {
      console.log('getCurrentAddress error', error)
    }
  }
}

/**
 * 離開 address edit 返回層數
 * @param {string} exitAddressEditBackLevel 返回層數
 * @returns {ThunkFunction}
 */
export function updateExitAddressEditBackLevel (exitAddressEditBackLevel) {
  return {
    type: ActionTypes.UPDATE_EXIT_ADDRESS_EDIT_BACK_LEVEL,
    payload: { exitAddressEditBackLevel },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restorePromoCodes () {
  return async (dispatch, getState) => {
    const promoCodesJson = localStorage.getItem('promoCodes')
    if (promoCodesJson === 'undefined') { // 之前寫錯會造成 crash 為了避免已經使用的人繼續 crash 所以要幫他清掉
      localStorage.removeItem('promoCodes')
      return
    }
    if (!promoCodesJson) return

    try {
      const promoCodes = JSON.parse(promoCodesJson)

      if (promoCodes.length === 0) return
      dispatch({
        type: ActionTypes.UPDATE_PROMOCODES,
        payload: { promoCodes },
      })
    } catch (error) {
      console.log('restorePromoCodes JSON.parse error', error, 'promoCodesJson', promoCodesJson)
    }
  }
}

/**
 * @param {IAppPromoCode} promoCode
 * @returns {ThunkFunction}
 */
export function addPromoCode (promoCode) {
  return async (dispatch, getState) => {
    const oldPromoCodes = getState().user.promoCodes

    // 已經有的 promoCode 不用新增
    if (oldPromoCodes.find(oldPromoCode => oldPromoCode.code === promoCode.code && oldPromoCode.merchantId === promoCode.merchantId)) return

    await dispatch({
      type: ActionTypes.ADD_PROMOCODE,
      payload: { promoCode },
    })

    const promoCodes = getState().user.promoCodes
    localStorage.setItem('promoCodes', JSON.stringify(promoCodes))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetPromoCodes () {
  return (dispatch, getState) => {
    localStorage.removeItem('promoCodes')
    dispatch({
      type: ActionTypes.RESET_PROMOCODES,
      payload: { },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getCoupons () {
  return async (dispatch, getState) => {
    const member = getState().user.member
    if (!member?.id) return

    try {
      const coupons = await dimorderApi.promoCode.getCoupons()
      dispatch({
        type: ActionTypes.UPDATE_COUPONS,
        payload: { coupons },
      })
    } catch (error) {
      console.log('getCoupons error', error)
    }
  }
}

/**
 * @param {string?} promoCodeId
 * @returns {ThunkFunction}
 */
export function claimCoupon (promoCodeId) {
  return async (dispatch, getState) => {
    if (!promoCodeId) {
      // 沒帶 promoCodeId 從 params 拿來用
      promoCodeId = getState().app.params.promoCodeId
    }
    if (!promoCodeId) {
      // 還是沒有 promoCodeId 直接結束
      return
    }

    try {
      const coupon = await dimorderApi.promoCode.claimCoupon(promoCodeId)

      dispatch({
        type: ActionTypes.ADD_COUPON,
        payload: { coupon },
      })

      // 從 params 刪除
      dispatch(actions.app.deletePromoCodeId())

      // 顯示領取成功
      dispatch(actions.app.toggleDialog('couponClaimed', true, coupon))
    } catch (error) {
      handleAxiosError(error, {
        loggerPrefix: '[claimCoupon] claimCoupon',
        loggerExtraData: { promoCodeId },
        responseErrorHandler: (errorMessage) => {
          // 從 params 刪除
          dispatch(actions.app.deletePromoCodeId())

          if (i18n.exists(`app.component.alert.coupon_claim_error.${errorMessage}`)) {
            // 顯示領取失敗
            const errMessage = _.get(error, 'response.data.error', 'message')
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.coupon_claim_error.title'),
              message: i18n.t(`app.component.alert.coupon_claim_error.${errMessage}`),
            }))
          } else {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.error_please_retry.title') + ` (${logId})`,
            }))
          }
        },
      })
    }
  }
}

/**
 * @param {Array<{id: string, updatedAt: string}>} checkedFinalStageOrders
 * @returns {ThunkFunction}
 */
export function updateCheckedFinalStageOrders (checkedFinalStageOrders) {
  return async (dispatch, getState) => {
    const member = getState().user.member
    if (!member?.id) return

    const { options } = member
    dispatch(updateCustomerInfo({ options: { ...options, checkedFinalStageOrders } }))
  }
}

export function updateSMSMarketingAgreement () {
  return (dispatch, getState) => {
    const isLogin = Boolean(getState().user.member?.id)
    const options = getState().user.member?.options ?? {}
    const { allowDirectMarketing } = options

    if (isLogin) {
      if (allowDirectMarketing === undefined) {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.offer_promo_sms_agreement.alert.title'),
          message: i18n.t('app.component.offer_promo_sms_agreement.alert.message'),
          buttons: [
            {
              text: i18n.t('app.common.disagree'),
              style: { color: theme.palette.primary.main },
              onClick: () => {
                dispatch(actions.user.updateCustomerInfo({ options: { ...options, allowDirectMarketing: false } }))
              },
            },
            {
              text: i18n.t('app.common.agree'),
              style: { backgroundColor: theme.palette.primary.main },
              onClick: () => {
                dispatch(actions.user.updateCustomerInfo({ options: { ...options, allowDirectMarketing: true } }))
              },
            },
          ],
        }))
      }
    }
  }
}

/**
 * 轉移匿名帳號下的所有訂單至正式帳號
 * @returns {ThunkFunction}
 */
export function transferAnonymousOrders () {
  return async (dispatch, getState) => {
    try {
      const token = getToken()
      const anonymousToken = getToken({ anonymous: true })
      if (token && anonymousToken) {
        await dimorderApi.order.transferAnonymousOrders(token, anonymousToken)
      }
    } catch (error) {
      console.error('transferAnonymousOrders error', error)
    }
  }
}
