import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import RestaurantFooter from '@/components/Footers/RestaurantFooter'
import constants from '@/constants'
import hooks from '@/hooks'

/**
 *
 * @param {*} props
 */
export default function CartFooter (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const merchant = hooks.merchant.useMerchant()

  const submitButtonProps = {
    title: t('app.component.footer.cart.go_checkout_order'),
    onClick: () => {
      // 讓使用者從 payment page 返回的時候可以一層一層返回而不是直接回到 restaurant list page
      history.replace(`/restaurant/${merchant?.id}`)
      history.pushWithSearch('/checkout')
      dispatch(actions.app.resetDrawers())
    },
  }

  return (
    <RestaurantFooter submitButtonProps={submitButtonProps} solidBackground>
      <div className={classes.buttonWrap}>
        <LetterSpacingButton
          fontSize='1.8rem'
          className={classes.addItemsButton}
          text={t('app.component.footer.cart.add_items')}
          onClick={() => {
            dispatch(actions.app.resetDrawers())
            history.push(`/restaurant/${merchant?.id}`)
          }}
        />
      </div>
    </RestaurantFooter>
  )
}

const useStyles = makeStyles(theme => ({
  buttonWrap: {
    marginTop: theme.spacing(1.5),
    display: 'grid',
    height: constants.app.height.FOOTER,
  },
  addItemsButton: {
    height: constants.app.height.FOOTER,
  },
}))
