import { DEFAULT_PER_PAGE, useSearchQueryParams } from '@/hooks/queries/params'
import { useDatetime, useDeliveryType } from '../app'
import { useQuery } from '@tanstack/react-query'
import { useRestaurantsRefetchInterval, useRestaurantsStaleTime } from './options'
import { useSelector } from '@/redux'
import landingApi from '@/libs/api/landing'

/**
 * 用於搜尋頁面的 InfiniteQueryList
 * 根據 filters 與 searchText 搜尋餐廳
 * @param {number} [perPage = 25]
 * @returns
 */
export function useSearchRestaurantsQueryOptions (perPage = DEFAULT_PER_PAGE) {
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const searchText = useSelector(state => state.landing.searchText)
  const filter = useSelector(state => state.landing.filter)
  const queryKey = isImmediate
    ? ['landing', 'category', 'all_categories', 'restaurants', 'paginated', { deliveryType, isImmediate, searchText, filter }]
    : ['landing', 'category', 'all_categories', 'restaurants', 'paginated', { deliveryType, date, time, searchText, filter }]
  const queryParams = useSearchQueryParams({ limit: perPage })
  const queryFn = ({ pageParam = 1 }) => fetchFn(pageParam)
  async function fetchFn (page) {
    const response = await landingApi.searchRestaurants({ ...queryParams, page })
    const data = response?.[0]?.restaurants ?? []
    const total = response?.[0]?.total ?? 0
    return {
      data,
      total,
      perPage,
      nextCursor: page + 1,
      prevCursor: Math.max(0, page - 1),
    }
  }
  const staleTime = useRestaurantsStaleTime()
  const refetchInterval = useRestaurantsRefetchInterval()
  return { queryKey, queryFn, staleTime, refetchInterval }
}

/**
 *
 * @returns
 */
export function useSearchRestaurantsQuery () {
  const options = useSearchRestaurantsQueryOptions()
  const query = useQuery(options)
  return query
}
