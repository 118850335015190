import React from 'react'

import { actions, useDispatch } from '@/redux'
import QrcodeImage from '@/assets/images/home/qrcode.png'

import DeliveryTypeButtonBase from './DeliveryTypeButtonBase'

/**
 * 內用按鈕
 * 1. 點擊打開 QRcode 掃描頁面
 * @param {*} props
 * @returns
 */
export default function TableButton (props) {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(actions.app.openQRCodeScanner())
  }

  return (
    <DeliveryTypeButtonBase
      title='掃描'
      subtitle='QR Code'
      onClick={handleClick}
      imageProps={{
        src: QrcodeImage,
        alt: 'QrCode',
        style: {
          height: '60%',
        },
      }}
      buttonProps={{
        style: {
          backgroundColor: 'rgb(255 209 101)',
        },
      }}
    />
  )
}
