import { makeStyles } from '@material-ui/core/styles'
import Lottie from 'react-lottie'
import React from 'react'

import { DEFAULT, LOTTIES, QR_CODE, TRACKING_MAP } from '@/libs/orderTracking'
import OrderTrackingMap from '@/pages/OrderTracking/OrderTrackingMap'

import PickupCode from './PickupCode'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 *
 * @param {{
 * order: IAppOrder
 * statusConfig: object
 * hidden: boolean
 * }} props
 * @returns
 */
export default function ProgressImage (props) {
  const classes = useStyles(props)
  const { order, statusConfig, hidden } = props

  if (hidden) return null

  switch (statusConfig.iconType) {
    case TRACKING_MAP:
      return (
        <OrderTrackingMap
          // TODO: locations
          origin={{ lat: 22.3171802, lng: 114.1699941 }}
          destination={{ lat: 22.3103909, lng: 114.1694516 }}
          className={classes.map}
        />
      )

    case QR_CODE:
      return <PickupCode code={order.code} />

    case LOTTIES:
      return (
        <Lottie
          options={{
            loop: statusConfig.loop || false,
            animationData: statusConfig.iconSource,
          }}
          width='50%'
          height='auto'
        />
      )

    case DEFAULT:
      return (
        <div className={classes.imgWrap}>
          <img className={classes.img} src={statusConfig?.iconSource} />
        </div>
      )

    default:
      return null
  }
}

const useStyles = makeStyles(theme => ({
  map: {
    height: 200,
  },
  imgWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '50%',
  },
}))
