import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { RESTAURANT_IMAGE, RESTAURANT_LOGO, transformation } from '@/libs/cloudinary'
import { actions, useDispatch, useSelector } from '@/redux'
import { poonchoiTimeslots } from '@/libs/poonchoi'
import Card from '@/components/Cards/Card'
import DeliveryTypeLabel from '@/components/DeliveryTypeLabel'
import DeliveryTypes from '@/constants/deliveryType'
import FavoriteIcon from '@/components/Cards/RestaurantCard/FavoriteIcon'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

import OrderActionButton from './OrderActionButton'

export default function OrderHistoryItem (props) {
  const { item: order } = props
  const { t } = useTranslation()
  const history = useHistory()
  const d2cBaseUrl = useD2CBaseUrl()
  const dispatch = useDispatch()
  const orderingOrder = useSelector(state => state.order?.selectedOrder)
  const logoImageUrl = transformation(order.merchantLogoImage, { width: RESTAURANT_LOGO.sm.width })
  const coverImageUrl = transformation(order.merchantCoverImage, { width: RESTAURANT_IMAGE.width })
  const classes = useStyles({ ...props, logoImageUrl, coverImageUrl })

  if (_.isEmpty(order)) return null

  /**
   * 盆菜會提早很久預定，所以盆菜訂單進行中的狀態需要改成幾月幾號下午/晚上送達
   * 1. 等待店家接單 waiting_accept
   * 2. 備餐中 preparing_meal => 盆菜將於 m月d號下午/晚上 送達
   * 3. 等待取餐 waiting_pick_up => 盆菜將於 m月d號下午/晚上 送達
   * 4. 送餐中 in_delivery => 盆菜將於 m月d號下午/晚上 送達
   * 5. 已送達 complete/arrived
   * @function
   * @returns {string}
   */
  function displayStatus () {
    const month = moment(order.pickupAt).format('M')
    const date = moment(order.pickupAt).format('D')
    const isPoonchoi = _.find(order.tags, tag => tag.name === 'poonchoi')
    const isPoonchoiProgressing = isPoonchoi && _.includes(['preparing_meal', 'waiting_pick_up', 'in_delivery'], order.displayStatusKey)

    if (isPoonchoiProgressing) {
      if (poonchoiTimeslots(order.pickupAt)['14~17']) return t('app.component.order_history_item.display_status.poonchoi_will_be_deliver_at', { month, date, timeslot: t('app.common.date_time.afternoon') })
      if (poonchoiTimeslots(order.pickupAt)['17~21']) return t('app.component.order_history_item.display_status.poonchoi_will_be_deliver_at', { month, date, timeslot: t('app.common.date_time.night') })
    }
    return t(`app.constant.displayStatusKey.${order.displayStatusKey}`)
  }

  const handleCardClick = async () => {
    dispatch(actions.orderHistory.selectOrder(order.id))
    if (orderingOrder?.deliveryType === DeliveryTypes.TABLE && orderingOrder?.Id === order.id) {
      history.pushWithSearch(`${d2cBaseUrl}/order`)
    } else {
      history.push(`${d2cBaseUrl}/order_tracking`)
    }
  }

  return (
    <li>
      <Card onClick={handleCardClick}>
        <div className={classes.header}>
          <div className={classes.restr}>
            <div className={classes.logoImage} />
            <Typography variant='body1' className={classes.bold}>
              {order.merchantName}
            </Typography>
          </div>
          <Typography variant='caption' className={classes.text}>
            {moment(order.createdAt).format(t('app.common.date_time.format_year_date_time'))}
          </Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.coverImage}>
            <FavoriteIcon key={order.merchantId} merchantId={order.merchantId} deliveryType={order.deliveryType} open />
          </div>
          <div className={classes.info}>
            <Typography variant='body1'>
              {order.serial}
            </Typography>
            <Typography variant='body1'>
              {displayStatus()}
            </Typography>
            <div className={classes.action}>
              <DeliveryTypeLabel deliveryType={order.deliveryType} />
              <OrderActionButton order={order} />
            </div>
          </div>

        </div>

      </Card>
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  restr: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  text: {
    opacity: '50%',
  },
  bold: {
    fontWeight: '600',
  },
  logoImage: {
    backgroundImage: props => `url(${props.logoImageUrl})`,
    width: 30,
    height: 30,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: 10,
    marginRight: theme.spacing(1),
    position: 'relative',
  },
  content: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1),
    },
    '& > :last-child': {
      marginRight: 0,
    },
  },
  coverImage: {
    backgroundImage: props => `url(${props.coverImageUrl})`,
    width: '32%',
    paddingTop: '24%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: 8,
    position: 'relative',
  },
  info: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  action: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
