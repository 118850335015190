import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * 顯示訂單預計多久還會送到
 * 過了預計時間顯示即將到達
 * 訂單完成則顯示訂單完成
 * 盆菜不顯示
 *
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function EstimatedDeliveryTime (props) {
  const { order } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const isPoonchoi = _.some(order.tags, tag => tag.name === 'poonchoi')
  const startTime = moment(order?.pickupAt).minute(Math.ceil(moment(order?.pickupAt).minute() / 5) * 5)
  const endTime = startTime.clone().add(15, 'm')

  if (isPoonchoi) return <div />
  return (
    <div className={classes.titleWrap}>
      <Typography variant='h5' className={classes.bold}>
        {
          order.displayStatusKey === 'arrived'
            ? t('app.page.order_tracking.progress_card.storeDelivery.arrived_title')
            : moment().isBefore(endTime)
              ? moment().isSame(moment(order?.pickupAt), 'day')
                ? `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`
                : moment(order?.pickupAt).format(t('app.common.date_time.format_year_date_time'))
              : t('app.page.order_tracking.progress_card.storeDelivery.almost_there')
        }
      </Typography>
      <Typography variant='caption' className={classes.hint}>
        {t('app.page.order_tracking.progress_card.storeDelivery.subtitle')}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  titleWrap: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  bold: {
    fontWeight: 'bold',
  },
  hint: {
    color: theme.palette.grey[600],
  },
}))
