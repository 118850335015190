import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import PropTypes from 'prop-types'
import React from 'react'

InfoButton.propTypes = {
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
}

/**
 *
 * @param {{
 * onClick: Function
 * hidden?: boolean
 * }} props
 */
export default function InfoButton (props) {
  const { onClick, hidden } = props
  const classes = useStyles(props)

  return (
    <IconButton
      className={classes.infoButton}
      onClick={onClick}
      disabled={hidden}
    >
      <InfoOutlinedIcon fontSize='small' className={classes.icon} />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  infoButton: {
    padding: theme.spacing(0.5),
    opacity: props => props.hidden ? 0 : 1,
    transition: 'all 300ms ease',
    transitionProperty: 'opacity',
  },
  icon: {
    fontSize: '1.8rem',
  },
}))
