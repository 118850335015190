import { Capacitor } from '@capacitor/core'

const safeArea = {
  top: Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-top)' : '0px',
  right: Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-right)' : '0px',
  bottom: Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-bottom)' : '0px',
  left: Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-left)' : '0px',
}

const height = {
  NAVBAR: 45,
  APPBAR: 64,
  CATEGORYBAR: 52,
  FOOTER: 56,
  SNACKBAR: 76,
}

export default {
  mode: {
    MOBILE: 'MODE--MOBILE',
    TABLET: 'MODE--TABLET',
  },
  zIndex: {
    NAVBAR: 1100,
    FOOTER: 1100,
    BACKDROP_LOADING: 1299,
    CATEGORYBAR: 10,
    SNACKBAR: 1200,
  },
  height: height,
  marginTop: {
    GENERAL: safeArea.top,
    NAVBAR: `calc(${height.NAVBAR}px + ${safeArea.top})`,
    APPBAR: `calc(${height.APPBAR}px + ${safeArea.top})`,
  },
  marginBottom: {
    GENERAL: safeArea.bottom,
    FOOTER: `calc(${height.FOOTER}px + ${safeArea.bottom})`,
  },
  width: {
    'FOOTER/SUBMIT_BUTTON--MOBILE': 144,
    'FOOTER/SUBMIT_BUTTON--TABLET': 400,
  },
  stepper: {
    MAX: 250,
  },
  image: {
    'MENU/IMAGE_WIDTH': 1125,
    EMPTY_IMAGE: 'https://img.dimorder.com/300x/public/logo/default_food_pic.jpeg',
  },
  snackbar: {
    OPTIONS: {
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
    },
  },
  safeArea: safeArea,
  STOREDELIVERY_TIMESLOT_DURATION: 30, // minutes
  TAKEAWAY_TIMESLOT_DURATION: 15, // minutes
  TIMESLOT_DURATION: { days: 7, start: 0, startMin: 0, end: 24, endMin: 0, duration: 30 }, // 不指定merchant時使用
  PINCODE_COUNT_DOWN_LIMIT: 60,
  NEARBY_KM: 6,
  TAKEAWAY_NEARBY_KM: 1,
  ERRORS: {
    SUBMIT_BTN_DISABLED: {
      SYSTEM_DISABLED: 'SYSTEM_DISABLED',
      CANT_SHIP: 'CANT_SHIP',
      DIMBOX_NOT_ENOUGH_BOXES: 'DIMBOX_NOT_ENOUGH_BOXES',
      NO_BATCH: 'NO_BATCH',
      UNCONFIRMED_BATCH: 'UNCONFIRMED_BATCH',
      UNCOMPLETED_SET: 'UNCOMPLETED_SET',
      AMOUNT_TOO_SMALL: 'AMOUNT_TOO_SMALL',
      LOADING: 'LOADING',
      LOADING_CALCULATION: 'LOADING_CALCULATION',
    },
  },
}
