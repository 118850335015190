const paymentMethod = {
  CASH: 'cash',
  BYPASS: 'bypass',
  RECENT: 'recent',
  CREDIT_CARD: 'creditcard',
  APPLE_PAY: 'applepay',
  GOOGLE_PAY: 'googlepay',
  WECHAT_PAY: 'wechatpay',
  ALI_PAY: 'alipay',
  FPS: 'fps',
  PAY_ME: 'payme',
  VISA: 'visa',
  MASTER: 'master',
  AE: 'ae',
  JCB: 'jcb',
  UNIONPAY: 'unionpay',
  WECHAT: 'wechat',
  UNIONPAY_QR: 'unionpayQR',
  OCTOPUS: 'octopus',
  TNG: 'tng',
  GIFT_CARD: 'gift-card',
}

export const supportedIssuersFor2c2p = [
  paymentMethod.VISA,
  paymentMethod.MASTER,
]

export const tokenType2c2p = {
  SECURE_PAY_TOKEN: 'securePayToken',
  CARD_TOKEN: 'cardToken',
}

export default paymentMethod
