
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useSplashAd } from '@/hooks/queries/useLandingSettingsQuery'

import Appbar from '@/components/Appbar'
import Banner from '@/components/Banner'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'

import DeliveryTypeButtons from './Components/DeliveryTypeButtons'
import MemberActionButtons from './Components/MemberActionButtons'

export default function Home (props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isInit = useSelector(state => state.app.isInit)
  const isTimeZoneError = useSelector(state => state.app.isTimeZoneError)
  const { isD2CWeb } = useSelector(state => state.app.params)
  const splashAd = useSplashAd()

  React.useEffect(() => {
    if (
      isInit &&
      !isD2CWeb &&
      !isTimeZoneError && // CA-1283 當 timezone 非 +8 時區時，優先顯示 timezone 的 alert，不顯示 SplashAD
      splashAd
    ) {
      dispatch(actions.app.toggleDialog('splashAd', true))
    }
  }, [isInit, splashAd, isD2CWeb])

  if (!isInit) {
    // 還沒 init 時可能會先進入首頁，但根據網址帶的參數可能目的地不是首頁，因此先不 render 首頁
    return null
  }

  if (isD2CWeb) {
    // 當由 https://c.dimorder.com?isD2CWeb=true&m=xxx 進入時因為 router 關係會先進入首頁，不需 render 平台模式的內容，之後會被導向 D2C 餐廳首頁
    return null
  }

  return (
    <Page>
      <Appbar />
      <PageContainer className={classes.container}>
        <div className={classes.banner}>
          <Banner />
        </div>
        <DeliveryTypeButtons />
        <MemberActionButtons />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.APPBAR,
    // paddingBottom: constants.app.marginTop.APPBAR,
  },
  banner: {
    marginTop: theme.spacing(1),
  },
}))
