import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'

/**
 * @param {{
 * lang: string,
 * selected: boolean,
 * onClose: function,
 * }} props
 */
export default function LangOption (props) {
  const { lang, text, selected, onClose } = props
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, selected })
  const currentLang = useSelector(state => state.app.lang)

  const changeLanguage = async (lang) => {
    if (lang !== currentLang) {
      await dispatch(actions.app.updateLang(lang))
      dispatch(actions.landing.resetFilter()) // 重置餐廳篩選
      dispatch(actions.landing.resetSearchInput()) // 重置餐廳搜尋
      dispatch(actions.orderHistory.getOrders()) // 更新訂單
    }
    onClose()
  }

  return (
    <MenuItem onClick={() => changeLanguage(lang)}>
      <Typography className={classes.optionText}>{text}</Typography>
    </MenuItem>
  )
}

const useStyles = makeStyles(theme => ({
  optionText: {
    minWidth: 200,
    fontSize: '1.6rem',
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    fontWeight: props => props.selected && 'bold',
    color: props => props.selected && theme.palette.warning.main,
  },
}))
