import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function EditAvatar (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g transform='translate(-872 -2788) translate(872 2788)'>
            <circle cx='16' cy='16' r='12' fill='#202020' />
            <path fill='#FFF' fillRule='nonzero' d='M10.04 19.095L10 21.652c-.002.191.153.348.344.348h.009l2.537-.062c.043-.003.086-.02.116-.05l8.944-8.925c.067-.066.067-.176 0-.243l-2.673-2.67c-.034-.035-.077-.05-.123-.05-.045 0-.088.017-.122.05l-8.942 8.924c-.03.032-.05.075-.05.12z' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
