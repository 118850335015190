import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

history.pushWithSearch = (url, state) => {
  history.push(url + history.location.search, state)
}

history.replaceWithSearch = (url, state) => {
  history.replace(url + history.location.search, state)
}

export default history
