import VConsole from 'vconsole/dist/vconsole.min'

const vConsole = {
  vConsole: null,
}

vConsole.init = () => {
  const params = localStorage.getItem('params')
  if (params) {
    try {
      const { vc } = JSON.parse(params)
      if (vc && !vConsole.vConsole) {
        vConsole.vConsole = new VConsole()
      }
    } catch (error) {

    }
  }
}

export default vConsole
