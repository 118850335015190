import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import { ReactComponent as HotSaleIcon } from '@/assets/icons/category_hotsale.svg'
import MenuItemList from '@/pages/Menu/MenuItemList'

import CategoryList from './index'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

CategoryItem.propTypes = {
  item: PropTypes.object,
}

/**
 *
 * @param {{
 * item: IAppCategory
 * }} props
 */
export default function CategoryItem (props) {
  const { item: category } = props
  const classes = useStyles(props)

  const list = React.useMemo(() => {
    return [...category.sets, ...category.menus]
  }, [category.menus, category.sets])

  return (
    <>
      <div className={classes.categoryItem}>
        <div className={classes.categoryNameWrap}>
          {category.id === 'promoted-category' && <HotSaleIcon className={classes.promotedIcon} />}
          <Typography className={classes.name} variant='h5'>{category.name}</Typography>
        </div>
        <MenuItemList categoryId={category.id} list={list} />
      </div>

      {/* sub categories */}
      <CategoryList list={category.categories} />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  categoryItem: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gap: theme.spacing(3),
    paddingBottom: theme.spacing(4), // 改這個需要改 CategoryLink 的 CATEGORY_ITEM_PADDING_BOTTOM
  },
  categoryNameWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  name: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  promotedIcon: {
    marginRight: 4,
  },
}))
