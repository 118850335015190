import { geocodeByLatLng } from 'react-google-places-autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import CenterMarker from '@/pages/OrderTracking/OrderTrackingMap/markers/CenterMarker'
import constants from '@/constants'
import hooks from '@/hooks'
import libs from '@/libs'

AddressSelectMap.propTypes = {
  zoom: PropTypes.number,
  location: PropTypes.object,
  center: PropTypes.object,
  setLocation: PropTypes.func,
  setAddress: PropTypes.func,
  setCountry: PropTypes.func,
  setZoom: PropTypes.func,
}

/**
 *
 * @param {{
 * zoom?: Number,
 * location?: ILatLng
 * center?: ILatLng
 * setLocation?: Function
 * setAddress?: Function
 * setCountry?: Function
 * setZoom?: Function
 * }} props
 */
export default function AddressSelectMap (props) {
  const { t } = useTranslation()
  const addressTemp = hooks.user.useAddressTemp()
  const defaultProps = {
    zoom: addressTemp.address ? 18 : 12,
    location: { lat: 22.290061251822266, lng: 114.17419455213064 },
  }
  const {
    zoom = defaultProps.zoom,
    center = props.center ?? props.location ?? defaultProps.location,
    setLocation,
    setAddress,
    setCountry,
    setZoom,
  } = props

  const dispatch = useDispatch()
  const classes = useStyles(props)

  const handleChange = async (event) => {
    setZoom(event.zoom)
  }

  const handleDrag = useCallback(_.debounce(async (e) => {
    // 當 latlng 改變時根據新的 laglng 去抓 address
    const latlng = { lat: e.center.lat(), lng: e.center.lng() }
    setLocation(latlng)
    try {
      const geocodeResults = await geocodeByLatLng(latlng)
      const country = libs.googleMap.getCountryInGeocodeResult(geocodeResults[0])
      console.log('address', geocodeResults[0]?.formatted_address)

      setAddress(geocodeResults[0]?.formatted_address)
      setCountry(country)
    } catch (error) {
      console.log('geocodeByLatLng error', error)
      switch (error) {
        case 'ZERO_RESULTS': {
          setAddress('')
          setCountry(null)
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.address_geocode_noresult.title'),
            message: t('app.component.alert.address_geocode_noresult.message'),
          }))
          break
        }
        case 'OVER_QUERY_LIMIT': {
          dispatch(actions.app.updateLoading('backdrop', true))
          setTimeout(async () => {
            try {
              const geocodeResults = await geocodeByLatLng(latlng)
              const country = libs.googleMap.getCountryInGeocodeResult(geocodeResults[0])

              setAddress(geocodeResults[0]?.formatted_address)
              setCountry(country)
              dispatch(actions.app.updateLoading('backdrop', false))
            } catch (error) {
              dispatch(actions.app.updateLoading('backdrop', false))
              console.log('retry geocodeByLatLng error', error)
            }
          }, 1200)
          // temporary hide OVER_QUERY_LIMIT alert
          // dispatch(actions.app.toggleAlert({
          //   title: t('app.component.alert.submit_too_fast.title'),
          //   message: t('app.component.alert.submit_too_fast.message'),
          // }))
          break
        }
        case 'REQUEST_DENIED':
        case 'INVALID_REQUEST':
        case 'UNKNOWN_ERROR':
        case 'ERROR':
        default: {
          setAddress('')
          setCountry(null)
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.address_geocode_error.title'),
            message: t('app.component.alert.address_geocode_error.message'),
          }))
          break
        }
      }
      // this.rendeGetCurrentPositionTimoutAlert()
    }
  }, 500), [])

  return (
    // ! Important! Always set the container height explicitly
    <div className={classes.mapWrap}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: constants.googleMap.API_KEY,
          libraries: ['places', 'geometry'],
        }}
        center={center}
        defaultZoom={defaultProps.zoom}
        zoom={zoom}
        onChange={handleChange}
        options={{
          fullscreenControl: false,
          zoomControl: false,
        }}
        // onBoundsChange={handleBoundsChange}
        onDragEnd={handleDrag}
      />
      <CenterMarker />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mapWrap: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}))
