import { useSelector } from '@/redux'

/**
 *
 * @returns {'en' | 'zh'}
 */
export default function useShortLang () {
  const lang = useSelector(state => state.app.lang)
  const shortLang = lang.split('-')[0]
  return shortLang
}
