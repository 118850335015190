import { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'

export function useWindowSize () {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set window width/height to state
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export function useMode () {
  const isWeb = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const isTablet = useMediaQuery(theme => theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  return { isWeb, isTablet, isMobile }
}
