import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {{ title: string, loading?: boolean }} props
 * @returns
 */
export default function SectionHeader (props) {
  const { title, loading } = props
  const classes = useStyles(props)
  if (loading) return <Skeleton variant='text' className={classes.sectionHeader} />
  return <Typography variant='h6' className={classes.sectionHeader}>{title}</Typography>
}

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.3,
    whiteSpace: 'pre-line',
    padding: theme.spacing(1.5, 1),
    margin: theme.spacing(0, 1),
    fontSize: '2rem',
  },
}))
