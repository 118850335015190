import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useBatchItemQuantity } from '@/hooks/contexts/BatchItemQuantityContext'
import BatchCountBadge from '../BatchCountBadge'
import React from 'react'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 *
 * @param {{
 * menu: IAppMenuItem | IAppSet
 * setItemMenu?: IAppMenuItem
 * isSold: boolean
 * }} props
 * @returns
 */
export default function OrderButton (props) {
  const { menu, setItemMenu, isSold } = props
  const classes = useStyles(props)
  const isSet = Boolean(menu.steps)
  const isSeperated = Boolean(menu.separatedStep)
  const batchItemQuantity = useBatchItemQuantity()
  const key = isSet ? (isSeperated ? menu.id + '/' + setItemMenu?.menuId : menu.id) : menu.id
  const quantity = batchItemQuantity[key]

  return (
    <ButtonBase
      className={classes.button}
      disabled={isSold}
    >
      點餐
      <BatchCountBadge quantity={quantity} />
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    width: 60,
    color: '#c61313',
    border: '1px solid #c61313',
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: 900,
  },
}))
