import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import RestaurantIcon from '@material-ui/icons/Restaurant'

import { actions, useDispatch } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import { useSelectedOrder } from '@/hooks/order'
import Switch from '@/components/Switch'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Tableware (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { isTable } = useDeliveryType()
  const selectedOrder = useSelectedOrder()
  const needTableware = selectedOrder?.needTableware
  const handleSwitchNeedTableware = () => {
    dispatch(actions.order.updateSelectedOrder('needTableware', !needTableware))
  }
  const helperText = needTableware ? t('app.page.checkout.info_card.tableware.helper.true') : t('app.page.checkout.info_card.tableware.helper.false')

  if (isTable) return null
  return (
    <ListItem className={classes.tableware}>
      <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
        <RestaurantIcon fontSize='small' className={classes.icon} />
      </ListItemIcon>
      <ListItemText
        primary={t('app.page.checkout.info_card.tableware.title')}
        secondary={helperText}
        classes={{
          primary: classes.listItemTextPrimary,
          secondary: classes.listItemTextSecondary,
        }}
      />
      <ListItemSecondaryAction>
        <Switch checked={needTableware} onChange={handleSwitchNeedTableware} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  tableware: {
    position: 'relative',
    // border: '1px solid #a8d8ff',
    // backgroundColor: '#f0f8ff',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `${theme.palette.info.light}`,
      opacity: 0.1,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: `1px solid ${theme.palette.info.light}`,
      borderRadius: theme.shape.borderRadius,
      opacity: 0.3,
    },
  },
  listItemIconRoot: {
    minWidth: 'unset',
    marginRight: theme.spacing(1),
  },
  listItemTextPrimary: {
    fontSize: '1.5rem',
  },
  listItemTextSecondary: {
    fontSize: '1.2rem',
    color: theme.palette.grey[500],
  },
  icon: {
    color: theme.palette.info.main,
  },
}))
