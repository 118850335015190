/**
   * 附近餐廳與所有餐廳分類為後端產生，並非由 landing 設定，所以名稱固定為
   * 1. nearby_list = 附近餐廳
   * 2. all_restaurants = 所有餐廳
   * @param {string} name
   * @param {TFunction} t
   * @returns
   */
export default function getCategoryName (name, t) {
  switch (name) {
    case 'nearby_list':
      return t('app.page.restaurant_list.nearby_list')
    case 'all_restaurants':
      return t('app.page.restaurant_list.all_restaurants')
    default:
      return name
  }
}
