import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import { IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef OrderBatchHeaderProps
 * @property {number} index
 * @property {string} createdAt
 * @property {boolean} [hidden=false]
 */

/**
 *
 * 若有多個 batches 則需分加入 batch header 作為分段顯示
 * 若只有一個或沒有 batch 則不顯示 batch header
 * @param {OrderBatchHeaderProps} props
 * @returns
 */
export default function OrderBatchHeader (props) {
  const { index, createdAt, hidden = false } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  if (hidden) return null
  return (
    <div className={classes.orderBatchHeader}>
      <Typography variant='subtitle1' className={classes.batchSerial}>#{index + 1}</Typography>
      <Typography variant='caption' color='textSecondary'>{moment(createdAt).format(t('app.common.date_time.format_year_date_time'))}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  orderBatchHeader: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, -1),
    backgroundColor: '#ff984333',
  },
  batchSerial: {
    fontWeight: 600,
  },
}))
