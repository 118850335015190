import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

/**
 *
 * @param {{
 * title: string
 * subtitle: string
 * onClick: () => void
 * imageProps: object
 * buttonProps?: object
 * titleProps?: object
 * subtitleProps?: object
 * }} props
 * @returns
 */
export default function DeliveryTypeButtonBase (props) {
  const {
    title,
    subtitle,
    onClick:
    handleClick,
    imageProps = {},
    buttonProps = {},
    titleProps = {},
    subtitleProps = {},
  } = props
  const classes = useStyles(props)

  return (
    <ButtonBase onClick={handleClick} className={classes.button} {...buttonProps}>
      <div className={classes.titleWrap}>
        <Typography className={clsx(classes.title, classes.font)} {...titleProps}>
          {title}
        </Typography>
        <Typography className={clsx(classes.subtitle, classes.font)} {...subtitleProps}>
          {subtitle}
        </Typography>
      </div>
      <img className={classes.image} {...imageProps} />
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    flex: 1,
    backgroundColor: 'rgb(255 209 101)',
    color: '#413230',
    borderRadius: 12,
    margin: theme.spacing(0.75),
    padding: theme.spacing(2),
    height: 150,
  },
  titleWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    height: '100%',
  },
  font: {
    fontFamily: 'Noto Sans HK, PingFang HK, Roboto, Helvetica, Arial, sans-serif',
  },
  title: {
    fontSize: '3.4rem',
    fontWeight: 'bold',
    color: '#3f3030',
    textShadow: '0 0.5px rgb(0 0 0 / 6%)',
    letterSpacing: 0,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '2.7rem',
    lineHeight: 1,
    color: '#3f3030',
    textShadow: '0 0.5px rgb(0 0 0 / 6%)',
    letterSpacing: 0,
    textAlign: 'left',
  },
  image: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
  },
}))
