import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import constants from '@/constants'

import AddressList from './AddressList'
import Navabr from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

export default function AddressSetting (props) {
  useOnStatusTapToPageTop()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)

  useEffect(() => {
    dispatch(actions.app.togglePopover('currentLocationTooFar', false))
  }, [])

  return (
    <Page>
      <Navabr title={t('app.page.setting_address.title')} />
      <PageContainer className={classes.container}>
        <AddressList />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    marginBottom: constants.app.marginBottom.GENERAL,
  },
}))
