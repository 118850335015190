import { useDatetime } from '../app'
import { useMemo } from 'react'
import { useSelector } from '@/redux'
import _ from 'lodash'
import moment from 'moment'

export const DEFAULT_PER_PAGE = 25
export const DEFAULT_PER_ROW = 8

export function useUserLatlng () {
  const latlng = useSelector(state => state.user?.address?.latlng)
  const lat = latlng?.lat
  const lng = latlng?.lng
  return { lat, lng }
}

export function useBaseQueryParams (options = {}) {
  const deliveryType = useSelector(state => state.app.params.deliveryType)
  const { lat, lng } = useUserLatlng()
  const { isImmediate, date, time } = useDatetime()
  const timestamp = isImmediate
    ? undefined // 即時的情況，不帶 timestamp
    : moment(`${date} ${time}`).toISOString() // 預約的情況，要帶 timestamp

  return {
    full: true,
    open: true,
    from: 'app',
    type: deliveryType,
    time: timestamp,
    deliveryType,
    timestamp,
    lat,
    lng,
    ...options,
  }
}

export function useFiltersQueryParams () {
  const selectedFilter = useSelector(state => state.landing.filter)
  const params = useMemo(() => {
    let i = 0
    const result = _.reduce(selectedFilter, (acc, filters) => {
      const key = 'filters' + `[${i}]`
      acc[key] = filters
      i += 1
      return acc
    }, {})
    return result
  }, [selectedFilter])
  return params
}

export function useSearchQueryParams (options = {}) {
  const baseParams = useBaseQueryParams()
  const filtersParams = useFiltersQueryParams()
  const searchText = useSelector(state => state.landing.searchText)
  return {
    ...baseParams,
    ...filtersParams,
    ...options,
    searchText,
    categories: ['all_restaurants'], // 從全部的餐廳分類內搜尋
  }
}
