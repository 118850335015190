export default {
  ADD_ITEM: 'TEMP_BATCH/ADD_ITEM',
  ADD_SET_ITEM: 'TEMP_BATCH/ADD_SET_ITEM',
  ADD_SET_ITEMS: 'TEMP_BATCH/ADD_SET_ITEMS',
  UPDATE_ITEM: 'TEMP_BATCH/UPDATE_ITEM',
  UPDATE_SET_ITEM: 'TEMP_BATCH/UPDATE_SET_ITEM',
  ADD_ITEM_OPTION: 'TEMP_BATCH/ADD_ITEM_OPTION',
  RESET_ITEM: 'TEMP_BATCH/RESET_ITEM',
  RSET_SET_ITEM: 'TEMP_BATCH/RSET_SET_ITEM',
  ADD_SET_ITEM_OPTION: 'TEMP_BATCH/ADD_SET_ITEM_OPTION',
  UPDATE_ITEM_OPTIONS: 'TEMP_BATCH/UPDATE_ITEM_OPTIONS',
  UPDATE_SET_ITEM_OPTIONS: 'TEMP_BATCH/UPDATE_SET_ITEM_OPTIONS',
  UPDATE_MODIFIER: 'TEMP_BATCH/UPDATE_MODIFIER',
  REMOVE_MODIFIER: 'TEMP_BATCH/REMOVE_MODIFIER',
}
