import { useSelector } from '@/redux'
import React from 'react'

// eslint-disable-next-line no-unused-vars
import { IUserState } from '@/redux/user/UserState.d'

/**
 * @returns {IUserState}
 */
export function useUser () {
  return useSelector(state => state.user)
}

/**
 * @returns {CustomerApiCustomerInfo}
 */
export function useMember () {
  return useSelector(state => state.user.member)
}

/**
 * 點單會員 id
 * @returns
 */
export function useMemberId () {
  const member = useMember()
  return member?.id || ''
}

/**
 * 是否登入點單會員
 * @returns {boolean}
 */
export function useIsLogin () {
  const member = useMember()
  return Boolean(member?.id)
}

/**
 * @returns {IAddress}
 */
export function useAddress () {
  // 避免沒有 address 時每次 useAddress 都 return 新的 {}
  const emptyAddressRef = React.useRef({})
  return useSelector(state => state.user.address) ?? emptyAddressRef.current
}

/**
 * @returns {IAddress[]}
 */
export function useAddressList () {
  // 避免沒有 addressList 時每次 useAddressList 都 return 新的 []
  const emptyAddressListRef = React.useRef([])
  return useSelector(state => state.user.addressList) ?? emptyAddressListRef.current
}

/**
 * @returns {IAddress}
 */
export function useAddressTemp () {
  // 避免沒有 addressTemp 時每次 useAddressTemp 都 return 新的 {}
  const emptyAddressRef = React.useRef({})
  return useSelector(state => state.user.addressTemp) ?? emptyAddressRef.current
}
