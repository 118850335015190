import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import opacity from '@/theme/opacity'

/**
 * @typedef SoldLayerProps
 * @property {boolean} sold
 */

/**
 * @param {SoldLayerProps} props
 * @returns
 */
export default function SoldLayer (props) {
  const { sold } = props
  const { t } = useTranslation()
  const soldoutText = t('app.common.sold_out')
  const classes = useStyles({ ...props, soldoutText })

  if (!sold) return null
  return (
    <div className={classes.soldLayer} />
  )
}

const useStyles = makeStyles(theme => ({
  soldLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '&:before': {
      // 售完背景
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: `${theme.palette.grey[600]}${opacity[70]}`,
    },
    '&:after': {
      // 售完文字
      zIndex: 200,
      content: props => `'${props.soldoutText}'`,
      position: 'absolute',
      display: 'grid',
      placeContent: 'center',
      color: theme.palette.grey[100],
      top: 0,
      left: 0,
      width: 80, // 圖片的大小
      height: 80, // 圖片的大小
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
  },
}))
