import { AppsFlyer } from 'appsflyer-capacitor-plugin'
import { Capacitor } from '@capacitor/core'
import React from 'react'
import ReactDOM from 'react-dom'
import 'moment/locale/ja'
import 'moment/locale/zh-hk'
import 'moment/locale/zh-tw'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { gaInstance } from '@/hooks/useTrack.js'
import ErrorBoundary from '@/components/ErrorBoundary'
import constants from '@/constants'
import logger from '@/libs/logger'
import vConsole from '@/libs/vConsole'
import '@/i18n'
import '@/libs/FirebaseAnalytics'
import '@/libs/sentry'

import App from './App.jsx'
import reportWebVitals from './reportWebVitals'
import './index.css'

vConsole.init()

;(async () => {
  try {
    await gaInstance.initialize()
  } catch (error) {
    console.log(error)
  }

  try {
    const platform = Capacitor.getPlatform()
    if (platform === 'android' || platform === 'ios') {
      const rt = await AppsFlyer.initSDK({
        appID: constants.track.IOS_APP_ID,
        devKey: constants.track.APPSFLYER_KEY,
        isDebug: process.env.NODE_ENV === 'development',
      })

      console.log('init appsflyer')
      console.log(rt)
    }
  } catch (error) {
    console.log('init appsflyer error')
    console.log(error)
  }

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()

window.addEventListener('error', event => {
  logger.error(`window error ${event.message}`, { event })
})
window.addEventListener('unhandledrejection', event => {
  logger.error(`unhandledrejection error ${event.reason}`, { event })
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
