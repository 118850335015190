import { validate as isUUID } from 'uuid'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import eachDeep from 'deepdash/eachDeep'
import filterDeep from 'deepdash/filterDeep'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { getStepQuantityMax } from '@/libs/order'
import { useAppLocale, useDatetime, useDeliveryType, useParams } from '@/hooks/app'

import { useMerchant } from '@/hooks/merchant'
import dimorderApi from '@/libs/api/dimorder'

/* eslint-disable no-unused-vars */
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'
import { ICategory } from 'dimorder-orderapp-lib/dist/types/Category'
/* eslint-enable no-unused-vars */

const DEFAULT_STALE_TIME = 3 * 60 * 1000 // 3 mins

export function useMenuQuery () {
  const dispatch = useDispatch()
  const merchantId = useMerchant().id
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const locale = useAppLocale()
  const { categoryTag, categoryTagId } = useCategoryTag()
  const menuTime = useMenuTime()
  const staleTime = DEFAULT_STALE_TIME
  const enabled = !!merchantId
  const queryKey = isImmediate
    ? ['menu', `merchantId:${merchantId}`, { locale, deliveryType, categoryTag, categoryTagId, isImmediate }]
    : ['menu', `merchantId:${merchantId}`, { locale, deliveryType, categoryTag, categoryTagId, date, time }]
  const queryFn = () => fetchFn()
  async function fetchFn () {
    const responseCategories = await dimorderApi.category.getCategories({
      merchantId,
      deliveryType,
      datetime: menuTime,
      categoryTag,
      categoryTagId,
    })
    const result = await fetchMenus(responseCategories, { merchantId, deliveryType, datetime: menuTime })
    dispatch(actions.orderBatch.updateBatchItemsAfterMenuFetched(result.menus, result.sets))
    return result
  }
  const query = useQuery({ queryKey, queryFn, enabled, staleTime })
  return query
}

/**
 * 建立 flattenCategories 方便 get menus
 * @param {ICategory[]} categories API 給的第一層 categories
 * @returns
 */
function flattenCategories (categories) {
  /** @type {IAppCategory[]} */
  const result = []
  // 建立 flattenCategories 方便 get menus
  eachDeep(
    categories,
    (category, key, parentCategory, context) => {
      // 重新判斷 isSet（用來過濾舊版在 category 的套餐）
      category.isSet = category.isSet && category.steps.length > 0 && category.categories.length === 0
      if (!category.isSet) { category.steps = [] }
      // 放到 flattenCategories
      result.push(category)
    },
    { childrenPath: 'categories' },
  )
  return result
}

/**
 * 如果第一步是單點則設定 separatedStep 為第一步的 id
 * 如果以後要擴充成透過 api 指定步驟的話會比較方便
 * 前端其他的地方可以用 separatedStep 去判斷
 * @param {IAppSet} set
 * @returns {string}
 */
function findSeparatedStep (set) {
  if (set.steps.length === 0) {
    return undefined
  }
  const firstStep = set.steps[0]
  const firstStepMax = getStepQuantityMax(undefined, firstStep)
  const isMultiple = firstStepMax > 1

  if (!isMultiple) {
    return firstStep?.id
  }
  return undefined
}

async function fetchMenus (categories, { merchantId, deliveryType, datetime }) {
  const flattenedCategories = flattenCategories(categories)

  // get menus
  await Promise.all(flattenedCategories.map(async category => {
    const [menus, sets] = await Promise.all([
      dimorderApi.menu.getCategoryMenus(merchantId, category.id, deliveryType, datetime).catch(() => []),
      dimorderApi.menu.getCategorySets(merchantId, category.id, deliveryType, datetime).catch(() => []),
    ])
    for await (const set of sets) {
      set.menus = await dimorderApi.menu.getSetMenus(merchantId, set.id, deliveryType, datetime) || []
    }
    category.menus = menus
    category.sets = _.filter(sets, set => set.menus.length > 0)
  }))

  // 過濾空的和不該在單點目錄的分類
  const filteredCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SET') {
        // 是個套餐目錄，一定不留
        return false
      }

      if (category.menus.length > 0) {
        // 有 menu，一定要留
        return true
      } else if (category.categories.length === 0) {
        // 沒 menu 也沒 categories，一定不留
        return false
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []

  // 過濾空的和不該在套餐目錄的分類
  const filteredSetCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SINGLE') {
        // 是個單點目錄，一定不留
        return false
      }

      if (category.sets.length > 0) {
        // 有 set，一定要留
        return true
      } else if (category.categories.length === 0) {
        // 沒 set 也沒 categories，一定不留
        return false
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []

  const rootCategory = {
    id: 'ROOT',
    name: '目錄',
    parentId: null,
    categories: filteredCategories,
    menus: [],
    sets: [],
    path: 'ROOT',
  }
  const rootSetCategory = {
    id: 'SET_ROOT',
    name: '套餐',
    parentId: null,
    categories: filteredSetCategories,
    menus: [],
    sets: [],
    isInSetCategories: true,
    path: 'SET_ROOT',
  }

  /** @type {{[id: string]: IAppCategory}} */
  const categoriesMap = {
    ROOT: rootCategory,
    SET_ROOT: rootSetCategory,
  }
  const menus = {}
  const sets = {}

  // create categoriesMap, set parentId
  eachDeep(
    rootCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'ROOT.categories' + context.path

      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'ROOT'
      }
      category.isInSetCategories = false
      categoriesMap[category.id] = category
      category.menus.forEach(menu => {
        menu.menuId = menu.id
        menu.path = path
        menus[menu.id] = menu
      })
      category.sets.forEach(set => {
        sets[set.id] = set
        set.path = path
        set.isSet = true
        set.categoryId = category.id
        set.steps.forEach(step => {
          step.id = step.key
          step.parentId = parentCategory.id
          step.setId = set.id
          step.isSetStep = true
        })
        set.menus.forEach(menu => {
          menu.stepIndex = set.steps.findIndex(step => step.key === menu.step) // 從套餐步驟中找出正確的 stepIndex
          menu.path = path
          menus[menu.id] = menu
        })
        set.separatedStep = findSeparatedStep(set)
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )

  eachDeep(
    rootSetCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'SET_ROOT.categories' + context.path

      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'SET_ROOT'
      }
      category.isInSetCategories = true
      categoriesMap[category.id] = category
      category.menus = [] // 將套餐目錄中的 menu 清空
      category.sets.forEach(set => {
        sets[set.id] = set
        set.path = path
        set.isSet = true
        set.categoryId = category.id
        set.steps.forEach(step => {
          step.id = step.key
          step.parentId = parentCategory.id
          step.setId = set.id
          step.isSetStep = true
        })
        set.menus.forEach(menu => {
          menu.stepIndex = set.steps.findIndex(step => step.key === menu.step) // 從套餐步驟中找出正確的 stepIndex
          menu.path = path
          // 只有套餐內有東西是推薦 把套餐設成推薦
          if (menu.promoted) {
            set.promoted = true
          }
          menus[menu.id] = menu
        })
        set.separatedStep = findSeparatedStep(set)
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )

  return { categoriesMap, rootCategory, rootSetCategory, menus, sets }
}

/**
 *
 * @returns {{ categoryTag: string | undefined, categoryTagId: string | undefined }}
 */
function useCategoryTag () {
  // merchant 開桌時會把 categoryTag id 放入 order.categoryTag，應優先使用
  // 活動 url 會帶 categoryTag name 到 params 中，例如盆菜
  // 但是 request getCategories 時若 categoryTag 是 id 需要帶在 categoryTagId，name 需要帶在 categoryTag
  const orderCategoryTag = useSelector(state => state.order.selectedOrder)?.categoryTag
  const paramsCategoryTag = useSelector(state => state.app.params.categoryTag)
  const categoryTag = orderCategoryTag || paramsCategoryTag || ''
  const result = {
    categoryTag: undefined,
    categoryTagId: undefined,
  }
  // 根據 _categoryTag 是不是 uuid 決定要把 categoryTag 放在 categoryTag 還是 categoryTagId，另一個就保持 undefined 就不會帶過去
  if (isUUID(categoryTag)) {
    result.categoryTagId = categoryTag
  } else {
    result.categoryTag = categoryTag
  }
  return result
}

function useMenuTime () {
  const { isStoreDelivery } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const restaurant = useSelector(state => state.landing.restaurant)
  const minMinutes = restaurant?.pickerRange?.minMinutes ?? 0
  const shipping = useSelector(state => state.order.shipping)
  const shippingTime = isStoreDelivery ? shipping?.shippingTime : 0 ?? 0 // 報價回來運送分鐘 shippingTime
  // 用 pickupAt 的時間扣掉備餐、外送等時間才是抓 menu 的時間
  const pickupAtMenuTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').subtract(minMinutes + shippingTime, 'minutes')
  const menuDatetime = isImmediate
    ? moment() // 即時使用現在時間
    : pickupAtMenuTime // 非即時，有指定 pickupAt，直接使用 pickupAtMenuTime
  return menuDatetime.isValid() ? menuDatetime.format() : moment().format()
}

export function useCategories () {
  const { isD2CWeb } = useParams()
  const customerLayout = useSelector(state => state.merchant.data?.setting?.customerLayout)
  const isLama = isD2CWeb && customerLayout === 'LAMA'
  const { data, isLoading } = useMenuQuery()
  const promotedCategories = usePromotedCategory()
  /** @type {IAppCategory[]} */
  let categories = []
  if (data) {
    const singleCategories = data.rootCategory.categories
    const setCategories = data.rootSetCategory.categories
    categories = categories.concat(promotedCategories)
    if (singleCategories && setCategories) {
      if (isLama) {
        // LaMaMenu 分類顯示先單點後套餐
        categories = categories.concat(singleCategories).concat(setCategories)
        // CA-1387 雜項放最底下
        const index = _.findIndex(categories, category => category.name === '雜項')
        if (index !== -1) {
          const el = categories.splice(index, 1)[0]
          categories.push(el)
        }
      } else {
        // 正常 Menu 分類顯示先套餐後單點
        categories = categories.concat(setCategories).concat(singleCategories)
      }
    }
  }
  return { data: categories, isLoading: isLoading }
}

function usePromotedCategory () {
  const { t } = useTranslation()
  const { data } = useMenuQuery()
  const menuTime = useMenuTime()
  const promotedCategories = []
  if (data) {
    const promotedMenus = filterPromoted(data.menus, menuTime)
    const promotedSets = filterPromoted(data.sets, menuTime)
    /** @type {IAppCategory} */
    const promotedCategory = {
      id: 'promoted-category',
      name: t('app.page.home.category_bar.promoted'),
      categories: [],
      menus: promotedMenus,
      sets: promotedSets,
    }
    const hasPromotedCategory = !_.isEmpty(promotedMenus) || !_.isEmpty(promotedSets)
    if (hasPromotedCategory) {
      promotedCategories.push(promotedCategory)
    }
  }
  return promotedCategories
}

/**
 * 找出 menus 或 sets 中 promoted 的單點餐點
 * 如有設定 weekday，需符合設定的 weekday
 * 如有設定 timerange，需符合設定的 timerange
 * @param {IAppMenuItem[] | IAppSet[]} menus
 * @param {string} menuTime
 * @returns {IAppMenuItem[] | IAppSet[]}
 */
function filterPromoted (menus, menuTime) {
  const datetime = moment(menuTime)
  const weekday = datetime.weekday()

  const promotedMenus = _(menus)
    .filter(menu => {
      const { promoted = false, promotedDays = [], promotedTimerange = [] } = menu

      if (!promoted) {
        // 沒有設定推薦 = 都不推薦
        return false
      }

      if (_.isEmpty(promotedDays) && _.isEmpty(promotedTimerange)) {
        // 沒有推薦日期 + 沒有推薦時段 = 都要推薦
        return true
      }

      if (_.includes(promotedDays, weekday) && _.isEmpty(promotedTimerange)) {
        // 有推薦日期 + 沒有推薦時段 = 符合日期時推薦
        return true
      }

      if (
        // 無推薦日期 + 有推薦時段 = 符合時間時推薦
        _.isEmpty(promotedDays) ||
      // 有推薦日期 + 有推薦時段 = 符合日期與時間時推薦
      _.includes(promotedDays, weekday)
      ) {
        const range = promotedTimerange
        const start = _.isEmpty(range[0])
          ? datetime.clone().startOf('d') // 當開始時間沒有設定時給預設值
          : datetime.clone().hour(range[0].hour).minute(range[0].minute).second(range[0].second)
        const end = _.isEmpty(range[0])
          ? datetime.clone().endOf('d')// 當開始時間沒有設定時給預設值
          : datetime.clone().hour(range[1].hour).minute(range[1].minute).second(range[1].second)
        const result = datetime.isBetween(start, end, undefined, '[)')
        return result
      }
      return false
    })
    .orderBy(['promotedWeight'], ['desc'])
    .value()
  return promotedMenus
}
