// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import _ from 'lodash'

import constants from '@/constants'

const { TAKEAWAY } = constants.deliveryType

/**
 *
 * @param {IAppOrder} order
 * @returns
 */
export function checkIsDimboxOrder (order) {
  return _.some(order?.tags, tag => tag?.name?.toLowerCase() === 'dimbox')
}

export function checkIsDimbox (isDimboxEnable, isTakeawayEnable, hasDimboxTag, deliveryType) {
  const isTakeaway = deliveryType === TAKEAWAY
  return isDimboxEnable && isTakeawayEnable && hasDimboxTag && isTakeaway
}
