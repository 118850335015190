import { useBaseQueryParams } from './params'
import { useDatetime, useDeliveryType } from '../app'
import { useQuery } from '@tanstack/react-query'
import landingApi from '@/libs/api/landing'

export function useLandingCuisinesQueryOptions () {
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const cuisineQueryParams = useBaseQueryParams()
  const queryKey = isImmediate
    ? ['landing', 'cuisines', { deliveryType, isImmediate }]
    : ['landing', 'cuisines', { deliveryType, date, time }]
  const queryFn = () => landingApi.fetchCuisines(cuisineQueryParams)
  const select = (data) => data.filter(cuisine => cuisine?.total > 0) // 篩掉餐廳數量為 0 的菜式，避免顯示沒有任何餐廳的菜式卡
  const staleTime = 3 * 60 * 1000 // 3 mins landing 的菜式設定資料不應會太頻繁的改變
  return { queryKey, queryFn, select, staleTime }
}

/**
 * 用於餐廳列表頁面的菜式列表
 * @returns
 */
export function useLandingCuisinesQuery () {
  const options = useLandingCuisinesQueryOptions()
  const query = useQuery(options)
  return query
}
