import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { useMerchantNotices } from '@/hooks/queries/merchant/useMerchantNoticesQuery'
import NoticeCard from '@/components/Cards/NoticeCard'

/**
 *
 * @param {*} props
 * @returns
 */
export default function NoticeCardList (props) {
  const classes = useStyles(props)
  const { notices } = useMerchantNotices()

  if (_.isEmpty(notices)) return null
  return (
    <ul className={classes.noticeCardList}>
      {_.map(notices, (notice, index) => (
        <NoticeCard
          key={index}
          variant={notice?.variant}
          message={notice?.message}
          onClick={notice?.onClick}
        />
      ))}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  noticeCardList: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0.5, 1.5),
    gap: theme.spacing(1.5),
  },
}))
