// eslint-disable-next-line no-unused-vars
import { ButtonBase, ButtonProps, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useLangLetterSpacing } from '@/hooks/useLangLetterSpacing'
import { useMember } from '@/hooks/user'
import { useMerchant } from '@/hooks/merchant'

import MemberAvatar from '@/components/MemberAvatar'

// eslint-disable-next-line no-unused-vars
import { Group } from 'dimorder-orderapp-lib/dist/types/CRM'

export const CRM_GRADIENT_BUTTON = { height: 75 }
const AVATAR_SIZE = 52

/**
 *
 * @param {{ isCRMMember: boolean, isLogin: boolean }} props
 * @returns
 */
export function CRMRegisterButton (props) {
  const { isLogin, isCRMMember } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const letterSpacing = useLangLetterSpacing({ zh: '0.75rem' })
  function handleClick () {
    if (!isLogin) {
      // not login yet
      // open login drawer
      dispatch(actions.app.toggleDrawer('login', true))
      return
    }
    if (!isCRMMember) {
      // login but not a CRM member
      // open CRM member register dialog
      dispatch(actions.app.toggleDialog('crmRegister', true))
    }
  }
  return (
    <ButtonBase className={classes.container} onClick={handleClick}>
      <Typography variant='h5' className={classes.bold} style={{ letterSpacing }}>
        {t('app.page.crm.join_now')}
      </Typography>
    </ButtonBase>
  )
}

/**
 *
 * @param {{ group: Group }} props
 * @returns
 */
export function CRMRedirectButton (props) {
  const { group } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const history = useHistory()
  const merchant = useMerchant()
  const letterSpacing = useLangLetterSpacing({ zh: '0.75rem' })
  function handleClick () {
    history.push(`/d2c/${merchant.id}/membership/${group?.id}`) // 前往 CRM 會員頁面
  }
  return (
    <ButtonBase className={classes.container} onClick={handleClick} {...props}>
      <Typography variant='h5' className={classes.bold} style={{ letterSpacing }}>
        {t('app.page.crm.go_to_membership_page')}
      </Typography>
    </ButtonBase>
  )
}

/**
 *
 * @param {{ group: Group }} props
 * @returns
 */
export function CRMMemberCardButton (props) {
  const { group } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const history = useHistory()
  const merchant = useMerchant()
  const member = useMember()
  function handleClick () {
    history.push(`/d2c/${merchant.id}/membership/${group?.id}`) // 前往 CRM 會員頁面
  }
  return (
    <ButtonBase className={classes.container} onClick={handleClick} {...props}>
      <div className={classes.memberCard}>
        <MemberAvatar avatarProps={{ style: { marginRight: 16, width: AVATAR_SIZE, height: AVATAR_SIZE } }} />
        <div className={classes.memberDetail}>
          <Typography className={classes.bold}>{member.name}</Typography>
          <Typography variant='caption'>{t('app.page.crm.crm_available_points')}・
            <Typography variant='caption' style={{ color: '#B03475' }}>
              {group?.points} {t('app.page.crm.crm_points')}
            </Typography>
          </Typography>
          <ArrowForwardIosIcon className={classes.icon} />
        </div>
      </div>
    </ButtonBase>
  )
}

export function LoadingComponent (props) {
  const classes = useStyles(props)
  return (
    <ButtonBase className={classes.container} disabled {...props}>
      <CircularProgress size={36} style={{ visibility: 'initial', color: 'inherit' }} />
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    background: 'linear-gradient(93.06deg, #E9C532 33.12%, #DA7530 148.79%)',
    borderRadius: 10,
    textAlign: 'start',
  },
  memberCard: {
    flex: 1,
    display: 'flex',
  },
  memberDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    position: 'absolute',
    right: theme.spacing(4),
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))
