import { IconButton, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import AnimatedNumber from 'react-animated-number'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import constants from '@/constants'

export default function RiceCoinInfo (props) {
  const { t } = useTranslation()
  const { balance } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const discountAmount = Math.floor(balance / constants.riceCoin.RATIO)
  const usedRiceCoin = discountAmount * constants.riceCoin.RATIO
  const [animatedBalance, setAnimatedBalance] = React.useState(0)

  React.useEffect(() => {
    setAnimatedBalance(balance)
  }, [balance])

  const onClickInfoButton = () => {
    dispatch(actions.app.toggleAlert({
      title: t('app.page.riceCoin.ricecoin_info.title'),
      renderMessage: () => {
        return (
          <ul>
            <li className={classes.numberedItem}>
              <div className={classes.number}>
                1
              </div>
              <span>
                {t('app.page.riceCoin.ricecoin_info.rule_1')}
              </span>
            </li>
            <li className={classes.numberedItem}>
              <div className={classes.number}>
                2
              </div>
              <span>
                {t('app.page.riceCoin.ricecoin_info.rule_2')}
              </span>
            </li>
          </ul>
        )
      },
      messageAlign: 'left',
      button: { text: t('app.common.confirm') },
    }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.amountWrap}>
        <Typography className={classes.amount}>
          <AnimatedNumber
            initialValue={0}
            stepPrecision={0}
            value={animatedBalance}
            duration={1000}
          />
          <IconButton
            className={classes.infoButton}
            onClick={onClickInfoButton}
          >
            <InfoOutlinedIcon fontSize='small' className={classes.icon} />
          </IconButton>
        </Typography>

      </div>
      <Typography variant='caption' className={classes.text}>
        {discountAmount
          ? t('app.page.riceCoin.subtitle', { usedRiceCoin, discountAmount })
          : 'RiceCoin'}
      </Typography>
    </div>

  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amountWrap: {
    display: 'flex',
  },
  amount: {
    fontSize: '4.8rem',
    fontWeight: 'bold',
    position: 'relative',
  },
  infoButton: {
    position: 'absolute',
  },
  numberedItem: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  number: {
    backgroundColor: theme.palette.text.primaryLight,
    width: '22px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: theme.spacing(0, 1),
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  text: {
    marginTop: theme.spacing(1) * -1,
    zIndex: 'inherit',
  },
}))
