import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import RowBase from './RowBase'

TotalRow.propTypes = {
  value: PropTypes.number,
}

/**
 *
 * @param {{
 * value: number
 * }} props
 * @returns
 */
export default function TotalRow (props) {
  const { value, isLoading } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <RowBase
      field={t('app.component.card.payment_summary_card.total')}
      value={value}
      customClasses={{
        field: classes.totalRowField,
        value: classes.totalRowValue,
      }}
      isLoading={isLoading}
    />
  )
}

const useStyles = makeStyles(theme => ({
  totalRowField: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  totalRowValue: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    '&:before': {
      content: '"HK$"',
      marginRight: theme.spacing(1),
    },
  },
}))
