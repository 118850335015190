import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'
import store from '@/redux/store'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrder, IAppOrderBatch, IFormatedBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IAppMenuItem, IAppSet, ISetStep } from 'dimorder-orderapp-lib/dist/types/Menu'
import { IShipping } from 'dimorder-orderapp-lib/dist/types/Merchant'
/* eslint-enable no-unused-vars */

/** @type {() => IRootState} */
const getState = store.getState

/**
 * @param {IAppBatchItem} item
 * @returns {IFormatedBatchItem}
 */
export function getDisplayBatchItem (item) {
  const selectedOrder = getState().order.selectedOrder
  return orderappLibs.calculateLocalBatchItem(orderappLibs.formatBatchItemStatus(item), selectedOrder)
}
/**
 * 計算套餐內的所選步驟的餐點總數量
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @returns {number}}
 */
export function calculateStepQuantityTotal (item, setStep) {
  const setItems = item?.setItems
  const stepQuantityTotal = _.reduce(setItems, (sum, setItem) => {
    if (setItem?.step === setStep?.id) {
      return sum + setItem?.quantity
    }
    return sum
  }, 0)
  return stepQuantityTotal
}

/**
 * 計算套餐內的所選步驟的餐點總數量上限
 * @param {ISetStep} setStep
 * @returns {number}}
 */
export function getStepQuantityMax (setStep) {
  const stepQuantityLimit = setStep && (setStep?.max || setStep?.min || 1)
  return stepQuantityLimit || 1
}

/**
 * 計算套餐內的所選步驟的餐點總數量上限
 * @param {ISetStep} setStep
 * @returns {number}}
 */
export function getStepQuantityMin (setStep) {
  if (setStep.optional) return 0
  const stepQuantityLimit = setStep && (setStep?.min || 1)
  return stepQuantityLimit || 1
}

/**
 * 判斷套餐內的所選步驟的餐點總數量是否已達步驟上限
 * @param {IAppSet} set
 * @param {ISetStep} setStep
 * @returns {boolean}
 */
export function calculateStepIsMax (set, setStep) {
  if (set?.isSet) {
    const stepQuantityMax = getStepQuantityMax(setStep)
    const stepQuantityTotal = calculateStepQuantityTotal(set, setStep)
    const isMax = Boolean(stepQuantityMax) && stepQuantityTotal >= stepQuantityMax
    return isMax
  }
}

/**
 * @param {IAppSet} batchItemTemp
 * @param {ISetStep} setStep
 * @param {IAppBatchItem} setItem
 * @returns {boolean}
 */
export function calculateSetItemIsMax (batchItemTemp, setStep, setItem) {
  if (batchItemTemp?.isSet) {
    const max = setItem.max || setStep?.max || 0 // 當 setItem.max 是 0 時改用 step.max 做判斷
    const setItems = batchItemTemp?.setItems
    const setItemQuantity = _.reduce(setItems, (acc, current) => {
      return current.id === setItem.id ? acc + current?.quantity : acc // 不同的 step 內可以有相同 setItem.menuId，但他們的 setItem.id 是不重複的
    }, 0)
    return setItemQuantity >= max
  }
}

/**
 * 判斷套餐內的所選步驟的餐點總數量是否已完成
 * @param {IAppSet} set
 * @param {ISetStep} setStep
 * @returns {boolean}
 */
export function calculateStepIsComplete (set, setStep) {
  if (set?.isSet) {
    const stepQuantityMin = getStepQuantityMin(setStep)
    const stepQuantityTotal = calculateStepQuantityTotal(set, setStep)
    const isComplete = stepQuantityTotal >= stepQuantityMin
    return isComplete
  }
}

/**
 * 判斷選項是否完成
 * @param {IAppBatchItem} batchItem
 * @param {IAppMenuItem} menu
 * @returns {boolean}
 */
export function isOptionsComplete (batchItem, menu) {
  let isComplete = true
  _.forEach(menu.options, menuOptionGroup => {
    if (menuOptionGroup.min > 0) {
      const optionGroupOptions = _.filter(batchItem.options, optionGroup => optionGroup.optionGroupId === menuOptionGroup.id)
      const optionGroupQuantity = _.sumBy(optionGroupOptions, 'quantity')
      if (optionGroupQuantity < menuOptionGroup.min) {
        isComplete = false
      }
    }
  })

  return isComplete
}

/**
 * 判斷套餐是否已完成
 * @param {IAppBatchItem} batchItem
 * @param {IAppSet} set
 * @returns {boolean}
 */
export function isSetItemComplete (batchItem, set) {
  if (!batchItem.isSet) return true // 非套餐，直接算完成

  const filteredSteps = _.filter(set.steps, step => {
    const dependentItemIds = !_.isEmpty(step.dependsOnSetMenuItems)
      ? step.dependsOnSetMenuItems
      : step.dependsOnSetMenuItem
        ? [step.dependsOnSetMenuItem]
        : []

    if (_.isEmpty(dependentItemIds)) return true // 沒有設定承接餐單的 step 直接留下
    const hasDependentItem = _.some(batchItem.setItems, setItem => _.includes(dependentItemIds, setItem.setMenuId))
    if (hasDependentItem) return true // 檢查步驟是否有設定承接餐單 檢查是否點過指定餐點 指定餐點存在的話才留下 step
    return false
  })

  const notCompleteStep = _.find(filteredSteps, step => {
    return !calculateStepIsComplete(batchItem, step)
  })

  return !notCompleteStep
}

/**
 * @param {IAppMenuItem | IAppSet} menu
 * @param {number?} quantity
 * @returns {IAppBatchItem | {}}
 */
export function createBatchItem (menu, quantity) {
  if (!menu) return {}
  const batchItem = {
    key: uuid(),
    setId: menu.isSet ? menu.id : undefined,
    menuId: menu.id,
    categoryId: menu.categoryId,
    name: menu.name,
    desc: menu.desc,
    price: menu.price ?? 0,
    priceUndetermined: menu.priceUndetermined,
    discount: menu.discount ?? 0,
    excludeOrderDiscount: menu.excludedDiscount,
    excludedOrderSurcharge: menu.excludedSurcharge,
    quantity: quantity ?? 1,
    options: [],
    tags: [],
    remarks: [],
    setItems: [],
    modifiers: [],
    isSet: menu.isSet,
    separatedStep: menu.separatedStep,
  }
  return batchItem
}

/**
 * @param {IAppMenuItem | IAppSet} setItemMenu
 * @param {number?} quantity
 * @returns {IAppBatchItem | {}}
 */
export function createBatchSetItem (setItemMenu, quantity) {
  if (!setItemMenu) return {}
  const batchSetItem = {
    key: uuid(),
    menuId: setItemMenu.menuId, // set menu 原始的 menu id
    setMenuId: setItemMenu.id, // set menu 自己的 id
    setMenuIndex: null,
    categoryId: setItemMenu.categoryId,
    step: setItemMenu.step,
    stepIndex: setItemMenu.stepIndex,
    name: setItemMenu.name,
    desc: setItemMenu.desc,
    price: setItemMenu.price ?? 0,
    priceUndetermined: setItemMenu.priceUndetermined,
    discount: setItemMenu.discount ?? 0,
    excludeOrderDiscount: setItemMenu.excludedDiscount,
    excludedOrderSurcharge: setItemMenu.excludedSurcharge,
    quantity: quantity ?? 1,
    options: [],
    tags: [],
    remarks: [],
    setItems: [],
    modifiers: [],
    isRequired: setItemMenu.isRequired,
  }
  return batchSetItem
}

/**
 * @param {IAppOrderBatch} selectedBatch
 * @param {IAppOrder} selectedOrder
 * @returns
 */
export function calculateDisplayBatchTotal (selectedBatch, selectedOrder) {
  let quantity = 0
  let discountTotal = 0
  let total = 0
  let originalTotal = 0

  _.forEach(selectedBatch?.items, item => {
    const calculatedItem = orderappLibs.calculateLocalBatchItem(item, selectedOrder)
    quantity += item.quantity
    discountTotal += calculatedItem.totalDiscount
    total += calculatedItem.total
  })
  originalTotal = total + discountTotal

  return {
    quantity,
    total,
    discountTotal, // Modifiers 不含 menu discount
    originalTotal, // total + discountTotal
  }
}

/**
 * @param {number} total
 * @param {IShipping} shipping
 * @return {{
 * shippingFee: number
 * originalShippingFee: number
 * shippingDiscount: number
 * }}
 */
export function calculateDisplayShippingFee (total, shipping) {
  let shippingFee = 0
  let originalShippingFee = 0
  let shippingDiscount = 0

  const minOrderAmount = _.get(shipping?.discounts, ['0', 'minOrderAmount']) ?? 0
  const discountAmount = _.get(shipping?.discounts, ['0', 'amount']) ?? 0
  originalShippingFee = shipping?.totalFee ?? 0
  shippingDiscount = total >= minOrderAmount ? discountAmount : 0
  const discountShippingFee = originalShippingFee > shippingDiscount ? originalShippingFee - shippingDiscount : 0
  shippingFee = shipping ? discountShippingFee : NaN
  return {
    shippingFee,
    originalShippingFee,
    shippingDiscount,
  }
}

export default {
  getDisplayBatchItem,
  calculateStepQuantityTotal,
  getStepQuantityMax,
  getStepQuantityMin,
  calculateStepIsMax,
  calculateStepIsComplete,
  isOptionsComplete,
  isSetItemComplete,
  createBatchItem,
  createBatchSetItem,
}
