import PropTypes from 'prop-types'
import React from 'react'

import GroupItemBase from './GroupItemBase'
import Sold from '@/components/Groups/GroupItem/rightActions/Sold'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'

GroupItemSold.propTypes = {
  item: PropTypes.object,
}

/**
 *
 * @param {{
 * item: IMenuOptionItem
 * }} props
 * @returns
 */
export default function GroupItemSold (props) {
  const { item } = props

  return (
    <GroupItemBase item={item} rightAction={<Sold />} />
  )
}
