import { ListItem, ListItemSecondaryAction } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined'
import PropTypes from 'prop-types'
import React from 'react'

CardListItem.propTypes = {
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * onClick: Function
 * }} props
 * @returns
 */
export default function CardListItem (props) {
  const { onClick, children } = props
  const classes = useStyles(props)

  return (
    <ListItem
      button={Boolean(onClick)}
      className={classes.listItem}
      onClick={onClick}
    >
      {children}
      {onClick &&
        <ListItemSecondaryAction className={classes.secondaryAction}>
          <KeyboardArrowRightOutlinedIcon className={classes.icon} />
        </ListItemSecondaryAction>}
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  listItem: {
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.shadows[25],
  },
  icon: {
    color: theme.palette.grey[400],
  },
  secondaryAction: {
    transform: 'translate(40%, -40%)',
  },
}))
