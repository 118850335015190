import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

DineinButtonBase.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disableRipple: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * title: string
 * icon: node
 * disabled?: boolean
 * backgroundColor?: string
 * className?: string
 * disableRipple?: boolean
 * onClick?: function
 * }} props
 * @returns
 */
export default function DineinButtonBase (props) {
  const {
    title,
    icon,
    disabled,
    className,
    disableRipple,
    onClick,
  } = props
  const classes = useStyles(props)

  return (
    <Button
      variant='contained'
      disabled={disabled}
      disableRipple={disableRipple}
      disableElevation
      startIcon={icon}
      onClick={onClick}
      className={className}
      classes={{
        contained: classes['DineinButtonBase-root'],
        disabled: classes['DineinButtonBase-disabled'],
        startIcon: classes['DineinButtonBase-startIcon'],
      }}
    >
      <Typography>{title}</Typography>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  'DineinButtonBase-root': {
    flex: 1,
    color: theme.palette.common.white,
    backgroundColor: props => props.backgroundColor,
    '&:hover': {
      backgroundColor: props => props.backgroundColor,
    },
  },
  'DineinButtonBase-disabled': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[300],
  },
  'DineinButtonBase-startIcon': {
    marginRight: theme.spacing(0.5),
  },
}))
