import { Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

/**
 *
 * @param {{
 * className?: string
 * ratio?: number
 * }} props
 * @returns
 */
export default function RiceCoinRatioLabel (props) {
  const { t } = useTranslation()
  const { className, ratio } = props
  const classes = useStyles(props)

  if (!ratio || ratio <= 1) return null

  return (
    <div className={clsx(classes.container, className)}>
      <Typography variant='caption' className={classes.text} align='center'>
        {t('app.page.riceCoin.ricecoinRatioLabel', { ratio })}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 1),
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.info.main, 0.1),
    flexShrink: 0,
  },
  text: {
    color: theme.palette.info.main,
  },
}))
