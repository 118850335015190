import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import InfoButton from '@/components/Buttons/InfoButton'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IDimbox } from '@/redux/dimbox/Dimbox.d'
/* eslint-enable no-unused-vars */

/**
 *
 * @param {{
 * index: number
 * cabinetId: number
 * box: IDimbox
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function Dimbox (props) {
  const { index, cabinetId, box, order } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [loading, setLoading] = React.useState(false)

  const status = React.useMemo(() => {
    const { pickupAt } = order

    if (loading) {
      return 'LOADING'
    }

    // 若當前時間超過取餐時間上限（14:30）則為逾期，不可再打開 dimbox，若要打開 dimbox 需與客服聯繫。
    const isOverdue = moment().isAfter(moment(pickupAt).hours(14).minutes(30), 'minutes')
    if (isOverdue) {
      return 'OVERDUE'
    }

    // 若當前時間介於可取餐區間內（12:00~14:30）則用戶可開啟 dimbox，若非在規定時間內則無法開啟 dimbox。
    const isAvailable = moment().isBetween(moment(pickupAt).hours(12).minutes(0), moment(pickupAt).hours(14).minutes(30), 'minutes', '[]')
    if (isAvailable) {
      return 'AVAILABLE'
    }

    return 'UNAVAILABLE'
  }, [order.pickupAt, loading])

  let disabled = false
  let buttonText = ''
  switch (status) {
    case 'AVAILABLE':
      buttonText = t('app.page.order_tracking.dimbox.status.available')
      disabled = false
      break

    case 'UNAVAILABLE':
      buttonText = t('app.page.order_tracking.dimbox.status.unavailable')
      disabled = true
      break

    case 'OVERDUE':
      buttonText = t('app.page.order_tracking.dimbox.status.overdue')
      disabled = true
      break

    case 'LOADING':
      buttonText = t('app.page.order_tracking.dimbox.status.available')
      disabled = true
      break

    default:
      break
  }

  const handleOpenDimbox = () => {
    const alertConfig = {
      title: t('app.component.alert.dimbox_open.title'),
      message: t('app.component.alert.dimbox_open.message'),
      buttons: [
        {
          text: loading
            ? t('app.component.alert.dimbox_open.opening')
            : t('app.component.alert.dimbox_open.confirm_text'),
          disabled: loading,
          onClick: async () => {
            setLoading(true)
            await dispatch(actions.dimbox.openDimbox(cabinetId, order.id, box.boxId))
            setLoading(false)
          },
        },
        {
          text: t('app.component.alert.dimbox_open.cancel_text'),
          onClick: () => {},
        },
      ],
    }
    dispatch(actions.app.toggleAlert(alertConfig))
  }

  return (
    <li className={classes.dimbox}>
      <div className={classes.leftColumn}>
        <span className={classes.sequenceWrap}>
          <Typography className={classes.sequence} component='span'>{index + 1}</Typography>
        </span>
        <div className={classes.boxIdWrap}>
          {
            box.boxId != null
              ? <Typography className={classes.box}>{box.boxId}</Typography>
              : <Typography className={classes.box} style={{ fontSize: 14 }}>{t('app.page.order_tracking.dimbox.booking')}</Typography>
          }
          {box.boxId == null && <InfoButton text={t('app.page.order_tracking.dimbox.booking_desc')} />}
        </div>
      </div>
      <div className={classes.rightColumn}>
        <Button
          variant='contained'
          color='primary'
          disabled={disabled}
          className={classes.button}
          onClick={handleOpenDimbox}
        >
          {buttonText}
          {loading && <CircularProgress className={classes.loading} size={16} />}
        </Button>
      </div>
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  dimbox: {
    display: 'flex',
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: 60,
  },
  leftColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#17a0d9',
    color: '#ffffff',
    padding: theme.spacing(1, 2),
  },
  rightColumn: {
    flexShrink: 0,
    display: 'flex',
    width: 'clamp(80px, 40%, 140px )',
  },
  box: {
    fontSize: 20,
    fontWeight: 600,
  },
  sequenceWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 40,
    minHeight: 40,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
  sequence: {
    fontSize: 22,
    fontWeight: 600,
  },
  button: {
    flex: 1,
    borderTopLeftRadius: 0,
    fontSize: 16,
  },
  disabled: {
    backgroundColor: '#e5e5e5',
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
  boxIdWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))
