import _ from 'lodash'

/* eslint-disable no-unused-vars */
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'
/* eslint-enable no-unused-vars */

/**
 * 取得套餐的圖片
 * 1.若套餐本身有圖片則使用套餐的圖片
 * 2.找特定步驟（預設第一步）裡的第一個品項的圖片
 * @param {IAppMenuItem | IAppSet} menu
 * @param {number?} stepIndex 預設抓第一個
 * @returns {string}
 */
export function getMenuImage (menu, stepIndex = 0) {
  if (!menu.isSet) return menu.image ?? ''
  if (menu.isSet && menu.image) return menu.image
  const firstStepId = menu.steps[stepIndex]?.id
  const firstStepfirstItem = _.find(menu.menus, m => m.step === firstStepId)
  return firstStepfirstItem?.image ?? ''
}

export default {
  getMenuImage,
}

export function checkIsSold ({ merchant, menu, setItemMenu }) {
  if (menu?.sold || setItemMenu?.sold) {
    // 若品項被設定為售完時
    // 或於分拆套餐的情況下，套餐本身跟分拆出來的品項其中有任何一項被設定為售完時
    return true
  }

  if (merchant?.setting?.inventory) {
    // 若餐廳有開啟庫存設定時，才需要檢查是否尚有庫存
    if (setItemMenu) {
      // 若有傳入 setItem 代表是分拆的套餐，因此除了套餐本身的庫存外，也需要將 setItem 的庫存列入考量
      return menu?.inventory === 0 || setItemMenu?.inventory === 0
    } else {
      return menu?.inventory === 0
    }
  }

  return false
}
