import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { RestaurantCardSkeleton } from '@/components/Cards/RestaurantCard'

export default function RestaurantCardsSkeleton (props) {
  const classes = useStyles(props)
  return (
    <ul className={classes.container}>
      {_.times(24, n => <RestaurantCardSkeleton key={n} />)}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: 16,
    gap: 8,
  },
}))
