import { RESTAURANT_LOGO, transformation } from '@/libs/cloudinary'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @typedef RestaurantLogoProps
 * @property {string} image
 * @property {boolean} open
 * @param {RestaurantLogoProps} props
 * @returns
 */
export default function RestaurantLogo (props) {
  const { image, open } = props
  const classes = useStyles(props)
  const imageUrl = transformation(image, { width: RESTAURANT_LOGO.sm.width })

  return (
    <div
      className={classes.logo}
      style={{
        display: open ? undefined : 'none',
        backgroundImage: `url(${imageUrl})`,
      }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  logo: {
    zIndex: 100,
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 'clamp(40px, 20%, 50px)',
    backgroundColor: '#fafafa',
    borderRadius: 12,
    cursor: 'pointer',
    boxShadow: `0 0 0 1px ${theme.palette.grey[50]}, 0 0 8px ${theme.palette.grey[500]}`,
    transform: 'translateY(clamp(6px, 3%, 12px))',
    marginRight: 'clamp(6px, 3%, 12px)',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
}))
