import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { actions, useDispatch, useSelector } from '@/redux'
import { useParams as useAppParams } from '@/hooks/app'
import { useMemberId } from '@/hooks/user'
import dimorderApi from '@/libs/api/dimorder'

/**
 *
 * @param {string} [groupId='']
 * @param {string} [merchantId='']
 * @returns
 */
export function useGroupQueryOptions (groupId = '', merchantId = '') {
  const params = useParams()
  const memberId = useMemberId()
  const merchant = useSelector(state => state.merchant.data)
  const _groupId = groupId || params.groupId
  const _merchantId = merchantId || params.merchantId || merchant.id

  let enabled = false
  let queryKey = ['crm', 'group', `groupId:${_groupId}`, `merchantId:${_merchantId}`, `memberId:${memberId}`]
  let queryFn = () => {}

  if (_groupId) {
    enabled = true
    queryKey = ['crm', 'group', `groupId:${_groupId}`, `memberId:${memberId}`]
    const fetchFn = async () => await dimorderApi.crm.getGroupById(_groupId)
    queryFn = () => fetchFn()
  } else if (_merchantId) {
    enabled = merchant?.setting?.enableCRM || false
    queryKey = ['crm', 'group', `merchantId:${_merchantId}`, `memberId:${memberId}`]
    const fetchFn = async () => await dimorderApi.crm.getGroupByMerchantId(_merchantId)
    queryFn = () => fetchFn()
  }

  const retry = (failureCount, error) => {
    // 當 merchant 沒有設定 group 時 API 會 response 410 error，不 retry
    if (error.response?.data?.error === 'no such group') { return false }
  }
  const staleTime = 0.5 * 60 * 1000
  return { queryKey, queryFn, enabled, retry, staleTime }
}

/**
 *
 * @param {string} [groupId]
 * @param {string} [merchantId]
 * @returns
 */
export function useGroupQuery (groupId, merchantId) {
  const options = useGroupQueryOptions(groupId, merchantId)
  const query = useQuery(options)
  return { ...query, isCRMMember: !!query.data?.joinedAt }
}

export function useGroupRegisteration () {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const memberId = useMemberId()

  const { merchantId } = useAppParams()
  const { data: group } = useGroupQuery()
  const mutation = useMutation({
    mutationFn: () => dimorderApi.crm.registerCRM(group?.id, merchantId),
    retry: 3,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['crm', 'group', `merchantId:${merchantId}`, `memberId:${memberId}`] }) // refetch useGroupQuery
      queryClient.invalidateQueries({ queryKey: ['crm', 'group', `groupId:${group?.id}`, `memberId:${memberId}`] }) // refetch useGroupQuery
    },
    onError: () => {
      dispatch(actions.app.toggleAlert({ title: t('app.component.alert.error_please_retry.title') }))
    },
  })
  return mutation
}
