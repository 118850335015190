import { ButtonBase, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'

// import InventoryLabel from '@/components/InventoryLabel'
import { MENU_ITEM_IMAGE, transformation } from '@/libs/cloudinary'
import { getMenuImage } from '@/libs/menu'
import PriceLabel from '@/components/PriceLabel'
import constants from '@/constants'
import hooks from '@/hooks'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 *
 * 如果有傳 setItemMenu 代表是點開根據特定步驟拆開的套餐
 * 需要顯示點開的 setItemMenu 的資訊 name, desc, image
 * price 仍顯示為套餐的總額，若 price 為 0 則不顯示總額
 * @param {{
 * menu: IAppMenuItem | IAppSet
 * setItemMenu?: IAppMenuItem,
 * }} props
 * @returns
 */
export default function MenuItem (props) {
  const { menu, setItemMenu } = props

  const _menu = !_.isEmpty(setItemMenu) ? setItemMenu : menu
  const dispatch = useDispatch()
  const imageUrl = transformation(getMenuImage(_menu), { width: MENU_ITEM_IMAGE.sm.width }) || constants.app.image.EMPTY_IMAGE
  const classes = useStyles({ ...props, imageUrl })
  const selectedOrder = hooks.order.useSelectedOrder()
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const calculatedItem = !_.isEmpty(batchItemTemp) && Boolean(selectedOrder)
    ? orderappLibs.calculateLocalBatchItem({ ...batchItemTemp, quantity: 1 }, selectedOrder) // 在 PriceLabel 上只要固定顯示一份的價錢 (行為與單點一致) 所以設 quantity: 1
    : {}

  const switchPrice = (menu) => {
    const { steps, price } = menu
    const { total, totalMenuDiscount } = calculatedItem
    const isSet = Boolean(steps)
    if (isSet) return total + totalMenuDiscount // total + totalMenuDiscount 可以還原 price (用來被劃掉的)
    return price
  }

  const renderPriceLabel = () => {
    const price = switchPrice(menu)
    const { discount, totalMenuDiscount } = calculatedItem

    // 把指定步驟  (separatedStep) 拆出來的套餐
    if (setItemMenu) {
      return <PriceLabel menu={setItemMenu} price={price} discount={totalMenuDiscount} isSeparated />
    }

    return <PriceLabel menu={menu} price={price} discount={discount} />
  }

  return (
    <div className={classes.menuItem}>
      <ButtonBase className={classes.image} onClick={() => dispatch(actions.app.toggleDialog('menuDetail', true, { menu: _menu }))} />
      <div className={classes.content}>
        <section className={classes.nameWrap}>
          <Typography className={classes.name}>{_menu?.name}</Typography>
          <Typography className={classes.desc} variant='caption'>{_menu?.desc}</Typography>
        </section>
        {renderPriceLabel()}
        {/* <InventoryLabel menu={menu} /> */}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'grid',
    gap: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(1),
    gridTemplateColumns: 'auto 1fr',
  },
  image: {
    alignSelf: 'start',
    paddingBottom: '100%',
    backgroundColor: theme.palette.grey[100],
    backgroundImage: props => `url(${props.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: theme.shape.borderRadius * 2,

    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
    [theme.breakpoints.up('sm')]: {
      width: 125,
    },
    [theme.breakpoints.up('md')]: {
      width: 150,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  name: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  desc: {
    color: theme.palette.grey[600],
  },
  nameWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  bold: {
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
  },
  set: {
    marginRight: theme.spacing(0.5),
  },
}))
