import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

ComingSoon.propTypes = {
  text: PropTypes.string,
}

/**
 *
 * @param {{
 * text?: string
 * }} props
 * @returns
 */
export default function ComingSoon (props) {
  const { text } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <Box className={classes.comingSoon}>
      <Typography variant='h5' className={classes.comingSoonText}>
        {text || t('app.common.scan_qrcode_to_order')}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  comingSoon: {
    flex: 1,
    display: 'grid',
    placeItems: 'center',
    minHeight: 320,
  },
  comingSoonText: {
    color: theme.palette.grey[400],
  },
}))
