import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { isOptionsComplete } from '@/libs/order'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import AddToCartButton from '@/components/Drawers/common/AddToCartButton'
import DrawerContent from '@/components/Drawers/common/DrawerContent'
import MenuItem from '@/components/Drawers/common/MenuItem'
import MenuItemStepper from '@/components/Stepper/MenuItemStepper'
import OptionGroup from '@/components/Groups/OptionGroup'
import RemarkInput from '@/components/Drawers/common/RemarkInput'
import SwipeableDrawer from '@/components/SwipeableDrawer'
import constants from '@/constants'
import hooks from '@/hooks'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MenuItemDrawer (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)

  const merchant = hooks.merchant.useMerchant()
  const open = useSelector(state => state.app.drawers.menuItem.open)
  const openDrawer = () => dispatch(actions.app.toggleDrawer('menuItem', true))
  const closeDrawer = () => dispatch(actions.app.toggleDrawer('menuItem', false))
  const resetDrawerData = () => {
    setTimeout(() => {
      dispatch(actions.app.toggleDrawer('menuItem', false, {}))
      dispatch(actions.batchItemTemp.resetItem())
      // 200ms delay for drawer close transition duration
    }, 200)
  }
  const handleClose = () => {
    closeDrawer()
    resetDrawerData()
  }

  const batchItem = useSelector(state => state.app.drawers.menuItem.data.batchItem) // 如果有 batchItem 代表在 /checkout 編輯
  const menuQuery = useMenuQuery()
  const menus = menuQuery.data?.menus
  const menuItem = useSelector(state => state.app.drawers.menuItem.data.menu) // 如果有 menu 代表在 /restaurant 點餐
  const menu = (
    !_.isEmpty(batchItem) && menus
      ? menus[batchItem?.menuId]
      : menuItem
  ) ?? {}

  const { sendEvent } = hooks.useTrack()
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const remark = batchItemTemp.remarks ? (batchItemTemp.remarks[0] ?? '') : ''
  const handleRemarkChange = (e) => dispatch(actions.batchItemTemp.updateItem(['remarks'], [e.target.value]))

  const isComplete = open && !_.isEmpty(batchItemTemp) && isOptionsComplete(batchItemTemp, menu)
  const handleAddItem = () => dispatch(actions.orderBatch.addItem(batchItemTemp))
  const handleReplaceItem = () => dispatch(actions.orderBatch.replaceItem(batchItemTemp))
  const handleSubmit = () => {
    handleClose()

    // 有給 batchItem 代表在 checkout 編輯，所以編輯完後要取代原有的 batchItem
    if (!_.isEmpty(batchItem)) {
      handleReplaceItem()
      return
    }

    handleAddItem()
    sendEvent(constants.track.EVENT.ADD_TO_CART, {
      item: batchItemTemp,
      merchant,
    })
  }

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={handleClose}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <DrawerContent>
        <header>
          <MenuItem menu={menu} />
        </header>
        <ul className={classes.groupList}>
          {_.map(menu?.options, (optionGroup, index) => {
            return (
              <OptionGroup
                key={index}
                group={optionGroup}
                menu={menu}
              />
            )
          })}
          <RemarkInput value={remark} onChange={handleRemarkChange} />
        </ul>
        <footer className={classes.footer}>
          <MenuItemStepper />
          <AddToCartButton
            disabled={!isComplete}
            onClick={handleSubmit}
          />
        </footer>
      </DrawerContent>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(3, 2),
    paddingBottom: `calc(${theme.spacing(3)}px + ${constants.app.safeArea.bottom})`,
  },
  groupList: {
    display: 'grid',
    gridGap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    overflowY: 'auto',
  },
  footer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
  },
}))
