import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import { isBetweenValidTime, isNotBegin } from '@/libs/promoCode'
import { useIsLogin } from '@/hooks/user'
import CouponCard from '@/components/Coupon/CouponCard'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import useShortLang from '@/hooks/useShortLang'

import { actions, useDispatch, useSelector } from '@/redux'

export default function Coupon (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const lang = useShortLang()
  const coupons = useSelector(state => state.user.coupons)

  const promoCodeId = useSelector(state => state.app.params.promoCodeId)
  const isLogin = useIsLogin()

  React.useEffect(() => {
    if (!promoCodeId) return
    if (!isLogin) {
      dispatch(actions.app.toggleLoginDrawer(true))
      return
    }

    // 領取 coupon，不帶 promoCodeId，讓他自動使用 params.promoCodeId，用完後刪除
    dispatch(actions.user.claimCoupon())
  }, [promoCodeId, isLogin])

  const availableCoupons = React.useMemo(() => {
    return coupons.filter(c => !c.usedAt && isBetweenValidTime(c.validFrom, c.validUntil))
  }, [coupons])

  const notAvailableCoupons = React.useMemo(() => {
    return coupons.filter(c => !c.usedAt && isNotBegin(c.validFrom, c.validUntil))
  }, [coupons])

  const usedCoupons = React.useMemo(() => {
    return coupons.filter(c => c.usedAt && isBetweenValidTime(c.validFrom, c.validUntil))
  }, [coupons])

  const noCoupons = availableCoupons.length === 0 && notAvailableCoupons.length === 0 && usedCoupons.length === 0

  const handleUsdCoupon = (coupon) => {
    if (coupon.deliveryType) {
      // 有 deliveryType，選擇 deliveryType
      dispatch(actions.app.updateDeliveryType(coupon.deliveryType))
    }
    if (coupon.action && coupon.action.type !== '') {
      // 有 action 依照 action 處理
      const { type, payload } = coupon.action
      dispatch(actions.app.handleMarketingAction(type, payload))
    } else if (coupon.merchantId) {
      history.push(`/restaurant/${coupon.merchantId}`)
    } else {
      // 沒有 action 直接前往餐廳列表
      history.push('/restaurants')
    }
  }

  return (
    <Page>
      <Navbar title={t('app.page.coupon_details.title')} />
      <PageContainer className={clsx(classes.container, { [classes.emptyContainer]: noCoupons })}>
        {noCoupons && <Typography>{t('app.page.coupon_details.no_coupons')}</Typography>}
        {availableCoupons.length > 0 && <Typography className={classes.listTitle} color='textPrimary'>{t('app.page.coupon_details.coupon_available')}</Typography>}
        {
          availableCoupons.map((c, idx) => {
            return (
              <CouponCard
                key={`${c.id}-${idx}`}
                name={c?.name?.[lang]}
                validFrom={c?.validFrom}
                validUntil={c?.validUntil}
                buttonText={t('app.component.alert.coupon_card.useNow')}
                discountNumber={c.amount === 0 ? c.percent : c.amount}
                discountType={c.amount === 0 ? 'percent' : 'amount'}
                applyTo={c.applyTo}
                merchantId={c.merchantId}
                minOrderAmount={c.minOrderAmount}
                tags={c.tags}
                onTncButtonClick={() => dispatch(actions.app.toggleDialog('couponTnc', true, c))}
                onButtonClick={() => handleUsdCoupon(c)}
              />
            )
          })
        }
        {notAvailableCoupons.length > 0 && <Typography className={classes.listTitle} color='textPrimary'>{t('app.page.coupon_details.coupon_not_begin')}</Typography>}
        {
          notAvailableCoupons.map((c, idx) => {
            return (
              <CouponCard
                key={`${c.id}-${idx}`}
                name={c?.name?.[lang]}
                validFrom={c?.validFrom}
                validUntil={c?.validUntil}
                buttonText={t('app.page.coupon_details.coupon_not_begin')}
                buttonDisabled
                discountNumber={c.amount === 0 ? c.percent : c.amount}
                discountType={c.amount === 0 ? 'percent' : 'amount'}
                applyTo={c.applyTo}
                merchantId={c.merchantId}
                minOrderAmount={c.minOrderAmount}
                tags={c.tags}
                onTncButtonClick={() => dispatch(actions.app.toggleDialog('couponTnc', true, c))}
              />
            )
          })
        }
        {usedCoupons.length > 0 && <Typography className={classes.listTitle} color='textPrimary'>{t('app.page.coupon_details.coupon_used')}</Typography>}
        {
          usedCoupons.map((c, idx) => (
            <CouponCard
              used
              key={`${c.id}-${idx}`}
              name={c?.name?.[lang]}
              validFrom={c?.validFrom}
              validUntil={c?.validUntil}
              buttonText={t('app.page.coupon_details.coupon_used')}
              disabled
              buttonDisabled
              discountNumber={c.amount === 0 ? c.percent : c.amount}
              discountType={c.amount === 0 ? 'percent' : 'amount'}
              applyTo={c.applyTo}
              merchantId={c.merchantId}
              minOrderAmount={c.minOrderAmount}
              tags={c.tags}
              onTncButtonClick={() => dispatch(actions.app.toggleDialog('couponTnc', true, c))}
            />
          ))
        }
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    marginBottom: constants.app.marginBottom.GENERAL,
    paddingLeft: 16,
    paddingRight: 16,
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  listTitle: {
    marginTop: theme.spacing(2),
  },
}))
