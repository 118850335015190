import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import SearchTag from '@/components/Search/SearchTag'

export default function FilterList (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const filter = useSelector(state => state.landing.filter)
  const fields = _.keys(filter)

  const onRemoveFilter = (field, option) => {
    const updatedFilter = {
      ...filter,
      [field]: filter[field].filter(o => o !== option),
    }
    dispatch(actions.landing.applyFilter(updatedFilter))
  }

  const hasFilter = _.some(Object.values(filter), array => array.length > 0)
  if (!hasFilter) return null

  return (
    <div className={classes.container}>
      {_.map(fields, field => {
        return _.map(filter[field], (option, index) => {
          return (
            <SearchTag
              key={option}
              // text={t(`app.page.filter.${field}.${option}`)}
              // 暫時直接用中文
              text={option}
              onDelete={() => onRemoveFilter(field, option)}
            />
          )
        })
      })}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flexShrink: 0,
    display: 'flex',
    overflowX: 'scroll',
  },
}))
