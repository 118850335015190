import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import { isPoonChoiCategoryTag } from '@/libs/poonchoi'
import { useDeliveryType, useParams } from '@/hooks/app'
import { useGroupAllRewardsQuary } from '@/hooks/queries/CRM/useGroupRewardsQuery'
import { useGroupQuery } from '@/hooks/queries/CRM/useGroupQuery'
import { useMerchant } from '@/hooks/merchant'
import { useShipping } from '@/hooks/order'
import constants from '@/constants'

import Address from './Address'
import DatetimePickerButton from './DatetimePickerButton'
import InfoButton from './InfoButton'
import RestaurantLogo from '@/components/RestaurantLogo'
import TableButton from './TableButton'

/**
 *
 * @param {{*}} props
 * @returns
 */
export default function MerchantCard (props) {
  const { className } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { categoryTag, isD2CWeb, table } = useParams()
  const { isTable } = useDeliveryType()
  const merchant = useMerchant()
  const shippingTime = useShipping()?.shippingTime
  const hasAddress = useSelector(state => state.user.address?.address)
  const isPoonchoi = isPoonChoiCategoryTag(categoryTag)

  const showMerchantDetail = () => {
    dispatch(actions.app.toggleDialog('merchantDetail'))
  }

  function Content () {
    // 盆菜時不顯示 DatetimePickerButton，但無法外送時會顯示不能按的 DatetimePickerButton（就會顯示無法外送至您的位置）
    if (isPoonchoi) {
      return (
        <>
          {hasAddress && shippingTime === 'NO_RESULT' && <DatetimePickerButton disabled />}
          <Address />
        </>
      )
    }

    return (
      <>
        {/* D2C web 不顯示按鈕以免用戶可以改 deliveryType 和 datetime */}
        {isTable && (
          <div className={classes.dineinWrap}>
            <TableButton />{/* 內用時顯示桌號 */}
          </div>
        )}
        <DatetimePickerButton hidden={isD2CWeb && table} />
        {
        // 內用則不顯示地址，若沒有桌號會顯示提示文字
          isTable
            ? !table && <div className={classes.noTableText}>{t('app.page.restaurant.merchant_card.datetime_picker_button.no_table_text')}</div>
            : <Address />
        }
      </>
    )
  }

  return (
    <div className={clsx(classes.merchantCard, className)}>
      <section className={classes.header}>
        <Typography className={classes.title}>
          {merchant?.name}
          <InfoButton onClick={showMerchantDetail} />
        </Typography>
        <RestaurantLogo onClick={showMerchantDetail} />
      </section>
      <section className={classes.content}>
        <Content />
        <CRMRewardsButton />
      </section>
    </div>
  )
}

/**
 * 當以下條件皆符合時顯示這個按鈕
 * 1. merchant.setting.enableCRM 若餐廳有開啟 CRM
 * 2. group?.id 若餐廳有加入任何 group
 * 3. rewards.length > 0 若餐廳有任何積分獎勵可以兌換
 * 點擊後開啟 CRMRewardsDrawer
 * @param {*} props
 * @returns
 */
function CRMRewardsButton (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const enableCRM = useMerchant().setting?.enableCRM
  const { data: group } = useGroupQuery()
  const { rewards } = useGroupAllRewardsQuary()
  const hidden = !(enableCRM && !!group?.id && rewards.length > 0)
  if (hidden) return null
  return (
    <ButtonBase
      className={classes.crmRewardsButton}
      onClick={() => { dispatch(actions.app.toggleDrawer('crmRewards', true)) }}
    >
      🎁
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  merchantCard: {
    margin: theme.spacing(1.5),
    marginTop: constants.app.safeArea.top,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    boxShadow: theme.shadows[25],
    transform: `translateY(calc(48px - ${constants.app.safeArea.top}))`,
  },
  header: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  title: {
    fontSize: '2.4rem',
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  dineinWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  noTableText: {
    textAlign: 'center',
    color: '#b0b0b0',
  },
  crmRewardsButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
    padding: theme.spacing(1),
    width: 40,
    height: 40,
    borderRadius: 800,
    fontSize: '2.4rem',
  },
}))
