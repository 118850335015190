import React from 'react'

import OptionStepper from '@/components/Groups/GroupItem/rightActions/OptionStepper'

import GroupItemBase from './GroupItemBase'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup, IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'

/**
 *
 * @param {{
 * item: IMenuOptionItem
 * group: IMenuOptionGroup
 * disabled: boolean
 * disableIncrement?: boolean
 * }} props
 * @returns
 */
export default function GroupItemOptionStepper (props) {
  const { item, group, disabled, disableIncrement } = props
  const optionStepper = <OptionStepper option={item} optionGroup={group} disableIncrement={disabled || disableIncrement} disableDecrement={disabled} />

  return (
    <GroupItemBase variant='optionStepper' item={item} rightAction={optionStepper} />
  )
}
