import { Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

/**
 * @typedef {Object} IDialogBaseCustomClasses
 * @property {string?} paper
 * @property {string?} header
 * @property {string?} content
 * @property {string?} footer
 */

/**
 *
 * @param {{
 * open: boolean
 * onClose: function
 * title?: string
 * contentText?: string
 * footerText?: string
 * renderTitle?: () => JSX.Element
 * renderContent?: () => JSX.Element
 * renderFooter?: () => JSX.Element
 * customClasses?: IDialogBaseCustomClasses
 * }} props
 * @returns
 */
export default function DialogBase (props) {
  const {
    open,
    onClose,
    title,
    contentText,
    footerText,
    renderTitle,
    renderContent,
    renderFooter,
    customClasses = {},
  } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: clsx([classes.rootPaper, customClasses.paper]) }}
    >
      <header className={clsx([classes.header, customClasses.header])}>
        {
          renderTitle
            ? renderTitle()
            : <Typography className={clsx(classes.title, customClasses.title)}>{title}</Typography>
        }
      </header>
      <main className={clsx([classes.content, customClasses.content])}>
        {
          renderContent
            ? renderContent()
            : <Typography variant='body2'>{contentText}</Typography>
        }
      </main>
      <footer className={clsx([classes.footer, customClasses.footer])}>
        {
          renderFooter
            ? renderFooter()
            : <LetterSpacingButton text={footerText ?? t('app.common.close')} onClick={onClose} />
        }
      </footer>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  rootPaper: {
    minWidth: 320,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    minHeight: 56,
  },
  title: {
    fontWeight: 'bold',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  content: {
    display: 'grid',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footer: {
    display: 'grid',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2, 2, 2, 2),
    boxShadow: '0px -1px 5px 0px rgba(0, 0, 0, 0.08)',
    zIndex: 1, // 高於 content
  },
}))
