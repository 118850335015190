// eslint-disable-next-line no-unused-vars
import { Button, ButtonProps, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

/**
 *
 * @param {ButtonProps & {
 * text: string,
 * fontSize?: string | number,
 * bold?: boolean,
 * customClasses?: { text?: string },
 * loading?: boolean
 * }} props
 * @returns
 */
export default function LetterSpacingButton (props) {
  const {
    text,
    title,
    color = 'primary',
    variant = 'contained',
    fontSize,
    disableElevation = true,
    children,
    className,
    customClasses,
    disabled,
    onClick,
    bold,
    loading = false,
    ...rest
  } = props

  const theme = useTheme()
  const classes = useStyles({
    bold,
    text,
    fontSize,
    disabled,
  })

  return (
    <Button
      color={color}
      variant={variant}
      disableElevation={disableElevation}
      className={clsx([classes.letterSpacingButton, className, { [classes.loading]: loading }])}
      disabled={disabled || loading}
      onClick={onClick}
      {...rest}
    >
      <Typography className={clsx([classes.text, customClasses?.text])}>{text}</Typography>
      {loading ? <CircularProgress size={24} style={{ color: theme.palette.common.white }} /> : children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  letterSpacingButton: {
    position: 'relative',
    flexShrink: 0,
    borderWidth: '2px !important',
    borderColor: theme.palette.primary.main,
    color: props => props.disabled ? theme.palette.grey[500] : theme.palette.text.primary,
    borderRadius: 10,
    minHeight: 40,
    minWidth: 120,
  },
  loading: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    opacity: 0.8,
  },
  text: {
    fontSize: props => props.fontSize,
    fontWeight: props => props.bold && theme.typography.fontWeightBold,
    letterSpacing: props => props.text?.length === 2 ? '0.75rem' : '0.25rem',
  },
}))
