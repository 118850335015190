import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { getRewardItemsTotal, useAvailableCRMPoints, useRewardItems } from '@/hooks/useCart'
import { useGroupRewardLocale } from '@/hooks/queries/CRM/useGroupRewardsQuery'
import { useSelectedBatch } from '@/hooks/order'

import { BatchContainer, ItemListCard, ItemQuantity } from '@/components/OrderBatchItemList'
import { OrderItem } from '@/components/OrderBatchItemList/OrderBatchItem'
import OrderBatchHeader from '@/components/OrderBatchItemList/OrderBatchHeader'
import StepperBase from '@/components/Stepper/StepperBase'
import TotalQuantity from '@/components/OrderBatchItemList/TotalQuantity'

/* eslint-disable no-unused-vars */
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { Reward, RewardItem } from 'dimorder-orderapp-lib/dist/types/CRM'
/* eslint-enable no-unused-vars */

/**
 * 沒有 rewards 時不顯示
 * @typedef OrderRewardItemListProps
 * @property {IAppOrder}  order
 * @property {boolean} [editable=false]
 * @property {{card?:string, orderBatchItem?:string}} [customClasses]
 * @param {OrderRewardItemListProps} props
 * @returns
 */
export default function OrderRewardItemList (props) {
  const { t } = useTranslation()
  const { order, editable = false, customClasses } = props
  const { rewardItems, total } = useRewardItems()
  const { balancePoints } = useAvailableCRMPoints(total.points)
  const quantity = editable ? total.quantity : getRewardItemsTotal(order.rewardItems).quantity
  const data = editable ? rewardItems : order.rewardItems
  const selectedBatch = useSelectedBatch()
  const batches = editable
    ? [{ ...selectedBatch, rewardItems: data }]
    : _.map(order.batches, batch => ({ ...batch, rewardItems: _.filter(data, r => r.batchId === batch.id) }))

  /**
   * order history 時不顯示
   * @returns
   */
  function RightContent () {
    if (!editable) return null
    return (
      <Typography variant='caption' style={{ textAlign: 'end' }}>
        {t('app.page.crm.crm_available_points') + ': '}
        <Typography variant='caption' color='primary'>
          {balancePoints}
        </Typography>
      </Typography>
    )
  }

  if (!quantity) return null

  return (
    <ItemListCard className={customClasses?.card}>
      <TotalQuantity title={t('app.page.crm.redeem_reward')} total={quantity} RightContent={RightContent} />
      {
        _.map(batches, batch => {
          const rewardItems = batch.rewardItems
          if (rewardItems.length === 0) return null // 當 rewardItems 中沒有任何 rewardItem 時，不顯示這個 batch
          return (
            <BatchContainer key={batch.id}>
              <OrderBatchHeader
                index={batch.index}
                createdAt={batch.createdAt}
                hidden={editable}
              />
              {_.map(rewardItems, rewardItem => {
                const rewardId = rewardItem.id || rewardItem.rewardId
                return (
                  <OrderRewardItem
                    key={rewardId}
                    item={rewardItem}
                    editable={editable}
                    customClasses={{ root: customClasses?.orderBatchItem }}
                  />
                )
              })}
            </BatchContainer>
          )
        })
      }
    </ItemListCard>
  )
}

/**
 *
 * @typedef OrderRewardItemProps
 * @property {Reward | RewardItem} item
 * @property {boolean} [editable=false]
 * @property {{root?: string}} [customClasses]
 * @param {OrderRewardItemProps} props
 * @returns
 */
function OrderRewardItem (props) {
  const { item: rewardItem, editable = false, customClasses } = props
  const { t } = useTranslation()
  const isCancelled = rewardItem.cancelled || rewardItem.batchStatus === 'cancelled'

  function Content () {
    const { name } = useGroupRewardLocale(rewardItem)
    return (
      <>
        <Typography style={{ fontSize: '1.4rem' }}>{name}</Typography>
        <Typography style={{ fontSize: '1.4rem' }} color='textSecondary'>
          {t('app.page.crm.crm_points')} {rewardItem.points}
        </Typography>
      </>
    )
  }

  function RightContent () {
    return (
      editable
        ? <RewardItemStepper item={rewardItem} />
        : <ItemQuantity quantity={rewardItem.quantity} />
    )
  }

  if (isCancelled) return null // 已取消的 reward 不顯示

  return (
    <OrderItem
      customClasses={{ root: customClasses?.root }}
      content={<Content />}
      rightContent={<RightContent />}
    />
  )
}

/**
 * @typedef RewardItemStepperProps
 * @property {RewardItem} item
 * @param {RewardItemStepperProps} props
 * @returns
 */
function RewardItemStepper (props) {
  const { item: rewardItem } = props
  const dispatch = useDispatch()
  const { availablePoints } = useAvailableCRMPoints()
  const disableIncrement = availablePoints < rewardItem.points
  const handleIncrement = (e) => {
    e.stopPropagation()
    dispatch(actions.orderBatch.updateRewardItem(rewardItem, rewardItem.quantity + 1))
  }
  const handleDecrement = (e) => {
    e.stopPropagation()
    dispatch(actions.orderBatch.updateRewardItem(rewardItem, rewardItem.quantity - 1))
  }
  return (
    <StepperBase
      quantity={rewardItem.quantity}
      disableIncrement={disableIncrement}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
    />
  )
}
