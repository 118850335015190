import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { checkIsDimboxOrder } from '@/libs/dimbox'
import Card from '@/components/Cards/Card'
import WarningMessage from '@/components/WarningMessage'

import DimboxList from './DimboxList'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function DimboxCard (props) {
  const { order } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const isDimbox = checkIsDimboxOrder(order)

  if (!isDimbox) return null
  if (_.isEmpty(order)) return null

  const renderCardContent = () => {
    // 顯示 DimboxList 狀態
    return (
      <>
        <Typography variant='body1' className={classes.desc}>
          {`${t('app.component.dialog.merchant_detail_dialog.pickup_time')} ${moment(order.pickupAt).format(t('app.common.date_time.format_date'))} 12:00~14:30`}
        </Typography>
        <WarningMessage text={t('app.component.warning_message.dimbox_pickup_warning')} customClasses={{ root: classes.dimboxWarningSpacing }} />
        <DimboxList order={order} />
      </>
    )
  }
  if (order.displayStatusKey === 'waiting_accept') {
    return null
  }
  return (
    <Card className={classes.container}>
      {renderCardContent()}
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(3),
    // overflow: 'hidden',
  },
  desc: {
    color: theme.palette.grey[700],
  },
  headerSection: {
    display: 'grid',
    gap: theme.spacing(1.5),
  },
  dimboxWarningSpacing: {
    marginTop: theme.spacing(1),
  },
}))
