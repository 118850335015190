import { ButtonBase } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles, useTheme } from '@material-ui/styles'
import React from 'react'

import { RESTAURANT_LOGO, transformation } from '@/libs/cloudinary'
import { useRestaurantQuery } from '@/hooks/queries/useRestaurantQuery'

const LOGO_SIZE = 72

export function LogoSkeleton (props) {
  const classes = useStyles(props)
  return (
    <Skeleton
      variant='rect'
      width={LOGO_SIZE}
      height={LOGO_SIZE}
      className={classes.logo}
    />
  )
}

/**
 * @typedef RestaurantLogoProps
 * @property {() => void} [onClick]
 * @param {RestaurantLogoProps} props
 * @returns
 */
export default function RestaurantLogo (props) {
  const { onClick } = props
  const theme = useTheme()
  const classes = useStyles(props)
  const { data: restaurant, isLoading } = useRestaurantQuery()
  const imageUrl = transformation(restaurant?.logoImage, { width: RESTAURANT_LOGO.sm.width })

  function Wrapper ({ children }) {
    return (
      <>
        <div
          style={{ width: LOGO_SIZE }} // 用來撐開 logo 的空間
        />
        {children}
      </>
    )
  }

  if (isLoading) {
    return (
      <Wrapper>
        <LogoSkeleton />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <ButtonBase
        className={classes.logo}
        onClick={onClick}
        disabled={!onClick}
        style={{
          width: LOGO_SIZE,
          height: LOGO_SIZE,
          backgroundImage: `url(${imageUrl})`,
          backgroundColor: theme.palette.grey[500],
          cursor: onClick ? 'pointer' : 'default',
        }}
      />
    </Wrapper>
  )
}

const useStyles = makeStyles(theme => ({
  logo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
}))
