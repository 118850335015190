import { Checkbox as MuiCheckbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

Checkbox.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * selected: Boolean
 * disabled: Boolean
 * onClick: Function
 * }} props
 */
export default function Checkbox (props) {
  const { selected, disabled, onClick } = props
  const classes = useStyles(props)

  return (
    <MuiCheckbox
      color='primary'
      classes={{
        root: classes.checkboxRoot,
        disabled: classes.checkboxDisabled,
      }}
      checked={selected}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

const useStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  checkboxDisabled: {
    opacity: 0.3,
  },
}))
