import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import React from 'react'

export default function DestinationMarker (props) {
  const classes = useStyles(props)

  return (
    <LocationOnIcon className={classes.destinationMarker} />
  )
}

const useStyles = makeStyles(theme => ({
  destinationMarker: {
    position: 'relative',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.success.main,
    width: '4rem',
    height: '4rem',
  },
}))
