// eslint-disable-next-line no-unused-vars
import { CardProps, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'

import { actions, useDispatch, useSelector } from '@/redux'
import { getBatchItemsTotal } from '@/hooks/useCart'
import { useIsDimbox } from '@/hooks/dimbox'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'

import BatchItemStepper from '@/components/Stepper/BatchItemStepper'
import Card from '@/components/Cards/Card'
import WarningMessage from '@/components/WarningMessage'

import OrderBatchHeader from './OrderBatchHeader'
import OrderBatchItem from './OrderBatchItem'
import TotalQuantity from './TotalQuantity'

// eslint-disable-next-line no-unused-vars
import { IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef OrderBatchItemListProps
 * @property {IAppOrder} order
 * @property {IAppOrderBatch[]} batches
 * @property {boolean} [editable=false]
 * @property {{card?:string, orderBatchItem?:string}} [customClasses]
 */

/**
 *
 * @param {OrderBatchItemListProps} props
 * @returns
 */
export default function OrderBatchItemList (props) {
  const { order, batches = [], editable = false, customClasses } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const menuQuery = useMenuQuery()
  const menus = menuQuery.data?.menus
  const sets = menuQuery.data?.sets
  const isDimbox = useIsDimbox()
  const availableDimboxCount = useSelector(state => state.dimbox.available)
  const match = useRouteMatch()
  const isCheckoutPage = match.path === '/checkout'
  const hasBatch = batches.length > 0

  const handleEditItem = (batchItem, editable) => {
    if (!editable) return
    const isSet = batchItem.isSet
    // 放入 batchItem 到對應的 drawer.data 來 render drawer 需要的資訊
    if (isSet) {
      dispatch(actions.app.toggleDrawer('setItem', true, { batchItem }))
    } else {
      dispatch(actions.app.toggleDrawer('menuItem', true, { batchItem }))
    }
    dispatch(actions.batchItemTemp.addItem(undefined, batchItem))
  }

  const quantity = useMemo(() => getBatchItemsTotal(batches).quantity, [batches])

  if (!hasBatch || quantity === 0) {
    return null
  }

  return (
    <ItemListCard className={customClasses?.card}>
      <TotalQuantity title={t('app.common.commodity')} total={quantity} />
      {
        _.map(batches, batch => {
          const batchItems = _.filter(batch.items, batchItem => !batchItem.rewardId) // 當 batchItem 有 rewardId 時代表這是一個 reward，reward 應僅顯示於 OrderRewardItemList 中，因此在這裡不顯示
          if (batchItems.length === 0) return null // 當 batch 中沒有任何 batchItem 時，不顯示這個 batch
          return (
            <BatchContainer key={batch.batchId}>
              <OrderBatchHeader
                index={batch.index}
                createdAt={batch.createdAt}
                hidden={editable}
              />
              {
                _.map(batchItems, batchItem => {
                  const { menuId } = batchItem
                  const menu = menus?.[menuId] ?? sets?.[menuId]
                  const item = editable ? orderappLibs.calculateLocalBatchItem(batchItem, order) : batchItem
                  return (
                    <OrderBatchItem
                      key={item.key}
                      order={order}
                      menu={menu}
                      item={item}
                      customClasses={{ root: customClasses?.orderBatchItem }}
                      onClick={() => handleEditItem(item, editable)}
                      rightContent={
                        editable
                          ? <BatchItemStepper menu={menu} item={item} />
                          : <ItemQuantity quantity={item.quantity} />
                      }
                    />
                  )
                })
              }
            </BatchContainer>
          )
        })
      }
      {
        isCheckoutPage && (
          <WarningMessage
            text={t('app.component.warning_message.dimbox_not_enough_boxes_warning', { available: availableDimboxCount })}
            hidden={!isDimbox || quantity <= availableDimboxCount}
          />
        )
      }
    </ItemListCard>
  )
}

/**
 *
 * @param {{ quantity: number }} props
 * @returns
 */
export function ItemQuantity (props) {
  const { quantity } = props
  const classes = useStyles(props)
  return (
    <Typography className={classes.quantity}>{quantity}</Typography>
  )
}

/**
 *
 * @param {CardProps} props
 * @returns
 */
export function ItemListCard (props) {
  const classes = useStyles(props)
  const { className } = props
  return (
    <Card className={[clsx(classes.itemListCard, className)]} {...props}>
      {props.children}
    </Card>
  )
}

export function BatchContainer (props) {
  const classes = useStyles(props)
  return <ul className={classes.orderBatch}>{props.children}</ul>
}

const useStyles = makeStyles(theme => ({
  itemListCard: {
    '& > :first-child': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  orderBatch: {
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  quantity: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1.5),
  },
}))
