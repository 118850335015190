import { Divider, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DividerRow (props) {
  const classes = useStyles(props)
  return (
    <TableRow>
      <TableCell colSpan='2' className={classes.tableCell}>
        <Divider />
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: theme.spacing(1, 1),
  },
}))
