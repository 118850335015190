import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import constants from '@/constants'

SafeAreaFill.propTypes = {
  hidden: PropTypes.bool,
}

/**
 *
 * @param {{
 * hidden?: boolean
 * }} props
 * @returns
 */
export default function SafeAreaFill (props) {
  const { hidden } = props
  const classes = useStyles(props)
  const safeAreaRef = useRef()
  const dispatch = useDispatch()
  const safeAreaHeight = safeAreaRef?.current?.offsetHeight ?? 0

  useEffect(() => {
    dispatch(actions.app.updateSafeAreaHeight(safeAreaHeight))
  }, [safeAreaHeight])

  if (hidden) return null
  return (
    <div ref={safeAreaRef} className={classes.safeAreaFill} />
  )
}

const useStyles = makeStyles(theme => ({
  safeAreaFill: {
    width: '100%',
    height: constants.app.safeArea.top,
  },
}))
