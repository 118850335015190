import firebase from 'firebase/app'
import 'firebase/remote-config'

import firebaseConfig from '@/config/firebase'

let app
if (firebase.apps.length) {
  app = firebase.app()
} else {
  app = firebase.initializeApp(firebaseConfig)
}

export const remoteConfig = app.remoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 3600000
remoteConfig.defaultConfig = {
  customer_app_min_version: '0.0.0',
  customer_app_latest_version: '0.0.0',
  customer_app_min_package_version: '0.0.0',
}
remoteConfig.fetchAndActivateWithTimeout = (timeout = 5000) => {
  return new Promise((resolve, reject) => {
    const fetchAndActivateTimeout = setTimeout(() => {
      reject(new Error('remote config fetchAndActivate timeout'))
    }, timeout)
    remoteConfig.fetchAndActivate()
      .then((activated) => {
        clearTimeout(fetchAndActivateTimeout)
        resolve(activated)
      })
  })
}

export default {
  app: app,
  remoteConfig,
}
