import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import deliveryOption from '@/constants/deliveryOption'

import DeliveryOptionItem from './DeliveryOptionItem'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DeliveryOptionList (props) {
  const classes = useStyles(props)
  const list = Object.values(deliveryOption)

  return (
    <ul className={classes.deliveryOptionList}>
      {
        _.map(list, (li, index) => (
          <li key={index}>
            <DeliveryOptionItem type={li} />
          </li>
        ))
      }
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  deliveryOptionList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))',
    gridGap: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}))
