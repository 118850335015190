import { VariableSizeList } from 'react-window'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useMode, useWindowSize } from '@/hooks/useWindowSize'
import getCategoryName from '@/libs/getCategoryName'

import CategoryHeader from '@/components/RestaurantList/CategoryHeader'
import RestaurantCard, { RESTAURANT_CARD } from '@/components/Cards/RestaurantCard'

import { FIND_MORE_CARD, FindMoreCard, FindMoreFooterButton } from './FindMoreCard'

const RESTAURANT_SAMPLE_LIMIT = 8

/**
 * 餐廳分類與數間餐廳的橫向列表
 * 1. 於標題顯示分類名稱
 * 2. 於橫向列表顯示最多 max 間餐廳
 * 3. 若該分類餐廳間數大於 max 則顯示『查看更多』按鈕
 * 4. 點擊『查看更多』按鈕進入分類頁面
 * @typedef CategoryRestaurantSampleListProps
 * @property {ILandingCategory} category
 * @property {number} [max = 4]
 * @property {boolean} [loading = false]
 * @property {'horizontal' | 'vertical'} [orientation = 'horizontal']
 * @param {CategoryRestaurantSampleListProps} props
 * @returns
*/
export default function CategoryRestaurantSampleList (props) {
  const { category, max, loading = false, orientation = 'horizontal' } = props
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles(props)
  const isVertical = orientation === 'vertical'
  const categoryName = getCategoryName(category?.name, t)
  const total = category?.total ?? 0
  const data = category?.restaurants ?? []
  const items = loading ? _.times(RESTAURANT_SAMPLE_LIMIT) : data

  // 若該分類餐廳間數 total 大於 max 則顯示『查看更多』按鈕
  // onFindOutMore undefined 會不顯示 FindeMore 按鈕
  const hasMore = total > max
  const onFindOutMore = hasMore
    ? () => history.push('/category', { category })
    : undefined

  return (
    <div className={classes.section}>
      <CategoryHeader
        title={categoryName}
        subtitle={category?.subtitle}
        onFindOutMore={onFindOutMore}
        loading={loading}
      />
      {
        isVertical
          ? <VerticalDisplay items={items} loading={loading} hasMore={hasMore} onFindOutMore={onFindOutMore} />
          : <HorizontalDisplay items={items} loading={loading} hasMore={hasMore} onFindOutMore={onFindOutMore} />
      }
    </div>
  )
}

function VerticalDisplay (props) {
  const { items, loading, hasMore, onFindOutMore } = props
  const classes = useStyles(props)
  const { isMobile } = useMode()
  const cardSize = isMobile ? 'md' : 'sm'
  const containerStyle = {
    justifyContent: isMobile ? 'center' : undefined, // 手機置中顯示
    padding: isMobile ? 0 : RESTAURANT_CARD.card.gutter, // 手機以外的才需要 padding
  }
  return (
    <ul className={classes.verticalList} style={containerStyle}>
      {items.map((item, index) => (
        <RestaurantCard key={item?.id || index} restaurant={item} size={cardSize} loading={loading} />
      ))}
      <FindMoreFooterButton hidden={!hasMore || loading} onFindOutMore={onFindOutMore} />
    </ul>
  )
}

function HorizontalDisplay (props) {
  const { items, loading, hasMore, onFindOutMore } = props
  const { innerWidth } = useWindowSize()
  const itemCount = loading ? items.length : items.length + (hasMore ? 1 : 0)

  const getItemSize = index => {
    if (hasMore && index === items.length) return FIND_MORE_CARD.width + RESTAURANT_CARD.card.gutter * 2 // Find More Button
    if (!hasMore && index === items.length - 1) return RESTAURANT_CARD.card.sm.width + RESTAURANT_CARD.card.gutter * 2 // Last Restaurant Card
    if (loading && index === items.length - 1) return RESTAURANT_CARD.card.sm.width + RESTAURANT_CARD.card.gutter * 2 // Last Loading Card
    return RESTAURANT_CARD.card.sm.width + RESTAURANT_CARD.card.gutter
  }

  const Column = ({ index, style }) => {
    const isFindMoreColumn = hasMore && index === items.length
    const contentSpacing = { marginLeft: RESTAURANT_CARD.card.gutter }
    return (
      <div style={style}>
        {isFindMoreColumn
          ? <FindMoreCard hidden={!hasMore || loading} onFindOutMore={onFindOutMore} style={contentSpacing} />
          : <RestaurantCard restaurant={items[index]} size='sm' loading={loading} style={contentSpacing} />}
      </div>
    )
  }

  return (
    <VariableSizeList
      width={innerWidth}
      height={200}
      itemCount={itemCount}
      itemSize={getItemSize}
      layout='horizontal'
    >
      {Column}
    </VariableSizeList>
  )
}

const useStyles = makeStyles((theme, props) => ({
  section: {
    display: 'grid',
    paddingTop: RESTAURANT_CARD.card.gutter,
    paddingBottom: RESTAURANT_CARD.card.gutter,
  },
  verticalList: {
    flexShrink: 0,
    display: 'flex',
    flexWrap: 'wrap',
    gap: RESTAURANT_CARD.card.gutter,
  },
}))
