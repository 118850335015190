import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

import { MENU_ITEM_IMAGE, transformation } from '@/libs/cloudinary'
import { actions, useDispatch } from '@/redux'
import { getMenuImage } from '@/libs/menu'
import constants from '@/constants'
import opacity from '@/theme/opacity'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem } from '@/redux/menu/MenuState.d'

MenuItemImage.propTypes = {
  menu: PropTypes.object,
  sold: PropTypes.bool,
}

/**
 *
 * menu 是 setItemMenu 的情況是套餐根據指定步驟  (separatedStep) 被拆開成數個套餐的情況
 * 這種情況要顯示的圖片是 setItem 自己的圖片
 * @param {{
 * menu: IAppMenuItem
 * sold: boolean
 * }} props
 * @returns
 */
export default function MenuItemImage (props) {
  const { menu, sold } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const soldoutText = t('app.common.sold_out')
  const imageUrl = transformation(getMenuImage(menu), { width: MENU_ITEM_IMAGE.sm.width }) || constants.app.image.EMPTY_IMAGE
  const classes = useStyles({ ...props, imageUrl, soldoutText })

  return (
    <ButtonBase
      className={clsx(classes.image, { [classes.soldout]: sold })}
      onClick={() => dispatch(actions.app.toggleDialog('menuDetail', true, { menu: { ...menu, image: getMenuImage(menu) } }))}
    />
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
    backgroundImage: props => `url(${props.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    paddingBottom: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },
  soldout: {
    '&:before': {
      zIndex: 100,
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: `${theme.palette.grey[900]}${opacity[70]}`,
      borderRadius: theme.shape.borderRadius * 2,
      transform: 'translateY(50%)',
    },
    '&:after': {
      zIndex: 200,
      content: props => `'${props.soldoutText}'`,
      position: 'absolute',
      display: 'grid',
      placeContent: 'center',
      color: theme.palette.grey[200],
      fontSize: '2.2rem',
      fontWeight: 'bold',
      width: '100%',
      height: '100%',
      transform: 'translateY(50%)',
    },
  },
}))
