import { CircularProgress } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useDeliveryType } from '@/hooks/app'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethods from '@/constants/paymentMethod'
import SecureForm2c2p from '@/components/Payments/SecureForm2c2p'
import constants from '@/constants'
import dimorderApi from '@/libs/api/dimorder'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'
import userAgentDetection from '@/libs/userAgentDetection'

// 目前只有 2c2p 信用卡會進來這裡
// Fiserv 信用卡會前往對方的付款頁面輸入信用卡資料，不會到這裡
export default function AddCreditCard (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const merchant = useSelector(state => state.merchant?.data)
  const history = useHistory()
  const isOrderTrackingSelectPayment = history.location.state?.isOrderTrackingSelectPayment ?? false
  const d2cBaseUrl = useD2CBaseUrl()
  const { isTable } = useDeliveryType()

  const orderId = useSelector(state => state.orderHistory.selectedOrderId)
  const order = useSelector(state => state.orderHistory.orders.find(order => order.id === orderId))

  const [isMerchantLoading, setMerchantLoading] = React.useState(false)
  const [payingMerchant, setPayingMerchant] = React.useState(null)

  const getPayingMerchant = async () => {
    const tempMerchant = await dimorderApi.merchant.getMerchant(order.merchantId)
    if (tempMerchant) {
      setPayingMerchant(tempMerchant)
    }
  }
  React.useEffect(() => {
    if (!order) return
    if (!isOrderTrackingSelectPayment) return // OrderTracking 選擇付款方式用
    if (!order.merchantId) return
    if (!payingMerchant || payingMerchant.id !== order.merchantId) {
      setMerchantLoading(true)
      // merchant 不同，重新抓 merchant 放到 tempMerchant
      getPayingMerchant()
    } else {
      setMerchantLoading(false)
    }
  }, [payingMerchant?.id, order])

  if (isOrderTrackingSelectPayment && !order) return null

  const handleSuccess2c2p = ({
    cardType,
    encryptedData,
    tokenType2c2p,
    saveCard,
  }) => {
    dispatch(actions.payment.updatePaymentMethod(PaymentMethods.CREDIT_CARD))
    dispatch(actions.payment.updatePaymentGateway(PaymentGateway.PAYMENT_GATEWAY_2C2P))
    dispatch(actions.payment.update2c2pToken({
      token: encryptedData.encryptedCardInfo,
      last4: encryptedData.maskedCardInfo.slice(-4),
      cardType: cardType,
      tokenType: tokenType2c2p.SECURE_PAY_TOKEN,
    }))
    dispatch(actions.payment.updateSavePayment(saveCard))

    if (isOrderTrackingSelectPayment) {
      dispatch(actions.payment.payOrder(order))
      history.goBack()
    } else {
      // 堂食後付款 (內用後結帳)
      if (isTable && !merchant.setting.payFirst) {
        // 使用DimPay 的餐廳無法用WeChat/Alipay 的In-App Browser 進行付款
        if (userAgentDetection.isInApp()) {
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.checkout_not_support_in_app.title'),
            message: t('app.component.alert.checkout_not_support_in_app.message'),
            dialogProps: { disableBackdropClick: true },
            buttons: [{ text: t('app.common.confirm') }],
          }))
        } else {
          history.replaceWithSearch(`${d2cBaseUrl}/pay`)
        }
      } else {
        history.replaceWithSearch(`${d2cBaseUrl}/checkout`)
      }
    }
  }

  const renderLoading = () => {
    return (
      <PageContainer className={classes.loadingContainer}>
        <CircularProgress size={80} />
      </PageContainer>
    )
  }

  const renderMainContent = () => {
    return (
      <PageContainer className={classes.container}>
        <SecureForm2c2p onSuccess={handleSuccess2c2p} />
      </PageContainer>
    )
  }

  const renderContentOrLoading = () => {
    if (isOrderTrackingSelectPayment) {
      if (isMerchantLoading || !payingMerchant || payingMerchant.id !== order.merchantId) {
        return renderLoading()
      } else {
        return renderMainContent()
      }
    }

    return renderMainContent()
  }

  return (
    <Page>
      <Navbar title={t('app.page.setting_payment.add_card.title')} />
      {renderContentOrLoading()}
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  text: {
    fontSize: '1.6rem',
  },
  loadingContainer: {
    marginTop: constants.app.marginTop.NAVBAR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
