import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import React from 'react'

import { RESTAURANT_COVER, transformation } from '@/libs/cloudinary'
import { useMode, useWindowSize } from '@/hooks/useWindowSize'
import { useRestaurantQuery } from '@/hooks/queries/useRestaurantQuery'
import constants from '@/constants'

/**
 *
 * @param {{
 * innerRef: object
 * }} props
 * @returns
 */
export default function RestaurantCoverImage (props) {
  const { innerRef } = props
  const classes = useStyles(props)
  const { isMobile } = useMode()
  const { innerWidth } = useWindowSize()
  const { data: restaurant, isLoading } = useRestaurantQuery()
  const imageUrl = transformation(restaurant?.coverImage, { width: isMobile ? RESTAURANT_COVER.sm.width : RESTAURANT_COVER.md.width })
  const width = innerWidth + (parseInt(constants.app.safeArea.top) * 2)
  const height = width / 2

  if (isLoading) {
    return (
      <div>
        <Skeleton
          variant='rect'
          width={width}
          height={height}
          className={classes.skeleton}
        />
      </div>
    )
  }

  return (
    <div
      ref={innerRef}
      className={classes.coverImage}
      style={{ backgroundImage: `url(${imageUrl})`, width }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  coverImage: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    width: `calc(100% + ${constants.app.safeArea.top} + ${constants.app.safeArea.top})`, // 讓圖片的相對高度在有 safeArea 的情況下與沒有 safeArea 的情況一致 (aspectRatio: 1:0.5)
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transformOrigin: 'top',
    transform: 'scale(1.01) translateY(-1%)', // 隱藏 blur 效果下的圖片白邊
    maxHeight: 190,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 320,
    },
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '50%',
    },
  },
  skeleton: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    maxHeight: 190,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 320,
    },
  },
}))
