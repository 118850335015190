import { RESTAURANT_CARD } from '@/components/Cards/RestaurantCard'
import { RESTAURANT_IMAGE, transformation } from '@/libs/cloudinary'
import { makeStyles } from '@material-ui/core/styles'
import CloseLayer from './CloseLayer'
import React from 'react'

// eslint-disable-next-line no-unused-vars
import { INextStartOpening } from '@/redux/landing/Landing.d'

/**
 * @typedef RestaurantImageProps
 * @property {string} image
 * @property {boolean} open
 * @property {INextStartOpening} nextStartOpening
 * @property {IPickerRange} pickerRange
 * @param {RestaurantImageProps} props
 * @returns
 */
export default function RestaurantImage (props) {
  const { image, open, nextStartOpening, pickerRange } = props
  const classes = useStyles(props)
  const imageUrl = transformation(image, { width: RESTAURANT_IMAGE.width })

  return (
    <div className={classes.imageWrap}>
      <div className={classes.image} style={{ backgroundImage: `url(${imageUrl})` }} />
      <CloseLayer open={open} nextStartOpening={nextStartOpening} pickerRange={pickerRange} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  imageWrap: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: RESTAURANT_CARD.card.md.borderRadius,
  },
  image: {
    position: 'relative',
    borderRadius: RESTAURANT_CARD.card.md.borderRadius,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.grey[300],
    cursor: 'pointer',
    transition: 'transform 0.5s ease',
    aspectRatio: RESTAURANT_CARD.coverImage.aspectRatio,
  },
}))
