import { DEFAULT_PER_PAGE, useBaseQueryParams } from '@/hooks/queries/params'
import { useDatetime, useDeliveryType } from '../app'
import { useQuery } from '@tanstack/react-query'
import { useRestaurantsRefetchInterval, useRestaurantsStaleTime } from './options'
import _ from 'lodash'
import landingApi from '@/libs/api/landing'

/**
 * 用於菜式頁面的 InfiniteQueryList
 * 根據所選菜式顯示屬於該菜式的餐廳
 * @param {string} cuisinePayload The payload of the cuisine.
 * @param {number} [perPage = 25]
 * @returns
 */
export function useCuisineRestaurantsQueryOptions (cuisinePayload, perPage = DEFAULT_PER_PAGE) {
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const queryKey = isImmediate
    ? ['landing', 'cuisine', cuisinePayload, 'restaurants', 'paginated', { deliveryType, isImmediate }]
    : ['landing', 'cuisine', cuisinePayload, 'restaurants', 'paginated', { deliveryType, date, time }]
  const queryParams = useBaseQueryParams({ limit: perPage, cuisines: [cuisinePayload] })
  const queryFn = ({ pageParam = 1 }) => fetchFn(pageParam)
  async function fetchFn (page) {
    const pageData = await landingApi.searchCuisineRestaurants(cuisinePayload, { ...queryParams, page })
    const meta = _.chain(pageData).omit('restaurants').assign({ page, perPage }).value()
    const restaurants = pageData?.restaurants ?? []
    const total = pageData?.total ?? 0
    return {
      meta,
      data: restaurants,
      total,
      perPage,
      nextCursor: page + 1,
      prevCursor: Math.max(0, page - 1),
    }
  }
  const staleTime = useRestaurantsStaleTime()
  const refetchInterval = useRestaurantsRefetchInterval()
  return { queryKey, queryFn, staleTime, refetchInterval }
}

/**
 *
 * @param {string} cuisinePayload The payload of the cuisine.
 * @returns
 */
export function useCuisineRestaurantsQuery (cuisinePayload) {
  const options = useCuisineRestaurantsQueryOptions(cuisinePayload)
  const query = useQuery(options)
  return query
}
