import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

import constants from '@/constants'

Header.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
}

/**
 *
 * @param {{
 * onBack?: function
 * title?: string
 * children?: any
 * className?: string
 * }} props
 * @returns
 */
export default function Header (props) {
  const classes = useStyles(props)
  const { onBack, title, children, className } = props

  return (
    <header className={clsx(classes.header, className)} {...props}>
      <div className={classes.nav}>
        {Boolean(onBack) &&
          <IconButton
            className={classes.backButton}
            onClick={onBack}
          >
            <ArrowBackIosIcon fontSize='small' />
          </IconButton>}

        {Boolean(title) &&
          <Typography className={classes.title}>
            {title}
          </Typography>}
      </div>
      <div className={classes.content}>
        {children}
      </div>
    </header>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    flexShrink: 0,
    minHeight: 160,
    padding: theme.spacing(0, 2),
    paddingTop: constants.app.safeArea.top,
    background: `linear-gradient(45deg, ${theme.palette.primary.customDark} 0%, ${theme.palette.primary.customLight} 100%)`,
    display: 'flex',
    flexDirection: 'column',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '150%',
      height: '100%',
      background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 50%)',
      transform: 'rotate(45deg) translate(0%, 50%)',
      opacity: 0.5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '150%',
      height: '100%',
      background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.35) 50%)',
      transform: 'rotate(-45deg) translate(10%, 30%)',
      opacity: 0.5,
    },
    '& > *': {
      zIndex: 2,
    },
  },
  nav: {
    position: 'absolute',
    left: 0,
    top: constants.app.safeArea.top,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    justifyItems: 'baseline',
    alignItems: 'center',
    zIndex: 3,
    padding: theme.spacing(0, 2),
  },
  title: {
    fontSize: '1.8rem',
    textAlign: 'center',
    zIndex: 'inherit',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
