import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import CategoryLink from './CategoryLink'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

SubCategoryList.propTypes = {
  list: PropTypes.array,
  selectedRoot: PropTypes.object,
  setSelectedRoot: PropTypes.func,
}

/**
 *
 * @param {{
 * list: IAppCategory[]
 * selectedRoot: IAppCategory
 * setSelectedRoot: Function
 * }} props
 */
export default function SubCategoryList (props) {
  const { list, selectedRoot, setSelectedRoot } = props
  const [width, setWidth] = useState(0)
  const classes = useStyles({ ...props, width })
  const subCategoryListRef = useRef()

  useEffect(() => {
    if (subCategoryListRef.current?.scrollWidth && subCategoryListRef.current?.scrollWidth !== width && width === 0) {
      setWidth(subCategoryListRef.current.scrollWidth)
    }
  }, [])

  if (_.isEmpty(list)) return null
  return (
    <ul
      className={classes.subCategoryList}
      ref={subCategoryListRef}
    >
      {_.map(list, (category, index) => {
        return (
          <CategoryLink
            key={index}
            item={category}
            selectedRoot={selectedRoot}
            setSelectedRoot={setSelectedRoot}
            isSubCategory
          />
        )
      })}
    </ul>

  )
}

const useStyles = makeStyles(theme => ({
  subCategoryList: {
    flexShrink: 0,
    display: 'flex',
    overflow: 'hidden',
    // transition: 'all 200ms ease',
    width: props => props.display ? props.width : 0,
  },
}))
