const userAgent = navigator.userAgent || navigator.vendor || window.opera

function isInApp () {
  const inapp = ['AliApp', 'Alipay', 'MicroMessenger', 'Line', 'Instagram', 'Messanger']
  const allowinapp = (new URL(document.location)).searchParams.get('allowinapp')
  if (allowinapp) {
    return false
  }
  return inapp.some(e => userAgent.includes(e))
}

// for future use only
function detectBrowser () {
  let browserName

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge'
  // } else if (userAgent.match(/android/i)) {
  //   browserName = 'Android'
  // } else if (userAgent.match(/iphone/i)) {
  //   browserName = 'iPhone'
  } else {
    browserName = 'Unknown'
  }

  return browserName
}

export default { isInApp, detectBrowser }
