import { useRouteMatch } from 'react-router-dom'

import { useSelector } from '@/redux'

/**
 * 如果在餐廳模式會拿到 /d2c/:id 否則會拿到空字串
 * @returns {string}
 */
export default function useD2CBaseUrl () {
  const routeMatch = useRouteMatch('/d2c/:id')
  const params = useSelector(state => state.app.params)
  const merchantId = useSelector(state => state.merchant?.data?.id)

  if (routeMatch?.url) return routeMatch.url
  if (!params.isD2CWeb || !merchantId) return ''
  return `/d2c/${merchantId}`
}
