import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

SaveButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

/**
 *
 * @param {{
 * title: String
 * onClick: Function
 * disabled?: Boolean
 * className: String
 * }} props
 * @returns
 */
export default function SaveButton (props) {
  const { title, onClick, disabled, className } = props
  const { t } = useTranslation()

  return (
    <LetterSpacingButton
      className={className}
      text={title ?? t('app.common.save')}
      disabled={disabled}
      onClick={onClick}
    />
  )
}
