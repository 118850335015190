import _ from 'lodash'

import { checkIsDimbox } from '@/libs/dimbox'
import { useDeliveryType, useSystem } from './app'
import { useSelector } from '@/redux'

// eslint-disable-next-line no-unused-vars
import { IDimbox } from '@/redux/dimbox/Dimbox.d'

/**
 *
 * @returns {IDimbox[]}
 */
export function useSelectedCabinetBoxes () {
  const dimboxes = useSelector(state => state.dimbox.dimboxes)
  const selectedCabinetId = useSelector(state => state.dimbox.selectedCabinetId)
  const selectedCabinetBoxes = _.filter(dimboxes, box => box.cabinetId === selectedCabinetId)
  return selectedCabinetBoxes ?? []
}

/**
 *
 * @param {'takeaway' | 'storeDelivery' | 'table'} [deliveryTypeParam]
 * @returns
 */
export function useIsDimbox (deliveryTypeParam) {
  const system = useSystem()
  const isDimboxEnable = system.dimbox.enable
  const isTakeawayEnable = system.takeaway.enable
  const restaurant = useSelector(state => state.landing.restaurant)
  const restaurantTags = restaurant?.tags ?? []
  const hasDimobxTag = restaurantTags.some(tag => tag?.toLowerCase() === 'dimbox')
  const { deliveryType } = useDeliveryType(deliveryTypeParam)
  return checkIsDimbox(isDimboxEnable, isTakeawayEnable, hasDimobxTag, deliveryType)
}
