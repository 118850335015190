import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

RemarkInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

/**
 * 餐點備註
 * 1. 餐廳設定不開放
 *
 * @param {{
 * value: String
 * onChange: Function
 * }} props
 * @returns
 */
export default function RemarkInput (props) {
  const { t } = useTranslation()
  const { value, onChange } = props
  const classes = useStyles(props)
  const merchant = useSelector(state => state.merchant.data)
  const hidden = merchant?.setting?.disableItemRemarks

  if (hidden) {
    // 餐廳不開放輸入備註
    return null
  }

  return (
    <section style={{ marginBottom: 16 }}>
      <Typography className={classes.title} variant='body1'>
        {t('app.component.drawer.remark_input.title')}
      </Typography>
      <Typography className={classes.hint} variant='body2'>
        ({t('app.component.drawer.remark_input.helper')})
      </Typography>
      <div className={classes.inputWrap}>
        <textarea
          className={classes.input}
          value={value}
          onChange={onChange}
          placeholder={t('app.component.drawer.remark_input.placeholder')}
        />
      </div>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
    padding: theme.spacing(0, 1),
  },
  hint: {
    color: theme.palette.grey[500],
    padding: theme.spacing(0, 1),
  },
  inputWrap: {
    display: 'grid',
  },
  input: {
    minHeight: 80,
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    resize: 'none',
  },
}))
