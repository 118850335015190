import moment from 'moment'

import { actions } from '@/redux'
import dimboxAPi from '@/libs/api/dimbox'

import ActionTypes from './ActionTypes'
// import dimboxCutOfTime from '@/constants/dimboxCutOfTime'

/**
 *
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    try {
      await dispatch(updateDimboxes())
      dispatch({ type: ActionTypes.INIT })
    } catch (error) {
      console.error('[Dimbox/init] error', error)
    }
  }
}

/**
 *
 * @param {Date?} pickupAt
 * @returns {ThunkFunction}
 */
export function updateDimboxes (pickupAt) {
  return async (dispatch, getState) => {
    try {
      const date = pickupAt ? moment(pickupAt) : undefined
      // DimBox 餐廳當日截單時間為 10:30，可於當日 12:00~14:30 取餐。
      // 超過 10:30 下單，取餐時間為隔日 12:00~14:30。所以要看隔天的格子。
      // 每日 02:00 重置格子，00:00 ~ 02:00 之間都算當日所以要用 02:00 之後的時間
      const cutOffTime = moment().hours(10).minutes(30)
      const defaultDate = moment().isBetween(cutOffTime, moment().endOf('d'))
        ? moment().add(1, 'd').hour(8).minute(0)
        : moment().isBetween(moment().startOf('d'), moment().startOf('d').add(2, 'h').add(0, 'm'))
          ? moment().hour(8).minute(0)
          : moment()
      const dimboxes = await dimboxAPi.getDimboxes(date ?? defaultDate)
      dispatch({
        type: ActionTypes.UPDATE_DIMBOXES,
        payload: { boxes: dimboxes?.boxes ?? [], available: dimboxes?.available ?? 0 },
      })
    } catch (error) {
      console.error('[Dimbox/updateDimboxes] error', error)
    }
  }
}

/**
 *
 * @param {number} cabinetId
 * @returns {ThunkFunction}
 */
export function selectCabinet (cabinetId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_CABINET,
      payload: { cabinetId },
    })
  }
}

/**
 *
 * @param {number} cabinetId
 * @param {string} orderId
 * @param {string} boxId
 * @returns {ThunkFunction}
 */
export function openDimbox (cabinetId, orderId, boxId) {
  return async (dispatch, getState) => {
    try {
      await dimboxAPi.openDimbox(cabinetId, orderId, boxId)
    } catch (error) {
      console.error('[Dimbox/openDimbox] error', error)
      dispatch(actions.app.toggleAlert({
        title: 'Error',
        message: error.response.data?.error,
      }))
    }
  }
}
