import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @typedef CommentProps
 * @property {string} comment
 * @param {CommentProps} props
 * @returns
 */
export default function Comment (props) {
  const { comment } = props
  const classes = useStyles(props)

  if (!comment) return null
  return (
    <Typography variant='caption' className={classes.comment}>{comment}</Typography>
  )
}

const useStyles = makeStyles(theme => ({
  comment: { },
}))
