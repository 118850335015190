// eslint-disable-next-line no-unused-vars
import { Badge, ButtonBase, ButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import EditAvatar from '@/assets/icons/EditAvatar'
import MemberAvatar from '@/components/MemberAvatar'

const badgeAnchorOrigin = { vertical: 'bottom', horizontal: 'right' }

/**
 * @typedef BadgedAvatorButton
 * @property {ButtonProps} [buttonProps={}]
 * @param {BadgedAvatorButton} props
 * @returns
 */
export default function BadgedAvatorButton (props) {
  const classes = useStyles(props)
  const { buttonProps = {} } = props

  return (
    <ButtonBase className={classes.button} {...buttonProps}>
      <Badge
        badgeContent={<EditAvatar />}
        anchorOrigin={badgeAnchorOrigin}
        classes={{ badge: classes.badge }}
      >
        <MemberAvatar size={80} />
      </Badge>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '50%',
  },
  badge: {
    height: 'auto',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(0),
    borderRadius: '50%',
    transform: 'none',
  },
}))
