import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

InputBase.propTypes = {
  inputId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  textarea: PropTypes.bool,
  rows: PropTypes.number,
  className: PropTypes.string,
}

/**
 *
 * @param {{
 * inputId: string
 * label: string
 * value: string
 * onChange: Funcion
 * onClick?: Function
 * placeholder: string
 * readOnly?: boolean
 * textarea?: boolean
 * rows?: number
 * className?: string
 * }} props
 * @returns
 */
export default function InputBase (props) {
  const classes = useStyles(props)
  const {
    inputId,
    label,
    value,
    onChange,
    onClick,
    placeholder,
    readOnly,
    textarea,
    rows,
    className,
    labelClassName,
    inputClassName,
    disabled,
  } = props

  return (
    <div
      className={clsx(classes.inputBase, className)}
    >
      <Typography
        component='label'
        variant='body2'
        className={clsx(classes.label, labelClassName)}
        htmlFor={inputId}
      >
        {label}
      </Typography>
      <Typography
        id={inputId}
        component={textarea ? 'textarea' : 'input'}
        variant='caption'
        className={clsx(classes.input, inputClassName)}
        value={value ?? ''}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={rows}
        disabled={disabled}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  inputBase: {
    display: 'grid',
    gridTemplateColumns: 'minmax(40px, 100px) 1fr',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    '& ::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: theme.palette.grey[500],
      opacity: 1, /* Firefox */
    },
    '& :-ms-input-placeholder': { /* Internet Explorer 10-11 */
      color: theme.palette.grey[500],
    },
    '& ::-ms-input-placeholder': { /* Microsoft Edge */
      color: theme.palette.grey[500],
    },
  },
  label: {
    fontWeight: 'bold',
    margin: theme.spacing(0.5),
  },
  input: {
    resize: 'none',
    minWidth: '100%',
    maxWidth: '100%',
    border: '2px solid transparent',
    borderRadius: theme.spacing(0.5),
    '&:focus': {
      outline: 'none',
      border: `2px solid ${theme.palette.secondary.main}`,
    },
  },
}))
