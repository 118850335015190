import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'

/**
 * 點擊開啟條款連結
 * 沒連結不顯示
 *
 * @param {
 * link?: string
 * } props
 * @returns
 */
export default function TncButton (props) {
  const { link } = props
  const classes = useStyles(props)
  const handleClick = () => window.open(link, '_blank')

  if (!link) return null
  return (
    <IconButton className={classes.tncButton} onClick={handleClick}>
      <InfoOutlinedIcon className={classes.infoIcon} />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  tncButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(1.5),
    padding: theme.spacing(0),
  },
  infoIcon: {
    color: theme.palette.common.white,
  },
}))
