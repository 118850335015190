import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CategoryList from './CategoryList'
import MenuItemList from './MenuItemList'

import React from 'react'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

/**
 * @param {{
 * item: IAppCategory
 * }} props
 */
export default function CategoryItem (props) {
  const { category } = props
  const classes = useStyles(props)

  const menus = React.useMemo(() => {
    return [...category.sets, ...category.menus]
  }, [category.menus, category.sets])

  return (
    <>
      <div className={classes.categoryItem}>
        <div className={classes.categoryNameWrap}>
          {/* {category.id === 'promoted-category' && <HotSaleIcon className={classes.promotedIcon} />} */}
          <Typography className={classes.name} variant='h5'>{category.name}</Typography>
        </div>
        <MenuItemList categoryId={category.id} menus={menus} />
      </div>

      {/* Sub Categories */}
      <CategoryList categories={category.categories} />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  categoryItem: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    paddingBottom: theme.spacing(4), // 改這個需要改 CategoryLink 的 CATEGORY_ITEM_PADDING_BOTTOM
  },
  categoryNameWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: 8,
  },
  name: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  // promotedIcon: {
  //   marginRight: 4,
  // },
}))
