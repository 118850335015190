import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useCart } from '@/hooks/useCart'
import CartIcon from '@/assets/icons/Cart'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CartButton (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { hasItemsInCart } = useCart()
  const disabled = !hasItemsInCart

  return (
    <IconButton
      className={classes.basketButton}
      onClick={() => dispatch(actions.app.toggleDrawer('cart'))}
      disabled={disabled}
    >
      <Badge
        color='secondary'
        variant='dot'
        invisible={disabled}
        classes={{
          anchorOriginTopRightRectangular: classes.anchorOriginTopRightRectangular,
          colorSecondary: classes.colorSecondary,
        }}
      >
        <CartIcon color={disabled ? 'disabled' : 'inherit'} />
      </Badge>
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  basketButton: {
    padding: theme.spacing(0.5),
  },
  anchorOriginTopRightRectangular: {
    transform: 'scale(1) translate(5%, 15%)',
  },
  colorSecondary: {
    backgroundColor: 'red',
  },
}))
