import { RESTAURANT_LOGO, transformation } from '@/libs/cloudinary'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useAddress as useMerchantAddress } from '@/hooks/merchant'
import { useSelector } from '@/redux'

import AddressIcon from '@/assets/icons/lamaIcons/address.svg'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MerchantInfo (props) {
  const merchantAddress = useMerchantAddress()
  const restaurant = useSelector(state => state.landing.restaurant)
  const classes = useStyles({
    ...props,
    logoImageUrl: transformation(restaurant?.logoImage, { width: RESTAURANT_LOGO.sm.width }),
  })

  return (
    <div className={classes.merchantInfo}>
      <div className={classes.logo} />
      <div className={classes.merchantDetails}>
        <span className={classes.bestLabel}>
          <Typography variant='body1' className={classes.bestText}>
            BEST
          </Typography>
        </span>
        <Typography variant='h5' className={classes.restaurantName}>
          {restaurant?.name}
        </Typography>
        <div className={classes.addressContainer}>
          <img src={AddressIcon} className={classes.addressIcon} />
          <Typography noWrap variant='body2' className={classes.address}>{merchantAddress}</Typography>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  merchantInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    marginBottom: 16,
  },
  logo: {
    width: 66,
    height: 66,
    backgroundImage: props => `url(${props.logoImageUrl})`,
    borderRadius: 19,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 8,
  },
  merchantDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  bestLabel: {
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    transform: 'scale(0.7)',
    transformOrigin: 'left',
    backgroundColor: '#ff6b1d',
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 8,
  },
  bestText: {
    fontSize: '1rem',
    color: theme.palette.common.white,
  },
  restaurantName: {
    marginTop: 4,
    marginBottom: 4,
    fontSize: '1.5rem',
    fontWeight: 900,
    margin: '2px 8px',
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    marginRight: 8,
  },
  addressIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
  address: {
    flex: 1,
    // maxWidth: 150,
    fontSize: '1rem',
    color: theme.palette.text.primary.light,
    textOverflow: 'ellipsis',
  },
}))
