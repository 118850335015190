import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useDeliveryType, useTable } from '@/hooks/app'
import { useSelectedOrderHistory } from '@root/src/hooks/orderHistory'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

/**
 *
 * 非內用不顯示
 * useSelectedOrderHistory 沒有 items 不顯示
 * @param {{
 * hidden?: boolean
 * }} props
 * @returns
 */
export default function CheckOrderHistoryButton (props) {
  const { hidden } = props
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles(props)
  const { pathname } = useLocation()
  const d2cBaseUrl = useD2CBaseUrl()
  const table = useTable()
  const { isTable } = useDeliveryType()
  const orderHistory = useSelectedOrderHistory() ?? {}
  const totalQuantity = _.reduce(orderHistory.batches, (acc, batch) => acc + _.size(batch.items), 0)
  const orderHistoryItems = _.flatMap(orderHistory.batches, batch => batch.items)

  const handleClick = () => {
    history.pushWithSearch(`${d2cBaseUrl}/order`)
  }

  if (hidden) return null
  if (pathname.includes('/pay')) return null
  if (!isTable) return null
  if (!table) return null
  if (!_.size(orderHistoryItems)) return null
  return (
    <Button
      color='secondary'
      variant='contained'
      fullWidth
      disableElevation
      className={classes.checkOrderHistoryButton}
      onClick={handleClick}
    >
      {t('app.component.check_order_history_button', { total: totalQuantity })}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  checkOrderHistoryButton: {
    marginBottom: theme.spacing(1),
    minHeight: 32,
  },
}))
