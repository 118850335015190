import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Clipboard } from '@capacitor/clipboard'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CopyIcon from '@material-ui/icons/FileCopy'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { flush, logId } from '@/libs/logger'
import { useShowSnackbar } from '@/hooks/app'
import InputDialog from '@/components/Dialogs/InputDialog'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import colors from '@/theme/colors'
import config from '@/config'
import constants from '@/constants'
import vConsole from '@/libs/vConsole'

export default function Version (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const showSnackbar = useShowSnackbar()
  const codePushPackageMeta = useSelector(state => state.app.codePushPackageMeta)
  const params = useSelector(state => state.app.params)
  const appInfo = useSelector(state => state.app.appInfo)
  const deviceId = useSelector(state => state.app.deviceId)
  const deviceInfo = useSelector(state => state.app.deviceInfo)
  const member = useSelector(state => state.user.member)
  const [debugMode, setDebugMode] = React.useState(config.debugMode || false)
  const [deployment, setDeployment] = React.useState(config.deployment || null)
  const [isVConsoleOn, setIsVConsoleOn] = React.useState(Boolean(vConsole.vConsole))
  const [promoCodeDialogOpen, setPromoCodeDialogOpen] = React.useState(false)

  const debugInfo = {
    customerId: member?.id,
    logId: logId,
    deviceId: deviceId,
    deployment,
    env: config.env,
    platform: config.platform,
    nativeVersion: appInfo && `${appInfo.version} (${appInfo.build})`,
    packageVersion: `${config.version} (${config.commit})`,
    updatedRelease: codePushPackageMeta?.label,
  }

  const debugInfoString = '```\n' + JSON.stringify(debugInfo, null, 2) + '\n```'

  const renderCopyButton = () => {
    return (
      <IconButton
        classes={{ root: classes.iconButtonRoot }}
        onClick={() => {
          Clipboard.write({
            string: debugInfoString,
          })
          showSnackbar('版本資訊已複製')
        }}
      >
        <CopyIcon fontSize='small' className={classes.copyIcon} />
      </IconButton>
    )
  }

  return (
    <Page>
      <Navbar
        title={t('app.page.setting_version.navbar.title')}
        rightColumn={renderCopyButton()}
      />
      <PageContainer className={classes.container}>
        <List dense>
          <ListItem dense>
            <ListItemText primary='Environment' secondary={config.env === 'prod' ? 'Production' : config.env} />
            {(debugMode || config.env !== 'prod') && (
              <Select
                value={config.env}
                onChange={(event) => dispatch(actions.app.overwriteEnv(event.target.value))}
              >
                <MenuItem value='test'>Test</MenuItem>
                <MenuItem value='beta'>Beta</MenuItem>
                <MenuItem value='prod'>Production</MenuItem>
              </Select>
            )}
          </ListItem>
          {member?.id && (
            <ListItem
              dense
              button
              onClick={() => {
                Clipboard.write({
                  string: member.id,
                })
                showSnackbar('Customer id 已複製')
              }}
            >
              <ListItemText primary='Customer Id' secondary={member.id} />
            </ListItem>
          )}
          <ListItem
            dense
            button
            onClick={() => {
              Clipboard.write({
                string: logId,
              })
              showSnackbar('Log id 已複製')
            }}
          >
            <ListItemText primary='Log Id' secondary={logId} />
          </ListItem>
          <ListItem
            dense
            button
            onClick={() => {
              Clipboard.write({
                string: deviceId,
              })
              showSnackbar('Device id 已複製')
            }}
          >
            <ListItemText primary='Device Id' secondary={deviceId} />
          </ListItem>
          {(config.env !== 'prod' || debugMode) && (
            <ListItem dense>
              <ListItemText primary='Debug Mode' secondary={debugMode ? 'ON' : 'OFF'} />
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => {
                  setDebugMode(!debugMode)
                  dispatch(actions.app.updateDebugMode(!debugMode))
                }}
              >
                {`TRUN ${debugMode ? 'OFF' : 'ON'}`}
              </Button>
            </ListItem>
          )}
          {(debugMode || isVConsoleOn) && (
            <ListItem dense>
              <ListItemText primary='vConsole' secondary={isVConsoleOn ? 'ON' : 'OFF'} />
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={async () => {
                  const newParams = {
                    ...params,
                  }

                  if (isVConsoleOn) {
                    // delete vc flag
                    delete newParams.vc
                    localStorage.setItem('params', JSON.stringify(newParams))

                    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
                    await flush()
                    // reload
                    document.location.href = '/'
                  } else {
                    newParams.vc = 1
                    localStorage.setItem('params', JSON.stringify(newParams))
                    vConsole.init()
                    setIsVConsoleOn(true)
                  }
                }}
              >
                {`TRUN ${isVConsoleOn ? 'OFF' : 'ON'}`}
              </Button>
            </ListItem>
          )}
          {debugMode && (
            <ListItem dense>
              <ListItemText primary='領取優惠券' />
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => setPromoCodeDialogOpen(true)}
              >
                輸入 id
              </Button>
            </ListItem>
          )}

          <ListSubheader color='inherit' className={classes.subHeader}>Version info</ListSubheader>
          {appInfo?.version && (
            <ListItem dense>
              <ListItemText primary='App Version' secondary={`${appInfo.version}`} />
            </ListItem>
          )}
          <ListItem dense>
            <ListItemText primary='Package Version' secondary={`${config.version} (${config.commit})`} />
            {config.platform !== 'web' && debugMode && (
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => dispatch(actions.app.codePushSync(true))}
              >
                檢查更新
              </Button>
            )}
          </ListItem>
          {config.platform !== 'web' && (
            <ListItem dense>
              <ListItemText primary='Deployment' secondary={config.deployment} />
              {debugMode && (
                <Select
                  value={deployment || config.deployment}
                  onChange={(event) => {
                    setDeployment(event.target.value)
                    dispatch(actions.app.overwriteDeployment(event.target.value))
                  }}
                >
                  <MenuItem value={null}>預設</MenuItem>
                  <MenuItem value='Test'>Test</MenuItem>
                  <MenuItem value='Beta'>Beta</MenuItem>
                  <MenuItem value='Production-Smoke'>Production-Smoke</MenuItem>
                  <MenuItem value='Production'>Production</MenuItem>
                </Select>
              )}
            </ListItem>
          )}
          {codePushPackageMeta && (
            <ListItem dense>
              <ListItemText primary='Updated Version' secondary={`${codePushPackageMeta.deployment} (${codePushPackageMeta.label})`} />
            </ListItem>
          )}

          {deviceInfo && (
            <>
              <ListSubheader color='inherit' className={classes.subHeader}>Device info</ListSubheader>
              <ListItem dense>
                <ListItemText primary='Model' secondary={deviceInfo.model} />
              </ListItem>
              <ListItem dense>
                <ListItemText primary='Platform' secondary={deviceInfo.platform} />
              </ListItem>
              <ListItem dense>
                <ListItemText primary='OS' secondary={deviceInfo.operatingSystem} />
              </ListItem>
              <ListItem dense>
                <ListItemText primary='OS Version' secondary={deviceInfo.osVersion} />
              </ListItem>
            </>
          )}
        </List>
      </PageContainer>
      <InputDialog
        open={promoCodeDialogOpen}
        onClose={() => setPromoCodeDialogOpen(false)}
        title='領取優惠券'
        placeholder='優惠券 ID'
        onConfirm={(promoCodeId) => {
          dispatch(actions.user.claimCoupon(promoCodeId))
        }}
      />
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    padding: 0,
    paddingBottom: theme.spacing(8),
  },
  subHeader: {
    backgroundColor: colors.grey[50],
  },
  iconButtonRoot: {
    padding: theme.spacing(1),
  },
  copyIcon: {
    // src/components/Navbar/BackButton.jsx
    color: props => props.color,
    fontSize: '1.8rem',
    transform: 'translateX(-3px)',
  },
}), { name: 'Version' })
