import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

/**
 * @param {{
 * hidden?: boolean
 * onClick: Function
 * }} props
 * @returns
 */
export default function ClearButton (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { hidden, onClick } = props

  if (hidden) return null
  return (
    <ButtonBase onClick={onClick}>
      <Typography variant='body2' className={classes.text}>
        {t('app.common.clear')}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  text: { },
}))
