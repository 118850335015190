import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import { useOrderTrackingSnackbar, useParams } from '@/hooks/app'
import constants from '@/constants'

import Alert from '@/components/Alerts/Alert'
import BottomNavigation from '@/components/BottomNavigation'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import Tabs from '@/components/Tabs'

import OrderHistoryItemList from './OrderHistoryItemList'
import RedirectView from './RedirectView'

import { ReactComponent as RedirectLoginIcon } from '@/assets/icons/redirect_login.svg'
import { ReactComponent as RedirectOrdersIcon } from '@/assets/icons/redirect_orders.svg'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType
const { OCTOPUS } = constants.paymentMethod

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderHistory (props) {
  useOnStatusTapToPageTop()

  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const showOrderTrackingSnackbar = useOrderTrackingSnackbar()
  const classes = useStyles({ ...props, showOrderTrackingSnackbar })
  const { isD2CWeb, method, ref, success } = useParams()
  const isLogin = useIsLogin()
  const isInit = useSelector(state => state.orderHistory.isInit)
  const orderHistories = useSelector(state => state.orderHistory.orders)
  const [openOctopusPaymentResult, setOpenOctopusPaymentResult] = React.useState(false)

  const completedOrders = useMemo(() => {
    return _.groupBy(orderHistories, orderHistory => _.some([
      orderHistory?.deliveryType === TABLE && orderHistory?.status === 'paid', // 內用單流程結束
      orderHistory?.deliveryType === TAKEAWAY && orderHistory?.takeawayStatus === 'completed', // 外帶單流程結束
      orderHistory?.deliveryType === STORE_DELIVERY && orderHistory?.takeawayStatus === 'delivered', // 外送單流程結束
      orderHistory?.status === 'cancelled', // 訂單被取消
    ], valid => valid))
  }, [orderHistories])
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    // 如果沒有進行中的訂單則自動選擇『已完成』的 tab
    if (isInit && !_.size(completedOrders.false)) {
      setSelectedTab(1)
    }
  }, [isInit, completedOrders])

  useEffect(() => {
    if (method === OCTOPUS) {
      setOpenOctopusPaymentResult(true)
    }
  }, [isInit, method, ref, success])

  const alertConfig = {
    title: t(`app.component.alert.octopusPaymentResult.title.${success}`),
    message: t(`app.component.alert.octopusPaymentResult.message.${success}`),
    buttons: [
      {
        text: t('app.component.alert.octopusPaymentResult.close'),
        onClick: () => {
          setOpenOctopusPaymentResult(false)
        },
      },
      {
        text: t('app.component.alert.octopusPaymentResult.viewOrder'),
        style: { backgroundColor: theme.palette.primary.main },
        loading: !isInit,
        onClick: () => {
          setOpenOctopusPaymentResult(false)
          const currentOrder = orderHistories.find(order => {
            const currentPayment = order.payments.find(payment => payment.ref === ref)
            return Boolean(currentPayment)
          })
          if (currentOrder) {
            // 找到訂單，前往 order tracking
            history.push(`/order_tracking/${currentOrder.id}`)
          } else {
            // 找不到訂單，顯示 alert
            dispatch(actions.app.showAlert({
              title: t('app.page.order_tracking.return_prev_browser_alert.title'),
              message: t('app.page.order_tracking.return_prev_browser_alert.message'),
            }))
          }
        },
      },
    ],
  }

  const renderContent = () => {
    // 沒登入但是有訂單（匿名登入的堂食訂單，先顯示）
    let list = []
    if (_.size(orderHistories) > 0) {
      switch (selectedTab) {
        case 0:
          // 進行中
          list = completedOrders.false
          if (!_.size(list)) return <Typography>沒有進行中的訂單</Typography>
          return <OrderHistoryItemList list={list} />

        case 1:
          // 已完成
          list = completedOrders.true
          if (!_.size(list)) return <Typography>沒有已完成的訂單</Typography>
          return <OrderHistoryItemList list={list} />

        case 2:
          // 全部訂單
          list = _.chain(completedOrders.false).concat(completedOrders.true).without(undefined).value()
          return <OrderHistoryItemList list={list} />

        default:
          break
      }
    } else if (!isLogin) {
      // 沒訂單也沒登入，顯示註冊/登入
      return (
        <RedirectView
          renderIcon={() => <RedirectLoginIcon />}
          title={t('app.page.order_history_list.redirect_login.title')}
          subtitle={t('app.page.order_history_list.redirect_login.subtitle')}
          action={t('app.page.order_history_list.redirect_login.action')}
          onClick={() => dispatch(actions.app.toggleLoginDrawer(true))}
        />
      )
    } else {
      // 有登入但沒訂單
      return (
        <RedirectView
          renderIcon={() => <RedirectOrdersIcon />}
          title={t('app.page.order_history_list.redirect_home.title')}
          // subtitle={t('app.page.order_history_list.redirect_home.subtitle')}
          action={t('app.page.order_history_list.redirect_home.action')}
          onClick={() => {
            history.goBack()
          }}
        />
      )
    }
  }

  return (
    <Page>
      <Navbar
        title={t('app.page.order_history_list.navbar.title')}
      />
      <Tabs
        className={classes.tabs}
        list={[
          t('app.page.order_history_list.tab.processing'),
          t('app.page.order_history_list.tab.completed'),
          t('app.page.order_history_list.tab.all'),
        ]}
        selected={selectedTab}
        setSelected={setSelectedTab}
      />
      <PageContainer className={clsx(classes.container, { [classes.marginFooter]: !isD2CWeb })}>
        {renderContent()}
      </PageContainer>
      <BottomNavigation hidden={isD2CWeb} />
      <Alert
        open={openOctopusPaymentResult}
        onClose={() => setOpenOctopusPaymentResult(false)}
        alertConfig={alertConfig}
      />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1.5),
    paddingBottom: props => props.showOrderTrackingSnackbar && constants.app.height.SNACKBAR + theme.spacing(2),
    overflow: 'auto',
  },
  marginFooter: {
    marginBottom: constants.app.marginBottom.FOOTER,
  },
  tabs: {
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0),
    zIndex: constants.app.zIndex.NAVBAR + 100,
    marginTop: constants.app.marginTop.NAVBAR,
  },
}))
