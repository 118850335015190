/** @type {IBaseConfig} */
const config = {
  api: {
    dimorderGo: 'https://api.dimorder.com',
    dimorderNode: 'https://node.dimorder.com',
    customer: 'https://customer-api.dimorder.com',
    landing: 'https://node.dimorder.com',
    lalamovePrice: 'https://delivery-price.dimorder.com',
    dimbox: 'https://box-api.dimorder.com',
  },
  stripe: {
    apiKey: 'pk_live_WNOFapnrT9mOGIxvr8AC8HxF00q6quvbyA',
  },
  firebase: {
    apiKey: 'AIzaSyAQef--6OoYEJs1OgntDq6LiDPgEEjbHug',
  },
  fps: {
    baseUrl: 'https://fps.dimorder.com/c/payment/fps',
    androidAction: 'hk.com.hkicl',
  },
  checkout: {
    publicKey: 'pk_68c92b86-5ea5-42fa-ae3d-d604d6cb8f56',
    visaPublicKey: 'pk_c4b5537d-6928-4229-81f4-144bf2db4776',
  },
  webUrl: 'https://c.dimorder.com',
  universalBaseUrl: 'https://app.dimorder.com',
  receiptUrl: 'https://receipt.dimorder.com',
  links: {
    tnc: 'https://www.dimorder.com/tnc/',
    privacy: 'https://www.dimorder.com/privacy-policy/',
  },
  my2c2p: {
    jsSrcSecureField: 'https://2c2p-uat-cloudfront.s3-ap-southeast-1.amazonaws.com/2C2PPGW/secureField/my2c2p-secureFields.1.0.0.min.js',
    jsSrcSecurePayment: 'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p-sandbox.1.7.3.min.js',
    jsSrcSecureFieldLive: 'https://2c2p-uat-cloudfront.s3-ap-southeast-1.amazonaws.com/2C2PPGW/secureField/my2c2p-secureFields.1.0.0.min.js',
    jsSrcSecurePaymentLive: 'https://t.2c2p.com/SecurePayment/api/my2c2p.1.7.3.min.js',
  },
  applePay2c2p: {
    merchantId: 'merchant.com.dimorder.customer',
    merchantDomain: 'c.dimorder.com',
  },
  fiserv: {
    connectUrl: 'https://www4.ipg-online.com/connect/gateway/processing',
    sharedSecret: 'Qs7D3c!(Tg',
    storeId: '4720096972',
    dateTimeFormat: 'YYYY:MM:DD-HH:mm:ss',
    defaultData: {
      timezone: 'Asia/Hong_Kong',
      hash_algorithm: 'HMACSHA256',
      currency: '344',
      checkoutoption: 'combinedpage',
      transactionNotificationURL: 'https://api.dimorder.com/s/hook/fiserv',
    },
  },
}

export default config
