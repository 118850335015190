import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDeliveryType } from '@/hooks/app'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'

export default function Openings (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const merchant = useSelector(state => state.merchant)
  const { isTakeaway, isStoreDelivery } = useDeliveryType()

  return (
    <div className={classes.openings}>
      {
        isTakeaway && (
          <div className={classes.opening}>
            <Typography variant='caption'>{t('app.component.dialog.merchant_detail_dialog.pickup_time')}</Typography>
            <Typography variant='caption'>{merchant?.data?.setting?.directoryTakeawayTimeText}</Typography>
          </div>
        )
      }
      {
        isStoreDelivery && (
          <div className={classes.opening}>
            <Typography variant='caption'>{t('app.component.dialog.merchant_detail_dialog.delivery_time')}</Typography>
            <Typography variant='caption'>{merchant?.data?.setting?.directoryDeliveryTimeText}</Typography>
          </div>
        )
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  openings: {
    maxWidth: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  opening: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gap: theme.spacing(0.5),
    '& span': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  },
}))
