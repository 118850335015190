import PropTypes from 'prop-types'
import React from 'react'

import deliveryOption from '@/constants/deliveryOption'

import LeaveAtDoor from './LeaveAtDoor'
import MeetAtDoor from './MeetAtDoor'
import MeetOutside from './MeetOutside'

DeliveryOptionItem.propTypes = {
  type: PropTypes.oneOf(['LEAVE_AT_DOOR', 'MEET_AT_DOOR', 'MEET_OUTSIDE']),
}

/**
 *
 * @param {{
 *  type: 'LEAVE_AT_DOOR' | 'MEET_AT_DOOR' | 'MEET_OUTSIDE'
 * }} props
 * @returns
 */
export default function DeliveryOptionItem (props) {
  const { type } = props

  switch (type) {
    case deliveryOption.LEAVE_AT_DOOR:
      return <LeaveAtDoor />

    case deliveryOption.MEET_AT_DOOR:
      return <MeetAtDoor />

    case deliveryOption.MEET_OUTSIDE:
      return <MeetOutside />

    default:
      return null
  }
}
