import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IOrderBatchState } from '@/redux/orderBatch/OrderBatchState.d'
/* eslint-enable no-unused-vars */

/** @type {IOrderBatchState} */
export const initialState = {
  selectedBatch: null,
  rewardItems: [],
}

export default produce(
  /**
   * @param {IOrderBatchState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.SHOW_NOT_COMPLETE_SETS: {
        const { showNotCompleteSets } = action.payload
        draft.selectedBatch.showNotCompleteSets = showNotCompleteSets
        break
      }
      case ActionTypes.SELECT_BATCH: {
        const { batch } = action.payload
        draft.selectedBatch = batch
        break
      }
      case ActionTypes.ADD_ITEM: {
        const { batchItem } = action.payload

        // 新增項目
        draft.selectedBatch.items.push(batchItem)
        break
      }

      case ActionTypes.UPDATE_ITEM: {
        const { key, path, value } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === key)
        if (!selectedItem) return
        // 更改項目
        _.set(selectedItem, path, value)
        break
      }
      case ActionTypes.REPLACE_ITEM: {
        const { batchItem } = action.payload
        const targetIndex = _.findIndex(draft.selectedBatch.items, item => item.key === batchItem?.key)
        draft.selectedBatch.items.splice(targetIndex, 1, batchItem)
        break
      }
      case ActionTypes.DELETE_ITEM: {
        const { index } = action.payload

        // 刪除
        draft.selectedBatch.items.splice(index, 1)
        break
      }
      case ActionTypes.RESET_BATCH: {
        return initialState
      }
      case ActionTypes.RESTORE_BATCH: {
        const { batch } = action.payload
        draft.selectedBatch = batch
        break
      }
      case ActionTypes.RESET_ITEMS: {
        // 清除所有 items
        draft.selectedBatch.items = []
        break
      }
      case ActionTypes.UPDATE_BATCH_ITEMS: {
        const { items } = action.payload
        draft.selectedBatch.items = items
        break
      }
      case ActionTypes.UPSERT_REWARD_ITEM: {
        const { rewardItem } = action.payload
        const itemIndex = draft.rewardItems.findIndex(item => item.id === rewardItem.id)
        if (rewardItem.quantity > 0) {
          if (itemIndex === -1) {
            // 新增
            draft.rewardItems.push(rewardItem)
          } else {
            // 修改
            draft.rewardItems.splice(itemIndex, 1, rewardItem)
          }
        } else {
          // 刪除
          draft.rewardItems.splice(itemIndex, 1)
        }
        break
      }
      case ActionTypes.RESET_REWARD_ITEMS: {
        draft.rewardItems = []
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
