import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useFavoriteRestaurantsQueryOptions } from '@/hooks/queries/useFavoriteRestaurantsQuery'
import { useRestaurantCardListSize } from '@/hooks/useRestaurantCardListSize'

import { ReactComponent as RedirectHomeIcon } from '@/assets/icons/redirect_home.svg'
import InfiniteQueryList from '@/components/RestaurantList/InfiniteQueryList'
import RedirectView from '@/pages/OrderHistoryList/RedirectView'
import RestaurantCard from '@/components/Cards/RestaurantCard'
import RestaurantCardsSkeleton from '@/components/RestaurantList/RestaurantCardsSkeleton'

export default function FavoriteRestaurantList (props) {
  const history = useHistory()
  const { rowHeight, columnWidth } = useRestaurantCardListSize()
  const queryOptions = useFavoriteRestaurantsQueryOptions()

  function EmptyComponent () {
    const { t } = useTranslation()
    return (
      <RedirectView
        renderIcon={() => <RedirectHomeIcon />}
        title={t('app.page.order_history_list.no_favorite_redirect_home.title')}
        action={t('app.page.order_history_list.no_favorite_redirect_home.action')}
        onClick={() => history.push('/')}
      />
    )
  }

  return (
    <InfiniteQueryList
      queryOptions={queryOptions}
      rowHeight={rowHeight}
      columnWidth={columnWidth}
      ItemComponent={RestaurantCard}
      FooterComponent={() => <div />} // 多顯示一行空白的 row
      LoadingComponent={RestaurantCardsSkeleton}
      LoadingIndicator={RestaurantCardsSkeleton}
      EmptyComponent={EmptyComponent}
    />
  )
}
