import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import constants from '@/constants'

import Card from './Card'
import DeliveryTypeLabel from '../DeliveryTypeLabel'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { TABLE } = constants.deliveryType

/**
 *
 * 內用不顯示
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function AddressCard (props) {
  const { order } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  if (order.deliveryType === TABLE) return null
  return (
    <Card className={classes.conainer}>
      <div className={classes.header}>
        <Typography className={classes.title}>
          {t('app.component.card.address_card.title')}
        </Typography>
        <DeliveryTypeLabel deliveryType={order.deliveryType} />
      </div>
      <Typography className={classes.text}>{order?.address}</Typography>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  conainer: {
    position: 'relative',
    padding: theme.spacing(1, 2),
  },
  header: {
    padding: theme.spacing(0.5, 0, 1, 0),
    display: 'flex',
  },
  title: {
    flex: 1,
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  text: {
    color: theme.palette.grey[700],
  },
}))
