import { Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'

import { useDeliveryType } from '@/hooks/app'
import { useIsLogin, useAddress as useUserAddress } from '@/hooks/user'
import { useMerchant, useAddress as useMerchantAddress } from '@/hooks/merchant'

export default function Address (props) {
  const { isCart } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const { isStoreDelivery } = useDeliveryType()
  const merchant = useMerchant()
  const merchantAddress = useMerchantAddress()
  const userAddress = useUserAddress()
  const isLogin = useIsLogin()

  const handleEditAddress = () => {
    // 如果是購物車的話，先關掉drawer，並先到餐廳頁面，讓地址頁面回上一頁是餐廳頁面
    if (isCart) {
      dispatch(actions.app.toggleDrawer('cart', false))
      history.push(`/restaurant/${merchant.id}`)
    }

    if (isLogin) {
      // 有登入
      dispatch(actions.user.updateExitAddressEditBackLevel(2))
      history.push('/settings/address')
    } else {
      // 沒登入，直接編輯現在位置
      dispatch(actions.user.updateExitAddressEditBackLevel(1))
      dispatch(actions.user.addAddressTemp(userAddress))
      history.push('/settings/address/edit')
    }
  }

  if (isStoreDelivery) {
    return (
      <section className={classes.section} onClick={handleEditAddress}>
        <div>
          <Typography className={classes.bold} variant='h6'>{userAddress.name}</Typography>
          <div className={classes.addressWrap}>
            <Typography variant='body2'>
              {t('app.page.checkout.info_card.delivery_address')}
            </Typography>
            <Typography variant='body2' component='span' className={classes.address}>
              {userAddress.address}
            </Typography>
          </div>
        </div>
        <ArrowForwardIosIcon fontSize='small' style={{ placeSelf: 'center' }} />
      </section>
    )
  }

  return (
    <section className={classes.section}>
      <div>
        <Typography className={classes.bold} variant='h6'>{merchant.name}</Typography>
        <div className={classes.addressWrap}>
          <Typography variant='body2'>
            {t('app.page.checkout.info_card.store_address')}
          </Typography>
          <Typography variant='body2' component='span' className={classes.address}>
            {merchantAddress}
          </Typography>
        </div>
      </div>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  bold: {
    fontWeight: 600,
  },
  addressWrap: {
    justifySelf: 'stretch',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridGap: theme.spacing(1),
  },
  address: {
    color: theme.palette.info.main,
  },
}))
