import _ from 'lodash'
import moment from 'moment'
import produce from 'immer'

import DeliveryType from '@/constants/deliveryType'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethod from '@/constants/paymentMethod'

import ActionTypes from './ActionTypes'

/** @typedef {import('@/redux/app/AppState.d').IAlerts} IAlerts */
/** @typedef {import('@/redux/app/AppState.d').IAppState} IAppState */
/** @typedef {import('@/redux/app/AppState.d').IDatetime} IDatetime */
/** @typedef {import('@/redux/app/AppState.d').IDialog} IDialog */
/** @typedef {import('@/redux/app/AppState.d').IDrawer} IDrawer */
/** @typedef {import('@/redux/app/AppState.d').ILoading} ILoading */
/** @typedef {import('@/redux/app/AppState.d').IModal} IModal */
/** @typedef {import('@/redux/app/AppState.d').IMode} IMode */
/** @typedef {import('@/redux/app/AppState.d').IParams} IParams */
/** @typedef {import('@/redux/app/AppState.d').IPopover} IPopover */
/** @typedef {import('@/redux/app/AppState.d').ISnackbar} ISnackbar */
/** @typedef {import('@/redux/app/AppState.d').ISystem} ISystem */

/** @type {IAlerts} */
const initialAlerts = {

}

/** @type {IModal} */
const initialModal = {
  open: false,
  data: {},
}

/** @type {IDrawer} */
const initialDrawers = {
  cart: initialModal,
  datetime: initialModal,
  menuItem: initialModal,
  setItem: initialModal,
  orderTracking: initialModal,
  paymentMethod: initialModal,
  paymentHelp: initialModal,
  qrReader: initialModal,
  login: initialModal,
  schedule: initialModal,
  scheduledDatetime: initialModal,
  preorder: initialModal,
  crmRewards: initialModal,
}

/** @type {IDialog} */
const initalDialogs = {
  changeLang: initialModal,
  serviceRequest: initialModal,
  menuDetail: initialModal,
  merchantDetail: initialModal,
  setItemOption: initialModal,
  setItemDelete: initialModal,
  dineinOrderConfirm: initialModal,
  promoCode: initialModal,
  invitation: initialModal,
  splashAd: initialModal,
  specialTimePicker: initialModal,
  couponTnc: initialModal,
  couponClaimed: initialModal,
  crmRegister: initialModal,
}

/** @type {ISnackbar} */
const initialSnackbars = {
  orderTracking: initialModal,
}

/** @type {IPopover} */
const initialPopovers = {
  currentLocationTooFar: initialModal,
}

/** @type {Object} */
const initialSetting = {

}

/** @type {IDatetime} */
const initialDatetime = {
  date: moment().format('YYYY-MM-DD'),
  time: moment().format('HH:mm'),
  isImmediate: true,
}

/** @type {IParams} */
export const initialParams = {
  merchantId: undefined,
  orderId: undefined,
  table: undefined,
  datetime: initialDatetime,
  deliveryType: DeliveryType.STORE_DELIVERY,
  invite: undefined,
  REDEEM: undefined,
  categoryTag: undefined,
  isD2CWeb: false,
  dineIn: false,
  takeaway: true,
  storeDelivery: true,
}

/** @type {ILoading} */
const initialLoadings = {
  backdrop: false,
  submit: false,
  createShipping: false,
  restaurantInit: false,
  d2cRouterSettingUpdate: false,
}

/** @type {ISystem} */
const initialSystemEnable = {
  table: {
    enable: true,
    remark: '',
  },
  takeaway: {
    enable: true,
    remark: '',
  },
  storeDelivery: {
    enable: true,
    remark: '',
  },
  dimbox: {
    enable: true,
    remark: '',
  },
  campaign: {
    foodAngel: true,
    poonchoi: false,
  },
}

/** @type {IAppState} */
export const initialState = {
  isInit: false,
  loadings: initialLoadings,
  isLoginSetting: false,
  isQRCodeScanning: false,
  isCategoryBarSticky: false,
  params: initialParams,
  lang: 'zh-HK',
  alerts: initialAlerts,
  drawers: initialDrawers,
  dialogs: initalDialogs,
  snackbars: initialSnackbars,
  popovers: initialPopovers,
  setting: initialSetting,
  safeAreaHeight: 0,
  codePushPackageMeta: null,
  appInfo: null,
  deviceId: null,
  deviceInfo: null,
  updateStatus: {
    status: null,
    progress: null,
  },
  system: initialSystemEnable,
  test: {
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm'),
  },
  pendingMarketingAction: undefined,
  isTimeZoneError: false,
  paymentGateway: {
    [PaymentMethod.PAY_ME]: PaymentGateway.PAYMENT_GATEWAY_2C2P,
    [PaymentMethod.CREDIT_CARD]: PaymentGateway.PAYMENT_GATEWAY_FISERV,
  },
}

export default produce(
  /**
   *  @param {IAppState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.RESET_ALERTS: {
        draft.alerts = initialAlerts
        break
      }
      case ActionTypes.RESET_DIALOGS: {
        draft.dialogs = initalDialogs
        break
      }
      case ActionTypes.RESET_DRAWERS: {
        draft.drawers = initialDrawers
        break
      }
      case ActionTypes.RESET_SNACKBAR: {
        draft.snackbars = initialSnackbars
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { loading, open } = action.payload
        draft.loadings[loading] = open
        break
      }
      case ActionTypes.UPDATE_SCANNING: {
        const { isQRCodeScanning } = action.payload
        draft.isQRCodeScanning = isQRCodeScanning
        break
      }
      case ActionTypes.UPDATE_CATEGORYBAR_STICKY: {
        const { isSticky } = action.payload
        draft.isCategoryBarSticky = isSticky
        break
      }
      case ActionTypes.UPDATE_IS_LOGIN_SETTING: {
        const { isLoginSetting } = action.payload
        draft.isLoginSetting = isLoginSetting
        break
      }
      case ActionTypes.UPDATE_TABLE: {
        const { table } = action.payload
        draft.params.table = table
        break
      }
      case ActionTypes.UPDATE_LANG: {
        const { lang } = action.payload
        draft.lang = lang
        break
      }
      case ActionTypes.TOGGLE_ALERT: {
        const { alertConfig, id, open } = action.payload
        if (_.isBoolean(open)) {
          draft.alerts[id] = {
            id,
            open,
            alertConfig,
          }
          break
        }
        draft.alerts[id].open = !draft.alerts[id].open
        break
      }
      case ActionTypes.TOGGLE_DRAWER: {
        const { drawer, open, data } = action.payload
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.drawers[drawer].open = !draft.drawers[drawer].open
          draft.drawers[drawer].data = {}
          break
        }
        if (_.isBoolean(open)) {
          draft.drawers[drawer].open = open
        }
        if (!_.isNil(data)) {
          draft.drawers[drawer].data = data
        }
        break
      }
      case ActionTypes.TOGGLE_DIALOG: {
        const { dialog, open, data } = action.payload
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.dialogs[dialog].open = !draft.dialogs[dialog].open
          draft.dialogs[dialog].data = {}
          break
        }
        if (_.isBoolean(open)) {
          draft.dialogs[dialog].open = open
        }
        if (!_.isNil(data)) {
          draft.dialogs[dialog].data = data
        }
        break
      }
      case ActionTypes.TOGGLE_SNACKBAR: {
        const { snackbar, open, data } = action.payload
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.snackbars[snackbar].open = !draft.snackbars[snackbar].open
          draft.snackbars[snackbar].data = {}
          break
        }
        if (_.isBoolean(open)) {
          draft.snackbars[snackbar].open = open
        }
        if (!_.isNil(data)) {
          draft.snackbars[snackbar].data = data
        }
        break
      }
      case ActionTypes.TOGGLE_POPOVER: {
        const { popover, open, data } = action.payload
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.popovers[popover].open = !draft.popovers[popover].open
          draft.popovers[popover].data = {}
          break
        }
        if (_.isBoolean(open)) {
          draft.popovers[popover].open = open
        }
        if (!_.isNil(data)) {
          draft.popovers[popover].data = data
        }
        break
      }
      case ActionTypes.UPDATE_SETTING: {
        const { path, setting } = action.payload
        _.set(draft.setting, path, setting)
        break
      }
      case ActionTypes.UPDATE_PARAMS: {
        const { params } = action.payload
        draft.params = params
        break
      }
      case ActionTypes.UPDATE_IS_D2C_WEB: {
        const { isD2CWeb } = action.payload
        draft.params.isD2CWeb = isD2CWeb
        break
      }
      case ActionTypes.UPDATE_MERCHANT_ID: {
        const { merchantId } = action.payload
        draft.params.merchantId = merchantId
        break
      }
      case ActionTypes.DELETE_REDEEM_CODE: {
        delete draft.params.REDEEM
        break
      }
      case ActionTypes.DELETE_PROMO_CODE_ID: {
        delete draft.params.promoCodeId
        break
      }
      case ActionTypes.UPDATE_DELIVERY_TYPE: {
        const { deliveryType } = action.payload
        draft.params.deliveryType = deliveryType
        break
      }
      case ActionTypes.UPDATE_DATETIME: {
        const { datetime } = action.payload
        draft.params.datetime = datetime
        break
      }
      case ActionTypes.UPDATE_SAFEAREA_HEIGHT: {
        const { height } = action.payload
        draft.safeAreaHeight = height
        break
      }
      case ActionTypes.UPDATE_UPDATE_STATUS: {
        const { updateStatus } = action.payload
        draft.updateStatus = updateStatus
        break
      }
      case ActionTypes.UPDATE_PACKAGE_META: {
        const { codePushPackageMeta } = action.payload
        draft.codePushPackageMeta = codePushPackageMeta
        break
      }
      case ActionTypes.UPDATE_APP_INFO: {
        const { appInfo } = action.payload
        draft.appInfo = appInfo
        break
      }
      case ActionTypes.UPDATE_DEVICE_INFO: {
        const { deviceId, deviceInfo } = action.payload
        draft.deviceId = deviceId
        draft.deviceInfo = deviceInfo
        break
      }
      case ActionTypes.UPDATE_SYSTEM_DELIVERYTYPE_ENABLE: {
        const { deliveryType, systemEnable } = action.payload
        draft.system[deliveryType] = systemEnable
        break
      }
      case ActionTypes.UPDATE_TEST: {
        const { path, value } = action.payload
        _.set(draft.test, path, value)
        break
      }
      case ActionTypes.ADD_PENDING_MARKETING_ACTION: {
        draft.pendingMarketingAction = action.payload
        break
      }
      case ActionTypes.REMOVE_PENDING_MARKETING_ACTION: {
        draft.pendingMarketingAction = undefined
        break
      }
      case ActionTypes.SET_TIME_ZONE_ERROR: {
        draft.isTimeZoneError = true
        break
      }
      case ActionTypes.UPDATE_PAYMENT_GATEWAY: {
        const { paymentMethod, gateway } = action.payload
        draft.paymentGateway[paymentMethod] = gateway
        break
      }
      default:
        break
    }
  },
  initialState,
)
