import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  component: PropTypes.oneOf(['div', 'ul']),
}

/**
 *
 * @param {{
 * children?: Any
 * className?: String
 * component?: JSX.IntrinsicElements
 * }} props
 */
export default function Card (props) {
  const { children, className, component = 'div', ...rest } = props
  const classes = useStyles(props)
  const Component = component

  return (
    <Component className={clsx(classes.card, className)} {...rest}>
      {children}
    </Component>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[25],
  },
}))
