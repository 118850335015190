import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PromoCodeInput (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [inputCode, setInputCode] = React.useState('')
  const open = useSelector(state => state.app.dialogs.promoCode.open)

  React.useEffect(() => {
    if (open) {
      setInputCode('')
    }
  }, [open])

  const onConfirm = async (code) => {
    dispatch(actions.order.applyPromoCode(code))
    dispatch(actions.app.toggleDialog('promoCode'))
  }

  return (
    <div className={classes.container}>
      <TextField
        id='standard-adornment-password'
        placeholder={t('app.component.dialog.promo_dialog.promo_code_helper')}
        variant='outlined'
        value={inputCode}
        onChange={e => setInputCode(e.target.value)}
        autoFocus
        className={classes.inputWrap}
        InputProps={{
          endAdornment: inputCode && (
            <InputAdornment position='end'>
              <HighlightOffIcon
                fontSize='small'
                className={classes.clearIcon}
                onClick={() => setInputCode('')}
              />
            </InputAdornment>
          ),
          classes: { input: classes.input },
        }}
      />
      <LetterSpacingButton
        text={t('app.common.confirm')}
        onClick={() => onConfirm(inputCode)}
        className={classes.submitButton}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
  },
  inputWrap: {
    flex: 1,
  },
  input: {
    padding: theme.spacing(1.5, 1.5),
    fontSize: '1.4rem',
  },
  clearIcon: {
    color: theme.palette.grey[500],
  },
  submitButton: {
    minWidth: 80,
  },
}))
