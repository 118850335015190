import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-unused-vars
import { IUserState } from '@/redux/user/UserState.d'

/** @type {IUserState} */
export const initialState = {
  isInit: false,
  member: {},
  address: {},
  addressTemp: {},
  addressList: [],
  exitAddressEditBackLevel: 1,
  promoCodes: [],
  coupons: [],
}

export default produce(
  /**
   * @param {IUserState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_MEMBER: {
        const { member } = action.payload
        draft.member = member
        break
      }
      case ActionTypes.UPDATE_ADDRESS: {
        const { address } = action.payload
        draft.address = address
        break
      }
      case ActionTypes.UPDATE_ADDRESS_LIST: {
        const { addressList } = action.payload
        draft.addressList = addressList
        break
      }
      case ActionTypes.ADD_ADDRESS_TEMP: {
        const { address } = action.payload
        draft.addressTemp = address
        break
      }
      case ActionTypes.REST_ADDRESS_TEMP: {
        draft.addressTemp = {}
        break
      }
      case ActionTypes.UPDATE_ADDRESS_TEMP: {
        const { path, value } = action.payload
        if (path == null) {
          draft.addressTemp = value
        } else {
          _.set(draft.addressTemp, path, value)
        }
        break
      }
      case ActionTypes.UPDATE_EXIT_ADDRESS_EDIT_BACK_LEVEL: {
        const { exitAddressEditBackLevel } = action.payload
        draft.exitAddressEditBackLevel = exitAddressEditBackLevel
        break
      }
      case ActionTypes.UPDATE_PROMOCODES: {
        const { promoCodes } = action.payload
        draft.promoCodes = promoCodes
        break
      }
      case ActionTypes.ADD_PROMOCODE: {
        const { promoCode } = action.payload
        draft.promoCodes.push(promoCode)
        break
      }
      case ActionTypes.RESET_PROMOCODES: {
        draft.promoCodes = []
        break
      }
      case ActionTypes.UPDATE_COUPONS: {
        const { coupons } = action.payload
        draft.coupons = coupons
        break
      }
      case ActionTypes.ADD_COUPON: {
        const { coupon } = action.payload
        draft.coupons.push(coupon)
        break
      }
      default:
        break
    }
  },
  initialState,
)
