import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @typedef {import('@/redux/payment/PaymentState.d').IPaymentState} IPaymentState */

/** @type {IPaymentState} */
export const initialState = {
  isInit: false,
  selectedPaymentMethod: null,
  selectedPaymentGateway: null,
  savePayment: false, // Whether to display recent payment method
  payingOutside: false,
  token2c2p: null,
  url3DS2c2p: '',
  paymentQRCode: '',
  showQRCode: false,
  qrCodePaymentMethod: null,
}

export default produce(
  /**
   * @param {IPaymentState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_PAYMENT_METHOD: {
        const { paymentMethod } = action.payload
        draft.selectedPaymentMethod = paymentMethod
        break
      }
      case ActionTypes.UPDATE_PAYMENT_GATEWAY: {
        const { paymentGateway } = action.payload
        draft.selectedPaymentGateway = paymentGateway
        break
      }
      case ActionTypes.UPDATE_SAVE_PAYMENT: {
        const { savePayment } = action.payload
        draft.savePayment = savePayment
        break
      }
      case ActionTypes.UPDATE_PAYING_OUTSIDE: {
        const { payingOutside } = action.payload
        draft.payingOutside = payingOutside
        break
      }
      case ActionTypes.UPDATE_2C2P_TOKEN: {
        draft.token2c2p = action.payload
        break
      }
      case ActionTypes.UPDATE_2C2P_3DS_URL: {
        draft.url3DS2c2p = action.payload
        break
      }
      case ActionTypes.RESET_PAYMENT: {
        draft.selectedPaymentMethod = initialState.selectedPaymentMethod
        draft.token2c2p = initialState.token2c2p
        draft.url3DS2c2p = initialState.url3DS2c2p
        break
      }
      case ActionTypes.UPDATE_PAYMENT_QRCODE: {
        draft.paymentQRCode = action.payload
        break
      }
      case ActionTypes.UPDATE_PAYMENT_QRCODE_STATUS: {
        const { showQRCode, paymentMethod = null } = action.payload
        draft.showQRCode = showQRCode
        draft.qrCodePaymentMethod = paymentMethod
        break
      }
      default:
        break
    }
  },
  initialState,
)
