import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import React from 'react'

const BatchCountBadge = (props) => {
  const { quantity } = props
  const classes = useStyles(props)

  return (
    <Badge
      color='secondary'
      badgeContent={quantity}
      className={classes.badge}
    />
  )
}
const useStyles = makeStyles(theme => ({
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

export default BatchCountBadge
