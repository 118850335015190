import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import { checkIsDimboxOrder } from '@/libs/dimbox'
import { openMap } from '@/libs/googleMap'
import { useAddress as useMerchantAddress, useMerchantById } from '@/hooks/merchant'
import constants from '@/constants'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType

RestaurantInfo.propTypes = {
  order: PropTypes.object,
}

/**
 *
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function RestaurantInfo (props) {
  const { order } = props

  const { t } = useTranslation()
  const classes = useStyles(props)
  const merchant = useMerchantById(order.merchantId)
  const merchantAddress = useMerchantAddress()
  const isDimbox = checkIsDimboxOrder(order)

  const handleOpenMap = () => {
    if (order.deliveryType === TAKEAWAY) {
      openMap(merchant)
    }
  }

  return (
    <ul className={classes.infoList}>
      {/* Name Row */}
      <li>
        <Typography variant='body1' className={classes.bold}>
          {merchant?.name}
        </Typography>
      </li>

      {/* Address Row */}
      <li>
        <Typography variant='body2'>
          {
            isDimbox
              ? t('app.page.order_tracking.progress_card.dimbox.address')
              : t(`app.page.order_tracking.progress_card.${order.deliveryType}.address`)
          }
        </Typography>

        <Link
          target='_blank'
          rel='noopener'
          variant='body2'
          className={classes.info}
          underline={order.deliveryType === TAKEAWAY ? 'hover' : 'none'}
          onClick={handleOpenMap}
        >
          {
            [TAKEAWAY, TABLE].includes(order.deliveryType)
              ? merchantAddress || '-'
              : order.address
          }
        </Link>
      </li>

      {/* Contact Row */}
      {
        order.deliveryType === STORE_DELIVERY || isDimbox
          ? null
          : (
            <li>
              <Typography variant='body2'>
                {t(`app.page.order_tracking.progress_card.${order.deliveryType}.contact`)}
              </Typography>
              <Link
                target='_self'
                variant='body2'
                className={classes.info}
                underline={merchant?.contact ? 'hover' : 'none'}
                href={`tel:${merchant?.contact}`}
              >
                {merchant?.contact || '-'}
              </Link>
            </li>
          )
      }
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  infoList: {
    display: 'grid',
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(0, -2, -2, -2),
    backgroundColor: theme.palette.grey[200],
    '& li': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gap: theme.spacing(2),
    },
    borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
  },
  bold: {
    fontWeight: 'bold',
  },
  info: {
    color: theme.palette.info.main,
    textAlign: 'right',
    cursor: 'pointer',
    // whiteSpace: 'nowrap',
    // overflow: 'auto',
  },
}))
