import PropTypes from 'prop-types'
import React from 'react'

import GroupContainer from '@/components/Groups/GroupContainer'

import OptionGroupHeader from './OptionGroupHeader'
import OptionItemList from './OptionItemList'

/* eslint-disable no-unused-vars */
import { IAppMenuItem } from '@/redux/menu/MenuState.d'
import { IMenuOptionGroup } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

OptionGroup.propTypes = {
  group: PropTypes.object,
  menu: PropTypes.object,
}

/**
 *
 * @param {{
 * group: IMenuOptionGroup
 * menu: IAppMenuItem
 * }} props
 * @returns
 */
export default function OptionGroup (props) {
  const { group, menu } = props
  const list = group.options

  return (
    <GroupContainer>
      <OptionGroupHeader group={group} />
      <OptionItemList
        group={group}
        list={list}
        max={5}
        menu={menu}
      />
    </GroupContainer>
  )
}
