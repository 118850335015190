import { useTranslation } from 'react-i18next'
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import deliveryOption from '@/constants/deliveryOption'
import hooks from '@/hooks'

import DeliveryOptionItemBase from './DeliveryOptionItemBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MeetOutside (props) {
  const dispatch = useDispatch()
  const addressTemp = hooks.user.useAddressTemp()
  const { t } = useTranslation()
  const title = t('app.page.setting_address.address.options.MEET_OUTSIDE')
  const type = deliveryOption.MEET_OUTSIDE
  const isSelected = addressTemp.deliveryOption === type
  const handleClick = () => {
    if (isSelected) {
      dispatch(actions.user.updateAddressTemp('deliveryOption', ''))
    } else {
      dispatch(actions.user.updateAddressTemp('deliveryOption', type))
    }
  }

  return (
    <DeliveryOptionItemBase
      icon={<HomeWorkOutlinedIcon fontSize='large' />}
      title={title}
      onClick={handleClick}
      selected={isSelected}
    />
  )
}
