import { Slide, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import React, { useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { checkIsDimboxOrder } from '@/libs/dimbox'
import { useMerchantById } from '@/hooks/merchant'
import constants from '@/constants'

import history from '@/libs/history'
import hooks from '@/hooks'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderTrackingSnackbar (props) {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const d2cBaseUrl = useD2CBaseUrl()
  const classes = useStyles(props)

  // TODO: 過一陣子可以移除 storagedList
  // 舊的，存在 localstorage 的 list 也要拿出來用
  const storagedList = JSON.parse(localStorage.getItem('checkedFinalStageOrders')) ?? []
  const oldList = storagedList.map(order => _.pick(order, ['id', 'updatedAt']))
  // 拿出來就可以移除了，之後都改存到 customer.options 內
  localStorage.removeItem('checkedFinalStageOrders')

  // checkedFinalStageOrders 裡面記錄已完成或已取消的訂單，且該訂單已經點開 OrderTrackingSnackbar 看過，點開看過之後就不需要再去追蹤這張單
  const checkedFinalStageOrders = (useSelector(state => state.user.member.options?.checkedFinalStageOrders) ?? []).concat(oldList)
  const orderHistories = useSelector(state => state.orderHistory.orders)
  const historyOrder = _
    .chain(orderHistories)
    .filter(o => moment().diff(moment(o.updatedAt), 'hours') <= 12) // 只關注最近 12 小時內有更新的單
    .orderBy(['updatedAt'], ['desc']) // 依照跟新順序排序，找出最近更新的訂單
    .find(o => !_.find(checkedFinalStageOrders, s => s.id === o.id)) // 追蹤第一張不在 checkedFinalStageOrders 裡面的單
    .value()

  const pages = ['/orders', '/member', '/restaurants', '/search'] // 只有在有 BottomNavigation 的頁面顯示
  const hasBottomNavigation = _.includes(pages, location.pathname)
  const orderTrackingOpened = hooks.app.useOrderTrackingSnackbar()
  const showSnackbar = orderTrackingOpened && hasBottomNavigation
  const merchant = useMerchantById(historyOrder?.merchantId)

  useEffect(() => {
    const isDimbox = checkIsDimboxOrder(historyOrder)
    if (isDimbox) {
      // ! dimbox 先不顯示 snackbar 通知
      return
    }

    if (
      !historyOrder ||
      _.some(checkedFinalStageOrders, o => o?.id === historyOrder?.id) // 紀錄為已看過的單就不顯示 OrderTrackingSnackbar
    ) {
      dispatch(actions.app.toggleSnackbar('orderTracking', false))
      return
    }

    dispatch(actions.app.toggleSnackbar('orderTracking', true))
  }, [historyOrder?.id, historyOrder?.status, historyOrder?.takeawayStatus])

  const handleClick = () => {
    dispatch(actions.app.toggleSnackbar('orderTracking', false))
    dispatch(actions.orderHistory.selectOrder(historyOrder.id))

    history.push(`${d2cBaseUrl}/order_tracking`)

    // 當點開 OrderTrackingSnackbar 時如果 orderHistory 為最終狀態的話紀錄為已經看過的單，之後不會再顯示 OrderTrackingSnackbar
    if (_.some([
      historyOrder?.deliveryType === TABLE && historyOrder?.status === 'paid', // 內用單流程結束
      historyOrder?.deliveryType === TAKEAWAY && historyOrder?.takeawayStatus === 'completed', // 外帶單流程結束
      historyOrder?.deliveryType === STORE_DELIVERY && historyOrder?.takeawayStatus === 'delivered', // 外送單流程結束
      historyOrder?.status === 'cancelled', // 訂單被取消
    ], valid => valid)) {
      const filterdList = _.filter(checkedFinalStageOrders, o => moment().diff(moment(o.updatedAt), 'hours') <= 24) // 避免 localStorage 內的資料越來越多
      dispatch(actions.user.updateCheckedFinalStageOrders([...filterdList, _.pick(historyOrder, ['id', 'updatedAt'])])) // 只存 id 和 updatedAt
    }
  }

  if (!historyOrder) return null

  return (
    <Slide in={showSnackbar} direction='right'>
      <div
        className={classes.orderTrackingSnackbar}
        onClick={handleClick}
      >
        <AccessTimeIcon />
        <div className={classes.textWrap}>
          <Typography variant='body1'>
            {t(`app.constant.displayStatusKey.${historyOrder?.displayStatusKey}`)}
          </Typography>
          <Typography variant='body2'>
            {merchant?.name}
          </Typography>
        </div>
      </div>
    </Slide>
  )
}

const useStyles = makeStyles(theme => ({
  orderTrackingSnackbar: {
    width: '70%',
    height: constants.app.height.SNACKBAR,
    position: 'fixed',
    left: theme.spacing(1),
    bottom: `calc(${constants.app.marginBottom.FOOTER} + ${theme.spacing(1)}px)`,
    maxWidth: '70%',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[25],
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    zIndex: constants.app.zIndex.SNACKBAR,
  },
  textWrap: {
    '& > *': {
      padding: theme.spacing(0.5, 1.5),
    },
  },
}))
