import { ButtonBase, CircularProgress, Slide, Typography } from '@material-ui/core'
import { Favorite, FavoriteBorder } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { actions, useDispatch } from '@/redux'
import { useFavoriteMutation, useIsFavorite } from '@/hooks/queries/useFavoritesQuery'
import { useMember } from '@/hooks/user'

const DEFAULT_ICON_COLOR = '#D9363E'

/**
 * @typedef {FavoriteIconProps}
 * @property {string} merchantId
 * @property {boolean} open
 * @param {FavoriteIconProps} props
 * @returns
 */
export default function FavoriteIcon (props) {
  const { merchantId, open } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const history = useHistory()
  const member = useMember()
  const isVisiable = member?.id && open
  const { isFavorite } = useIsFavorite(merchantId)
  const [isEnable, setIsEnable] = useState(isFavorite)
  const { mutate, isLoading } = useFavoriteMutation(handleSuccess)

  useEffect(() => { setIsEnable(isFavorite) }, [isFavorite])

  function handleSuccess () {
    // mutation 成功後直接改變 icon 顯示的狀態，不等重抓 favorites
    setIsEnable(prev => !prev)

    // 顯示 snackbar 提示
    dispatch(actions.snackbar.enqueueSnackbar({
      message: isFavorite
        ? t('app.component.alert.favorite.remove.title')
        : t('app.component.alert.favorite.add.title'),
      options: {
        TransitionComponent: Slide,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        action: () => (
          <ButtonBase
            disableRipple
            onClick={() => {
              dispatch(actions.snackbar.closeSnackbar())
              history.push('/favorite')
            }}
          >
            <Typography className={classes.viewAll}>
              {t('app.common.find_more')}
            </Typography>
          </ButtonBase>
        ),
      },
    }))
  }

  // const debouncedMutate = useCallback(_.debounce(async () => { mutate(merchantId) }, 500), [])

  function handleClick (e) {
    e.stopPropagation()
    if (isLoading) return
    mutate(merchantId)
  }

  if (!isVisiable) return null
  return (
    <div
      className={classes.iconClickableWrap}
      onClick={handleClick}
      style={{ zIndex: open ? 110 : undefined }}
    >
      <div className={classes.iconWrap}>
        {
          isLoading
            ? <CircularProgress size={18} style={{ color: DEFAULT_ICON_COLOR }} />
            : isEnable
              ? <Favorite style={{ fontSize: '18px', color: DEFAULT_ICON_COLOR }} />
              : <FavoriteBorder style={{ fontSize: '18px' }} />
        }
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  iconClickableWrap: {
    position: 'absolute',
    top: 4,
    right: 4,
    borderRadius: 28,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrap: {
    position: 'absolute',
    background: '#fff',
    borderRadius: 28,
    boxShadow: theme.shadows[25],
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewAll: {
    color: '#fff',
  },
}))
