export default {
  SELECT_ORDER: 'ORDER/SELECT_ORDER',
  UPDATE_CALCULARE_ORDER_LOADING: 'ORDER/UPDATE_CALCULARE_ORDER_LOADING',
  UPDATE_CALCULARE_ORDER_ERROR: 'ORDER/UPDATE_CALCULARE_ORDER_ERROR',
  UPDATE_SELECTED_ORDER: 'ORDER/UPDATE_SELECTED_ORDER',
  UPDATE_REQUEST_SERVICE_TIME: 'ORDER/UPDATE_REQUEST_SERVICE_TIME',
  UPDATE_PROMO_CODE: 'ORDER/UPDATE_PROMO_CODE',
  DELETE_PROMO_CODE: 'ORDER/DELETE_PROMO_CODE',
  UPDATE_DELETE_PROMO_CODE_LOADING: 'ORDER/UPDATE_DELETE_PROMO_CODE_LOADING',
  UPDATE_APPLY_PROMO_CODE_LOADING: 'ORDER/UPDATE_APPLY_PROMO_CODE_LOADING',
  UPDATE_AUTOFILL_PROMO_CODE_LOADING: 'ORDER/UPDATE_AUTOFILL_PROMO_CODE_LOADING',
  UPDATE_RICECOIN_DISCOUNT: 'ORDER/UPDATE_RICECOIN_DISCOUNT',
  UPDATE_APPLY_RICECOIN_DISCOUNT: 'ORDER/UPDATE_APPLY_RICECOIN_DISCOUNT',
  UPDATE_DONATE_RICECOIN_REWARD: 'ORDER/UPDATE_DONATE_RICECOIN_REWARD',
  UPDATE_SHIPPING: 'ORDER/UPDATE_SHIPPING',
  UPDATE_PRIORITIZED_PROMO_CODE: 'ORDER/UPDATE_PRIORITIZED_PROMO_CODE',
}
