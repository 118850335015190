import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import dimorderApi from '@/libs/api/dimorder'
import moment from 'moment'

import { useAppLocale, useDatetime, useDeliveryType, useParams } from '../../app'
import { useIsDimbox } from '../../dimbox'
import { useMerchant } from '../../merchant'
import { useUser } from '../../user'

const DEFAULT_STALE_TIME = 10 * 60 * 1000 // 10 mins

export function useMerchantNoticesQuery () {
  const merchant = useMerchant()
  const merchantId = merchant?.id
  const user = useUser()
  const latlng = user.address?.latlng?.lat
  const locale = useAppLocale()
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const { categoryTag } = useParams()
  const staleTime = DEFAULT_STALE_TIME
  const enabled = !!merchantId
  const utcTimeString = isImmediate ? moment().format() : moment(`${date} ${time}`).format()
  const queryKey = isImmediate
    ? ['merchant', 'notices', `merchantId:${merchantId}`, { locale, deliveryType, isImmediate, lat: latlng?.lat, lng: latlng?.lng, categoryTag }]
    : ['merchant', 'notices', `merchantId:${merchantId}`, { locale, deliveryType, date, time, lat: latlng?.lat, lng: latlng?.lng, categoryTag }]
  const queryFn = () => fetchFn()
  async function fetchFn () {
    return await dimorderApi.merchant.getMerchantNotices(merchantId, deliveryType, latlng, utcTimeString, categoryTag)
  }
  return useQuery({ queryKey, queryFn, enabled, staleTime })
}

export function useMerchantNotices () {
  const merchantNoticesQuery = useMerchantNoticesQuery()
  const isDimbox = useIsDimbox()
  const notices = useMemo(() => {
    let result = merchantNoticesQuery.data || []
    if (isDimbox) {
      // 如果是 dimbox 餐廳的話要顯示 dimbox 的使用方式
      const url = 'https://www.dimorder.com/dimbox/'
      result = [...result, {
        variant: 'info',
        message: 'app.notice.dimbox',
        onClick: () => window.open(url, '_blank'),
      }]
    }
    return result
  }, [merchantNoticesQuery.data, isDimbox])
  return { ...merchantNoticesQuery, notices }
}
