import _ from 'lodash'

/**
 * @param {string} string
 * @returns {string}
 */
export function toUpperCase (string) {
  if (typeof string === 'string') {
    return string.toUpperCase()
  }
  return string
}

export const groupString = (string, members) => {
  // members: how may merbers in a group.
  const arr = []
  const groups = Math.ceil(string.length / members)
  let i
  for (i = 0; i < groups; i++) {
    arr.push(string.substr(i * members, members))
  }
  return arr
}

/**
  * @function
  * @param {string} phone
  * @returns {string}
  */
export const removeContryCode = (phone) => {
  if (phone && phone.length > 8) {
    if (phone.startsWith('+852')) {
      return phone.slice(4)
    }
    if (phone.startsWith('852')) {
      return phone.slice(3)
    }
  } else {
    return phone
  }
}

/**
 * @param {string} string
 * @returns {Number}
 */
export function pixelToNumber (string) {
  if (_.isString(string) && string.includes('px')) {
    return Number(string.replace('px', ''))
  }
}

/**
 * Replaces all occurrences of '&nbsp;' with a regular space character in the input string.
 * @param {string} inputString - The string to process.
 * @returns {string} The processed string with '&nbsp;' replaced by regular spaces.
 */
export function replaceNbsp (inputString) {
  // eslint-disable-next-line no-irregular-whitespace
  return inputString.replace(/ /g, ' ')
}

export default {
  toUpperCase,
  groupString,
  removeContryCode,
  pixelToNumber,
  replaceNbsp,
}
