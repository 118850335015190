import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import DeliveryOptionList from './DeliveryOptionList'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DeliveryOptions (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <div className={classes.deliveryOptions}>
      <Typography variant='body2' className={classes.label}>
        {t('app.page.setting_address.address.options.title')}
      </Typography>
      <DeliveryOptionList />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  deliveryOptions: {
    display: 'none',
    // display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[25],
  },
  label: {
    fontWeight: 'bold',
    padding: theme.spacing(0, 1),
  },
}))
