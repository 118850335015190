export default {
  apiKey: 'AIzaSyAQef--6OoYEJs1OgntDq6LiDPgEEjbHug',
  authDomain: 'dimorder0.firebaseapp.com',
  databaseURL: 'https://dimorder0.firebaseio.com',
  projectId: 'dimorder0',
  storageBucket: 'dimorder0.appspot.com',
  messagingSenderId: '1005971784833',
  appId: '1:1005971784833:web:594852046c52fc7723b980',
  measurementId: 'G-8Z54ZCV4TL',
}
