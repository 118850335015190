import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import { useGroupRewardLocale } from '@/hooks/queries/CRM/useGroupRewardsQuery'
import constants from '@/constants'

import { SafeAreaPage } from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

import CRMNavbar from '../components/Navbar'
import Card, { CardHeader, CardImage } from '../components/Card'
import PageHeader from '../components/PageHeader'
import RewardsList from './RewardList'

// eslint-disable-next-line no-unused-vars
import { Reward } from 'dimorder-orderapp-lib/dist/types/CRM'

export const CARD = { height: 92 }
const CARD_IMAGE = { size: 58 }

export default function CRMRewardsPage (props) {
  const { t } = useTranslation()

  return (
    <SafeAreaPage>
      <CRMNavbar />
      <PageContainer style={{ overflow: 'hidden' }}>
        <PageHeader title={t('app.page.crm.rewards')} />
        <RewardsList />
      </PageContainer>
    </SafeAreaPage>

  )
}

/**
 * @typedef RewardCardProps
 * @property {Reward} item
 * @property {string} [className]
 * @property {boolean} [selected]
 * @property {() => void} [onClick]
 * @param {RewardCardProps} props
 * @returns
 */
export function RewardCard (props) {
  const { item: reward, className, ...rest } = props
  const { imageUrl = constants.app.image.EMPTY_IMAGE, points } = reward
  const { t } = useTranslation()
  const classes = useStyles(props)
  const { name, desc } = useGroupRewardLocale(reward)

  return (
    <Card
      className={clsx([classes.card, className, { [classes.selected]: props.selected }])}
      component={props.onClick ? ButtonBase : undefined}
      {...rest}
    >
      <CardImage imageUrl={imageUrl} size={CARD_IMAGE.size} />
      <div style={{ flex: 1, alignSelf: 'center' }}>
        <CardHeader title={name} />
        <Typography variant='caption' className={classes.desc}>{desc}</Typography>
      </div>
      <div className={classes.pointsWrap}>
        <Typography variant='h4' className={classes.points}>{points}</Typography>
        <Typography variant='subtitle1'>{t('app.page.crm.crm_points')}</Typography>
      </div>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    alignItems: 'stretch',
    gap: theme.spacing(2),
    height: CARD.height,
    padding: 0,
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  desc: {
    display: 'block',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '150%',
    maxHeight: 54,
  },
  pointsWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
  points: {
    marginRight: theme.spacing(0.5),
  },
}))
