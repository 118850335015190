import { scrollToTop } from '@/libs/scroll'
import { useEffect } from 'react'

/**
 * @typedef ScrollToOptions
 * @property {number} [duration = 200]
 * @property {number} [delay = 0]
 * @property {number} [offset = 0]
 * @property {number} [smooth = true]
 */

/**
 * 觸碰 status bar 捲動至 pageContainer 頂部
 * @param {ScrollToOptions} options
 */
export function useOnStatusTapToPageTop (options) {
  const callback = () => {
    scrollToTop('pageContainer', options)
  }
  useOnStatusTap(callback)
}

export function useOnStatusTap (callback) {
  useEffect(() => {
    window.addEventListener('statusTap', callback)
    return () => {
      window.removeEventListener('statusTap', callback)
    }
  }, [])
}
