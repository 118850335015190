import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

function SkeletonProgressHeader (props) {
  const classes = useStyles(props)

  return (
    <header className={classes.header}>
      <Skeleton animation='wave' variant='text' width={200} height={32} />
      <Skeleton animation='wave' variant='text' width={100} height={32} />
    </header>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'flex-end',
  },
}))

export default React.memo(SkeletonProgressHeader)
