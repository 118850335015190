import { useSelector } from '@/redux'

/**
 * 在 D2C 模式且餐廳有開啟 disableD2CUIPrice 隱藏客人介面上所有價錢（機場貴賓室）設定時
 * 應隱藏介面上所有顯示價錢的地方，因為貴賓室不需要付款
 * 1. 有用到 PriceLabel 的地方
 *  a. 餐點的卡片
 *  b. 推薦的餐點卡片
 *  c. 單點的 drawer 最上方的餐點資訊
 *  d. 套餐的 drawer 最上方的餐點資訊
 * 2. 有用到 GroupItem 的地方
 *  a. 餐點的選項的價格
 *  b. 套餐的餐點的價格
 * 3. 有用到 FooterContent 的地方
 *  a. 菜單頁面的 footer 左邊的小計
 *  b. 結帳頁面的 footer 左邊的小計
 * 4. 有用到 SubmitItemButton 的地方
 *  a. 單點的 drawer 最下方的加入購物車按鈕
 *  b. 套餐的 drawer 最下方的加入購物車按鈕
 * 5. 有用到 OrderBatchItem 的地方
 *  a. 結帳頁面的 batch 列表
 *  b. 訂單記錄頁面的 batch 列表
 *  c. 訂單追蹤的  batch 列表
 * 6. 有用到 Options 的地方，同有用到 OrderBatchItem 的地方
 * 7. 有用到 PaymentSummaryCard 的地方，同有用到 OrderBatchItem 的地方
 *
 * @returns {boolean}
 */
export default function useDisableD2CUIPrice () {
  const isD2CWeb = useSelector(state => state.app.params.isD2CWeb)
  const { disableD2CUIPrice } = useSelector(state => state.merchant.data?.setting) ?? {}
  return Boolean(isD2CWeb && disableD2CUIPrice)
}
