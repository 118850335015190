import { SplashScreen } from '@capacitor/splash-screen'
import React from 'react'
import logger from '@/libs/logger'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch (error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
    logger.error('ErrorBoundary catch error', { error, errorInfo })
    SplashScreen.hide()

    // CA-1501 當網頁版本更新但 user 尚未 reload 時進入 d2c 會 load 舊的 bundle js 導致 Loading chunk xxx failed
    // 顯示 alert 後 reload 來載入最新的 index.html 和 main bundle
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      window.alert('網頁已更新，需要重新載入')
      window.location.reload()
    }
  }

  render () {
    if (this.state.error) {
      return (
        <div>
          <p>{this.state.error.toString()}</p>
          <p style={{ whiteSpace: 'pre-line' }}>{this.state.errorInfo.componentStack}</p>
        </div>
      )
    }

    return this.props.children
  }
}
