import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'

/**
 * @typedef FindMoreButtonProps
 * @property {() => void} onClick
 * @param {FindMoreButtonProps} props
 * @returns
 */
export default function FindMoreButton (props) {
  const { t } = useTranslation()
  const { onClick } = props
  const classes = useStyles(props)

  return (
    <ButtonBase className={classes.findMore} onClick={onClick}>
      <Typography className={classes.text}>{t('app.common.find_more')}</Typography>
      <ArrowForwardIosIcon className={classes.icon} />
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  findMore: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    fontSize: '1.6rem',
  },
  icon: {
    fontSize: '1.4rem',
    marginLeft: theme.spacing(0.5),
  },
}))
