import * as crypto from 'crypto'

const c = {
  algorithm: 'aes-256-ctr',
  key: '102f0quf9i351k7sgxesox3fltepgqu7',
}
const algorithm = c.algorithm
const password = c.key
const utility = {
  encrypt: (text) => {
    const iv = Buffer.from(crypto.randomBytes(16))
    const cipher = crypto.createCipheriv(algorithm, password, iv)
    let crypted = cipher.update(text, 'utf8', 'hex')
    crypted += cipher.final('hex')
    return `${iv.toString('hex')}::${crypted.toString()}`
  },
  decrypt: (text) => {
    const textParts = text.split('::')
    const iv = Buffer.from(textParts.shift(), 'hex')
    const encryptedText = Buffer.from(textParts.join('::'), 'hex')
    const decipher = crypto.createDecipheriv(algorithm, password, iv)
    let decrypted = decipher.update(encryptedText, 'hex', 'utf8')
    decrypted += decipher.final('utf8')
    return decrypted.toString()
  },
}

export function decryptToken (token) {
  try {
    return utility.decrypt(token)
  } catch (error) {
    return false
  }
}
export function encryptToken (token) {
  return utility.encrypt(token)
}

export default {
  decryptToken,
  encryptToken,
}
