import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useDeliveryType, useTable } from '@/hooks/app'

import DineinButton from './DineinButton'

/**
 *
 * 結帳付款與請求服務按鈕，未入座不顯示
 * @param {{}} props
 * @returns
 */
export default function DineinButtons (props) {
  const classes = useStyles(props)
  const table = useTable()
  const { isTable } = useDeliveryType()

  if (!isTable) return null
  if (!table) return null // 還沒入座不顯示
  return (
    <section className={classes.dineinButtons}>
      <DineinButton variant='checkout' />
      <DineinButton variant='serviceRequest' />
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  dineinButtons: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    margin: theme.spacing(0.5, 1.5),
    gap: theme.spacing(2),
  },
}))
