import { createStore as _createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'
import * as Sentry from '@sentry/react'

import batchStorage from './batchStorage'
import rootReducer from './reducer'

const middleware = [ReduxThunk, batchStorage]
const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const createStore = () => _createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer),
)

const store = createStore()

export default store
