import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { useDatetime } from '@/hooks/app'
import { useSelector } from '@/redux'
import dimorderApi from '@/libs/api/dimorder'

export function useMerchant () {
  return useSelector(state => state.merchant.data)
}

export function useOpenings () {
  // openings[0]是星期天, openings[1]是星期一...
  return useSelector(state => state.merchant.data?.openingsWithSurcharge) ?? {}
}

const cache = {}
export function useMerchantById (merchantId) {
  const [merchant, setMerchant] = React.useState(null)

  React.useEffect(() => {
    if (!merchantId) return

    const fetchMerchant = async () => {
      if (cache[merchantId]) {
        const data = cache[merchantId]
        setMerchant(data)
      } else {
        const data = await dimorderApi.merchant.getMerchant(merchantId)
        setMerchant(data)

        // set response in cache
        cache[merchantId] = data
      }
    }

    fetchMerchant()
  }, [merchantId])

  return merchant
}

/**
 *
 * @returns
 */
export function useAddress () {
  const merchant = useMerchant()
  const str = (merchant?.address ?? '')
  // 避免顯示出現問題，移除 merchant address 字串首尾的空白與換行
  // 但允許換行顯示 merchant address
  return str.trim()
}

export function useTodayOpenings () {
  const openings = useOpenings()
  const datetime = useDatetime()
  const weekday = moment(datetime.date).day()
  const todoayOpeings = openings[weekday]
  return todoayOpeings
}

export function useOpening24h () {
  const todoayOpeings = useTodayOpenings()
  const opening24h = _.find(todoayOpeings, opening => {
    return _.chain(opening)
      .pick(['start', 'startMin', 'end', 'endMin'])
      .every(o => o === 0)
      .value()
  })
  return opening24h
}

export function useClosingTime () {
  const todoayOpeings = useTodayOpenings()
  const closingTime = _.chain(todoayOpeings)
    .sortBy(['end', 'endMin'])
    .findLast()
    .value()
  return closingTime
}

export function useCustomerLayout () {
  const isD2CWeb = useSelector(state => state.app.params.isD2CWeb)
  const merchant = useMerchant()
  const customerLayout = merchant?.setting?.customerLayout
  const isLamaLayout = isD2CWeb && customerLayout === 'LAMA'
  return { customerLayout, isLamaLayout }
}

export default {
  useMerchant,
  useOpenings,
  useMerchantById,
  useTodayOpenings,
  useOpening24h,
  useClosingTime,
}
