import { makeStyles } from '@material-ui/core/styles'
import { transformation } from '@/libs/cloudinary'
import React from 'react'

import { useSelectedBatch } from '@/hooks/order'
import BatchCountBadge from '../BatchCountBadge'

const defaultIcon = 'https://img.dimorder.com/80x/public/logo/lama/default2.png'
const promotedIcon = 'https://img.dimorder.com/80x/public/logo/lama/category-promoted.png'
const SidebarCategoryButton = (props) => {
  const { category } = props
  const classes = useStyles(props)
  const promoted = category.id === 'promoted-category'
  const icon = transformation(category.image || (promoted ? promotedIcon : defaultIcon), { width: 80 })
  const batch = useSelectedBatch()

  const countItem = () => {
    if (promoted) {
      return (batch?.items ?? [])
      // 套餐的從 category.sets 裡面去找，單點的從 category.menus 裡找
        .filter((item) => category.menus.some(menu => menu.menuId === item.menuId) || category.sets.some(menu => menu.id === item.setId))
        .reduce((sum, item) => sum + item.quantity, 0)
    }
    return (batch?.items ?? [])
      .filter((item) => item.categoryId === category.id)
      .reduce((sum, item) => sum + item.quantity, 0)
  }

  return (
    <div className={classes.icon} style={{ backgroundImage: `url(${icon})` }}>
      <BatchCountBadge quantity={countItem()} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    width: 40,
    height: 40,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.common.white,
    backgroundSize: 'contain',
    borderRadius: 10,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    position: 'relative',
  },
}))

export default SidebarCategoryButton
