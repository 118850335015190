import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Card from '@/components/Cards/Card'

function SkeletonOrderBatchItemList (props) {
  const classes = useStyles(props)

  return (
    <Card className={classes.container}>
      <div className={classes.totalQuantity}>
        <Skeleton animation='wave' variant='text' width={50} height={30} />
      </div>
      <ul className={classes.orderBatch}>
        <li className={classes.item}>
          <div className={classes.left}>
            <Skeleton animation='wave' variant='text' width={140} height={21} />
            <Skeleton animation='wave' variant='text' width={80} height={21} />
          </div>
          <Skeleton animation='wave' variant='text' width={32} height={32} />
        </li>
        <li className={classes.item}>
          <div className={classes.left}>
            <Skeleton animation='wave' variant='text' width={120} height={21} />
            <Skeleton animation='wave' variant='text' width={60} height={21} />
          </div>
          <Skeleton animation='wave' variant='text' width={32} height={32} />
        </li>
      </ul>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  totalQuantity: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  orderBatch: {
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  item: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default React.memo(SkeletonOrderBatchItemList)
