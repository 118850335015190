import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { MENU_ITEM_IMAGE, transformation } from '@/libs/cloudinary'
import { actions, useDispatch } from '@/redux'
import { getMenuImage } from '@/libs/menu'
import { useBatchItemQuantity } from '@/hooks/contexts/BatchItemQuantityContext'
import { useMerchant } from '@/hooks/merchant'
import constants from '@/constants'
import useTrack from '@/hooks/useTrack'

import Card from '@/components/Cards/Card'
import PriceLabel from '@/components/PriceLabel'

import SoldLayer from './SoldLayer'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem } from '@/redux/menu/MenuState.d'

/**
 * @typedef RecommendedMenuCardProps
 * @property {IAppMenuItem} menu
 */

/**
 * 推薦餐點卡
 * 顯示餐點的圖片、名稱、價錢。
 * 點擊打開 MenuItemDrawer 查看細節，並透過 Drawer 加入購物車。
 * 點擊後無論如何都應該打開 MenuItemDrawer（不能用快速點餐） 原因有以下幾點：
 *  a. 推薦 RecommandedMenuCard 卡片太小無法顯示說明文字，要查看更多細節需要打開 MenuItemDrawer。
 *  b. 避免 user 在結帳頁面時，誤觸加了不想要的東西到 batch 內。
 *
 * 1. 售完時顯示售完並不可點擊 (menu.sold)
 * 2. 庫存不足時顯示售完並不可點擊 (merchant.setting.inventory && menu.inventory === 0)
 * @param {RecommendedMenuCardProps} props
 * @returns
 */
export default function RecommandedMenuCard (props) {
  const { menu } = props
  const imageUrl = transformation(getMenuImage(menu), { width: MENU_ITEM_IMAGE.sm.width }) || constants.app.image.EMPTY_IMAGE
  const classes = useStyles({ ...props, imageUrl })
  const dispatch = useDispatch()
  const batchItemQuantity = useBatchItemQuantity()
  const merchant = useMerchant()
  const { sendEvent } = useTrack()

  const name = menu.name.replace(/\u00A0/g, ' ') // production 8c80423a-e540-4d64-b1b3-4b1962db63eb 餐廳的設定餐點名稱內含有 U+00a0 (&nbsp;) 會造成破版，取代為空格
  const quantity = batchItemQuantity[menu.id]
  const enableInventoryCheck = merchant?.setting?.inventory

  const isSold = React.useMemo(() => {
    if (menu.isSold) return true
    if (enableInventoryCheck) {
      if (menu.inventory === -1) return false // 當 inventory === -1 時代表不限數量
      if (menu.inventory === 0) return true // 當 inventory === 0 時代表已無庫存
      if (menu.inventory <= quantity) return true // 當庫存數量已被這個 batch 點完時代表庫存不足
    }
    return false
  }, [menu.isSold, menu.inventory, quantity, enableInventoryCheck])

  const handleIncrement = (e) => {
    if (isSold) return // 已售完不可點擊
    e.stopPropagation()
    // 這邊不使用 quickAdd
    // 這邊先不處理套餐
    dispatch(actions.batchItemTemp.addItem(menu))
    dispatch(actions.app.toggleDrawer('menuItem', true, { menu }))
    sendEvent(constants.track.EVENT.VIEW_ITEM, { item: menu, merchant })
  }

  return (
    <Card className={classes.recommendedMenuCard}>

      {/*
      點擊卡片打開 Drawer
      無論是否有選項，都必需要打開 Drawer 避免不小心加了不想要的東西到購物車
       */}
      <ButtonBase
        className={classes.pressable}
        onClick={handleIncrement}
        disabled={isSold}
      >

        <SoldLayer sold={isSold} />

        {/*
        餐點圖片：
        1. 顯示餐點圖片
        */}
        <div className={classes.menuImage} />

        {/*
        餐點內容：
        1. 顯示餐點名稱
        2. 顯示餐點價錢（使用 PriceLabel component）
        3. 卡片太小無法顯示說明文字，要查看更多細節需要打開 MenuItemDrawer
        */}
        <div className={classes.menuContent}>
          <Typography variant='subtitle2'>{name}</Typography>
          <PriceLabel menu={menu} />
        </div>

        {/* 裝飾性的 + */}
        <div className={classes.add}>
          <Typography variant='h6' className={classes.addIcon}>+</Typography>
        </div>
      </ButtonBase>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  recommendedMenuCard: {
    display: 'flex',
    minHeight: 80,
    minWidth: 240,
    padding: 0,
    overflow: 'hidden',
  },
  pressable: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  menuImage: {
    flexShrink: 0,
    height: 80,
    width: 80,
    backgroundColor: theme.palette.grey[100],
    backgroundImage: props => `url(${props.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: 8,
  },
  menuContent: {
    flex: 1,
    maxHeight: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    padding: 6,
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  addIcon: {
    padding: 4,
    fontSize: '1.8rem',
  },
}))
