import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@material-ui/icons/HelpOutline'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import AppConstants from '@/constants/app'
import DeliveryTypes from '@/constants/deliveryType'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'
import useQuery from '@/hooks/useQuery'

import Navbar from '@/components/Navbar'
import NoticeCard from '@/components/Cards/NoticeCard'
import OrderBatchItemList from '@/components/OrderBatchItemList'
import OrderRewardItemList from '@/components/OrderRewardItemList'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import PayingCard from '@/components/Cards/PayingCard'
import PaymentMethodDrawer from '@/components/Drawers/PaymentMethodDrawer'
import PaymentSummaryCard from '@/components/Cards/PaymentSummaryCard'

import CancelButton, { CUSTOMER_CANCEL_REASON } from './CancelButton'
import DimboxCard from './DimboxCard'
import GainCouponsCard from './GainCouponsCard'
import GoPrevBrowserScreen from './GoPrevBrowserScreen'
import LuckyDrawButton from './LuckyDrawButton'
import OrderCancelledDialog from './OrderCancelledDialog'
import ProgressCard from './ProgressCard'
import Skeleton from './Skeleton'
import WebPaymentQRCode from './WebPaymentQRCode'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'
// eslint-disable-next-line no-unused-vars
import { TDeliveryType } from 'dimorder-orderapp-lib/dist/types/Order'

/**
 * 從 orderHistory 中找出選擇的訂單，
 * 如果網址有帶 orderId /order_tracking/:orderId，優先使用 orderId，
 * 再來才看 orderHistory.selectedOrderId
 * @returns
 */
function useTrackingOrder () {
  const { orderId } = useParams()
  const { orders, selectedOrderId } = useSelector((state) => state.orderHistory)
  const id = orderId ?? selectedOrderId
  return _.find(orders, order => order.id === id)
}

/**
 * 當訂單的最後一個 payment 的 status 改變時，根據 payment.status 和 payment.createdAt 判斷是否要顯示付款失敗的警告
 * 1. 當 payment.status 變成 'cancel' 時代表這個 payment 失敗或被取消
 * 2. 當 payment.createdAt 在當前的 5 分鐘內
 * 符合以上情況則顯示付款失敗的警告，當 user 離開頁面後再進來可以再顯示一次，不用特別阻擋重複顯示
 * @param {IAppOrder} order
 */
function usePaymentFailedAlert (order) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const deliveryType = order?.deliveryType
  const lastPayment = _.last(order?.payments)

  /**
   * 根據 payment.respCode 取得錯誤訊息
   * @param {string} respCode
   * @param {TDeliveryType} deliveryType
   * @returns
   */
  function getPaymentFailedAlertMessage (respCode, deliveryType) {
    const isTable = deliveryType === 'table'
    switch (respCode) {
      case '4093':
        return t('app.component.alert.payment_failed.error_4093')
      case '4005':
        return t('app.component.alert.payment_failed.error_4005')
      case '4081':
        return t('app.component.alert.payment_failed.error_4081')
      default:
        if (isTable) {
          return t('app.component.alert.payment_failed.error_default_table')
        } else {
          return t('app.component.alert.payment_failed.error_default')
        }
    }
  }

  const { id, gateway, status, paidAt, createdAt, respCode } = lastPayment ?? {}
  useEffect(() => {
    if (!id) return // 沒有 lastPayment

    const isFailedPayment = status === 'cancel' && !paidAt // 狀態為 cancel 且沒有 paidAt 代表付款失敗
    const isWithinDuration = moment().isBetween(moment(createdAt), moment(createdAt).add(5, 'minutes')) // payment.createdAt 在 5 分鐘內
    if (isFailedPayment && isWithinDuration) {
      dispatch(actions.app.toggleAlert({
        title: t('app.component.alert.payment_failed.title'),
        message: getPaymentFailedAlertMessage(respCode, deliveryType),
        buttons: [{ text: t('app.common.confirm') }],
      }))
    }
  }, [id, gateway, status, paidAt, createdAt, respCode])
}

export default function OrderTracking (props) {
  useOnStatusTapToPageTop()

  const { disableNavbar, location } = props
  const classes = useStyles({ ...props, disableNavbar })
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const d2cBaseUrl = useD2CBaseUrl()
  const showQRCode = useSelector(state => state.payment.showQRCode)
  const savePayment = useSelector(state => state.payment.savePayment)
  const merchantId = useSelector(state => state.merchant.data?.id)
  const isResetOrder = location.state?.resetOrder

  const [cancelledDialogOpen, setCancelledDialogOpen] = useState(false)
  const [isGetOrdersLoading, setGetOrderLoading] = useState(false)
  const [isFirstTimeGetOrdersDone, setFirstTimeGetOrdersDone] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const [showGoPrevBrowser, setGoPrevBrowser] = useState(false)

  // 在 /order_tracking/:orderId 的情況若有帶 isLogin 表示轉到第三方付款前是有登入的
  const query = useQuery()
  const isLoginBefore = ['1', 'true'].includes(query.get('isLogin'))
  const isLogin = useIsLogin()

  const { orderId } = useParams()
  const order = useTrackingOrder()

  const isOrderPendingPay = ['waiting_pay', 'paying'].includes(order?.displayStatusKey)
  const shouldShowPaymentQRCode = showQRCode && isOrderPendingPay

  const getOrders = async () => {
    setGetOrderLoading(true)
    await dispatch(actions.orderHistory.getOrders())
    setFirstTimeGetOrdersDone(true)
    setGetOrderLoading(false)
  }

  // 當網址為 /order_tracking （沒有 orderId）時使用這個 function init
  const initWithSelectedOrderId = () => {
    if (!order) {
      history.replace(`${d2cBaseUrl}/orders`)
    }

    setIsInit(true)
  }

  // 當網址 /order_tracking/:orderId 有帶 orderId 時使用這個 function init
  const initWithUrlOrderId = () => {
    if (isGetOrdersLoading) return // 還在 loading 表示上一個 useEffect 尚未完成，無法確認是否能從 orderHistory 抓到 order

    if (order) {
      // 在 orderHistory 中直接就找到訂單，表示使用者一定是使用原來的瀏覽器
      dispatch(actions.orderHistory.selectOrder(orderId, false))
      setIsInit(true)
      return
    }

    // 當 orderHistory 中找不到訂單
    if (!isFirstTimeGetOrdersDone) {
      // 一開始在 orderHistory 中找不到 order 可能是正常的
      // 先抓一次 getOrders 以便確認能不能抓到指定的 orderId
      getOrders()
      return
    }

    // 已經抓過一次 orders 了
    if (!order) {
      // 但還是抓不到，表示從第三方支付回來有換瀏覽器
      // user 沒有登入
      if (!isLogin) {
        if (isLoginBefore) {
        // 之前有登入，要求使用者回到原本的瀏覽器，或是再登入一次
          setGoPrevBrowser(true)
        } else {
          // 之前是匿名登入，selectOrder 帶第二個參數 trackingGlobal: true，直接改用 /g/order 來更新訂單
          dispatch(actions.orderHistory.selectOrder(orderId, true))
        }
      }
      // 重新抓一次 getOrders
      getOrders()
      setIsInit(true)
    }
  }

  useEffect(() => {
    // 如果是訂單結帳後進來 orderTracking，訂單不會再點餐，所以清除開新的，避免無法在同一間餐廳下單
    if (isResetOrder) dispatch(actions.order.resetOrder('/order_tracking with resetOrder'))
  }, [isResetOrder])

  // 處理登入後讓訂單顯示出來
  useEffect(() => {
    if (isLogin) {
      setGoPrevBrowser(false)
      // 抓一次 getOrders 去抓指定的 orderId
      getOrders()
    }
  }, [isLogin])

  // 初始 OrderTracking
  useEffect(() => {
    if (isInit) return // 已經完成流程就不用再執行了
    if (!orderId) {
      // 當網址沒帶 orderId 就是照以前的流程使用 orderHistory.selectedOrderId
      initWithSelectedOrderId()
    } else {
      // 當網址 /order_tracking/:orderId 有帶 orderId 需要先抓一次 getOrders 以確認是否能拿到屬於自己的訂單
      initWithUrlOrderId()
    }
  }, [isInit, isGetOrdersLoading, orderId, order])

  useEffect(() => {
    if (order?.merchantId && order?.merchantId !== merchantId) {
      // 如果拿到 order 但是還沒有 merchant 的資料 fetchMerchant
      dispatch(actions.merchant.fetchMerchant(order.merchantId))
    }
  }, [order?.merchantId])

  // 處理訂單狀態改變
  useEffect(() => {
    if (!order) return
    if (order.status === 'cancelled') {
      setCancelledDialogOpen(true)
    } else if (order.status === 'paid') {
      if (!savePayment) {
        dispatch(actions.payment.resetPayment())
      }
    }
  }, [order?.id, order?.status])

  // 找不到 order 的時候顯示 dialog
  useEffect(() => {
    if (!isGetOrdersLoading && isInit && !order && !showGoPrevBrowser) {
      dispatch(actions.app.toggleAlert({
        message: t('app.page.order_tracking.order_not_found_alert.message'),
        buttons: [
          {
            text: t('app.page.order_tracking.order_not_found_alert.retry'),
            onClick: () => { setIsInit(false) },
          },
          {
            text: t('app.page.order_tracking.order_not_found_alert.back'),
            onClick: () => history.replace('/orders'),
            style: { backgroundColor: theme.palette.primary.main, flexGrow: 1.2 },
          },
        ],
      }))
    }
  }, [isGetOrdersLoading, order, isInit, showGoPrevBrowser])

  usePaymentFailedAlert(order)

  const HelpButton = (
    <ButtonBase
      className={classes.helpButton}
      onClick={(e) => {
        window.open(`https://wa.me/85268262032?text=訂單編號:${order?.serial ?? orderId}`)
      }}
    >
      <InfoOutlinedIcon className={classes.helpButtonIcon} fontSize='small' />
      <Typography variant='body2'>
        {t('app.page.order_tracking.navbar.help')}
      </Typography>
    </ButtonBase>
  )

  // 要求使用者回到原本的瀏覽器
  if (!order && showGoPrevBrowser) {
    return <GoPrevBrowserScreen />
  }

  // 目前還沒抓到訂單 / 抓不到訂單
  if (!order) {
    return <Skeleton />
  }

  return (
    <Page>
      <Navbar
        title={t('app.page.order_tracking.navbar.title')}
        withLeave={false}
        rightColumn={HelpButton}
      />
      <PageContainer className={classes.container}>
        <LuckyDrawButton order={order} />
        <DimboxCard order={order} />
        <ProgressCard order={order} />
        {order.deliveryType === DeliveryTypes.STORE_DELIVERY && (
          <NoticeCard
            variant='info'
            message={t('app.page.order_tracking.deliveryNotice')}
          />
        )}
        <GainCouponsCard order={order} />
        <PayingCard order={order} />
        <OrderBatchItemList order={order} batches={order.batches} />
        <OrderRewardItemList order={order} />
        <PaymentSummaryCard isHistory order={order} />
        <CancelButton order={order} />
        {shouldShowPaymentQRCode && <WebPaymentQRCode />}
        {cancelledDialogOpen && (
          <OrderCancelledDialog
            title={order.deliveryType === DeliveryTypes.TABLE
              ? t('app.page.order_tracking.cancelled_dialog.title') // 堂食只顯示訂單已取消，因為不一定會退回費用
              : order.cancelReason === CUSTOMER_CANCEL_REASON
                ? t('app.page.order_tracking.cancelled_dialog.customer_cancel_title') // 由顧客自己取消，顯示訂單已取消，將退回費用
                : t('app.page.order_tracking.cancelled_dialog.restaurant_cancel_title')} // 由餐廳取消，顯示餐廳取消訂單，將退回費用
            open={cancelledDialogOpen}
            onConfirm={() => {
              setCancelledDialogOpen(false)
            }}
          />
        )}
      </PageContainer>
      <PaymentMethodDrawer />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: AppConstants.marginTop.NAVBAR,
    marginBottom: AppConstants.marginBottom.GENERAL,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  helpButton: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: 'rgba(0,0,0,0.75)',
  },
}))
