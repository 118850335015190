import _ from 'lodash'

import { useSelector } from '@/redux'

import { useGroupQuery } from './queries/CRM/useGroupQuery'
import { useMenuQuery } from './queries/menu/useMenuQuery'
import { useSelectedBatch } from './order'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IRewardItem } from 'dimorder-orderapp-lib/dist/types/CRM'
/* eslint-enable no-unused-vars */

export function useCart () {
  const { hasRewardItems, rewardItems } = useRewardItems()
  const { hasBatchItems, batchItems } = useBatchItems()
  const hasItemsInCart = hasBatchItems || hasRewardItems
  return { batchItems, rewardItems, hasBatchItems, hasRewardItems, hasItemsInCart }
}

export function useBatchItems () {
  const batchItems = useSelectedBatch()?.items ?? []
  const menuQuery = useMenuQuery()
  const menus = menuQuery.data?.menus
  const hasBatchItems = batchItems.length > 0
  const hasAlcoholDisclaimerItem = batchItems.some(batchItem => _.find(menus?.[batchItem.menuId]?.tags, { name: 'disclaimer_alcoholic' }))
  return { batchItems, hasBatchItems, hasAlcoholDisclaimerItem }
}

/**
 *
 * @param {IAppOrderBatch[]} data
 * @returns
 */
export function getBatchItemsTotal (data) {
  const quantity = _.reduce(data, (acc, batch) => {
    const batchQuantity = _.reduce(batch?.items, (acc, item) => {
      if (item.rewardId) return acc // 如果 batchItem 是 reward 的話不列入計算
      return acc + item.quantity
    }, 0)
    return acc + batchQuantity
  }, 0)
  return { quantity }
}

export function useRewardItems () {
  const rewardItems = useSelector(state => state.orderBatch.rewardItems)
  const total = getRewardItemsTotal(rewardItems)
  const hasRewardItems = total.quantity > 0
  return { rewardItems, total, hasRewardItems }
}

/**
 *
 * @param {RewardItem[]} data
 * @returns
 */
export function getRewardItemsTotal (data) {
  return _.reduce(data, (acc, curr) => {
    const isCancelled = curr.cancelled || curr.batchStatus === 'cancelled' // 已取消的 reward 不列入計算
    if (isCancelled) return acc
    acc.quantity = acc.quantity + curr.quantity
    acc.points = acc.points + curr.points * curr.quantity
    return acc
  }, { points: 0, quantity: 0 })
}

/**
 * balancePoints - 帳戶點數
 * payablePoints - 應付點數
 * availablePoints - 可用點數
 * @param {number} [payablePoints]
 * @returns
 */
export function useAvailableCRMPoints (payablePoints) {
  const { total } = useRewardItems()
  const { data: group } = useGroupQuery()
  const balancePoints = group?.points ?? 0
  const availablePoints = balancePoints - (payablePoints ?? total.points)
  return { balancePoints, payablePoints, availablePoints }
}
