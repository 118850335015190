import { ButtonBase } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { createBatchSetItem } from '@/libs/order'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import constants from '@/constants'
import hooks from '@/hooks'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 *
 * @param {{
 * menu: IAppMenuItem | IAppSet
 * setItemMenu?: IAppMenuItem
 * isSold: boolean
 * }} props
 * @returns
 */
export default function OrderLayer (props) {
  const { menu, setItemMenu, isSold } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const merchant = hooks.merchant.useMerchant()
  const { sendEvent } = hooks.useTrack()

  const isSet = Boolean(menu.steps)

  const handleClick = () => {
    dispatch(actions.batchItemTemp.addItem(menu))
    if (isSet) {
      // 如果 menu 是套餐的話，打開 SetItemDrawer
      // 如果是根據特定步驟拆開的套餐要傳 setItemMenu 給 SetItemDrawer 知道現在點的是哪個 setItem
      dispatch(actions.app.toggleDrawer('setItem', true, { setMenu: menu, setItemMenu }))

      if (setItemMenu) {
        // 如果是把 step1 拆開來的套餐
        const hasOptions = !_.isEmpty(setItemMenu.options)
        if (hasOptions) {
          // 如果有選項則打開選項視窗
          dispatch(actions.app.toggleDialog('setItemOption', true, { setMenu: menu, setItemMenu }))
          return
        }
        dispatch(actions.batchItemTemp.updateSetItems([createBatchSetItem(setItemMenu)]))
      }
    } else {
      dispatch(actions.app.toggleDrawer('menuItem', true, { menu }))
    }

    sendEvent(constants.track.EVENT.VIEW_ITEM, {
      item: menu,
      merchant,
    })
  }

  return (
    <ButtonBase
      className={classes.layer}
      disabled={isSold}
      TouchRippleProps={{ classes: { child: classes.rippleChild } }}
      onClick={handleClick}
    >
      {/* <Typography className={classes.text}>{t('app.common.sold_out')}</Typography> */}
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  rippleChild: {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  layer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      zIndex: 100,
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      // backgroundColor: `${theme.palette.grey[900]}${opacity[70]}`,
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
  text: {
    zIndex: 200,
    color: theme.palette.grey[200],
    fontSize: '2.2rem',
    fontWeight: 'bold',
  },
}))
