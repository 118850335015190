import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { scrollToTop } from '@/libs/scroll'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import customerApi from '@/libs/api/customer'

import Header from '@/components/Header'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import Tabs from '@/components/Tabs'
import constants from '@/constants'

import RiceCoinInfo from './RiceCoinInfo'
import TransactionList from './TransactionList'

let LedgerApiPerPage = 25

export default function RiceCoin (props) {
  useOnStatusTapToPageTop()

  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const history = useHistory()
  const member = useSelector(state => state.user.member) ?? 0
  const { amount: riceCoinBalance = 0, withholding = 0 } = member
  const availableBalance = Math.round(riceCoinBalance - withholding)

  const [selectedTab, setSelectedTab] = useState(0)

  // 收入
  const [isRewardNextPageLoading, setIsRewardNextPageLoading] = React.useState(false)
  const [rewardHasNextPage, setRewardHasNextPage] = React.useState(true)
  const [rewardItems, setRewardItems] = React.useState([])
  // 支出
  const [isTransactionNextPageLoading, setIsTransactionNextPageLoading] = React.useState(false)
  const [transactionHasNextPage, setTransactionHasNextPage] = React.useState(true)
  const [transactionItems, setTransactionItems] = React.useState([])

  const handleScrollToTop = () => scrollToTop('pageContainer')

  useEffect(() => {
    dispatch(actions.user.getCustomerInfo())
    const updater = setInterval(() => {
      dispatch(actions.user.getCustomerInfo())
    }, 10e3) // 每10秒更新CustomerInfo，拿到最新RiceCoin balance

    return () => clearInterval(updater)
  }, [])

  const tabs = [
    t('app.page.riceCoin.tabs.rewards'),
    t('app.page.riceCoin.tabs.transactions'),
  ]

  const loadRewardNextPage = async (startIndex, stopIndex) => {
    setIsRewardNextPageLoading(true)

    const page = Math.floor(startIndex / LedgerApiPerPage) + 1
    const response = await customerApi.getLedgers({ page, 'amount[gte]': 1 })
    LedgerApiPerPage = response.meta.perPage
    setRewardHasNextPage(response.meta.lastPage > response.meta.currentPage)
    setRewardItems([...rewardItems, ...response.data])

    setIsRewardNextPageLoading(false)
  }

  const loadTransactionNextPage = async (startIndex, stopIndex) => {
    setIsTransactionNextPageLoading(true)

    const page = Math.floor(startIndex / LedgerApiPerPage) + 1
    const response = await customerApi.getLedgers({ page, 'amount[lte]': -1 })
    LedgerApiPerPage = response.meta.perPage
    setTransactionHasNextPage(response.meta.lastPage > response.meta.currentPage)
    setTransactionItems([...transactionItems, ...response.data])

    setIsTransactionNextPageLoading(false)
  }

  return (
    <Page>
      <Header onBack={() => history.goBack()}>
        <RiceCoinInfo balance={availableBalance} />
      </Header>
      <Tabs
        className={classes.tabs}
        list={tabs}
        selected={selectedTab}
        setSelected={(index) => {
          handleScrollToTop()
          setSelectedTab(index)
        }}
      />

      <PageContainer className={classes.container}>
        {selectedTab === 0
          ? (
            <TransactionList
              hasNextPage={rewardHasNextPage}
              isNextPageLoading={isRewardNextPageLoading}
              items={rewardItems}
              loadNextPage={loadRewardNextPage}
              isReward
            />
          )
          : (
            <TransactionList
              hasNextPage={transactionHasNextPage}
              isNextPageLoading={isTransactionNextPageLoading}
              items={transactionItems}
              loadNextPage={loadTransactionNextPage}
            />
          )}

      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    marginBottom: constants.app.marginBottom.GENERAL,
  },
  tabs: {
    justifyContent: 'space-around',
    margin: theme.spacing(1, 0),
  },
}))
