import { BsPersonVcard } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import hooks from '@/hooks'

import { ReactComponent as AddressIcon } from '@/assets/icons/menu_address.svg'
import { ReactComponent as CouponIcon } from '@/assets/icons/coupon.svg'
import { ReactComponent as FavoriteIcon } from '@/assets/icons/menu_favorite.svg'
import { ReactComponent as LanguageIcon } from '@/assets/icons/menu_language.svg'
import { ReactComponent as LogoutIcon } from '@/assets/icons/menu_logout.svg'
import { ReactComponent as RcIcon } from '@/assets/icons/menu_ricecoin.svg'
import { ReactComponent as RedeemIcon } from '@/assets/icons/menu_redeem.svg'
import { ReactComponent as VersionIcon } from '@/assets/icons/menu_version.svg'

import MenuItemBase from './MenuItemBase'

MenuItem.propTypes = {
  variant: PropTypes.oneOf([
    'riceCoin',
    'redeem',
    'coupon',
    'membership',
    'address',
    'language',
    'version',
    'login',
    'logout',
    'favorite',
  ]),
}

/**
 *
 * @param {{
 * variant: 'riceCoin' | 'redeem' | 'coupon' | 'membership' | 'address' | 'language' | 'version' | 'login' | 'logout' | 'favorite'
 * hidden?: boolean
 * }} props
 * @returns
 */
export default function MenuItem (props) {
  const { variant, hidden } = props
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const member = hooks.user.useMember()
  const memberId = member?.id

  if (hidden) {
    return null
  }

  switch (variant) {
    case 'riceCoin':
      return (
        <MenuItemBase
          title='RiceCoin'
          icon={<RcIcon />}
          onClick={() => {
            if (member?.id) {
              history.push('/member/ricecoin')
            } else {
              dispatch(actions.app.toggleLoginDrawer(true))
            }
          }}
        />
      )

    case 'redeem':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.redeem')}
          icon={<RedeemIcon />}
          onClick={() => {
            if (member?.id) {
              history.push('/member/redeem')
            } else {
              dispatch(actions.app.toggleLoginDrawer(true))
            }
          }}
        />
      )

    case 'coupon':
      return (
        <MenuItemBase
          title={t('app.page.member.coupon')}
          icon={<CouponIcon />}
          onClick={() => {
            if (member?.id) {
              history.push('/member/coupon')
            } else {
              dispatch(actions.app.toggleLoginDrawer(true))
            }
          }}
        />
      )

    case 'address':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.address')}
          icon={<AddressIcon />}
          onClick={() => {
            dispatch(actions.user.updateExitAddressEditBackLevel(1))
            history.push('/settings/address')
          }}
        />
      )

    case 'language':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.language')}
          icon={<LanguageIcon />}
          onClick={() => { dispatch(actions.app.toggleDialog('changeLang')) }}
        />
      )

    case 'version':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.version')}
          icon={<VersionIcon />}
          onClick={() => { history.push('/settings/version') }}
        />
      )

    case 'login':
      return (
        <MenuItemBase
          title={`${t('app.page.member.menu_list.sign_in')} (login anonymous for test)`}
          onClick={() => { dispatch(actions.user.dummyMemberLogin()) }}
          hidden={!(!memberId && process.env.NODE_ENV !== 'production')}
        />
      )

    case 'logout':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.sign_out.title')}
          icon={<LogoutIcon />}
          onClick={() => {
            dispatch(actions.app.toggleAlert({
              title: t('app.page.member.menu_list.sign_out.alert.title'),
              message: t('app.page.member.menu_list.sign_out.alert.message'),
              onConfirm: () => dispatch(actions.user.logout()),
            }))
          }}
          hidden={!memberId}
        />
      )

    case 'favorite':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.favorite')}
          icon={<FavoriteIcon />}
          onClick={() => { history.push('/favorite') }}
          hidden={!memberId}
        />
      )

    case 'membership':
      return (
        <MenuItemBase
          title={t('app.page.member.menu_list.membership_card')}
          icon={<BsPersonVcard size={24} style={{ color: theme.palette.secondary.main }} />}
          onClick={() => history.push('/membership')}
        />
      )

    default:
      return null
  }
}
