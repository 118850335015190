import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

DrawerContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

/**
 *
 * @param {{
 * children?: Any
 * className?: String
 * }} props
 */
export default function DrawerContent (props) {
  const { children, className } = props
  const classes = useStyles(props)

  return (
    <div className={clsx(classes.drawerContent, className)}>
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawerContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
}))
