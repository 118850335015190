import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useRef, useState } from 'react'
import clsx from 'clsx'

export default function PincodeInput (props) {
  const { value = '', onChange, error, helperText } = props

  const [focus, setFocus] = useState(false)
  const inputRef = useRef()
  const classes = useStyles(props)

  const handleValueChange = useCallback((e) => {
    if (e.target.value.length <= 6) {
      onChange(e.target.value)
    }
  }, [])

  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        autoFocus
        ref={inputRef}
        value={value}
        onChange={handleValueChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        inputMode='numeric'
      />
      <ul
        className={clsx(
          classes.inputWrap,
          classes.borderColor,
          {
            [classes.borderColorFocus]: focus,
            [classes.borderColorError]: error,
          },
        )}
        onClick={() => inputRef.current.focus()}
      >
        {value.padEnd(6, ' ').split('').map((number, index) => {
          return (
            <li key={index}>{number}</li>
          )
        })}
      </ul>
      {error && <Typography className={classes.errorMessage}>{helperText}</Typography>}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 252,
  },
  input: {
    position: 'fixed',
    top: '-100%',
    left: 0,
  },
  inputWrap: {
    padding: '5px 0px',
    maxWidth: 252,
    height: 44,
    display: 'flex',
    border: '2px solid #E0E0E0',
    borderRadius: 4,
    fontSize: 18,

    '& > li': {
      width: 'calc(100% / 6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& > :not(:last-child)': {
      borderRight: '2px solid #E0E0E0',
    },
  },
  borderColor: {
    borderColor: '#E0E0E0',
  },
  borderColorFocus: {
    borderColor: theme.palette.primary.main,
  },
  borderColorError: {
    borderColor: theme.palette.error.main,
    '& > :not(:last-child)': {
      borderColor: theme.palette.error.main,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}))
