import { ButtonBase, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { useIsEndReached, useScrollActiveIntoView, useTempDatetimeContext, useTimeRangeContext } from '@/hooks/contexts/DatetimePickerContext'

/* eslint-disable no-unused-vars */
import { UITime } from 'dimorder-orderapp-lib/dist/types/Timeslot'
/* eslint-enable no-unused-vars */

const TIME_BUTTON = { width: undefined, height: 40, borderRadius: 5 }

export default function TimeRangeList (props) {
  const { timeRange, isLoading: isTimeRangeLoading } = useTimeRangeContext()
  const { scrollRef, isStartReached, isEndReached, handleScroll } = useIsEndReached('vertical', timeRange)
  const classes = useStyles({ ...props, isStartReached, isEndReached })

  return (
    <section className={classes.timeRangeListWrap}>
      <ul
        ref={scrollRef}
        className={classes.timeRangeList}
        onScroll={e => handleScroll(e)}
      >
        <SkeletonTimeRangeList loading={isTimeRangeLoading} />
        {_.map(timeRange, time => (
          <TimeButton key={time.key} time={time} />
        ))}
      </ul>
    </section>
  )
}

/**
 * @typedef TimeButtonProps
 * @property {UITime} time
 * @param {TimeButtonProps} props
 * @returns
 */
function TimeButton (props) {
  const { time } = props
  const { tempDatetime, setTempDatetime } = useTempDatetimeContext()
  const classes = useStyles(props)
  const isActive = time.key === tempDatetime.time
  const activeRef = useScrollActiveIntoView()

  return (
    <ButtonBase
      ref={isActive ? activeRef : undefined}
      component='li'
      className={clsx(classes.timeBlock, isActive && 'active')}
      onClick={() => {
        setTempDatetime({ ...tempDatetime, time: time.key, isImmediate: time.isImmediate })
      }}
    >
      <Typography>{time.time}</Typography>
    </ButtonBase>
  )
}

/**
 * @typedef SkeletonTimeRangeListProps
 * @property {boolean} loading
 * @param {SkeletonTimeRangeListProps} props
 * @returns
 */
function SkeletonTimeRangeList (props) {
  const { loading } = props
  if (!loading) return null
  return _.map(_.times(15), (n, i) => (
    <Skeleton
      key={i}
      variant='rect'
      width={TIME_BUTTON.width}
      height={TIME_BUTTON.height}
      style={{
        borderRadius: TIME_BUTTON.borderRadius,
        flexShrink: 0,
      }}
    />
  ))
}

const useStyles = makeStyles(theme => ({
  timeRangeListWrap: {
    flexShrink: 0,
    position: 'relative',
    margin: theme.spacing(1, 0),
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 100,
      top: 0,
      left: 0,
      width: '100%',
      height: 0,
      boxShadow: `0 8px 16px 8px ${theme.palette.common.white}`,
      opacity: props => props.isStartReached ? 0 : 0.9,
      transition: 'opacity 500ms ease',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 100,
      bottom: -16,
      left: 0,
      height: 16,
      width: '100%',
      boxShadow: `0 -8px 16px 8px ${theme.palette.common.white}`,
      opacity: props => props.isEndReached ? 0 : 0.9,
      transition: 'opacity 500ms ease',
    },
  },
  timeRangeList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 'max-content',
    gap: theme.spacing(1),
    height: 190,
    overflow: 'auto',
  },
  timeBlock: {
    height: TIME_BUTTON.height,
    backgroundColor: theme.palette.grey[50],
    border: '1px solid',
    borderColor: theme.palette.grey[500],
    borderRadius: TIME_BUTTON.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&.active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
}))
