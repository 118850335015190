import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {{
 * size: number
 * vertical: 'top' | 'bottom'
 * horizontal: 'left' | 'right'
 * }} props
 * @returns
 */
export default function Corner (props) {
  const { vertical, horizontal } = props
  const getPosition = () => {
    if (vertical === 'top' && horizontal === 'left') return 'topLeft'
    if (vertical === 'top' && horizontal === 'right') return 'topRight'
    if (vertical === 'bottom' && horizontal === 'right') return 'bottomRight'
    if (vertical === 'bottom' && horizontal === 'left') return 'bottomLeft'
    return 'topLeft'
  }
  const position = getPosition()
  const classes = useStyles({ ...props, position })

  return (
    <span className={classes.corner} />
  )
}

const useStyles = makeStyles(theme => ({
  corner: {
    position: 'absolute',
    top: props => props.vertical === 'top' && 0,
    bottom: props => props.vertical === 'bottom' && 0,
    left: props => props.horizontal === 'left' && 0,
    right: props => props.horizontal === 'right' && 0,
    transform: props => {
      switch (props.position) {
        case 'topLeft':
          return 'translate(-2px, -2px) rotate(0deg)'
        case 'topRight':
          return 'translate(2px, -2px) rotate(90deg)'
        case 'bottomRight':
          return 'translate(2px, 2px) rotate(180deg)'
        case 'bottomLeft':
          return 'translate(-2px, 2px) rotate(270deg)'
        default:
          return 'translate(-2px, -2px) rotate(0deg)'
      }
    },
    width: props => props.size || 30,
    height: props => props.size || 30,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 4,
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
    },
  },
}))
