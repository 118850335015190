import { useHistory } from 'react-router-dom'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import TakeawayImage from '@/assets/images/home/pickup.png'
import constants from '@/constants'

import DeliveryTypeButtonBase from './DeliveryTypeButtonBase'

const { TAKEAWAY } = constants.deliveryType

/**
 * 自取按鈕
 * 1. 點擊前往自取餐廳列表頁面
 * @param {*} props
 * @returns
 */
export default function TakeawayButton (props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(actions.app.updateDeliveryType(TAKEAWAY))
    history.push('/restaurants')
  }

  return (
    <DeliveryTypeButtonBase
      title='自取'
      subtitle='Takeaway'
      onClick={handleClick}
      imageProps={{
        src: TakeawayImage,
        alt: 'Takeaway',
        style: { height: '65%', right: -10 },
      }}
      buttonProps={{
        style: { backgroundColor: 'rgb(255 222 126)' },
      }}
    />
  )
}
