import { useDatetime } from '../app'

const DEFAULT_STALE_TIME = 3 * 60 * 1000 // 3 mins
const DEFAULT_REFETCH_INTERVAL = 1 * 60 * 1000 // 1 mins

/**
 *
 * @param {number} [ms = 180000]
 * @returns
 */
export function useRestaurantsStaleTime (ms) {
  const { isImmediate } = useDatetime()
  const value = ms ?? DEFAULT_STALE_TIME
  return isImmediate
    ? 0 // 即時的情況必須確保餐廳資料都是最即時的，所以抓到資料後就預設資料過期
    : value // 預約的情況都是固定選擇一個時段，所以抓到資料後就讓資料保持一段時間不重抓
}

/**
 *
 * @param {number} [ms = 60000]
 * @returns
 */
export function useRestaurantsRefetchInterval (ms) {
  const { isImmediate } = useDatetime()
  const value = ms ?? DEFAULT_REFETCH_INTERVAL
  return isImmediate
    ? value // 即時的情況必須確保餐廳資料都是最即時的，所以 interval 更新 cache
    : 0 // 預約的情況都是固定選擇一個時段，所以不需要 interval 更新
}
