import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import SaveButton from '@/pages/Settings/AddressSetting/SaveButton'
import constants from '@/constants'
import hooks from '@/hooks'
import theme from '@/theme'

import DeleteAddressButton from './DeleteAddressButton'
import DeliveryOptions from './DeliveryOptions'
import Input from './InputList/Input'
import InputList from './InputList'
import InputRemark from './InputList/Input/InputRemark'
/**
 *
 * @param {*} props
 * @returns
 */
export default function AddressEdit (props) {
  useOnStatusTapToPageTop()

  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const addressList = hooks.user.useAddressList()
  const addressTemp = hooks.user.useAddressTemp()
  const exitAddressEditBackLevel = useSelector(state => state.user.exitAddressEditBackLevel) ?? 1
  const isLogin = useIsLogin()
  const isCurrentAddress = addressTemp.id === 'CURRENT'
  const isNewAddress = !addressTemp.id
  const title = isNewAddress
    ? t('app.page.setting_address.add_address')
    : t('app.page.setting_address.edit_address')

  const handleBack = () => {
    dispatch(actions.user.updateExitAddressEditBackLevel(1))
    // 返回指定的層數
    history.go(-exitAddressEditBackLevel)
  }

  const handleUpdateRemark = (e) => {
    dispatch(actions.user.updateAddressTemp(['remark'], e.target.value))
  }

  const handleSave = () => {
    if (isCurrentAddress) {
      if (isLogin) {
        // 有登入可以詢問要不要儲存現在位置
        const alertConfig = {
          title: t('app.page.setting_address.save_address_alert.title'),
          message: t('app.page.setting_address.save_address_alert.message'),
          buttons: [
            {
              text: t('app.common.no'),
              onClick: () => {
                // 現在位置直接儲存到 user.address 並紀錄當前地址完成編輯頁面
                dispatch(actions.user.selectAddress({ ...addressTemp, isComplete: true }))
                handleBack()
              },
            }, {
              text: t('app.common.yes'),
              style: { backgroundColor: theme.palette.primary.main },
              onClick: () => {
                dispatch(actions.user.createAddress())
                handleBack()
              },
            },
          ],
        }
        dispatch(actions.app.toggleAlert(alertConfig))
        return
      }

      // 現在位置直接儲存到 user.address 並紀錄當前地址完成編輯頁面
      dispatch(actions.user.selectAddress({ ...addressTemp, isComplete: true }))
      history.goBack()
      return
    }

    if (isNewAddress) {
      dispatch(actions.user.createAddress())
    } else {
      dispatch(actions.user.updateAddress())
    }

    history.goBack()
  }

  const isValid = (addressTemp) => {
    const isNewAddress = !addressTemp.id
    const isNameDuplicated = Boolean(_.find(addressList, addr => addr.name === addressTemp.name))
    if (!addressTemp.address) return false
    if (!addressTemp.name) return false
    if (isNewAddress && isNameDuplicated) return false
    return true
  }

  return (
    <Page>
      <Navbar
        title={title}
        rightColumn={<DeleteAddressButton hidden={isNewAddress || isCurrentAddress} />}
        onBack={() => {
          dispatch(actions.user.resetAddressTemp())
          history.goBack()
        }}
      />
      <PageContainer className={classes.container}>
        <Input variant='address' />
        <InputList />
        <InputRemark value={addressTemp.remark} onChange={handleUpdateRemark} />
        <DeliveryOptions />
        <Input variant='name' />
        <SaveButton className={classes.saveButton} onClick={handleSave} disabled={!isValid(addressTemp)} />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    marginBottom: constants.app.marginBottom.GENERAL,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  saveButton: {
    marginBottom: constants.app.safeArea.bottom,
  },
}))
