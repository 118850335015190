import { useSelector } from '@/redux'

const DEFAULT_SPACING = {
  zh: undefined,
  en: undefined,
  jp: undefined,
}

/**
 * return letter spacing by current app language
 * @example
 * const letterSpacing = useLangLetterSpacing({ zh: '0.75rem' })
 * @param {{ zh?: number | string, en?: number | string, jp?: number | string }} spacing
 * @returns
 */
export function useLangLetterSpacing (spacing = DEFAULT_SPACING) {
  const lang = useSelector(state => state.app.lang)
  const key = lang.slice(0, 2)
  const letterSpacing = { ...DEFAULT_SPACING, ...spacing }
  return letterSpacing[key]
}
