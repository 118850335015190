import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useIsLogin } from '@/hooks/user'

/**
 *
 * @param {*} props
 * @returns
 */
export default function InviteButton (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const isLogin = useIsLogin()

  const onCilck = () => {
    setTimeout(() => {
      dispatch(actions.app.toggleDialog('invitation', true))
    }, 300)
  }

  if (!isLogin) return null
  return (
    <ButtonBase
      centerRipple
      classes={{ root: classes.button }}
      onClick={onCilck}
    >
      <img
        src={require('@/assets/images/invite/ricecoin.png').default}
        // src={require('@/assets/images/invite/icon.png').default}
        className={classes.logo}
      />
      <div className={classes.texts}>
        <Typography className={classes.title}>
          {t('app.page.member.invitation_button.title')}
        </Typography>
        <Typography className={classes.subtitle}>
          {t('app.page.member.invitation_button.message')}
        </Typography>
      </div>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    minHeight: 71,
    backgroundColor: '#ffeec5',
    display: 'flex',
    textAlign: 'start',
    padding: theme.spacing(1),
    margin: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
    flexShrink: 0,
  },
  texts: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  logo: {
    gridColumn: 1,
    gridRow: 'span 2',
    width: 55,
    objectFit: 'contain',
  },
  title: {
    gridColumn: 2,
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  subtitle: {
    gridColumn: 2,
    gridRow: 2,
    fontSize: '1.4rem',
  },
}))
