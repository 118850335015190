import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'

import LangOption from './LangOption'

/**
 * @param {{ lang: string }} props
 */
export default function ChangeLangDialog (props) {
  const langs = [
    { key: 'en-US', text: 'English' },
    { key: 'zh-HK', text: '中文-香港' },
    // { key: 'zh-TW', text: '中文-台灣' },
  ]
  const { lang } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.dialogs.changeLang.open)
  const handleClose = () => dispatch(actions.app.toggleDialog('changeLang'))

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paperRoot }}>
      {_.map(langs, (lng, index) => {
        const isSelected = lng.text === lang
        return <LangOption key={lng.key} lang={lng.key} text={lng.text} selected={isSelected} onClose={handleClose} />
      })}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  paperRoot: {
    '& li': {
      boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
    },
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
}))
