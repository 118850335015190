import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import useShortLang from '@/hooks/useShortLang'

import DialogBase from './DialogBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CouponClaimedDialog (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles(props)
  const history = useHistory()
  const open = useSelector(state => state.app.dialogs.couponClaimed?.open || false)
  const lang = useShortLang()

  const coupon = useSelector(state => state.app.dialogs.couponClaimed.data)

  if (open && !coupon) return null

  // render 時會判斷裡個都沒設定就都不顯示
  // 沒限制的話顯示成即日起 (today)
  const validFrom = coupon.validFrom
    ? moment(coupon.validFrom).format(t('app.common.date_time.format_year_date_time'))
    : t('app.component.alert.coupon_card.today')
  // 沒限制的話顯示成無期限 (no limit)
  const validUntil = coupon.validUntil
    ? moment(coupon.validUntil).format(t('app.common.date_time.format_year_date_time'))
    : t('app.component.alert.coupon_card.noLimit')

  const isValidDate = moment().isBetween(coupon.validFrom, coupon.validUntil, '[)')

  const handleClose = () => dispatch(actions.app.toggleDialog('couponClaimed', false, {}))
  const onTncButtonClick = () => dispatch(actions.app.toggleDialog('couponTnc', true, coupon))

  return (
    <DialogBase
      title={t('app.component.alert.coupon_claimed.title')}
      open={open}
      onClose={handleClose}
      renderContent={() => (
        <div className={classes.contain}>

          {coupon.name && (
            <div className={classes.row}>
              <Typography className='title'>
                {coupon.name[lang]}
              </Typography>
            </div>
          )}

          {(coupon.validFrom || coupon.validUntil) && (
            <div className={classes.row}>
              <Typography className='title'>
                {t('app.component.alert.coupon_card.expiration')}
              </Typography>
              <Typography className='desc'>
                {`${validFrom} ~ ${validUntil}`}
              </Typography>
            </div>
          )}

        </div>
      )}
      renderFooter={() => (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
        >
          <LetterSpacingButton
            text={t('app.component.alert.coupon_claimed.details')}
            variant='outlined'
            onClick={onTncButtonClick}
          />
          {isValidDate
            ? (
              <LetterSpacingButton
                text={t('app.component.alert.coupon_card.useNow')}
                onClick={() => {
                  handleClose()

                  if (coupon.deliveryType) {
                    // 有 deliveryType，選擇 deliveryType
                    dispatch(actions.app.updateDeliveryType(coupon.deliveryType))
                  }
                  if (coupon.action && coupon.action.type !== '') {
                    // 有 action 依照 action 處理
                    const { type, payload } = coupon.action
                    dispatch(actions.app.handleMarketingAction(type, payload))
                  } else if (coupon.merchantId) {
                    // 前往可用的餐廳
                    history.push(`/restaurant/${coupon.merchantId}`)
                  } else {
                    // 沒有 action 直接前往餐廳列表
                    history.push('/restaurants')
                  }
                }}
              />
            )
            : (
              <LetterSpacingButton
                text={t('app.common.confirm')}
                onClick={handleClose}
              />
            )}
        </div>
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  contain: {
  },
  row: {
    marginBottom: theme.spacing(1),

    '& > .title': {
      fontSize: 14,
      fontWeight: 'bold',
    },

    '& > .desc': {
      fontSize: 12,
    },
  },
}))
