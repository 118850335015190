import { ButtonBase, Popover, Typography } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import AlarmIcon from '@/assets/icons/alarm.png'
import LocationIcon from '@/assets/icons/location.png'
import constants from '@/constants'

/**
 *
 * @returns
 */
export default function CurrentLocationTooFarPopover (props) {
  const history = useHistory()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.popovers.currentLocationTooFar.open)
  const handleClose = () => dispatch(actions.app.togglePopover('currentLocationTooFar', false))

  return (
    <Popover
      open={open}
      anchorEl={props.anchorEl}
      classes={{ root: classes.popoverRoot, paper: classes.popoverPaper }}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorReference='anchorEl'
    >
      <ButtonBase
        className={classes.popoverContent}
        onClick={() => history.push('/settings/address')} // 轉頁到地址設定頁面，地址設定頁 useEffect 關閉 currentLocationTooFar popover
      >
        <Typography variant='body2' className={classes.text}>
          {t('app.component.popover.current_location_too_far.remind')}
        </Typography>
        <img src={AlarmIcon} alt='AlarmIcon' className={classes.icon} />
        <Typography variant='body2' className={classes.text}>
          {t('app.component.popover.current_location_too_far.message')}
        </Typography>
        <img src={LocationIcon} alt='LocationIcon' className={classes.icon} />
      </ButtonBase>
    </Popover>
  )
}

const useStyles = makeStyles(theme => ({
  popoverRoot: {
    // 避免 backdrop 覆蓋全頁導致不能滑動
    bottom: 'unset !important',
    '& > :first-child': {
      bottom: 'unset !important',
    },
  },
  popoverPaper: {
    overflow: 'visible', // 讓三角形能夠凸出去指向目標
    borderRadius: 8,
    transform: `translateX(-15%) translateY(${constants.app.height.NAVBAR}px) !important`,
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    color: theme.palette.common.white,
    backgroundColor: '#1a84db',
    borderRadius: 8,
    padding: theme.spacing(0.75, 1.5),
    cursor: 'pointer',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 12,
      height: 12,
      backgroundColor: '#1a84db',
      transform: 'translate(370%, -40%) rotate(45deg)',
    },
  },
  text: {
    flexShrink: 0,
  },
  icon: {
    height: 12,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))
