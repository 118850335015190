import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @typedef FeatureTagsProps
 * @property {string} tags
 * @param {FeatureTagsProps} props
 * @returns
 */
export default function FeatureTags (props) {
  const { tags } = props
  const classes = useStyles(props)

  if (!tags) return null
  return (
    <div className={classes.tagWrap}>
      <Typography variant='caption' className={classes.tag}>
        {tags}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  tagWrap: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  tag: {
    color: theme.palette.grey[500],
    wordBreak: 'keep-all',
  },
}))
