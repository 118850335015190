import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { isPoonChoiCategoryTag, isPoonChoiRestaurant } from '@/libs/poonchoi'
import { useCart } from '@/hooks/useCart'
import { useLoadings, useParams } from '@/hooks/app'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import { useMerchant } from '@/hooks/merchant'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DefaultMenuButton (props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const loadings = useLoadings()
  const merchant = useMerchant()
  const { hasItemsInCart } = useCart()
  const restaurant = useSelector(state => state.landing.restaurant)
  const menuQuery = useMenuQuery()
  const isMenuLoading = menuQuery.isLoading || loadings.createShipping
  const isPoonchoi = isPoonChoiCategoryTag(params.categoryTag)
  const isPoonchoiRestaurant = isPoonChoiRestaurant(restaurant?.tags)

  const handleSwitch = () => {
    // 更新 catagoryTag，並帶上這個 params 重抓 menu
    dispatch(actions.app.updateParams({ ...params, categoryTag: undefined }))
    dispatch(actions.orderBatch.resetBatch())
    dispatch(actions.order.createLocalOrder())
    dispatch(actions.menu.fetchCategories())
    dispatch(actions.app.openPreorderDrawer(merchant.id))
  }

  const handleClick = () => {
    if (hasItemsInCart) {
      // 購物車中有東西且 categoryTag 不同，需要詢問並清除才能繼續切換
      dispatch(actions.app.toggleAlert({
        title: t('app.component.alert.difference_menu_reset_batch.title'),
        message: t('app.component.alert.difference_menu_reset_batch.message'),
        onConfirm: () => handleSwitch(),
      }))
      return
    }

    handleSwitch()
  }

  if (!isPoonchoiRestaurant) return null // 餐廳沒有參加盆菜活動
  if (!isPoonchoi) return null // 目前不是盆菜菜單
  return (
    <ButtonBase
      className={classes.defaultMenuButton}
      onClick={handleClick}
      disabled={isMenuLoading}
    >
      <Typography>{t('app.component.default_menu_button')}</Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  defaultMenuButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    padding: theme.spacing(0.5),
    borderRadius: 5,
    '& p': {
      fontSize: '1.2rem',
    },
  },
}))
