import { BANNER, transformation } from '@/libs/cloudinary'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useMode } from '@/hooks/useWindowSize'
import TncButton from './TncButton'

// eslint-disable-next-line no-unused-vars
import { ILandingCategory } from '@/redux/landing/Landing.d'

/**
 *
 * 當 landing api 抓到的 category 有 banner 時會於餐廳列表上方顯示 banner
 * 單純顯示圖片，沒有 onClick 功能
 * 當有 link 時顯示 TncButton 擊後開啟 tnc 連結
 * @param {{
 * category: ILandingCategory
 * }} props
 * @returns
 */
export default function CategoryBanner (props) {
  const { category } = props
  const { bannerUrl, links } = category
  const classes = useStyles(props)
  const { isMobile } = useMode()

  if (!category) return null
  if (!bannerUrl) return null
  const imageUrl = transformation(bannerUrl, { width: isMobile ? BANNER.sm.width : BANNER.md.width })

  return (
    <div className={classes.bannerWrap}>
      <div className={classes.imageWrap} style={{ maxWidth: isMobile ? 360 : 640 }}>
        <img src={imageUrl} alt='category-banner' className={classes.bannerImage} />
        <TncButton link={links?.tnc} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  bannerWrap: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageWrap: {
    flexShrink: '0',
    position: 'relative',
    width: '100%',
  },
  bannerImage: {
    display: 'block',
    width: '100%',
    margin: '0 auto',
    borderRadius: theme.shape.borderRadius * 2,
  },
}))
