import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import BackButton from '@/components/Navbar/BackButton'
import constants from '@/constants'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CRMNavbar (props) {
  const classes = useStyles(props)
  return (
    <nav className={classes.navbar}>
      <BackButton variant='solid' />
    </nav>
  )
}

const useStyles = makeStyles(theme => ({
  navbar: {
    zIndex: constants.app.zIndex.NAVBAR,
    position: 'fixed',
    top: theme.spacing(1.5),
    left: theme.spacing(0.5),
    width: '100%',
    height: constants.app.marginTop.NAVBAR,
    padding: theme.spacing(1, 2),
    paddingTop: constants.app.safeArea.top,
  },
}))
