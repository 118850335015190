import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import constants from '@/constants'
import useTrack from '@/hooks/useTrack'

import ImageBanner from './ImageBanner'
import VideoBanner from './VideoBanner'

BannerItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  slider: PropTypes.object,
}

/**
 *
 * @param {{
 * item: IBannerItem
 * active: boolean
 * slider: object
 * }} props
 * @returns
 */
export default function BannerItem (props) {
  const { item, active, slider } = props
  const classes = useStyles({ ...props, item })
  const [mouseDownLocation, setMouseDownLocation] = useState({ x: 0, y: 0 })
  const { sendEvent } = useTrack()

  return (
    <Paper
      className={classes.bannerItemContainer}
      onMouseDown={e => {
        setMouseDownLocation({ x: e.pageX, y: e.pageY })
      }}
      onMouseUp={e => {
        if ((mouseDownLocation.x - e.pageX) + (mouseDownLocation.y - e.pageY) <= 30) {
          item.onClick?.()

          sendEvent(constants.track.EVENT.VIEW_PROMOTION, {
            type: item.info?.type,
            name: item.info?.name,
            slot: 'banner',
          })
        }
      }}
      onClick={() => {
        // 避免拖動時觸發 onClick，所以使用 onMouseUp 來觸發 click callback
      }}
      square
      elevation={0}
    >
      <ImageBanner item={item} />
      <VideoBanner item={item} active={active} slider={slider} />
    </Paper>
  )
}
const useStyles = makeStyles(theme => ({
  bannerItemContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    maxHeight: 400,
  },
}))
