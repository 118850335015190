import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import opacity from '@/theme/opacity'

/**
 * @param {{
 * isSold: boolean
 * }} props
 * @returns
 */
export default function SoldLayer (props) {
  const { isSold } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  if (!isSold) {
    return null
  }

  return (
    <div className={classes.layer}>
      <Typography className={classes.text}>{t('app.common.sold_out')}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  layer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      zIndex: 100,
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: `${theme.palette.grey[900]}${opacity[70]}`,
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
  text: {
    zIndex: 200,
    color: theme.palette.grey[200],
    fontSize: '2.2rem',
    fontWeight: 'bold',
  },
}))
