import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Scan (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#000' fillRule='nonzero'>
          <g>
            <path d='M4.75 12H6.5c.138 0 .25-.113.25-.25v-5.5h5.5c.137 0 .25-.112.25-.25V4.25c0-.138-.113-.25-.25-.25H6.625C5.45 4 4.5 4.95 4.5 6.125v5.625c0 .137.112.25.25.25zm16-5.75h5.5v5.5c0 .137.113.25.25.25h1.75c.137 0 .25-.113.25-.25V6.125C28.5 4.95 27.55 4 26.375 4H20.75c-.137 0-.25.112-.25.25V6c0 .138.113.25.25.25zm-8.5 19.5h-5.5v-5.5c0-.137-.112-.25-.25-.25H4.75c-.138 0-.25.113-.25.25v5.625C4.5 27.05 5.45 28 6.625 28h5.625c.137 0 .25-.113.25-.25V26c0-.137-.113-.25-.25-.25zm16-5.75H26.5c-.137 0-.25.113-.25.25v5.5h-5.5c-.137 0-.25.113-.25.25v1.75c0 .137.113.25.25.25h5.625c1.175 0 2.125-.95 2.125-2.125V20.25c0-.137-.113-.25-.25-.25zm.5-5.125H4.25c-.138 0-.25.113-.25.25v1.75c0 .137.112.25.25.25h24.5c.137 0 .25-.113.25-.25v-1.75c0-.137-.113-.25-.25-.25z' transform='translate(-401 -2574) translate(401 2574)' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
