import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import GroupContainer from '@/components/Groups/GroupContainer'
import hooks from '@/hooks'

import StepGroupHeader from './StepGroupHeader'
import StepItemList from './StepItemList'

// eslint-disable-next-line no-unused-vars
import { IAppSet, IAppSetStep } from '@/redux/menu/MenuState.d'

StepGroup.propTypes = {
  group: PropTypes.object,
  menu: PropTypes.object,
}

/**
 *
 * @typedef StepGroupProps
 * @property {IAppSetStep} group
 * @property {IAppSet} menu
 * @param {StepGroupProps}
 * @returns
 */
export default function StepGroup (props) {
  const { group: step, menu: setMenu } = props
  const setMenuItems = _.filter(setMenu?.menus, menu => menu.step === step.key)
  const dependentItemIds = !_.isEmpty(step.dependsOnSetMenuItems)
    ? step.dependsOnSetMenuItems
    : step.dependsOnSetMenuItem
      ? [step.dependsOnSetMenuItem]
      : []

  // 如果套餐步驟裡有設定『承接餐單』的話，該步驟只有在點了指定餐點後才會出現。
  // 例：
  // step1 『漢堡』 （經典漢堡、炸雞漢堡）
  // step2 『副餐』（炸物套餐、沙拉套餐、單點）
  // step3 『炸物』（薯條、可樂餅）  <dependsOnSetMenuItems: [炸物套餐]>
  // step4 『沙拉』（水果沙拉、雞肉沙拉） <dependsOnSetMenuItems: [沙拉套餐]>
  // step5 『沙拉配料』 (葡萄乾、堅果) <dependsOnSetMenuItems: [水果沙拉、雞肉沙拉]>
  // step6 『沙拉醬』 (凱薩醬、和風醬) <dependsOnSetMenuItems: [葡萄乾、堅果]>
  // step7 『炸物醬』 (番茄醬、糖醋醬) <dependsOnSetMenuItems: [薯條、可樂餅]>
  // step8 『飲料』（水、汽水）<dependsOnSetMenuItems: [炸物套餐、沙拉套餐]>
  // 例：經典漢堡 -> 炸物套餐 才會出現炸物和飲料步驟
  // 例：經典漢堡 -> 沙拉套餐 才會出現沙拉和飲料步驟
  // 例：經典漢堡 -> 炸物套餐 -> 薯條 才會出現炸物醬步驟
  // 例：經典漢堡 -> 沙拉套餐 -> 水果沙拉 才會出現沙拉配料步驟
  // 例：經典漢堡 -> 沙拉套餐 -> 水果沙拉 -> 葡萄乾 才會出現沙拉醬步驟

  if (!_.isEmpty(dependentItemIds)) {
    const { setItems } = hooks.batchItemTemp.useBatchItemTemp()
    const hasDependentItem = _.some(setItems, item => _.includes(dependentItemIds, item.setMenuId))
    if (!hasDependentItem) return null
  }

  return (
    <GroupContainer>
      <StepGroupHeader step={step} />
      <StepItemList step={step} setMenuItems={setMenuItems} />
    </GroupContainer>
  )
}
