import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

InputAddress.propTypes = {
  address: PropTypes.object,
}

/**
 *
 * @param {{
 * value: String
 * }} props
 * @returns
 */
export default function InputAddress (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const history = useHistory()

  const { address } = props
  const value = address?.address

  const handleClick = () => {
    history.push('/settings/address/map')
  }

  return (
    <ButtonBase
      className={clsx(classes.card)}
      onClick={handleClick}
    >
      <LocationOnIcon className={classes.icon} color='primary' />
      <div className={classes.textContainer}>
        {value && (
          <Typography className={classes.text} variant='subtitle2'>
            {value}
          </Typography>
        )}
        {value && !value.endsWith('香港') && (
          <Typography className={clsx(classes.text, classes.textSecondary)} variant='body2'>
            香港
          </Typography>
        )}
        {!value && (
          <Typography className={clsx(classes.text, classes.textSecondary)} variant='body2'>
            {t('app.page.setting_address.address.address.placeholder')}
          </Typography>
        )}
      </div>
      <EditIcon className={classes.icon} color='primary' />
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[25],
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  textContainer: {
    flex: 1,
    minWidth: 0,
    padding: theme.spacing(0, 1),
  },
  icon: {
    margin: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(1),
  },
  textSecondary: {
    color: theme.palette.grey[500],
  },
}))
