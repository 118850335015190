import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'

import StorageKey from '@/constants/storageKey'
import config from '@/config'
import enUS from '@/i18n/locales/en-US'
import ja from '@/i18n/locales/ja'
import zhHK from '@/i18n/locales/zh-HK'
import zhTW from '@/i18n/locales/zh-TW'

// restore from local storage
let i18nextLng = localStorage.getItem(StorageKey.LANG)
if (!i18nextLng) {
  // if local storage not config
  i18nextLng = 'zh-HK'
  localStorage.setItem(StorageKey.LANG, i18nextLng)
}

const resources = {
  'en-US': {
    translation: enUS,
  },
  'zh-HK': {
    translation: zhHK,
  },
  'zh-TW': {
    translation: zhTW,
  },
  ja: {
    translation: ja,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: i18nextLng, // 預設語言
    fallbackLng: i18nextLng, // 如果當前切換的語言沒有對應的翻譯則使用這個語言，
    interpolation: {
      escapeValue: false, // 如果語系檔中有巢狀的結構，則 escapeValue 要設為 false，這樣就可以透過物件的方式來取得巢狀內的翻譯
    },
  },
  async () => {
    if (config.env !== 'test') {
      ['en-US', 'zh-TW', 'zh-HK', 'ja'].forEach((lng) => {
        const S3_BUCKET = 'i18n-file-production'
        const S3_REGION = 's3.ap-east-1'

        fetch(`https://${S3_BUCKET}.${S3_REGION}.amazonaws.com/${lng}.json?time=${Date.now()}`, {
          mode: 'cors',
        })
          .then((data) => data.json())
          .then((data) =>
            i18n.addResourceBundle(lng, 'translation', data, true, true),
          )
          .catch((error) => {
            console.log(`fetch s3 lang file (${lng}) error.`, error.toString())
          })
      })
    }
  })

export default i18n
