import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { useGroupQuery } from '@/hooks/queries/CRM/useGroupQuery'

import { SafeAreaPage } from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

import { GroupImage, GroupName } from '../HomePage'
import CRMNavbar from '../components/Navbar'
import Card from '../components/Card'
import LedgerList from './LedgerList'
import SectionHeader from '../components/SectionHeader'

/* eslint-disable no-unused-vars */
import { GroupUserPoint } from 'dimorder-orderapp-lib/dist/types/CRM'
/* eslint-enable no-unused-vars */

export const CARD = {
  height: 96,
  colors: {
    income: '#25d69d',
    expense: '#ed631f',
  },
}

export default function CRMPointsPage (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <SafeAreaPage>
      <CRMNavbar />
      <PageContainer>
        <GroupImage height={100} />
        <GroupName />
        <GroupPointsCard />
        <SectionHeader title={t('app.page.crm.points_history')} />
        <Card className={classes.ledgerListCard}>
          <LedgerList />
        </Card>
      </PageContainer>
    </SafeAreaPage>
  )
}

function GroupPointsCard (props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(props)
  const { data: group, isLoading } = useGroupQuery()
  function Points (props) {
    if (isLoading) return <Skeleton variant='text' width={80} style={{ fontSize: '1.6rem' }} />
    return <Typography variant='subtitle1'>{group.points} {t('app.page.crm.crm_points')}</Typography>
  }
  return (
    <Card className={classes.pointsCard}>
      <Typography variant='subtitle1' style={{ fontWeight: theme.typography.fontWeightBold }}>{t('app.page.crm.crm_available_points')}:</Typography>
      <Points />
    </Card>
  )
}

/**
 *
 * @param {{ item: GroupUserPoint }} props
 * @returns
 */
export function LedgerCard (props) {
  const { item: ledger } = props
  const { points, orderSerial, effectiveAt, expiredAt } = ledger
  const { t } = useTranslation()
  const classes = useStyles(props)
  const isExpense = points < 0

  return (
    <div className={classes.ledgerCard}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Typography variant='caption' className={classes.key}>{t('app.common.order_serial')}</Typography>
          <Typography variant='caption' className={classes.value}>{orderSerial}</Typography>
        </div>
        <Typography variant='caption' className={classes.key}>{t('app.page.crm.crm_points')}</Typography>
        <Typography variant='caption' className={classes.value} style={{ color: isExpense ? CARD.colors.expense : CARD.colors.income }}>{points}</Typography>
      </div>
      <div>
        <Typography variant='caption' className={classes.key}>{t('app.common.date_time.effective_at')}</Typography>
        <Typography variant='caption' className={classes.value}>{formatTime(effectiveAt)}</Typography>
      </div>
      <div>
        <Typography variant='caption' className={classes.key}>{t('app.common.date_time.expired_at')}</Typography>
        <Typography variant='caption' className={classes.value}>{formatTime(expiredAt)}</Typography>
      </div>
    </div>
  )
}

/**
 *
 * @param {string | null} timestamp
 * @returns
 */
function formatTime (timestamp) {
  return timestamp ? moment(timestamp).format('DD/MM/YYYY hh:mm') : '-'
}

const useStyles = makeStyles(theme => ({
  pointsCard: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ledgerListCard: {
    flex: 1,
    margin: theme.spacing(0, 1, 1, 1),
  },
  ledgerCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    height: CARD.height,
    borderBottom: '1px solid #D1D1D1',
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0, 1),
  },
  key: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(2),
  },
  value: {
    minWidth: 24,
    textAlign: 'center',
  },
}))
