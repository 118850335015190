import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import _ from 'lodash'

export default function NoSearchResult (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const filter = useSelector(state => state.landing.filter)
  const searchText = useSelector(state => state.landing.searchText)

  const values = useMemo(() => {
    const searchValues = _.chain(searchText).split(' ').without('').value()
    let values = _.flatMap(filter)
    if (searchText) {
      values = _.concat(_.flatMap(filter), [...searchValues])
    }
    return values
  }, [filter, searchText])

  return (
    <div className={classes.noResult}>
      <Typography variant='h6'>{t('app.page.search.no_result', { searchText: _.join(values, ',  ') })}</Typography>
      <Typography>{t('app.page.search.no_result_helper')}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  noResult: {
    textAlign: 'center',
  },
}))
