const EVENT = {
  TEST_EVENT: 'TEST_EVENT',
  //
  ADD_TO_CART: 'ADD_TO_CART',
  ADD_PAYMENT_INFO: 'ADD_PAYMENT_INFO',
  BEGIN_CHECKOUT: 'BEGIN_CHECKOUT',
  PURCHASE: 'PURCHASE',
  SEARCH: 'SEARCH',
  VIEW_ITEM: 'VIEW_ITEM',
  VIEW_PROMOTION: 'VIEW_PROMOTION',
  SIGN_UP: 'SIGN_UP',
  LOGIN: 'LOGIN',
}

const formatEvent = (eventName, param = {}, defaultParam) => {
  let ga = {}
  let fbWeb = {}
  let fbAndroid = {}
  let fbIos = {}

  const { item, merchant, items, order } = param

  switch (eventName) {
    case EVENT.TEST_EVENT:
      ga = {
        eventName: 'test_event',
        param: {
          ...defaultParam,
        },
      }
      fbWeb = {
        eventName: 'TestEvent',
        param: {
          ...defaultParam,
        },
      }
      fbAndroid = {
        eventName: 'TEST_EVENT',
        param: {
          ...defaultParam,
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameTestEvent',
        param: {
          ...defaultParam,
        },
      }
      break
    case EVENT.ADD_TO_CART:
      ga = {
        eventName: 'add_to_cart',
        param: {
          ...defaultParam,
          currency: 'HKD',
          value: item?.price,
          items: [
            {
              item_id: item?.key,
              item_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: 0,
              item_brand: merchant?.name,
              price: item.price,
              quantity: item.quantity,
            },
            ...item.setItems.map((item, idx) => {
              return {
                item_id: item?.key,
                item_name: item?.name,
                currency: 'HKD',
                discount: item.discount,
                index: idx + 1,
                item_brand: merchant?.name,
                price: item.price,
                quantity: item.quantity,
              }
            }),
          ],
        },
      }
      fbWeb = {
        eventName: 'AddToCart',
        param: {
          ...defaultParam,
          contents: [
            {
              content_ids: item?.key, // 食物編號
              content_name: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            },
            ...item.setItems.map((item, idx) => {
              return {
                content_ids: item?.key, // 食物編號
                content_name: item?.name, // 食物名稱
                currency: 'HKD',
                price: item.price,
              }
            }),
          ],
          restaurant_name: merchant?.name, // 餐廳名稱,
          restaurant_id: merchant?.id, // 餐廳編號,
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_ADDED_TO_CART',
        param: {
          ...defaultParam,
          contents: [
            {
              content_ids: item?.key, // 食物編號
              content_name: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            },
            ...item.setItems.map(item => {
              return {
                content_ids: item?.key, // 食物編號
                content_name: item?.name, // 食物名稱
                currency: 'HKD',
                price: item.price,
              }
            }),
          ],
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameAddedToCart',
        param: {
          ...defaultParam,
          contents: [
            {
              ContentID: item?.key, // 食物編號
              contentName: item?.name, // 食物名稱
              Description: item?.desc,
              currency: 'HKD',
              valueToSum: item.price,
            },
            ...item.setItems.map(item => {
              return {
                ContentID: item?.key, // 食物編號
                contentName: item?.name, // 食物名稱
                Description: item?.desc,
                currency: 'HKD',
                valueToSum: item.price,
              }
            }),
          ],
        },
      }
      break
    case EVENT.ADD_PAYMENT_INFO:
      ga = {
        eventName: 'add_payment_info',
        param: {
          ...defaultParam,
          SUCCESS: true,
        },
      }
      fbWeb = {
        eventName: 'AddPaymentInfo',
        param: {
          ...defaultParam,
          SUCCESS: true,
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_ADDED_PAYMENT_INFO',
        param: {
          ...defaultParam,
          SUCCESS: true,
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameAddedPaymentInfo',
        param: {
          ...defaultParam,
          SUCCESS: true,
        },
      }
      break
    case EVENT.BEGIN_CHECKOUT:
      ga = {
        eventName: 'begin_checkout',
        param: {
          ...defaultParam,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          value: order?.total,
          items: items?.map((item, idx) => {
            return {
              item_id: item?.key,
              item_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: idx + 1,
              item_brand: merchant?.name,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      }
      fbWeb = {
        eventName: 'InitiateCheckout',
        param: {
          ...defaultParam,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          value: order?.total,
          contents: items?.map((item) => {
            return {
              content_ids: item?.key, // 食物編號
              content_name: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            }
          }),
        },
      }
      fbAndroid = {
        eventName: 'logInitiatedCheckoutEvent',
        param: {
          ...defaultParam,
          Currency: 'HKD',
          Coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          valueToSum: order?.total,
          Content: items?.map((item) => {
            return {
              ContentID: item?.key, // 食物編號
              contentName: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            }
          }),
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameInitiatedCheckout',
        param: {
          ...defaultParam,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          valueToSum: order?.total,
          content: items?.map((item) => {
            return {
              content_ids: item?.key, // 食物編號
              content_name: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            }
          }),
        },
      }
      break
    case EVENT.PURCHASE:
      ga = {
        eventName: 'purchase',
        param: {
          ...defaultParam,
          transaction_id: order?.id,
          value: order?.total,
          shipping: order?.shippingFee,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          items: order?.items?.map((item, idx) => {
            return {
              item_id: item?.key,
              item_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: idx + 1,
              item_brand: order?.merchantName,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      }
      fbWeb = {
        eventName: 'Purchase',
        param: {
          ...defaultParam,
          transaction_id: order?.id,
          value: order?.total,
          shipping: order?.shippingFee,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          contents: order?.items?.map((item, idx) => {
            return {
              content_ids: item?.key,
              content_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: idx + 1,
              item_brand: order?.merchantName,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      }
      fbAndroid = {
        eventName: 'logPurchase',
        param: {
          ...defaultParam,
          transaction_id: order?.id,
          value: order?.total,
          shipping: order?.shippingFee,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          contents: order?.items?.map((item, idx) => {
            return {
              content_ids: item?.key,
              content_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: idx + 1,
              item_brand: order?.merchantName,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      }
      fbIos = {
        eventName: 'logPurchase',
        param: {
          ...defaultParam,
          transaction_id: order?.id,
          value: order?.total,
          shipping: order?.shippingFee,
          currency: 'HKD',
          coupon: order?.modifiers.find(v => v.type === 'PROMOCODE')?.name,
          contents: order?.items?.map((item, idx) => {
            return {
              content_ids: item?.key,
              content_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: idx + 1,
              item_brand: order?.merchantName,
              price: item.price,
              quantity: item.quantity,
            }
          }),
        },
      }
      break
    case EVENT.SEARCH:
      ga = {
        eventName: 'search',
        param: {
          ...defaultParam,
          search_term: param.value, // 搜尋字詞
        },
      }
      fbWeb = {
        eventName: 'Search',
        param: {
          ...defaultParam,
          search_string: param.value, // 搜尋字詞
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_SEARCHED',
        param: {
          ...defaultParam,
          SEARCH_STRING: param.value, // 搜尋字詞
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameSearched',
        param: {
          ...defaultParam,
          SearchString: param.value, // 搜尋字詞
        },
      }
      break
    case EVENT.VIEW_ITEM:
      ga = {
        eventName: 'view_item',
        param: {
          ...defaultParam,
          currency: 'HKD',
          value: item?.price,
          items: [
            {
              item_id: item?.id,
              item_name: item?.name,
              currency: 'HKD',
              discount: item.discount,
              index: 0,
              item_brand: merchant?.name,
              price: item.price,
              quantity: item.quantity,
            },
            // ...item.setItems.map((item, idx) => {
            //   return {
            //     item_id: item?.key,
            //     item_name: item?.name,
            //     currency: 'HKD',
            //     discount: item.discount,
            //     index: idx + 1,
            //     item_brand: merchant?.name,
            //     price: item.price,
            //     quantity: item.quantity,
            //   }
            // }),
          ],
        },
      }
      fbWeb = {
        eventName: 'ViewContent',
        param: {
          ...defaultParam,
          contents: [
            {
              content_ids: item?.id, // 食物編號
              content_name: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            },
            // ...item.setItems.map((item, idx) => {
            //   return {
            //     content_ids: item?.key, // 食物編號
            //     content_name: item?.name, // 食物名稱
            //     currency: 'HKD',
            //     price: item.price,
            //   }
            // }),
          ],
          restaurant_name: merchant?.name, // 餐廳名稱,
          restaurant_id: merchant?.id, // 餐廳編號,
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_VIEWED_CONTENT',
        param: {
          ...defaultParam,
          contents: [
            {
              content_ids: item?.id, // 食物編號
              content_name: item?.name, // 食物名稱
              currency: 'HKD',
              price: item.price,
            },
            // ...item.setItems.map(item => {
            //   return {
            //     content_ids: item?.key, // 食物編號
            //     content_name: item?.name, // 食物名稱
            //     currency: 'HKD',
            //     price: item.price,
            //   }
            // }),
          ],
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameViewedContent',
        param: {
          ...defaultParam,
          contents: [
            {
              ContentID: item?.id, // 食物編號
              contentName: item?.name, // 食物名稱
              Description: item?.desc,
              currency: 'HKD',
              valueToSum: item.price,
            },
            // ...item.setItems.map(item => {
            //   return {
            //     ContentID: item?.key, // 食物編號
            //     contentName: item?.name, // 食物名稱
            //     Description: item?.desc,
            //     currency: 'HKD',
            //     valueToSum: item.price,
            //   }
            // }),
          ],
        },
      }
      break
    case EVENT.VIEW_PROMOTION:
      ga = {
        eventName: 'view_promotion',
        param: {
          ...defaultParam,
          creative_name: param.name, // 廣告名稱
          creative_slot: param.slot, // 廣告出現位置
          // location_id: '', // 廣告出現位置編號
          // promotion_id: '', // 推廣活動編號
          // promotion_name: '', // 推廣活動名稱
          // item_id: '', // 食物編號
          // item_name: '', // 食物名稱
          // affiliation: '', // 合作平台(如有)
          currency: 'HKD', // HKD
          // item_brand: '', // 餐廳名稱
          // item_category: '', // 菜式分類
          // item_category2: '', // 餐廳地區
          // item_category3: '', // 外送/堂食/自選
          // item_variant: '', // 特製選項
          // quantity: '', // 數量
        },
      }
      fbWeb = {
        eventName: 'BannerClick',
        param: {
          ...defaultParam,
          creative_name: param.name, // 廣告名稱
          creative_slot: param.slot, //  廣告出現位置
          // location_id: '', //  廣告出現位置編號
          // promotion_id: '', //  推廣活動編號
          // promotion_name: '', // 推廣活動名稱
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_AD_CLICK',
        param: {
          ...defaultParam,
          AD_TYPE: param.type,
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameAdClick',
        param: {
          ...defaultParam,
          AdType: param.type,
        },
      }
      break
    case EVENT.SIGN_UP:
      ga = {
        eventName: 'sign_up',
        param: {
          ...defaultParam,
          method: param.method,
        },
      }
      fbWeb = {
        eventName: 'CompleteRegistration',
        param: {
          ...defaultParam,
          method: param.method,
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_COMPLETED_REGISTRATION',
        param: {
          ...defaultParam,
          REGISTRATION_METHOD: param.method,
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameCompletedRegistration',
        param: {
          ...defaultParam,
          RegistrationMethod: param.method,
        },
      }
      break
    case EVENT.LOGIN:
      ga = {
        eventName: 'login',
        param: {
          ...defaultParam,
          method: param.method,
        },
      }
      fbWeb = {
        eventName: 'LogIn',
        param: {
          ...defaultParam,
          method: param.method,
        },
      }
      fbAndroid = {
        eventName: 'EVENT_NAME_LOG_IN',
        param: {
          ...defaultParam,
          REGISTRATION_METHOD: param.method,
        },
      }
      fbIos = {
        eventName: 'FBSDKAppEventNameLogIn',
        param: {
          ...defaultParam,
          RegistrationMethod: param.method,
        },
      }
      break
    default:
      ga = { eventName, param }
      fbWeb = { eventName, param }
      fbAndroid = { eventName, param }
      fbIos = { eventName, param }
      break
  }

  return { ga, fbWeb, fbAndroid, fbIos }
}

export default {
  gaCode: process.env.NODE_ENV === 'development'
    ? 'G-7JGSBNKF8K'
    : 'G-2XJVS3WX8Q',
  fbPixelCode: '236495218159647',
  APPSFLYER_KEY: 'Ys4WfSZrVT75eh9oY4t9vg',
  IOS_APP_ID: '1558146057',
  //
  EVENT,
  formatEvent,
}
