import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import React, { useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import hooks from '@/hooks'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'
import userAgentDetection from '@/libs/userAgentDetection'

import DineinButtonBase from './DineinButtonBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DineinButtonCheckout (props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const table = hooks.app.useTable()
  const merchant = useSelector(state => state.merchant?.data)
  const orderBatches = useSelector(state => state.order?.selectedOrder?.batches)
  const orderItems = useSelector(state => state.order?.selectedOrder?.batches?.map(v => v.items).flat(1) ?? [])
  const [isRequestServiceSuccess, setIsRequestServiceSuccess] = useState(false)
  const handleDineinCheckout = useDineinCheckoutHandler({ setSuccess: setIsRequestServiceSuccess })

  if (!merchant?.id) return null
  if (merchant?.setting?.payFirst) return null
  if (merchant?.setting?.dineinHidePayButton) return null

  return (
    <DineinButtonBase
      title={isRequestServiceSuccess
        ? t('app.page.restaurant.dinein_button.checkout.waiting_pay')
        : t('app.page.restaurant.dinein_button.checkout.title')}
      icon={<LocalAtmIcon />}
      backgroundColor={theme.palette.success.main}
      disabled={(!table || _.isEmpty(orderBatches)) || orderItems.some(v => v.priceUndetermined)}
      onClick={handleDineinCheckout}
    />
  )
}

/**
 *
 * @typedef CheckoutCallbackOptions
 * @property {() => void} [setSuccess = () => {}]
 * @property {'pushWithSearch' | 'replaceWithSearch'} [redirectMethod = 'pushWithSearch']
 * @param {CheckoutCallbackOptions} options
 * @returns
 */
export function useDineinCheckoutHandler (options = {}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const merchant = useSelector(state => state.merchant?.data)
  const batches = useSelector(state => state.order.selectedOrder?.batches) ?? []
  const isPayfirst = merchant?.setting?.payFirst
  const hasBatch = batches.length > 0
  const onConfirm = useDineinCheckoutCallback(options)

  return () => {
    if (!hasBatch) return // 沒有 batch 不可結帳
    const lastSubmittedBatch = _.chain(batches).sortBy(batch => batch.submittedAt).last().value()
    const POP_ALERT_DURATION = 5 * 60 * 1000 // 5 mins
    const isWithinDuration = moment().isBetween(moment(lastSubmittedBatch.submittedAt), moment(lastSubmittedBatch.submittedAt).add(POP_ALERT_DURATION, 'ms'))
    if (!isPayfirst && isWithinDuration) { // 後付款且於最後的 batch 提交的 5 分鐘之內，點擊顯示 alert
      dispatch(actions.app.toggleAlert({
        message: t('app.component.alert.dinein_checkout.message'),
        buttons: [
          { text: t('app.component.alert.dinein_checkout.cancel') },
          { text: t('app.component.alert.dinein_checkout.confirm'), onClick: onConfirm, style: { backgroundColor: theme.palette.primary.main } },
        ],
      }))
    } else {
      onConfirm()
    }
  }
}

/**
 *
 * @typedef CheckoutCallbackOptions
 * @property {() => void} [setSuccess = () => {}]
 * @property {'pushWithSearch' | 'replaceWithSearch'} [redirectMethod = 'pushWithSearch']
 * @param {CheckoutCallbackOptions} options
 * @returns
 */
export function useDineinCheckoutCallback (options = {}) {
  const { t } = useTranslation()
  const merchant = useSelector(state => state.merchant?.data)
  const history = useHistory()
  const dispatch = useDispatch()
  const d2cBaseUrl = useD2CBaseUrl()

  function CheckoutAlertHeader (props) {
    const classes = useStyles(props)
    return (
      <header className={classes.alertHeader}>
        <LocalAtmIcon className={classes.alertImage} />
      </header>
    )
  }

  return async () => {
    const { setSuccess = () => {}, redirectMethod = 'pushWithSearch' } = options

    // 若餐廳設定限用 APP 結帳會開啟 APP
    const shouldRedirectToApp = dispatch(actions.app.checkDineInPayOnAppOnlyAndRedirect())
    if (shouldRedirectToApp) return

    if (merchant?.setting?.enableDineInCustomerPay) {
    // * DimPay 自助付款
    // 使用DimPay 的餐廳無法用WeChat/Alipay 的In-App Browser 進行付款
      if (userAgentDetection.isInApp()) {
        dispatch(actions.app.toggleAlert({
          title: t('app.component.alert.checkout_not_support_in_app.title'),
          message: t('app.component.alert.checkout_not_support_in_app.message'),
          dialogProps: { disableBackdropClick: true },
          buttons: [{ text: t('app.common.confirm') }],
        }))
      } else {
      // 店家允許客人自己用線上付款，按了現在結帳直接前往 /pay 頁面
        const url = `${d2cBaseUrl}/pay`
        if (redirectMethod === 'pushWithSearch') history.pushWithSearch(url)
        if (redirectMethod === 'replaceWithSearch') history.replaceWithSearch(url)
      }
    } else {
      // * 店內收銀台付款
      await dispatch(actions.order.requestService('pay'))
      setSuccess(true)
      dispatch(actions.app.toggleAlert({
        title: t('app.page.restaurant.dinein_button.checkout.alert.title'),
        message: t('app.page.restaurant.dinein_button.checkout.alert.message'),
        button: { text: t('app.common.confirm') },
        header: CheckoutAlertHeader,
      }))
    }
  }
}

const useStyles = makeStyles(theme => ({
  alertHeader: {
    display: 'grid',
    placeItems: 'center',
    minHeight: 140,
    marginTop: theme.spacing(3),
  },
  alertImage: {
    fontSize: '10rem',
    color: theme.palette.secondary.light,
  },
}))
