import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { checkIsSold } from '@/libs/menu'
import { useDeliveryType } from '@/hooks/app'
import { useMerchant } from '@/hooks/merchant'
import constants from '@/constants'

// import InventoryLabel from '@/components/InventoryLabel'
import { getMerchants } from '@/constants/merchant'
import MenuStepper from '@/components/Stepper/MenuStepper'
import PriceLabel from '@/components/PriceLabel'

import MenuItemImage from './MenuItemImage'
import SoldLayer from './SoldLayer'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 * props 有傳 setItemMenu 的情況是套餐被指定步驟（預設第一步）拆開的情況
 * 當有 setItemMenu 時應顯示該 setItem 的 name, desc, image, inventory(sold)
 *
 * @param {{
 * menu: IAppMenuItem | IAppSet
 * setItemMenu?: IAppMenuItem,
 * }} props
 */
export default function MenuItem (props) {
  const { menu, setItemMenu } = props
  const _menu = setItemMenu ?? menu
  const name = _menu?.name?.replace(/\u00A0/g, ' ') // production 8c80423a-e540-4d64-b1b3-4b1962db63eb 餐廳的設定餐點名稱內含有 U+00a0 (&nbsp;) 會造成破版，取代為空格
  const merchant = useMerchant()
  const classes = useStyles(props)
  const isSold = checkIsSold({ merchant, menu, setItemMenu })
  const isSet = _.size(_menu.steps) > 0
  const { isTable } = useDeliveryType()
  const stepperRef = React.useRef()

  const priceLabel = React.useMemo(() => {
    if (setItemMenu) {
      // 分拆的套餐
      const priceTotal = (menu.price ?? 0) + (setItemMenu.price ?? 0)
      const discountTotal = (menu.discount ?? 0) + (setItemMenu.discount ?? 0)
      return (
        <PriceLabel
          menu={_menu}
          price={priceTotal}
          discount={discountTotal}
          isSeparated // 顯示前綴：套餐
          suffix // 顯示後綴：起
        />
      )
    }

    if (isSet) {
      // 套餐（分拆的步驟為複選時不可被分拆）
      // 複選的情況下該步驟不包含選項的最小總額
      const firstStep = _menu.steps[0]
      const firstStepMenus = _.filter(_menu.menus, menu => menu.step === firstStep?.id)
      const sorted = _
        .chain(firstStepMenus)
        .map(menu => ({ ...menu, total: (menu.price ?? 0) - (menu.discount ?? 0) }))
        .orderBy(['required', 'total'], ['desc', 'asc']) // 將折扣後的價格從小排到大，必選的排在最前面
        .value()
      const items = []
      for (const menu of sorted) {
        if (items.length >= (firstStep.min || 1)) {
          // 當已達步驟最少需選數量時
          break
        }
        const max = menu.max || firstStep.max || constants.app.stepper.MAX
        _.times(max, () => {
          if (!(items.length >= (firstStep.min || 1))) {
            // 當未達步驟最少需選數量時
            items.push({ price: menu.price ?? 0, discount: menu.discount ?? 0 })
          }
        })
      }
      // 計算第一步最少需點多少元起
      const priceTotal = (menu.price ?? 0) + _.sumBy(items, 'price')
      const discountTotal = (menu.discount ?? 0) + _.sumBy(items, 'discount')

      return (
        <PriceLabel
          menu={_menu}
          price={priceTotal}
          discount={discountTotal}
          suffix// 顯示後綴：起
        />
      )
    }

    return <PriceLabel menu={_menu} />
  }, [menu, setItemMenu])

  if (!isTable && menu.takeawayDisabled) {
    // 隱藏不提供外賣的餐點
    return null
  }

  return (
    <li className={classes.menuItem}>
      <SoldLayer sold={isSold} />
      <MenuItemImage menu={_menu} sold={isSold} />
      <section
        className={classes.content} onClick={() => {
          if (!getMerchants('hotpotland').includes(merchant.id)) {
            stepperRef.current.click()
          }
        }}
      >
        <div className={classes.nameDescWrap}>
          {/* for debug */}
          {/* <Typography className={classes.name}>{_menu.id}</Typography> */}
          <Typography className={classes.name}>{name}</Typography>
          <Typography variant='caption' className={classes.desc}>{_menu?.desc}</Typography>
        </div>
        {priceLabel}
        {/* <InventoryLabel menu={_menu} /> */}
      </section>
      <MenuStepper menu={menu} setItemMenu={setItemMenu} sold={isSold} innerRef={stepperRef} />
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  menuItem: {
    cursor: 'pointer',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '4fr 7fr minmax(40px, 1fr)',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: `0 0 4px ${theme.palette.grey[300]}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  nameDescWrap: {
    marginBottom: theme.spacing(1),
  },
  name: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2, /* number of lines to show */
    '-webkit-box-orient': 'vertical',
  },
  desc: {
    color: theme.palette.grey[600],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2, /* number of lines to show */
    '-webkit-box-orient': 'vertical',
  },
  bold: {
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
  },
  set: {
    marginRight: theme.spacing(0.5),
  },
}))
