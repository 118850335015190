import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import useDatetimeText from '@/hooks/useDatetimeText'

/**
 *
 * @param {{
 * deliveryType: TDeliveryType
 * }} props
 * @returns
 */
export default function DatetimeText (props) {
  const { deliveryType } = props
  const { text, style } = useDatetimeText(deliveryType)
  const classes = useStyles(props)
  if (!text) return null

  if (style === 'COLOR_NUMBER') {
    const numberRegex = /\d+/g
    const characters = [...text]
    return _.map(characters, (char, index) => {
    // 把數字拿出來特別style
      if (!_.isEmpty(char.match(numberRegex))) {
        return (
          <Typography
            key={index}
            color='textSecondary'
            component='span'
            style={{
              marginRight: !_.isEmpty(characters[index + 1]?.match(numberRegex)) ? 0 : 4,
              marginLeft: !_.isEmpty(characters[index - 1]?.match(numberRegex)) ? 0 : 4,
            }}
          >{char}
          </Typography>
        )
      }
      if (char === ' ') return <Typography key={index} color='textPrimary' component='span'>&nbsp;</Typography>
      return <Typography key={index} color='textPrimary' component='span'>{char}</Typography>
    })
  }

  if (style === 'DISABLED') return <Typography component='span' className={classes.disabledText}>{text}</Typography>

  return <Typography color='textPrimary' component='span'>{text}</Typography>
}

const useStyles = makeStyles(theme => ({
  disabledText: {
    color: theme.palette.grey[500],
  },
}))
