import { Element } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import CategoryItem from './CategoryItem'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

CategoryList.propTypes = {
  list: PropTypes.array,
  isLoading: PropTypes.bool,
}

/**
 *
 * @param {{
 * list: IAppCategory[]
 * isLoading: boolean
 * }} props
 */
export default function CategoryList (props) {
  const { list: categories, isLoading } = props
  const classes = useStyles(props)
  if (_.isEmpty(categories)) return null
  return (
    <ul className={classes.categoryList}>
      {_.map(categories, (category, index) => {
        if (isLoading) return null
        return (
          <li key={index}>
            <Element name={category.id}>
              <CategoryItem item={category} />
            </Element>
          </li>
        )
      })}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  categoryList: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    listStyle: 'none',
    margin: 0,
  },
}))
