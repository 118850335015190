import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import Corners from '@/components/Corners'
import Navbar from '@/components/Navbar'

// import FlashlightFab from './FlashlightFab'

/**
 *
 * @param {*} props
 * @returns
 */
export default function QRCodeScanning (props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const closeDrawer = () => dispatch(actions.app.closeQRCodeScanner())
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Navbar color='white' background='transparent' onBack={closeDrawer} />
      <div className={classes.scannerOverlay}>
        <Corners />
        <section className={classes.desc}>
          <Typography>
            {t('app.page.qr_code_scanning.title')}
          </Typography>
          <Typography>
            {t('app.page.qr_code_scanning.subtitle')}
          </Typography>
        </section>
      </div>
      {/* <FlashlightFab /> */}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 200,
  },
  scannerOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'clamp(200px, 65%, 300px)',
    paddingBottom: 'clamp(200px, 65%, 300px)', // same as width to keep 1:1 aspect ratio
    outlineWidth: '100vh',
    outlineStyle: 'solid',
    outlineColor: 'rgba(0, 0, 0, 0.5)',
  },
  desc: {
    position: 'absolute',
    bottom: '-25%',
    left: 0,
    width: '100%',
    '& p': {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
  },
}))
