import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import constants from '@/constants'
import userAgentDetection from '@/libs/userAgentDetection'

import { useCategories } from '@/hooks/queries/menu/useMenuQuery'
import { useDeliveryType, useLoadings, useParams } from '@/hooks/app'
import { useIsLogin } from '@/hooks/user'
import { useMerchant } from '@/hooks/merchant'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import { usePayingRedirectOrderTracking } from '@/hooks/orderHistory'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

import BackButton from '@/components/Navbar/BackButton'
import DatetimeColumn from '@/components/Navbar/DatetimeColumn'
import InfoButton from '@/pages/Menu/MerchantCard/InfoButton'
import LaMaMenu from '@/pages/CustomizedRestaurants/LaMaMenu'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import RestaurantCoverImage from '@/components/RestaurantCoverImage'
import RestaurantFooter from '@/components/Footers/RestaurantFooter'
import ShareButton from '@/components/Navbar/ShareButton'
import SkeletonMenuCategory from '@/components/Skeletons/SkeletonMenuCategory'

import CategoryBar from './CategoryBar'
import CategoryList from './CategoryList'
import DefaultMenuButton from './PoonchoiMenuButtons/DefaultMenuButton'
import DimpayBanner from './DimpayBanner'
import DineinButtons from './DineinButtons'
import MerchantCard from './MerchantCard'
import NoticeCardList from './NoticeCardList'
import PoonChoiMenuButton from './PoonchoiMenuButtons/PoonChoiMenuButton'

/**
 * @param {*} props
 * @returns
 */
export default function Menu (props) {
  useOnStatusTapToPageTop()

  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const loadings = useLoadings()
  const merchant = useMerchant()
  const { data: categories, isLoading } = useCategories()
  const isLogin = useIsLogin()
  const d2cBaseUrl = useD2CBaseUrl()
  const { deliveryType, isStoreDelivery, isTable } = useDeliveryType()
  const params = useParams()

  const isAppInit = useSelector(state => state.app.isInit)
  const sticky = useSelector(state => state.app.isCategoryBarSticky)
  const fetchRestaurantsParams = useSelector(state => state.landing.fetchRestaurantsParams)
  const restaurant = useSelector(state => state.landing.restaurant)
  const orderShipping = useSelector(state => state.order.shipping)
  const address = useSelector(state => state.user.address)
  const payFirst = useSelector(state => state.merchant?.data?.setting?.payFirst)

  // 檢查CA能不能付款，不能的話跳轉到 order tracking
  usePayingRedirectOrderTracking()

  const classes = useStyles({ ...props, sticky })
  const coverImageRef = useRef()

  const shippingTime = orderShipping?.shippingTime
  const canShip = _.isNumber(shippingTime)
  const isSameRestaurantData = (!fetchRestaurantsParams.merchantId || fetchRestaurantsParams.merchantId === merchant?.id) &&
  fetchRestaurantsParams.deliveryType === deliveryType &&
  fetchRestaurantsParams.latlng?.lat.toFixed(6) === address?.latlng?.lat.toFixed(6)

  const [scrollOffset, setScrollOffset] = React.useState(0)

  // detect browser, then disable menu page
  useEffect(() => {
    // 堂食時 先付款的餐廳不能用WeChat 或 Alipay 點餐
    if (isTable && userAgentDetection.isInApp() && payFirst) {
      dispatch(actions.app.toggleAlert({
        title: t('app.component.alert.payment_not_support_in_app.title'),
        message: t('app.component.alert.payment_not_support_in_app.message'),
        dialogProps: { disableBackdropClick: true },
        buttons: [],
      }))
    }
  }, [payFirst])

  const handleBack = () => {
    if (!params.isD2CWeb && isTable && params.table != null && params.table !== '') {
      // 平台模式內用且有桌號，先詢問再退出
      dispatch(actions.app.toggleAlert({
        title: t('app.page.restaurant.table_leave_alert.title'),
        message: t('app.page.restaurant.table_leave_alert.message', { restaurant: merchant.name }),
        buttons: [
          {
            text: t('app.page.restaurant.table_leave_alert.ok_button'),
            onClick: () => {
              dispatch(actions.app.exitDineIn())
              history.goBack()
            },
          },
          {
            text: t('app.page.restaurant.table_leave_alert.cancel_button'),
            style: { backgroundColor: theme.palette.primary.main },
            onClick: () => {},
          },
        ],
      }))
      return
    }

    history.goBack()
  }

  const handlePageScroll = (e) => {
    const coverImageEle = coverImageRef.current
    const navBackButtonEle = document.getElementById('navBackButton')
    if (!navBackButtonEle) return // back button 可能會被隱藏 navBackButtonEle 不存在
    const navBackButtonOpacity = window.getComputedStyle(navBackButtonEle, null).opacity
    const scrollTop = e.target.scrollTop
    const offsetHeight = coverImageEle.offsetHeight
    const ratio = Math.min((scrollTop / offsetHeight), 1)

    // ios can scroll over the top with cover image scale up effect
    if (scrollTop <= 0) {
      coverImageEle.style.transform = `scale(${1.01 - (ratio * 0.5)}) translateY(-1%)`
      coverImageEle.style.opacity = 1
      coverImageEle.style.filter = 'blur(0px)'
      navBackButtonEle.style.visibility = 'visible'
      navBackButtonEle.style.opacity = 1
    }

    // scroll down
    if (scrollTop > 0) {
      // cover image scroll down visual effect
      if (ratio < 1) {
        coverImageEle.style.transform = `scale(${1.01 + (ratio * 0.05)}) translateY(-1%)`
        coverImageEle.style.opacity = 1 - (ratio * 0.5)
        coverImageEle.style.filter = `blur(${12 * (ratio * 0.5)}px)`

        // hide the navbar back button to avoid it cover the merchant card component when scrolling the page down
        navBackButtonEle.style.opacity = Math.max(1 - (ratio * 2), 0)
        if (navBackButtonOpacity > 0) {
          navBackButtonEle.style.visibility = 'visible'
        } else {
          navBackButtonEle.style.visibility = 'hidden'
        }
      }

      if (sticky) {
        navBackButtonEle.style.opacity = 1
        navBackButtonEle.style.visibility = 'visible'
      }
    }
  }

  const handleAddressSelect = () => {
    if (isLogin) {
      // 有登入，去地址選擇頁面
      dispatch(actions.user.updateExitAddressEditBackLevel(2))
      history.pushWithSearch(d2cBaseUrl + '/settings/address')
    } else {
      // 沒登入，快速選地址
      dispatch(actions.user.createAddressTemp('CURRENT'))
      history.pushWithSearch(d2cBaseUrl + '/settings/address/map')
    }
  }

  const rightColumn = () => {
    return (
      <>
        <InfoButton hidden={!sticky} onClick={() => dispatch(actions.app.toggleDialog('merchantDetail'))} />
        <ShareButton sticky={sticky} />
      </>
    )
  }

  useEffect(() => {
    // usePreorderEffect
    // 餐廳模式專用（平台模式在 RestaurantList 處理地址、Restaurant 處理 preorder）
    if (!params.isD2CWeb) return
    if (loadings.landing) return
    if (loadings.restaurantInit) return
    if (loadings.createShipping) return
    if (loadings.d2cRouterSettingUpdate) return
    if (!isSameRestaurantData) return

    if (isStoreDelivery) {
      if (!address?.address) {
        // 沒有地址，直接前往選擇地址
        handleAddressSelect()
        return
      }

      if (!canShip) {
        // 地址無法使用，提示後前往選擇地址
        dispatch(actions.app.toggleAlert({
          title: t('app.component.footer.content.unable_to_deliver'),
          message: t('app.page.setting_address.please_select_address'),
          buttons: [{
            text: t('app.common.back'),
            onClick: () => {
              history.goBack()
            },
          }, {
            text: t('app.common.continue'),
            style: { backgroundColor: theme.palette.primary.main },
            onClick: handleAddressSelect,
          }],
        }))
        return
      }
    }

    // dispatch openPreorderDrawer，會判斷需不需要開啟
    dispatch(actions.app.openPreorderDrawer(merchant.id))
  }, [
    params.isD2CWeb,
    loadings,
    deliveryType,
    address?.address,
    canShip,
    restaurant,
    orderShipping,
  ])

  // 顯示客製化餐廳 Menu UI
  const customerLayout = merchant.setting?.customerLayout
  if (params.isD2CWeb && customerLayout === 'LAMA') {
    return <LaMaMenu isLoading={!isAppInit || isLoading} />
  }

  return (
    <Page>
      <Navbar
        onBack={handleBack}
        backButton={!sticky && <BackButton variant='solid' onBack={handleBack} />}
        sticky={sticky}
        leftColumn={<DatetimeColumn hidden={!sticky} />}
        rightColumn={rightColumn()}
      />
      <PageContainer className={classes.container} onScroll={handlePageScroll}>
        <section className={classes.merchantCardWrap}>
          <RestaurantCoverImage innerRef={coverImageRef} />
          <MerchantCard />
        </section>
        <NoticeCardList />
        <DineinButtons />
        <DimpayBanner />
        <PoonChoiMenuButton />
        <CategoryBar scrollOffset={scrollOffset} />
        <section
          className={classes.categoryListWrap}
          id='category-list-wrap'
          ref={(element) => {
            if (element && !scrollOffset) {
              // 只有第一次（還沒有scrollOffset）時設定，因為捲動後 boundingClientRect.y 就會改變，就不是我們要的高度
              const boundingClientRect = element.getBoundingClientRect()
              setScrollOffset(boundingClientRect.y)
            }
          }}
        >
          <DefaultMenuButton />
          <SkeletonMenuCategory disabled={!isLoading} />
          <CategoryList list={categories} isLoading={isLoading} />
          <div style={{ height: window.innerHeight - scrollOffset }} />
        </section>
      </PageContainer>
      <RestaurantFooter slide />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'scroll',
    padding: 0,
  },
  merchantCardWrap: {
    position: 'relative',
    paddingTop: constants.app.marginTop.NAVBAR,
    paddingBottom: props => props.sticky && constants.app.height.CATEGORYBAR, // 撐開 absolute 的 CategoryBar 高度
    marginBottom: `calc(48px - ${constants.app.safeArea.top})`, // 讓卡片與 Navbar 的間距在有 safeArea 的情況下與沒有 safeArea 的情況一致
    transition: 'padding 300ms ease',
    [theme.breakpoints.up('lg')]: {
      marginTop: 256,
    },
  },
  categoryListWrap: {
    position: 'relative',
    padding: theme.spacing(1.5, 1.5),
    paddingBottom: '85px',
    backgroundColor: theme.palette.grey[50],

    [theme.breakpoints.down('xs')]: {
      borderRadius: undefined,
      margin: undefined,
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 12,
      margin: theme.spacing(0, 1.5),
    },
  },
}))
