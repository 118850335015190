import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useRef } from 'react'

import { useCuisineRestaurantsQuery, useCuisineRestaurantsQueryOptions } from '@/hooks/queries/useCuisineRestaurantsQuery'
import { useRestaurantCardListSize } from '@/hooks/useRestaurantCardListSize'

import InfiniteQueryList from '@/components/RestaurantList/InfiniteQueryList'
import RestaurantCard from '@/components/Cards/RestaurantCard'
import RestaurantCardsSkeleton from '@/components/RestaurantList/RestaurantCardsSkeleton'

export default function CuisineRestaurantList (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const history = useHistory()
  const payload = history.location.state?.cuisine?.payload
  const { rowHeight, columnWidth } = useRestaurantCardListSize()
  const queryOptions = useCuisineRestaurantsQueryOptions(payload)
  const { data } = useCuisineRestaurantsQuery(payload)
  const meta = data?.pages?.[0]?.meta

  function HeaderComponent () {
    const countsRef = useRef()
    const cuisineCount = `${meta?.total} ${meta?.total > 1
    ? t('app.common.restaurants')
    : t('app.common.restaurant')}`

    return (
      <div>
        <div className={classes.image} style={{ backgroundImage: `url(${meta?.bannerUrl})` }} />
        <div ref={countsRef} className={classes.counts}>
          <Typography variant='h5'>{meta?.name}</Typography>
          <Typography variant='caption' className={classes.subtitle}>
            {cuisineCount}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <InfiniteQueryList
      queryOptions={queryOptions}
      rowHeight={rowHeight}
      columnWidth={columnWidth}
      ItemComponent={RestaurantCard}
      HeaderComponent={HeaderComponent}
      HeaderComponentHeight={300}
      FooterComponent={() => <div />} // 多顯示一行空白的 row
      LoadingComponent={RestaurantCardsSkeleton}
      LoadingIndicator={RestaurantCardsSkeleton}
    />
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 265,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  counts: {
    position: 'absolute',
    right: 12,
    bottom: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    minWidth: 120,
    minHeight: 60,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[25],
  },
  subtitle: {
    color: theme.palette.grey[700],
  },
}))
