import { scroller } from 'react-scroll'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

export const DEFAULT_SCROLL_DURATION = 200
export const DEFAULT_SCROLL_DELAY = 0
export const DEFAULT_SCROLL_OFFSET = 0
export const DEFAULT_SCROLL_SMOOTH = true

/**
 * @typedef ScrollToOptions
 * @property {number} [duration = 200]
 * @property {number} [delay = 0]
 * @property {number} [offset = 0]
 * @property {number} [smooth = true]
 */

/**
 *
 * @param {string} containerId
 * @param {ScrollToOptions} [options = {}]
 */
export function scrollToTop (containerId, options = {}) {
  const container = document.getElementById(containerId)

  const isTop = container.scrollTop <= 0
  if (isTop) return null

  //  force-stop momentum scrolling on ios by setting container overflow to hidden
  container.style.overflowY = 'hidden'

  setTimeout(() => {
    container.style.overflowY = 'scroll'
    scroller.scrollTo(`${containerId}--top`, {
      containerId: containerId,
      duration: options.duration ?? DEFAULT_SCROLL_DURATION,
      delay: options.delay ?? DEFAULT_SCROLL_DELAY,
      offset: options.offset ?? DEFAULT_SCROLL_OFFSET,
      smooth: options.smooth ?? DEFAULT_SCROLL_SMOOTH,
    })
  }, 10)
}

/**
 *
 * @param {IAppCategory} category
 * @param {string} containerId
 * @param {ScrollToOptions} [options = {}]
 */
export function scrollToCategory (category, containerId, options = {}) {
  const container = document.getElementById(containerId)

  //  force-stop momentum scrolling on ios by setting container overflow to hidden
  container.style.overflowY = 'hidden'

  setTimeout(() => {
    container.style.overflowY = 'scroll'
    scroller.scrollTo(category.id, {
      containerId: containerId,
      duration: options.duration ?? DEFAULT_SCROLL_DURATION,
      delay: options.delay ?? DEFAULT_SCROLL_DELAY,
      offset: options.offset ?? DEFAULT_SCROLL_OFFSET,
      smooth: options.smooth ?? DEFAULT_SCROLL_SMOOTH,
    })
  }, 10)
}
