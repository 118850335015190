import React from 'react'

import CRMRewardsDrawer from './CRMRewardsDrawer'
import CartDrawer from './CartDrawer'
import DatetimeDrawer from './DatetimeDrawer'
import LoginDrawer from './LoginDrawer'
import MenuItemDrawer from './MenuItemDrawer'
import PaymentHelpDrawer from './PaymentHelpDrawer'
import PreorderDrawer from './PreorderDrawer'
import QrReaderDrawer from './QrReaderDrawer'
import ScheduleDatetimeDrawer from './DatetimeDrawer/ScheduleDatetimeDrawer'
import ScheduleDrawer from './ScheduleDrawer'
import SetItemDrawer from './SetItemDrawer'
// import OrderTrackingDrawer from './OrderTrackingDrawer'

export default function Drawers (props) {
  return (
    <>
      <CartDrawer />
      <DatetimeDrawer />
      <MenuItemDrawer />
      <SetItemDrawer />
      <CRMRewardsDrawer />
      <PaymentHelpDrawer />
      <QrReaderDrawer />
      <LoginDrawer />
      <ScheduleDrawer />
      <ScheduleDatetimeDrawer />
      <PreorderDrawer />
      {/* <OrderTrackingDrawer /> */}
    </>
  )
}
