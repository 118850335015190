import { useDeliveryType } from '../app'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@/redux'
import _ from 'lodash'
import customerApi from '@/libs/api/customer'

export function useFavoritesQueryOptions () {
  const userId = useSelector(state => state.user.member?.id)
  const enabled = !!userId
  const queryKey = ['user', 'favorites', userId]
  const queryFn = () => fetchFn()
  async function fetchFn () {
    const { data } = await customerApi.fetchFavorites()
    return data
  }
  const staleTime = 3 * 60 * 1000 // 3 mins 收藏的餐廳不應會太頻繁的改變，當 mutate 時會 invalid 這個 queryKey 重抓資料
  return { queryKey, queryFn, staleTime, enabled }
}

/**
 * 用戶收藏表
 * @returns
 */
export function useFavoritesQuery () {
  const options = useFavoritesQueryOptions()
  const query = useQuery(options)
  return query
}

/**
 *
 * @param {string} merchantId
 * @returns
 */
export function useIsFavorite (merchantId) {
  const { deliveryType } = useDeliveryType()
  const { data } = useFavoritesQuery()
  const favorites = data ?? []
  const checkIsFavorite = (merchantId) => _.some(favorites, v => v.merchant_id === String(merchantId) && v.delivery_type === deliveryType)
  const isFavorite = checkIsFavorite(merchantId)
  return { isFavorite, checkIsFavorite }
}

export function useFavoriteMutation (handleSuccess) {
  const { deliveryType } = useDeliveryType()
  const { checkIsFavorite } = useIsFavorite()
  const mutationFn = async (merchantId) => {
    const isFavorite = checkIsFavorite(merchantId)
    const addFavorite = async (merchantId) => customerApi.addFavorites(merchantId, deliveryType)
    const deleteFavorite = async (merchantId) => customerApi.deleteFavorites(merchantId, deliveryType)
    return isFavorite ? deleteFavorite(merchantId) : addFavorite(merchantId)
  }
  const queryClient = useQueryClient()
  const { queryKey } = useFavoritesQueryOptions()
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey })
    queryClient.invalidateQueries({ queryKey: ['landing', 'favorites'] })
    handleSuccess()
  }
  const mutation = useMutation({ mutationFn, onSuccess })
  return mutation
}
