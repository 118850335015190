import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'

import FilterButton from '@/components/Appbar/buttons/FilterButton'
import SearchInput from '@/components/Search/SearchInput'
import constants from '@/constants'

export default function Searchbar (props) {
  const history = useHistory()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const searchText = useSelector(state => state.landing.searchText)
  const filter = useSelector(state => state.landing.filter)
  const hasSelectedFilter = _.some(filter, arr => arr.length > 0)

  return (
    <AppBar position='fixed' elevation={0} className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          onClick={() => {
            if (searchText || hasSelectedFilter) {
              dispatch(actions.landing.resetSearchInput())
              dispatch(actions.landing.resetFilter())
            } else {
              history.goBack()
            }
          }}
        >
          <ArrowBackIosIcon fontSize='small' />
        </IconButton>
        <SearchInput />
        <FilterButton onClick={() =>
          history.push({
            pathname: '/filter',
            state: { prevPath: history.location.pathname },
          })}
        />
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles(theme => ({
  appbar: {
    paddingTop: constants.app.safeArea.top,
    background: `linear-gradient(45deg, ${theme.palette.primary.customDark} 0%, ${theme.palette.primary.customLight} 100%)`,
  },
  toolbar: {
    justifyContent: 'space-between',
    height: constants.app.height.APPBAR,
  },
}))
