import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import _ from 'lodash'

/* eslint-disable no-unused-vars */
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
import { IUserState } from '@/redux/user/UserState.d'
/* eslint-enable no-unused-vars */

/**
 * @param {google.maps.GeocoderRequest} request
 * @returns {Promise<google.maps.GeocoderResult[]>}
 */
export function findPlacesByLatLng (request) {
  const geocoder = new window.google.maps.Geocoder()
  const { OK } = window.google.maps.GeocoderStatus

  return new Promise((resolve, reject) => {
    geocoder.geocode(request, (results, status) => {
      if (status !== OK) {
        return reject(status)
      }

      return resolve(results)
    })
  })
}

export function getDirectionResult (params) {
  const {
    origin,
    destination,
    travelMode = 'DRIVING',
    transitOptions,
    drivingOptions,
    unitSystem,
    waypoints,
    optimizeWaypoints,
    provideRouteAlternatives,
    avoidFerries = true,
    avoidHighways = false,
    avoidTolls = true,
    region,
  } = params
  const directionsService = new window.google.maps.DirectionsService()
  const { OK } = window.google.maps.DirectionsStatus

  const request = {
    origin,
    destination,
    travelMode,
    transitOptions,
    drivingOptions,
    unitSystem,
    waypoints,
    optimizeWaypoints,
    provideRouteAlternatives,
    avoidFerries,
    avoidHighways,
    avoidTolls,
    region,
  }

  return new Promise((resolve, reject) => {
    directionsService.route(request, (results, status) => {
      if (status !== OK) {
        return reject(new Error(status))
      }
      return resolve(results)
    })
  })
}

export function findCountry (geocodeResult) {
  const country = geocodeResult.address_components.find(a => a.types.includes('country'))
  return country ? country.short_name : 'HK'
}

/**
 * @returns {ILatLng}
 */
export async function getOriginLatLng (location, address) {
  const lat = location?.[1]
  const lng = location?.[0]
  const hasLatLng = lat != null && lng !== null

  // convert merchant adress to lat lng.
  if (address && !hasLatLng) {
    const geocodeResult = await geocodeByAddress(address)
    const latLng = await getLatLng(geocodeResult[0])
    return latLng
  }
  return { lat, lng }
}

/**
 * seconds
 * @param {IMerchant} merchant
 * @param {IUserState} user
 * @returns {number}
 */
export async function getShippingDuration (merchant, user) {
  if (_.isEmpty(merchant) || _.isEmpty(user)) return 0
  const origin = await getOriginLatLng(merchant?.setting?.location, merchant?.address)
  const destination = user.address.latlng
  const directionResult = await getDirectionResult({ origin, destination })
  const duration = directionResult.routes[0].legs[0].duration // e.g. { text: "14 分鐘", value: 827 }
  return duration.value
}

/**
 * @param {google.maps.GeocoderResult} geocoderResult
 */
export function getCountryInGeocodeResult (geocoderResult) {
  const countryComponent = geocoderResult.address_components.find((addressComponent) => {
    return addressComponent.types[0] === 'country'
  })

  return countryComponent?.short_name
}

/**
 * @param {IMerchant} merchant
 */
export function openMap (merchant) {
  const { address, setting } = merchant
  const { location, mapLink } = setting
  const latlng = { lat: location[1], lng: location[0] }
  const baseUrl = 'https://maps.google.com/?q='
  const latlngUrl = baseUrl + `${latlng.lat},${latlng.lng}`
  const addressUrl = baseUrl + address
  window.open(mapLink || latlngUrl || addressUrl)
}

export default {
  findPlacesByLatLng,
  getDirectionResult,
  findCountry,
  getOriginLatLng,
  getShippingDuration,
  getCountryInGeocodeResult,
  openMap,
}
