import moment from 'moment'

const debugMode = localStorage.getItem('DEBUG_MODE')

const timers = {}
const option = {
  disabled: !debugMode,
}

function time (label) {
  if (option.disabled) return
  try {
    timers[label] = moment()
  } catch (error) {
    console.debug(`[timer][${label}] start timer error`)
  }
}

function timeEnd (label) {
  if (option.disabled) return

  try {
    const seconds = moment.duration(moment() - timers[label]).asSeconds()
    console.debug(`[timer][${label}]:`, seconds, 's')
  } catch (error) {
    console.debug(`[timer][${label}] end timer error`)
  }
}

export default {
  option,
  time,
  timeEnd,
}
