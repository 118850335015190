import { combineReducers } from 'redux'

import app from './app/reducer'
import batchItemTemp from './batchItemTemp/reducer'
import dimbox from './dimbox/reducer'
import landing from './landing/reducer'
import merchant from './merchant/reducer'
import order from './order/reducer'
import orderBatch from './orderBatch/reducer'
import orderHistory from './orderHistory/reducer'
import payment from './payment/reducer'
import snackbar from './snackbar/reducer'
import user from './user/reducer'

export default combineReducers({
  app,
  user,
  merchant,
  landing,
  order,
  orderBatch,
  orderHistory,
  batchItemTemp,
  payment,
  snackbar,
  dimbox,
})
