import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import React, { useEffect, useState } from 'react'

import { useIsLogin } from '@/hooks/user'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import { usePayingRedirectOrderTracking, useSelectedOrderHistory } from '@/hooks/orderHistory'
import { useSelector } from '@/redux'
import constants from '@/constants'
import customerApi from '@/libs/api/customer'

import { useDineinCheckoutHandler } from '@/pages/Menu/DineinButtons/DineinButton/DineinButtonCheckout'
import DineinButtonBase from '@/pages/Menu/DineinButtons/DineinButton/DineinButtonBase'
import Navbar from '@/components/Navbar'
import OrderHistoryItem from './OrderHistoryItem'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

/**
 * @typedef CheckoutButtonProps
 * @property {boolean} [disabled = false]
 * @param {CheckoutButtonProps} props
 * @returns
 */
function CheckoutButton (props) {
  const { disabled = false } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(props)
  const merchant = useSelector(state => state.merchant?.data)
  const handleDineinCheckout = useDineinCheckoutHandler({ redirectMethod: 'replaceWithSearch' }) // 用以直接返回菜單頁面

  if (!merchant?.setting?.enableDineInCustomerPay) return null

  return (
    <DineinButtonBase
      title={t('app.page.restaurant.dinein_button.checkout.title')}
      icon={<LocalAtmIcon />}
      className={classes.fullWidth}
      backgroundColor={theme.palette.success.main}
      disabled={disabled}
      onClick={handleDineinCheckout}
      style={{ minWidth: '100%' }}
    />
  )
}

export default function OrderHistory (props) {
  useOnStatusTapToPageTop()

  const classes = useStyles(props)
  const { t } = useTranslation()
  const order = useSelectedOrderHistory()
  const history = useHistory()
  const [reward, setReward] = useState(0)
  const isLogin = useIsLogin()

  // 檢查CA能不能付款，不能的話跳轉到 order tracking
  usePayingRedirectOrderTracking()

  useEffect(() => {
    const fetchLedger = async () => {
      if (isLogin) {
        const response = await customerApi.getLedgers({ orderId: order.id })
        const reward = response.data?.reduce((a, c) => c.amount > 0 ? a + c.amount : a, 0)
        setReward(reward)
      }
    }

    if (order?.id) {
      fetchLedger()
    } else {
      // 沒有選擇訂單，回到 /orders
      history.goBack()
    }
  }, [isLogin, order?.id])

  return (
    <Page>
      <Navbar title={t('app.page.order_history.navbar.title')} />
      <PageContainer className={classes.container}>
        <OrderHistoryItem order={order} reward={reward} />
        <div>
          <CheckoutButton />
        </div>
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    marginBottom: constants.app.marginBottom.GENERAL,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  fullWidth: {
    width: '100%',
  },
}))
