import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

SubmitButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @typedef SubmitButtonProps
 * @property {string} [title]
 * @property {boolean} [disabled]
 * @property {boolean} [loading]
 * @property {() => void} [onClick]
 */

/**
 *
 * @param {SubmitButtonProps} props
 * @returns
 */
export default function SubmitButton (props) {
  const {
    title,
    disabled = false,
    loading = false,
    onClick,
  } = props

  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <Button
      disabled={disabled}
      variant='contained'
      color='primary'
      classes={{ label: classes.label }}
      className={clsx(classes.submitButton, { [classes.disabled]: disabled })}
      disableElevation
      onClick={onClick}
    >
      <Typography>
        {title ?? t('app.common.confirm_submit')}
      </Typography>

      {loading && (
        <div className={classes.loading}>
          <CircularProgress color='inherit' size={16} />
        </div>
      )}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  submitButton: {
    borderRadius: 10,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
  },
  disabled: {
    opacity: 0.5,
  },
  label: {
    flexWrap: 'wrap',
  },
}))
