import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import useDisableD2CUIPrice from '@/hooks/useDisableD2CUIPrice'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItemOption } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef OptionsProps
 * @property {IAppBatchItemOption[]} options
 */

/**
 *
 * @param {OptionsProps} props
 * @returns
 */
export default function Options (props) {
  const { options = [] } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const disableD2CUIPrice = useDisableD2CUIPrice()

  const renderPrice = React.useCallback(
    /**
     *
     * @param {IAppBatchItemOption} option
     * @returns
     */
    (option) => {
      if (option?.price) {
        if (disableD2CUIPrice) return null
        return (
          <Typography
            className={clsx(classes.optText, classes.price)}
            component='span'
            variant='caption'
          >
            {`(+$${option.price})`}
          </Typography>
        )
      }
    }, [disableD2CUIPrice],
  )

  const renderQuantity = React.useCallback(
    /**
     *
     * @param {IAppBatchItemOption} option
     * @returns
     */
    (option) => {
      if (option?.quantity > 1) {
        return (
          <Typography
            className={clsx(classes.optText, classes.price)}
            component='span'
            variant='caption'
          >
            x {option.quantity}
          </Typography>
        )
      }
    }, [])

  // FIXME 在 orderTracking 消失
  if (_.isEmpty(options) || _.isEmpty(options[0])) return null
  const itemOptions = Array.isArray(_.get(options, [0])) ? options[0] : options

  return (
    <Box className={classes.optionsContainer}>
      {!_.isEmpty(itemOptions) && (
        <Typography className={classes.optionTitle}>
          {t('app.component.order_batch_item.options')}
        </Typography>
      )}
      <ul className={classes.option}>
        {
          _.map(itemOptions, (option, index) => {
            return (
              <li key={index} className={classes.opt}>
                <Typography
                  className={classes.optText}
                  component='span'
                  variant='caption'
                >
                  {option.name}
                </Typography>
                {renderPrice(option)}
                {renderQuantity(option)}
              </li>
            )
          })
        }
      </ul>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  optionsContainer: {
    margin: theme.spacing(1, 0),
  },
  optionTitle: {
    fontSize: '1.4rem',
    fontStyle: 'italic',
  },
  option: {
    display: 'grid',
    gridGap: theme.spacing(0.5),
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
    padding: theme.spacing(1),
    color: theme.palette.info.main,
  },
  opt: {
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  optionText: {
    wordBreak: 'break-word',
  },
  price: {
    color: theme.palette.grey[500],
  },
}))
