import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import StepperBase from '@/components/Stepper/StepperBase'
import constants from '@/constants'
import hooks from '@/hooks'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionGroup, IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'
/**
 *
 * @param {{
 * option: IMenuOptionItem
 * optionGroup: IMenuOptionGroup
 * disableIncrement: boolean
 * disableDecrement: boolean
 * }} props
 * @returns
 */
export default function OptionStepper (props) {
  const { option, optionGroup, disableIncrement, disableDecrement } = props
  const dispatch = useDispatch()
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const batchSetItemTemp = hooks.batchItemTemp.useBatchSetItemTemp()
  const temp = _.isEmpty(batchSetItemTemp) ? batchItemTemp : batchSetItemTemp
  const tempOption = _.find(temp.options, opt => opt.optionItemId === option.id)
  const isSet = Boolean(temp.step)
  const quantity = tempOption?.quantity ?? 0
  const max = option.max || optionGroup.max || constants.app.stepper.MAX

  const handleIncrement = () => {
    dispatch(actions.batchItemTemp.updateOptions(option, optionGroup, isSet, tempOption, 1))
  }
  const handleDecrement = () => {
    dispatch(actions.batchItemTemp.updateOptions(option, optionGroup, isSet, tempOption, -1))
  }

  return (
    <StepperBase
      max={max}
      quantity={quantity}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
      disableIncrement={disableIncrement}
      disableDecrement={disableDecrement}
    />
  )
}
