import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { DEFAULT_PER_PAGE } from '@/hooks/queries/params'
import { useMemberId } from '@/hooks/user'
import dimorderApi from '@/libs/api/dimorder'

/**
 * 用於 CRM 積分紀錄頁面的捲動載入
 * @param {string} [groupId]
 * @param {number} [perPage = 25]
 * @returns
 */
export function useGroupLedgersQueryOptions (groupId, perPage = DEFAULT_PER_PAGE) {
  const params = useParams()
  const memberId = useMemberId()
  const id = groupId || params.groupId
  const enabled = !!id
  const queryKey = ['crm', 'ledgers', `groupId:${id}`, `memberId:${memberId}`]
  const queryFn = ({ pageParam: next }) => fetchFn(next)
  async function fetchFn (next) {
    const pageData = await dimorderApi.crm.getGroupLedgers(id, { after: next, limit: perPage })
    const meta = { perPage, next: pageData?.next }
    return {
      meta,
      data: pageData?.data ?? [],
      perPage,
      next: pageData?.next,
    }
  }
  return { queryKey, queryFn, enabled }
}

/**
 *
 * @param {string} [groupId]
 * @returns
 */
export function useGroupLedgersQuery (groupId) {
  const options = useGroupLedgersQueryOptions(groupId)
  const query = useQuery(options)
  return query
}
