import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import { useMerchant } from '@/hooks/merchant'
import useAcceptedPaymentMethods from '@/hooks/useAcceptedPaymentMethods'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

import { createApplePaySession } from '@/libs/payments'
import AppConstants from '@/constants/app'
import Merchant from '@/constants/merchant'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethodCard from '@/components/Cards/PaymentMethodCard'
import PaymentMethods from '@/constants/paymentMethod'
import SwipeableDrawer from '@/components/SwipeableDrawer'

import RecentPaymentMethodList2c2p from './RecentPaymentMethodList2c2p'

const {
  RECENT,
  BYPASS,
  CREDIT_CARD,
  APPLE_PAY,
  WECHAT_PAY,
  ALI_PAY,
  PAY_ME,
  FPS,
  OCTOPUS,
} = PaymentMethods

export default function PaymentMethodDrawer (props) {
  const classes = useStyles(props)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { open } = useSelector(state => state.app.drawers.paymentMethod)
  const creditCardGateway = useSelector(state => state.app.paymentGateway.creditcard)
  const paymeGateway = useSelector(state => state.app.paymentGateway.payme)
  const { isTable } = useDeliveryType()
  const merchant = useMerchant()
  const d2cBaseUrl = useD2CBaseUrl()
  const acceptedPaymentMethods = useAcceptedPaymentMethods()

  // 在 /order_tracking 開啟 PaymentMethodDrawer 時表示要選擇新的付款方式馬上付款
  // 使用的訂單要根據 orderHistory.selectedOrderId
  const isOrderTrackingSelectPayment = location.pathname.includes('/order_tracking')
  const orderingOrder = useSelector(state => state.order.selectedOrder)
  const historyOrder = useSelector(state => state.orderHistory.orders.find(order => order.id === state.orderHistory.selectedOrderId))
  const selectedOrder = isOrderTrackingSelectPayment && historyOrder ? historyOrder : orderingOrder

  const [showCreditCardType, setShowCreditCardType] = useState(false)
  const [isMerchantLoading, setMerchantLoading] = useState(false)

  const updateMerchant = async () => {
    if (!selectedOrder || !selectedOrder.merchantId) {
      // 沒有 merchantId 不要 fetchMerchant
      return
    }

    setMerchantLoading(true)
    await dispatch(actions.merchant.fetchMerchant(selectedOrder.merchantId))
    setMerchantLoading(false)
  }

  React.useEffect(() => {
    if (isMerchantLoading) return
    if (!selectedOrder) return
    if (!merchant?.id || merchant.id !== selectedOrder.merchantId) {
      // merchant 不符，需要先抓 merchant
      updateMerchant()
    }
  }, [isMerchantLoading, selectedOrder?.merchantId, merchant?.id])

  const closeDrawer = () => {
    setShowCreditCardType(false)
    dispatch(actions.app.toggleDrawer('paymentMethod', false))
  }

  const onSelectPaymentMethod = (paymentMethod, paymentGateway) => {
    dispatch(actions.payment.resetPayment())
    dispatch(actions.payment.updatePaymentMethod(paymentMethod))
    dispatch(actions.payment.updatePaymentGateway(paymentGateway))
    closeDrawer()
    if (isOrderTrackingSelectPayment) {
      createApplePaySession(selectedOrder, paymentMethod)
      // 在 /order_tracking 開啟 PaymentMethodDrawer 時表示要選擇新的付款方式馬上付款
      dispatch(actions.payment.payOrder(selectedOrder))
    }
  }

  const renderPaymentOption = (paymentMethod) => {
    switch (paymentMethod) {
      case BYPASS:
        // 直接顯示付款方式名稱，待 submit 或 pay 時再處理
        return (
          <PaymentMethodCard
            key={paymentMethod}
            paymentMethod={paymentMethod}
            onClick={() => {
              onSelectPaymentMethod(BYPASS, PaymentGateway.PAYMENT_GATEWAY_BYPASS)
            }}
          />
        )
      case PAY_ME:
        return (
          <PaymentMethodCard
            key={paymentMethod}
            paymentMethod={paymentMethod}
            onClick={() => {
              onSelectPaymentMethod(paymentMethod, paymeGateway)
            }}
          />
        )
      case WECHAT_PAY:
      case ALI_PAY:
      case FPS:
        // 直接顯示付款方式名稱，待 submit 或 pay 時再處理
        return (
          <PaymentMethodCard
            key={paymentMethod}
            paymentMethod={paymentMethod}
            onClick={() => {
              onSelectPaymentMethod(paymentMethod, PaymentGateway.PAYMENT_GATEWAY_QFPAY)
            }}
          />
        )
      case OCTOPUS:
        // 直接顯示付款方式名稱，待 submit 或 pay 時再處理
        return (
          <PaymentMethodCard
            key={paymentMethod}
            paymentMethod={paymentMethod}
            onClick={() => {
              onSelectPaymentMethod(OCTOPUS, PaymentGateway.PAYMENT_GATEWAY_OCTOPUS)
            }}
          />
        )
      case CREDIT_CARD:
        if (creditCardGateway === PaymentGateway.PAYMENT_GATEWAY_2C2P) {
          // 立即開啟填寫信用卡畫面
          return (
            <PaymentMethodCard
              key={CREDIT_CARD}
              paymentMethod={CREDIT_CARD}
              onClick={() => {
                history.pushWithSearch(d2cBaseUrl + '/settings/payment/add_card', {
                  isOrderTrackingSelectPayment,
                })
                closeDrawer()
              }}
            />
          )
        }
        if (creditCardGateway === PaymentGateway.PAYMENT_GATEWAY_FISERV) {
          // 直接顯示付款方式名稱，待 submit 或 pay 時再處理
          return (
            <PaymentMethodCard
              key={CREDIT_CARD}
              paymentMethod={CREDIT_CARD}
              onClick={() => {
                onSelectPaymentMethod(CREDIT_CARD, PaymentGateway.PAYMENT_GATEWAY_FISERV)
              }}
            />
          )
        }
        return null
      case APPLE_PAY:
        // 直接顯示付款方式名稱，待 submit 或 pay 時再處理
        // 目前只有 2c2p 支援 apple pay
        return (
          <PaymentMethodCard
            key={paymentMethod}
            paymentMethod={paymentMethod}
            onClick={() => {
              onSelectPaymentMethod(APPLE_PAY, PaymentGateway.PAYMENT_GATEWAY_2C2P)
            }}
          />
        )
      case RECENT:
        if (creditCardGateway === PaymentGateway.PAYMENT_GATEWAY_2C2P) {
          if (!showCreditCardType) {
            return (
              <RecentPaymentMethodList2c2p
                key={paymentMethod}
                order={selectedOrder}
                isRetryPayment={isOrderTrackingSelectPayment}
                closeDrawer={closeDrawer}
              />
            )
          }
        }
        // 其他 gateway 暫不支援 RECENT
        return null
      default:
        if (isTable && [Merchant.SOUL_CAFE, Merchant.明輝茶餐廳].includes(merchant?.id) && merchant?.setting?.payFirst) {
          // TODO: remove this, 這家餐廳 payFirst 允許內用沒選 paymentMethod = 店內付款
          return (
            <PaymentMethodCard
              key='PayInStore'
              displayText={t('app.constant.paymentMethod.payInStore')}
              onClick={() => onSelectPaymentMethod(null)}
            />
          )
        }

        return null
    }
  }

  return (
    <SwipeableDrawer
      open={open}
      onOpen={() => {
        // 沒有滑出來的控制方式，所以不需要設定 onOpen，但是 onOpen isRequired in material ui
      }}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <Typography className={classes.title}>
        {t('app.constant.paymentMethod.title')}
      </Typography>
      {acceptedPaymentMethods.map(renderPaymentOption)}
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(2),
    paddingBottom: `calc(${theme.spacing(2)}px + ${AppConstants.safeArea.bottom})`,
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& > :first-child': {
      marginBottom: theme.spacing(2),
    },
    overflowY: 'auto',
  },
  title: {
    fontSize: '1.8rem',
  },
}))
