const defaultColors = {
  primary: {
    main: '',
    disabled: '',
    contrastText: '',
    contrastTextHighlight: '',
  },
  secondary: {
    main: '',
    disabled: '',
    contrastText: '',
    contrastTextHighlight: '',
  },
}

const customColors = {
  primary: {
    main: '#ffc42b',
    contrastText: '#393939',
  },
  secondary: {
    main: '#ff9843',
    contrastText: '#fafafa',
  },
  disabled: {
    main: '#e0e0e0',
    contrastText: '#9e9e9e',
  },
  text: {
    primary: {
      main: '#393939',
      light: '#494949',
      dark: '#292929',
    },
    secondary: {
      main: '#ed631f',
      light: '#ff9843',
      dark: '#d4581c',
    },
  },
}

const enableCustomColors = (open) => {
  return open ? customColors : defaultColors
}

export default enableCustomColors(true)
