import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

function SkeletonMenuItem (props) {
  const classes = useStyles(props)

  return (
    <li className={classes.card}>
      <Skeleton className={classes.background} variant='rect' width='100%' height='100%' />
      <Skeleton className={classes.image} animation='wave' variant='rect' width='100%' height='100%' />
      <div className={classes.contnet}>
        <Skeleton animation='wave' variant='text' width='80%' height={24} />
        <Skeleton animation='wave' variant='text' width='80%' height={18} />
      </div>
      <div />
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '4fr 7fr minmax(40px, 1fr)',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  contnet: {
    '& > *': {
      marginBottom: 8,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    borderRadius: theme.shape.borderRadius * 2,
    paddingBottom: '100%',
  },
}))

export default React.memo(SkeletonMenuItem)
