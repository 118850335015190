
import { RESTAURANT_CARD } from '@/components/Cards/RestaurantCard'
import { useWindowSize } from './useWindowSize'

const ROW_HEIGHT = 240

/**
 * 使用 InfiniteQueryList 顯示 RestaurantCard 時
 * 都需要使用固定的 rowHeight 與 columnWidth
 * @returns
 */
export function useRestaurantCardListSize () {
  const rowHeight = ROW_HEIGHT
  const maxWidth = RESTAURANT_CARD.card.md.width
  const gutter = RESTAURANT_CARD.card.gutter

  const { innerWidth } = useWindowSize()
  const responsiveWidth = innerWidth - (gutter * 2)
  const columnWidth = Math.min(maxWidth, responsiveWidth)
  return { rowHeight, columnWidth }
}
