import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import Card from '@/components/Cards/Card'
import constants from '@/constants'

import ProgressBar from './ProgressBar'
import SkeletonProgressHeader from './ProgressHeader/Skeleton'
import SkeletonRestaurantInfo from './SkeletonRestaurantInfo'

const { TABLE } = constants.deliveryType

function SkeletonProgressCard (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <Card className={classes.container}>
      <section className={classes.headerSection}>
        <SkeletonProgressHeader />
        <ProgressBar order={{ deliveryType: TABLE }} currentIndex={-1} />
      </section>

      {/* Description */}
      <div className={classes.desc}>
        <Typography>{t('app.common.please_wait')}</Typography>
      </div>

      {/* Image */}
      <div className={classes.imageWrap} />

      <SkeletonRestaurantInfo />
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(3),
    // overflow: 'hidden',
  },
  desc: {
    color: theme.palette.grey[700],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerSection: {
    display: 'grid',
    gap: theme.spacing(1.5),
  },
  imageWrap: {
    width: '100%',
    minHeight: 180,
  },
  dimboxWarningSpacing: {
    marginTop: theme.spacing(1),
  },
}))

export default React.memo(SkeletonProgressCard)
