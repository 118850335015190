import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined'
import PropTypes from 'prop-types'
import React from 'react'

MenuItemBase.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
}

/**
 *
 * @param {{
 * title: string
 * onClick: Function
 * icon?: JSX.Element
 * hidden?: boolean
 * itemClasses?: object
 * }} props
 * @returns
 */
export default function MenuItemBase (props) {
  const { title, onClick, icon, hidden = false, itemClasses } = props
  const classes = useStyles(props)

  if (hidden) return null
  return (
    <ListItem
      button
      classes={itemClasses}
      className={classes.menuItemBase}
      onClick={onClick}
    >
      {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
      <ListItemText primary={title} />
      <ListItemSecondaryAction className={classes.secondaryAction}>
        <KeyboardArrowRightOutlinedIcon className={classes.chevronIcon} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  menuItemBase: {
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: theme.shadows[25],
  },
  listItemIcon: {
    minWidth: 34,
  },
  secondaryAction: {
    transform: 'translate(40%, -40%)',
  },
  chevronIcon: {
    color: theme.palette.grey[400],
  },
}))
