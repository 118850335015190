import { Checkbox, List, ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@material-ui/icons/Check'
import React, { useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import Alert from '@/components/Alerts/Alert'
import hooks from '@/hooks'

export default function SetItemDeleteDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const menuQuery = useMenuQuery()
  const sets = menuQuery.data?.sets

  const open = useSelector(state => state.app.dialogs.setItemDelete.open)
  const handleClose = () => {
    dispatch(actions.app.toggleDialog('setItemDelete', false))
    setTimeout(() => {
      dispatch(actions.app.toggleDialog('setItemDelete', undefined, {}))
      // 200ms delay for dialog close transition duration
    }, 200)
  }

  // SetItemStepper handleDecrement 的時候把 setItemMenu (IAppMenuItem) 存到 redux 讓這裡可以拿到
  const setItemMenu = useSelector(state => state.app.dialogs.setItemDelete.data.setItemMenu) ?? {}

  // IAppBatchItem
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const setItems = _.filter(batchItemTemp.setItems, setItem => setItem.setMenuId === setItemMenu?.id)
  // const setItems = _.clone(batchItemTemp.setItems)

  const initialSelected = _.reduce(setItems, (result, setItem) => {
    result[setItem?.key] = false
    return result
  }, {})
  const [selected, setSelected] = useState(initialSelected)
  const handleSubmit = () => {
    // delete selected setItem
    const selectedKeys = Object.keys(selected)
    const deleteKeys = _.filter(selectedKeys, key => selected[key])
    const setItems = _.clone(batchItemTemp.setItems)
    _.remove(setItems, setItem => _.includes(deleteKeys, setItem.key))
    dispatch(actions.batchItemTemp.updateSetItems(setItems))
    dispatch(actions.batchItemTemp.cleanUpDependentSetItems(sets))
  }

  const alertConfig = {
    title: '',
    message: t('app.component.dialog.setItem_delete_dialog.alert.message'),
    onConfirm: handleSubmit,
    children: (
      <List>
        {
          _.map(setItems, (setItem, index) => {
            const option = _.isEmpty(setItem?.options)
              ? t('app.common.none')
              : _.map(setItem?.options, option => option.name).join(', ')
            const isSelected = selected[setItem?.key]
            const handleClick = () => setSelected({ ...selected, [setItem?.key]: !selected[setItem?.key] })

            return (
              <ListItem
                button
                key={index + JSON.stringify(selected)} // force rerender on selected changed
                onClick={handleClick}
              >
                <Typography className={classes.optionName}>
                  {option}
                </Typography>
                <ListItemSecondaryAction>
                  <Checkbox
                    color='primary'
                    icon={<div />}
                    checkedIcon={<CheckIcon />}
                    classes={{ root: classes.checkbox }}
                    checked={isSelected}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })
        }
      </List>
    ),
  }

  return (
    <Alert open={open} onClose={handleClose} alertConfig={alertConfig} />
  )
}

const useStyles = makeStyles(theme => ({
  optionName: {
    fontSize: '1.6rem',
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
}))
