import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import Card from '@/components/Cards/Card'
import CouponGif from '@/assets/images/coupon.gif'
import dimorderApi from '@/libs/api/dimorder'
import useShortLang from '@/hooks/useShortLang'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef GainCouponsCardProps
 * @property {IAppOrder} order
 */

/**
 * 顯示因為這張訂單而獲得的 coupons
 * 若該筆訂單未獲得任何 coupon 則不顯示
 * @param {GainCouponsCardProps} props
 * @returns
 */
export default function GainCouponsCard (props) {
  const { order } = props
  const { id: orderId, payments } = order
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [coupons, setCoupons] = React.useState([])
  const lang = useShortLang()
  const isLogin = useIsLogin()
  const intervalId = React.useRef()

  const useGetCouponsInterval = () => {
    const paidAt = React.useMemo(() => {
      return _.find(payments, payment => payment.paidAt)?.paidAt
    }, [payments])

    const getCoupons = async () => {
      if (!paidAt) return // 若尚未成功付款則不抓 coupons
      const data = await dimorderApi.promoCode.getCoupons(orderId)
      setCoupons(data)
    }

    React.useEffect(() => {
      getCoupons() // 立即抓一次 coupons
      intervalId.current = setInterval(() => { getCoupons() }, 1000) // 之後每秒鐘抓一次 coupons
      return () => { clearInterval(intervalId.current) }
    }, [paidAt])

    React.useEffect(() => {
      if (
        coupons.length > 0 || // 當有抓到 coupons 時立即停止抓 coupons
        moment().isAfter(moment(paidAt).add(10, 'sec')) // 只在成功付款後 10 秒內持續抓 coupons（因為寫入資料需要時間沒辦法馬上顯示）
      ) {
        clearInterval(intervalId.current)
      }
    }, [coupons, paidAt])
  }
  useGetCouponsInterval()

  const aggregatedCoupons = React.useMemo(() => {
    // 整合相同 id 的 coupon 並附加 quantity 到 coupon 內以顯示數量
    const hashMap = _.reduce(coupons, (acc, coupon) => {
      !acc[coupon.id]
        ? acc[coupon.id] = { ...coupon, quantity: 1 }
        : acc[coupon.id].quantity += 1
      return acc
    }, {})
    return Object.values(hashMap)
  }, [coupons])

  const handleLogin = () => {
    dispatch(actions.app.toggleLoginDrawer(true))
  }

  if (_.isEmpty(coupons)) return null // 若該筆訂單未獲得任何 coupon 則不顯示

  return (
    <Card className={classes.couponCard}>
      <img src={CouponGif} alt='coupon' className={classes.icon} />
      <Typography>
        {!isLogin
          ? t('app.page.order_tracking.gain_coupon_card.you_will_get')
          : t('app.page.order_tracking.gain_coupon_card.you_got')}
      </Typography>
      <div className={classes.couponList}>
        {_.map(aggregatedCoupons, coupon => (
          <Typography key={coupon.id} color='secondary' variant='h6' className={classes.couponName}>
            {coupon.name?.[lang]} x {coupon.quantity}
          </Typography>
        ))}
      </div>
      {!isLogin && (
        <Link
          variant='body2'
          className={classes.loginLink}
          onClick={handleLogin}
        >
          {t('app.page.order_tracking.gain_coupon_card.login_or_register_to_get')}
        </Link>
      )}
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  couponList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  couponCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  icon: {
    maxWidth: 80,
  },
  couponName: {
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  loginLink: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
}))
