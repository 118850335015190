
import { useDeliveryType } from '@/hooks/app'
import { useLocation } from 'react-router-dom'
import { useMerchant } from '@/hooks/merchant'
import React from 'react'

import AddressCard from '@/components/Cards/AddressCard'
import OrderBatchItemList from '@/components/OrderBatchItemList'
import OrderRewardItemList from '@/components/OrderRewardItemList'
import PaymentSummaryCard from '@/components/Cards/PaymentSummaryCard'
import RedeemRiceCoinCard from '@/components/Cards/RedeemRiceCoinCard'
import RiceCoinRewardCard from '@/components/Cards/RiceCoinRewardCard'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef OrderHistoryItemProps
 * @property {IAppOrder}  order
 * @property {number} reward
 * @param {OrderHistoryItemProps} props
 * @returns
 */
export default function OrderHistoryItem (props) {
  const { order, reward } = props
  const { pathname } = useLocation()
  const merchant = useMerchant()
  const { isTable } = useDeliveryType()
  const showPayment = pathname.includes('/pay') || !isTable || merchant?.setting?.payFirst

  if (!order) return null
  return (
    <>
      <OrderBatchItemList order={order} batches={order.batches} />
      <OrderRewardItemList order={order} />
      <RedeemRiceCoinCard />
      <RiceCoinRewardCard reward={reward} />
      {showPayment && <PaymentSummaryCard order={order} />}
      <AddressCard order={order} />
    </>
  )
}
