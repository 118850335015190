import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React from 'react'

import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

export default function AlipayLanding () {
  const { t } = useTranslation()

  return (
    <Page>
      <PageContainer>
        <Typography>{t('app.component.alert.web_payment.alipay_landing_text')}</Typography>
      </PageContainer>
    </Page>
  )
}
