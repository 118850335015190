import React from 'react'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import StarRateIcon from '@material-ui/icons/StarRate'

/**
 *
 * @param {{
 * variant: 'error' | 'warning' | 'info'
 * className?: string
 * }} props
 * @returns
 */
export default function NoticeCardIcon (props) {
  const { variant, className } = props

  switch (variant) {
    case 'error':
      return <ReportProblemIcon className={className} />
    case 'warning':
      return <ErrorOutlineIcon className={className} />
    case 'info':
      return <StarRateIcon className={className} />
    default:
      return null
  }
}
