import {
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useSelector } from '@/redux'

import logo from '@/assets/icon-192.png'

export default function AppUpdate (props) {
  const classes = useStyles(props)
  const { status, progress } = useSelector(state => state.app.updateStatus)

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={logo} alt='logo' />
      <Typography>{status}</Typography>
      <LinearProgress className={classes.progress} variant='determinate' value={progress} />
      <Typography>{progress}%</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme, props) => ({
  container: {
    flex: 1,
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  logo: {
    width: 192,
    height: 192,
  },
  progress: {
    width: '75%',
  },
}))
