import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useDeliveryType } from '@/hooks/app'

/**
 * 只有外送要顯示
 * @typedef DistanceProps
 * @property {number} distance
 * @param {DistanceProps} props
 * @returns
 */
export default function Distance (props) {
  const { distance } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const { isTakeaway } = useDeliveryType()

  if (!isTakeaway) return null
  return (
    <div className={classes.distanceWrap}>
      <Typography className={classes.distance}>
        {_.round(distance, 1)} {t('app.common.km_distance')}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  distanceWrap: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  distance: {
    fontSize: '1rem',
  },
}))
