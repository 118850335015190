import { Route, Switch } from 'react-router-dom'

import React, { lazy, useEffect } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'

import AddCreditCard from '@/pages/Settings/PaymentSetting/AddCreditCard'
import AddressEdit from '@/pages/Settings/AddressSetting/AddressEdit'
import AddressSelect from '@/pages/Settings/AddressSetting/AddressSelect'
import AddressSetting from '@/pages/Settings/AddressSetting'
import AlipayLanding from '@/pages/AlipayLanding'
import AppUpdate from '@/pages/AppUpdate'
import BackdropLoading from '@/components/BackdropLoading'
import CRMHomePage from '@/pages/CRM/HomePage'
import CRMMembershipPage from '@/pages/CRM/MembershipPage'
import CRMPointsPage from '@/pages/CRM/PointsPage'
import CRMRewardsPage from '@/pages/CRM/RewardsPage'
import Category from '@/pages/Category'
import Checkout from '@/pages/Checkout'
import Coupon from '@/pages/Coupon'
import Cuisine from '@/pages/Cuisine'
import EditMemberDetails from './Member/EditMemberDetails'
import Favorite from '@/pages/Favorite'
import Filter from '@/pages/Filter'
import Home from '@/pages/Home'
import Member from '@/pages/Member'
import OrderHistory from '@/pages/OrderHistory'
import OrderHistoryList from '@/pages/OrderHistoryList'
import OrderTracking from '@/pages/OrderTracking'
import Pay from '@/pages/Pay'
import Payment3DS from '@/pages/Checkout/Payment3DS'
import RedeemCode from '@/pages/RedeemCode'
import Restaurant from '@/pages/Restaurant'
import RestaurantList from '@/pages/RestaurantList'
import RiceCoin from '@/pages/RiceCoin'
import Search from '@/pages/Search'
import Version from '@/pages/Settings/Version'

const D2CRouter = lazy(() => import('@/pages/D2CRouter'))

export default function AppRouter (props) {
  const dispatch = useDispatch()
  const isAppInit = useSelector(state => state.app.isInit)
  const isUserInit = useSelector(state => state.user.isInit)
  const isUpdating = useSelector(state => state.app.updateStatus.status)

  useEffect(() => {
    if (!isAppInit) {
      dispatch(actions.app.init())
    } else {
      // 已經 init 過了，不需要整個 app 重新 init，但 listener 還是要跑起來
      dispatch(actions.app.startAppListener())
      dispatch(actions.orderHistory.startOrdersUpdater())
    }
    return () => dispatch(actions.app.stopAppListener())
  }, [])

  if (isUpdating) return <AppUpdate />
  if (!isUserInit) return <BackdropLoading open />

  return (
    <Switch>
      <Route path='/d2c/:id' component={D2CRouter} />
      <Route path='/restaurants' component={RestaurantList} />
      <Route path='/favorite' component={Favorite} />
      <Route path='/restaurant/:id' component={Restaurant} />
      <Route path='/member/edit' component={EditMemberDetails} />
      <Route path='/member/ricecoin' component={RiceCoin} />
      <Route path='/member/coupon' component={Coupon} />
      <Route path='/member/redeem' component={RedeemCode} />
      <Route path='/member' component={Member} />
      <Route path='/orders' component={OrderHistoryList} />
      <Route path='/order' component={OrderHistory} />
      <Route path='/order_tracking/:orderId' component={OrderTracking} />
      <Route path='/order_tracking' component={OrderTracking} />
      <Route path='/pay' component={Pay} />
      <Route path='/settings/address/edit' component={AddressEdit} />
      <Route path='/settings/address/map' component={AddressSelect} />
      <Route path='/settings/address' component={AddressSetting} />
      <Route path='/settings/payment/add_card' component={AddCreditCard} />
      <Route path='/settings/version' component={Version} />
      <Route path='/checkout/3ds' component={Payment3DS} />
      <Route path='/checkout' component={Checkout} />
      <Route path='/search' component={Search} />
      <Route path='/filter' component={Filter} />
      <Route path='/cuisine' component={Cuisine} />
      <Route path='/category/:categoryName' component={Category} />
      <Route path='/category' component={Category} />
      <Route path='/alipay_landing' component={AlipayLanding} />
      <Route path='/membership/:groupId/points' component={CRMPointsPage} />
      <Route path='/membership/:groupId/rewards' component={CRMRewardsPage} />
      <Route path='/membership/:groupId' component={CRMHomePage} />
      <Route path='/membership' component={CRMMembershipPage} />
      <Route exact path='/' component={Home} />
    </Switch>
  )
}
