import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'

import DeliveryTypes from '@/constants/deliveryType'

export const CUSTOMER_CANCEL_REASON = 'Customer cancel.'

export default function CancelButton (props) {
  const { order } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useStyles(props)

  if (!order) return null
  if (!['waiting_pay', 'paying'].includes(order?.displayStatusKey)) return null // 等待付款的狀態才能取消
  if (order.deliveryType === DeliveryTypes.TABLE) return null // 內用不顯示取消按鈕

  const handleCancelOrder = () => {
    if (!order || order.status !== 'pending') return

    dispatch(actions.app.toggleAlert({
      title: t('app.page.order_tracking.confirm_cancel_alert.title'),
      message: t('app.page.order_tracking.confirm_cancel_alert.message'),
      buttons: [
        {
          text: t('app.common.yes'),
          onClick: () => {
            dispatch(actions.orderHistory.cancelSelectedOrder(order.id, CUSTOMER_CANCEL_REASON))
          },
        },
        {
          text: t('app.common.no'),
          style: { backgroundColor: theme.palette.primary.main },
          onClick: () => {},
        },
      ],
    }))
  }

  return (
    <ButtonBase
      className={classes.cancelButton}
      onClick={handleCancelOrder}
    >
      <Typography variant='body2'>
        {t('app.page.order_tracking.buttons.cancel_order')}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  cancelButton: {
    flexShrink: 0,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: theme.palette.info.main,
  },
}))
