import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

TabBase.propTypes = {
  title: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * title: String
 * selected: Boolean
 * onClick: Function,
 * }} props
 * @returns
 */
export default function TabBase (props) {
  const { title, selected, onClick } = props
  const classes = useStyles(props)

  return (
    <Typography
      component='span'
      className={clsx(classes.tab, { [classes.selected]: selected })}
      onClick={onClick}
    >{title}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  tab: {
    position: 'relative',
    padding: theme.spacing(1, 2),
    fontSize: '1.6rem',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '0%',
      transform: 'translateX(-50%)',
      width: '0%',
      height: '90%',
      borderBottom: '3px solid transparent',
    },
  },
  selected: {
    fontSize: '1.8rem',
    fontWeight: 600,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '75%',
      height: '90%',
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      transition: 'all 500ms ease',
    },
  },
}))
