// eslint-disable-next-line no-unused-vars
import { Avatar, AvatarProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useMember } from '@/hooks/user'
import defaultUserAvatar from '@/assets/icons/default-user-avatar.svg'

/**
 * @typedef MemberAvatarProps
 * @property {number} [size=40]
 * @property {AvatarProps} [avatarProps=[]]
 * @param {MemberAvatarProps} props
 * @returns
 */
export default function MemberAvatar (props) {
  const { size = 40, avatarProps = {} } = props
  const classes = useStyles(props)
  const member = useMember()
  return (
    <Avatar classes={{ root: classes.avatarRoot }} style={{ width: size, height: size }} {...avatarProps}>
      {
        member?.avatar
          ? <img src={member.avatar} alt='avatar' className={classes.avatar} />
          : <img src={defaultUserAvatar} alt='default-user-avatar' className={classes.avatar} />
      }
    </Avatar>
  )
}

const useStyles = makeStyles(theme => ({
  avatarRoot: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.common.white}`,
  },
  avatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))
