import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import opacity from '@/theme/opacity'

/**
 *
 * @param {{
 * sold: boolean
 * }} props
 * @returns
 */
export default function SoldLayer (props) {
  const { sold } = props
  const classes = useStyles(props)

  if (!sold) return null
  return (
    <div className={classes.soldLayer} />
  )
}

const useStyles = makeStyles(theme => ({
  soldLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: `${theme.palette.grey[100]}${opacity[60]}`,
    },
  },
}))
