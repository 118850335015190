import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import opacity from '@/theme/opacity'

/**
 *
 * @typedef PageTabsBaseProps
 * @property {Tab[]} tabs
 * @property {number} selectedTab
 * @property {string} [className]
 * @typedef Tab
 * @property {TDeliveryType} deliveryType
 * @property {string} title
 * @property {() => void} onClick
 * @param {PageTabsBaseProps} props
 * @returns
 */
export default function PageTabsBase (props) {
  const { tabs, selectedTab, className } = props
  const classes = useStyles({ ...props })

  return (
    <ul className={clsx(classes.tabs, className)}>
      {_.map(tabs, (tab, index) => {
        const { title, onClick } = tab
        const isSelected = index === selectedTab
        return (
          <li
            key={index}
            className={clsx(classes.tab, { [classes.selectedTab]: isSelected })}
            onClick={onClick}
          >
            <span>
              <Typography>
                {title}
              </Typography>
            </span>
          </li>
        )
      })}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  tabs: {
    flexShrink: 0,
    display: 'flex',
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  tab: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.75),
    borderRadius: '15px 15px 0 0',
    color: theme.palette.text.primary,
    backgroundColor: '#ffe4c5',
    boxShadow: props => !props.disableBoxShadow && `0px -2px 2px #292929${opacity[16]}`,
    cursor: 'pointer',
    '& p': {
      fontSize: '1.4rem',
    },
    '&:before': {
      zIndex: 1,
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '-20px',
      width: '20px',
      height: '20px',
      borderRadius: 10,
    },
    '&:after': {
      zIndex: 1,
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: '-20px',
      width: '20px',
      height: '20px',
      borderRadius: 10,
    },
    '& span': {
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '-10px',
        width: '10px',
        height: '10px',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        right: '-10px',
        width: '10px',
        height: '10px',
      },
    },
  },
  selectedTab: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[50],
    zIndex: 10,
    marginTop: 0,
    '& p': {
      fontSize: '1.6rem',
      fontWeight: 600,
      transition: 'all 50ms ease',
    },
    '&:before': {
      backgroundColor: '#ffe4c5',
    },
    '&:after': {
      backgroundColor: '#ffe4c5',
    },
    '& span': {
      '&:before': {
        backgroundColor: theme.palette.grey[50],
      },
      '&:after': {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}))
