import { Box, Typography } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import React, { useEffect } from 'react'

function WebPaymentQRCode () {
  const ALERT_ID_WEB_PAYMENT_QR = 'web_payment_qr'

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const paymentQRCode = useSelector((state) => state.payment.paymentQRCode)
  const qrCodePaymentMethod = useSelector((state) => state.payment.qrCodePaymentMethod)
  const paymentMethod = useSelector((state) => state.payment.selectedPaymentMethod)

  useEffect(() => {
    if (!paymentQRCode) {
      return
    }

    const urlObj = new URL(paymentQRCode)
    const alertConfig = {
      title: (
        <Typography>
          {t('app.component.alert.web_payment.qr_title', {
            paymentMethod: t(`app.component.alert.web_payment.${qrCodePaymentMethod || paymentMethod}`),
          })}
        </Typography>
      ),
      children: (
        <Box display='flex' justifyContent='center'>
          {urlObj.host === 'globalmapi.alipay.com'
            ? (
              <iframe
                style={{ width: 200, height: 204, border: 'none', margin: 12 }}
                src={paymentQRCode}
              />
            )
            : (
              <QRCode
                value={paymentQRCode}
                size={256}
                includeMargin
              />
            )}
        </Box>
      ),
      button: {
        text: t('app.common.back'),
        onClick: () => dispatch(actions.payment.updatePaymentQRCodeStatus(false)),
      },
    }

    dispatch(actions.app.toggleAlert(alertConfig, ALERT_ID_WEB_PAYMENT_QR))

    return () => {
      dispatch(actions.app.toggleAlert(alertConfig, ALERT_ID_WEB_PAYMENT_QR, false))
      dispatch(actions.payment.updatePaymentQRCodeStatus(false))
    }
  }, [paymentQRCode])

  return null
}

export default WebPaymentQRCode
