import { makeStyles } from '@material-ui/core/styles'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import React from 'react'

export default function CenterMarker (props) {
  const classes = useStyles(props)

  return (
    <div className={classes.centerMarkerWrap}>
      <PersonPinCircleIcon className={classes.centerMarker} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  centerMarkerWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
  },
  centerMarker: {
    color: theme.palette.error.main,
    width: '4rem',
    height: '4rem',
  },
}))
