import { actions } from '@/redux'
import _ from 'lodash'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosError } from 'axios'

import i18n from '@/i18n'
import logger, { logId } from '@/libs/logger'
import store from '@/redux/store'

/**
 * @typedef {Object} HandleAxiosErrorOptions
 * @property {string | string[]} responseErrorMessagePath _.get 從 error 抓出錯誤訊息的路徑（因應每支 api 回應錯誤的格式不同）
 * @property {string} loggerPrefix 加在 logger error message 前的字串
 * @property {Object} loggerExtraData error 以外，要放進 logger jsonPayload
 * @property {(errorMessage: string) => void} notAxiosErrorHandler 處理非 axios error
 * @property {(errorMessage: string) => void} requestErrorHandler 處理前端 request 建立失敗
 * @property {(errorMessage: string) => void} networkErrorHandler 處理後端無回應或網路異常
 * @property {(errorMessage: string) => void} responseErrorHandler 處理後端回應錯誤
 */

const defaultOptions = {
  responseErrorMessagePath: 'response.data.error',
  loggerPrefix: undefined,
  loggerExtraData: {},
  notAxiosErrorHandler: (errorMessage) => {
    // 非 axios 錯誤通常是前端要改 code 修正了，而且不一定知道發生什麼事，所以通常可以直接用這個預設的 handler 顯示發生問題就好
    store.dispatch(actions.app.toggleAlert({
      title: i18n.t('app.component.alert.axios.unknow_error.title'),
      message: i18n.t('app.component.alert.axios.unknow_error.message') + ` (${logId})`,
    }))
  },
  requestErrorHandler: () => {
    // request 建立誤通常是前端要改 code 修正了，而且不一定知道發生什麼事，所以通常可以直接用這個預設的 handler 顯示發生問題就好
    store.dispatch(actions.app.toggleAlert({
      title: i18n.t('app.component.alert.axios.unknow_error.title'),
      message: i18n.t('app.component.alert.axios.unknow_error.message') + ` (${logId})`,
    }))
  },
  networkErrorHandler: () => {
    // 網路問題或沒有 resopnse 通常可以用這個預設的顯示請稍後再試
    store.dispatch(actions.app.toggleAlert({
      title: i18n.t('app.component.alert.axios.network_error.title'),
      message: i18n.t('app.component.alert.axios.network_error.message') + ` (${logId})`,
    }))
  },
  responseErrorHandler: undefined,
}

/**
 * 懶得一直重寫判斷和 call logger，直接包一層來處理 error，詳見第二個參數 options: HandleAxiosErrorOptions 的 jsdoc
 * @param {AxiosError | Error} error
 * @param {HandleAxiosErrorOptions} options
 * @example
 * // hanlde request POST /order error
 * handleAxiosError(error, {
 *   responseErrorMessagePath: 'response.data.error',
 *   loggerPrefix: '[createOrder] order create',
 *   loggerExtraData: { orderData },
 *   responseErrorHandler: (errorMessage) => {
 *     // 處理後端回應錯誤
 *     switch (errorMessage) {
 *       case 'no such merchant':
 *         // ...show alert
 *         break
 *       case 'no such table':
 *         // ...show alert
 *         break
 *       default:
 *         // ...show alert
 *     }
 *   },
 * })
 */
export default function handleAxiosError (error, options = defaultOptions) {
  if (!error) return

  const {
    responseErrorMessagePath = defaultOptions.responseErrorMessagePath,
    loggerPrefix = `[${_.get(error.request?.url)}]`,
    loggerExtraData = defaultOptions.loggerExtraData,
    notAxiosErrorHandler = defaultOptions.notAxiosErrorHandler,
    requestErrorHandler = defaultOptions.requestErrorHandler,
    networkErrorHandler = defaultOptions.networkErrorHandler,
    responseErrorHandler = defaultOptions.responseErrorHandler,
  } = options

  const errorMessage = _.get(error, responseErrorMessagePath, error.message || String(error))
  const loggerMessage = loggerPrefix + ' ' + errorMessage

  if (!axios.isAxiosError(error)) {
    logger.error(loggerMessage, { error, ...loggerExtraData })
    // 非 axios error
    notAxiosErrorHandler(errorMessage)
    return
  }

  // logger 的 data (jsonPayload) 塞太大包好像會在 GCP 上看不到全部內容，因此下面只把需要的 config 和 response 部分拿出來放進 data
  if (!error.response) {
    // 沒有 response
    logger.error(loggerMessage, {
      error: {
        hasResponse: false,
        hasRequest: Boolean(error.request),
        config: {
          method: error.config.method,
          url: error.config.url,
          headers: error.config.headers,
          params: error.config.params,
          data: error.config.data,
        },
      },
      ...loggerExtraData,
    })
    if (!error.request) {
      // 前端 request 建立失敗
      requestErrorHandler(errorMessage)
      return
    }
    // 有成功建立 request 但未得到回應，可能為網路異常或伺服器無回應
    networkErrorHandler(errorMessage)
    return
  }

  // 後端有回應錯誤
  logger.error(loggerMessage, {
    error: {
      hasResponse: true,
      hasRequest: true,
      config: {
        method: error.config.method,
        url: error.config.url,
        headers: error.config.headers,
        params: error.config.params,
        data: error.config.data,
      },
      response: {
        headers: error.response.headers,
        status: error.response.status,
        data: error.response.data,
      },
    },
    ...loggerExtraData,
  })
  responseErrorHandler(errorMessage)
}
