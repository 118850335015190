import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { isPoonChoiCategoryTag, isPoonChoiRestaurant } from '@/libs/poonchoi'
import { useCart } from '@/hooks/useCart'
import { useDeliveryType, useLoadings, useParams } from '@/hooks/app'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PoonchoiMenuButton (props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const { isStoreDelivery } = useDeliveryType()
  const loadings = useLoadings()
  const { hasItemsInCart } = useCart()
  const restaurant = useSelector(state => state.landing.restaurant)
  const menuQuery = useMenuQuery()
  const isMenuLoading = menuQuery.isLoading || loadings.createShipping
  const isPoonchoi = isPoonChoiCategoryTag(params.categoryTag)
  const isPoonchoiRestaurant = isPoonChoiRestaurant(restaurant?.tags)

  const handleSwitch = () => {
    // 盆菜只做外送，更新 catagoryTag，並帶上這個 params 重抓 menu)
    dispatch(actions.app.updateParams({ ...params, categoryTag: '盆菜' }))
    dispatch(actions.orderBatch.resetBatch())
    dispatch(actions.order.createLocalOrder())
    dispatch(actions.menu.fetchCategories())
  }

  const handleClick = () => {
    if (hasItemsInCart) {
      // 購物車中有東西且 categoryTag 不同，需要詢問並清除才能繼續切換
      dispatch(actions.app.toggleAlert({
        title: t('app.component.alert.difference_menu_reset_batch.title'),
        message: t('app.component.alert.difference_menu_reset_batch.message'),
        onConfirm: () => handleSwitch(),
      }))
      return
    }

    handleSwitch()
  }

  if (!isPoonchoiRestaurant) return null // 餐廳沒有參加盆菜活動
  if (isPoonchoi) return null // 目前是盆菜菜單
  if (!isStoreDelivery) return null // 只有外送能看到盆菜菜單
  return (
    <Button
      color='primary'
      variant='contained'
      className={classes.poonchoiMenuButton}
      onClick={handleClick}
      disableElevation
      disabled={isMenuLoading}
    >
      <Typography>{t('app.component.poonchoi_menu_button')}</Typography>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  poonchoiMenuButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 1.5),
    borderRadius: 12,
    minHeight: 40,
  },
}))
