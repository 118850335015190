import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import { useSelectedOrder } from '@/hooks/order'
import {
  validateDate,
  validateMerchantId,
  validateMinOrderAmount,
  validateQuota,
} from '@/libs/promoCode'
import CouponCard from '@/components/Coupon/CouponCard'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AvailablePromoCodeList (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { merchantId } = useSelector(state => state.app.params)
  const { deliveryType } = useDeliveryType()
  const promoCodes = useSelector(state => state.user.promoCodes)
  const order = useSelectedOrder()

  // 之前用過的優惠碼
  const availablePromoCodes = React.useMemo(() => {
    return _.chain(promoCodes)
      .filter(promoCode => {
        return (
          (!promoCode.deliveryType || promoCode.deliveryType === deliveryType) && // 沒設定 deliveryType 或 deliveryType 符合設定
          validateMerchantId(promoCode, merchantId) && // 符合 merchant id 設定
          validateDate(promoCode) && // 符合使用時間
          validateQuota(promoCode) && // 檢查有沒有被用光
          validateMinOrderAmount(promoCode, order?.originalTotal) // 達到低消
        )
      })
      .uniqBy('code')
      .value()
  }, [promoCodes, merchantId, deliveryType, order])

  const onConfirm = async (code) => {
    dispatch(actions.order.applyPromoCode(code))
    dispatch(actions.app.toggleDialog('promoCode'))
  }

  if (_.isEmpty(availablePromoCodes)) {
    return null
  }

  return (
    <div>
      <Typography className={classes.title}>
        {t('app.component.dialog.promo_dialog.available_promo_code')}
      </Typography>
      {
        _.map(availablePromoCodes, (promoCode, index) => {
          return (
            <CouponCard
              key={index}
              name={promoCode.code}
              type='PROMOCODE'
              validFrom={promoCode?.validFrom}
              validUntil={promoCode?.validUntil}
              desc={[t('app.component.dialog.promo_dialog.promo_code')]}
              discountNumber={promoCode.amount === 0 ? promoCode.percent : promoCode.amount}
              discountType={promoCode.amount === 0 ? 'percent' : 'amount'}
              buttonText={t('app.component.dialog.promo_dialog.apply')}
              onButtonClick={() => onConfirm(promoCode.code)}
            />
          )
        })
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.6rem',
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))
