import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import { actions, useDispatch, useSelector } from '@/redux'
import { calculateDisplayBatchTotal } from '@/libs/order'
import { isPoonChoiCategoryTag } from '@/libs/poonchoi'
import constants from '@/constants'

import { useDeliveryType, useParams, useSystem } from '@/hooks/app'
import { useMerchant } from '@/hooks/merchant'
import { useRewardItems } from '@/hooks/useCart'
import { useSelectedBatch, useSelectedOrder, useShipping } from '@/hooks/order'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

import FooterBase from '@/components/Footers/FooterBase'

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType

/**
 * @typedef RestaurantFooterProps
 * @property {IFooterSubmitButtonProps} submitButtonProps
 * @property {boolean} [slide]
 * @property {boolean} [solidBackground]
 * @property {JSX.Element} [children]
 */

/**
 *
 * @param {RestaurantFooterProps} props
 * @returns
 */
export default function RestaurantFooter (props) {
  const { submitButtonProps, slide, solidBackground, children } = props
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const d2cBaseUrl = useD2CBaseUrl()
  const system = useSystem()
  const merchant = useMerchant()
  const { categoryTag } = useParams()
  const { deliveryType, isTable } = useDeliveryType()
  const selectedBatch = useSelectedBatch()
  const selectedOrder = useSelectedOrder()
  const shippingFee = useShipping()?.totalFee
  const restaurant = useSelector(state => state.landing.restaurant)
  const isPoonchoi = isPoonChoiCategoryTag(categoryTag)
  const displayBatchTotal = calculateDisplayBatchTotal(selectedBatch, selectedOrder) // 未送出的餐點金額，使用本地 selectedBatch 計算
  const rewardItems = useRewardItems()
  const canShip = _.isNumber(shippingFee)
  const open = restaurant?.open ?? false

  const handleClick = async () => {
    // 盆菜活動先打開時間日期選擇 選完時間確認後再轉頁到 checkout
    if (isPoonchoi) {
      dispatch(actions.app.toggleDialog('specialTimePicker', true, { type: 'moon' }))
      return
    }

    const hasOfflineDevice = await dispatch(actions.merchant.findOfflineDevice(merchant?.id, true)) // 內用時檢查 merchant 是否離線
    dispatch(actions.app.toggleMaterDeviceOfflineAlert(hasOfflineDevice))
    history.pushWithSearch(`${d2cBaseUrl}/checkout`)
  }

  const enabled = () => {
    if (isPoonchoi) return true // 盆菜活動都要是 enable
    if (system[deliveryType].enable) { // deliveryType 的 system 全域設定有開
      switch (deliveryType) {
        case TABLE:
          if (open) return true // 餐廳營業中
          break

        case TAKEAWAY:
          if (!merchant.takeawayDisabled) return true // 餐廳有開啟外帶設定
          break

        case STORE_DELIVERY:
          if (merchant.setting?.storeDelivery && canShip) return true // 餐廳有開啟外送設定，且有外送方案
          break

        default:
          break
      }
    }
    return false
  }

  const defaultSubmitButtonProps = {
    title: t('app.component.footer.cart.go_checkout_order'),
    disabled: !enabled(),
    subtitle: (isTable && !open) ? t('app.component.alert.invalid_timeslot.title') : undefined,
    onClick: handleClick,
  }

  return (
    <FooterBase
      slide={slide}
      quantity={displayBatchTotal.quantity + rewardItems.total.quantity}
      total={orderAppLib.libs.getDisplayRoundNumber(displayBatchTotal.total, merchant.rounding)}
      originalTotal={orderAppLib.libs.getDisplayRoundNumber(displayBatchTotal.originalTotal, merchant.rounding)}
      submitButtonProps={submitButtonProps ?? defaultSubmitButtonProps}
      solidBackground={solidBackground}
    >
      {children}
    </FooterBase>
  )
}
