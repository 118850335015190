import { formatToUIDate, formatToUITime } from '@/libs/dateTimePicker'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from '@/redux'
import _ from 'lodash'
import dimorderApi from '@/libs/api/dimorder'

/* eslint-disable no-unused-vars */
import { TDeliveryType } from 'dimorder-orderapp-lib/dist/types/Order'
/* eslint-enable no-unused-vars */

const staleTime = 10 * 60 * 1000

const generateQueryKey = (shippingTime, deliveryType, merchantId, forAll, date) => {
  let queryKey = ['timeslot', 'times']
  if (forAll) {
    queryKey.push('forAll')
  } else {
    queryKey.push(`merchantId:${merchantId}`)
  }
  if (deliveryType === 'takeaway') {
    queryKey = queryKey.concat([`deliveryType:${deliveryType}`])
  } else if (deliveryType === 'storeDelivery') {
    queryKey = queryKey.concat([`deliveryType:${deliveryType}`, `shippingTime:${shippingTime}`])
  }
  if (date) {
    queryKey.push(`date:${date}`)
  }
  return queryKey
}

/**
 *
 * @param {TDeliveryType} deliveryType
 * @param {{ forAll?: boolean }} options
 * @returns
 */
export function useDatesQuery (deliveryType, options) {
  const forAll = options?.forAll ?? false
  const merchantId = useSelector(state => state.merchant.data?.id) ?? ''
  const shippingTime = useSelector(state => state.order.shipping?.shippingTime) ?? 0
  const enabled = (!!merchantId || forAll) && (deliveryType === 'storeDelivery' || deliveryType === 'takeaway') // 堂食沒有選時段的功能
  const queryKey = generateQueryKey(shippingTime, deliveryType, merchantId, forAll)
  const queryFn = () => dimorderApi.timeslot.getDates({ shippingTime, deliveryType, merchantId, forAll })
  return useQuery({ enabled, queryKey, queryFn, staleTime })
}

/**
 *
 * @param {string} date
 * @param {TDeliveryType} deliveryType
 * @param {{ forAll?: boolean }} options
 * @returns
 */
export function useTimesQuery (date, deliveryType, options) {
  const forAll = options?.forAll ?? false
  const merchantId = useSelector(state => state.merchant.data?.id) ?? ''
  const shippingTime = useSelector(state => state.order.shipping?.shippingTime) ?? 0
  const enabled = !!date && (!!merchantId || forAll) && (deliveryType === 'storeDelivery' || deliveryType === 'takeaway') // 堂食沒有選時段的功能
  const queryKey = generateQueryKey(shippingTime, deliveryType, merchantId, forAll, date)
  const queryFn = () => dimorderApi.timeslot.getTimes({ date, shippingTime, deliveryType, merchantId, forAll })
  return useQuery({ enabled, queryKey, queryFn, staleTime })
}

/**
 *
 * @param {TDeliveryType} deliveryType
 * @param {{ forAll?: boolean }} options
 * @returns
 */
export function useDateRange (deliveryType, options) {
  const query = useDatesQuery(deliveryType, options)
  const dateRange = useMemo(() => _.map(query.data?.dates, formatToUIDate), [query.data?.dates])
  return { ...query, dateRange }
}

/**
 *
 * @param {string} date
 * @param {TDeliveryType} deliveryType
 * @param {{ forAll?: boolean }} options
 * @returns
 */
export function useTimeRange (date, deliveryType, options) {
  const query = useTimesQuery(date, deliveryType, options)
  const timeRange = useMemo(() => _.map(query.data, formatToUITime), [query.data])
  return { ...query, timeRange }
}
