import React from 'react'
import _ from 'lodash'

import SidebarItemButton from './SidebarItemButton'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

/**
 * @param {{
 * categories: IAppCategory[]
 * }} props
 * @returns
 */
export default function SidebarCategoryList (props) {
  const { categories } = props

  if (_.isEmpty(categories)) {
    return null
  }

  return (
    _.map(categories, category => (
      <SidebarItemButton
        key={category.id}
        category={category}
      />
    ))
  )
}
