import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import config from '@/config'

const isProduction = config.env === 'prod'

/**
 * 更改測試用時間 (store.app.test.time)
 * production 環境不顯示
 *
 * @param {{
 * onChange?: function
 * className?: string
 * }} props
 * @returns
 */
export default function TestTimeInput (props) {
  const { onChange = () => {}, className } = props
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const testTime = useSelector(state => state.app.test.time)
  const handleTestTimeChange = (time) => dispatch(actions.app.updateTest('time', time))

  if (isProduction) return null
  return (
    <form className={clsx(classes.form, className)}>
      <Typography
        component='label'
        color='textSecondary'
        htmlFor='testTime'
      >
        測試時間
      </Typography>
      <input
        type='time'
        id='testTime'
        value={testTime}
        onChange={(e) => {
          handleTestTimeChange(e.target.value)
          onChange()
        }}
      />
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
}))
