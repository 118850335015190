import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import FilterIcon from '@/assets/icons/Filter'

/**
 *
 * @param {*} props
 * @returns
 */
export default function FilterButton (props) {
  const history = useHistory()
  const classes = useStyles(props)
  const { onClick, disabled } = props

  return (
    <IconButton
      className={classes.filterButton}
      onClick={() => onClick ? onClick() : history.push('/filter')}
      disabled={disabled}
    >
      <FilterIcon />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  filterButton: {
    padding: theme.spacing(0.5),
  },
}))
