import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import React from 'react'

import { GroupImage } from '@/pages/CRM/HomePage'
import { actions, useDispatch, useSelector } from '@/redux'
import { useGroupQuery, useGroupRegisteration } from '@/hooks/queries/CRM/useGroupQuery'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import config from '@/config'

import DialogBase from '../DialogBase'

export default function CRMRegisterDialog (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.dialogs.crmRegister.open)
  const { data: group, isCRMMember } = useGroupQuery()
  const { mutate: register, isLoading: isRegistering } = useGroupRegisteration()

  function handleClose () {
    dispatch(actions.app.toggleDialog('crmRegister', false))
  }

  function Content (props) {
    if (isCRMMember) {
      return (
        <div>
          <div className={classes.lottieContainer}>
            <Lottie
              options={{ loop: false, animationData: require('@/assets/lotties/paid.json') }}
              width='80%'
              height='auto'
            />
          </div>
          <div className={classes.joinSuccess}>
            <Typography variant='h5' style={{ letterSpacing: 'inherit' }}>
              {t('app.page.crm.join_success')}
            </Typography>
            <Typography variant='body1' style={{ letterSpacing: 'inherit' }}>{group?.name}</Typography>
          </div>
        </div>
      )
    }
    return (
      <div>
        <Typography variant='h6' className={classes.header}>{group?.name}</Typography>
        <GroupImage style={{ aspectRatio: 1 }} />
        <Typography variant='caption'>
          {t('app.page.crm.tnc.first_line')}
          <Link href={config.links.privacy} target='_blank' rel='noreferrer' underline='always' color='textPrimary' style={{ fontSize: '1.2rem' }}>
            {t('app.component.drawer.login_drawer.terms_conditions.accept.privacy_policy')}
          </Link>
          {t('app.page.crm.tnc.second_line')}
        </Typography>
      </div>
    )
  }

  function Footer (props) {
    if (isCRMMember) {
      return (
        <div className={classes.footer}>
          <LetterSpacingButton
            fullWidth
            text={t('app.common.continue')}
            onClick={handleClose}
          />
        </div>
      )
    }

    return (
      <div className={classes.footer}>
        <LetterSpacingButton
          fullWidth
          text={isRegistering ? '' : t('app.common.accept')}
          onClick={register}
          loading={isRegistering}
        />
        <Link
          underline='always'
          color='textPrimary'
          style={{ cursor: 'pointer', letterSpacing: '0.2rem' }}
          onClick={handleClose}
        >
          {t('app.common.cancel')}
        </Link>
      </div>
    )
  }

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      title={group?.name}
      customClasses={{
        paper: classes.overridePaper,
        header: classes.overrideHeader,
      }}
      renderContent={Content}
      renderFooter={Footer}
    />
  )
}

const useStyles = makeStyles(theme => ({
  overridePaper: {
    backgroundColor: theme.palette.grey[50],
  },
  overrideHeader: {
    display: 'none',
  },
  header: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  footer: {
    display: 'grid',
    placeItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(1),
  },
  lottieContainer: {
    display: 'grid',
    placeItems: 'center',
    width: 300,
    height: 200,
    overflow: 'hidden',
  },
  joinSuccess: {
    display: 'grid',
    gap: theme.spacing(2),
    textAlign: 'center',
    letterSpacing: '0.2rem',
  },
}))
