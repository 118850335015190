import { MENU_ITEM_IMAGE, transformation } from '@/libs/cloudinary'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import constants from '@/constants'

MenuImage.propTypes = {
  src: PropTypes.string,
}

/**
 *
 * @param {{
 * src: string
 * }} props
 * @returns
 */
export default function MenuImage (props) {
  const { src } = props
  const thumbnailUrl = transformation(src, { width: MENU_ITEM_IMAGE.sm.width }) || constants.app.image.EMPTY_IMAGE
  const imageUrl = transformation(src, { width: MENU_ITEM_IMAGE.lg.width }) || constants.app.image.EMPTY_IMAGE
  const [isLoaded, setIsLoaded] = useState(false)
  const classes = useStyles({ ...props, isLoaded })

  return (
    <div>
      <img
        src={thumbnailUrl}
        style={{ display: isLoaded && 'none' }}
        className={classes.thumbnail}
      />
      <img
        src={imageUrl}
        style={{ display: !isLoaded && 'none' }}
        className={classes.image}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  '@keyframes blur_in': {
    from: { filter: 'blur(3px)' },
    to: { filter: 'blur(0px)' },
  },
  image: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[100],
    animationName: '$blur_in',
    animation: '500ms 1 ease',
  },
  thumbnail: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[100],
    filter: 'blur(3px)',
  },
}))
