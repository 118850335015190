import { Button, ButtonBase, CircularProgress, Typography } from '@material-ui/core'
import { Capacitor } from '@capacitor/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import DeliveryType from '@/constants/deliveryType'
import Merchant from '@/constants/merchant'
import PaymentMethod from '@/constants/paymentMethod'
import PaymentMethodCard from '@/components/Cards/PaymentMethodCard'
import colors from '@/theme/colors'

/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppOrder} IAppOrder */
/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppPayment} IAppPayment */

// 非自定義的付款方式
const DEFAULT_PAYMENT_METHODS = new Set(Object.values(PaymentMethod))

const WhatsappBtn = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#4dc247',
    '&:hover': {
      backgroundColor: '#37da2f',
    },
  },
}))(Button)

/**
 * 第三方付款時顯示 payment 在 OrderTracking
 * 優先顯示 paid payment，再來是自己的最後一筆 pending payment，如果自己沒有就顯示最後一筆 pending payment
 *
 * @typedef PayingCardProps
 * @property {IAppOrder} order
 * @param {PayingCardProps} props
 * @returns
 */
export default function PayingCard (props) {
  const { order } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const merchant = useSelector(state => state.merchant?.data)
  const merchantId = useSelector(state => state.merchant?.data?.id)
  const payFirst = useSelector(state => state.merchant?.data?.setting?.payFirst)
  const enableDineInCustomerPay = useSelector(state => state.merchant?.data?.setting?.enableDineInCustomerPay)
  const { isTable } = useDeliveryType()
  const isTrackingGlobalOrder = useSelector(state => state.orderHistory.isTrackingGlobalOrder)
  const platform = Capacitor.getPlatform()

  /**
   *
   * @param {() => void} openDrawerCallback
   */
  async function handleProcessingPaymentClicked (openDrawerCallback) {
    dispatch(actions.order.selectOrder(order))

    if (isTrackingGlobalOrder) {
      // 這裡只會有內用的情況，因為外帶外送下單一定有登入
      // 處理當堂食訂付款失敗(PaymentHelpButton) 或 處理當堂食訂尚未付款時按下選擇付款方式(WaitingPaymentSection)
      // 會先嘗試加入訂單，若加入訂單失敗則用 openReturnPrevBrowserAlert 引導使用者回到之前的瀏覽器或重新登入
      try {
        await dispatch(actions.order.joinOrder())
        if (typeof openDrawerCallback === 'function') openDrawerCallback()
      } catch (error) {
        // 加入訂單失敗時不能直接選擇付款方式，請他回到之前的瀏覽器開啟訂單追蹤
        dispatch(actions.orderHistory.openReturnPrevBrowserAlert())
        // 因為無法加入訂單，不打開 Drawer
      }
    } else {
      if (typeof openDrawerCallback === 'function') openDrawerCallback()
    }
  }

  /**
   * 選擇付款方式
   * @returns
   */
  function WaitingPaymentSection () {
    const handleOpenDrawer = () => { dispatch(actions.app.toggleDrawer('paymentMethod', true)) } // 打開選擇付款方式的 Drawer
    const handleClick = () => handleProcessingPaymentClicked(handleOpenDrawer)
    return (
      <PaymentMethodCard
        titleText={t('app.page.setting_payment.chose_payment_method')}
        renderRight={() => <KeyboardArrowRightIcon />}
        onClick={handleClick}
      />
    )
  }

  /**
   * 付款處理中
   * @typedef ProcessingPaymentSectionProps
   * @property {IAppPayment} payment
   * @param {ProcessingPaymentSectionProps} props
   * @returns
   */
  function ProcessingPaymentSection (props) {
    const { payment } = props

    function WhatsAppButton () {
      if (order.deliveryType !== DeliveryType.TABLE) return null
      const handleClick = () => { window.open(`https://wa.me/85268262032?text=訂單編號:${order.serial}`) }
      return (
        <WhatsappBtn onClick={handleClick}>
          <Typography variant='button' className={classes.dineinHelpText}>
            <WhatsAppIcon />
            {t('app.page.order_tracking.buttons.dinein_payment_need_help')}
          </Typography>
        </WhatsappBtn>
      )
    }

    function PaymentHelpButton () {
      const handleOpenDrawer = () => { dispatch(actions.app.toggleDrawer('paymentHelp', true)) } // 打開 Payment Help Drawer
      const handleClick = () => handleProcessingPaymentClicked(handleOpenDrawer)
      return (
        <ButtonBase className={classes.helpButton} onClick={handleClick}>
          <Typography variant='button' className={classes.helpText}>
            {t('app.page.order_tracking.buttons.payment_need_help')}
          </Typography>
        </ButtonBase>
      )
    }

    if (!payment) return null

    return (
      <>
        <PaymentMethodCard
          paymentMethod={payment.paymentMethod}
          renderRight={() => (
            <div className={classes.paymentStatus}>
              <Typography className={classes.paymentStatusText}>{t('app.page.order_tracking.payment.processing')}</Typography>
              <CircularProgress size={16} />
            </div>
          )}
        />
        <WhatsAppButton />
        <PaymentHelpButton />
      </>
    )
  }

  function ContinuePaymentSection () {
    // 最後能不能付款 = order.status 是 pending 和 payments 中沒有成功的付款 和 有開堂食付款
    const canCAPay = (
      order.status === 'pending' &&
        !order.payments.some(payment => payment.status === 'paid') &&
        !(isTable && !enableDineInCustomerPay)
    )

    if (!canCAPay) return null

    // 找最後一筆 pendingPayment
    const lastPendingPayment = _.findLast(order.payments, payment => payment.status === 'pending')
    const canAcceptNoPendingPayment = isTable && payFirst && [Merchant.SOUL_CAFE, Merchant.明輝茶餐廳].includes(merchantId) // TODO: rmb to remove this, 這家餐廳 payFirst 允許內用沒選 paymentMethod = 店內付款

    // CA 還能付款
    if (lastPendingPayment) {
      // 已經有 pending payment，顯示最後一筆 pendingPayment
      return <ProcessingPaymentSection payment={lastPendingPayment} />
    } else {
      // 沒有 pending payment，顯示選擇付款方式
      return canAcceptNoPendingPayment
        ? <PaymentMethodCard displayText={t('app.constant.paymentMethod.payInStore')} />
        : <WaitingPaymentSection />
    }
  }

  /**
   *
   * @param {IAppPayment} payment
   * @returns
   */
  function getPaymentMethodName (payment) {
    const isDefaultPaymentMethod = DEFAULT_PAYMENT_METHODS.has(payment.paymentMethod)
    if (isDefaultPaymentMethod) return t(`app.constant.paymentMethod.${payment.paymentMethod}`)
    const customizedPaymentMethodName = _.find(merchant?.payments, merchantPayment => (merchantPayment.key === payment.paymentMethod))?.name
    if (customizedPaymentMethodName) return customizedPaymentMethodName
    return t('app.constant.paymentMethod.payInStore')
  }

  /**
   * 顯示付款狀態與付款金額
   * @param {IAppPayment} payment
   * @returns
   */
  function renderCardRight (payment) {
    const isFailedPayment = payment.status === 'cancel' && !payment.paidAt
    const statusText = isFailedPayment
      ? t('app.page.order_tracking.payment.failed')
      : t(`app.page.order_tracking.payment.${payment.status}`)
    const paymentStatusText = isFailedPayment
      ? statusText // 付款失敗不用顯示金額
      : statusText + ' $' + payment.paidAmount
    return (
      <div className={classes.paymentStatus}>
        <Typography className={classes.paymentStatusText}>{paymentStatusText}</Typography>
        {payment.status === 'paid' && <CheckCircleIcon style={{ color: colors.success.main }} />}
        {payment.status === 'cancel' && <CancelIcon style={{ color: colors.error.main }} />}
      </div>
    )
  }

  /**
   * 顯示『已付』或是『退款』或是『付款失敗』的 payment methods
   * @returns
   */
  function PaymentMethodCards () {
    const filteredPayments = _.filter(order.payments, payment => payment.paidAt || payment.status === 'cancel')
    return _.map(filteredPayments, payment => {
      const paymentMethodName = getPaymentMethodName(payment)
      return (
        <PaymentMethodCard
          key={payment.id}
          displayText={paymentMethodName}
          paymentMethod={payment.paymentMethod}
          cardProvider={payment.cardType}
          renderRight={() => renderCardRight(payment)}
        />
      )
    })
  }

  if (!order) return null

  // 只限 app 付款時隱藏 (Android 除外，因為 Android App 很卡不特別推使用 App)
  if (
    isTable && // 堂食
    merchant?.setting?.dineInPayOnAppOnly && // merchant 設定限 App 付款
    platform === 'web' && // 現在在 web
    !navigator.userAgent.includes('Android') // Android 除外
  ) {
    return null
  }

  return (
    <>
      <PaymentMethodCards />
      <ContinuePaymentSection />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  notSelectedCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    minHeight: 40,
  },
  paymentStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  dineinHelpText: {
    display: 'flex',
    fontSize: '1.8rem',
    alignItems: 'center',
  },
  helpButton: {
    flexShrink: 0,
    borderRadius: theme.spacing(1),
    color: theme.palette.info.main,
  },
}))
