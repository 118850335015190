import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

import QuantityLabel from './QuantityLabel'

/**
 *
 * @param  {IFooterSubmitButtonProps} props
 */
export default function SubmitButton (props) {
  const {
    title,
    subtitle,
    onClick,
    disabled = false,
    quantity = 0,
    isLoading = false,
    hideQuantityLabel = false,
    className = {},
    ...rest
  } = props
  const classes = useStyles(props)

  return (
    <Button
      component='div' // warning: <button> cannot appear as a descendant of <button>. 外層有新要求要連左邊一起點所以包了一個 ButtonBase
      color='primary'
      variant='contained'
      className={clsx(classes.submitButton, className)}
      disabled={disabled}
      disableElevation
      onClick={onClick}
      {...rest}
    >
      <div className={classes.titleWrap}>
        <Typography className={classes.title}>{title}</Typography>
        {Boolean(subtitle) && <Typography variant='caption'>{subtitle}</Typography>}
      </div>
      {isLoading && (
        <div className={classes.loadingWrap}>
          <CircularProgress size={24} />
        </div>
      )}
      <QuantityLabel quantity={quantity} hidden={hideQuantityLabel} />
    </Button>

  )
}

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: 'flex',
    padding: theme.spacing(1),
    alignSelf: 'stretch',
    minWidth: 100,
    width: '100%',
    borderRadius: 0,
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 9,
  },
  title: {
    fontSize: '1.8rem',
  },
  loadingWrap: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
}))
