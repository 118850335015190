import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'
import clsx from 'clsx'

import { ReactComponent as ArrowBackIcon } from '@/assets/icons/lamaIcons/arrow_back.svg'
import { scrollToCategory } from '@/libs/scroll'

import SidebarCategoryButton from '@/pages/CustomizedRestaurants/LaMaMenu/Sidebar/SidebarCategoryButton'
import SidebarCategoryList from './SidebarCategoryList'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

/**
 * @param {{
 * category: IAppCategory
 * isBackButton?: boolean
 * isSkeleton?: boolean
 * }} props
 * @returns
 */
export default function SidebarItemButton (props) {
  const { category = {}, isBackButton, isSkeleton } = props
  const classes = useStyles(props)
  const history = useHistory()

  if (isSkeleton) {
    return (
      <div className={clsx(classes.sidbarItem)}>
        <Skeleton
          className={classes.skeletonIcon}
          variant='rect'
          width={40}
          height={40}
        />
        <Skeleton
          className={classes.name}
          variant='text'
          width={48}
          height={18}
        />
      </div>
    )
  }

  const handleClick = React.useCallback(() => {
    if (isBackButton) {
      history.goBack()
      return
    }
    scrollToCategory(category, 'pageContainer', { offset: -64 })
  }, [isBackButton])

  return (
    <>
      <div
        className={clsx(classes.sidbarItem)}
        onClick={handleClick}
      >
        {
          isBackButton
            ? <ArrowBackIcon className={classes.backButton} />
            : (
              <>
                <SidebarCategoryButton category={category} />
                <Typography className={classes.name} variant='body1'>{category.name}</Typography>
              </>
            )
        }
      </div>

      {/* Sub Categories */}
      <SidebarCategoryList categories={category.categories} />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  sidbarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 90,
    marginTop: 16,
    marginBottom: 16,
    cursor: 'pointer',
  },
  skeletonIcon: {
    width: 40,
    height: 40,
    borderRadius: 10,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
  name: {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: theme.palette.text.primary.light,
    padding: 4,
  },
  backButton: {
    width: 30,
    height: 30,
  },
}))
