import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

/**
 * @typedef DeliveryFeeTagProps
 * @property {number | null} [amount = null]
 * @property {boolean} [hidden = false]
 * @param {DeliveryFeeTagProps} props
 * @returns
 */
export default function DeliveryFeeTag (props) {
  const { amount = null, hidden = false } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  if (hidden) return null
  if (amount == null) return null
  return (
    <div className={classes.tagWrap}>
      <Typography variant='caption' className={classes.bold}>
        {
          amount === 0
            ? t('app.common.shipping.free_shipping')
            : t('app.common.shipping.shipping_fee', { amount })
        }
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  tagWrap: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    padding: theme.spacing(0.2, 1),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[25],
  },
  bold: {
    fontWeight: '400',
  },
}))
