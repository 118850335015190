import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import React from 'react'

CollapseIconButton.propTypes = {
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * onClick: Function
 * }} props
 */
export default function CollapseIconButton (props) {
  const { onClick } = props
  const classes = useStyles(props)

  return (
    <IconButton
      className={classes.collapseButton}
      onClick={onClick}
    >
      <KeyboardArrowUpIcon fontSize='inherit' color='secondary' classes={{ colorSecondary: classes.colorSecondary }} />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  collapseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '2rem',
    padding: theme.spacing(1),
  },
  colorSecondary: {
    color: theme.palette.text.secondary,
  },
}))
