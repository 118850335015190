const districtOptions = {
  // 香港島 Hong Kong
  hong_kong_region: [
    'centraland_western_district', // 中西區 Central and Western District
    'wan_chai_district', // 灣仔區 Wan Chai District
    'eastern_district', // 東區 Eastern District
    'southern_district', // 南區 Southern District
  ],
  // 九龍 Kowloon
  kowloon_region: [
    'sham_shui_po_district', // 深水埗區 Sham Shui Po District
    'yau_tsim_mong_district', // 油尖旺區 Yau Tsim Mong District
    'kowloon_city_district', // 九龍城區 Kowloon City District
    'wong_tai_sin_district', // 黃大仙區 Wong Tai Sin District
    'kwun_tong_district', // 觀塘區 Kwun Tong District
  ],
  // 新界 New Territories
  new_territories_region: [
    'tsuen_wan_district', // 荃灣區 Tsuen Wan District
    'kwai_tsing_district', // 葵青區 Kwai Tsing District
    'islands_district', // 離島區 Islands District
    'tuen_mun_district', // 屯門區 Tuen Mun District
    'yuen_long_district', // 元朗區 Yuen Long District
    'north_district', // 北區 North District
    'tai_po_district', // 大埔區 Tai Po District
    'sha_tin_district', // 沙田區 Sha Tin District
    'sai_kung_district', // 西貢區 Sai Kung District
  ],
}

export default districtOptions
