import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

/**
 *
 * @param {{
 * renderIcon: () => JSX.Element
 * title: string
 * subtitle: string
 * action: string
 * onClick: () => void
 * }} props
 * @returns
 */
export default function RedirectView (props) {
  const classes = useStyles(props)
  const { renderIcon, title, subtitle, action, onClick } = props

  return (
    <div className={classes.container}>
      {renderIcon()}

      <div className={classes.info}>
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>

      <LetterSpacingButton
        fullWidth
        text={action}
        onClick={onClick}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '60%',
    margin: 'auto',
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginBottom: theme.spacing(4),
    },
    '&>:last-child': {
      marginBottom: 0,
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '1.4rem',
    opacity: '50%',
  },
}))
