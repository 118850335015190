import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'

import Dimbox from './Dimbox'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 *
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function DimboxList (props) {
  const { order } = props
  const classes = useStyles(props)
  const dimboxes = useSelector(state => state.dimbox.dimboxes)
  const orderBoxes = React.useMemo(() => _.filter(dimboxes, box => {
    /** @type {IDimboxStatus[]} */
    const bookedStatus = ['book', 'not_box'] // 當天和隔天成功下訂的格子
    return box?.orderId === order.id && _.includes(bookedStatus, box?.status)
  }), [dimboxes, order.id])
  const cabinetId = orderBoxes[0]?.cabinetId

  return (
    <ul className={classes.dimboxList}>
      {_.map(orderBoxes, (box, index) => <Dimbox key={index} index={index} cabinetId={cabinetId} box={box} order={order} />)}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  dimboxList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}))
