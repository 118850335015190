import { Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import OrderCancelledIcon from '@/assets/icons/order_cancelled.svg'

export default function OrderCancelledDialog (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { open, title, onConfirm } = props

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.rootPaper }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <header className={classes.header}>
        <img src={OrderCancelledIcon} alt='order_cancelled_icon' className={classes.image} />
      </header>
      <section className={classes.content}>
        <Typography variant='h6' className={classes.title}>{title}</Typography>
        <Typography variant='body1' className={classes.message}> {t('app.page.order_tracking.cancelled_dialog.message')}</Typography>
      </section>

      <LetterSpacingButton
        fullWidth
        text={t('app.common.confirm')}
        onClick={onConfirm}
      />
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  rootPaper: {
    minWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  header: {
    display: 'grid',
    placeItems: 'center',
    minHeight: 140,
    width: '100%',
    marginTop: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  image: {
    fontSize: '10rem',
    color: theme.palette.secondary.light,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  message: {
    color: theme.palette.grey[600],
  },
}))
