import config from '@/config'

export function createFiservForm (data) {
  const form = document.createElement('form')
  form.action = config.fiserv.connectUrl
  form.method = 'POST'
  form.target = '_self'
  Object.keys(data)
    .forEach((key) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = data[key]
      form.appendChild(input)
    })
  document.body.appendChild(form)

  return form
}
