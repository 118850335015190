import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { formatRange } from '@/libs/promoCode'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import useShortLang from '@/hooks/useShortLang'

import DialogBase from './DialogBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CouponTncDialog (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles(props)
  const lang = useShortLang()
  const open = useSelector(state => state.app.dialogs.couponTnc.open)
  const coupon = useSelector(state => state.app.dialogs.couponTnc.data)

  const {
    name,
    tnc,
    tncUrl,
    applyTo,
    amount,
    percent,
    maxAmount,
    minOrderAmount,
    deliveryType,
  } = coupon
  const { validFrom, validUntil } = formatRange(coupon.validFrom, coupon.validUntil)

  const handleClose = () => dispatch(actions.app.toggleDialog('couponTnc', false, {}))

  return open && (
    <DialogBase
      title={t('app.component.dialog.coupon_dialog.tnc_title')}
      open={open}
      onClose={handleClose}
      renderContent={() => (
        <div className={classes.contain}>
          <div className={classes.row}>
            <Typography className='title'>
              {name && name[lang]}
            </Typography>
            <Typography className='desc'>
              {
                applyTo === 'ALL'
                  ? t('app.component.dialog.coupon_dialog.apply_to_all')
                  : applyTo === 'PRODUCT'
                    ? t('app.component.dialog.coupon_dialog.apply_to_product')
                    : applyTo === 'SHIPPING'
                      ? t('app.component.dialog.coupon_dialog.apply_to_shipping')
                      : ''
              }
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography className='title'>
              {t('app.component.alert.coupon_card.expiration')}
            </Typography>
            <Typography className='desc'>
              {validFrom} ~ {validUntil}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography className='title'>
              {t('app.page.checkout.terms_and_conditions.link')}
            </Typography>
            <Typography className='desc'>
              {tnc && tnc[lang]}
            </Typography>
            {
              tncUrl &&
                <Link
                  className={classes.link}
                  target='_blank'
                  variant='body2'
                  onClick={() => window.open(tncUrl)}
                >
                  {t('app.page.checkout.terms_and_conditions.link')}
                </Link>
            }
          </div>
          <div className={classes.row}>
            <Typography className='title'>
              {t('app.component.dialog.coupon_dialog.coupon_rule')}
            </Typography>
            {amount > 0 && <Typography className='desc'>{t('app.component.dialog.coupon_dialog.coupon_amount_rule', { amount })}</Typography>}
            {percent > 0 && <Typography className='desc'>{t('app.component.dialog.coupon_dialog.coupon_percent_rule')}: {percent}%</Typography>}
            {maxAmount > 0 && <Typography className='desc'>{t('app.component.dialog.coupon_dialog.coupon_max_amount_rule', { maxAmount })}</Typography>}
            {minOrderAmount > 0 && <Typography className='desc'>{t('app.component.dialog.coupon_dialog.coupon_min_order_amount_rule')}: {minOrderAmount}</Typography>}
            {deliveryType && <Typography className='desc'>{t('app.component.dialog.coupon_dialog.coupon_delivery_type_rule')}: {t(`app.constant.deliveryType.${deliveryType}`)}</Typography>}
          </div>
        </div>
      )}
      renderFooter={() => (
        <LetterSpacingButton
          text={t('app.common.confirm')}
          onClick={handleClose}
        />
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  contain: {
  },
  row: {
    marginBottom: theme.spacing(1),

    '& > .title': {
      fontSize: 14,
      fontWeight: 'bold',
    },

    '& > .desc': {
      fontSize: 12,
    },
  },
}))
