
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import DialogBase from '@/components/Dialogs/DialogBase'

import AvailableCouponList from './AvailableCouponList'
import AvailablePromoCodeList from './AvailablePromoCodeList'
import PromoCodeInput from './PromoCodeInput'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PromoCodeDialog (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.dialogs.promoCode.open)

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('promoCode'))
  }

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      title={t('app.component.dialog.promo_dialog.title')}
      customClasses={{ paper: classes.paper, content: classes.content }}
      renderContent={() => (
        <div className={classes.container}>
          <PromoCodeInput />
          <AvailablePromoCodeList />
          <AvailableCouponList />
        </div>
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  paper: {
    margin: 0,
  },
  content: {
    display: 'block',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))
