import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @typedef QuantityLabelProps
 * @property {number} quantity
 * @property {boolean} [hidden]
 * @param {QuantityLabelProps} props
 */
export default function QuantityLabel (props) {
  const { quantity, hidden } = props
  const classes = useStyles(props)

  if (hidden) return null
  if (!quantity) return null

  return (
    <span className={classes.quantityLabel}>{quantity}</span>
  )
}

const useStyles = makeStyles(theme => ({
  quantityLabel: {
    position: 'fixed',
    right: theme.spacing(4),
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    fontSize: '1.6rem',
    display: 'grid',
    placeContent: 'center',
    borderRadius: 14,
    minWidth: 28,
    height: 28,
    fontWeight: 'bold',
    padding: theme.spacing(1),
    margin: theme.spacing('auto', 0.5),
  },
}))
