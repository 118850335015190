import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

ExpandButton.propTypes = {
  onClick: PropTypes.func,
  quantity: PropTypes.number,

}

/**
 *
 * @param {{
 * onClick: Funcion,
 * quantity: Number
 * }} props
 */
export default function ExpandButton (props) {
  const { t } = useTranslation()
  const { onClick, quantity } = props
  const classes = useStyles(props)

  return (
    <Button
      className={classes.expandButton}
      onClick={onClick}
    >
      <Typography color='textSecondary'>
        {t('app.component.order_batch_item.set_details', { quantity })}
      </Typography>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  expandButton: {
    fontSize: '1.4rem',
    padding: theme.spacing(0.5, 0),
    '& p': {
      fontSize: 'inherit',
    },
  },
}))
