import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import constants from '@/constants'

import { MENU_ITEM_IMAGE, transformation } from '@/libs/cloudinary'
import { checkIsSold, getMenuImage } from '@/libs/menu'
import { useMerchant } from '@/hooks/merchant'
import PriceLabel from '@/components/PriceLabel'

import OrderButton from './OrderButton'
import OrderLayer from './OrderLayer'
import SoldLayer from './SoldLayer'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 * props 有傳 setItemMenu 的情況是套餐被指定步驟（預設第一步）拆開的情況
 * 當有 setItemMenu 時應顯示該 setItem 的 name, desc, image, inventory(sold)
 *
 * @param {{
 * menu: IAppMenuItem | IAppSet
 * setItemMenu?: IAppMenuItem,
 * }} props
 */
export default function MenuItem (props) {
  const { menu, setItemMenu } = props
  const classes = useStyles({ props })
  const _menu = setItemMenu ?? menu
  const name = _menu?.name?.replace(/\u00A0/g, ' ') // production 8c80423a-e540-4d64-b1b3-4b1962db63eb 餐廳的設定餐點名稱內含有 U+00a0 (&nbsp;) 會造成破版，取代為空格
  const desc = _menu?.desc
  const merchant = useMerchant()
  const isSold = checkIsSold({ merchant, menu, setItemMenu })
  const isSet = _.size(_menu.steps) > 0
  const imageUrl = transformation(getMenuImage(_menu), { width: MENU_ITEM_IMAGE.md.width }) || constants.app.image.EMPTY_IMAGE

  const PriceLabelComponent = React.useMemo(() => {
    if (setItemMenu) {
      // 分拆的套餐
      const priceTotal = (menu.price ?? 0) + (setItemMenu.price ?? 0)
      const discountTotal = (menu.discount ?? 0) + (setItemMenu.discount ?? 0)
      return (
        <PriceLabel
          menu={_menu}
          price={priceTotal}
          discount={discountTotal}
          isSeparated // 顯示前綴：套餐
          suffix // 顯示後綴：起
          color='#fafafa'
        />
      )
    }

    if (isSet) {
      // 套餐（分拆的步驟為複選時不可被分拆）
      // 複選的情況下該步驟不包含選項的最小總額
      const firstStep = _menu.steps[0]
      const firstStepMenus = _.filter(_menu.menus, menu => menu.step === firstStep?.id)
      const sorted = _
        .chain(firstStepMenus)
        .map(menu => ({ ...menu, total: (menu.price ?? 0) - (menu.discount ?? 0) }))
        .orderBy(['required', 'total'], ['desc', 'asc']) // 將折扣後的價格從小排到大，必選的排在最前面
        .value()
      const items = []
      for (const menu of sorted) {
        if (items.length >= (firstStep.min || 1)) {
          // 當已達步驟最少需選數量時
          break
        }
        const max = menu.max || firstStep.max || constants.app.stepper.MAX
        _.times(max, () => {
          if (!(items.length >= (firstStep.min || 1))) {
            // 當未達步驟最少需選數量時
            items.push({ price: menu.price ?? 0, discount: menu.discount ?? 0 })
          }
        })
      }
      // 計算第一步最少需點多少元起
      const priceTotal = (menu.price ?? 0) + _.sumBy(items, 'price')
      const discountTotal = (menu.discount ?? 0) + _.sumBy(items, 'discount')

      return (
        <PriceLabel
          menu={_menu}
          price={priceTotal}
          discount={discountTotal}
          suffix// 顯示後綴：起
          color='#fafafa'
        />
      )
    }

    return <PriceLabel menu={_menu} color='#fafafa' />
  }, [menu, setItemMenu])

  return (
    <li
      className={classes.card}
      key={menu.id}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className={classes.header}>
        <Typography className={classes.name}>
          {name}
        </Typography>
        <Typography className={classes.desc}>
          {desc}
        </Typography>
      </div>
      <div className={classes.footer}>
        {PriceLabelComponent}
        <OrderButton menu={menu} setItemMenu={setItemMenu} isSold={isSold} />
      </div>
      <OrderLayer menu={menu} setItemMenu={setItemMenu} isSold={isSold} />
      <SoldLayer isSold={isSold} />
    </li>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 220,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: theme.shape.borderRadius * 2,
    color: '#fafafa',
    overflow: 'hidden',
    margin: 8,
  },
  header: {
    padding: 16,
    background: 'linear-gradient(180deg, #00000080,  #00000000)',
  },
  name: {
    fontSize: '2rem',
    fontWeight: 900,
  },
  desc: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2, /* number of lines to show */
    '-webkit-box-orient': 'vertical',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    background: 'linear-gradient(0deg, #00000080,  #00000000)',
  },
  price: {
    '&:before': {
      content: '"HK$"',
      marginRight: 2,
    },
  },
}))
