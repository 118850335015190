import { CircularProgress, Typography } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import AppConstants from '@/constants/app'
import PaymentMethodCard from '@/components/Cards/PaymentMethodCard'
import SwipeableDrawer from '@/components/SwipeableDrawer'

export default function PaymentHelpDrawer (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { open } = useSelector(state => state.app.drawers.paymentHelp)
  const orderId = useSelector(state => state.orderHistory.selectedOrderId)
  const order = useSelector(state => state.orderHistory.orders.find(order => order.id === orderId))

  const closeDrawer = () => {
    dispatch(actions.app.toggleDrawer('paymentHelp', false))
  }

  if (!order) return null

  return (
    <SwipeableDrawer
      open={open}
      onOpen={() => {
        // 沒有滑出來的控制方式，所以不需要設定 onOpen，但是 onOpen isRequired in material ui
      }}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <Typography className={classes.title}>
        {t('app.page.order_tracking.payment_help_drawer.title')}
      </Typography>
      <PaymentMethodCard
        titleText={t('app.page.order_tracking.payment_help_drawer.paid')}
        onClick={() => {
          dispatch(actions.app.toggleAlert({
            children: (
              <div className={classes.checkingPaymentDialogContent}>
                <CircularProgress size={24} />
                <Typography variant='body1'>
                  {t('app.page.order_tracking.checking_order_payment_alert.message')}
                </Typography>
              </div>
            ),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: t('app.common.cancel'),
              onClick: () => {
                // 關閉 alert
                dispatch(actions.app.toggleAlert(null, order.id, false))
              },
            },
          }, order.id))
          closeDrawer()
        }}
      />
      <PaymentMethodCard
        titleText={t('app.page.order_tracking.payment_help_drawer.select_payment_method')}
        onClick={() => {
          dispatch(actions.app.toggleDrawer('paymentMethod', true))
          closeDrawer()
        }}
      />
      <PaymentMethodCard
        titleText={t('app.page.order_tracking.payment_help_drawer.order_help')}
        onClick={() => {
          window.open(`https://wa.me/85268262032?text=訂單編號:${order.serial}`)
          closeDrawer()
        }}
      />
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(2),
    paddingBottom: `calc(${theme.spacing(2)}px + ${AppConstants.safeArea.bottom})`,
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& > :first-child': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: '1.6rem',
  },
  checkingPaymentDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  paymentImage: {
    height: 18,
  },
}))
