import { ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import i18next from 'i18next'
import moment from 'moment'

import DeliveryTypeLabel from '@/components/DeliveryTypeLabel'
import RiceCoinRatioLabel from '@/components/RiceCoinRatioLabel'

const getMemo = (memo) => {
  if (!memo) return

  const i18nKey = `app.page.riceCoin.memo.${memo}`
  const i18nExists = i18next.exists(i18nKey)
  if (i18nExists) {
    return i18next.t(i18nKey)
  }

  return memo
}

/**
 *
 * @param {{
 * ledger: CustomerApiLedger
 * }} props
 * @returns
 */
export default function TransactionListItem (props) {
  const classes = useStyles(props)
  const { style, ledger, divider } = props
  const { t } = useTranslation()

  const memo = getMemo(ledger?.memo)

  if (!ledger) {
    return (
      <ListItem style={style} divider={divider} className={classes.container}>
        <Typography variant='body1' className={classes.loading}>
          loading...
        </Typography>
      </ListItem>
    )
  }

  const desc = ledger.merchantName && memo
    ? `${ledger.merchantName} ${memo}` // 同時有 merchantName 和 memo 就兩個都顯示
    : ledger.merchantName || memo || '' // 有哪個顯示哪個

  return (
    <ListItem className={classes.container} style={style} divider={divider}>
      <div className={classes.mainContent}>
        <div className={classes.restaurant}>
          <DeliveryTypeLabel
            className={classes.label}
            deliveryType={ledger.deliveryType}
          />
          <RiceCoinRatioLabel
            className={classes.label}
            ratio={ledger.ratio}
          />
          <Typography variant='body1' className={classes.desc}>{desc}</Typography>
        </div>
        <Typography variant='caption' color='textPrimary'>
          {moment(ledger.createdAt).format(t('app.common.date_time.format_year_date_time'))}
        </Typography>
      </div>

      <Typography variant='body1' className={classes.textBold}>
        {ledger.amount > 0 && '+'}{String(ledger.amount)}
      </Typography>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: 60,
    backgroundColor: theme.palette.common.white,
  },
  loading: {
    flex: 1,
    textAlign: 'center',
  },
  mainContent: {
    flex: 1,
  },
  restaurant: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing(1),
  },
  desc: {
    fontWeight: 'bold',
    flexWrap: 'wrap',
  },
}))
