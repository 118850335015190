import { actions, useDispatch } from '@/redux'
import { updateMerchantPromocodes } from '@/redux/landing/actions'
import { useDeliveryType } from '../app'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import constants from '@/constants'
import landingApi from '@/libs/api/landing'
import useTrack from '../useTrack'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType

export function useLandingSettingsQueryOptions () {
  const dispatch = useDispatch()
  const queryKey = ['landing', 'settings']
  const queryFn = () => fetchFn()
  async function fetchFn () {
    const data = await landingApi.fetchSpreadSheet()
    return data
  }
  const staleTime = 3 * 60 * 1000 // 3 mins landing 的設定不應會太頻繁的改變
  const onSuccess = (data) => {
    dispatch(updateMerchantPromocodes(parseMerchantPromocodes(data.merchantPromocodes)))
  }
  return { queryKey, queryFn, staleTime, onSuccess }
}

export function useLandingSettingsQuery () {
  const options = useLandingSettingsQueryOptions()
  const query = useQuery(options)
  return query
}

/**
 * 進餐廳列表時的蓋版廣告
 * @returns
 */
export function useSplashAd () {
  const { data } = useLandingSettingsQuery()
  const splashAd = data?.splashes?.[0] // 僅顯示 splashes 的第一張
  return splashAd
}

/**
 * 熱門搜尋單字 TrendingTags
 * @returns
 */
export function useTrendingTags () {
  const { data } = useLandingSettingsQuery()
  return data?.trendingTags ?? []
}

/**
 * 餐廳 Filters
 * @returns
 */
export function useRestaurantFilters () {
  const { data } = useLandingSettingsQuery()
  return data?.filters ?? []
}

/**
 * 活動 Banners
 * @returns
 */
export function useBanners () {
  const { sendEvent } = useTrack()
  const dispatch = useDispatch()
  const { data } = useLandingSettingsQuery()
  const banners = data?.banners ?? []

  /**
   * @function
   * @param {TLandingAction} action
   * @param {string} payload
   * @returns {function}
   */
  function handleBannerAction (action, payload) {
    sendEvent('bannerClick', { event_category: `${action}/${payload}` })
    dispatch(actions.app.handleMarketingAction(action, payload))
  }

  return _
    .chain(banners)
    .map(banner => {
      const { bannerUrl, action, payload, type } = banner
      switch (type) {
        case 'IMAGE':
          return createBannerItem({
            info: banner,
            image: bannerUrl,
            onClick: () => handleBannerAction(action, payload),
          })

        case 'VIDEO':
          return createBannerItem({
            info: banner,
            video: bannerUrl,
            onClick: () => handleBannerAction(action, payload),
          })

        default:
          return undefined
      }
    })
    .without(undefined)
    .value()
}

// 算 aspect ratio 用的
const DEFAULT_BANNER_SIZE = { width: 375, height: 146 }

/**
 * @typedef {object} IBannerItemConfig
 * @property {string} image
 * @property {string} video
 * @property {nubmer} width
 * @property {number} height
 * @property {function} onClick
 * @param {IBannerItemConfig} params
 * @returns {IBannerItem}
 */
function createBannerItem ({ image, video, info, onClick, width = DEFAULT_BANNER_SIZE.width, height = DEFAULT_BANNER_SIZE.height }) {
  const banner = {}
  if (image) { _.set(banner, 'image', { url: image, width, height }) }
  if (video) { _.set(banner, 'video', { url: video, width, height }) }
  return { ...banner, info, onClick }
}

/**
 *
 * @returns
 */
export function useMerchantPromocodes () {
  const { data } = useLandingSettingsQuery()
  const originalData = data?.merchantPromocodes ?? []
  const parsedData = parseMerchantPromocodes(originalData)
  return parsedData
}

/**
 *
 * @param {string} merchantId
 * @returns {string}
 */
export function useMerchantPromocodeBannerText (merchantId) {
  const { deliveryType } = useDeliveryType()
  const merchantPromocodes = useMerchantPromocodes()
  const merchantPromocode = _.find(merchantPromocodes, p => String(p.id) === merchantId)
  const bannerText = merchantPromocode?.bannerText?.[deliveryType] ?? ''
  return bannerText
}

/**
  * @param {IApiMerchantPromocode[]} merchantPromocodes
  * @returns {IAppMerchantPromocode[]}
  */
function parseMerchantPromocodes (merchantPromocodes) {
  if (_.isEmpty(merchantPromocodes)) return []
  return _.map(merchantPromocodes, (p) => {
    const merchantId = p.id
    const takeawayPromocodes = p.takeawayPromocode ? String(p.takeawayPromocode).replace(/\s/g, '').split(',') : []
    const storeDeliveryPromocodes = p.storeDeliveryPromocode ? String(p.storeDeliveryPromocode).replace(/\s/g, '').split(',') : []
    const tablePromocodes = p.tablePromocode ? String(p.tablePromocode).replace(/\s/g, '').split(',') : []
    const bannerText = { takeaway: p.takeawayBannerText ?? '', storeDelivery: p?.storeDeliveryBannerText ?? '', table: p?.tableBannerText ?? '' }
    const promoCodes = {
      takeaway: _.map(takeawayPromocodes, code => ({ merchantId, code, deliveryType: TAKEAWAY })),
      storeDelivery: _.map(storeDeliveryPromocodes, code => ({ merchantId, code, deliveryType: STORE_DELIVERY })),
      table: _.map(tablePromocodes, code => ({ merchantId, code, deliveryType: TABLE })),
    }

    return _
      .chain(p)
      .clone()
      .set('bannerText', bannerText)
      .set('promoCodes', promoCodes)
      .omit('takeawayBannerText')
      .omit('storeDeliveryBannerText')
      .omit('tableBannerText')
      .omit('takeawayPromocode')
      .omit('storeDeliveryPromocode')
      .omit('tablePromocode')
      .value()
  })
}
