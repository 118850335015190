import React from 'react'

import SidebarItemButton from './SidebarItemButton'

/**
 * @param {*} props
 * @returns
 */
function SkeletonSidebarCategories (props) {
  return (
    <>
      <SidebarItemButton isSkeleton />
      <SidebarItemButton isSkeleton />
      <SidebarItemButton isSkeleton />
      <SidebarItemButton isSkeleton />
      <SidebarItemButton isSkeleton />
      <SidebarItemButton isSkeleton />
    </>
  )
}

export default React.memo(SkeletonSidebarCategories)
