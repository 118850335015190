import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import constants from '@/constants'

// import { scrollToTop } from '@/libs/scroll'
import { useAddress, useMember } from '@/hooks/user'
import { useDeliveryType, useOrderTrackingSnackbar, useSystem } from '@/hooks/app'
import { useLandingCategoriesQuery } from '@/hooks/queries/useLandingCategoriesQuery'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'

import Appbar from '@/components/Appbar'
import Banner from '@/components/Banner'
import BottomNavigation from '@/components/BottomNavigation'
import CategoryRestaurantSampleList from '@/components/RestaurantList/CategoryRestaurantSampleList'
import ComingSoon from '@/components/ComingSoon'
import DeliveryTypeTabs from '@/components/Page/PageTabs/DeliveryTypeTabs'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

import CuisineList from './CuisineList'

const RESTAURANT_SAMPLE_LIMIT = 8
const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType
const deliveryTypes = [TAKEAWAY, STORE_DELIVERY, TABLE]

export default function RestaurantList (props) {
  useOnStatusTapToPageTop({ offset: -8 })

  const dispatch = useDispatch()
  const history = useHistory()
  const showOrderTrackingSnackbar = useOrderTrackingSnackbar()
  const classes = useStyles({ ...props, showOrderTrackingSnackbar })
  const member = useMember()
  const address = useAddress()
  const { isTable } = useDeliveryType()

  useEffect(() => {
    // 尚未設定地址，轉頁到 address setting
    if (!address.address && !isTable) {
      if (member?.id) {
        // 有登入，去地址選擇頁面
        dispatch(actions.user.updateExitAddressEditBackLevel(2))
        history.push('/settings/address')
      } else {
        // 沒登入，快速選地址
        dispatch(actions.user.createAddressTemp('CURRENT'))
        history.push('/settings/address/map')
      }
    }
  }, [address])

  if (_.isEmpty(address)) return null
  return (
    <Page>
      <Appbar backgroundHeight='140%' />
      <DeliveryTypeTabs className={classes.pageTabs} deliveryTypes={deliveryTypes} />
      <PageContainer className={classes.container}>
        <Content />
      </PageContainer>
      <BottomNavigation />
    </Page>
  )
}

function Content () {
  const system = useSystem()
  const { deliveryType, isTable } = useDeliveryType()
  const { data: categories, isFetchedAfterMount, isFetching } = useLandingCategoriesQuery(RESTAURANT_SAMPLE_LIMIT)
  const shouldShowLoading = !isFetchedAfterMount && isFetching
  const memorizedCategories = useMemo(() => {
    const data = categories ?? []
    return _.reduce(data, (acc, category) => {
      // 『所有餐廳』分類
      if (category?.name === 'all_restaurants') {
        acc.allRestaurantsCategory = category
        return acc
      }

      // 『附近餐廳』分類
      if (category?.name === 'nearby_list') {
        acc.nearbyRestaurantsCategory = category
        return acc
      }

      // 第一個不為『所有餐廳』或『附近餐廳』的分類
      if (category?.name !== 'all_restaurants' && category?.name !== 'nearby_list' && !acc.firstCategory) {
        acc.firstCategory = category
        return acc
      }

      // 其他的分類
      acc.restCategories.push(category)
      return acc
    }, {
      allRestaurantsCategory: undefined,
      nearbyRestaurantsCategory: undefined,
      firstCategory: undefined,
      restCategories: [],
    })
  }, [categories])

  if (isTable) return <ComingSoon /> // deliveryType 為 TABLE 時顯示 "請掃描餐廳內QR Code下單"
  if (!system[deliveryType]?.enable) return <ComingSoon text={`暫停服務：${system[deliveryType].remark}`} /> // system 沒有關閉對映的 deliveryType 時顯示 "暫停服務: 原因"
  return (
    <>
      <Banner />
      <CategoryRestaurantSampleList
        category={memorizedCategories.firstCategory}
        max={RESTAURANT_SAMPLE_LIMIT}
        loading={shouldShowLoading}
      />
      <CategoryRestaurantSampleList
        category={memorizedCategories.nearbyRestaurantsCategory}
        max={RESTAURANT_SAMPLE_LIMIT}
        loading={shouldShowLoading}
      />
      <CuisineList />
      {_.map(memorizedCategories.restCategories, (category) => (
        <CategoryRestaurantSampleList
          key={category.name}
          category={category} max={RESTAURANT_SAMPLE_LIMIT}
          loading={shouldShowLoading}
        />
      ))}
      <CategoryRestaurantSampleList
        category={memorizedCategories.allRestaurantsCategory}
        max={RESTAURANT_SAMPLE_LIMIT}
        loading={shouldShowLoading}
        orientation='vertical'
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: `calc(${constants.app.marginBottom.FOOTER} + ${theme.spacing(1)}px)`,
    paddingBottom: props => props.showOrderTrackingSnackbar && constants.app.height.SNACKBAR,
    paddingLeft: 0,
    paddingRight: 0,
  },
  pageTabs: {
    zIndex: constants.app.zIndex.NAVBAR + 100,
    marginTop: constants.app.marginTop.APPBAR,
    marginBottom: theme.spacing(1),
    marginRight: 0,
    marginLeft: 0,
  },
}))
