import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

/**
 * @typedef SearchTagProps
 * @property {string} text
 * @property {() => void} onClick
 * @property {() => void} onDelete
 * @param {SearchTagProps} props
 * @returns
 */
export default function SearchTag (props) {
  const { text, onClick, onDelete } = props
  const classes = useStyles(props)

  return (
    <Chip
      label={text}
      variant='outlined'
      color='primary'
      deleteIcon={<CloseIcon />}
      onClick={onClick}
      onDelete={onDelete}
      clickable={Boolean(onClick)}
      classes={{
        root: classes.text,
        deleteIcon: classes.deleteIcon,
      }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  text: {
    flexShrink: 0,
    fontSize: '1.4rem',
    color: theme.palette.grey[500],
    padding: theme.spacing(0, 1),
    minWidth: 80,
    margin: 8,
  },
  deleteIcon: {
    color: theme.palette.grey[500],
    width: '1.6rem',
    height: '1.6rem',
  },
}))
