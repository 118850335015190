import { Capacitor } from '@capacitor/core'

import { getDeployment } from '@/constants/codePush'
import packageJson from '@root/package.json'

import betaConfig from './beta'
import defaultConfig from './default'
import prodConfig from './prod'

// * platform

/** @type {TPlatform} */
const platform = Capacitor.getPlatform()

// commit
const commit = process.env.REACT_APP_SHORT_SHA

// * env

/** @type {TEnv} */
const REACT_APP_ENV = process.env.REACT_APP_ENV
/** @type {TEnv} */
const OVERWRITE_ENV = localStorage.getItem('OVERWRITE_ENV')
/** @type {TEnv} */
const env = OVERWRITE_ENV || REACT_APP_ENV || 'test'

// * debug mode
const debugMode = localStorage.getItem('DEBUG_MODE') === 'true' || false

// * deployment, deploymentKey
const [deployment, deploymentKey] = getDeployment()

// * storeUrl

const platformStoreUrls = {
  android: 'https://play.google.com/store/apps/details?id=com.dimorder.app',
  ios: 'https://apps.apple.com/app/apple-store/id1558146057',
}

const storeUrl = platformStoreUrls[platform]

console.log('final config', {
  debugMode,
  commit,
  platform,
  env,
  deployment,
  deploymentKey,
  storeUrl,
})

/** @type {{[env: TEnv]: IBaseConfig}} */
const configs = {
  test: defaultConfig,
  beta: betaConfig,
  prod: prodConfig,
}

/** @type {IBaseConfig} */
const envConfig = configs[env] || {}

/** @type {IConfig} */
const exportConfig = {
  version: packageJson.version,
  debugMode,
  commit,
  platform,
  env,
  deployment,
  deploymentKey,
  storeUrl,
  ...defaultConfig,
  ...envConfig,
}

export default exportConfig
