import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import constants from '@/constants'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType

const progressSteps = {
  [TABLE]: 2,
  [TAKEAWAY]: 4,
  [STORE_DELIVERY]: 5,
}

/**
 *
 * @param {{
 * order: IAppOrder
 * currentIndex: number
 * size?: number
 * hidden?: boolean
 * }} props
 * @returns
 */
export default function ProgressBar (props) {
  const { order, currentIndex = 0, size: propSize, hidden } = props
  const classes = useStyles(props)
  const defaultSize = progressSteps[order.deliveryType]
  const size = propSize ?? defaultSize

  if (hidden) return null
  return (
    <div className={classes.container}>
      {
        _.times(size, (index) => {
          const isFinished = index < currentIndex
          const isCurrent = index === currentIndex
          const isFinal = index + 1 === size && isCurrent

          return (
            <div
              key={index}
              className={clsx(
                classes.step,
                { [classes.finishedStep]: isFinished || isFinal },
                { [classes.currentStep]: isCurrent && !isFinal },
              )}
            />
          )
        })
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  step: {
    position: 'relative',
    flex: 1,
    height: theme.spacing(1),
    borderRadius: 4,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 4,
      backgroundColor: theme.palette.disabled.main,
      opacity: 0.3,
    },
    '&:after': {
      content: '',
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '0%',
      height: '100%',
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.9,
    },
  },
  '@keyframes progress': {
    from: {
      width: '0%',
    },
    to: {
      width: '100%',
    },
  },
  currentStep: {
    '&:before': {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.3,
    },
    '&:after': {
      content: '""',
      animationName: '$progress',
      animation: '2500ms infinite ease-out',
    },
  },
  finishedStep: {
    '&:after': {
      content: '""',
      width: '100%',
    },
  },
}))
