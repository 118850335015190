import { CircularProgress, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import Div100vh from 'react-div-100vh'
import React from 'react'

import { Provider as BatchItemQuantityContextProvider } from '@/hooks/contexts/BatchItemQuantityContext'
import { scrollToTop } from '@/libs/scroll'
import { useCategories } from '@/hooks/queries/menu/useMenuQuery'
import AppConstants from '@/constants/app'
import DineinButtonCheckout from '@/pages/Menu/DineinButtons/DineinButton/DineinButtonCheckout'
import PageContainer from '@/components/Page/PageContainer'
import RestaurantFooter from '@/components/Footers/RestaurantFooter'
import opacity from '@/theme/opacity'

import CategoryList from './CategoryList'
import MerchantInfo from './MerchantInfo'
import Sidebar from './Sidebar'

/**
 * @param {{isLoading: boolean}} props
 * @returns
 */
export default function LaMaMenu (props) {
  const { isLoading } = props
  const classes = useStyles({ props })
  const { data: categories } = useCategories()

  const [offset, setOffset] = React.useState(0)

  return (
    <Div100vh className={classes.root}>
      <BatchItemQuantityContextProvider>
        <Sidebar isLoading={isLoading} />
        <PageContainer
          className={classes.main}
          onScroll={(event) => {
            setOffset(event.target.scrollTop)
          }}
        >
          <MerchantInfo />
          {isLoading
            ? (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )
            : (
              <>
                <div className={classes.checkoutDiv}>
                  <DineinButtonCheckout />
                </div>
                <CategoryList categories={categories} />
              </>
            )}
          {offset > 32 && (
            <Fab
              className={classes.fab}
              size='small'
              onClick={() => scrollToTop('pageContainer', { offset: -8 })}
            >
              <ArrowUpwardIcon className={classes.fabIcon} />
            </Fab>
          )}
        </PageContainer>
      </BatchItemQuantityContextProvider>
      <RestaurantFooter slide />
    </Div100vh>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  main: {
    marginTop: AppConstants.marginTop.GENERAL,
    marginBottom: AppConstants.marginBottom.GENERAL,
    paddingBottom: 64,
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkoutDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: `#ffffff${opacity[80]} !important`,
  },
  fabIcon: {
    color: '#c61313',
  },
}))
