import { useQuery } from '@tanstack/react-query'

import { DEFAULT_PER_PAGE } from '@/hooks/queries/params'
import { useIsLogin, useMemberId } from '@/hooks/user'

import dimorderApi from '@/libs/api/dimorder'

/**
 * 用於 CRM 會員卡頁面的捲動載入
 * @param {number} [perPage = 25]
 * @returns
 */
export function useJoinedGroupsQueryOptions (perPage = DEFAULT_PER_PAGE) {
  const isLogin = useIsLogin()
  const memberId = useMemberId()
  const queryKey = ['crm', 'joined_groups', `memberId:${memberId}`]
  const queryFn = ({ pageParam: next }) => fetchFn(next)
  async function fetchFn (next) {
    const pageData = await dimorderApi.crm.getJoinedGroups({ after: next, limit: perPage })
    const meta = { perPage, next: pageData?.next }
    return {
      meta,
      data: pageData?.data ?? [],
      perPage,
      next: pageData?.next,
    }
  }
  return { queryKey, queryFn, enabled: isLogin }
}

/**
 *
 * @param {string} [groupId]
 * @returns
 */
export function useJoinedGroupsQuery (groupId) {
  const options = useJoinedGroupsQueryOptions(groupId)
  const query = useQuery(options)
  return query
}
