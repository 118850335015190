import { ClickAwayListener, Tooltip, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import PropTypes from 'prop-types'
import React from 'react'
InfoButton.propTypes = {
  text: PropTypes.string,
}

/**
 *
 * @param {{
 * text: string
 * }} props
 * @returns
 */
export default function InfoButton (props) {
  const {
    text,
  } = props
  const classes = useStyles({ ...props })
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        className={classes.tooltip}
        PopperProps={{ disablePortal: true }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={text}
      >
        <InfoIcon
          onClick={handleTooltipOpen} fontSize='small'
          className={classes.info_icon}
        />
      </Tooltip>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(theme => ({
  tooltip: {},
  info_icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: '1.6rem',
  },
}))
