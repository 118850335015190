import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useDeliveryType } from '@/hooks/app'
import config from '@/config'

/**
 * 條款及細則，點擊可打開外部連結
 * 內用不顯示
*
* @param {*} props
* @returns
*/
export default function TermsAndConditions (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const handleClick = () => window.open(config.links.tnc)
  const { isTable } = useDeliveryType()

  if (isTable) return null
  return (
    <div className={classes.wrap}>
      <Typography variant='body2' align='center'>
        {t('app.page.checkout.terms_and_conditions.first_line')}
        <Link
          className={classes.link}
          target='_blank'
          variant='body2'
          onClick={handleClick}
        >
          {t('app.page.checkout.terms_and_conditions.link')}
        </Link>
        {t('app.page.checkout.terms_and_conditions.second_line')}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  wrap: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 2),
  },
  link: {
    color: theme.palette.info.main,
  },
}))
