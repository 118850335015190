// this message file is for window.addEventListener('message') and window.postMessage
/**
 * @typedef {Object} IWindowEventData
 * @property {string} type - 代表 message type
 * @property {*} payload - 化表paylaod, must be object
 */
const messageType = {
  APPLE_PAY_ORDER_CALLBACK_EVENT: 'APPLE_PAY_ORDER_CALLBACK_EVENT',
}

export default messageType
