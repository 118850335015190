import { CircularProgress } from '@material-ui/core'
import { VariableSizeList, areEqual } from 'react-window'
import { makeStyles } from '@material-ui/core/styles'
import { useInfiniteQuery } from '@tanstack/react-query'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import React, { memo, useMemo, useRef } from 'react'
import _ from 'lodash'

import { useOnStatusTap } from '@/hooks/useOnStatusTap'
import { useWindowSize } from '@/hooks/useWindowSize'

export const DEFAULT_ROW_PADDING_TOP = 16
const DEFAULT_ROW_HEIGHT = 40
const DEFAULT_LIST_WIDTH = 1280
const DEFAULT_COLUMN_WIDTH = DEFAULT_LIST_WIDTH
const DEFAULT_COLUMN_MIN_COUNT = 1

/**
 * @typedef InfiniteQueryListProps
 * @property {object} queryOptions // useInfiniteQuery options
 * @property {number} [rowHeight = DEFAULT_ROW_HEIGHT]
 * @property {number} [columnWidth = DEFAULT_COLUMN_WIDTH]
 * @property {JSX.Element} ItemComponent
 * @property {JSX.Element} HeaderComponent
 * @property {number} [HeaderComponentHeight = 0]
 * @property {JSX.Element} FooterComponent
 * @property {number} [FooterComponentHeight = 0]
 * @property {JSX.Element} [LoadingIndicator]
 * @property {JSX.Element} [LoadingComponent]
 * @property {JSX.Element} [EmptyComponent]
 * @param {InfiniteQueryListProps} props
 * @returns
 */
export default function InfiniteQueryList (props) {
  useOnStatusTap(scrollToTop)

  const {
    queryOptions = {},
    rowHeight = DEFAULT_ROW_HEIGHT,
    columnWidth = DEFAULT_COLUMN_WIDTH,
    ItemComponent,
    HeaderComponent,
    HeaderComponentHeight = 0,
    FooterComponent,
    FooterComponentHeight = 0,
    LoadingIndicator,
    LoadingComponent,
    EmptyComponent,
  } = props

  const classes = useStyles(props)
  const {
    isLoading,
    isError,
    isFetchingNextPage,
    data,
    error,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    getNextPageParam: (lastPage, allPages) => {
      const { next, nextCursor, perPage, total } = lastPage
      const hasNextPage = allPages.length * perPage < total
      if (typeof next === 'string') {
        // API 使用 next 做分頁時
        return next !== '' ? next : undefined
      } else {
        // API 使用 page 做分頁時
        return hasNextPage ? nextCursor : undefined
      }
    },
    getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
    ...queryOptions,
  })

  const memorizedData = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      acc = acc.concat(page.data)
      return acc
    }, [])
  }, [data]) ?? []

  const MemorizedItemComponent = memo(({ item }) => {
    return <ItemComponent item={item} />
  }, areEqual)

  const loaderRef = useRef()
  const windowSize = useWindowSize()
  const columnMinCount = DEFAULT_COLUMN_MIN_COUNT
  const columnMaxCount = Math.floor(DEFAULT_LIST_WIDTH / columnWidth)
  const columnCount = Math.floor(windowSize.innerWidth / columnWidth)
  const columnClampCount = Math.min(Math.max(columnCount, columnMinCount), columnMaxCount)
  const isEmpty = memorizedData.length === 0

  // Render an item or a loading indicator.
  function Row ({ index: rowIndex, style }) {
    if (HeaderComponent && rowIndex === 0) {
      return (
        <div key={HeaderComponentHeight} className={classes.row} style={style}>
          <HeaderComponent />
        </div>
      )
    }

    if (EmptyComponent && !hasNextPage && rowIndex === loadingIndicatorRowIndex - 1 && isEmpty) {
      return (
        <div className='list-empty' style={style}>
          <EmptyComponent />
        </div>
      )
    }

    if (FooterComponent && !hasNextPage && rowIndex === loadingIndicatorRowIndex) {
      return (
        <div className='list-footer' style={style}>
          <FooterComponent />
        </div>
      )
    }

    if (!isRowLoaded(rowIndex)) {
      if (rowIndex === loadingIndicatorRowIndex) {
        return (
          <div className='loading-indicator' style={style}>
            {LoadingIndicator ? <LoadingIndicator /> : <LoadingSpinner />}
          </div>
        )
      }
      return null
    }

    return (
      <div className={classes.row} style={style}>
        {_.times(columnClampCount, (columnIndex) => {
          const headerRowCount = HeaderComponent ? 1 : 0
          const dataIndex = (rowIndex - headerRowCount) * columnClampCount + columnIndex
          const item = memorizedData[dataIndex]
          return <MemorizedItemComponent key={dataIndex} item={item} />
        })}
      </div>
    )
  }

  if (isLoading) {
    if (LoadingComponent) return <LoadingComponent />
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    )
  }

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isFetchingNextPage ? () => { } : fetchNextPage
  const rowCount = getRowCount()
  const loadingIndicatorRowIndex = getLoadingIndicatorRowIndex()

  function getRowCount () {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    let count = hasNextPage
      ? Math.ceil(memorizedData.length / columnClampCount) + 1
      : Math.ceil(memorizedData.length / columnClampCount)
    if (HeaderComponent) { count += 1 }
    if (FooterComponent) { count += 1 }
    if (EmptyComponent && isEmpty) { count += 1 }
    return count
  }

  function getLoadingIndicatorRowIndex () {
    let indicatorRowIndex = Math.ceil(memorizedData.length / columnClampCount)
    if (HeaderComponent) { indicatorRowIndex += 1 }
    if (EmptyComponent && isEmpty) { indicatorRowIndex += 1 }
    return indicatorRowIndex
  }

  /**
   * Check every row is loaded except for our loading indicator row.
   * @param {number} index
   * @returns
   */
  function isRowLoaded (index) {
    return !hasNextPage || index < loadingIndicatorRowIndex
  }

  /**
   * Get the height of the row.
   * @param {number} index
   * @returns
   */
  function getRowHeight (index) {
    let height = rowHeight
    // If rendered HeaderComponent at the first row.
    // The height of the first row should be HeaderComponentHeight or rowHeight.
    if (HeaderComponent && index === 0) {
      height = HeaderComponentHeight || rowHeight
    }
    // If rendered FooterComponent at the last row.
    // The height of the last row should be FooterComponentHeight or rowHeight.
    if (FooterComponent && !hasNextPage && index === loadingIndicatorRowIndex) {
      height = FooterComponentHeight || rowHeight
    }
    return height
  }

  function scrollToTop () {
    loaderRef.current._listRef.scrollTo(0)
  }

  return (
    <AutoSizer key={HeaderComponentHeight}>
      {({ height, width }) => (
        <InfiniteLoader
          ref={loaderRef}
          isItemLoaded={isRowLoaded}
          itemCount={rowCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              height={height}
              width={width}
              itemCount={rowCount}
              itemSize={getRowHeight}
              onItemsRendered={onItemsRendered}
              overscanCount={5}
              ref={ref}
              style={{ scrollBehavior: 'smooth' }}
            >
              {Row}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

/**
 *
 * @param {{ height: number }} props
 * @returns
 */
export function LoadingSpinner (props) {
  const { height = DEFAULT_ROW_HEIGHT } = props
  return (
    <div style={{ display: 'grid', placeItems: 'center', paddingTop: DEFAULT_ROW_PADDING_TOP, height }}>
      <CircularProgress size={24} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: DEFAULT_ROW_PADDING_TOP,
    gap: theme.spacing(1),
  },
}))
