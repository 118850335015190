import { createTheme } from '@material-ui/core/styles'

import colors from './colors'
import custom from './colors/custom'
import opacity from './opacity'

const defaultTheme = createTheme()
const fontFamily = 'PingFang HK, Noto Sans HK, Roboto, Helvetica, Arial, sans-serif'

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 6,
  },
  shadows: [...defaultTheme.shadows, `0px 0px 6px #000000${opacity[10]}`],
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    action: {
      disabled: custom.disabled.contrastText || colors.disabled.contrastText,
      disabledBackground: custom.disabled.main || colors.disabled.main,
    },
    primary: {
      main: custom.primary.main || colors.primary.main,
      customDark: custom.primary.dark || colors.primary.dark,
      customLight: custom.primary.light || colors.primary.light,
      contrastText: custom.primary.contrastText || colors.primary.contrastText,
    },
    secondary: {
      main: custom.secondary.main || colors.secondary.main,
      contrastText: custom.secondary.contrastText || colors.secondary.contrastText,
    },
    disabled: {
      main: custom.disabled.main || colors.disabled.main,
      contrastText: custom.disabled.contrastText || colors.disabled.contrastText,
    },
    error: {
      main: colors.error.main,
    },
    warning: {
      main: colors.warning.main,
    },
    info: {
      main: colors.info.main,
    },
    text: {
      primary: custom.text.primary.main || colors.text.primary.main,
      primaryLight: custom.text.primary.light || colors.text.primary.light,
      primaryDark: custom.text.primary.dark || colors.text.primary.dark,
      secondary: custom.text.secondary.main || colors.text.secondary.main,
      secondaryLight: custom.text.secondary.light || colors.text.secondary.light,
      secondaryDark: custom.text.secondary.dark || colors.text.secondary.dark,
    },
  },
  safeArea: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: colors.grey[50],
      },
    },
    MuiTypography: {
      root: { fontFamily },
      h1: { fontFamily },
      h2: { fontFamily },
      h3: { fontFamily },
      h4: { fontFamily },
      h5: { fontFamily },
      h6: { fontFamily },
      subtitle1: { fontFamily },
      subtitle2: { fontFamily },
      body1: { fontFamily },
      body2: { fontFamily },
      button: { fontFamily },
      caption: { fontFamily },
      overline: { fontFamily },
    },
    MuiButton: {
      root: {
        minWidth: 40,
        minHeight: 40,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        opacity: 0.3,
      },
    },
  },
}

export default theme
