import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import FoodAngelBanner from '@/assets/campaign/foodAngel/food_angel_banner.png'

import DialogBase from './DialogBase'

/**
 *
 * @param {{
 * open: boolean
 * onClose: Function
 * }} props
 * @returns
 */
export default function FoodAngelDetailDialog (props) {
  const { open, onClose } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={t('app.component.dialog.food_angel_detail_dialog.title')}
      renderContent={() => (
        <>
          <img src={FoodAngelBanner} className={classes.banner} />
          <Typography variant='body2'>
            {t('app.component.dialog.food_angel_detail_dialog.desc')}
          </Typography>
        </>
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  banner: {
    justifySelf: 'center',
  },
}))
