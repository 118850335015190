import { Checkbox, Link, Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useMemo, useState } from 'react'
import fixPrecision from 'dimorder-orderapp-lib/dist/libs/fixPrecision'

import { actions, useDispatch, useSelector } from '@/redux'
import { isPoonChoiCategoryTag } from '@/libs/poonchoi'
import { useDeliveryType, useParams, useSystem } from '@/hooks/app'
import { useSelectedOrder } from '@/hooks/order'
import constants from '@/constants'

import Card from './Card'
import FoodAngelDetailDialog from '@/components/Dialogs/FoodAngelDetailDialog'

import FoodAngelLogo from '@/assets/campaign/foodAngel/food_angel_logo.gif'

const { DEFAULT_BONUS_RATIO, POONCHOI_BONUS_RATIO } = constants.riceCoin

/**
 *
 * 惜食堂活動，可於結帳時選擇是否捐贈下單後獲得的 RiceCoin 給惜食堂
 * 點擊可顯示關於惜時堂的介紹
 * 堂食不顯示
 * @param {*} props
 * @returns
 */
export default function DonateRiceCoinCard (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const order = useSelectedOrder()
  const { isTable, isTakeaway } = useDeliveryType()
  const campaignEnable = useSystem().campaign.foodAngel
  const { categoryTag } = useParams()
  const isPoonchoi = isPoonChoiCategoryTag(categoryTag)
  const donateRiceCoinReward = useSelector(state => state.order.donateRiceCoinReward)
  const restaurant = useSelector(state => state.landing.restaurant)

  const [showDetail, setShowDetail] = useState(false)

  const ricecoinReward = useMemo(() => {
    const ricecoinBonusRatio = isPoonchoi
      ? POONCHOI_BONUS_RATIO
      : restaurant?.ricecoinBonusRatio ?? DEFAULT_BONUS_RATIO
    return fixPrecision(order.ricecoin * ricecoinBonusRatio)
  }, [order?.ricecoin, restaurant?.ricecoinBonusRatio, isPoonchoi])

  const onChange = (e) => dispatch(actions.order.updateDonateRiceCoinReward(e.target.checked))

  if (!order) return null
  if (!campaignEnable) return null
  if (isTable) return null // 內用不發 RiceCoin
  if (isTakeaway) return null // 外帶不發 RiceCoin
  if (ricecoinReward < 1) return null // 沒有獎勵不顯示

  return (
    <Card className={classes.container}>
      <img src={FoodAngelLogo} alt='logo' className={classes.logo} />
      <section>
        <Typography variant='body2'>{t('app.component.card.donate_riceCoin_card.expected_reward', { reward: ricecoinReward })}</Typography>
        <Typography variant='body2' className={classes.bold}>
          {t('app.component.card.donate_riceCoin_card.first_line')}
          <Link color='textPrimary' underline='always' onClick={() => setShowDetail(true)}>FoodAngel</Link>
          {t('app.component.card.donate_riceCoin_card.second_line')}
        </Typography>
      </section>
      <Checkbox
        checked={donateRiceCoinReward}
        onChange={onChange}
        disabled={!ricecoinReward}
        classes={{
          root: classes.root,
          checked: classes.checked,
        }}
      />
      <FoodAngelDetailDialog open={showDetail} onClose={() => setShowDetail(false)} />
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: theme.spacing(1),
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.info.main, 0.1),
    border: `1px solid ${alpha(theme.palette.info.main, 0.3)}`,
    padding: theme.spacing(1, 2),
  },
  root: {
    padding: 0,
    color: theme.palette.info.main,
    '&$checked': {
      color: theme.palette.info.main,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  logo: {
    maxWidth: 48,
    maxHeight: 48,
  },
  checked: {},
}))
