import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useAddress, useMember } from '@/hooks/user'
import { useDeliveryType, useOrderTrackingSnackbar, useParams } from '@/hooks/app'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'

import BottomNavigation from '@/components/BottomNavigation'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'

import QuickSearchList from './QuickSearchList'
import SearchRestaurantList from '@/components/RestaurantList/SearchRestaurantList'
import Searchbar from './Searchbar'

export default function Search (props) {
  useOnStatusTapToPageTop()

  const dispatch = useDispatch()
  const params = useParams()
  const { isTable } = useDeliveryType()
  const member = useMember()
  const address = useAddress()
  const history = useHistory()
  const showOrderTrackingSnackbar = useOrderTrackingSnackbar()
  const classes = useStyles({ ...props, showOrderTrackingSnackbar })
  const searchText = useSelector(state => state.landing.searchText)
  const filter = useSelector(state => state.landing.filter)
  const hasSelectedFilter = _.some(filter, arr => arr.length > 0)

  useEffect(() => {
    if (!isTable && !address?.address) {
      // 沒有設定地址，先前往設定
      if (member?.id) {
        // 有登入，去地址選擇頁面
        dispatch(actions.user.updateExitAddressEditBackLevel(2))
        history.push('/settings/address')
      } else {
        // 沒登入，快速選地址
        dispatch(actions.user.createAddressTemp('CURRENT'))
        history.push('/settings/address/map')
      }
    }
  }, [params.deliveryType, address?.address])

  return (
    <Page>
      <Searchbar />
      <PageContainer className={classes.container}>
        {
          searchText || hasSelectedFilter
            ? <SearchRestaurantList />
            : <QuickSearchList />
        }
      </PageContainer>
      <BottomNavigation />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.APPBAR,
    marginBottom: `calc(${constants.app.marginBottom.FOOTER} + ${theme.spacing(1)}px)`,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: props => props.showOrderTrackingSnackbar && constants.app.height.SNACKBAR,
  },
}))
