import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IOrderState } from '@/redux/order/OrderState.d'
/* eslint-enable no-unused-vars */

/** @type {IAppOrderBatch} */
export const initBatch = {
  orderId: null,
  orderSerial: null,
  batchId: null,
  index: 0,
  table: null,
  items: [],
  remark: '',
  takeout: false, // 外帶
  delivery: false, // 是否, pickupp 外送
  storeDelivery: false, // 是否外送訂單
  shop: false, // 是否網店訂單
  takeaway: false, // 是否外帶訂單
  status: null,
}

/** @type {IAppOrder} */
export const initOrder = {
  id: null,
  // submit order 的時候再補上  app.params.deliveryType 和 app.params.table
  // deliveryType: 'takeaway',
  // table: null,
  customers: 1,
  adults: 1,
  children: 0,
  batches: [],
  surcharge: { percent: 0, amount: 0 },
  originalTotal: 0,
  calculatedDiscountTotal: 0,
  surchargeTotal: 0,
  total: 0,
  modifiers: [],
  payments: [],
  needTableware: false,
}

/** @type {IOrderState} */
export const initialState = {
  isCalculateOrderLoading: false,
  isCalculateOrderError: false,
  selectedOrder: null,
  requestServiceTime: {
    pay: null,
    waiter: null,
  },
  promoCode: null,
  isApplyPromoCodeLoading: false,
  isAutoFillPromoCodeLoading: false,
  isDeletePromoCodeLoading: false,
  riceCoinDiscount: null,
  applyRiceCoinDiscount: null,
  donateRiceCoinReward: false,
  shipping: undefined,
  prioritizedPromoCodes: [],
}

export default produce(
  /**
   * @param {IOrderState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.UPDATE_CALCULARE_ORDER_LOADING: {
        const { isCalculateOrderLoading } = action.payload
        draft.isCalculateOrderLoading = isCalculateOrderLoading
        break
      }
      case ActionTypes.UPDATE_CALCULARE_ORDER_ERROR: {
        const { isCalculateOrderError } = action.payload
        draft.isCalculateOrderError = isCalculateOrderError
        break
      }
      case ActionTypes.SELECT_ORDER: {
        const { order } = action.payload
        draft.selectedOrder = order
        break
      }
      case ActionTypes.UPDATE_SELECTED_ORDER: {
        const { path, value } = action.payload
        if (path) {
          _.set(draft.selectedOrder, path, value)
          return
        }
        draft.selectedOrder = value
        break
      }
      case ActionTypes.UPDATE_REQUEST_SERVICE_TIME: {
        const { path, time } = action.payload
        draft.requestServiceTime[path] = time
        break
      }
      case ActionTypes.UPDATE_PROMO_CODE: {
        const { promoCode } = action.payload
        draft.promoCode = promoCode
        break
      }
      case ActionTypes.DELETE_PROMO_CODE: {
        draft.promoCode = null
        break
      }
      case ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING: {
        const { isLoading } = action.payload
        draft.isApplyPromoCodeLoading = isLoading
        break
      }
      case ActionTypes.UPDATE_AUTOFILL_PROMO_CODE_LOADING: {
        const { isLoading } = action.payload
        draft.isAutoFillPromoCodeLoading = isLoading
        break
      }
      case ActionTypes.UPDATE_DELETE_PROMO_CODE_LOADING: {
        const { isLoading } = action.payload
        draft.isDeletePromoCodeLoading = isLoading
        break
      }
      case ActionTypes.UPDATE_RICECOIN_DISCOUNT: {
        const { riceCoinDiscount } = action.payload
        draft.riceCoinDiscount = riceCoinDiscount
        break
      }
      case ActionTypes.UPDATE_APPLY_RICECOIN_DISCOUNT: {
        const { applyRiceCoinDiscount } = action.payload
        draft.applyRiceCoinDiscount = applyRiceCoinDiscount
        break
      }
      case ActionTypes.UPDATE_DONATE_RICECOIN_REWARD: {
        const { donateRiceCoinReward } = action.payload
        draft.donateRiceCoinReward = donateRiceCoinReward
        break
      }
      case ActionTypes.UPDATE_SHIPPING: {
        const { shipping } = action.payload
        draft.shipping = shipping
        break
      }
      case ActionTypes.UPDATE_PRIORITIZED_PROMO_CODE : {
        const { promoCodes } = action.payload
        draft.prioritizedPromoCodes = promoCodes
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
