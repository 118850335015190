import { Radio as MuiRadio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import React from 'react'

Radio.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * selected: Boolean
 * disabled: Boolean
 * onClick: Function
 * }} props
 */
export default function Radio (props) {
  const { selected, disabled, onClick } = props
  const classes = useStyles(props)

  return (
    <MuiRadio
      color='primary'
      icon={<RadioButtonUncheckedIcon color='primary' />}
      classes={{
        root: classes.radioRoot,
        disabled: classes.radioDisabled,
      }}
      disabled={disabled}
      checked={selected}
      onClick={onClick}
    />
  )
}

const useStyles = makeStyles(theme => ({
  radioRoot: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  radioDisabled: {
    opacity: 0.3,
  },
}))
