import { Button, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

ExpandButton.propTypes = {
  number: PropTypes.number,
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * number: Number
 * onClick: Function
 * }} props
 */
export default function ExpandButton (props) {
  const { t } = useTranslation()
  const { number, onClick } = props
  const classes = useStyles(props)

  return (
    <Button
      className={classes.expandButton}
      onClick={onClick}
    >
      <Typography color='textSecondary'>
        {`${t('app.common.find_more')}(${number})`}
      </Typography>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  expandButton: {
    fontSize: '1.4rem',
  },
}))
