import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { getStatusConfig } from '@/libs/orderTracking'
import { poonchoiTimeslots } from '@/libs/poonchoi'
import Card from '@/components/Cards/Card'
import OrderStatus from '@/constants/orderStatus'
import hooks from '@/hooks'

import { checkIsDimboxOrder } from '@/libs/dimbox'
import ProgressBar from './ProgressBar'
import ProgressHeader from './ProgressHeader'
import ProgressImage from './ProgressImage'
import RestaurantInfo from './RestaurantInfo'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 *
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function ProgressCard (props) {
  const { order } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  if (_.isEmpty(order)) return null

  let statusConfig = getStatusConfig(order.deliveryType, order.displayStatusKey)

  const merchant = hooks.merchant.useMerchantById(order.merchantId)
  const isCancelled = order.status === OrderStatus.CANCELLED
  const isPoonchoi = React.useMemo(() => _.some(order.tags, tag => tag.name === 'poonchoi'), [order.tags])
  const isPoonchoiProgressing = React.useMemo(() => isPoonchoi && _.includes(['preparing_meal', 'waiting_pick_up', 'in_delivery'], order.displayStatusKey), [isPoonchoi, order.displayStatusKey])
  const isDimbox = checkIsDimboxOrder(order)

  function Description () {
    /**
     * 盆菜會提早很久預定，所以盆菜訂單進行中的狀態需要改成幾月幾號下午/晚上送達
     * 1. 等待店家接單 waiting_accept
     * 2. 備餐中 preparing_meal => 盆菜將於 m月d號下午/晚上 送達
     * 3. 等待取餐 waiting_pick_up => 盆菜將於 m月d號下午/晚上 送達
     * 4. 送餐中 in_delivery => 盆菜將於 m月d號下午/晚上 送達
     * 5. 已送達 complete/arrived
     * @function
     * @returns {string}
     */
    const getDesc = () => {
      let deliveryType = order.deliveryType
      const month = moment(order.pickupAt).format('M')
      const date = moment(order.pickupAt).format('D')
      if (isPoonchoiProgressing) {
        statusConfig = { index: statusConfig.index } // 盆菜訂單進行中的狀態不顯示備餐圖片，只留下用來顯示 progress step 的 index
        if (poonchoiTimeslots(order.pickupAt)['14~17']) return t('app.component.order_history_item.display_status.poonchoi_will_be_deliver_at', { month, date, timeslot: t('app.common.date_time.afternoon') })
        if (poonchoiTimeslots(order.pickupAt)['17~21']) return t('app.component.order_history_item.display_status.poonchoi_will_be_deliver_at', { month, date, timeslot: t('app.common.date_time.night') })
      }
      if (isDimbox) {
        statusConfig.iconType = undefined // dimbox訂單不顯示取餐碼
        deliveryType = 'dimbox'
      }
      return t(`app.page.order_tracking.progress_card.${deliveryType}.desc.${order.displayStatusKey}`, { merchantName: merchant?.name })
    }

    return (
      <Typography>
        {getDesc()}
      </Typography>
    )
  }

  function Datetime () {
    if (order?.displayStatusKey === 'paid' && order?.paidAt) {
      return <Typography>{moment(order.paidAt).format(t('app.common.date_time.format_year_date_time'))}</Typography>
    }

    if (order?.deliveryType === 'table' && order?.displayStatusKey === 'waiting_pick_up' && order?.pickupNotifyAt) {
      return <Typography>{moment(order.pickupNotifyAt).format(t('app.common.date_time.format_year_date_time'))}</Typography>
    }

    if (order?.status === 'cancelled' && order?.cancelledAt) {
      return <Typography>{moment(order.cancelledAt).format(t('app.common.date_time.format_year_date_time'))}</Typography>
    }

    return null
  }

  return (
    <Card className={classes.container}>
      <section className={classes.headerSection}>
        {!isCancelled && <ProgressHeader order={order} />}
        <ProgressBar order={order} currentIndex={statusConfig?.index} hidden={isCancelled || isPoonchoi} />
      </section>

      {/* Description */}
      <div className={classes.desc}>
        <Description />
        <Datetime />
      </div>

      {/* Image */}
      <ProgressImage order={order} statusConfig={statusConfig} />

      <RestaurantInfo order={order} />
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(3),
    // overflow: 'hidden',
  },
  desc: {
    color: theme.palette.grey[700],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerSection: {
    display: 'grid',
    gap: theme.spacing(1.5),
  },
  dimboxWarningSpacing: {
    marginTop: theme.spacing(1),
  },
}))
