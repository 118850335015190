import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

SkeletonCategoryBar.propTypes = {
  disabled: PropTypes.bool,
}

/**
 *
 * @param {{
 * disabled: boolean
 * }} props
 * @returns
 */
export default function SkeletonCategoryBar (props) {
  const { disabled } = props
  const classes = useStyles(props)

  if (disabled) return null
  return (
    <Skeleton className={classes.skeletonCategoryBar} animation='wave' variant='text' width='100%' height='100%' />
  )
}

const useStyles = makeStyles(theme => ({
  skeletonCategoryBar: {
    margin: theme.spacing(0, 1),
  },
}))
