import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export default function ActionButtonBase ({ text, onClick, icon }) {
  const classes = useStyles()

  return (
    <ButtonBase onClick={onClick} className={classes.btn}>
      {icon}
      <Typography className={classes.text} variant='subtitle2'>{text}</Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  btn: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(255 237 181)',
    borderRadius: 16,
    margin: theme.spacing(0.5),
    padding: 10,
    overflow: 'hidden',
  },
  text: {
    marginTop: theme.spacing(0.5),
  },
}))
