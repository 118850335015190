import { Geolocation } from '@capacitor/geolocation'

/**
 * @typedef {object} GeolocationCoords
 * @property {number} latitude
 * @property {number} longitude
 * @property {number} accuracy
 * @property {number} [altitudeAccuracy]
 * @property {number} [altitude]
 * @property {number} [speed]
 * @property {number} [heading]
 */

/**
 * @typedef {object} GeolocationPosition
 * @property {number} timestamp
 * @property {GeolocationCoords} coords
 */

/**
 * @typedef {object} GeolocationOptions
 * @property {boolean} enableHighAccuracy
 * @property {number} timeout
 * @property {number} maximumAge
 */

/** @type {GeolocationOptions} */
const defaultOptions = {
  enableHighAccuracy: true,
  timeout: 20 * 1000,
  maximumAge: 60 * 1000,
}

/**
 * @param {GeolocationOptions?} options
 * @return {Promise<GeolocationPosition>}
 */
export function getCurrentPosition (options = {}) {
  const _options = { ...defaultOptions, ...options }
  return new Promise((resolve, reject) => {
    let resultPosition = null
    let callbackId = null
    Geolocation
      .watchPosition(_options, (position, error) => {
        resultPosition = position
        if (error) {
          reject(error)
          return
        }
        if (callbackId) {
          Geolocation.clearWatch({ id: callbackId })
          resolve(position)
        }
      })
      .then(id => {
        callbackId = id
        if (resultPosition) {
          Geolocation.clearWatch({ id: callbackId })
          resolve(resultPosition)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

/**
 * Distance in km
 * @param {number} lat1
 * @param {number} lon1
 * @param {number} lat2
 * @param {number} lon2
 * @returns {number}
 */
export function getDistanceFromLatLonInKm (lat1, lon1, lat2, lon2) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

function deg2rad (deg) {
  return deg * (Math.PI / 180)
}

export default {
  getCurrentPosition,
  getDistanceFromLatLonInKm,
}
