import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Input from './Input'

/**
 *
 * @param {*} props
 * @returns
 */
export default function InputList (props) {
  const classes = useStyles(props)
  return (
    <ul className={classes.inputList}>
      <li>
        <Input variant='floorRoom' />
      </li>
      <li>
        <Input variant='building' />
      </li>
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  inputList: {
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[25],
  },
}))
