import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { openMap } from '@/libs/googleMap'
import { useMerchant, useAddress as useMerchantAddress } from '@/hooks/merchant'

import DialogBase from '@/components/Dialogs/DialogBase'
import Openings from './Openings'
import RestaurantLogo from '@/components/RestaurantLogo'

export default function MerchantDetailDialog (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const merchant = useMerchant()
  const merchantAddress = useMerchantAddress()
  const open = useSelector(state => state.app.dialogs.merchantDetail.open)

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('merchantDetail'))
  }

  const handleOpenMap = () => {
    openMap(merchant)
  }

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      renderContent={() => (
        <div className={classes.card}>
          <section className={classes.info}>
            <Typography variant='h5' className={classes.title}>
              {merchant?.name}
            </Typography>
            <RestaurantLogo />
          </section>
          <section className={classes.detail}>
            <Link
              target='_blank'
              rel='noopener'
              variant='caption'
              onClick={handleOpenMap}
              className={classes.address}
            >
              {merchantAddress}
            </Link>
            {/* <Link
              target='_self'
              variant='caption'
              className={classes.contact}
              underline={merchant?.contact ? 'hover' : 'none'}
              href={`tel:${merchant?.contact}`}
            >
              {merchant?.contact || '-'}
            </Link> */}
            <Openings />
          </section>
        </div>
      )}
      customClasses={{ content: classes.customContent }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  customContent: {
    overflow: 'initial',
  },
  card: {
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2, 1.5),
  },
  info: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  logo: {
    position: 'absolute',
    right: 0,
    bottom: '10%',
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[500],
    width: props => props.avatarSize,
    height: props => props.avatarSize,
    backgroundImage: props => `url(${props.logoImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  title: {
    // padding: theme.spacing(0, 1.5),
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5, 0),
  },
  address: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  contact: {
    color: theme.palette.info.main,
    '&:before': {
      content: '"tel:"',
      marginRight: theme.spacing(0.5),
    },
  },
}))
