import { Container } from '@material-ui/core'
import { Element } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

PageContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

/**
 *
 * @param {{
 * id?: string
 * className?: string
 * children?: any
 * }} props
 */
export default function PageContainer (props) {
  const { id = 'pageContainer', className, children, scrolling, containerRef, ...rest } = props
  const classes = useStyles(props)

  return (
    <Container
      ref={containerRef}
      id={id}
      className={clsx(classes.pageContainer, className)}
      {...rest}
    >
      <Element className={classes.topAnchor} name={`${id}--top`} />
      {children}
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  pageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  topAnchor: {
    padding: '0 !important',
    margin: '0 !important',
  },
}))
