import { Collapse, List } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import ExpandButton from '@/components/Drawers/common/ExpandButton'
import GroupItem from '@/components/Groups/GroupItem'
import hooks from '@/hooks'

/* eslint-disable no-unused-vars */
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'
import { IMenuOptionGroup, IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

OptionItemList.propTypes = {
  group: PropTypes.object,
  list: PropTypes.array,
  max: PropTypes.number,
  menu: PropTypes.object,
}

/**
 *
 * @param {{
 * group: IMenuOptionGroup
 * list: IMenuOptionItem[]
 * max?: Number
 * menu: IAppMenuItem | IAppSet
 * }} props
 * @returns
 */
export default function OptionItemList (props) {
  const { group: optionGroup, list, max, menu } = props
  const isSet = Boolean(menu.step)
  const dispatch = useDispatch()
  const batchItem = isSet
    ? hooks.batchItemTemp.useBatchSetItemTemp()
    : hooks.batchItemTemp.useBatchItemTemp()
  const options = batchItem.options
  const selectedCount = React.useMemo(() => _.reduce(options, (acc, opt) => {
    const match = opt.optionGroupId === optionGroup.id
    return match ? acc + opt.quantity : acc
  }, 0), [options, optionGroup.id])
  const isMax = optionGroup.max > 0 && selectedCount >= optionGroup.max
  const isMultiple = optionGroup.multiple && optionGroup.max !== 1 // group.max === 1 的情況即為單選

  const [expanded, setExpanded] = useState(false)
  const collapsedList = _.take(list, max ?? list.length)
  const remainList = _.drop(list, max)

  useEffect(() => {
    // 自動選滿選項群底下的全部選項
    dispatch(actions.batchItemTemp.canAutoSelectOptions(
      menu,
      optionGroup,
      actions.batchItemTemp.selectAllOptions,
    ))
  }, [])

  const renderGroupItems = (list) => {
    return _.map(list, (option, index) => {
      const isSelected = Boolean(_.find(options, opt => opt.optionItemId === option.id))
      const isStepper = isMultiple && (option.max || optionGroup.max) > 1
      const isDisabled = shouldDisable()

      function shouldDisable () {
        // 條件與 canAutoSelectOptions 基本上一致
        // 除了 if (!isSelected) return true
        if (!isMax) return false

        if (!isMultiple) {
          // radio 當選項群為單選，且群組選項只有一個，且為單選 （只有一個 radio），且不可不選
          if (_.size(optionGroup.options) === 1 && optionGroup.min === 1) return true
          return false
        }

        if (isMultiple) {
          // stepper 選項群為複選，且群組選項只有一個，且為多選（只有一個 stepper）
          if (_.size(optionGroup.options) === 1 && optionGroup.options[0].max > 1 && optionGroup.options[0].max === optionGroup.min) return true

          // checkbox 選項群為複選，且群組選項最少需點數量與群組選項數量相等，且每個選項皆為單選（全部都是 checkbox）
          if (_.size(optionGroup.options) === optionGroup.min && _.every(optionGroup.options, option => option.max <= 1)) return true
          if (!isSelected) return true

          return false
        }
      }

      const handleClick = () => {
        if (isDisabled) return
        dispatch(actions.batchItemTemp.updateOptions(option, optionGroup, isSet))
      }

      if (isStepper) {
        return <GroupItem key={index} variant='optionStepper' item={option} group={optionGroup} disabled={isDisabled} disableIncrement={isMax} />
      }
      return (
        isMultiple
          ? <GroupItem key={index} variant='checkbox' item={option} selected={isSelected} disabled={isDisabled} onClick={handleClick} />
          : <GroupItem key={index} variant='radio' item={option} selected={isSelected} disabled={isDisabled} onClick={handleClick} />
      )
    })
  }

  return (
    <List>
      {renderGroupItems(collapsedList)}

      <Collapse in={expanded}>
        {renderGroupItems(remainList)}
      </Collapse>

      <Collapse in={!expanded && remainList.length > 0}>
        <ExpandButton
          number={remainList.length}
          onClick={() => setExpanded(true)}
        />
      </Collapse>
    </List>
  )
}
