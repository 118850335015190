import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import constants from '@/constants'

const { STORE_DELIVERY } = constants.deliveryType
const { PENDING, CONFIRMED, READY, COMPLETED, DELIVERED, CANCELLED } = constants.orderTakeawayStatus

export default function OrderActionButton (props) {
  const { order } = props
  const history = useHistory()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isD2CWeb = useSelector(state => state.app.params?.isD2CWeb)

  const TrackingButton = () => (
    <ButtonBase
      className={clsx(classes.button, classes.trackButton)}
      onClick={(e) => {
        e.stopPropagation()
        dispatch(actions.orderHistory.selectOrder(order.id))
        history.push('/order_tracking')
      }}
    >
      <Typography variant='body2' className={classes.bold}>
        {t('app.page.order_history_list.order_action.track')}
      </Typography>
    </ButtonBase>)

  const OrderAgainButton = () => (
    <ButtonBase
      className={classes.button}
      onClick={(e) => {
        e.stopPropagation()
        if (isD2CWeb) {
          history.push(`/d2c/${order.merchantId}`)
        } else {
          history.push(`/restaurant/${order.merchantId}`)
        }
      }}
    >
      <Typography variant='body2' className={classes.bold}>
        {t('app.page.order_history_list.order_action.delivered')}
      </Typography>
    </ButtonBase>)

  const renderButton = () => {
    switch (order.takeawayStatus) {
      case PENDING :
      case CONFIRMED :
      case READY :
        return <TrackingButton />
      case COMPLETED:
        if (order.deliveryType === STORE_DELIVERY) {
          return <TrackingButton />
        }
        // 外帶的completed是最後一個status了
        return <OrderAgainButton />
      case DELIVERED:
        return <OrderAgainButton />
      case CANCELLED:
        return (
          <div className={classes.button}>
            <Typography variant='body2' className={classes.bold} align='center'>
              {t('app.page.order_history_list.order_action.cancelled')}
            </Typography>
          </div>
        )
      default:
        return null
    }
  }

  return renderButton()
}

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: '35%',
    alignSelf: 'flex-end',
    border: `1px solid ${theme.palette.text.primaryLight}`,
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    opacity: '50%',
  },
  trackButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    opacity: '100%',
  },
  bold: {
    fontWeight: '600',
  },
}))
