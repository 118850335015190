import React from 'react'

import OrderTrackingSnackbar from './OrderTrackingSnackbar'

export default function Snackbars (props) {
  return (
    <>
      <OrderTrackingSnackbar />
    </>
  )
}
