import { ActionExtension } from '@dimorder/capacitor-action-extension'
import { Alipay } from '@dimorder/ionic-native-alipay'
import { AppLauncher } from '@capacitor/app-launcher'
import { Capacitor } from '@capacitor/core'
import { WebIntent } from '@ionic-native/web-intent'
import { Wechat } from '@dimorder/ionic-native-wechat'
import { v4 as uuid } from 'uuid'
import CryptoJS from 'crypto-js'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

import { Platforms, getPlatform } from '@/libs/platform'
import { actions } from '@/redux'
import { createFiservForm } from '@/libs/fiserv'
import { get3DSUrlFor2c2p, getCardProviderKey } from '@/libs/paymentMethod'
import { getApplePaySession, getOrderPayment, getOutTradeNumber } from '@/libs/payments'
import DeliveryType from '@/constants/deliveryType'
import MessageType from '@/constants/message'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethod, { supportedIssuersFor2c2p, tokenType2c2p } from '@/constants/paymentMethod'
import config from '@/config'
import customerApi from '@/libs/api/customer'
import dimorderApi from '@/libs/api/dimorder'
import handleAxiosError from '@/libs/handleAxiosError'
import history from '@/libs/history'
import i18n from '@/i18n'
import logger from '@/libs/logger'
import openBrowser from '@/libs/openBrowser'

import ActionTypes from './ActionTypes'

/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppOrder} IAppOrder */
/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppPayment} IAppPayment */
/** @typedef {import('dimorder-orderapp-lib/dist/types/Order').IOctopusPaymentData} IOctopusPaymentData */
/** @typedef {import('./PaymentState').FiservConnectData} FiservConnectData */

const {
  RECENT,
  BYPASS,
  CREDIT_CARD,
  APPLE_PAY,
  WECHAT_PAY,
  ALI_PAY,
  FPS,
  PAY_ME,
  OCTOPUS,
} = PaymentMethod
const {
  PAYMENT_GATEWAY_BYPASS,
  PAYMENT_GATEWAY_QFPAY,
  PAYMENT_GATEWAY_FPS,
  PAYMENT_GATEWAY_2C2P,
  PAYMENT_GATEWAY_FISERV,
} = PaymentGateway

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return (dispatch) => {
    dispatch({ type: ActionTypes.INIT })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restoreSavePayment (savePayment) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SAVE_PAYMENT,
      payload: { savePayment },
    })
  }
}

/**
 * 若有 savePayment
 * @returns {ThunkFunction}
 */
export function selectDefaultPayment () {
  return (dispatch, getState) => {
    const savePayment = getState().payment.savePayment
    const memberPayments = getState().user.member?.payments ?? []
    const creditCardGateway = getState().app.paymentGateway.creditcard

    if (savePayment && creditCardGateway === PAYMENT_GATEWAY_2C2P) {
      const defaultPayment = memberPayments.find((payment) => payment.default)

      if (!defaultPayment) {
        return
      }

      const {
        payment_scheme: paymentScheme,
        card_token: cardToken,
        card_no: cardNo,
      } = defaultPayment

      dispatch(updatePaymentMethod(CREDIT_CARD))
      dispatch(updatePaymentGateway(PAYMENT_GATEWAY_2C2P))
      dispatch(update2c2pToken({
        token: cardToken,
        last4: cardNo.slice(-4),
        cardType: paymentScheme,
        tokenType: tokenType2c2p.CARD_TOKEN,
      }))
      return
    }

    // 其他選擇 null
    dispatch(updatePaymentMethod(null))
  }
}

/**
 * 跳到其他apps付款前先記錄用戶的付款方法
 * @param {TPaymentMethod} payingMethod
 * @returns {ThunkFunction}
 */
export function updatePayingOutside (payingOutside) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_PAYING_OUTSIDE,
      payload: { payingOutside },
    })
  }
}

/**
 * 選擇 payment method
 * @returns {ThunkFunction}
 */
export function updatePaymentMethod (paymentMethod) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_METHOD,
      payload: { paymentMethod },
    })
  }
}

/**
 * 選擇 payment gateway
 * @returns {ThunkFunction}
 */
export function updatePaymentGateway (paymentGateway) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
      payload: { paymentGateway },
    })
  }
}

/**
 * 更改是否儲存支付方式
 * @returns {ThunkFunction}
 */
export function updateSavePayment (savePayment) {
  return async (dispatch, getState) => {
    const isLogin = Boolean(getState().user.member?.id)
    const options = getState().user.member?.options

    if (isLogin && (!options || options.savePayment !== savePayment)) {
      customerApi.updateCustomerInfo({ options: { ...options, savePayment } })
    }

    dispatch({
      type: ActionTypes.UPDATE_SAVE_PAYMENT,
      payload: { savePayment },
    })
  }
}

/**
 * 更新 2c2p encryptedTokenData
 * @returns {ThunkFunction}
 */
export function update2c2pToken (encryptedTokenData) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_2C2P_TOKEN,
      payload: encryptedTokenData,
    })
  }
}

/**
 * 重置 payment
 * @returns {ThunkFunction}
 */
export function resetPayment () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_PAYMENT,
    })
  }
}

export function updatePaymentQRCode (qrcode) {
  return {
    type: ActionTypes.UPDATE_PAYMENT_QRCODE,
    payload: qrcode,
  }
}

/**
 * @param {boolean} showQRCode
 * @param {string?} paymentMethod default: payment.selectedPaymentMethod
 */
export function updatePaymentQRCodeStatus (showQRCode, paymentMethod = null) {
  return {
    type: ActionTypes.UPDATE_PAYMENT_QRCODE_STATUS,
    payload: { showQRCode, paymentMethod },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getQFPayOrderStatus (outTradeNo, options = {}) {
  return async (dispatch, getState) => {
    let type = ''
    const selectedPaymentMethod = getState().payment.selectedPaymentMethod
    if (selectedPaymentMethod === WECHAT_PAY) { type = 'in_app' }
    const qfpay = await dimorderApi.order.getQFPayOrderStatus(outTradeNo, type, options)
    return qfpay.respcd
  }
}

export function update2c2p3DSUrl (url) {
  return {
    type: ActionTypes.UPDATE_2C2P_3DS_URL,
    payload: url,
  }
}

/**
 * 第三方付款結束後要回到哪個 url
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function getPaymentReturnUrl (order, is2c2pPayme = false) {
  return (dispatch, getState) => {
    const isD2CWeb = getState().app.params.isD2CWeb
    const orderId = order.id
    const merchantId = order.merchantId
    const isLogin = Boolean(getState().user.member?.id)
    const isNative = Capacitor.isNativePlatform()
    const search = new URLSearchParams()

    if (is2c2pPayme && isNative) {
      // CA-1439
      // 原本在 APP 中前往 2c2p Payme 付款後，從 2c2p 回到 App 會有問題
      // 需要往 receipt.dimorder.com 顯示返回 App 的頁面引導使用者回到 App
      return `${config.receiptUrl}/order_tracking/${orderId}?isLogin=1&returnApp=1&init=1`
    }

    // 以下為非 2c2p Payme，不需使用 receiptUrl 和 returnApp=1

    // D2C Web 不要回到 app，因此不用 universalBaseUrl，繼續用 d2c 餐廳 url
    // 非 D2C Web 優先回到 App，所以使用 universalBaseUrl (app.dimorder.com)
    let returnUrl = isD2CWeb
      ? `${window.origin}/d2c/${merchantId}`
      : config.universalBaseUrl

    // 加上 /order_tracking/:oid
    returnUrl += `/order_tracking/${orderId}`

    // 因為已經在付款了，點餐那些都可以結束了，加上 init 避免還原到之前的 params
    search.set('init', 1)
    if (isLogin) {
      // 若有登入，帶 isLogin=1 query
      search.set('isLogin', 1)
    }

    // CA-1489 不管 search params 有沒有內容都要加上 ?，因為第三方付款 App 可能會在後面加東西，如果沒加上 ? 會被判斷成網址的一部份
    returnUrl += ('?' + search.toString())

    return returnUrl
  }
}

/**
 * 處理第三方付款的準備
 * @param {IAppOrder} order
 * @param {HandleAxiosErrorOptions} options
 * @returns {ThunkFunction}
 */
export function createPaymentOrder (order, options) {
  return async (dispatch, getState) => {
    try {
      const token2c2p = getState().payment.token2c2p
      const savePayment = getState().payment.savePayment

      const selectedPaymentMethod = getState().payment.selectedPaymentMethod
      const selectedPaymentGateway = getState().payment.selectedPaymentGateway

      logger.log(`[createPaymentOrder] paymentMethod: ${selectedPaymentMethod}, gateway: ${selectedPaymentGateway}`)

      let payment = null
      switch (selectedPaymentMethod) {
        case BYPASS:
          payment = {
            paymentMethod: BYPASS,
            gateway: PAYMENT_GATEWAY_BYPASS,
            source: 'dimdan',
          }
          break
        case CREDIT_CARD: {
          if (selectedPaymentGateway === PAYMENT_GATEWAY_FISERV) {
            payment = await dispatch(actions.payment.createCreditCardPaymentFiserv(order))
          }
          if (selectedPaymentGateway === PAYMENT_GATEWAY_2C2P && token2c2p) {
            if (!supportedIssuersFor2c2p.includes(token2c2p.cardType)) {
              throw new Error(`2C2P_UNSUPPORTED_CARD_TYPE_${token2c2p.cardType}`)
            }
            payment = {
              paymentMethod: getCardProviderKey(token2c2p.cardType),
              gateway: PAYMENT_GATEWAY_2C2P,
              source: token2c2p.token,
              payload2c2p: {
                sourceType: tokenType2c2p.SECURE_PAY_TOKEN,
                shouldSaveCard: savePayment,
              },
            }
          }
          break
        }
        case RECENT:
          if (selectedPaymentGateway === PAYMENT_GATEWAY_2C2P && token2c2p) {
            payment = {
              paymentMethod: getCardProviderKey(token2c2p.cardType),
              gateway: PAYMENT_GATEWAY_2C2P,
              source: token2c2p.token,
              payload2c2p: {
                sourceType: tokenType2c2p.CARD_TOKEN,
                shouldSaveCard: savePayment,
              },
            }
          }
          break
        case APPLE_PAY:
          if (selectedPaymentGateway === PAYMENT_GATEWAY_FISERV) {
            payment = await dispatch(actions.payment.createApplePayPaymentFiserv(order))
          }
          if (selectedPaymentGateway === PAYMENT_GATEWAY_2C2P) {
            payment = await dispatch(actions.payment.createApplePayPayment2C2P(order))
          }
          break
        case WECHAT_PAY:
          payment = await dispatch(actions.payment.createWechatPayment(order))
          break
        case ALI_PAY:
          payment = await dispatch(actions.payment.createAliPayment(order))
          break
        case FPS:
          payment = await dispatch(actions.payment.createFPSPayment(order))
          break
        case PAY_ME: {
          if (selectedPaymentGateway === PAYMENT_GATEWAY_2C2P) {
            payment = await dispatch(actions.payment.createPayMePayment2C2P(order))
          }
          if (selectedPaymentGateway === PAYMENT_GATEWAY_QFPAY) {
            payment = await dispatch(actions.payment.createPayMePaymentQfpay(order))
          }
          break
        }
        case OCTOPUS: {
          payment = await dispatch(actions.payment.createOctopusPayment(order))
          break
        }
        default:
          break
      }

      return _.isObject(payment)
        ? {
          id: uuid(),
          amount: order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal, // 讓後端檢查應付金額是否正確
          ...payment,
        }
        : null
    } catch (error) {
      // 統一處理 create payment error. 再throw出去
      handleAxiosError(error, {
        loggerPrefix: options.loggerPrefix,
        loggerExtraData: { order },
        notAxiosErrorHandler: () => {
          if (error?.message === 'WECHAT_UNINSTALL') {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.wechat.uninstall_title'),
              message: i18n.t('app.component.alert.wechat.uninstall_message'),
            }))
          } else if (error?.message === 'APPLEPAY_CANCEL') {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.applepay2c2p.cancel_title'),
              message: i18n.t('app.component.alert.applepay2c2p.cancel_message'),
            }))
          } else if (error?.message === 'APPLEPAY_VALIDATION_ERROR') {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.applepay2c2p.validation_error_title'),
              message: i18n.t('app.component.alert.applepay2c2p.validation_error_message'),
            }))
          } else if (error?.message === 'APPLEPAY_AUTHORIZATION_ERROR') {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.applepay2c2p.authorization_error_title'),
              message: i18n.t('app.component.alert.applepay2c2p.authorization_error_message'),
            }))
          } else {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.create_payment_error.title'),
              message: i18n.t('app.component.alert.create_payment_error.message'),
            }))
          }
        },
        responseErrorHandler: () => {
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.create_payment_error.title'),
            message: i18n.t('app.component.alert.create_payment_error.message'),
          }))
        },
      })
      throw error
    }
  }
}

/**
 * 前往第三方付款
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function launchPayment (order) {
  return async (dispatch, getState) => {
    const memberId = getState().user.member.id

    /**
     *
     * @param {IAppPayment} payment
     * @returns {string | undefined}
     */
    function getPaymentPayload (payment) {
      try {
        if (payment?.payload && typeof payment.payload === 'string' && payment.payload !== '') {
          return JSON.parse(payment.payload)
        }
      } catch (error) {
        logger.error(`[launchPayment] payment.payload parse error: ${error.message}`, { order, error })
      }
    }

    const orderPayment = getOrderPayment(order.payments, memberId)

    if (!orderPayment) return

    const payload = getPaymentPayload(orderPayment)

    const alertTitle = orderPayment?.paymentMethod === WECHAT_PAY
      ? i18n.t('app.component.alert.wechat.hint_title')
      : i18n.t('app.component.alert.launch_payment.title')
    const alertMessage = orderPayment?.paymentMethod === WECHAT_PAY
      ? i18n.t('app.component.alert.wechat.hint_message')
      : i18n.t('app.component.alert.launch_payment.message')

    const launchPaymentActions = {
      [CREDIT_CARD]: launchCreditCardPayment,
      [WECHAT_PAY]: launchWechatPay,
      [ALI_PAY]: launchAliPay,
      [FPS]: launchFPS,
      [PAY_ME]: launchPayMe,
      [OCTOPUS]: launchOctopus,
    }

    const launchPaymentAction = launchPaymentActions[orderPayment?.paymentMethod]

    if (!launchPaymentAction) return

    const alertConfig = {
      title: alertTitle,
      message: alertMessage,
      dialogProps: { disableBackdropClick: true },
      buttons: [
        {
          text: i18n.t('app.common.confirm'),
          onClick: () => {
            // 啟動付款
            dispatch(launchPaymentAction(order, payload, orderPayment))
          },
        },
      ],
    }

    dispatch(actions.app.toggleAlert(alertConfig))
  }
}

// * Create Payments

/**
 * @param {IAppOrder} order
 * @param {object?} options
 * @param {string?} options.outTradeNumber
 * @param {number?} options.amount
 * @returns {ThunkFunction}
 */
export function createWechatPayment (order, options = {}) {
  return async (dispatch) => {
    logger.log('[createWechatPayment]')
    const returnUrl = dispatch(getPaymentReturnUrl(order))
    const REDIRECT_URL = encodeURIComponent(returnUrl)

    const outTradeNo = options.outTradeNumber ?? getOutTradeNumber(WECHAT_PAY, order)

    logger.log('[createWechatPayment][1/5] outTradeNo Done', { outTradeNo })
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal // 還沒有 submit 過的訂單需要使用 roundedTotalWithRiceCoinDiscount 才會包含 RiceCoin 折扣；submit 過的訂單不會有 roundedTotalWithRiceCoinDiscount 且 roundedTotal 已包含 RiceCoin 折扣
    const txamt = options.amount ?? parseInt(amount * 100, 10)
    const currency = 'HKD'

    const payTypeMap = {
      [Platforms.APP]: '800210',
      [Platforms.MOBILE_WEB]: '800212', // H5
      [Platforms.WEB]: '800201', // QCR
    }
    const platform = await getPlatform()
    const payType = payTypeMap[platform]

    logger.log('[createWechatPayment][2/5] getPlatform Done', { platform })

    const payment = {
      paymentMethod: WECHAT_PAY,
      gateway: PAYMENT_GATEWAY_QFPAY,
      type: 'in_app',
      source: outTradeNo,
    }

    if ([Platforms.WEB, Platforms.MOBILE_WEB].includes(platform)) {
      logger.log('[createWechatPayment][web]')
      logger.log('[createWechatPayment][web][3/5] skip')
      const result = await dimorderApi.order.createWechatPayment({
        txamt,
        pay_type: payType,
        txdtm: new Date().toISOString().slice(0, 19).replace('T', ' '),
        out_trade_no: outTradeNo,
        txcurrcd: currency,
        extend_info: {
          scene_info: {
            h5_info: {
              type: 'Wap',
              wap_url: 'https://www.dimorder.com/',
              wap_name: 'dimorder',
            },
          },
        },
      })

      logger.log('[createWechatPayment][web][4/5] createWechatPayment Done', { result })

      payment.syssn = result.syssn
      payment.payUrl = platform === Platforms.MOBILE_WEB
        ? `${result.pay_url}&redirect_url=${REDIRECT_URL}`
        : result.qrcode
    } else {
      logger.log('[createWechatPayment][native]')
      const installed = await Wechat.isInstalled()

      logger.log('[createWechatPayment][native][3/5] check installed Done', { installed })

      if (!installed) {
        throw new Error('WECHAT_UNINSTALL')
      }

      const payLoad = {
        txamt,
        pay_type: payType,
        out_trade_no: outTradeNo,
        txcurrcd: currency,
      }

      const wechatData = await dimorderApi.order.createWechatPayment(payLoad)
      logger.log('[createWechatPayment][native][4/5] createWechatPayment Done', { wechatData })
      const payParams = _.pick(wechatData.pay_params, ['partnerid', 'prepayid', 'noncestr', 'timestamp', 'sign'])
      payParams.sign = payParams.sign.slice(0, -2)

      payment.syssn = wechatData.syssn
      payment.payParams = payParams
    }

    payment.payload = JSON.stringify({
      payUrl: payment.payUrl,
      payParams: payment.payParams,
    })
    logger.log(`[createWechatPayment][5/5] success paymentId: ${payment.id}`, { order, payment })
    return payment
  }
}

/**
 * @param {IAppOrder} order
 * @param {object?} options
 * @param {string?} options.outTradeNumber
 * @param {number?} options.amount
 * @returns {ThunkFunction}
 */
export function createAliPayment (order, options = {}) {
  return async (dispatch) => {
    const outTradeNo = options.outTradeNumber ?? getOutTradeNumber(ALI_PAY, order)
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal // 還沒有 submit 過的訂單需要使用 roundedTotalWithRiceCoinDiscount 才會包含 RiceCoin 折扣；submit 過的訂單不會有 roundedTotalWithRiceCoinDiscount 且 roundedTotal 已包含 RiceCoin 折扣
    const txamt = options.amount ?? parseInt(amount * 100, 10)
    const currency = 'HKD'

    const payment = {
      paymentMethod: ALI_PAY,
      gateway: PAYMENT_GATEWAY_QFPAY,
      source: outTradeNo,
    }

    const payTypeMap = {
      [Platforms.APP]: '801510',
      [Platforms.MOBILE_WEB]: '801512', // H5
      [Platforms.WEB]: '801501', // QCR
    }
    const platform = await getPlatform()
    const payType = payTypeMap[platform]

    if ([Platforms.WEB, Platforms.MOBILE_WEB].includes(platform)) {
      const returnUrl = dispatch(getPaymentReturnUrl(order))
      const aliData = await dimorderApi.order.createAliPayment({
        txamt,
        pay_type: payType,
        out_trade_no: outTradeNo,
        return_url: returnUrl,
        txdtm: new Date().toISOString().slice(0, 19).replace('T', ' '),
        txcurrcd: currency,
      })

      payment.syssn = aliData.syssn
      payment.payUrl = aliData.pay_url
    } else {
      const payLoad = {
        txamt,
        pay_type: payType,
        out_trade_no: outTradeNo,
        txcurrcd: currency,
        goods_name: outTradeNo,
        goods_info: options.goodsInfo ?? `DimOrder - ${order.serial}`,
        return_url: 'http://www.qfpay.com/', // alipay 有辦法直接打開我們的 APP 因此不用設定我們的 returnUrl
      }
      const aliData = await dimorderApi.order.createAliPayment(payLoad)

      payment.syssn = aliData.syssn
      payment.payParams = aliData.pay_params
    }

    payment.payload = JSON.stringify({
      payUrl: payment.payUrl,
      payParams: payment.payParams,
    })
    console.log('createAliPayment 6')
    // logger.log(`[createAliPayment] paymentId: ${payment.id}`, { order, payment })
    return payment
  }
}

/**
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function createFPSPayment (order) {
  return async () => {
    const remark = 'orderapp@' + PAYMENT_GATEWAY_FPS + '@' + config.env + '@' +
      order.merchantId + '@' +
      order.id.split('-')[0] + '@' + Math.floor(Date.now() / 1000)

    const payment = {
      paymentMethod: FPS,
      gateway: PAYMENT_GATEWAY_FPS,
      source: remark,
    }

    payment.payload = JSON.stringify({
      source: payment.source,
    })
    logger.log(`[createFPSPayment] paymentId: ${payment.id}`, { order, payment })
    return payment
  }
}

/**
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function createPayMePaymentQfpay (order) {
  return async (dispatch) => {
    const outTradeNo = getOutTradeNumber(PAY_ME, order)
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal // 還沒有 submit 過的訂單需要使用 roundedTotalWithRiceCoinDiscount 才會包含 RiceCoin 折扣；submit 過的訂單不會有 roundedTotalWithRiceCoinDiscount 且 roundedTotal 已包含 RiceCoin 折扣
    const txamt = parseInt(amount * 100, 10)
    const currency = 'HKD'
    const returnUrl = dispatch(getPaymentReturnUrl(order))

    // 805814 PayMe Online WEB (in browser Chrome etc.) Payment (HK Merchants)
    // 805812 PayMe Online WAP (in mobile browser Chrome etc.) Payment (HK Merchants)
    const payTypeMap = {
      [Platforms.APP]: '805812',
      [Platforms.MOBILE_WEB]: '805814',
      [Platforms.WEB]: '805812', // show qrcode
    }
    const platform = await getPlatform()
    const payType = payTypeMap[platform]

    const payment = {
      paymentMethod: PAY_ME,
      gateway: PAYMENT_GATEWAY_QFPAY,
      source: outTradeNo,
    }
    let payload = {} // 存在這 for logger.log

    if ([Platforms.WEB, Platforms.MOBILE_WEB].includes(platform)) {
      payload = {
        txamt,
        pay_type: payType,
        out_trade_no: outTradeNo,
        txcurrcd: currency,
      }

      // desktop web 會直接在 order tracking 畫面中出現 QRCode dialog，用手機掃描付款
      // 付款完成後就會關閉 QRCode dialog 因此不需要設定 returnUrl
      if (platform === Platforms.MOBILE_WEB) {
        payload.return_url = returnUrl
      }

      logger.log('[createPayMePaymentQfpay] request', { payload })
      const payMeData = await dimorderApi.order.createPayMePaymentQfpay(payload)
      logger.log('[createPayMePaymentQfpay] response', { payMeData })

      payment.syssn = payMeData.syssn
      payment.payUrl = payMeData.pay_url
    } else {
      payload = {
        txamt,
        pay_type: payType,
        out_trade_no: outTradeNo,
        txcurrcd: currency,
        goods_name: `DimOrder - ${order.serial}`,
        goods_info: `DimOrder - ${order.serial}`,
        return_url: returnUrl,
      }
      const payMeData = await dimorderApi.order.createPayMePaymentQfpay(payload)

      payment.syssn = payMeData.syssn
      payment.payUrl = payMeData.pay_url
    }

    payment.payload = JSON.stringify({
      payUrl: payment.payUrl,
    })
    logger.log(`[createPayMePaymentQfpay] paymentId: ${payment.id}`, { order, payment, payload })
    return payment
  }
}

/**
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function createPayMePayment2C2P (order) {
  return async (dispatch, getState) => {
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal
    const returnUrl = dispatch(getPaymentReturnUrl(order, true))
    const requestData = {
      orderId: order.id,
      merchantId: order.merchantId,
      deliveryType: getState().app.params.deliveryType,
      amount: amount,
      returnUrl: returnUrl,
    }
    logger.log('[createPayMePayment2C2P] createPayMePayment2c2p request data', { requestData, returnUrl })
    const paymeData = await dimorderApi.order.createPayMePayment2c2p(requestData)

    return {
      amount: amount,
      paymentMethod: 'payme',
      gateway: PaymentGateway.PAYMENT_GATEWAY_2C2P,
      ref: paymeData.ref,
      source: order.id,
      payload: JSON.stringify({ payUrl: paymeData.payUrl }),
    }
  }
}

export function createCreditCardPaymentFiserv (order) {
  return (dispatch, getState) => {
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal
    // fiserv 不能直接回到 window.origin d2c 所以這邊不使用 getPaymentReturnUrl，另外自己組 returnUrl
    const isD2CWeb = getState().app.params.isD2CWeb
    const isLogin = Boolean(getState().user.member?.id)
    const search = new URLSearchParams()
    search.set('init', 1)
    if (isLogin) {
      // 若有登入，帶 isLogin=1 query
      search.set('isLogin', 1)
    }
    const returnUrl = config.universalBaseUrl + (isD2CWeb ? `/d2c/${order.merchantId}` : '') + `/order_tracking/${order.id}?` + search.toString()

    let language = getState().app.lang
    if (language === 'ja') {
      language = 'ja-JP'
    }

    const createdAt = moment()
    const paymentId = uuid()
    logger.log('[createCreditCardPaymentFiserv] isDimPay', {
      isDimPay: order.deliveryType === DeliveryType.TABLE,
      order,
    })
    const isDimPay = order.deliveryType === DeliveryType.TABLE
    const fiservOrderId = `${order.id}@${paymentId}`
    const ponumber = `${isDimPay ? 'dp' : 'td'}@${order.serial}`
    const invoicenumber = order.merchantId

    /** @type {FiservConnectData} */
    const data = {
      ...config.fiserv.defaultData,
      responseFailURL: returnUrl,
      responseSuccessURL: returnUrl,
      oid: fiservOrderId, // 必須和 ref 相同
      chargetotal: amount,
      txndatetime: createdAt.format(config.fiserv.dateTimeFormat),
      storename: config.fiserv.storeId,
      language,
      txntype: isDimPay ? 'sale' : 'preauth',
      ponumber,
      invoicenumber,
    }

    const paramsString = Object.keys(data)
      .sort()
      .map((key) => data[key]) // Change the parameter type to `(key: keyof typeof data) => string`
      .join('|')

    const hash = CryptoJS.HmacSHA256(
      paramsString,
      config.fiserv.sharedSecret,
    )

    const hashBase64 = CryptoJS.enc.Base64.stringify(hash)
    data.hashExtended = hashBase64

    return {
      id: paymentId,
      ref: fiservOrderId,
      paymentMethod: CREDIT_CARD,
      gateway: PAYMENT_GATEWAY_FISERV,
      source: order.id,
      payload: JSON.stringify(data),
      createdAt: createdAt,
    }
  }
}

/**
 * @param {IAppOrder} order
 * @returns {ThunkFunction}1
 */
export function createApplePayPayment2C2P (order) {
  return async () => {
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal
    const applePaySession = getApplePaySession()
    if (!applePaySession) throw new Error('APPLEPAY_NOT_SUPPORTED')
    logger.log('[ApplePay] start await onvalidatemerchant')

    // Validate merchant with server
    const validationURL = await new Promise((resolve, reject) => {
      applePaySession.onvalidatemerchant = (event) => {
        resolve(event?.validationURL)
      }
      applePaySession.oncancel = () => {
        reject(new Error('APPLEPAY_CANCEL'))
      }
      applePaySession.begin()
    })
    if (!validationURL) throw new Error('APPLEPAY_VALIDATION_ERROR')

    const postData = {
      appleUrl: validationURL,
      merchantId: config.applePay2c2p.merchantId,
      domainName: window.location.hostname.includes('localhost')
        ? config.applePay2c2p.merchantDomain
        : window.location.hostname,
      displayName: 'DimOrder',
    }

    logger.log('[ApplePay] before request /validateSession', { postData })

    const merchantSession = await dimorderApi.order.validateSession(postData)

    logger.log('[ApplePay] after request /validateSession', { merchantSession })

    logger.log('[ApplePay] start await onpaymentauthorized')
    const token = await new Promise((resolve, reject) => {
      applePaySession.onpaymentauthorized = (event) => {
        logger.log('[ApplePay] onpaymentauthorized', { event, eventPayment: event?.payment })
        const cb = e => {
          /** @type {IWindowEventData} */
          const data = e?.data
          if (data?.type === MessageType.APPLE_PAY_ORDER_CALLBACK_EVENT) {
            logger.log('[ApplePay] on message handler event:', { data })
            /** @type {IAppOrder?} */
            const order = data.payload?.order
            if (order?.status === 'paid') {
              applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS)
            } else {
              applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE)
            }
            window.removeEventListener('message', cb)
          }
        }
        window.addEventListener('message', cb)
        resolve(event?.payment.token)
      }
      applePaySession.oncancel = () => {
        reject(new Error('APPLEPAY_CANCEL'))
      }
      applePaySession.completeMerchantValidation(merchantSession)
    })
    if (!token) throw new Error('APPLEPAY_AUTHORIZATION_ERROR')

    return {
      paymentMethod: 'applepay',
      gateway: PaymentGateway.PAYMENT_GATEWAY_2C2P,
      amount: amount,
      paymentToken2c2p: Buffer.from(JSON.stringify(token.paymentData)).toString('base64'),
      source: order.id,
      applePaySession: applePaySession,
    }
  }
}

export function createOctopusPayment (order) {
  return async () => {
    const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal
    const response = await dimorderApi.order.createOctopusPayment({
      amount,
      orderId: order.id,
    })

    return {
      amount: amount,
      paymentMethod: OCTOPUS,
      gateway: OCTOPUS,
      ref: response.gatewayRef,
      source: response.token,
      payload: JSON.stringify(response),
    }
  }
}

// * Launch Payments

/**
 * @param {IAppOrder} order
 * @param {FiservConnectData} data
 * @param {IAppPayment} payment
 * @returns {ThunkFunction}
 */
export function launchCreditCardPayment (order, data, payment) {
  return async () => {
    if (payment.gateway === PAYMENT_GATEWAY_FISERV) {
      logger.log('[Fiserv] launchCreditCardPayment()', { orderId: order.id, serial: order.serial, payment, data })

      const isApp = await Capacitor.isNativePlatform()
      const platform = Capacitor.getPlatform()
      if (isApp && platform === 'ios') {
        // FIXME: need full data?
        const params = new URLSearchParams()
        params.append('orderId', order.id)
        params.append('paymentId', payment.id)
        const url = config.webUrl + '/launch_fiserv?' + params.toString()

        openBrowser(url)
        return
      }

      const form = createFiservForm(data)
      form.submit()
    }
  }
}

/**
 * @param {IAppOrder} order
 * @param {IPaymentData} data
 * @returns {ThunkFunction}
 */
export function launchWechatPay (order, data) {
  return async (dispatch) => {
    const { payParams, payUrl } = data
    const platform = await getPlatform()

    if (!payUrl && platform !== Platforms.APP) {
      // 處理經後端請求第三方付款失敗後仍回傳 200 但沒有 payUrl 而轉頁至白畫面的問題 (CA-1370)
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.launch_payment_error.title'),
        message: i18n.t('app.component.alert.launch_payment_error.message'),
      }))
      return
    }

    if (platform === Platforms.MOBILE_WEB) {
      // 手機網頁版
      window.location.href = payUrl
    } else if (platform === Platforms.WEB) {
      // 電腦網頁版
      dispatch(actions.payment.updatePaymentQRCode(payUrl))
      dispatch(actions.payment.updatePaymentQRCodeStatus(true, WECHAT_PAY))
    } else {
      // 手機 App
      try {
        logger.log('[Wechat] Wechat.sendPaymentRequest() request', { orderId: order.id, serial: order.serial, payParams })
        await Wechat.sendPaymentRequest(payParams)
      } catch (error) {
        logger.error('[Wechat] Wechat.sendPaymentRequest() error', { orderId: order.id, serial: order.serial, payParams, error })
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.launch_payment_error.title'),
          message: i18n.t('app.component.alert.launch_payment_error.message'),
        }))
      }
    }
  }
}

/**
 * @param {IAppOrder} order
 * @param {IPaymentData} data
 * @returns {ThunkFunction}
 */
export function launchAliPay (order, data) {
  return async (dispatch) => {
    const { payParams, payUrl } = data
    const platform = await getPlatform()

    if (!payUrl && platform !== Platforms.APP) {
      // 處理經後端請求第三方付款失敗後仍回傳 200 但沒有 payUrl 而轉頁至白畫面的問題 (CA-1370)
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.launch_payment_error.title'),
        message: i18n.t('app.component.alert.launch_payment_error.message'),
      }))
      return
    }

    if (platform === Platforms.MOBILE_WEB) {
      // 手機網頁版
      // NOTE: no need to check if alipay is installed or not
      // TODO: check app state to make the workaround more robust
      // a workaround to check whether the scheme is a registered handler
      // if the scheme is registered, this handler will be invoked
      // let isSchemeRegistered = false
      // const windowBlurHandler = () => {
      //   isSchemeRegistered = true
      //   window.removeEventListener('blur', windowBlurHandler)
      // }
      // window.addEventListener('blur', windowBlurHandler)

      window.location.href = payUrl

      // // probe alipay app
      // setTimeout(() => {
      //   if (!isSchemeRegistered) {
      //     dispatch(actions.app.toggleAlert({
      //       title: i18n.t('app.component.alert.web_payment.title'),
      //       message: i18n.t('app.component.alert.web_payment.alipay_not_installed'),
      //     }))
      //   }
      // }, 1000)
    } else if (platform === Platforms.WEB) {
      // 電腦網頁版
      dispatch(actions.payment.updatePaymentQRCode(payUrl))
      dispatch(actions.payment.updatePaymentQRCodeStatus(true, ALI_PAY))
    } else {
      // 手機 App
      const sortObjectKeys = Object.keys(payParams).sort()
      let payString = ''
      _.forEach(sortObjectKeys, (sortObjectKey) => {
        if (sortObjectKey !== 'sign_type' && sortObjectKey !== 'sign' && payParams[sortObjectKey] !== '') {
          if (sortObjectKey === 'total_fee') {
            payString += `${sortObjectKey}="${Number(payParams[sortObjectKey]).toFixed(1)}"&`
          } else {
            payString += `${sortObjectKey}="${payParams[sortObjectKey]}"&`
          }
        }
      })
      payString += `sign="${payParams.sign}"&sign_type="${payParams.sign_type}"`
      try {
        logger.log(`[Alipay] Alipay.pay() request ${order.id}`, { orderId: order.id, serial: order.serial, payParams, payString })
        const { resultStatus } = await Alipay.pay(payString)
        logger.log(`[Alipay] Alipay.pay() success: ${resultStatus === '9000'}`, { orderId: order.id, serial: order.serial, resultStatus, payParams, payString })
      } catch (error) {
        logger.error(`[Alipay] Alipay.pay() error: ${error.message}`, { orderId: order.id, serial: order.serial, error, payParams, payString })
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.launch_payment_error.title'),
          message: i18n.t('app.component.alert.launch_payment_error.message'),
        }))
      }
    }
  }
}

/**
 * @param {IAppOrder} order
 * @param {IPaymentData} data
 * @returns {ThunkFunction}
 */
export function launchFPS (order, data) {
  return async (dispatch) => {
    const { source } = data
    const platform = Capacitor.getPlatform()

    const returnUrl = dispatch(getPaymentReturnUrl(order))

    const query = qs.stringify({
      payment: order.roundedTotal,
      remark1: source,
      orderid: order.serial,
    })
    const url = `${config.fps.baseUrl}/genQrcode?${query}`
    if (platform === 'ios') {
      try {
        logger.log('[FPS] ActionExtension.fpsPay() request', { orderId: order.id, serial: order.serial, url })
        const result = await ActionExtension.fpsPay({ callback: returnUrl, url })
        logger.log('[FPS] ActionExtension.fpsPay() result', { orderId: order.id, serial: order.serial, result })
      } catch (error) {
        logger.log('[FPS] ActionExtension.fpsPay() error', { orderId: order.id, serial: order.serial, url, error })
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.launch_payment_error.title'),
          message: i18n.t('app.component.alert.launch_payment_error.message'),
        }))
      }
      return
    }

    if (platform === 'android') {
      try {
        // call intent
        const options = {
          action: config.fps.androidAction,
          extras: { url },
        }

        logger.log('[FPS] WebIntent.startActivityForResult() request', { orderId: order.id, serial: order.serial, url, options })
        // result format: {"extras":{"resultCode":-1,"requestCode":1},"flags":0}
        /** @type {{extras: {resultCode: number, requestCode: number}, flags: number}} */
        const result = await WebIntent.startActivityForResult(options)
        logger.log('[FPS] WebIntent.startActivityForResult() result', { orderId: order.id, serial: order.serial, url, options, result })

        // resultCode -1 implies OK
        // resultCode 0 implies cancelled / failed
        if (result.extras.resultCode === -1) {
          logger.log('[FPS] WebIntent.startActivityForResult() success', { orderId: order.id, serial: order.serial, url, options, result })
        } else {
          logger.log('[FPS] WebIntent.startActivityForResult() error', { orderId: order.id, serial: order.serial, url, options, result })
        }
      } catch (error) {
        logger.log('[FPS] WebIntent.startActivityForResult() error', { orderId: order.id, serial: order.serial, url, error })
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.launch_payment_error.title'),
          message: i18n.t('app.component.alert.launch_payment_error.message'),
        }))
      }
    }
  }
}

/**
 * @param {IAppOrder} order
 * @param {IPaymentData} data
 * @returns {ThunkFunction}
 */
export function launchPayMe (order, data) {
  return async (dispatch) => {
    const { payUrl } = data
    const platform = await getPlatform()
    const isApp = await Capacitor.isNativePlatform()

    if (!payUrl) {
      // 處理經後端請求第三方付款失敗後仍回傳 200 但沒有 payUrl 而轉頁至白畫面的問題 (CA-1370)
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.launch_payment_error.title'),
        message: i18n.t('app.component.alert.launch_payment_error.message'),
      }))
      return
    }

    if (isApp) {
      // 手機 App，直接開啟 payUrl
      await AppLauncher.openUrl({ url: payUrl })
      return
    }

    if (payUrl.includes('2c2p.com') || platform === Platforms.MOBILE_WEB) {
      // 2c2p Payme 或手機網頁，直接前往 url 再跳轉
      // （2c2p Payme 的連結無法用 QRCode 掃描的方式開啟）
      window.location.href = payUrl
      return
    }

    // 電腦網頁版，在畫面中顯示 QRCode 用 PAYME 掃描付款
    dispatch(actions.payment.updatePaymentQRCode(payUrl))
    dispatch(actions.payment.updatePaymentQRCodeStatus(true, PAY_ME))
  }
}

/**
 * @param {IAppOrder} order
 * @param {IOctopusPaymentData} data
 */
export function launchOctopus (order, data) {
  return async () => {
    const platform = await getPlatform()
    const url = new URL(data.octopusUri)
    const landingUrl = new URL(platform !== Platforms.APP ? config.webUrl : config.universalBaseUrl)

    // set return url
    landingUrl.pathname = `/order_tracking/${order.id}`
    url.searchParams.set('return', landingUrl.toString())

    setTimeout(() => {
      document.location = data.landingUrl
    }, 300)

    // Redirect to Octopus Web
    window.location.href = url.toString()
  }
}

/**
 * @param {IAppOrder} order
 * @param {string} paymentMethod
 * @returns {ThunkFunction}
 */
export function payOrder (order) {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('backdrop', true))
    /**
     * 結束payOrder前記得要call這個function cleanup
     * @param {IAppOrder?} order
     */
    const endPayOrder = (responseOrder) => {
      try {
        dispatch(actions.app.updateLoading('backdrop', false))
        window.postMessage({
          type: MessageType.APPLE_PAY_ORDER_CALLBACK_EVENT,
          payload: { order: responseOrder },
        }, '*')
      } catch (error) {
        logger.error('[payOrder] endPayOrder window.postMessage', { error, id: order.id })
      }
      if (!responseOrder) {
        try {
          // 當付款失敗時再抓一次訂單避免是因為訂單為更新而失敗
          dispatch(actions.orderHistory.getOrder(order.id))
        } catch (error) {
          logger.error('[payOrder] error & getOrder error', { error, id: order.id })
        }
      }
    }

    const { isD2CWeb } = getState().app.params
    const merchantId = getState().merchant.data.id
    const d2cBaseUrl = isD2CWeb ? `/d2c/${merchantId}` : ''

    let payment
    try {
      // 用選擇的支付方式 createPaymentOrder
      payment = await dispatch(actions.payment.createPaymentOrder(order, { loggerPrefix: '[payOrder][1/4] createPaymentOrder' }))
      logger.log('[payOrder][1/4] createPaymentOrder() Done', { payment })
    } catch (error) {
      logger.log('[payOrder][1/4] createPaymentOrder() error', { error })
      endPayOrder()
      return
    }

    let responseOrder
    try {
      // request pay
      responseOrder = await dimorderApi.order.pay(order.id, payment)
      logger.log('[payOrder][2/4] pay() Done', { responseOrder })
    } catch (error) {
      handleAxiosError(error, {
        loggerPrefix: '[payOrder][2/4] pay',
        loggerExtraData: { order },
        responseErrorHandler: (errorMessage) => {
          if (errorMessage === 'incorrect payment amount') {
            // 付款金額不正確
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.incorrect payment amount.title'),
              message: i18n.t('app.component.alert.incorrect payment amount.message'),
            }))
            return
          }
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.create_payment_error.title'),
            message: i18n.t('app.component.alert.create_payment_error.message'),
          }))
        },
      })
      endPayOrder()
      return
    }

    // 更新到 orderHistory
    const memberId = getState().user.member.id
    dispatch(actions.orderHistory.updateOrder(responseOrder.payments, memberId))
    logger.log('[payOrder][3/4] updateOrder() Done')

    // 啟動 payment
    const url3DS = get3DSUrlFor2c2p(responseOrder.payments, memberId)
    if (url3DS) {
      dispatch(actions.payment.update2c2p3DSUrl(url3DS))
      logger.log('[payOrder][4/4] start 3DS challenge', { url3DS })
      history.replace(`${d2cBaseUrl}/checkout/3ds`)
    } else {
      dispatch(actions.payment.launchPayment(responseOrder))
      logger.log('[payOrder][4/4] launchPayment() Done')
    }

    endPayOrder(responseOrder)
  }
}

export function payOrderWithApplePay () {
  return () => {

  }
}

/**
 * @returns {ThunkFunction}
 */
export function getPayingMerchant (order) {
  return async (dispatch) => {
    dispatch(updateMerchantLoading(true))
    const merchant = await dimorderApi.merchant.getMerchant(order.merchantId)
    dispatch({
      type: ActionTypes.UPDATE_PAYING_MERCHANT,
      payload: { merchant },
    })
    dispatch(updateMerchantLoading(false))
  }
}

export function updateMerchantLoading (isLoading) {
  return {
    type: ActionTypes.UPDATE_PAYING_MERCHANT_LOADING,
    payload: { isLoading },
  }
}
