import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { getSpecialDateRange, getSpecialTimeRange } from '@root/src/libs/dateTimePicker'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import TestDateInput from '@/components/TestDateInput'
import config from '@/config'
import constants from '@/constants'

import DialogBase from '../DialogBase'

const { STORE_DELIVERY } = constants.deliveryType

/**
 *
 * @param {*} props
 * @returns
 */
export default function PoonchoiTimePicker (props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles(props)
  const open = useSelector(state => (
    state.app.dialogs.specialTimePicker.open &&
    state.app.dialogs.specialTimePicker.data?.type === 'moon'
  ))

  const [dateOptions, setDateOptions] = useState([])
  const [timeOptions, setTimeOptions] = useState([])
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const testDate = useSelector(state => state.app.test.date)

  const datetime = useSelector(state => state.app.params.datetime)
  const validDate = useMemo(() => _.some(dateOptions, d => moment(d).isSame(moment(datetime.date), 'day')), [dateOptions, datetime.date])
  const validTime = useMemo(() => _.some(['15:00', '18:00', '14:00', '17:00'], t => t === datetime.time), [datetime.time])
  const fetchDate = useCallback(async () => {
    const dateOptions = await getSpecialDateRange('moon', config.env !== 'prod' ? testDate : undefined)
    setDateOptions(dateOptions)
  }, [testDate])

  useEffect(() => {
    if (open) {
      if (validDate) {
        setDate(datetime.date)
        fetchTime()
        if (validTime) {
          setTime(datetime.time)
        } else {
          setTime('')
        }
      } else {
        setDate('')
        setTime('')
      }

      setDateOptions([])
      setTimeOptions([])
      fetchDate()
    }
  }, [open, testDate])

  useEffect(() => {
    setTime('')
    if (!_.isEmpty(date)) {
      fetchTime()
    }
  }, [date])

  const handleClose = () => dispatch(actions.app.toggleDialog('specialTimePicker', false, {}))

  const onConfirm = async () => {
    handleClose()
    dispatch(actions.app.updateDatetime({ date, time, isImmediate: false }))
    dispatch(actions.app.updateDeliveryType(STORE_DELIVERY))
    if (location.pathname !== '/checkout') history.pushWithSearch('/checkout')
  }

  const fetchTime = async () => {
    setTimeOptions(await getSpecialTimeRange('moon', date))
  }

  const enableDateSelect = () => {
    if (validTime) return true
    if (!_.isEmpty(timeOptions)) return true
    return false
  }

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      customClasses={{
        header: classes.hidden,
      }}
      renderContent={() => (
        <>
          <TestDateInput
            className={classes.testDateInput}
            onChange={() => {
              setDate('')
              setTime('')
            }}
          />
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='date-native-label-placeholder'>
              {t('app.component.dialog.special_time_picker.moon.date')}
            </InputLabel>
            <Select
              value={date}
              onChange={(event) => setDate(event.target.value)}
              inputProps={{
                name: 'date',
                id: 'date-native-label-placeholder',
              }}
            >
              <MenuItem key='' value='' className={classes.hidden} />
              {dateOptions.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='time-native-label-placeholder'>
              {t('app.component.dialog.special_time_picker.moon.time')}
            </InputLabel>
            <Select
              value={time}
              onChange={(event) => setTime(event.target.value)}
              inputProps={{
                name: 'time',
                id: 'time-native-label-placeholder',
              }}
              disabled={!enableDateSelect()}
            >
              <MenuItem key='' value='' className={classes.hidden} />
              {timeOptions.map(v => <MenuItem key={v} value={v?.split('-')[0]}>{v}</MenuItem>)}
            </Select>
          </FormControl>
        </>
      )}
      renderFooter={() => (
        <LetterSpacingButton
          text={t('app.common.confirm')}
          onClick={onConfirm}
          disabled={_.isEmpty(date) || _.isEmpty(time)}
        />
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  testDateInput: {
    '& > label': {
      fontSize: '1.2rem',
    },
  },
}))
