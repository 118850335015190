import { makeStyles } from '@material-ui/core/styles'
import { useBatchItemQuantity } from '@/hooks/contexts/BatchItemQuantityContext'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { createBatchSetItem } from '@/libs/order'
import { useCart } from '@/hooks/useCart'
import constants from '@/constants'
import hooks from '@/hooks'
import libs from '@/libs'

import StepperBase from './StepperBase'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 *
 * @param {{
 * menu: IAppMenuItem | IAppSet
 * setItemMenu?: IAppMenuItem
 * sold: boolean
 * innerRef: React.Ref
 * min?: number
 * max?: number
 * }} props
 * @returns
 */
export default function MenuStepper (props) {
  const {
    menu, // 單點或是套餐
    setItemMenu, // 被分拆的套餐品項
    innerRef,
    sold,
    min,
    max,
  } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { sendEvent } = hooks.useTrack()
  const merchant = hooks.merchant.useMerchant()
  const batchItemQuantity = useBatchItemQuantity()
  const { batchItems } = useCart()
  const isSet = Boolean(menu.steps)
  const isSeperated = Boolean(menu.separatedStep)
  const key = isSet ? (isSeperated ? menu.id + '/' + setItemMenu?.menuId : menu.id) : menu.id
  const quantity = batchItemQuantity[key]

  const enableQuickAddItem = React.useMemo(
    // 有些餐廳的餐點多，但單品不用客製化沒有選項和不提供寫備註（如：串燒、吃到飽…）
    // 希望點 + 時可以直接加入購物車，不跳出 drawer，以加快客人點餐速度。
    () => {
      const hasOptions = !_.isEmpty(menu.options)
      return (
        !isSet && // 單點
        !hasOptions && // 且沒有選項
        merchant?.setting?.disableItemRemarks // 且餐廳不開放餐點備註
      )
    },
    [
      menu.steps,
      menu.options,
      merchant?.setting?.disableItemRemarks,
    ],
  )

  const handleIncrement = (e) => {
    e.stopPropagation()

    if (enableQuickAddItem) {
      // 判斷這個品項總共已經點了多少個
      if (quantity > 0) {
        // 若 quantity > 0，代表已經點過這個品項，因此僅需將該品項的數量 menu.quantity += 1
        const batchItem = _.find(batchItems, item => item.menuId === menu.id) // 因為 batchItem 已存在且沒有選項或備註，因此應必找且唯一
        dispatch(actions.orderBatch.updateSelectedItem(batchItem.key, 'quantity', quantity + 1))
      } else {
        // 若 quantity <= 0，代表還沒有點過因此要新增品項
        const batchItemTemp = libs.order.createBatchItem(menu)
        dispatch(actions.orderBatch.addItem(batchItemTemp))
      }
    } else {
      dispatch(actions.batchItemTemp.addItem(menu))

      if (isSet) {
        // 如果 menu 是套餐的話，打開 SetItemDrawer
        // 如果是根據特定步驟拆開的套餐要傳 setItemMenu 給 SetItemDrawer 知道現在點的是哪個 setItem
        dispatch(actions.app.toggleDrawer('setItem', true, { setMenu: menu, setItemMenu }))

        if (setItemMenu) {
          // 如果是把 step1 拆開來的套餐
          const hasOptions = !_.isEmpty(setItemMenu.options)
          if (hasOptions) {
            // 如果有選項則打開選項視窗
            dispatch(actions.app.toggleDialog('setItemOption', true, { setMenu: menu, setItemMenu }))
          } else {
            dispatch(actions.batchItemTemp.updateSetItems([createBatchSetItem(setItemMenu)]))
          }
        }
      } else {
        dispatch(actions.app.toggleDrawer('menuItem', true, { menu }))
      }
    }

    sendEvent(constants.track.EVENT.VIEW_ITEM, {
      item: menu,
      merchant,
    })
  }

  return (
    <StepperBase
      innerRef={innerRef}
      quantity={quantity}
      min={min}
      max={max}
      onIncrement={handleIncrement}
      className={classes.menuStepper}
      variant='increment'
      disableIncrement={sold}
    />
  )
}

const useStyles = makeStyles(theme => ({
  menuStepper: {
    alignSelf: 'center',
    zoom: '1.2',
  },
}))
