import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import constants from '@/constants'

import CartButton from './buttons/CartButton'
import FilterButton from './buttons/FilterButton'
import Member from './Member'
import SearchInput from '../Search/SearchInput'

/**
 * @param {{
 * backgroundHeight: Number | String
 * }} props
 * @returns
 */
export default function Appbar (props) {
  const { disabled } = props
  const classes = useStyles(props)
  const history = useHistory()

  return (
    <AppBar position='fixed' className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Member />
        <section>
          <SearchInput
            onClick={() => history.push('/search')}
            disabledClick={disabled}
          />
          <FilterButton disabled={disabled} />
          <CartButton />
        </section>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles(theme => {
  return {
    appBar: {
      paddingTop: constants.app.safeArea.top,
      backgroundColor: 'transparent',
      '&:before': {
        content: '""',
        zIndex: -1,
        position: 'absolute',
        top: 0,
        left: 0,
        height: props => props.backgroundHeight ?? '100%',
        width: '100%',
        background: `linear-gradient(45deg, ${theme.palette.primary.customDark} 0%, ${theme.palette.primary.customLight} 100%)`,
      },
    },
    toolbar: {
      justifyContent: 'space-between',
      height: constants.app.height.APPBAR,
      padding: theme.spacing(0, 1),
      '& > section': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
})
