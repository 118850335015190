import { ButtonBase, Typography } from '@material-ui/core'
import { Clipboard } from '@capacitor/clipboard'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import AppConstants from '@/constants/app'
import InfoOutlinedIcon from '@material-ui/icons/HelpOutline'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

export default function GoPrevBrowserScreen (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { orderId } = useParams()

  const HelpButton = (
    <ButtonBase
      className={classes.helpButton}
      onClick={(e) => {
        window.open(`https://wa.me/85268262032?text=訂單編號:${orderId}，訂單追蹤無法載入`)
      }}
    >
      <InfoOutlinedIcon className={classes.helpButtonIcon} fontSize='small' />
      <Typography variant='body2'>
        {t('app.page.order_tracking.navbar.help')}
      </Typography>
    </ButtonBase>
  )

  return (
    <Page>
      <Navbar
        title={t('app.page.order_tracking.navbar.title')}
        withLeave={false}
        rightColumn={HelpButton}
      />
      <PageContainer className={classes.pageContainer}>
        <div className={classes.container}>
          <Typography variant='h5'>
            {t('app.page.order_tracking.return_prev_browser_alert.title')}
          </Typography>
          <Typography color='error' variant='subtitle1'>
            {t('app.page.order_tracking.return_prev_browser_alert.message')}
          </Typography>

          <div className={classes.buttons}>
            <LetterSpacingButton
              className={classes.button}
              text={t('app.page.order_tracking.return_prev_browser_alert.copy_link')}
              onClick={() => {
                Clipboard.write({
                  string: window.location.href,
                })
                dispatch(actions.snackbar.enqueueSnackbar({
                  message: t('app.page.order_tracking.return_prev_browser_alert.copied'),
                }))
              }}
            />
            <LetterSpacingButton
              className={classes.button}
              text={t('app.page.order_tracking.return_prev_browser_alert.login')}
              onClick={() => {
                dispatch(actions.app.toggleDrawer('login', true))
              }}
            />
          </div>
        </div>
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  button: {
    alignSelf: 'center',
  },
  pageContainer: {
    marginTop: AppConstants.marginTop.NAVBAR,
    marginBottom: AppConstants.marginBottom.GENERAL,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  helpButton: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: 'rgba(0,0,0,0.75)',
  },
}))
