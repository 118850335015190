import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Badge from '@material-ui/core/Badge'
import React from 'react'
import RemoveIcon from '@material-ui/icons/Remove'
import clsx from 'clsx'

import constants from '@/constants'

/**
 * @typedef StepperBaseProps
 * @property {React.Ref} [innerRef]
 * @property {number} quantity
 * @property {number} [min=0]
 * @property {number} [max=250]
 * @property {() => void} onIncrement
 * @property {() => void} onDecrement
 * @property {boolean} [disableIncrement=false]
 * @property {boolean} [disableDecrement=false]
 * @property {string} [className]
 * @property {'increment'} [variant]
 * @property {CustomClasses} [customClasses]
 *
 * @typedef CustomClasses
 * @property {string} [stepperIcon]
 * @property {string} [stepperAddIcon]
 * @property {string} [stepperMinusIcon]
 * @property {string} [stepperCount]
 *
 * @param {StepperBaseProps} props
 */
export default function StepperBase (props) {
  const {
    innerRef,
    quantity,
    min = 0,
    max = constants.app.stepper.MAX,
    onIncrement,
    onDecrement,
    disableIncrement = false,
    disableDecrement = false,
    className,
    variant,
    customClasses,
  } = props

  const classes = useStyles({ ...props })
  const disableMinus = disableDecrement || quantity <= min
  const disableAdd = disableIncrement || quantity >= max

  if (variant === 'increment') {
    return (
      <div className={clsx(classes.stepperContainer, className)}>
        <Badge
          color='secondary'
          badgeContent={quantity}
          classes={{
            anchorOriginTopRightRectangular: classes.anchorOriginTopRightRectangular,
            colorSecondary: classes.colorSecondary,
          }}
        >
          <IconButton
            disableRipple
            ref={innerRef}
            disabled={disableAdd}
            classes={{ root: classes.stepperIconButtonRoot }}
            className={clsx({ [classes.disable]: disableAdd })}
            onClick={(e) => onIncrement(e, quantity, 1)}
          >
            <AddIcon
              className={clsx(
                classes.stepperIcon,
                classes.addIcon,
                customClasses?.stepperIcon,
                customClasses?.stepperAddIcon,
              )}
              classes={{ root: classes.stepperIconRoot }}
            />
          </IconButton>
        </Badge>
      </div>
    )
  }

  return (
    <div className={clsx(classes.stepperContainer, className)}>
      <IconButton
        disabled={disableMinus}
        classes={{ root: classes.stepperIconButtonRoot }}
        className={clsx({ [classes.disable]: disableMinus })}
        onClick={(e) => onDecrement(e, quantity, 1)}
      >
        <RemoveIcon
          className={clsx(
            classes.stepperIcon,
            classes.minusIcon,
            customClasses?.stepperIcon,
            customClasses?.stepperMinusIcon,
          )}
          classes={{ root: classes.stepperIconRoot }}
        />
      </IconButton>

      <Typography className={clsx(classes.stepperCount, customClasses?.stepperCount)}>
        {quantity}
      </Typography>

      <IconButton
        disabled={disableAdd}
        classes={{ root: classes.stepperIconButtonRoot }}
        className={clsx({ [classes.disable]: disableAdd })}
        onClick={(e) => onIncrement(e, quantity, 1)}
      >
        <AddIcon
          className={clsx(
            classes.stepperIcon,
            classes.addIcon,
            customClasses?.stepperIcon,
            customClasses?.stepperAddIcon,
          )}
          classes={{ root: classes.stepperIconRoot }}
        />
      </IconButton>
    </div>
  )
}

/**
 *
 * @param {StepperBaseProps} props
 * @returns
 */
export function DrawerStepperBase (props) {
  const classes = useStyles(props)
  return (
    <StepperBase
      {...props}
      customClasses={{
        stepperIcon: classes.drawerStepperIcon,
        stepperCount: classes.drawerStepperCount,
        stepperMinusIcon: classes.drawerStepperMinusIcon,
      }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  stepperContainer: {
    flexShrink: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 0.7fr 1fr',
  },
  stepperIcon: {
    width: 20,
    height: 20,
    borderRadius: theme.shape.borderRadius,
  },
  addIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  minusIcon: {
    backgroundColor: theme.palette.grey[300],
  },
  stepperIconRoot: {
    fill: theme.palette.common.black,
  },
  stepperIconButtonRoot: {
    padding: theme.spacing(0.75),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  stepperCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    fontSize: '1.6rem',
    fontWeight: 500,
    fontFamily: 'sans-serif',
    color: theme.palette.text.primaryDark,
  },
  drawerStepperIcon: {
    width: '32px !important',
    height: '32px !important',
    borderRadius: '50% !important',
  },
  drawerStepperCount: {
    fontSize: '2.4rem !important',
  },
  drawerStepperMinusIcon: {
    backgroundColor: 'transparent !important',
    border: `2px solid ${theme.palette.grey[400]} !important`,
  },
  disable: {
    opacity: 0.3,
  },
  anchorOriginTopRightRectangular: {
    transform: 'scale(0.9) translate(50%, -50%)',
  },
  colorSecondary: {
    backgroundColor: theme.palette.text.secondary,
  },
}))
