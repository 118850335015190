import { useSnackbar } from 'notistack'

import { useSelector } from '@/redux'
import constants from '@/constants'
import customColors from '@/theme/colors/custom'

// eslint-disable-next-line no-unused-vars
import { IDatetime, ILoading, IParams } from '@/redux/app/AppState.d'

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType

/**
 * @returns {IParams}
 */
export function useParams () {
  return useSelector(state => state.app.params)
}

/**
 * @returns {ILoading}
 */
export function useLoadings () {
  return useSelector(state => state.app.loadings)
}

/**
 *
 * @param {'takeaway' | 'storeDelivery' | 'table'} [deliveryType]
 * @returns
 */
export function useDeliveryType (deliveryType) {
  const params = useSelector(state => state.app.params)
  const type = deliveryType || params.deliveryType
  return {
    deliveryType: type,
    isTakeaway: type === TAKEAWAY,
    isStoreDelivery: type === STORE_DELIVERY,
    isTable: type === TABLE,
  }
}

/**
 * @returns {String}
 */
export function useTable () {
  return useSelector(state => state.app.params.table)
}

/**
 * @returns {IDatetime}
 */
export function useDatetime () {
  return useSelector(state => state.app.params.datetime)
}

export function useCustomColors () {
  return customColors
}

export function useShowSnackbar () {
  const { enqueueSnackbar } = useSnackbar()
  /**
   * @param {string} message
   * @param {'success' | 'error' | 'warning' | 'default' | null} variant
   * @param {object} options
   */
  const showSnackbar = (message, variant, options) => {
    enqueueSnackbar(message, { ...constants.app.snackbar.OPTIONS, ...options, variant })
  }
  return showSnackbar
}

export function useOrderTrackingSnackbar () {
  return useSelector(state => state.app.snackbars.orderTracking.open)
}

export function useSystem () {
  return useSelector(state => state.app.system)
}

export function useAppLocale () {
  /** @type {'en' | 'zh' | 'jp'} */
  const appLocale = useSelector(state => state.app.lang).split('-')[0]
  return appLocale
}

export default {
  useParams,
  useLoadings,
  useDeliveryType,
  useTable,
  useDatetime,
  useCustomColors,
  useShowSnackbar,
  useOrderTrackingSnackbar,
  useSystem,
}
