import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useCategories } from '@/hooks/queries/menu/useMenuQuery'
import AppConstants from '@/constants/app'

import SidebarCategoryList from './SidebarCategoryList'
import SidebarItemButton from './SidebarItemButton'
import SkeletonSidebarCategories from './SkeletonSidebarCategories'

/**
 * @param {{
 * isLoading: boolean
 * }} props
 * @returns
 */
export default function Sidebar (props) {
  const { isLoading } = props
  const classes = useStyles(props)

  const { data: categories } = useCategories()

  return (
    <div className={classes.sidebar}>
      <div className={classes.backButtonContainer}>
        <SidebarItemButton isBackButton />
      </div>
      <div className={classes.categories}>
        {
          isLoading
            ? <SkeletonSidebarCategories />
            : <SidebarCategoryList categories={categories} />
        }
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  sidebar: {
    marginTop: AppConstants.marginTop.GENERAL,
    marginBottom: AppConstants.marginBottom.GENERAL,
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    width: 90,
  },
  categories: {
    flex: 1,
    overflow: 'scroll',
    // 32 是 CheckOrderHistoryButton 的 minHeight，theme.spacing(1) 是 CheckOrderHistoryButton 的 marginBottom
    paddingBottom: `calc(${AppConstants.height.FOOTER + 32 + theme.spacing(1)}px + ${AppConstants.safeArea.bottom})`,
  },
  backButtonContainer: {
    flexShrink: 0,
    width: 90,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
