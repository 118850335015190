
import { ActionExtension } from '@dimorder/capacitor-action-extension'
import { Capacitor } from '@capacitor/core'
import { WebIntent } from '@ionic-native/web-intent'
import _ from 'lodash'
import qs from 'qs'

import URLParser from '@/libs/URLParser'
import config from '@/config'
import constants from '@/constants'
import logger from '@/libs/logger'

/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppPayment} IAppPayment */

/**
 *
 * @param {IAppPayment[]} payments
 * @param {string} [memberId]
 * @returns
 */
export function getOrderPayment (payments, memberId) {
  // 優先顯示 paid payment (payment.status === 'paid')
  // 再來是自己的最後一筆 pending payment (payment.status === 'pending' && payment.customerId === user.member.id)
  // 如果自己沒有就顯示最後一筆 pending payment (payment.status === 'pending')
  const paymentMethodStatusGroup = _.groupBy(payments, payment => payment.status)
  const paidPayments = paymentMethodStatusGroup.paid ?? []
  const pendingPayments = paymentMethodStatusGroup.pending ?? []
  const userPendingPayments = pendingPayments.filter(payment => payment.customerId === memberId)
  return _.last(paidPayments) || _.last(userPendingPayments) || _.last(pendingPayments)
}

/**
 *
 * 從開啟 APP 的 url 判斷第三方付款方式
 * @param {URL} url - the return url
 * @returns {string|null} - payment type or null if the payment type is not supported
 */
export const getPaymentTypeByReturnUrl = (url) => {
  if (URLParser.isValidSite(url.hostname)) {
    // 不是用來開我們 APP 的 url 不處理
    return null
  }

  if (url.searchParams.has('ret')) {
    return constants.paymentMethod.WECHAT_PAY
  }

  if (url.pathname === '//safepay/') {
    return constants.paymentMethod.ALI_PAY
  }

  if (url.searchParams.get('from') === constants.paymentMethod.PAY_ME) {
    return constants.paymentMethod.PAY_ME
  }

  if (url.searchParams.get('method') === constants.paymentMethod.FPS) {
    return constants.paymentMethod.FPS
  }

  if (url.searchParams.get('method') === constants.paymentMethod.OCTOPUS) {
    return constants.paymentMethod.OCTOPUS
  }

  return null
}

const getFPSUrl = (orderId, amount, remark) => {
  const query = qs.stringify({
    payment: amount,
    remark1: remark,
    orderid: orderId,
  })

  return `${config.fps.baseUrl}/genQrcode?${query}`
}

/**
 *
 * @param {string} params.orderId max 24 chars long
 * @param {number} params.amount 10HKD -> 10
 * @param {string} params.remark
 * @param {function} params.onSuccess
 * @param {function} params.onFailed
 * @param {function} params.onCancelled - user closes the slide up
 * @returns Promise<void>
 */
export const handlePaymentFPS = async (params) => {
  const { orderId, amount, remark, callbackUrl, onSuccess, onFailed, onCancelled } = params
  const url = getFPSUrl(orderId, amount, remark)

  if (Capacitor.getPlatform() === 'ios') {
    const result = await ActionExtension.fpsPay({ callback: callbackUrl, url })

    if (result?.isCancelled) {
      onCancelled?.()
    }
  } else if (Capacitor.getPlatform() === 'android') {
    try {
      // call intent
      const options = {
        action: config.fps.androidAction,
        extras: { url },
      }
      console.log('url', url)

      // result format: {"extras":{"resultCode":-1,"requestCode":1},"flags":0}
      const result = await WebIntent.startActivityForResult(options)

      // -1 implies OK
      // 0 implies cancelled/failed
      if (result.extras.resultCode === -1) {
        // handle payment success
        console.log('onSuccess', JSON.stringify(result))
        if (onSuccess) {
          onSuccess()
        }
      } else {
        // handle cancelled/failed payment, e.g. user clicks back button to
        // return to our app instead of completing the payment flow
        console.log('onCancelled', JSON.stringify(result))
        if (onFailed) {
          onFailed(result)
        }
      }
    } catch (err) {
      console.log('onError', err)
      logger.log('[payment FPS]', err)
    }
  }
}

export const getOutTradeNumber = (paymentType, selectedOrder) => {
  return [
    'orderapp',
    paymentType,
    config.env,
    selectedOrder.merchantId,
    selectedOrder.id.split('-')[0],
    Math.floor(Date.now() / 1000),
  ].join('@')
}

export const getAlipayPayString = (payParams) => {
  const sortObjectKeys = Object.keys(payParams).sort()
  let payString = ''

  _.forEach(sortObjectKeys, (sortObjectKey) => {
    if (sortObjectKey !== 'sign_type' && sortObjectKey !== 'sign' && payParams[sortObjectKey] !== '') {
      if (sortObjectKey === 'total_fee') {
        payString += `${sortObjectKey}="${Number(payParams[sortObjectKey]).toFixed(1)}"&`
      } else {
        payString += `${sortObjectKey}="${payParams[sortObjectKey]}"&`
      }
    }
  })
  payString += `sign="${payParams.sign}"&sign_type="${payParams.sign_type}"`

  return payString
}

let applePaySession = null
/**
 * @param {IAppOrder} order
 * @param {string} paymentMethod
 */
export const createApplePaySession = (order, paymentMethod) => {
  if (paymentMethod !== constants.paymentMethod.APPLE_PAY) return
  if (!window?.ApplePaySession) return

  const amount = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal
  logger.log('[apple pay] createApplePaySession()', { orderId: order.id, amount })
  applePaySession = new window.ApplePaySession(10, {
    countryCode: 'HK',
    currencyCode: 'HKD',
    supportedNetworks: [
      'visa',
      'masterCard',
      // chinaUnionPay,
    ],
    merchantCapabilities: ['supports3DS'],
    total: {
      label: 'DimOrder',
      amount: amount,
    },
  })
}

export const getApplePaySession = () => {
  return applePaySession
}
