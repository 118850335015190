import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function RcIcon (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g fill='currentColor' fillRule='nonzero'>
          <g>
            <path fill='currentColor' d='M7.286.17C3.942 1.104.458 7.213.03 12.29c-.141 1.681.21 3.354.804 4.55.827 1.664 3.236 2.948 6.45 2.918 2.852-.026 5.878-1.208 8.5-2.553 2.953-1.516 5.322-3.35 5.01-6.083-.396-3.476-2.737-5.786-5.01-7.702C12.893.983 9.778-.526 7.285.17z' transform='translate(-812 -2581) translate(812 2581)' />
            <path fill='#FFF' fillRule='nonzero' d='M6.99 12.862V9.2H8.4l2.153 3.66H11.9l-2.443-3.97c1.186-.424 1.778-1.216 1.778-2.375C11.235 4.839 10.17 4 8.036 4H5.84v8.862h1.15zM8 8.243H6.99v-3.28h.978c.73 0 1.26.126 1.588.377.328.25.492.66.492 1.23 0 .582-.166 1.006-.499 1.273-.332.267-.849.4-1.55.4z' transform='translate(-812 -2581) translate(812 2581)' />
            <path fill='#FFF' fillRule='nonzero' d='M11.78 17.179c.947 0 1.7-.297 2.258-.891.466-.493.74-1.105.823-1.834h-.928c-.096.456-.241.823-.436 1.103-.367.531-.901.797-1.603.797-.762 0-1.336-.252-1.72-.757-.384-.504-.577-1.162-.577-1.973 0-.99.213-1.724.64-2.204.425-.479.984-.719 1.676-.719.568 0 1.01.13 1.323.392.315.26.524.614.63 1.06h.928c-.054-.587-.327-1.112-.818-1.574-.492-.462-1.182-.693-2.072-.693-1.043 0-1.862.355-2.455 1.065-.549.654-.823 1.492-.823 2.513 0 1.345.365 2.34 1.096 2.984.555.487 1.24.731 2.057.731z' transform='translate(-812 -2581) translate(812 2581)' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
