import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import hooks from '@/hooks'

/**
 * 顯示內用桌號與桌號群組
*
* @param {*} props
* @returns
*/
export default function TableButton (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const merchant = hooks.merchant.useMerchant()
  const { table } = hooks.app.useParams()
  const tableGroup = _.findKey(merchant?.tableGroups, group => _.includes(group, table))

  if (!table) return null

  return (
    <ButtonBase disabled className={classes.button}>
      <Typography variant='body2'>
        {`${t('app.constant.table.table_number')}: ${[tableGroup, table].filter(o => o).join(' / ')}`}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    flex: 1,
    borderRadius: 6,
    justifyContent: 'flex-start',
  },
}))
