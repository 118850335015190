import { ArrowForwardIos } from '@material-ui/icons'
import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { SafeAreaPage } from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

import CRMNavbar from '../components/Navbar'
import Card, { CardHeader, CardImage } from '../components/Card'
import JoinedGroupList from './JoinedGroupList'
import PageHeader from '../components/PageHeader'

export const CARD = { height: 92 }
const CARD_IMAGE = { size: 58 }

export default function CRMMembershipPage (props) {
  const { t } = useTranslation()
  return (
    <SafeAreaPage>
      <CRMNavbar />
      <PageContainer style={{ overflow: 'hidden' }}>
        <PageHeader title={t('app.page.crm.membership_card')} />
        <JoinedGroupList />
      </PageContainer>
    </SafeAreaPage>
  )
}

/**
 * @param {*} props
 * @returns
 */
export function GroupCard (props) {
  const { item: group } = props
  const classes = useStyles(props)
  const history = useHistory()
  const d2cBaseUrl = useD2CBaseUrl()
  const handleClick = () => history.push(`${d2cBaseUrl}/membership/${group?.id}`)
  const { imageUrl = constants.app.image.EMPTY_IMAGE, name } = group

  return (
    <Card className={classes.card} component={ButtonBase} onClick={handleClick}>
      <CardImage imageUrl={imageUrl} size={CARD_IMAGE.size} />
      <div style={{ flex: 1, alignSelf: 'center' }}>
        <CardHeader title={name} />
      </div>
      <div className={classes.forwardIcon}>
        <ArrowForwardIos fontSize='inherit' />
      </div>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    alignItems: 'stretch',
    gap: theme.spacing(2),
    width: '100%',
    height: CARD.height,
    padding: 0,
  },
  forwardIcon: {
    display: 'grid',
    placeItems: 'center',
    width: 32,
    height: 32,
    color: theme.palette.common.white,
    background: '#FF8A65',
    borderRadius: 10,
    fontSize: 22,
    alignSelf: 'center',
  },
}))
