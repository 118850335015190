import shipping from './shipping'
import * as URLParser from './URLParser'
import * as cloudinary from './cloudinary'
import * as crypto from './crypto'
import * as date from './date'
import * as delay from './delay'
import * as googleMap from './googleMap'
import * as menu from './menu'
import * as order from './order'
import * as riceCoin from './riceCoin'
import * as string from './string'

export default {
  cloudinary,
  crypto,
  date,
  delay,
  googleMap,
  menu,
  string,
  URLParser,
  order,
  shipping,
  riceCoin,
}
