import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Container (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <g fill='none' fillRule='evenodd'>
        <g fill='currentColor' fillRule='nonzero'>
          <g>
            <path d='M16.25 1.25H3.75c-.346 0-.625.28-.625.625v16.25c0 .346.28.625.625.625h12.5c.346 0 .625-.28.625-.625V1.875c0-.346-.28-.625-.625-.625zm-.781 16.094H4.53v-3.926h1.912c.227.64.625 1.217 1.155 1.654.673.557 1.527.866 2.402.866.875 0 1.729-.307 2.402-.866.53-.437.928-1.013 1.155-1.654h1.912v-1.23H12.57l-.101.482c-.235 1.168-1.274 2.018-2.469 2.018-1.195 0-2.234-.85-2.47-2.018l-.102-.482H4.53V2.655H15.47v14.688zM6.25 6.66h7.5c.086 0 .156-.07.156-.156v-.938c0-.086-.07-.156-.156-.156h-7.5c-.086 0-.156.07-.156.156v.938c0 .086.07.156.156.156zm0 3.125h7.5c.086 0 .156-.07.156-.156V8.69c0-.086-.07-.156-.156-.156h-7.5c-.086 0-.156.07-.156.156v.938c0 .086.07.156.156.156z' transform='translate(-233 -2580) translate(233 2580)' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
