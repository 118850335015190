import RiceCoinConstants from '@/constants/riceCoin'
import store from '@/redux/store'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { RATIO } = RiceCoinConstants

/** @type {() => IRootState} */
const getState = store.getState

/**
 * 計算 RiceCoin 可折抵金額和會使用多少 RiceCoin
 * @param {IAppOrder} order 訂單
 * @returns {{usedRiceCoin: number, discountAmount: number}}
 */
export function calculateRiceCoinDiscount (order) {
  const riceCoinBalance = getState().user.member.amount ?? 0
  const withholding = getState().user.member.withholding ?? 0
  const availableBalance = Math.floor(riceCoinBalance - withholding) // RiceCoin僅顯示整數，小數點無條件捨去（因進位會變成我們有多支出RC)

  // 計算 RiceCoin 需要未使用 RiceCoin 的 total
  let total = order.roundedTotal ?? order.total

  // 如果 modifiers 裡面有 RICECOIN 折扣，且該折扣有 id，表示已經從 server 拿到的折扣，要重新計算 RiceCoin 時需要先拿掉
  const riceCoinModifier = order?.modifiers.find(modifier => modifier.type === 'RICECOIN' && modifier.id)
  if (riceCoinModifier) {
    total -= riceCoinModifier?.calculatedAmount
  }

  // 未使用 RiceCoin 就已經低於低消，就直接不使用 RiceCoin
  if (total <= 10) {
    return {
      usedRiceCoin: 0, // 使用 riceCoin
      discountAmount: 0, // 折抵金額
    }
  }

  // 不可折的部分：低消 10 元
  const notAllowedDiscountAmount = 10
  // 最多折抵金額： total - 不能折的金額
  const maxDiscountAmount = total - notAllowedDiscountAmount
  // 可折抵金額： balance / ratio
  const availableDiscountAmount = Math.floor(availableBalance / RATIO)
  // 最後的折抵金額
  const discountAmount = Math.min(availableDiscountAmount, maxDiscountAmount)
  // 用了多少 RiceCoin
  const usedRiceCoin = Math.round(discountAmount * RATIO)

  return {
    usedRiceCoin, // 使用 riceCoin
    discountAmount, // 折抵金額
  }
}

export default {
  calculateRiceCoinDiscount,
}
