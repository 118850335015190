import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { useCategories } from '@/hooks/queries/menu/useMenuQuery'
import { useMerchant } from '@/hooks/merchant'
import { useTranslation } from 'react-i18next'

import RecommandedMenuCard from './RecommandedMenuCard'

/**
 * @typedef RecommendedMenuListProps
 * 之後可能會需要傳入
 * 1. merchantId? 當前餐廳
 * 2. deliveryType? 當前再看哪種菜單
 * 3. batches? 當前點了什麼
 * 4. userId? 之前點過什麼
 * 透過 API 得到推薦的餐點
 */

/**
 * 推薦餐點列表
 * 當店家開啟設定時 (merchant.setting.enableMenuRecommendation)，顯示店家推薦的餐點列表。
 * 目前先使用設為 promoted-category 的單點，未來可能透過 API 得到推薦的餐點清單。
 * 1. 當沒有開啟設定時不顯示。
 * 2. 當沒有推薦餐點時不顯示。
 * 3. 當顯示餐點多於 5 個時顯示 5 個。
 * 4. 當 5 個推薦餐點內同時有未售完和已售完的餐點時，未售完的餐點排在已售完的餐點前面。
 * 5. 當 5 個推薦餐點都為售完時，仍要顯示推薦列表。
 * @param {RecommendedMenuListProps} props
 * @returns
 */
export default function RecommendedMenuList (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const merchant = useMerchant()
  const { data: categories, isLoading } = useCategories()
  const promotedCategory = _.find(categories, cateogry => cateogry.id === 'promoted-category')
  const enableInventoryCheck = merchant?.setting?.inventory ?? false

  /**
   * 推薦餐點列表
   * 1. 僅限推薦單點
   * 2. 未售完的單點排在已售完的單點前面
   * 3. 取前五個
   */
  const recommendedMenus = React.useMemo(() => {
    const promotedMenus = promotedCategory?.menus ?? [] // 僅限推薦單點
    const grouped = _.groupBy(promotedMenus, menu => {
      if (menu.sold) return true
      if (enableInventoryCheck && menu.inventory === 0) return true
      return false
    })
    const sold = grouped.true ?? []
    const unsold = grouped.false ?? []
    const sorted = [...unsold, ...sold] // 未售完的單點排在已售完的單點前面
    return _.take(sorted, 5) // 取前五個
  }, [promotedCategory?.menus, enableInventoryCheck])

  const enableMenuRecommendation = merchant?.setting?.enableMenuRecommendation
  const hasRecommendedMenu = !_.isEmpty(recommendedMenus)

  const SkeletonCards = React.useMemo(() => {
    return _.map(_.times(5, Number), (number) => (
      <Skeleton
        key={number}
        className={classes.skeletonRecommendedMenuCard}
        variant='rect'
        width='100%'
        height='100%'
      />
    ))
  }, [])

  if (!enableMenuRecommendation) {
    // 1. 當沒有開啟設定時不顯示。
    return null
  }

  if (!isLoading && !hasRecommendedMenu) {
    // 2. 當沒有推薦商品時不顯示。
    return null
  }

  return (
    <div className={classes.recommendedMenuListWrap}>
      <Typography variant='h6' className={classes.recommendedMenuListHeader}>{t('app.component.recommended_menu.header')}:</Typography>
      <div className={classes.recommendedMenuList}>
        {isLoading
          ? SkeletonCards
          : _.map(recommendedMenus, menu => <RecommandedMenuCard key={menu.id} menu={menu} />)}
      </div>
    </div>

  )
}

const useStyles = makeStyles(theme => ({
  recommendedMenuListWrap: {
    backgroundColor: '#feefc4',
    margin: -16, // 抵銷外層的 padding 16
    paddingTop: 16,
    paddingBottom: 16,
  },
  recommendedMenuListHeader: {
    marginTop: 4,
    marginBottom: 4,
    color: '#60442f',
    paddingLeft: 16,
    paddingRight: 16,
  },
  recommendedMenuList: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
    gap: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  skeletonRecommendedMenuCard: {
    minHeight: 80,
    minWidth: 240,
    padding: 0,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[400],
  },
}))
