
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'

import { actions, useDispatch } from '@/redux'

import AddressItemBase from './AddressItemBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AddressItemAdd (props) {
  const classes = useStyles(props)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleClick = () => {
    dispatch(actions.user.createAddressTemp())
    history.push('/settings/address/edit')
  }

  return (
    <AddressItemBase
      className={classes.addAddress}
      icon={<AddIcon color='primary' />}
      title={t('app.page.setting_address.add_address')}
      titleColor='primary'
      onClick={handleClick}
    />
  )
}

const useStyles = makeStyles(theme => ({
  addAddress: {
    backgroundColor: 'unset',
    border: 'unset',
    boxShadow: 'none',
  },
}))
