import React from 'react'
import _ from 'lodash'

import { useCart } from '@/hooks/useCart'

const Context = React.createContext()

export function Provider (props) {
  const { children } = props
  const { batchItems } = useCart()
  const quantityMap = React.useMemo(
    () =>
      _.reduce(
        batchItems,
        (acc, batchItem) => {
          let key = batchItem.menuId

          if (batchItem.separatedStep) {
            // batchItem 若有 separatedStep 代表這個套餐是分拆的套餐
            // 找出 setItems 中 step 與 separatedStep 相符合的 setItem
            // 因為僅有第一步單選的套餐可以分拆，所以只會有一個符合的 setItem
            const seperatedItem = batchItem.setItems.find(
              (setItem) => setItem.step === batchItem.separatedStep,
            )
            if (seperatedItem) {
              key = key + '/' + seperatedItem.menuId
            }
          }

          if (acc[key] === undefined) {
            acc[key] = batchItem.quantity
          } else if (acc[key] >= 0) {
            acc[key] += batchItem.quantity
          }
          return acc
        },
        {},
      ),
    [batchItems],
  )
  return <Context.Provider value={quantityMap}>{children}</Context.Provider>
}

export function useBatchItemQuantity () {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useBatchItemQuantity must be used within a Provider')
  }
  return context
}
