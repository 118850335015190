import { Element, Link, scroller } from 'react-scroll'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from '@/redux'
import PropTypes from 'prop-types'
import React from 'react'

import { ReactComponent as HotSaleIcon } from '@/assets/icons/category_hotsale.svg'
import { scrollToCategory } from '@/libs/scroll'
import constants from '@/constants'

import SubCategoryList from './SubCategoryList'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

const CATEGORY_ITEM_PADDING_BOTTOM = 32

CategoryLink.propTypes = {
  item: PropTypes.object,
  selectedRoot: PropTypes.object,
  setSelectedRoot: PropTypes.func,
  isSubCategory: PropTypes.bool,
}

/**
 * @constant
 * @param {{
 * item: IAppCategory
 * selectedRoot: IAppCategory
 * setSelectedRoot: Function
 * isSubCategory?: boolean
 * scrollOffset: number
 * }} props
 */
export default function CategoryLink (props) {
  const { item: category, selectedRoot, setSelectedRoot, isSubCategory, scrollOffset } = props
  const safeAreaHeight = useSelector(state => state.app.safeAreaHeight)
  const headerHeight = constants.app.height.NAVBAR + safeAreaHeight + constants.app.height.CATEGORYBAR
  const offset = -headerHeight - CATEGORY_ITEM_PADDING_BOTTOM
  const scrollToCategoryOffset = scrollOffset - headerHeight - CATEGORY_ITEM_PADDING_BOTTOM
  const classes = useStyles(props)

  const handleSetActive = (to, category) => {
    if (to === selectedRoot.id) return null // 避免點 child 的時候觸發捲到 root
    if (!isSubCategory) setSelectedRoot(category)
    scroller.scrollTo('link' + to, {
      duration: 0,
      smooth: true,
      containerId: 'linkList',
      offset: -50, // active 的 CategoryLink 距離左邊保留 50px
      horizontal: true,
      isDynamic: true,
    })
  }

  return (
    <>
      <li className={classes.category}>
        <Element name={'link' + category.id}>
          <Link
            activeClass={classes.active}
            containerId='pageContainer'
            to={category.id}
            onClick={(e) => scrollToCategory(category, 'pageContainer', { offset: scrollToCategoryOffset })}
            onSetActive={to => handleSetActive(to, category)}
            duration={200}
            isDynamic //  in case the distance has to be recalculated - if you have content that expands etc.
            spy //  make Link selected when scroll is at its targets position
            smooth
            offset={offset} // scroll additional px ( like padding )
          >
            <div className={classes.categoryNameWrap}>
              {category.id === 'promoted-category' && <HotSaleIcon className={classes.promotedIcon} />}
              <Typography>{category.name}</Typography>
            </div>
          </Link>
        </Element>
      </li>

      <SubCategoryList
        list={category.categories}
        selectedRoot={selectedRoot}
        setSelectedRoot={setSelectedRoot}
        display={selectedRoot.id === category.id}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  category: {
    minHeight: 40,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    cursor: 'pointer',
    '& .active': {
      '&  p': {
        color: theme.palette.error.main,
        fontWeight: 'bold',
        '&:after': {
          content: '""',
          position: 'relative',
          display: 'block',
          bottom: theme.spacing(-1.5),
          borderBottom: `${theme.palette.error.main} solid 3px`,
          borderRadius: theme.spacing(1.5),
        },
      },
    },
  },
  categoryNameWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  promotedIcon: {
    marginRight: 4,
  },
}))
