import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

GroupHeader.propTypes = {
  title: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

/**
 *
 * @param {{
 * title:String
 * hint?: String | Node
 * }} props
 */
export default function GroupHeader (props) {
  const { title, hint } = props
  const classes = useStyles(props)

  return (
    <div>
      <Typography variant='body1' className={classes.title}>{title}</Typography>
      {hint && <Typography variant='body2' className={classes.hint}>{hint}</Typography>}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
    padding: theme.spacing(0, 1),
  },
  hint: {
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[500],
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}))
