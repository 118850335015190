import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import React, { useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import DineinButtonBase from './DineinButtonBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DineinButtonServiceRequest (props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [delaying, setDelaying] = useState(false)
  const selectedOrder = useSelector(state => state.order.selectedOrder)
  const merchant = useSelector(state => state.merchant?.data)
  const disabled = delaying || !selectedOrder?.valid

  if (!merchant?.id) return null
  if (!merchant?.setting?.waiter) return null

  const handleClick = async () => {
    setDelaying(true)
    setTimeout(() => setDelaying(false), 60 * 1000) // 一分鐘只能叫一次 waiter

    const hasOfflineDevice = await dispatch(actions.merchant.findOfflineDevice(merchant?.id, true)) // 內用時檢查 merchant 是否離線
    if (hasOfflineDevice) {
      // 當餐廳裝置 (master) 狀態為離線時不能呼叫服務生
      dispatch(actions.app.toggleMaterDeviceOfflineAlert(hasOfflineDevice))
      return
    }

    await dispatch(actions.order.requestService('waiter'))
    await dispatch(actions.app.toggleAlert({
      title: t('app.page.restaurant.dinein_button.service.alert.title'),
      message: t('app.page.restaurant.dinein_button.service.alert.message'),
      button: { text: t('app.common.confirm') },
      header: (
        <header className={classes.alertHeader}>
          <EmojiPeopleIcon className={classes.alertImage} />
        </header>
      ),
    }))
  }

  return (
    <DineinButtonBase
      title={disabled
        ? t('app.page.restaurant.dinein_button.service.title_disabled')
        : t('app.page.restaurant.dinein_button.service.title')}
      icon={<EmojiPeopleIcon />}
      backgroundColor={theme.palette.info.light}
      onClick={handleClick}
      disabled={disabled}
    />
  )
}

const useStyles = makeStyles(theme => ({
  alertHeader: {
    display: 'grid',
    placeItems: 'center',
    minHeight: 140,
    width: '100%',
    marginTop: theme.spacing(3),
  },
  alertImage: {
    fontSize: '10rem',
    color: theme.palette.secondary.light,
  },
}))
