import _ from 'lodash'

import { createBatchSetItem } from '@/libs/order'
import libs from '@/libs'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IAppBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'
import { IMenuOptionGroup, IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

/**
 * @returns {ThunkFunction}
 */
export function resetItem () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_ITEM,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetSetItem () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RSET_SET_ITEM,
      payload: {},
    })
  }
}

/**
 * 如果有 menu 代表在 /restaurant 點餐
 * 如果有 batchItem 代表在 /checkout 編輯
 * @param {IAppMenuItem | IAppSet?} menu
 * @param {IAppBatchItem?} batchItem
 * @returns {ThunkFunction}
 */
export function addItem (menu, batchItem) {
  return (dispatch, getState) => {
    const isSet = menu?.isSet
    const isEditing = !_.isEmpty(batchItem)
    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: {
        batchItem: isEditing
          ? batchItem
          : libs.order.createBatchItem(menu),
      },
    })

    // 如果是在 /restaurant 點套餐的話自動加入必點項目
    // 如果是在 /checkout 編輯的話不需要
    if (isSet && !isEditing) {
      dispatch(addRequiredSetItem(menu))
    }
  }
}

/**
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function addRequiredSetItem (set) {
  return (dispatch, getState) => {
    // 找出套餐中所有必點項目，轉換成 batchItem（必點幾個就會加入幾個）
    /** @type {IAppMenuItem[]} */
    const requiredBatchItems = []
    set.menus.forEach(menuItem => {
      _.times(menuItem.min, () => {
        requiredBatchItems.push(libs.order.createBatchSetItem(menuItem))
      })
    })

    dispatch(updateItem('setItems', requiredBatchItems))
  }
}

/**
 * @param {IAppMenuItem} menu
 * @returns {ThunkFunction}
 */
export function addSetItem (menu) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_SET_ITEM,
      payload: { batchSetItem: libs.order.createBatchSetItem(menu) },
    })
  }
}

/**
 * 當 setItemGroup 符合需自動全選的情況時使用
 * 直接把 setItemMenus 傳進來轉成 setItems 跟 draft 的 setItems 做 concat
 * @param {IAppMenuItem[]} setItemMenus
 * @param {number?} quantity
 * @returns {ThunkFunction}
 */
export function addSetItems (setItemMenus, quantity) {
  return (dispatch, actions) => {
    const setItems = _.map(setItemMenus, setItemMenu => createBatchSetItem(setItemMenu, quantity))
    dispatch({
      type: ActionTypes.ADD_SET_ITEMS,
      payload: { setItems },
    })
  }
}

/**
 * Use lodash.set to update batchItemTemp
 * @param {PropertyPath} path
 * @param {*} value
 * @returns {ThunkFunction}
 */
export function updateItem (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM,
      payload: { path, value },
    })
  }
}

/**
 *
 * @param {*} value
 * @returns
 */
export function updateSetItems (value) {
  return (dispatch, getState) => {
    dispatch(updateItem(['setItems'], value))
  }
}

/**
 * Use lodash.set to update batchSetItemTemp
 * @param {PropertyPath} path
 * @param {*} value
 * @returns {ThunkFunction}
 */
export function updateSetItem (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SET_ITEM,
      payload: { path, value },
    })
  }
}

/**
 * @param {IMenuOptionItem} option
 * @returns {ThunkFunction}
 */
export function addItemOption (option) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_ITEM_OPTION,
      payload: { option },
    })
  }
}

/**
 * @param {IMenuOptionItem} option
 * @returns {ThunkFunction}
 */
export function addSetItemOption (option) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_SET_ITEM_OPTION,
      payload: { option },
    })
  }
}

/**
 * @param {IMenuOptionItem[]} options
 * @returns {ThunkFunction}
 */
export function updateItemOptions (options) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM_OPTIONS,
      payload: { options },
    })
  }
}

/**
 * @param {IMenuOptionItem[]} options
 * @returns {ThunkFunction}
 */
export function updateSetItemOptions (options) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SET_ITEM_OPTIONS,
      payload: { options },
    })
  }
}

/**
  * @function
  * @param {IMenuOptionItem} optionItem
  * @param {IMenuOptionGroup} optionGroup
  * @param {Boolean} isSet
  * @param {IMenuOptionItem?} tempOption
  * @param {number?} increaseQuantity
  * @returns {ThunkFunction}
  */
export function updateOptions (optionItem, optionGroup, isSet, tempOption, increaseQuantity) {
  return (dispatch, getState) => {
    const temp = isSet
      ? getState().batchItemTemp.batchSetItemTemp
      : getState().batchItemTemp.batchItemTemp

    const newOption = {
      optionGroupId: optionGroup.id,
      optionGroupName: optionGroup.name,
      optionItemId: optionItem.id,
      name: optionItem.name,
      price: optionItem.price,
      quantity: (tempOption?.quantity ?? 0) + (increaseQuantity ?? 1),
    }

    const prevOptions = [...temp.options]
    const isMultiple = optionGroup.multiple && optionGroup.max !== 1
    const isStepper = isMultiple && (optionItem.max || optionGroup.max) > 1
    const isSelected = Boolean(_.find(prevOptions, prevOption => prevOption.optionItemId === optionItem.id))

    // ======================== Stepper ========================
    if (isStepper) {
      const index = _.findIndex(prevOptions, opt => opt.optionItemId === optionItem.id)
      if (index >= 0) {
        if (newOption.quantity > 0) {
          // 替換成 newOption
          prevOptions.splice(index, 1, newOption)
        } else {
          // 刪除
          prevOptions.splice(index, 1)
        }
      } else {
        // 新增 newOption
        prevOptions.push(newOption)
      }
      if (isSet) {
        dispatch(updateSetItemOptions(prevOptions))
      } else {
        dispatch(updateItemOptions(prevOptions))
      }
      return
    }

    // ======================== Radio ========================
    // 一個 option group 內只能選一個 option
    if (!isMultiple) {
      // 移除這個 option group 裡已選擇的 option
      _.remove(prevOptions, prevOption => prevOption.optionGroupId === optionGroup.id)

      // active 的 radio: 移除這個 option
      if (isSelected) {
        if (isSet) {
          dispatch(updateSetItemOptions(prevOptions))
        } else {
          dispatch(updateItemOptions(prevOptions))
        }
      } else {
        // inactive 的 radio: 新增這個 option
        prevOptions.push(newOption)
        if (isSet) {
          dispatch(updateSetItemOptions(prevOptions))
        } else {
          dispatch(updateItemOptions(prevOptions))
        }
      }
      return
    }

    // ======================== Checkbox ========================
    // 一個 option group 內最多只能選 group.max 個 option
    if (isMultiple) {
      // active 的 checkbox: 移除這個 option
      if (isSelected) {
        _.remove(prevOptions, prevOption => prevOption.optionItemId === optionItem.id)
        if (isSet) {
          dispatch(updateSetItemOptions(prevOptions))
        } else {
          dispatch(updateItemOptions(prevOptions))
        }
      } else {
        // inactive 的 checkbox: 要判斷已選擇的 options 數量是否已達 group 的上限
        const selectedGroupOptions = _.filter(prevOptions, prevOption => prevOption.optionGroupId === optionGroup.id)
        const isMax = optionGroup.max !== 0 && selectedGroupOptions.length >= optionGroup.max
        // 還沒到上限: 新增這個 option
        if (!isMax) {
          prevOptions.push(newOption)
          if (isSet) {
            dispatch(updateSetItemOptions(prevOptions))
          } else {
            dispatch(updateItemOptions(prevOptions))
          }
        }
      }
    }
  }
}

/**
 * 不給 action 會拿到 boolean 用來判斷 optionGroup 可不可以被自動選
 * @param {IAppMenuItem | IAppSet} menu
 * @param {IMenuOptionGroup} optionGroup
 * @param {function?} action
 * @param {number?} stepMin
 * @returns {ThunkFunction}
 */
export function canAutoSelectOptions (menu, optionGroup, action, stepMin) {
  return (dispatch, getState) => {
    // 只有在選項群上下限相等時要執行自動選
    if (optionGroup.max !== optionGroup.min) return !action ? false : null

    const isMultiple = optionGroup.multiple && optionGroup.max !== 1 // group.max === 1 的情況即為單選

    // radio 當選項群為單選，且群組選項只有一個，且為單選 （只有一個 radio），且不可不選
    if (!isMultiple && _.size(optionGroup.options) === 1 && optionGroup.min === 1) {
      if (!action) return true
      dispatch(action(menu, optionGroup))
    }

    if (isMultiple) {
      // stepper 選項群為複選，且群組選項只有一個，且為多選（只有一個 stepper）
      if (_.size(optionGroup.options) === 1 && optionGroup.options[0].max > 1 && optionGroup.options[0].max === optionGroup.min) {
        if (!action) return true
        dispatch(action(menu, optionGroup, optionGroup.min, stepMin))
      }

      // checkbox 選項群為複選，且群組選項最少需點數量與群組選項數量相等，且每個選項皆為單選（全部都是 checkbox）
      if (_.size(optionGroup.options) === optionGroup.min && _.every(optionGroup.options, option => option.max <= 1)) {
        if (!action) return true
        dispatch(action(menu, optionGroup))
      }
    }

    return !action ? false : null
  }
}

/**
 * @param {IAppMenuItem | IAppSet} menu
 * @param {IMenuOptionGroup} optionGroup
 * @returns {ThunkFunction}
 */
export function selectAllOptions (menu, optionGroup, quantity) {
  return (dispatch, getState) => {
    const isSet = Boolean(menu.step)
    _.forEach(optionGroup.options, option => {
      dispatch(updateOptions(option, optionGroup, isSet, undefined, quantity))
    })
  }
}

/**
 * @param {IAppMenuItem | IAppSet} menu
 * @param {IMenuOptionGroup} optionGroup
 * @param {number?} optionQuantity
 * @param {number?} setItemQuantity
 * @returns {ThunkFunction}
 */
export function selectSetItemWithAllOptions (menu, optionGroup, optionQuantity, setItemQuantity = 1) {
  return (dispatch, getState) => {
    const batchItemTemp = getState().batchItemTemp.batchItemTemp
    const setItems = batchItemTemp.setItems ? [...batchItemTemp.setItems] : []
    const temp = []

    _.times(setItemQuantity, () => {
      dispatch(addSetItem(menu))
      dispatch(selectAllOptions(menu, optionGroup, optionQuantity))
      const batchSetItemTemp = getState().batchItemTemp.batchSetItemTemp
      temp.push(batchSetItemTemp)
    })

    dispatch(updateItem('setItems', [...setItems, ...temp]))
  }
}

/**
 * 移除『承接餐單』
 * 例：
 * step1 『漢堡』 （經典漢堡、炸雞漢堡）
 * step2 『副餐』（炸物套餐、沙拉套餐、單點）
 * step3 『炸物』（薯條、可樂餅）  <dependsOnSetMenuItems: [炸物套餐]>
 * step4 『沙拉』（水果沙拉、雞肉沙拉） <dependsOnSetMenuItems: [沙拉套餐]>
 * step5 『沙拉配料』 (葡萄乾、堅果) <dependsOnSetMenuItems: [水果沙拉、雞肉沙拉]>
 * step6 『沙拉醬』 (凱薩醬、和風醬) <dependsOnSetMenuItems: [葡萄乾、堅果]>
 * step7 『炸物醬』 (番茄醬、糖醋醬) <dependsOnSetMenuItems: [薯條、可樂餅]>
 * step8 『飲料』（水、汽水）<dependsOnSetMenuItems: [炸物套餐、沙拉套餐]>
 * 例：經典漢堡 -> 沙拉套餐 -> 水果沙拉 -> 葡萄乾 -> 和風醬 -> 水
 * 1. 當刪除沙拉套餐時應一併刪除水果沙拉、葡萄乾、和風醬、水
 * 2. 當由沙拉套餐更換為單點時應一併刪除水果沙拉、葡萄乾、和風醬、水
 * 3. 當由沙拉套餐更換為炸物套餐時應一併刪除一併刪除水果沙拉、葡萄乾、和風醬，但不刪除水
 * @returns {ThunkFunction}
 */
export function cleanUpDependentSetItems (sets) {
  return (dispatch, getState) => {
    const { setId, setItems } = getState().batchItemTemp.batchItemTemp
    const set = sets?.[setId]

    if (_.isEmpty(setItems)) return
    if (_.isEmpty(set?.steps)) return

    // 對每個 step 檢查是否 step 能存在
    const invalidStepIds = []
    _.each(set.steps, step => {
      const { dependsOnSetMenuItems, dependsOnSetMenuItem } = step
      // step 需要有這些 itemIds 才可以存在
      const dependentItemIds = !_.isEmpty(dependsOnSetMenuItems)
        ? dependsOnSetMenuItems
        : dependsOnSetMenuItem
          ? [dependsOnSetMenuItem]
          : []

      if (!_.isEmpty(dependentItemIds)) {
        // 找出 setItems 有沒有 dependentItemIds 裡面的東西
        const hasDependentSetItem = _.some(dependentItemIds, dependentItemId => {
          return setItems.find(setItem => setItem.setMenuId === dependentItemId)
        })

        if (!hasDependentSetItem) {
          // 沒有找到，這個 step 不能存在
          invalidStepIds.push(step.id)
        }
      }
    })

    // 沒有東西要刪除，結束
    if (_.isEmpty(invalidStepIds)) return

    // 過濾掉 setItems 中該被刪除的東西
    const filteredSetItems = _.filter(setItems, setItem => !_.includes(invalidStepIds, setItem.step))

    // 沒有東西要被刪除，結束
    if (_.size(filteredSetItems) === _.size(setItems)) return

    // 寫入 batchItemTemp.setItems
    dispatch(updateItem(['setItems'], filteredSetItems))

    // 因為有東西被刪除，需要再檢查一次
    dispatch(cleanUpDependentSetItems(sets))
  }
}
