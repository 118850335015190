import { IconButton, SwipeableDrawer as MuiSwipeableDrawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

import ArrowBackIcon from '@/assets/icons/ArrowBackIcon'

/**
 * @param {{
 * open: boolean,
 * onOpen: function,
 * onClose: function,
 * anchor: 'top' | 'bottom' | 'left' | 'right',
 * children: any,
 * backButton?: boolean
 * dragableIndicator?: boolean
 * borderRadius?: number
 * }} props
 */
export default function SwipeableDrawer (props) {
  const {
    open,
    onOpen,
    onClose,
    anchor = 'bottom',
    children,
    paperClassName,
    backButton,
    dragableIndicator,
    borderRadius,
  } = props
  const classes = useStyles({ ...props, anchor, borderRadius })

  return (
    <MuiSwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      open={Boolean(open)}
      onOpen={onOpen}
      onClose={onClose}
      anchor={anchor}
      classes={{ paper: clsx(classes.drawerPaper, paperClassName) }}
    >
      {backButton && (
        <IconButton className={classes.backButton} onClick={onClose}>
          <ArrowBackIcon strokecolor='white' className={classes.arrowBackIcon} classes={{ root: classes.arrowBackIconRoot }} />
        </IconButton>
      )}
      {dragableIndicator && (
        <span className={classes.dragableIndicator} />
      )}
      {children}
    </MuiSwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    overflowY: 'visible',
    padding: theme.spacing(2),
    paddingTop: props => props.anchor === 'bottom' && '8%',
    paddingBottom: props => props.anchor === 'top' && '8%',
    borderTopLeftRadius: props => {
      switch (props.anchor) {
        case 'bottom':
          return props.borderRadius ?? 0
        case 'right':
          return props.borderRadius ?? 0
        default:
          return 0
      }
    },
    borderTopRightRadius: props => {
      switch (props.anchor) {
        case 'bottom':
          return props.borderRadius ?? 0
        case 'left':
          return props.borderRadius ?? 0
        default:
          return 0
      }
    },
    borderBottomLeftRadius: props => {
      switch (props.anchor) {
        case 'top':
          return props.borderRadius ?? 0
        case 'right':
          return props.borderRadius ?? 0
        default:
          return 0
      }
    },
    borderBottomRightRadius: props => {
      switch (props.anchor) {
        case 'top':
          return props.borderRadius ?? 0
        case 'left':
          return props.borderRadius ?? 0
        default:
          return 0
      }
    },
  },
  backButton: {
    position: 'absolute',
    top: -64,
    padding: theme.spacing(3),
  },
  arrowBackIcon: {
    width: 18,
    height: 18,
  },
  arrowBackIconRoot: {
    fill: 'none',
  },
  dragableIndicator: {
    position: 'absolute',
    top: props => {
      switch (props.anchor) {
        case 'bottom':
          return '2%'
        case 'top':
          return '98%'
        case 'left':
          return '50%'
        case 'right':
          return '50%'
        default:
          break
      }
    },
    left: props => {
      switch (props.anchor) {
        case 'bottom':
          return '50%'
        case 'top':
          return '50%'
        case 'left':
          return '98%'
        case 'right':
          return '2%'
        default:
          break
      }
    },
    transform: props => {
      switch (props.anchor) {
        case 'bottom':
          return 'translateX(-50%)'
        case 'top':
          return 'translateX(-50%)'
        case 'left':
          return 'translateY(-50%)'
        case 'right':
          return 'translateY(-50%)'
        default:
          break
      }
    },
    width: props => {
      switch (props.anchor) {
        case 'bottom':
          return 100
        case 'top':
          return 100
        case 'left':
          return 3
        case 'right':
          return 3
        default:
          break
      }
    },
    height: props => {
      switch (props.anchor) {
        case 'bottom':
          return 3
        case 'top':
          return 3
        case 'left':
          return 100
        case 'right':
          return 100
        default:
          break
      }
    },
    borderRadius: 8,
    backgroundColor: '#f9f9f980',
    boxShadow: '0px 1px 4px #39393980',
  },
}))
