export default {
  INIT: 'APP/INIT',
  RESET_ALERTS: 'APP/RESET_ALERTS',
  RESET_DIALOGS: 'APP/RESET_DIALOGS',
  RESET_DRAWERS: 'APP/RESET_DRAWERS',
  RESET_SNACKBAR: 'APP/RESET_SNACKBAR',
  RESET_POPOVER: 'APP/RESET_POPOVER',
  UPDATE_LOADING: 'APP/UPDATE_LOADING',
  UPDATE_SCANNING: 'APP/UPDATE_SCANNING',
  UPDATE_CATEGORYBAR_STICKY: 'APP/UPDATE_CATEGORYBAR_STICKY',
  UPDATE_IS_LOGIN_SETTING: 'APP/UPDATE_IS_LOGIN_SETTING',
  UPDATE_TABLE: 'APP/UPDATE_TABLE',
  UPDATE_LANG: 'APP/UPDATE_LANG',
  TOGGLE_ALERT: 'APP/TOGGLE_ALERT',
  TOGGLE_DRAWER: 'APP/TOGGLE_DRAWER',
  TOGGLE_DIALOG: 'APP/TOGGLE_DIALOG',
  TOGGLE_SNACKBAR: 'APP/TOGGLE_SNACKBAR',
  TOGGLE_POPOVER: 'APP/TOGGLE_POPOVER',
  UPDATE_SETTING: 'APP/UPDATE_SETTING',
  UPDATE_PARAMS: 'APP/UPDATE_PARAMS',
  UPDATE_MERCHANT_ID: 'APP/UPDATE_MERCHANT_ID',
  DELETE_REDEEM_CODE: 'APP/DELETE_REDEEM_CODE',
  DELETE_PROMO_CODE_ID: 'APP/DELETE_PROMO_CODE_ID',
  UPDATE_IS_D2C_WEB: 'APP/UPDATE_IS_D2C_WEB',
  UPDATE_DATETIME: 'APP/UPDATE_DATETIME',
  UPDATE_DELIVERY_TYPE: 'APP/UPDATE_DELIVERY_TYPE',
  UPDATE_SAFEAREA_HEIGHT: 'APP/UPDATE_SAFEAREA_HEIGHT',
  UPDATE_PACKAGE_META: 'APP/UPDATE_PACKAGE_META',
  UPDATE_APP_INFO: 'APP/UPDATE_APP_INFO',
  UPDATE_DEVICE_INFO: 'APP/UPDATE_DEVICE_INFO',
  UPDATE_UPDATE_STATUS: 'APP/UPDATE_UPDATE_STATUS',
  UPDATE_CATEGORIES: 'APP/UPDATE_CATEGORIES',
  UPDATE_SYSTEM_DELIVERYTYPE_ENABLE: 'APP/UPDATE_SYSTEM_DELIVERYTYPE_ENABLE',
  UPDATE_TEST: 'APP/UPDATE_TEST',
  ADD_PENDING_MARKETING_ACTION: 'APP/ADD_PENDING_MARKETING_ACTION',
  REMOVE_PENDING_MARKETING_ACTION: 'APP/REMOVE_PENDING_MARKETING_ACTION',
  SET_TIME_ZONE_ERROR: 'APP/SET_TIME_ZONE_ERROR',
  UPDATE_PAYMENT_GATEWAY: 'APP/UPDATE_PAYMENT_GATEWAY',
}
