import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import { useMerchant } from '@/hooks/merchant'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import { useParams } from '@/hooks/app'
import { usePayingRedirectOrderTracking } from '@/hooks/orderHistory'
import { useSelectedOrder } from '@/hooks/order'
import constants from '@/constants'

import CheckoutFooter from '@/components/Footers/CheckoutFooter'
import Navbar from '@/components/Navbar'
import OrderHistoryItem from '@/pages/OrderHistory/OrderHistoryItem'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import PaymentCard from '@/components/Cards/PaymentCard'
import PaymentMethodDrawer from '@/components/Drawers/PaymentMethodDrawer'

import TableCard from './TableCard'

export default function Pay (props) {
  useOnStatusTapToPageTop()

  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const { deliveryType } = useParams()
  const merchant = useMerchant()
  const isLogin = useIsLogin()

  // 檢查CA能不能付款，不能的話跳轉到 order tracking
  usePayingRedirectOrderTracking()

  useEffect(() => {
    // 進入頁面和離開頁面時重設 prioritizedPromoCodes
    dispatch(actions.order.updatePrioritizedPromoCodes([]))
    return () => {
      dispatch(actions.order.updatePrioritizedPromoCodes([]))
    }
  }, [])

  const debounceAutoFillPromoCode = useCallback(
    _.debounce(() => dispatch(actions.order.autoFillPromoCode()), 1000),
    [],
  )

  useEffect(() => {
    if (!merchant?.id) return
    // 還沒有抓到 merchant 先不處理

    // 訂單金額 0 不用 autoFillPromoCode
    if (!selectedOrder?.originalTotal || selectedOrder.originalTotal === 0) {
      return
    }

    // 自動選擇折最多的 promo code
    debounceAutoFillPromoCode() // 於清除 promocode 後重新計算最優惠 promocode 並設到 redux
  }, [deliveryType, selectedOrder?.originalTotal, merchant?.id, isLogin])

  return (
    <Page>
      <Navbar title={t('app.page.order_history.navbar.title')} />
      <PageContainer className={classes.container}>
        <TableCard />
        <OrderHistoryItem order={selectedOrder} />
        <PaymentCard order={selectedOrder} />
      </PageContainer>
      <CheckoutFooter />
      <PaymentMethodDrawer />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    marginBottom: constants.app.marginBottom.FOOTER,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
}))
