import { Checkbox, Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import React, { useEffect } from 'react'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import { calculateRiceCoinDiscount } from '@/libs/riceCoin'
import { useDeliveryType } from '@/hooks/app'

import Card from './Card'

export default function RedeemRiceCoinCard (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const order = useSelector(state => state.order.selectedOrder)
  const applyRiceCoinDiscount = useSelector(state => state.order.applyRiceCoinDiscount)
  const riceCoinBalance = useSelector(state => state.user.member.amount) ?? 0
  const withholding = useSelector(state => state.user.member.withholding) ?? 0
  const availableBalance = Math.floor(riceCoinBalance - withholding) // RiceCoin僅顯示整數，小數點無條件捨去（因進位會變成我們有多支出RC)
  const { deliveryType, isTable } = useDeliveryType()

  useEffect(() => {
    if (availableBalance === 0) {
      // 沒有 ricecoin balance 當然不用 ricecoin
      dispatch(actions.order.updateApplyRiceCoinDiscount(false))
      return
    }

    if (isTable) {
      // ! 堂食不可使用 RiceCoin 折抵
      dispatch(actions.order.updateApplyRiceCoinDiscount(false))
      return
    }

    if (applyRiceCoinDiscount === null) {
      // 只有在還沒有設定 applyRiceCoinDiscount 且允許使用時才自動選為 true
      dispatch(actions.order.updateApplyRiceCoinDiscount(true))
    }
  }, [availableBalance, applyRiceCoinDiscount, deliveryType])

  const { discountAmount, usedRiceCoin } = calculateRiceCoinDiscount(order)
  const isRedeemable = discountAmount !== 0
  const text = isRedeemable
    ? t('app.component.card.redeem_riceCoin_card.discount_available', { usedRiceCoin, discountAmount })
    : t('app.component.card.redeem_riceCoin_card.discount_unavailable', { availableBalance })

  const onChange = (event) => {
    dispatch(actions.order.updateApplyRiceCoinDiscount(event.target.checked))
  }

  if (isTable) {
    // ! 堂食不可使用 RiceCoin 折抵
    return null
  }

  return (
    <Card className={clsx(classes.container)}>
      <ConfirmationNumberOutlinedIcon style={{ color: '#1a84db' }} />
      <Typography variant='body2'>
        {text}
      </Typography>
      {
        isRedeemable && (
          <Checkbox
            checked={Boolean(applyRiceCoinDiscount)}
            onChange={onChange}
            disabled={!isRedeemable}
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        )
      }
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: theme.spacing(1),
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.info.main, 0.1),
    border: `1px solid ${alpha(theme.palette.info.main, 0.3)}`,
    padding: theme.spacing(1, 2),
  },
  root: {
    padding: 0,
    color: theme.palette.info.main,
    '&$checked': {
      color: theme.palette.info.main,
    },
  },
  checked: {},
}))
