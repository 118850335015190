import app from './app'
import deliveryOption from './deliveryOption'
import deliveryType from './deliveryType'
import googleMap from './googleMap'
import inventory from './inventory'
import merchant from './merchant'
import orderBatchStatus from './orderBatchStatus'
import orderStatus from './orderStatus'
import orderTakeawayStatus from './orderTakeawayStatus'
import paymentGateWay from './paymentGateway'
import paymentMethod from './paymentMethod'
import riceCoin from './riceCoin'
import shipping from './shipping'
import track from './track'

export default {
  app,
  deliveryOption,
  deliveryType,
  googleMap,
  inventory,
  merchant,
  orderBatchStatus,
  orderStatus,
  orderTakeawayStatus,
  paymentGateWay,
  paymentMethod,
  riceCoin,
  shipping,
  track,
}
