import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'

import { useDeliveryType, useParams } from '@/hooks/app'
import { useIsDimbox } from '@/hooks/dimbox'
import { useMerchant } from '@/hooks/merchant'
import useDatetimeText from '@/hooks/useDatetimeText'

/**
 *
 * @param {{
 * hidden: boolean
 * }} props
 * @returns
 */
export default function DatetimeColumn (props) {
  const { hidden } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const datetimeText = useDatetimeText()
  const params = useParams()
  const { isTable } = useDeliveryType()
  const merchant = useMerchant()
  const isDimbox = useIsDimbox()

  const renderTextComponent = () => {
    let { text, style } = datetimeText
    if (!text) return null

    // special case
    if (isTable) {
      style = ''
      text = merchant.name
      if (params.table) {
        const tableGroup = _.findKey(merchant?.tableGroups, group => _.includes(group, params.table))
        text += ` (${t('app.constant.table.table_number')}: ${[tableGroup, params.table].filter(o => o).join(' / ')})`
      }
    }

    if (style === 'COLOR_NUMBER') {
      const numberRegex = /\d+/g
      const characters = [...text]
      return _.map(characters, (char, index) => {
        // 把數字拿出來特別style
        if (!_.isEmpty(char.match(numberRegex))) {
          return (
            <Typography
              key={index}
              component='span'
              className={classes.highlightText}
              style={{
                marginRight: !_.isEmpty(characters[index + 1]?.match(numberRegex)) ? 0 : 4,
                marginLeft: !_.isEmpty(characters[index - 1]?.match(numberRegex)) ? 0 : 4,
              }}
            >{char}
            </Typography>
          )
        }
        if (char === ' ') return <Typography key={index} color='textPrimary' component='span'>&nbsp;</Typography>
        return <Typography key={index} color='textPrimary' component='span'>{char}</Typography>
      })
    }
    if (style === 'DISABLED') return <Typography component='span' noWrap className={classes.disabledText}>{text}</Typography>
    return <Typography color='textPrimary' component='span' noWrap>{text}</Typography>
  }

  return (
    <div
      className={classes.datetimeTextWrap}
      onClick={() => {
        if (hidden) return
        if (params.isD2CWeb) return // 餐廳模式不可選擇 datetime
        if (isDimbox) return // Dimbox phase 1 不能更改取餐時間
        dispatch(actions.app.toggleDrawer('datetime', true))
      }}
    >
      {renderTextComponent()}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  datetimeTextWrap: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(5),
    fontSize: '1.4rem',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    opacity: props => props.hidden ? 0 : 1,
    transition: 'all 300ms ease',
    transitionProperty: 'opacity',
    maxWidth: '160%',
  },
  highlightText: {
    color: theme.palette.common.white,
  },
  disabledText: {
    color: theme.palette.grey[500],
  },
}))
