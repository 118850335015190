import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'
import InputBase from './InputBase'
InputBuilding.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

/**
 *
 * @param {{
 * value: String
 * onChange: Function
 * }} props
 * @returns
 */
export default function InputBuilding (props) {
  const { value, onChange } = props
  const { t } = useTranslation()

  return (
    <InputBase
      inputId='building'
      label={t('app.page.setting_address.address.building.title')}
      placeholder={t('app.page.setting_address.address.building.placeholder')}
      value={value}
      onChange={onChange}
    />
  )
}
