import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import React from 'react'

import defaultTheme from '@/theme'

/**
 * 這裡可以根據 api 的 theme 去改再進去 MuiThemeProvider
 * @param {*} props
 * @returns
 */
export default function ThemeProvider (props) {
  const theme = { ...defaultTheme }

  if (process.env.NODE_ENV === 'development') {
    console.log('theme', theme)
  }

  return (
    <MuiThemeProvider theme={createTheme(theme)} {...props} />
  )
}
