import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'

import { actions, useDispatch } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import BottomNavigation from '@/components/BottomNavigation'
import Header from '@/components/Header'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import hooks from '@/hooks'

import InviteButton from './InviteButton'
import MenuList from './MenuList'
import RiceCoinButton from './RiceCoinButton'
import SupportList from './SupportList'
import UserButton from './UserButton'

export default function Member (props) {
  useOnStatusTapToPageTop()

  const dispatch = useDispatch()
  const isLogin = useIsLogin()
  const showOrderTrackingSnackbar = hooks.app.useOrderTrackingSnackbar()
  const classes = useStyles({ ...props, showOrderTrackingSnackbar })

  useEffect(() => {
    if (isLogin) {
      dispatch(actions.user.getCustomerInfo())
    }
    const updater = setInterval(() => {
      if (isLogin) {
        dispatch(actions.user.getCustomerInfo())
      }
    }, 10e3) // 每10秒更新CustomerInfo，拿到最新RiceCoin balance

    return () => clearInterval(updater)
  }, [isLogin])

  return (
    <Page>
      <Header>
        <div className={classes.info}>
          <UserButton />
          <RiceCoinButton />
        </div>
      </Header>
      <PageContainer className={classes.container}>
        <InviteButton />
        <MenuList />
        <SupportList />
      </PageContainer>
      <BottomNavigation />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    marginBottom: `calc(${constants.app.marginBottom.FOOTER} + ${theme.spacing(1)}px)`,
    paddingBottom: props => props.showOrderTrackingSnackbar && constants.app.height.SNACKBAR,
  },
  info: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 'inherit',
  },
}))
