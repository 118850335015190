import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import { useCuisineRestaurantsQuery } from '@/hooks/queries/useCuisineRestaurantsQuery'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import constants from '@/constants'

import CuisineRestaurantList from '@/components/RestaurantList/CuisineRestaurantList'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Cuisine (props) {
  useOnStatusTapToPageTop()

  const classes = useStyles(props)
  const history = useHistory()
  const payload = history.location.state?.cuisine?.payload
  const { data } = useCuisineRestaurantsQuery(payload)
  const meta = data?.pages?.[0]?.meta

  return (
    <Page>
      <Navbar title={meta?.name} />
      <PageContainer className={classes.container}>
        <CuisineRestaurantList />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))
