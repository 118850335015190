import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded'
import clsx from 'clsx'
/**
 *
 * @param {{
 * text: string
 * hidden: boolean
 * customClasses: object
 * }} props
 * @returns
 */
export default function WarningMessage (props) {
  const { text, hidden, customClasses } = props
  const classes = useStyles(props)

  if (hidden) return null
  return (
    <div className={clsx(classes.warningContainer, customClasses?.root)}>
      <ReportProblemRoundedIcon color='secondary' className={classes.icon} />
      <Typography variant='caption' color='textSecondary'>{text}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  warningContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))
