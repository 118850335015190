import { CssBaseline } from '@material-ui/core'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Router } from 'react-router-dom'
import React, { Suspense } from 'react'

import LaunchFiserv from '@/pages/LaunchFiserv'
import Main from '@/pages/Main'
import SuspenseFallback from '@/pages/SuspenseFallback'
import ThemeProvider from '@/components/ThemeProvider'
import history from '@/libs/history'
import store from '@/redux/store'

const queryClient = new QueryClient()

export default function App () {
  if (window.location.pathname.includes('/launch_fiserv')) return <LaunchFiserv />

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <CssBaseline />
          <Suspense fallback={<SuspenseFallback />}>
            <Router history={history}>
              <Main />
            </Router>
          </Suspense>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  )
}
