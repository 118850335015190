import { Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import constant from '@/constants'
import loadingSvg from '@/assets/icons/circular-loading.svg'

/**
 * @param {{
 * open: boolean,
 * onClick: function,
 * }} props
 */
export default function BackdropLoading (props) {
  const classes = useStyles(props)
  const { open, onClick } = props

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClick}>
      <img className={classes.indicator} src={loadingSvg} alt='loading' />
    </Backdrop>
  )
}

const useStyles = makeStyles((theme, props) => ({
  backdrop: {
    zIndex: constant.app.zIndex.BACKDROP_LOADING,
  },
  '@keyframes rotate': {
    from: { transfrom: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  indicator: {
    userSelect: 'none',
    animationName: '$rotate',
    animation: '800ms infinite linear',
    width: 80,
    height: 80,
    zIndex: constant.app.zIndex.BACKDROP_LOADING + 1,
  },
}))
