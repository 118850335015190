
import { useSelector } from '@/redux'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IShipping } from 'dimorder-orderapp-lib/dist/types/Merchant'
/* eslint-enable no-unused-vars */

/**
 * @returns {IAppOrder?}
 */
export function useSelectedOrder () {
  return useSelector(state => state.order.selectedOrder)
}

/**
 * @returns {IAppOrderBatch}
 */
export function useSelectedBatch () {
  return useSelector(state => state.orderBatch.selectedBatch)
}

/**
 * @returns {IShipping}
 */
export function useShipping () {
  return useSelector(state => state.order.shipping)
}

export default {
  useSelectedOrder,
  useSelectedBatch,
  useShipping,
}
