import _ from 'lodash'
import i18next from 'i18next'
import moment from 'moment'

import dimorderApi from '@/libs/api/dimorder'

// eslint-disable-next-line no-unused-vars
import { IPromoCode } from 'dimorder-orderapp-lib/dist/types/PromoCode'

/**
 * 整理成顯示用的優惠碼可用時間
 * @param {string?} from
 * @param {string?} until
 * @param {string?} format moment format
 * @returns {string}
 */
export const formatRange = (
  from,
  until,
  format = i18next.t('app.common.date_time.format_year_date_time'),
) => {
  const validFrom = from && moment(from).isValid()
    ? moment(from).format(format)
    : i18next.t('app.component.alert.coupon_card.today')

  const validUntil = until && moment(until).isValid()
    ? moment(until).format(format)
    : i18next.t('app.component.alert.coupon_card.noLimit')

  return {
    validFrom,
    validUntil,
  }
}

/**
 * 時間可用
 * @param {string?} from
 * @param {string?} until
 * @returns {boolean}
 */
export const isBetweenValidTime = (from, until) => {
  if (!from && !until) return true // 都沒設定，不限制

  const fromMoment = from ? moment(from) : moment().startOf('day')
  const untilMoment = until ? moment(until) : moment().add(1, 'year')

  if (!fromMoment.isValid()) return false // 時機有問題，保險起見 return false
  if (!untilMoment.isValid()) return false // 時機有問題，保險起見 return false

  return moment().isBetween(fromMoment, untilMoment, undefined, '[)')
}

/**
 * 尚未開放
 * @param {string?} from
 * @param {string?} until
 * @returns {boolean}
 */
export const isNotBegin = (from, until) => {
  const isValidTime = isBetweenValidTime(from, until)
  if (isValidTime) return false // 已經在可用範圍，不是尚未開放
  if (!from) return false // 沒有設定 from，不是尚未開放

  return moment(from).isValid() && moment().isBefore(from)
}

/**
 * 已經過期
 * @param {string?} from
 * @param {string?} until
 * @returns {boolean}
 */
export const isExpired = (from, until) => {
  const isValidTime = isBetweenValidTime(from, until)
  if (isValidTime) return false // 已經在可用範圍，不是已經過期
  if (!until) return false // 沒有設定 until，不是已經過期

  return moment(until).isValid() && moment().isSameOrAfter(until)
}

/**
 * 驗證 merchantId (如果為空字串則為全餐廳適用)
 * @param {IPromoCode} promoCode
 * @param {string} merchantId
 * @returns {boolean}
 */
export function validateMerchantId (promoCode, merchantId) {
  if (!promoCode) return false
  const { merchantId: id } = promoCode
  if (id === merchantId || id === '') return true
  return false
}

/**
 * 驗證日期 validFrom, validUntil
 * @param {IPromoCode} promoCode
 * @returns {boolean}
 */
export function validateDate (promoCode) {
  if (!promoCode) return false
  const { validFrom, validUntil } = promoCode
  return isBetweenValidTime(validFrom, validUntil)
}

/**
 * 驗證使用次數 usedTime < quota
 * @param {IPromoCode} promoCode
 * @returns {boolean}
 */
export function validateQuota (promoCode) {
  if (!promoCode) return false
  const { quota, usedTime } = promoCode
  if (quota != null && usedTime >= quota) return false
  return true
}

/**
 * 驗證最低消費金額 order.total>=minOrderAmount
 * @param {IPromoCode} promoCode
 * @param {number} total
 */
export function validateMinOrderAmount (promoCode, total = 0) {
  if (!promoCode) return false
  const { minOrderAmount } = promoCode
  if (total < minOrderAmount) return false
  return true
}

/**
  * 驗證 promoCodes 是否可用並拿到完整的 IPromoCode
  * @param {IPromoCode[]} promoCodes
  * @param {string} merchantId
  * @param {number} total
  * @returns {IPromoCode[]}
  */
export async function validatePromoCodes (promoCodes, merchantId, total) {
  const promises = _.map(promoCodes, promoCode => {
    return dimorderApi.promoCode.queryPromoCode(merchantId, promoCode.code)
  })
  const result = await Promise.all(promises)
  const validPromoCodes = _.filter(result, promoCode => {
    return (
      promoCode && // 若使用不存在的 code，會拿到 null
      validateMerchantId(promoCode, merchantId) &&
      validateDate(promoCode) &&
      validateQuota(promoCode) &&
      validateMinOrderAmount(promoCode, total)
    )
  })
  return validPromoCodes
}
