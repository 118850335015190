import { useHistory } from 'react-router-dom'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import DeliveryImage from '@/assets/images/home/delivery.png'
import constants from '@/constants'

import DeliveryTypeButtonBase from './DeliveryTypeButtonBase'

const { STORE_DELIVERY } = constants.deliveryType

/**
 * 外帶按鈕
 * 1. 點擊前往外帶餐廳列表頁面
 * @param {*} props
 * @returns
 */
export default function DeliveryButton (props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(actions.app.updateDeliveryType(STORE_DELIVERY))
    history.push('/restaurants')
  }

  return (
    <DeliveryTypeButtonBase
      title='外送'
      subtitle='Delivery'
      onClick={handleClick}
      imageProps={{
        src: DeliveryImage,
        alt: 'Delivery',
        style: {
          top: -24,
          right: -10,
        },
      }}
      buttonProps={{
        style: {
          backgroundColor: 'rgb(255 214 129)',
        },
      }}
    />
  )
}
