import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import FindMoreButton from './FindMoreButton'

/**
 * @typedef CategoryHeaderProps
 * @property {string} title
 * @property {string} [subtitle]
 * @property {() => void} [onFindOutMore]
 * @property {boolean} [hidden = false]
 * @property {boolean} [loading = false]
 * @param {CategoryHeaderProps} props
 * @returns
 */
export default function CategoryHeader (props) {
  const { title, subtitle, onFindOutMore, hidden = false, loading = false } = props
  const classes = useStyles(props)

  if (hidden) return null
  if (loading) return <CategoryHeaderSkeleton />
  return (
    <div className={classes.header}>
      <section className={classes.titleWrap}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </section>
      {onFindOutMore && <FindMoreButton onClick={onFindOutMore} />}
    </div>
  )
}

export function CategoryHeaderSkeleton (props) {
  return (
    <Skeleton width={200} height={50} variant='text' style={{ margin: 8 }} />
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 8,
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: '2rem',
    fontWeight: '500',
  },
  subtitle: {
    marginLeft: theme.spacing(1),
    fontSize: '2rem',
  },
}))
