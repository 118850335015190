import _ from 'lodash'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IAppBatchItem, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'
import { IOrderModifier } from 'dimorder-orderapp-lib/dist/types/Order'
import { Reward, RewardItem } from 'dimorder-orderapp-lib/dist/types/CRM'
/* eslint-enable no-unused-vars */

/**
 * @param {boolean} showNotCompleteSets
 */
export function updateShowNotCompleteSets (showNotCompleteSets) {
  return {
    type: ActionTypes.SHOW_NOT_COMPLETE_SETS,
    payload: { showNotCompleteSets },
  }
}

/**
 * @param {IAppOrderBatch} batch
 * @returns {ThunkFunction}
 */
export function selectBatch (batch) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_BATCH,
      payload: { batch },
    })
  }
}
/**
 * 根據 quantity 可以新增、改數量、移除
 * @param {Reward | RewardItem} reward
 * @param {number} quantity
 * @returns {ThunkFunction}
 */
export function updateRewardItem (reward, quantity) {
  return (dispatch, getState) => {
    if (!reward) return

    // 簡化的 batch item，但增加了 points 以便顯示使用的積分
    /** @type {RewardItem} */
    const rewardItem = { ...reward, quantity }

    dispatch({
      type: ActionTypes.UPSERT_REWARD_ITEM,
      payload: { rewardItem },
    })
  }
}

/**
 * @param {IAppMenuItem | IAppSet} batchItem
 * @returns {ThunkFunction}
 */
export function addItem (batchItem) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: { batchItem: sortSetItems(batchItem) },
    })
  }
}

/**
 *
 * @param {string} key item.key
 * @param {PropertyPath} path The path of the property to set .
 * @param {any} value The value to set.
 * @returns {ThunkFunction}
 */
export function updateSelectedItem (key, path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM,
      payload: { key, path, value },
    })
  }
}

/**
 * @param {IAppBatchItem} batchItem
 * @returns {ThunkFunction}
 */
export function replaceItem (batchItem) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REPLACE_ITEM,
      payload: { batchItem: sortSetItems(batchItem) },
    })
  }
}
/**
 * @param {string} itemKey
 * @returns {ThunkFunction}
 */
export function deleteItem (itemKey) {
  return (dispatch, getState) => {
    const selectedBatch = getState().orderBatch.selectedBatch
    const deleteItemIndex = selectedBatch.items.findIndex(stateItem => stateItem.key === itemKey)
    if (deleteItemIndex >= 0) {
      dispatch({
        type: ActionTypes.DELETE_ITEM,
        payload: { index: deleteItemIndex },
      })
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetBatch () {
  return (dispatch, getState) => {
    localStorage.removeItem('batchStorage')
    dispatch({
      type: ActionTypes.RESET_BATCH,
    })
    dispatch({
      type: ActionTypes.RESET_REWARD_ITEMS,
    })
  }
}

/**
 * @param {object} inventory 剩下的庫存
 * @returns {ThunkFunction}
 */
export function updateSoldOutInventory (inventory = {}) {
  return async (dispatch, getState) => {
    const selectedBatch = getState().orderBatch.selectedBatch

    _.forEach(selectedBatch.items, async batchItem => {
      const isSet = batchItem.setId

      if (isSet) {
        const setItems = batchItem.setItems ?? []
        setItems.forEach(setItem => {
          if (inventory[setItem.menuId] === undefined) {
            // 該 setItem 不在售完清單中
            return
          }
          // 如果套餐的 setItem 售完時
          // 將套餐從 selectedBatch.items 中移除
          dispatch(deleteItem(batchItem.key))
        })
      } else {
        if (inventory[batchItem.menuId] === undefined) {
          // 該 item 不在售完清單中
          return
        }
        // 如果 item 售完時
        // 1. 若尚有庫存則更新數量至庫存數量
        // 2. 若沒有庫存則將 item 從 selectedBatch.items 中移除
        const stock = inventory[batchItem.menuId] > 0 ? inventory[batchItem.menuId] : 0
        const updatedQty = _.min([stock, batchItem.quantity])
        if (updatedQty) {
          dispatch(updateSelectedItem(batchItem.key, 'quantity', updatedQty))
        } else {
          dispatch(deleteItem(batchItem.key))
        }
      }
    })
  }
}

/**
 * 當套餐售完時將其從購物車內移除
 * @param {object} inventory 剩下的庫存
 * @returns {ThunkFunction}
 */
export function removeSoldOutSets (inventory = {}) {
  return (dispatch, getState) => {
    const selectedBatch = getState().orderBatch.selectedBatch

    _.forEach(selectedBatch.items, batchItem => {
      if (inventory[batchItem.setId] === undefined) {
        // 該 set 不在售完清單中
        return
      }
      // 將該售完的 batchItem（套餐）從 selectedBatch.items 中移除
      dispatch(deleteItem(batchItem.key))
    })
  }
}

/**
 * 用來取回內用候位時點的東西因為會被 createOrder 清掉
 * replace old orderId, orderSerial and table to latest
 * @param {IAppBatchItem} batch
 * @returns {ThunkFunction}
 */
export function restoreBatch (batch) {
  return (dispatch, getState) => {
    if (!batch) return

    const selectedOrder = getState().order.selectedOrder
    dispatch({
      type: ActionTypes.RESTORE_BATCH,
      payload: {
        batch: {
          ...batch,
          orderId: selectedOrder.id,
          orderSerial: selectedOrder?.serial,
          table: selectedOrder.table,
        },
      },
    })
  }
}

/**
 * 當重抓 menu 時為了避免已點的餐點的 price, discount 跟新抓的 menu 不一樣
 * 要 call 這個 function 去把全部的 batchItem 更新價錢
 * @returns {ThunkFunction}
 */
export function updateBatchItemsAfterMenuFetched (menus, sets) {
  return (dispatch, getState) => {
    const batchItems = getState().orderBatch.selectedBatch?.items
    if (_.isEmpty(batchItems)) return
    const items = _
      .chain(batchItems)
      .clone()
      .map(batchItem => {
        // 如果是套餐的話
        if (batchItem.isSet) {
          const set = sets[batchItem.setId]
          if (!set) return undefined

          const setItems = _
            .chain(batchItem.setItems)
            .clone()
            .map(setItem => {
              const menu = _.find(set.menus, menu => menu.menuId === setItem.menuId && menu.id === setItem.setMenuId)
              if (!menu) return undefined
              return _
                .chain(setItem)
                .clone()
                .set('price', menu.price)
                .set('discount', menu.discount)
                .value()
            })
            .without(undefined)
            .value()

          return _
            .chain(batchItem)
            .clone()
            .set('setItems', setItems)
            .value()
        } else {
          // 單點
          const menu = _.find(menus, menu => menu.id === batchItem.menuId)
          if (!menu) return undefined
          return _
            .chain(batchItem)
            .clone()
            .set('price', menu.price)
            .set('discount', menu.discount)
            .value()
        }
      })
      .without(undefined)
      .value()

    dispatch({
      type: ActionTypes.UPDATE_BATCH_ITEMS,
      payload: { items },
    })
  }
}

/**
 * 若 batchItem 內有 setItems 則將 setItems 排序
 */
function sortSetItems (batchItem) {
  if (batchItem.setItems) {
    const sorted = _.orderBy(batchItem.setItems, ['stepIndex', 'menuId'], 'asc')
    const result = { ...batchItem, setItems: sorted }
    return result
  } else {
    return batchItem
  }
}
