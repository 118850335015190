import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useIsDimbox } from '@/hooks/dimbox'

import Card from '@/components/Cards/Card'
import WarningMessage from '@/components/WarningMessage'

import Address from './Address'
import Tableware from './Tableware'
import Time from './Time'

/**
 *
 * @param {{
 * isCart: boolean
 * }} props
 * @returns
 */
export default function InfoCard (props) {
  const { isCart } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const isDimbox = useIsDimbox()

  return (
    <Card className={classes.infoCard}>
      <Address isCart={isCart} />
      <Time />
      <WarningMessage text={t('app.component.warning_message.dimbox_pickup_warning')} hidden={!isDimbox} />
      <Tableware />
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  infoCard: {
    '& > *': {
      padding: theme.spacing(1),
    },
  },
}))
