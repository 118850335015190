import { ButtonBase, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import React from 'react'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import { useIsDimbox } from '@/hooks/dimbox'
import { useShipping } from '@/hooks/order'
import constants from '@/constants'

import DatetimeText from './DatetimeText'

const { STORE_DELIVERY, SHOP, TAKEAWAY } = constants.deliveryType

/**
 *
 * @param {{
 * hidden?: boolean
 * disabled?: boolean
 * className?: String
 * }} props
 * @returns
 */
export default function DatetimePickerButton (props) {
  const { hidden, disabled, className } = props
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { deliveryType } = useDeliveryType()
  const shippingTime = useShipping()?.shippingTime
  const isD2CWeb = useSelector(state => state.app.params.isD2CWeb)
  const isD2cRouterLoading = useSelector(state => state.app.loadings.d2cRouterSettingUpdate)
  const isDimbox = useIsDimbox()

  if (hidden) return null

  const renderIcon = () => {
    switch (deliveryType) {
      case STORE_DELIVERY:
        if (!shippingTime) return <CircularProgress size={24} />
        return <ArrowForwardIosOutlinedIcon fontSize='small' className={classes.icon} />
      case TAKEAWAY:
        if (isDimbox) return null
        return <ArrowForwardIosOutlinedIcon fontSize='small' className={classes.icon} />
      case SHOP:
        if (!shippingTime) return null
        return <ArrowForwardIosOutlinedIcon fontSize='small' className={classes.icon} />
      default:
        return <ArrowForwardIosOutlinedIcon fontSize='small' className={classes.icon} />
    }
  }

  return (
    <ButtonBase
      className={clsx(classes.datetimePickerButton, className)}
      onClick={() => dispatch(actions.app.toggleDrawer('datetime'))}
      disabled={disabled || isDimbox} // Dimbox phase 1 不能更改取餐時間
    >
      {isD2CWeb && isD2cRouterLoading
        ? (<CircularProgress size={24} />)
        : (
          <>
            <DatetimeText />
            {renderIcon()}
          </>
        )}
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  datetimePickerButton: {
    alignSelf: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0.5, 2.5),
    minHeight: 40,
    minWidth: '60%',
  },
  icon: {
    fontSize: '1.6rem',
    marginLeft: theme.spacing(0.5),
  },
}))
