import { isSupportWebp } from './isSupportWebp'
import _ from 'lodash'

export const MENU_ITEM_IMAGE = { sm: { width: 200 }, md: { width: 480 }, lg: { width: 1024 } }
export const RESTAURANT_LOGO = { sm: { width: 80 }, md: { width: 160 } }
export const RESTAURANT_COVER = { sm: { width: 480 }, md: { width: 1280 } }
export const RESTAURANT_IMAGE = { width: Math.ceil(300 * window.devicePixelRatio) }
export const BANNER = { sm: { width: 480 }, md: { width: 1024 } }
export const CRM_GROUP_COVER = { sm: { width: 480 }, md: { width: 1280 } }

/**
 * @typedef TransformOptions
 * @property {number} [width = 0]
 * @param {string} url image url
 * @param {TransformOptions} [options = {}]
 * @returns
 */
export function transformation (url = '', options = {}) {
  const { width = 0 } = options

  if (url.includes('res.cloudinary.com')) {
    const widthParam = width ? 'w_' + width : ''
    const splitUrl = url.split('/upload')
    const head = splitUrl.shift()
    splitUrl[0] = '/' + widthParam + splitUrl[0]
    splitUrl.unshift(head)
    url = splitUrl.join('/upload')
    url = url.replace('.png', '.jpg')
    return url
  }

  if (url.includes('img.dimorder.com')) {
    const res = /(https:\/\/img\.dimorder\.com)\/([^/]*)\/(.*)/.exec(url)
    if (!res) return url
    const widthParam = width ? 'w:' + width : undefined // 圖片寬度設定

    const tailing = res[3].split('/')

    // 移除網址上帶的寬度，改為套用設定的值
    if (widthParam) {
      _.remove(tailing, param => /w:(\d*)/.test(param))
      tailing.unshift(widthParam)
    }

    // 如果網址沒有 /plain，則在 /public 前加入 /plain
    if (!tailing.includes('plain')) {
      const publicIndex = tailing.findIndex(param => param === 'public')
      tailing.splice(publicIndex, 0, 'plain')
    }

    return [res[1], '-', ...tailing].join('/') + (isSupportWebp() ? '@webp' : '')
  }

  return url
}

export default {
  transformation,
}
