import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import ActionButtonBase from './ActionButtonBase'

import Container from '@/assets/icons/Container.jsx'
import Favorite from '@/assets/icons/Favorite.jsx'
import RCIcon from '@/assets/icons/RcIcon.jsx'
import User from '@/assets/icons/User.jsx'

export default function LinkButton ({ btnType }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const isLogin = useIsLogin()

  if (btnType === 'favorite') {
    return (
      <ActionButtonBase
        icon={<Favorite className={classes.icon} />}
        text={t('app.page.home.member_action_buttons.favorite')}
        onClick={() => {
          if (isLogin) {
            history.push('/favorite')
            return
          }
          dispatch(actions.app.toggleLoginDrawer(true))
        }}
      />
    )
  }
  if (btnType === 'member') {
    return (
      <ActionButtonBase
        icon={<User className={classes.icon} />}
        text={t('app.page.home.member_action_buttons.member')}
        onClick={() => {
          // member page 可以不用 login
          history.push('/member')
        }}
      />
    )
  }
  if (btnType === 'orders') {
    return (
      <ActionButtonBase
        icon={<Container className={classes.icon} />}
        text={t('app.page.home.member_action_buttons.orders')}
        onClick={() => {
          history.push('/orders')
        }}
      />
    )
  }
  if (btnType === 'riceCoin') {
    return (
      <ActionButtonBase
        icon={<RCIcon className={classes.icon} />}
        text={t('app.page.home.member_action_buttons.rice_coin')}
        onClick={() => {
          if (isLogin) {
            history.push('/member/ricecoin')
            return
          }
          dispatch(actions.app.toggleLoginDrawer(true))
        }}
      />
    )
  }
}

const useStyles = makeStyles(theme => ({
  icon: {
    margin: theme.spacing(0.5),
  },
}))
