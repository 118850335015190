import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import {
  formatRange,
  validateDate,
  validateMerchantId,
  validateMinOrderAmount,
} from '@/libs/promoCode'
import { useSelectedOrder } from '@/hooks/order'
import CouponCard from '@/components/Coupon/CouponCard'
import useShortLang from '@/hooks/useShortLang'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AvailableCouponList (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const lang = useShortLang()
  const { merchantId, deliveryType } = useSelector(state => state.app.params)
  const coupons = useSelector(state => state.user.coupons)
  const order = useSelectedOrder()

  const availableCoupons = React.useMemo(() => {
    return _.chain(coupons)
      .filter(coupon => {
        return (
          !coupon.usedAt && // 有 usedAt 表示被使用
          (!coupon.deliveryType || coupon.deliveryType === deliveryType) && // 沒設定 deliveryType 或 deliveryType 符合設定
          validateMerchantId(coupon, merchantId) && // 符合 merchant id 設定
          validateDate(coupon) && // 符合使用時間
          validateMinOrderAmount(coupon, order?.originalTotal) // 達到低消
        )
      })
      .uniqBy('id')
      .value()
  }, [coupons, merchantId, deliveryType, order])

  const onConfirm = async (coupon) => {
    dispatch(actions.order.applyCoupon(coupon))
    dispatch(actions.app.toggleDialog('promoCode'))
  }

  if (_.isEmpty(availableCoupons)) {
    return null
  }

  return (
    <div>
      <Typography className={classes.title}>
        {t('app.component.dialog.promo_dialog.available_coupons')}
      </Typography>
      {
        _.map(availableCoupons, (coupon, index) => {
          const { validFrom, validUntil } = formatRange(coupon.validFrom, coupon.validUntil, t('app.common.date_time.format_year_date'))

          return (
            <CouponCard
              key={index}
              name={coupon?.name?.[lang]}
              type='COUPON'
              desc={[`${t('app.component.alert.coupon_card.expiration')} ${validFrom}-${validUntil}`]}
              validFrom={coupon?.validFrom}
              validUntil={coupon?.validUntil}
              buttonText={t('app.component.alert.coupon_card.useNow')}
              discountNumber={coupon.amount === 0 ? coupon.percent : coupon.amount}
              discountType={coupon.amount === 0 ? 'percent' : 'amount'}
              applyTo={coupon.applyTo}
              merchantId={coupon.merchantId}
              minOrderAmount={coupon.minOrderAmount}
              tags={coupon.tags}
              onTncButtonClick={() => dispatch(actions.app.toggleDialog('couponTnc', true, coupon))}
              onButtonClick={() => onConfirm(coupon)}
            />
          )
        })
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.6rem',
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))
