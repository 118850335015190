import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {{
 * title: string,
 * total: number
 * }} props
 * @returns
 */
export default function TotalQuantity (props) {
  const { title, total, LeftContent, RightContent } = props
  const classes = useStyles(props)

  return (
    <div className={classes.totalQuantity}>
      {LeftContent ? <LeftContent /> : <span />}
      <Typography variant='caption' style={{ textAlign: 'center', fontWeight: 700 }}>{title} ({total})</Typography>
      {RightContent ? <RightContent /> : <span />}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  totalQuantity: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    paddingBottom: theme.spacing(1),
  },
}))
