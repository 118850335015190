import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import constants from '@/constants'
import getCategoryName from '@/libs/getCategoryName'

import CategoryRestaurantList from '@/components/RestaurantList/CategoryRestaurantList'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Category (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const history = useHistory()
  const category = history.location.state?.category
  const categoryName = getCategoryName(category?.name, t)

  return (
    <Page>
      <Navbar title={categoryName} />
      <PageContainer className={classes.container}>
        <CategoryRestaurantList category={category} />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))
