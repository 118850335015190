import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useIsLogin } from '@/hooks/user'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import MenuItem from '@/pages/Member/MenuItem'
import TestDateInput from '@/components/TestDateInput'
import TestTimeInput from '@/components/TestTimeInput'
import useTrack from '@/hooks/useTrack'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MenuList (props) {
  const classes = useStyles(props)
  const { testLogin, testLogout, sendEvent, testEvent } = useTrack()
  const isLogin = useIsLogin()

  return (
    <List className={classes.menuList}>
      <MenuItem variant='riceCoin' />
      <MenuItem variant='redeem' />
      <MenuItem variant='coupon' />
      <MenuItem variant='membership' hidden={!isLogin} />
      <MenuItem variant='favorite' />
      <MenuItem variant='address' />
      <MenuItem variant='language' />
      <MenuItem variant='version' />
      <MenuItem variant='login' />
      <MenuItem variant='logout' />
      <TestDateInput className={classes.testInput} />
      <TestTimeInput className={classes.testInput} />
      {false && (
        <>
          <LetterSpacingButton onClick={() => sendEvent('test--no-login', { from: 'member' })}>send event</LetterSpacingButton>
          <LetterSpacingButton onClick={() => testLogin()}>login</LetterSpacingButton>
          <LetterSpacingButton onClick={() => testLogout()}>logout</LetterSpacingButton>
          <LetterSpacingButton onClick={() => testEvent()}>testEvent</LetterSpacingButton>
        </>
      )}
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  menuList: {
    display: 'grid',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
  testInput: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: theme.shadows[25],
    padding: theme.spacing(1, 2),
    minHeight: 48,
  },
}))
