import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { checkIsSold } from '@/libs/menu'
import { useBatchItemTemp } from '@/hooks/batchItemTemp'
import { useMerchant } from '@/hooks/merchant'
import constants from '@/constants'

import { DrawerStepperBase } from './StepperBase'

function getMax (enableInventory, isSet, isSold, inventory = 0) {
  if (isSet) return undefined // 賣完直接 undefined
  if (isSold) return undefined // 賣完直接 undefined
  if (!enableInventory) return constants.app.stepper.MAX // 沒有開啟庫存管理，return MAX
  if (inventory === -1) return constants.app.stepper.MAX // 該餐點為無限庫存，return MAX
  return inventory
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function MenuItemStepper (props) {
  const batchItemTemp = useBatchItemTemp()
  const dispatch = useDispatch()
  const merchant = useMerchant()
  const menu = useSelector(state => state.app.drawers.menuItem.data.menu)
  const setMenu = useSelector(state => state.app.drawers.setItem.data.setMenu)
  const setItemMenu = useSelector(state => state.app.drawers.setItem.data.setItemMenu)
  const menuItem = menu || setMenu

  const isSet = Boolean(menuItem?.step)
  const isSold = checkIsSold({ merchant, menu: menuItem, setItemMenu })
  const max = getMax(merchant.setting.inventory, isSet, isSold, menuItem?.inventory)

  const handleIncrement = (e, number) => {
    e.stopPropagation()
    dispatch(actions.batchItemTemp.updateItem(['quantity'], batchItemTemp.quantity + number))
  }
  const handleDecrement = (e, number) => {
    e.stopPropagation()
    dispatch(actions.batchItemTemp.updateItem(['quantity'], batchItemTemp.quantity - number))
  }

  return (
    <DrawerStepperBase
      quantity={batchItemTemp.quantity}
      min={1}
      max={max}
      onIncrement={e => handleIncrement(e, 1)}
      onDecrement={e => handleDecrement(e, 1)}
    />
  )
}
