import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useSelector } from '@/redux'
import React from 'react'

export default function RiceCoinButton (props) {
  const classes = useStyles(props)
  const history = useHistory()
  const riceCoinBalance = useSelector(state => state.user.member.amount) ?? 0
  const withholding = useSelector(state => state.user.member.withholding) ?? 0
  const availableBalance = Math.round(riceCoinBalance - withholding)

  return (
    <ButtonBase
      classes={{ root: classes.button }}
      onClick={() => history.push('/member/ricecoin')}
    >
      <div className={classes.text}>
        <Typography className={classes.amount}>
          {availableBalance}
        </Typography>
        <Typography className={classes.caption}>
          RiceCoin
        </Typography>
      </div>
      <div className={classes.logoWrap}>
        <img src={require('@/assets/icons/rcIcon.svg').default} className={classes.logo} />
      </div>

    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    zIndex: 'inherit',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  amount: {
    fontSize: '2.4rem',
    fontWeight: 800,
  },
  caption: {
    fontSize: '0.9rem',
    lineHeight: '0.1rem',
  },
  logoWrap: {
    height: 32,
    width: 32,
    marginLeft: theme.spacing(1),
  },
  logo: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
}))
