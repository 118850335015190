import { Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'
import clsx from 'clsx'

import { getPaymentMethodConfig } from '@/libs/paymentMethod'
import { useSelector } from '@/redux'
import Card from '@/components/Cards/Card'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethod from '@/constants/paymentMethod'

/**
 * @typedef PaymentMethodCardProps
 * @property {TPaymentMethod} [paymentMethod]
 * @property {TCardProvider} [cardProvider]
 * @property {string} [className]
 * @property {() => void} [onClick]
 * @property {string} [displayText]
 * @property {string} [titleText]
 * @property {string} [rightText]
 * @property {() => JSX.Element} [renderRight]
 */

const FISERV_CREDIT_CARD_WALLET_ICONS = []
if (window.ApplePaySession) {
  FISERV_CREDIT_CARD_WALLET_ICONS.push(getPaymentMethodConfig(PaymentMethod.APPLE_PAY)?.iconSource)
}
if (
  navigator.userAgent.includes('Chromium/') ||
  navigator.userAgent.includes('Safari/') ||
  navigator.userAgent.includes('Firefox/') ||
  navigator.userAgent.includes('OPR/') ||
  navigator.userAgent.includes('UCBrowser/')
) {
  // Google Pay API 支援 Google Chrome、Mozilla Firefox、Apple Safari、Microsoft Edge、Opera 與 UCWeb UC 瀏覽器。
  FISERV_CREDIT_CARD_WALLET_ICONS.push(getPaymentMethodConfig(PaymentMethod.GOOGLE_PAY)?.iconSource)
}

/**
 *
 * @param {PaymentMethodCardProps} props
 * @returns
 */
export default function PaymentMethodCard (props) {
  const { paymentMethod, cardProvider, className, onClick, displayText, titleText, rightText, renderRight } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const creditcardGateway = useSelector(state => state.app.paymentGateway.creditcard)

  const configKey = cardProvider || paymentMethod
  const paymentMethodConfig = getPaymentMethodConfig(configKey)
  const text = displayText || (paymentMethod ? t(`app.constant.paymentMethod.${paymentMethod}`) : '')
  const smallText = (paymentMethod ? t(`app.constant.paymentMethodSmallText.${paymentMethod}`) : '')
  const iconSource = paymentMethodConfig?.iconSource

  // 在還沒有 cardProvider 時 fiserv 的 credit card 需要顯示很多種信用卡 icons (FISERV_CREDIT_CARD_ICONS)
  const isFiservCreditCardPayment = !cardProvider && paymentMethod === PaymentMethod.CREDIT_CARD && creditcardGateway === PaymentGateway.PAYMENT_GATEWAY_FISERV
  const isWeChatOrAli = paymentMethod === PaymentMethod.WECHAT || paymentMethod === PaymentMethod.WECHAT_PAY || paymentMethod === PaymentMethod.ALI_PAY

  function RightContnet () {
    if (typeof renderRight === 'function') return renderRight()
    if (rightText) return <Typography className={classes.smallText}>{rightText}</Typography>
    if (onClick) return <ArrowForwardIosIcon fontSize='small' />
    return null
  }

  const renderWechatAliContent = () => {
    // 多一個small text
    return (
      <>
        {titleText && (
          <Typography className={clsx(classes.text, { [classes.flex1]: titleText })}>
            {titleText}
          </Typography>
        )}
        <div className={clsx(classes.provider, { [classes.flex1]: !titleText })}>
          {iconSource && <img className={classes.image} src={iconSource} />}
          <Typography className={classes.text}>
            {text}
            <Typography className={classes.smallText}>{smallText}</Typography>
          </Typography>

        </div>
      </>
    )
  }

  const renderFiservCreditCardContent = () => {
    // 顯示多種信用卡 icons，不顯示 title
    return (
      <>
        <div className={clsx(classes.provider, { [classes.flex1]: !titleText })}>
          {FISERV_CREDIT_CARD_WALLET_ICONS.map((iconSource, index) => (
            <img key={index} className={classes.image} src={iconSource} />
          ))}
          <Typography className={clsx(classes.text, classes.fiservCreditCardText)}>{text}</Typography>
        </div>
      </>
    )
  }

  const renderPaymentMethodContent = () => {
    if (isFiservCreditCardPayment) {return renderFiservCreditCardContent()}
    if (isWeChatOrAli) {return renderWechatAliContent()}
    // 正常顯示支付方式
    return (
      <>
        {titleText && (
          <Typography className={clsx(classes.text, { [classes.flex1]: titleText })}>
            {titleText}
          </Typography>
        )}
        <div className={clsx(classes.provider, { [classes.flex1]: !titleText })}>
          {iconSource && <img className={classes.image} src={iconSource} />}
          <Typography className={classes.text}>{text}</Typography>
        </div>
      </>
    )
  }
  let containerClass = classes.container
  // wechatpay and alipay have small text
  if (isWeChatOrAli) {
    containerClass = classes.container2
  }
  return (
    <Card
      className={clsx(
        containerClass,
        className)}
      onClick={onClick}
    >
      {renderPaymentMethodContent()}
      <RightContnet />
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2.6, 2),
    minHeight: 40,
  },
  flex1: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  provider: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  image: {
    height: 18,
    marginRight: theme.spacing(1),
  },
  text: {
    fontSize: '1.4rem',
    whiteSpace: 'nowrap',
  },
  fiservCreditCardText: {
    fontWeight: 'bold',
  },
  smallText: {
    fontSize: '1.2rem',
    color: '#777',
  },
  container2: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    minHeight: 40,
  },
}))
