import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import MemberAvatar from '@/components/MemberAvatar'

export default function UserButton (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const member = useSelector(state => state.user.member)
  const history = useHistory()
  const { t } = useTranslation()

  const handleClick = () => {
    if (!member?.id) {
      dispatch(actions.app.toggleLoginDrawer(true))
    } else {
      history.push('/member/edit')
    }
  }

  return (
    <ButtonBase className={classes.user} onClick={handleClick}>
      <MemberAvatar size={60} />
      <div className={classes.text}>
        <Typography variant='h6' className={classes.primary}>
          {member?.name || t('app.common.sign_up_or_sign_in')}
        </Typography>
        <Typography variant='caption' color='textPrimary' className={classes.secondary}>
          {member?.mobile}
        </Typography>
      </div>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  user: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(1.5),
    justifyItems: 'start',
    zIndex: 'inherit',
    overflow: 'hidden',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    overflow: 'hidden',
  },
  primary: {
    fontWeight: 'bold',
  },
}))
