import React from 'react'

import { useSelector } from '@/redux'

import ChangeLangDialog from './ChangeLangDialog'
import InvitationDialog from './InvitationDialog'
import MenuDetailDialog from './MenuDetailDialog'
import MerchantDetailDialog from './MerchantDetailDialog'
import PromoCodeDialog from './PromoCodeDialog'
import RedeemCodeDialog from './RedeemCodeDialog'
import SetItemDeleteDialog from './SetItemDeleteDialog'
import SetItemOptionDialog from './SetItemOptionDialog'
import SplashAdDialog from './SplashAdDialog'
// import DineinOrderConfirmDialog from './DineinOrderConfirmDialog'
// import ServiceRequestDialog from './ServiceRequestDialog'
import CouponClaimedDialog from './CouponClaimedDialog'
import CouponTncDialog from './CouponTncDialog'
import PoonchoiTimePicker from './SpecialTimePicker/PoonchoiTimePicker'

export default function Dialogs (props) {
  const lang = useSelector(state => state.app.lang)

  return (
    <>
      <ChangeLangDialog lang={lang} />
      <MenuDetailDialog />
      <MerchantDetailDialog />
      <SetItemOptionDialog />
      <SetItemDeleteDialog />
      <PromoCodeDialog />
      <RedeemCodeDialog />
      {/* <ServiceRequestDialog /> */}
      {/* <DineinOrderConfirmDialog /> */}
      <InvitationDialog />
      <SplashAdDialog />
      <PoonchoiTimePicker />
      <CouponTncDialog />
      <CouponClaimedDialog />
    </>
  )
}
