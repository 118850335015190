import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'
import i18next from 'i18next'

const Tags = (props) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const {
    applyTo,
    tags,
    disabled,
    minOrderAmount,
    merchantId,
  } = props
  return (
    <div className={classes.tags}>
      {
        applyTo === 'SHIPPING' &&
          <div
            className={clsx(classes.tag, {
              [classes.availableTag]: !disabled,
              [classes.unavailableTag]: disabled,
            })}
          >
            <Typography>
              {t('app.page.coupon_details.discount_on_shipping')}
            </Typography>
          </div>
      }
      {
        merchantId !== '' &&
          <div
            className={clsx(classes.tag, {
              [classes.availableTag]: !disabled,
              [classes.unavailableTag]: disabled,
            })}
          >
            <Typography>
              {t('app.page.coupon_details.specific_merchant')}
            </Typography>
          </div>
      }
      {
        minOrderAmount > 0 &&
          <div
            className={clsx(classes.tag, {
              [classes.availableTag]: !disabled,
              [classes.unavailableTag]: disabled,
            })}
          >
            <Typography>
              HK${minOrderAmount} {t('app.page.coupon_details.minimum')}
            </Typography>
          </div>
      }
      {
        tags && tags.map((tag, index) => (
          <div key={index}>
            {
              i18next.exists(`app.component.coupon.tags.${tag}`) &&
                <div
                  className={clsx(classes.tag, {
                    [classes.availableTag]: !disabled,
                    [classes.unavailableTag]: disabled,
                  })}
                >
                  <Typography>
                    {t(`app.component.coupon.tags.${tag}`)}
                  </Typography>
                </div>
            }

          </div>

        ))
      }
    </div>
  )
}

export default Tags
const useStyles = makeStyles(theme => ({
  tag: {
    padding: theme.spacing(0.125, 1),
    borderRadius: 12,
    marginRight: 3,
    '& > p': {
      fontSize: 11,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flex: 1,
    flexWrap: 'wrap',
    gap: '4px',
  },
  availableTag: {
    backgroundColor: 'rgb( 255 206 103)',
    color: '#000000',
  },
  unavailableTag: {
    backgroundColor: 'rgb(136 136 136)',
    color: '#FFFFFF',
  },
}))
