import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Smile (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#000' fillRule='nonzero'>
          <g>
            <path d='M5.625 8.223c0 .517.42.937.938.937.517 0 .937-.42.937-.937 0-.518-.42-.938-.938-.938-.517 0-.937.42-.937.938zm6.875 0c0 .517.42.937.938.937.517 0 .937-.42.937-.937 0-.518-.42-.938-.938-.938-.517 0-.937.42-.937.938zM10 1.25c-4.832 0-8.75 3.918-8.75 8.75s3.918 8.75 8.75 8.75 8.75-3.918 8.75-8.75S14.832 1.25 10 1.25zm5.137 13.887c-.668.668-1.446 1.191-2.31 1.558-.893.38-1.845.57-2.827.57s-1.934-.19-2.828-.57c-.865-.365-1.643-.89-2.31-1.558-.669-.668-1.192-1.446-1.56-2.31-.376-.893-.568-1.845-.568-2.827s.192-1.934.57-2.828c.366-.865.891-1.643 1.56-2.31.667-.669 1.445-1.192 2.31-1.56.892-.376 1.844-.568 2.826-.568s1.934.192 2.828.57c.865.366 1.643.891 2.31 1.56.669.667 1.192 1.445 1.56 2.31.376.892.568 1.844.568 2.826s-.192 1.934-.57 2.828c-.366.865-.891 1.643-1.56 2.309zm-2.168-4.727h-.94c-.082 0-.152.063-.158.145-.074.966-.885 1.73-1.871 1.73s-1.799-.764-1.871-1.73c-.006-.082-.076-.145-.158-.145h-.94c-.09 0-.16.074-.156.164.086 1.647 1.455 2.961 3.125 2.961s3.04-1.314 3.125-2.96c.004-.09-.066-.165-.156-.165z' transform='translate(-149 -2580) translate(149 2580)' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
