import _ from 'lodash'

import { Clipboard } from '@capacitor/clipboard'
import { actions } from '@/redux'
import DeliveryTypes from '@/constants/deliveryType'
import delay from '@/libs/delay'
import dimorderApi from '@/libs/api/dimorder'
import handleAxiosError from '@/libs/handleAxiosError'
import history from '@/libs/history'
import i18n from '@/i18n'

import { initialFilter } from './reducer'
import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem, IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { TABLE } = DeliveryTypes

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    // 先抓一次
    await dispatch(getOrders())
    dispatch({ type: ActionTypes.INIT })

    // 開始定期更新
    dispatch(startOrdersUpdater())

    window.setOrdersUpdaterDuration = (ms) => {
      if (ordersUpdateInterval) {
        localStorage.setItem('getOrderDuration', ms)
        clearInterval(ordersUpdateInterval)
        ordersUpdateInterval = null
        dispatch(startOrdersUpdater())
      }
    }
    window.stopOrdersUpdater = () => {
      console.log('Stop get orders updater.')
      dispatch(stopOrdersUpdater())
    }
  }
}

let ordersUpdateInterval = null
/**
 * @returns {ThunkFunction}
 */
export function startOrdersUpdater () {
  return async (dispatch, getState) => {
    console.log('startOrdersUpdater()')
    const duration = await localStorage.getItem('getOrderDuration')

    if (ordersUpdateInterval) {
      clearInterval(ordersUpdateInterval)
    }

    ordersUpdateInterval = setInterval(() => {
      console.log('[getOrders] in ordersUpdateInterval')
      dispatch(getOrders())
    }, duration ?? 10e3) // 10秒更新一次 orderHistory.orders
  }
}

/**
 * @returns {ThunkFunction}
 */
export function stopOrdersUpdater () {
  return async (dispatch, getState) => {
    console.log('stopOrdersUpdater()')
    if (ordersUpdateInterval) {
      clearInterval(ordersUpdateInterval)
      ordersUpdateInterval = null
    }
  }
}

/**
 * 更新單個 order
 * @param {IAppOrder} appOrder app 格式的 order
 * @returns {ThunkFunction}
 */
export function updateOrder (appOrder) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ORDER,
      payload: { order: appOrder },
    })
  }
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function getOrder (orderId) {
  return async (dispatch) => {
    const order = await dimorderApi.order.getOrder(orderId)
    if (order) {
      dispatch({
        type: ActionTypes.UPDATE_ORDER,
        payload: { order },
      })
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getOrders (isFilteredByMe = false) {
  return async (dispatch, getState) => {
    const { isD2CWeb, merchantId } = getState().app.params
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const promoCode = getState().order.promoCode
    const params = { isFilteredByMe }
    if (isD2CWeb && merchantId) {
      params.merchantId = merchantId
    }

    // 先保留更新前的舊訂單，可用於比對
    const orderingOrder = getState().order.selectedOrder
    const originalSelectedOrder = getState().orderHistory.orders.find(order => order.id === selectedOrderId)

    // 抓訂單更新
    try {
      const newOrders = await dimorderApi.order.getOrders(params)

      // 如果有追蹤中的 global order，檢查有沒有在 newOrders 中
      const isTrackingGlobalOrder = getState().orderHistory.isTrackingGlobalOrder
      if (isTrackingGlobalOrder && selectedOrderId) {
        if (newOrders.find(order => order.id === selectedOrderId)) {
          // 在 getOrders 中有抓到追蹤中的訂單，表示使用者已經擁有這張訂單，不需要再用 global 的 getOrder 更新
          dispatch(trackingGlobalOrder(false))
        } else {
          // 沒有找到，表示需要用 global 的 getOrder 抓看看
          const globalOrder = await dimorderApi.getGlobalOrder(selectedOrderId)
          if (globalOrder) {
            // 抓到了，放進 newOrders 中一起更新
            newOrders.push(globalOrder)
          }
        }
      }

      // 更新 orderHistory.orders
      dispatch({
        type: ActionTypes.UPDATE_ORDERS,
        payload: { orders: newOrders },
      })

      // 處理正在點餐的訂單
      let updatedOrder = _.find(newOrders, order => order.id === orderingOrder?.id)
      if (updatedOrder) {
        updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder))

        if (orderingOrder.deliveryType === TABLE && orderingOrder?.status === 'pending' && updatedOrder.status === 'paid') {
          // 前往 OrderTracking 顯示已付款、重設內用中定單
          if (isD2CWeb && merchantId) {
            history.push(`/d2c/${merchantId}/order_tracking`, { resetOrder: true })
          } else {
            history.push('/order_tracking', { resetOrder: true })
          }
        } else if (window.location.pathname.includes('/pay') && promoCode) {
          // 正在付款且有填了 promocode，需要在訂單更新後再判斷一次 autoFillPromoCode，為了避免 UI 上金額亂跳，顯示 loading
          // 更新 order.selectedOrder
          dispatch(actions.order.selectOrder(updatedOrder))
          // 更新 promocode
          await dispatch(actions.order.autoFillPromoCode())
          await delay(500)
        } else {
          // 更新 order.selectedOrder
          dispatch(actions.order.selectOrder(updatedOrder))
        }
      }

      const newSelectedOrder = _.find(newOrders, order => order.id === selectedOrderId)
      if (!newSelectedOrder) return

      // 處理 dimbox 訂單
      const isDimboxOrder = _.some(newSelectedOrder?.tags, tag => tag?.name?.toLowerCase() === 'dimbox')
      if (isDimboxOrder) {
        // 若是 dimbox 訂單，更新 dimboxes
        dispatch(actions.dimbox.updateDimboxes(newSelectedOrder.pickupAt))
      }

      // 處理正在付款的訂單
      // 判斷訂單之前是否在等待付款
      const isOrderPendingPay = ['waiting_pay', 'paying'].includes(originalSelectedOrder?.displayStatusKey)
      if (!isOrderPendingPay) return
      // 處理正在顯示查詢付款 alert 的訂單
      if (getState().app.alerts[selectedOrderId]?.open) {
        // 若 alert 有打開，先關閉 alert
        dispatch(actions.app.toggleAlert(null, selectedOrderId, false))
        if (newSelectedOrder.status === 'paid') {
          // 付款成功，跳出付款成功
          dispatch(actions.app.toggleAlert({
            message: i18n.t('app.page.order_tracking.checking_order_payment_alert.success.message'),
          }))
        } else if (newSelectedOrder.status === 'pending') {
          // 付款未成功
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.page.order_tracking.checking_order_payment_alert.no_result.title'),
            messages: [
              i18n.t('app.page.order_tracking.checking_order_payment_alert.no_result.messages_1'),
              i18n.t('app.page.order_tracking.checking_order_payment_alert.no_result.messages_2'),
              i18n.t('app.page.order_tracking.checking_order_payment_alert.no_result.messages_3'),
            ],
            button: {
              text: i18n.t('app.common.continue'),
              onClick: () => {
                // 打開 payment help
                dispatch(actions.app.toggleDrawer('paymentHelp', true))
              },
            },
          }))
        }
      }
    } catch (error) {
      console.log('getOrders error', error)
    }
  }
}

export function trackingGlobalOrder (isTrackingGlobalOrder) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.TRACKING_GLOBAL_ORDER,
      payload: { isTrackingGlobalOrder },
    })
  }
}

export function openReturnPrevBrowserAlert () {
  return (dispatch, getState) => {
    dispatch(actions.app.toggleAlert({
      title: i18n.t('app.page.order_tracking.return_prev_browser_alert.title'),
      message: i18n.t('app.page.order_tracking.return_prev_browser_alert.message'),
      buttons: [
        {
          text: i18n.t('app.page.order_tracking.return_prev_browser_alert.copy_link'),
          onClick: () => {
            Clipboard.write({
              string: window.location.href,
            })
            dispatch(actions.snackbar.enqueueSnackbar({
              message: i18n.t('app.page.order_tracking.return_prev_browser_alert.copied'),
            }))
          },
        },
        { text: i18n.t('app.common.confirm') },
      ],
    }))
  }
}

/**
 * @param {string?} orderId
 * @param {boolean} [trackingGlobal=false]
 * @returns {ThunkFunction}
 */
export function selectOrder (orderId, trackingGlobal = false) {
  return (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    if (selectedOrderId !== orderId) {
      dispatch(resetSelectedOrderItems())
    }
    dispatch({
      type: ActionTypes.SELECT_ORDER,
      payload: { orderId },
    })

    dispatch(trackingGlobalOrder(trackingGlobal))
  }
}

/**
 * 選擇/反選 batch item
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function toggleSelectItem (item) {
  return (dispatch, getState) => {
    const selectedItems = getState().orderHistory.selectedOrderItems
    const existsItemIndex = selectedItems.findIndex(selectedItem => selectedItem.id === item.id)
    if (existsItemIndex >= 0) {
      dispatch({
        type: ActionTypes.DESELECT_ORDER_ITEM,
        payload: { index: existsItemIndex },
      })
    } else {
      dispatch({
        type: ActionTypes.SELECT_ORDER_ITEM,
        payload: { item },
      })
    }
  }
}

/**
 * 清除選擇的 batch item
 * @returns {ThunkFunction}
 */
export function resetSelectedOrderItems () {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_SELECTED_ORDER_ITEMS,
    })
  }
}

/**
 * 套用篩選
 * @returns {ThunkFunction}
 */
export function applyFilter (filter) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter },
    })
  }
}

/**
 * 清除清除篩選
 * @returns {ThunkFunction}
 */
export function resetFilter () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter: initialFilter },
    })
  }
}

/**
 * 更改搜尋字串
 * @returns {ThunkFunction}
 */
export function updateQuery (query) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_QUERY,
      payload: { query },
    })
  }
}

/**
 * 取消選擇的餐點
 * @returns {ThunkFunction}
 */
export function cancelSelectedOrderItem () {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const selectedItems = getState().orderHistory.selectedOrderItems
    const itemIds = selectedItems.map(item => item.id)

    const promises = itemIds.map(itemId => {
      return dimorderApi.order.cancelOrderItem(selectedOrderId, itemId)
    })
    await Promise.all(promises)
    const newOrders = await dimorderApi.order.getOrders({
      id: selectedOrderId,
    })
    if (newOrders?.[0]) {
      dispatch(updateOrder(newOrders[0]))
    }
  }
}

/**
 * 取消選擇的訂單
 * @param {string} orderId
 * @param {string} reason
 * @returns {ThunkFunction}
 */
export function cancelSelectedOrder (orderId, reason) {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('backdrop', true))
    try {
      const order = await dimorderApi.order.cancelOrder(orderId, reason)
      dispatch(updateOrder(order))
    } catch (error) {
      handleAxiosError(error, {
        loggerPrefix: '[cancelSelectedOrder] cancelOrder',
        loggerExtraData: { orderId, reason },
        responseErrorHandler: (errorMessage) => {
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.page.order_tracking.cancel_failed_alert.title'),
            message: i18n.t('app.page.order_tracking.cancel_failed_alert.message'),
          }))
        },
      })
    }
    dispatch(actions.app.updateLoading('backdrop', false))
  }
}
