import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export default function PickupCode (props) {
  const classes = useStyles(props)
  const { code } = props

  return (
    <div className={classes.container}>
      {[...code].map((char, i) => {
        return (
          <div
            key={i}
            className={classes.block}
          >
            <Typography variant='h4'>
              {char}
            </Typography>
          </div>
        )
      },
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 1),
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1),
    },
    '& :last-child': {
      marginRight: 0,
    },
  },
  block: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(1),
  },
}))
