import { AppsFlyer } from 'appsflyer-capacitor-plugin'
import { Capacitor } from '@capacitor/core'
import { Facebook } from '@ionic-native/facebook'
import { useCallback } from 'react'
import GA4React from 'ga-4-react'
import ReactPixel from 'react-facebook-pixel'

import constant from '@/constants'
import logger from '@/libs/logger'

const isDebug = process.env.NODE_ENV === 'development'

ReactPixel.init(constant.track.fbPixelCode, {}, { debug: isDebug })
const gaInstance = new GA4React(constant.track.gaCode, { debug_mode: isDebug })

export default function useGaEvent () {
  const sendEvent = useCallback(async (eventName, param) => {
    const defaultParam = {
      isDebug,
      custom_platform: Capacitor.getPlatform(),
    }

    const {
      ga,
      fbWeb,
      fbAndroid,
      fbIos,
    } = constant.track.formatEvent(eventName, param, defaultParam)

    // if (process.env.NODE_ENV === 'development') {
    //   console.log({ eventName, param, defaultParam })
    //   console.table({
    //     ga,
    //     fbWeb,
    //     fbAndroid,
    //     fbIos,
    //   })
    // }

    switch (Capacitor.getPlatform()) {
      case 'web':

        // facebook
        ReactPixel.track(fbWeb.eventName, fbWeb.param)

        // google
        try {
          gaInstance.gtag('event', ga.eventName, ga.param)
        } catch (error) {
          console.log(error)
          logger.warn('block ga track')
        }

        break
      case 'ios':
      case 'android':

        // facebook
        try {
          if (Capacitor.getPlatform() === 'ios') {
            await Facebook.setAdvertiserTrackingEnabled(true) // todo ask user
            await Facebook.setAutoLogAppEventsEnabled(true)

            await Facebook.logEvent(fbIos.eventName, fbIos.param)
          } else if (Capacitor.getPlatform() === 'android') {
            await Facebook.logEvent(fbAndroid.eventName, fbAndroid.param)
          }
        } catch (error) {
          console.log(error)
          logger.warn('fb sdk error')
        }

        // google
        try {
          gaInstance.gtag('event', ga.eventName, ga.param)
        } catch (error) {
          console.log(error)
          logger.warn('block ga track')
        }

        // AppsFlyer
        try {
          const res = await AppsFlyer.logEvent({
            eventName: ga.eventName,
            eventValue: ga.param,
          })

          console.log('AppsFlyer')
          console.log(res)
        } catch (error) {
          console.log(error)
          logger.warn('AppsFlyer sdk error')
        }

        break
    }
  }, [])

  const testEvent = useCallback(async () => {
    console.log('testEvent()')
    await sendEvent(constant.track.EVENT.TEST_ADDED_TO_CART)
    console.log('testEvent')
  }, [])

  const testLogin = useCallback(async () => {
    console.log('testLogin()')
    await sendEvent('testLogin start')
    const response = await Facebook.login([])
    await sendEvent('testLogin end')
    console.log('fb login response', response)
  }, [])

  const testLogout = useCallback(async () => {
    console.log('testLogout()')
    await sendEvent('testLogout start')
    const response = await Facebook.logout()
    await sendEvent('testLogout end')
    console.log('fb logout response', response)
  }, [])

  return { testLogin, testLogout, sendEvent, testEvent }
}

export {
  gaInstance,
}
