import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import RowBase from './RowBase'

SubtotalRow.propTypes = {
  value: PropTypes.number,
}

/**
 *
 * @param {{
 * value: number
 * }} props
 * @returns
 */
export default function SubtotalRow (props) {
  const { value } = props
  const { t } = useTranslation()

  return (
    <RowBase field={t('app.component.card.payment_summary_card.subtotal')} value={value} />
  )
}
