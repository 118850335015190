import { Drawer, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import {
  DatetimePickerContextProvider,
  useAutoSelectDatetime,
  useDateRangeContext,
  useResetDatetime,
  useSyncDeliveryType,
  useTempDatetimeContext,
  useTempDeliveryTypeContext,
  useTimeRangeContext,
} from '@/hooks/contexts/DatetimePickerContext'
import { actions, useDispatch, useSelector } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import appConstants from '@/constants/app'
import constants from '@/constants'

import DeliveryTypeTabs from '@/components/Page/PageTabs/DeliveryTypeTabs'
import DrawerContent from '@/components/Drawers/common/DrawerContent'
import SubmitButton from '@/components/Drawers/common/SubmitButton'

import DateRangeList from './DateRangeList'
import TimeRangeList from './TimeRangeList'

const { TAKEAWAY, STORE_DELIVERY } = constants.deliveryType
const deliveryTypes = [TAKEAWAY, STORE_DELIVERY]

export default function HOC () {
  return (
    <DatetimePickerContextProvider forAll>
      <ScheduleDatetimeDrawer />
    </DatetimePickerContextProvider>
  )
}

function ScheduleDatetimeDrawer (props) {
  const classes = useStyles(props)
  const open = useSelector(state => state.app.drawers.scheduledDatetime.open)
  const { setTempDatetime } = useTempDatetimeContext()
  const { setTempDeliveryType } = useTempDeliveryTypeContext()
  const { onClose, onSubmit, isSubmitDisabled } = useDrawerActions()

  useSyncDeliveryType(open, deliveryTypes)
  useResetDatetime(open)
  useAutoSelectDatetime()

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='bottom'
      PaperProps={{ className: classes.drawerPaper }}
    >
      <DeliveryTypeTabs
        deliveryTypes={deliveryTypes}
        setTempDeliveryType={setTempDeliveryType}
        setTempDatetime={setTempDatetime}
      />
      <DrawerContent className={classes.drawerContent}>
        <Content />
        <SubmitButton disabled={isSubmitDisabled} onClick={onSubmit} />
      </DrawerContent>
    </Drawer>
  )
}

function Content () {
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  switch (tempDeliveryType) {
    case TAKEAWAY:
    case STORE_DELIVERY:
      return <DatetimeList />
    default:
      return null
  }
}

export function DatetimeList (props) {
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  const { t } = useTranslation()
  const classes = useStyles(props)
  return (
    <>
      <DateRangeList />
      <Typography variant='body2' className={classes.hint}>
        {t(`app.component.drawer.${tempDeliveryType}.datetime.helper`)}
      </Typography>
      <TimeRangeList />
    </>
  )
}

function useDrawerActions () {
  const dispatch = useDispatch()
  const { deliveryType } = useDeliveryType()
  const { tempDatetime } = useTempDatetimeContext()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  const { dateRange, isLoading: isDateRangeLoading } = useDateRangeContext()
  const { timeRange, isLoading: isTimeRangeLoading } = useTimeRangeContext()

  const isSubmitDisabled = (
    _.isEmpty(dateRange) ||
    _.isEmpty(timeRange) ||
    isDateRangeLoading ||
    isTimeRangeLoading
  )

  const closeDrawer = () => {
    dispatch(actions.app.toggleDrawer('scheduledDatetime', false))
  }

  const onClose = () => {
    closeDrawer()
  }

  const onSubmit = () => {
    if (deliveryType !== tempDeliveryType) {
      dispatch(actions.app.updateDeliveryType(tempDeliveryType)) // 更新deliveryType
    }
    dispatch(actions.app.updateDatetime(tempDatetime)) // 更新datetime
    closeDrawer()
  }

  return {
    onClose,
    onSubmit,
    isSubmitDisabled,
  }
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    background: 'rgba(0, 0, 0, 0)',
    maxHeight: 'calc(100% - 64px)',
    overflow: 'hidden',
    padding: 0,
  },
  drawerContent: {
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(2),
    paddingBottom: `calc(${theme.spacing(2)}px + ${appConstants.safeArea.bottom})`,
    background: theme.palette.grey[50],
  },
  hint: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))
