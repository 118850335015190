import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { getCardProviderKey } from '@/libs/paymentMethod'
import { useDeliveryType } from '@/hooks/app'
import Card from '@/components/Cards/Card'
import Merchant from '@/constants/merchant'
import PaymentGateway from '@/constants/paymentGateway'
import PaymentMethodCard from '@/components/Cards/PaymentMethodCard'
import PaymentMethods from '@/constants/paymentMethod'
import constants from '@/constants'
import useTrack from '@/hooks/useTrack'

/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppOrder} IAppOrder */

const {
  RECENT,
  BYPASS,
  CREDIT_CARD,
  APPLE_PAY,
  GOOGLE_PAY,
  WECHAT_PAY,
  ALI_PAY,
  PAY_ME,
  FPS,
  OCTOPUS,
} = PaymentMethods

/**
 *
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function PaymentCard (props) {
  const { order } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { selectedPaymentMethod, token2c2p } = useSelector(state => state.payment)
  const creditCardGateway = useSelector(state => state.app.paymentGateway.creditcard)
  const merchant = useSelector(state => state.merchant?.data)
  const merchantId = useSelector(state => state.merchant?.data?.id)
  const payFirst = useSelector(state => state.merchant?.data?.setting?.payFirst)
  const isRestaurantInitLoading = useSelector(state => state.app.loadings.restaurantInit)
  const { isTable } = useDeliveryType()
  const openDrawer = () => { dispatch(actions.app.toggleDrawer('paymentMethod', true)) }
  const { sendEvent } = useTrack()

  useEffect(() => {
    if (selectedPaymentMethod) {
      sendEvent(constants.track.EVENT.ADD_PAYMENT_INFO, { selectedPaymentMethod })
    }
  }, [selectedPaymentMethod])

  const renderSelectedPaymentMethodCard = React.useCallback((selectedPaymentMethod) => {
    switch (selectedPaymentMethod) {
      case RECENT:
        if (creditCardGateway === PaymentGateway.PAYMENT_GATEWAY_2C2P) {
          return (
            <PaymentMethodCard
              paymentMethod={selectedPaymentMethod}
              cardProvider={getCardProviderKey(token2c2p.cardType)}
              displayText={`**** ${token2c2p.last4}`}
              onClick={openDrawer}
            />
          )
        }
        return null
      case CREDIT_CARD:
        if (creditCardGateway === PaymentGateway.PAYMENT_GATEWAY_FISERV) {
          // 只需顯示支付方式，不用特別處理的方法
          return (
            <PaymentMethodCard
              paymentMethod={selectedPaymentMethod}
              titleText={t('app.constant.paymentMethod.title')}
              onClick={openDrawer}
            />
          )
        }
        if (creditCardGateway === PaymentGateway.PAYMENT_GATEWAY_2C2P) {
          return (
            <PaymentMethodCard
              paymentMethod={selectedPaymentMethod}
              cardProvider={getCardProviderKey(token2c2p.cardType)}
              displayText={`**** ${token2c2p.last4}`}
              onClick={openDrawer}
            />
          )
        }
        return null
      case BYPASS:
      case APPLE_PAY:
      case GOOGLE_PAY:
      case PAY_ME:
      case WECHAT_PAY:
      case FPS:
      case ALI_PAY:
      case OCTOPUS:
        // 只需顯示支付方式，不用特別處理的方法
        return (
          <PaymentMethodCard
            paymentMethod={selectedPaymentMethod}
            titleText={t('app.constant.paymentMethod.title')}
            onClick={openDrawer}
          />
        )
      default:
        if (isTable && [Merchant.SOUL_CAFE, Merchant.明輝茶餐廳].includes(merchantId) && payFirst) {
          // TODO: remove this, 這家餐廳 payFirst 允許內用沒選 paymentMethod = 店內付款
          return (
            <PaymentMethodCard
              displayText={t('app.constant.paymentMethod.payInStore')}
              titleText={t('app.constant.paymentMethod.title')}
              onClick={openDrawer}
            />
          )
        }
        return (
          <Card className={classes.notSelectedCard} onClick={openDrawer}>
            <AddIcon />
            <Typography className={classes.text}>
              {t('app.page.setting_payment.add_payment_method')}
            </Typography>
          </Card>
        )
    }
  }, [openDrawer])

  if (!order) return null // 避免從 /pay refresh，一開始沒有 order 而 crash
  if (isRestaurantInitLoading) return null // restaurantInit 還沒完成，會沒有 token2c2p 先不 render

  if (isTable && merchant?.setting?.enableDineInCustomerPay) {
    const total = order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal ?? 0
    const minCharge = merchant?.setting?.dineInCreditCardPaymentMinCharge
    const meetMinCharge = total >= minCharge
    if (!meetMinCharge) {
      return (
        <Typography className={classes.warningText}>
          {t('app.page.pay.does_not_meet_min_charge', { amount: minCharge })}
        </Typography>
      )
    }
  }

  return renderSelectedPaymentMethodCard(selectedPaymentMethod)
}

const useStyles = makeStyles(theme => ({
  notSelectedCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    minHeight: 40,
  },
  warningText: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))
