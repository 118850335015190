import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

export default function Next (props) {
  const { disabled, onClick, isLoading } = props

  const classes = useStyles(props)

  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={classes.circle}
      style={{ backgroundColor: disabled ? '#E0E0E0' : '#FFC42B' }}
    >
      {
        isLoading
          ? <CircularProgress size={24} />
          : <ArrowForwardIcon style={{ color: '#fff' }} />
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  circle: {
    marginTop: 30,
    height: 50,
    width: 50,
    borderRadius: 25,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',
    right: 50,
  },
}))
