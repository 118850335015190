import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import TabBase from './TabBase'

Tabs.propTypes = {
  list: PropTypes.array,
  selected: PropTypes.number,
  setSelected: PropTypes.func,
}

/**
 *
 * @param {{
 * list: String[]
 * selected: number
 * setSelected: Function
 * }} props
 */
export default function Tabs (props) {
  const { list, selected, setSelected, className } = props
  const classes = useStyles(props)

  return (
    <ul className={clsx(classes.tabs, className)}>
      {_.map(list, (tab, index) => {
        const isSelected = selected === index
        const handleClick = () => setSelected(index)
        return (
          <li key={index}>
            <TabBase title={tab} selected={isSelected} onClick={handleClick} />
          </li>
        )
      })}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  tabs: {
    display: 'flex',
    padding: theme.spacing(1, 0),
  },
}))
