import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

DeliveryOptionItemBase.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

/**
 *
 * @param {{
 * icon: Node
 * title: String
 * onClick: Function
 * selected: Boolean
 * }} props
 * @returns
 */
export default function DeliveryOptionItemBase (props) {
  const { icon, title, selected, onClick } = props
  const classes = useStyles(props)

  return (
    <ButtonBase
      className={clsx(classes.deliveryOptionItemBase, { [classes.selected]: selected })}
      onClick={onClick}
    >
      <div className={classes.iconWrap}>
        {icon}
      </div>
      <Typography variant='body2'>
        {title}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  deliveryOptionItemBase: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridGap: theme.spacing(0.5),
    padding: theme.spacing(1),
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
  },
  selected: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
  },
  iconWrap: {
    display: 'grid',
    placeItems: 'center',
  },
}))
