import { ButtonBase, Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

import NoticeCardIcon from './NoticeCardIcon'
import theme from '@/theme'

// 根據 CA-1196 行銷要求
// info 改用 primary 色，warning 改用 secondary 色
// 所以這邊不直接用 theme.palette 取色，另外做 variant 和 color 的對照表
const colors = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  info: theme.palette.primary.main,
  warning: theme.palette.secondary.main,
  error: theme.palette.error.main,
}

/**
 * @typedef {Object} ICustomClasses
 * @property {string} card
 * @property {string} icon
 * @property {string} message
 */

/**
 *
 * @param {{
 * variant: 'error' | 'warning' | 'info'
 * message: string
 * onClick: function
 * customClasses: ICustomClasses
 * }} props
 * @returns
 */
export default function NoticeCard (props) {
  const { variant, message, onClick, customClasses } = props
  const { i18n, t } = useTranslation()
  const classes = useStyles(props)

  return (
    <ButtonBase
      className={clsx(classes.card, customClasses?.card)}
      onClick={onClick}
      disableRipple={!onClick}
    >
      <NoticeCardIcon
        variant={variant}
        className={clsx(classes.icon, customClasses?.icon)}
      />
      <Typography
        variant='body2'
        className={clsx(classes.message, customClasses?.message)}
      >
        {i18n.exists(message) ? t(message) : message}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    flexShrink: 0,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(1),
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: props => alpha(colors[props.variant], 0.5),
    backgroundColor: props => alpha(colors[props.variant], 0.25),
    borderRadius: 10,
    backdropFilter: 'blur(8px)',
    padding: theme.spacing(1, 2),
    '&:hover': {
      cursor: props => props.onClick ? 'pointer' : 'default',
    },
  },
  icon: {
    color: props => colors[props.variant],
  },
  message: {
    textAlign: 'start',
    whiteSpace: 'pre-line',
  },
}))
