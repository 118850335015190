import { actions } from '@/redux'
import DeliveryTypes from '@/constants/deliveryType'
import dimorderApi from '@/libs/api/dimorder'

import ActionTypes from './ActionTypes'

const { TABLE } = DeliveryTypes

/**
 * @param {string} merchantId
 * @returns {ThunkFunction}
 */
export function fetchMerchant (merchantId) {
  return async (dispatch, getState) => {
    const results = await Promise.all([
      dimorderApi.merchant.getMerchant(merchantId),
      dispatch(actions.landing.fetchRestaurant(merchantId)), // 同時也更新 landing restaurant
    ])

    dispatch({
      type: ActionTypes.UPDATE_MERCHANT,
      payload: { merchant: results[0] },
    })
  }
}

export function handleD2CSettingChanged () {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('d2cRouterSettingUpdate', true))

    const merchant = getState().merchant.data

    try {
      // * preorder drawer
      // 重新抓一次 restaurants，判斷 openPreorderDrawer 才會正確
      await dispatch(actions.landing.fetchRestaurant(merchant.id))

      // * shipping
      await dispatch(actions.order.createShipping()) // address 改變，重新 createShipping
    } catch (error) {

    }

    dispatch(actions.app.updateLoading('d2cRouterSettingUpdate', false))
  }
}

/**
 *
 * @param {string} merchantId
 * @param {boolean} isMaster // 只拿主機的資料
 * @returns {ThunkFunction}
 */
export function fetchMerchantDevices (merchantId, isMaster) {
  return async (dispatch, getState) => {
    const deliveryType = getState().app.params.deliveryType

    if (deliveryType !== TABLE) {
      // 僅有內用時要檢查餐廳的裝置是否處於離線模式
      return
    }

    const devices = await dimorderApi.device.getMerchantDevices(merchantId, isMaster)
    return devices
  }
}

/**
 * 1. 抓餐廳的裝置
 * 2. 找出已失去連線的裝置
 * 3. 回傳是否有找到已失去連線的裝置
 * @param {string} merchantId
 * @param {boolean} isMaster // 只拿主機的資料
 * @returns {ThunkFunction}
 */
export function findOfflineDevice (merchantId, isMaster) {
  return async (dispatch, getState) => {
    return false
    // const devices = await dispatch(fetchMerchantDevices(merchantId, isMaster))
    // const offlineDevices = _.filter(devices, device => {
    //   const offline = device?.lastHealthTime
    //     ? moment(device?.lastHealthTime).add(1, 'minute').isBefore(moment())
    //     : true
    //   return offline
    // })
    // return _.size(offlineDevices) > 0
  }
}

/**
 * @returns {ThunkFunction}
 */
export function reset () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_MERCHANT,
      payload: {},
    })
  }
}
