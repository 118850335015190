import { makeStyles } from '@material-ui/core/styles'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import PropTypes from 'prop-types'
import React from 'react'
import SwipeableDrawer from '@/components/SwipeableDrawer'
import constants from '@/constants'

PageDrawer.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  openDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  children: PropTypes.any,
}

/**
 *
 * @param {{
 * children?: Any
 * className?: String
 * }} props
 */
export default function PageDrawer (props) {
  const { title, open, openDrawer, closeDrawer, children } = props
  const classes = useStyles(props)

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='right'
    >
      <Page>
        <Navbar title={title} onBack={closeDrawer} />
        <PageContainer
          className={classes.container}
        >
          {children}
        </PageContainer>
      </Page>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '100%',
    padding: 0,
  },
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    padding: theme.spacing(2),
  },
}))
