import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useSelector } from '@/redux'
import useShortLang from '@/hooks/useShortLang'

import LoginNotificationRow from './row/LoginNotificationRow'
import PromoCodeRow from './row/PromoCodeRow'
import RowBase from './row/RowBase'

// eslint-disable-next-line no-unused-vars
import { IOrderModifier } from 'dimorder-orderapp-lib/dist/types/Order'

/**
 *
 * @param {{
 * modifiers: IOrderModifier[]
 * readOnly: boolean
 * }} props
 * @returns
 */
export default function ModifierList (props) {
  const { modifiers, readOnly = false } = props
  const { t } = useTranslation()
  const lang = useShortLang()
  const selectedOrder = useSelector(state => state.order.selectedOrder)
  const isPromoCodeLoading = useSelector(state => state.order.isApplyPromoCodeLoading || state.order.isAutoFillPromoCodeLoading || state.order.isDeletePromoCodeLoading)
  const promoCode = useSelector(state => state.order.promoCode)

  if (_.isEmpty(modifiers)) return null

  const filteredModifiers = modifiers.filter(modifier => modifier.calculatedAmount !== 0)

  // 還沒有優惠碼的話，先顯示 PromoCodeRow，才能輸入新的
  // isPromoCodeLoading 時暫時顯示舊的 promoCode
  const renderEditPromoCodeRow = () => {
    if (filteredModifiers.find(modifier => modifier.type === 'PROMOCODE')) {
      // 已經有 PROMOCODE modifier 不顯示
      return null
    }
    if (readOnly) {
      // 不可編輯時不顯示
      return null
    }
    if (!selectedOrder?.originalTotal || selectedOrder.originalTotal === 0) {
      // originalTotal 為 0 或未計算完成不顯示
      return null
    }

    if (isPromoCodeLoading && promoCode?.code) {
      // autoFill 正在 loading，顯示之前的 promocode 和 loading 狀態
      const name = promoCode.name?.[lang] || promoCode.name?.zh || undefined
      return (
        <PromoCodeRow
          name={name}
          code={promoCode.code}
          readOnly
          isLoading
        />
      )
    }

    if (!readOnly) {
      // 可以編輯時顯示
      return <PromoCodeRow />
    }
  }

  return (
    <>
      {_.map(filteredModifiers, (modifier, index) => {
        switch (modifier.type) {
          case 'SURCHARGE':
            return (
              <RowBase
                key={index}
                field={t('app.component.card.payment_summary_card.surcharge')}
                value={Number(modifier.calculatedAmount.toFixed(1))}
              />
            )
          case 'PROMOCODE':
            return (
              <PromoCodeRow
                key={index}
                readOnly={readOnly}
                name={modifier.name}
                code={modifier.code}
                value={Number(modifier.calculatedAmount.toFixed(1))}
                isLoading={isPromoCodeLoading}
              />
            )
          case 'MERCHANT':
          case 'DISCOUNT':
            return (
              <RowBase
                key={index}
                field={t('app.page.filter.discount.title')}
                value={Number(modifier.calculatedAmount.toFixed(1))}
              />
            )
          case 'SHIPPING_DISCOUNT':
            return (
              <RowBase
                key={index}
                field={t('app.component.card.payment_summary_card.shipping_discount')}
                value={Number(modifier.calculatedAmount.toFixed(1))}
              />
            )
          case 'RICECOIN':
            return (
              <RowBase
                key={index}
                field='RiceCoin'
                value={Number(modifier.calculatedAmount.toFixed(1))}
              />
            )
          default:
            return null
        }
      })}
      {renderEditPromoCodeRow()}
      <LoginNotificationRow />
    </>
  )
}
