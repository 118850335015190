import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'

import { useCategoryRestaurantsQueryOptions } from '@/hooks/queries/useCategoryRestaurantsQuery'
import { useMode } from '@/hooks/useWindowSize'
import { useRestaurantCardListSize } from '@/hooks/useRestaurantCardListSize'

import CategoryBanner from '@/pages/Category/CategoryBanner'
import InfiniteQueryList from '@/components/RestaurantList/InfiniteQueryList'
import RestaurantCard from '@/components/Cards/RestaurantCard'
import RestaurantCardsSkeleton from '@/components/RestaurantList/RestaurantCardsSkeleton'

export default function CategoryRestaurantList (props) {
  const { category } = props
  const classes = useStyles(props)
  const { isMobile } = useMode()
  const { rowHeight, columnWidth } = useRestaurantCardListSize()
  const queryOptions = useCategoryRestaurantsQueryOptions(category.name)

  const HeaderComponent = useCallback(() => {
    return (
      <div className={classes.listHeaderContainer}>
        <CategoryBanner category={category} />
      </div>
    )
  }, [category])

  return (
    <InfiniteQueryList
      queryOptions={queryOptions}
      rowHeight={rowHeight}
      columnWidth={columnWidth}
      ItemComponent={RestaurantCard}
      HeaderComponent={category?.bannerUrl ? HeaderComponent : undefined}
      HeaderComponentHeight={isMobile ? 200 : 300}
      FooterComponent={() => <div />} // 多顯示一行空白的 row
      LoadingComponent={RestaurantCardsSkeleton}
      LoadingIndicator={RestaurantCardsSkeleton}
    />
  )
}

const useStyles = makeStyles(theme => ({
  listHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
}))
