import { makeStyles } from '@material-ui/core/styles'
import React, { useLayoutEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useCategories, useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import SafeAreaFill from '@/components/SafeAreaFill'
import SkeletonCategoryBar from '@/components/Skeletons/SkeletonCategoryBar'
import constants from '@/constants'

import CategoryLink from './CategoryLink'

/**
 *
 * @param {{
 * scrollOffset: number
 * }} props
 */
export default function CategoryBar (props) {
  const { scrollOffset } = props
  const { isLoading } = useMenuQuery()
  const { data: categories } = useCategories()
  const dispatch = useDispatch()
  const [selectedRoot, setSelectedRoot] = useState({})
  const sticky = useSelector(state => state.app.isCategoryBarSticky)
  const [isLeftEnd, setIsLeftEnd] = useState(true)
  const [isRightEnd, setIsRightEnd] = useState(false)
  const classes = useStyles({ ...props, sticky, isLeftEnd, isRightEnd })
  const safeAreaHeight = useSelector(state => state.app.safeAreaHeight)

  // console.log({ cata })

  // ios position sticky malfunction problem
  useLayoutEffect(() => {
    const stickyInterval = setInterval(() => {
      const categoryListWrap = document.querySelector('#category-list-wrap')

      // 判斷 categoryListWrap 超出多少後要變成 sticky
      if (categoryListWrap.getBoundingClientRect().top < constants.app.height.CATEGORYBAR + constants.app.height.NAVBAR + safeAreaHeight) {
        dispatch(actions.app.updateCategoryBarSticky(true))
      } else {
        dispatch(actions.app.updateCategoryBarSticky(false))
      }
    }, 1000 / 60)

    return () => {
      clearInterval(stickyInterval)
    }
  }, [])

  const handleCatbarOnScroll = (e) => {
    const tar = e.target
    const isLeftEnd = tar.scrollLeft <= 0
    const isRightEnd = tar.scrollLeft + tar.offsetWidth >= tar.scrollWidth
    setIsLeftEnd(isLeftEnd)
    setIsRightEnd(isRightEnd)
  }

  return (
    <nav className={classes.nav}>
      <SafeAreaFill hidden={!sticky} />
      <ul
        id='linkList'
        className={classes.categoryBar}
        onScroll={(e) => handleCatbarOnScroll(e)}
      >
        <SkeletonCategoryBar disabled={!isLoading} />
        {_.map(categories, (category, index) => {
          if (isLoading) return null
          return (
            <CategoryLink
              key={index}
              item={category}
              selectedRoot={selectedRoot}
              setSelectedRoot={setSelectedRoot}
              scrollOffset={scrollOffset}
            />
          )
        })}
      </ul>
    </nav>
  )
}

const useStyles = makeStyles(theme => ({
  nav: {
    flexShrink: 0,
    position: props => props.sticky ? 'absolute' : 'relative',
    top: props => props.sticky ? constants.app.height.NAVBAR : 0,
    left: 0,
    borderRadius: props => props.sticky ? 0 : theme.shape.borderRadius * 2,
    margin: props => props.sticky ? 0 : theme.spacing(1, 1.5),
    padding: props => props.sticky ? theme.spacing(0, 2.5) : theme.spacing(0, 1),
    width: props => props.sticky ? '100%' : 'unset',
    zIndex: constants.app.zIndex.NAVBAR - 100,
    height: props => props.sticky
      ? `calc(${constants.app.height.CATEGORYBAR}px + ${constants.app.safeArea.top})`
      : constants.app.height.CATEGORYBAR,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[25],
    transition: 'all 300ms ease',
    transitionProperty: 'border-radius, margin, padding',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -24,
      height: '100%',
      width: 24,
      boxShadow: `24px 0 24px 4px ${theme.palette.common.white}`,
      opacity: props => props.isLeftEnd ? 0 : 0.9,
      transition: 'opacity 500ms ease',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: -24,
      height: '100%',
      width: 24,
      boxShadow: `-24px 0 24px 4px ${theme.palette.common.white}`,
      opacity: props => props.isRightEnd ? 0 : 0.9,
      transition: 'opacity 500ms ease',
    },
  },
  categoryBar: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: constants.app.height.CATEGORYBAR,
  },
}))
