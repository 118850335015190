import React from 'react'

import { useGroupRewardsQueryOptions } from '@/hooks/queries/CRM/useGroupRewardsQuery'

import { CARD, RewardCard } from './index'
import InfiniteQueryList, { DEFAULT_ROW_PADDING_TOP } from '@/components/RestaurantList/InfiniteQueryList'
import ListEmptyComponent from '../components/ListEmptyComponent'

export default function RewardsList (props) {
  const queryOptions = useGroupRewardsQueryOptions()
  const rowHeight = CARD.height + DEFAULT_ROW_PADDING_TOP
  return (
    <InfiniteQueryList
      queryOptions={queryOptions}
      rowHeight={rowHeight}
      ItemComponent={RewardItem}
      FooterComponent={() => <div />} // 多顯示一行空白的 row
      EmptyComponent={ListEmptyComponent}
    />
  )
}

function RewardItem (props) {
  const { style = {}, ...rest } = props
  return <RewardCard style={{ ...style, width: '100%' }} {...rest} />
}
