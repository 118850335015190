import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { useDineinCheckoutHandler } from '@/pages/Menu/DineinButtons/DineinButton/DineinButtonCheckout'
import { useSelector } from '@/redux'
import hooks from '@/hooks'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DimpayBanner (props) {
  const classes = useStyles(props)
  const table = hooks.app.useTable()
  const merchant = useSelector(state => state.merchant?.data)
  const orderBatches = useSelector(state => state.order?.selectedOrder?.batches)
  const orderItems = useSelector(state => state.order?.selectedOrder?.batches?.map(v => v.items).flat(1) ?? [])
  const handleDineinCheckout = useDineinCheckoutHandler()

  if (!merchant?.id) return null
  if (merchant?.setting?.payFirst) return null
  if (merchant?.setting?.dineinHidePayButton) return null
  if (!merchant?.setting?.enableDineInCustomerPay) return null
  if ((!table || _.isEmpty(orderBatches)) || orderItems.some(v => v.priceUndetermined)) return null

  return (
    <div className={classes.banner}>
      <video
        className={classes.video}
        autoPlay
        loop
        muted
        playsInline
        controls={false}
        src='https://s3.ap-east-1.amazonaws.com/dimorder-static/public/banner/DimPayBanner_b0rpwk.mp4'
        onClick={handleDineinCheckout}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  banner: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0.5, 1.5),
  },
  video: {
    flex: 1,
    flexShrink: 0,
    width: '100%',
    borderRadius: theme.spacing(1),
  },
}))
