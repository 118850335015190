import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'

export const Platforms = {
  WEB: 'web',
  MOBILE_WEB: 'mobile_web',
  APP: 'app',
}

export const isWebPlatform = () => {
  return Capacitor.getPlatform() === 'web'
}

export const isMobileWebPlatform = async () => {
  const os = ['ios', 'android']
  const { operatingSystem } = await Device.getInfo()

  return isWebPlatform() && os.includes(operatingSystem)
}

export const getPlatform = async () => {
  const isMobileWeb = await isMobileWebPlatform()

  if (isMobileWeb) {
    return Platforms.MOBILE_WEB
  }

  if (isWebPlatform()) {
    return Platforms.WEB
  }

  return Platforms.APP
}
