import { ListItem, ListItemIcon, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

AddressItemBase.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rightAction: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  titleColor: PropTypes.string,
}

/**
 *
 * @param {{
 * icon: Node
 * title: string
 * subtitle: string
 * rightAction?: Node
 * selected?: boolean
 * onClick?: function
 * className?: string
 * titleColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error'
 * }} props
 * @returns
 */
export default function AddressItemBase (props) {
  const { icon, title, subtitle, rightAction, selected, onClick, className, titleColor } = props
  const classes = useStyles(props)

  return (
    <ListItem className={clsx(classes.addressItem, { [classes.selected]: selected }, className)} onClick={onClick}>
      <ListItemIcon classes={{ root: classes['ListItemIcon-root'] }}>
        {icon}
      </ListItemIcon>
      <div className={classes.addressWrap}>
        <Typography color={titleColor ?? 'initial'} variant='body2'>
          {title}
        </Typography>
        <Typography variant='caption' className={classes.address}>
          {subtitle}
        </Typography>
      </div>
      <ListItemSecondaryAction>
        {rightAction}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  addressItem: {
    borderRadius: theme.shape.borderRadius * 2,
    border: `2px solid ${theme.palette.common.white}`,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[25],
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.grey[200],
  },
  addressWrap: {
    margin: theme.spacing(0, 1),
  },
  address: {
    color: theme.palette.grey[600],
  },
  'ListItemIcon-root': {
    minWidth: 'unset',
  },
}))
