import { VariableSizeList } from 'react-window'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { useLandingCuisinesQuery } from '@/hooks/queries/useLandingCuisinesQuery'
import { useWindowSize } from '@/hooks/useWindowSize'

import CategoryHeader from '@/components/RestaurantList/CategoryHeader'
import CuisineCard, { CUISINE_CARD } from './CuisineCard'

/**
 * 菜式列表
 * 1. 取得 landing 設定的菜式
 * 2. 篩掉餐廳數量為 0 的菜式
 * 3. 於橫向列表內顯示菜式卡
 * 4. 沒有任何菜式時不顯示
 * @param {*} props
 * @returns
 */
export default function CuisineList (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const { innerWidth } = useWindowSize()
  const { data: cuisines, isLoading } = useLandingCuisinesQuery()
  const data = isLoading ? _.times(8) : cuisines

  const Column = ({ index, style }) => {
    const contentSpacing = { marginLeft: CUISINE_CARD.gutter }
    return (
      <div style={style}>
        <CuisineCard cuisine={data[index]} loading={isLoading} style={contentSpacing} />
      </div>
    )
  }

  if (!isLoading && _.isEmpty(cuisines)) return null

  return (
    <section className={classes.section}>
      <CategoryHeader title={t('app.common.cuisines')} loading={isLoading} />
      <VariableSizeList
        width={innerWidth}
        height={CUISINE_CARD.height}
        itemCount={data.length}
        itemSize={index => CUISINE_CARD.width + CUISINE_CARD.gutter * (index === data.length - 1 ? 2 : 1)}
        layout='horizontal'
      >
        {Column}
      </VariableSizeList>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    paddingTop: CUISINE_CARD.gutter,
    paddingBottom: CUISINE_CARD.gutter,
  },
  list: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    overflow: 'scroll',
  },
}))
