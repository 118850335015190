import { ButtonBase, InputBase } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import SearchIcon from '@/assets/icons/search.svg'
import constants from '@/constants'
import useTrack from '@/hooks/useTrack'

/**
 * @typedef SearchInputProps
 * @property {() => void} [onClick] // 在 Appbar 內時會使用到
 * @property {boolean} [disableClick = false] 在 Appbar 內時會使用到
 * @param {SearchInputProps} props
 * @returns
 */
export default function SearchInput (props) {
  const { onClick, disabledClick = false } = props
  const { t } = useTranslation()
  const { sendEvent } = useTrack()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const searchText = useSelector(state => state.landing.searchText)
  const [value, setValue] = useState(searchText)

  // memoize a return value from our debounce function
  const debouncedSetSearchText = useMemo(() => {
    return _.debounce((value) => {
      dispatch(actions.landing.updateSearchText(value))
    }, 300)
  }, [])

  // clean up any side effects from debounce when our component gets unmounted
  useEffect(() => {
    return () => {
      debouncedSetSearchText.cancel()
    }
  }, [])

  useEffect(() => {
    // searchText 可能透過別的頁面改變，改變後應與 value 同步
    setValue(searchText)
    // 當 searchText 改變且不為空字串時，send event
    if (searchText) {
      sendEvent(constants.track.EVENT.SEARCH, { value: searchText })
    }
  }, [searchText])

  function handleClear () {
    setValue('')
    dispatch(actions.landing.resetSearchInput())
  }

  function handleChange (e) {
    setValue(e.target.value)
    debouncedSetSearchText(e.target.value)
  }

  const endAdornment = (
    <ButtonBase onClick={handleClear} className={classes.clearButton}>
      <HighlightOffIcon />
    </ButtonBase>
  )

  return (
    <div
      className={classes.container}
      onClick={onClick}
      disabled={disabledClick}
    >
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
          adornedStart: classes.inputAdornedStart,
          adornedEnd: classes.inputAdornedEnd,
        }}
        value={value}
        onChange={handleChange}
        placeholder={t('app.component.search.search_placeholder')}
        inputProps={{ 'aria-label': 'search' }}
        startAdornment={<img src={SearchIcon} alt='search_icon' style={{ paddingRight: 6 }} />}
        endAdornment={value && endAdornment}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: alpha(theme.palette.common.white, 0.9),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.8),
    },
  },
  inputRoot: {
    flex: 1,
    color: 'unset',
  },
  inputInput: {
    color: theme.palette.grey[800],
    fontSize: '1.3rem',
  },
  inputAdornedStart: {
    margin: theme.spacing(0.25, 1),
    color: theme.palette.grey[500],
  },
  inputAdornedEnd: {
    margin: theme.spacing(0.25, 1),
    color: theme.palette.grey[500],
  },
  clearButton: {
    borderRadius: '50%',
  },
}))
