import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { actions, useDispatch } from '@/redux'
import constants from '@/constants'
import opacity from '@/theme/opacity'

import ContainerIcon from '@/assets/icons/Container'
import ScanIcon from '@/assets/icons/Scan'
import SmileIcon from '@/assets/icons/Smile'
import UserIcon from '@/assets/icons/User'

/**
 *
 * @param {{
 * list?: []
 * hidden?: boolean
 * }} props
 * @returns
 */
export default function BottomNavigation (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const defaultNavs = [
    {
      name: t('app.component.footer.navigation.restaurant'),
      icon: <SmileIcon fontSize='small' />,
      path: '/restaurants',
      onClick: () => { },
    },
    {
      name: t('app.component.footer.navigation.orders'),
      icon: <ContainerIcon fontSize='small' />,
      path: '/orders',
      onClick: () => { },
    },
    {
      name: t('app.component.footer.navigation.account'),
      icon: <UserIcon fontSize='small' />,
      path: '/member',
      onClick: () => { },
    },
  ]
  const { list = defaultNavs, hidden } = props
  const selected = _.findIndex(list, (nav) => nav.path === match.path)
  const classes = useStyles(props)

  if (hidden) return null

  return (
    <footer className={classes.bottomNavigation}>
      <ul className={classes.naviationList}>
        {_.map(list, (nav, index) => {
          const isSelected = selected === index
          return (
            <li key={index}>
              <div
                className={clsx(classes.navigationItem, { [classes.selected]: isSelected })}
                onClick={() => {
                  history.push(nav.path)
                  nav.onClick()
                }}
              >
                {nav.icon}
                <span className={classes.navName}>{nav.name}</span>
              </div>
            </li>
          )
        })}
      </ul>
      <ButtonBase className={classes.qrButton} onClick={() => dispatch(actions.app.openQRCodeScanner())}>
        <div className={classes.qrWrap}>
          <ScanIcon className={classes.qrIcon} />
          <span
            className={classes.navName}
            style={{ transform: 'translateY(-4px)' }}
          >{t('app.component.footer.navigation.qr_code')}
          </span>
        </div>
      </ButtonBase>
    </footer>
  )
}

const useStyles = makeStyles(theme => ({
  bottomNavigation: {
    zIndex: constants.app.zIndex.FOOTER,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: constants.app.marginBottom.FOOTER,
    paddingBottom: constants.app.safeArea.bottom,
    display: 'flex',
    backgroundColor: theme.palette.grey[50] + opacity[70],
    boxShadow: theme.shadows[25],
    '&:before': {
      zIndex: -1,
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(24px)',
    },
  },
  naviationList: {
    flex: 1,
    display: 'flex',
    alignSelf: 'stretch',
    height: '100%',
    '& li': {
      flex: 1,
    },
  },
  navigationItem: {
    position: 'relative',
    flexShrink: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    opacity: 0.4,
    '& > *': {
      zIndex: 10,
    },
  },
  navName: {
    marginTop: theme.spacing(0.5),
    fontSize: '1.2rem',
  },
  selected: {
    opacity: 1,
  },
  qrButton: {
    backgroundColor: theme.palette.primary.main,
    background: `linear-gradient(0deg, ${theme.palette.grey[50] + opacity[70]} 0%, ${theme.palette.primary.main} 35%, ${theme.palette.primary.main} 100%)`,
    borderRadius: '50%',
    boxShadow: theme.shadows[25],
    width: 120,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(16px, -32px)',
  },
  qrIcon: {
    fontSize: '3.4rem',
    marginBottom: theme.spacing(1),
  },
  qrWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(0px, -8px)',
  },
}))
