import React from 'react'

import { useGroupLedgersQueryOptions } from '@/hooks/queries/CRM/useGroupLedgersQuery'

import { CARD, LedgerCard } from './index'
import InfiniteQueryList, { DEFAULT_ROW_PADDING_TOP } from '@/components/RestaurantList/InfiniteQueryList'
import ListEmptyComponent from '../components/ListEmptyComponent'

export default function LedgerList (props) {
  const queryOptions = useGroupLedgersQueryOptions()
  const rowHeight = CARD.height + DEFAULT_ROW_PADDING_TOP
  return (
    <InfiniteQueryList
      queryOptions={queryOptions}
      rowHeight={rowHeight}
      ItemComponent={LedgerCard}
      FooterComponent={() => <div />} // 多顯示一行空白的 row
      EmptyComponent={ListEmptyComponent}
    />
  )
}
