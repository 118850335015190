import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import constants from '@/constants'
import useDisableD2CUIPrice from '@/hooks/useDisableD2CUIPrice'

import Options from './Options'
import Remarks from './Remarks'
import SetItemList from './SetItemList'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem, IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { TABLE } = constants.deliveryType

/**
 * @typedef OrderBatchItemProps
 * @property {IAppOrder} order
 * @property {IAppBatchItem} item
 * @property {() => void} onClick
 * @property {JSX.Element} [rightContent]
 * @property {{root?: string}} [customClasses]
 * @param {OrderBatchItemProps} props
 * @returns
 */
export default function OrderBatchItem (props) {
  const { order, item, onClick, rightContent, customClasses } = props
  if (_.isEmpty(item)) return null
  return (
    <OrderItem
      onClick={onClick}
      customClasses={{ root: customClasses?.root }}
      content={<Content order={order} item={item} />}
      rightContent={rightContent}
    />
  )
}

/**
 * @typedef ContentProps
 * @property {IAppOrder} order
 * @property {IAppBatchItem} item
 * @param {ContentProps} props
 * @returns
 */
function Content (props) {
  const { order, item } = props
  const { t } = useTranslation()
  const disableD2CUIPrice = useDisableD2CUIPrice()
  const excludedSurchargeSuffix = (item.excludedOrderSurcharge && order?.deliveryType === TABLE)
    ? `(${t('app.component.order_batch_item.free_surcharge')})`
    : ''

  const Price = useMemo(() => {
    if (disableD2CUIPrice) return null
    return item.cancelled
      ? t('app.constant.takeawayStatus.cancelled')
      : `HK$ ${item.total} ${excludedSurchargeSuffix}`
  }, [disableD2CUIPrice, item.cancelled, item.total, excludedSurchargeSuffix])

  return (
    <>
      <Typography style={{ fontSize: '1.4rem' }}>{item.name}</Typography>
      <Typography style={{ fontSize: '1.4rem' }} color='textSecondary'>
        {Price}
      </Typography>
      <SetItemList item={item} />
      <Options options={item.options} />
      <Remarks remarks={item.remarks} />
    </>
  )
}

/**
 *
 * @typedef OrderItemProps
 * @property {() => void} onClick
 * @property {{ root?: string }} [customClasses]
 * @property { React.ReactNode} [content]
 * @property { React.ReactNode} [rightContent]
 * @param {OrderItemProps} props
 * @returns
 */
export function OrderItem (props) {
  const classes = useStyles(props)
  const { onClick, customClasses, content, rightContent } = props
  return (
    <li className={clsx([classes.orderBatchItem, customClasses?.root])} onClick={onClick}>
      <section className={classes.section}>
        {content}
      </section>
      {rightContent}
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  orderBatchItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  section: {
    flex: 1,
  },
}))
