import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Filter (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#000' fillRule='nonzero'>
          <g>
            <path d='M14.77 7.288c-.744 0-1.347-.603-1.347-1.346 0-.744.603-1.347 1.347-1.347s1.346.604 1.346 1.347c0 .744-.603 1.347-1.347 1.346zm2.596-2.019c-.3-1.157-1.346-2.019-2.597-2.019-1.25 0-2.296.862-2.597 2.02H4v1.345h8.172c.301 1.158 1.347 2.02 2.597 2.02s2.296-.862 2.597-2.02h2.788V5.27h-2.788zm-2.597 14.135c-.48 0-.925-.257-1.166-.674-.24-.416-.24-.93 0-1.346.241-.417.686-.673 1.167-.673.744 0 1.346.603 1.346 1.347 0 .743-.603 1.346-1.347 1.346m0-4.039c-1.25 0-2.296.862-2.597 2.02H4v1.346h8.172c.301 1.157 1.347 2.019 2.597 2.019s2.296-.862 2.597-2.02h2.788v-1.345h-2.788c-.3-1.158-1.346-2.02-2.597-2.02m-5.384-2.019c-.481 0-.926-.257-1.166-.673-.24-.417-.24-.93 0-1.347.24-.416.685-.673 1.166-.673.744 0 1.347.603 1.346 1.347 0 .744-.603 1.346-1.346 1.346m0-4.038c-1.251 0-2.296.861-2.597 2.019H4v1.346h2.788c.3 1.158 1.346 2.02 2.597 2.02 1.25 0 2.295-.862 2.596-2.02h8.173v-1.346H11.98c-.3-1.158-1.346-2.02-2.596-2.02' transform='translate(-146 -2526) translate(146 2526)' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
