import { Slide, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import React from 'react'
import _ from 'lodash'

import { useCart } from '@/hooks/useCart'
import { useDeliveryType } from '@/hooks/app'
import { useSelectedOrder } from '@/hooks/order'
import { useSelector } from '@/redux'
import constants from '@/constants'

import CheckOrderHistoryButton from './CheckOrderHistoryButton'
import SubmitButton from './SubmitButton'

/**
 * @typedef FooterBaseProps
 * @property {number} quntity
 * @property {number} total
 * @property {number} originalTotal
 * @property {IFooterSubmitButtonProps} submitButtonProps
 * @property {boolean} [slide]
 * @property {boolean} [solidBackground]
 * @property {JSX.Element} [children]
 */

/**
 *
 * @param {FooterBaseProps} props
 * @returns
 */
export default function FooterBase (props) {
  const {
    quantity,
    submitButtonProps,
    slide,
    children,
  } = props
  const { pathname } = useLocation()
  const selectedOrder = useSelectedOrder()
  const { isTable } = useDeliveryType()
  const historyOrders = useSelector(state => state.orderHistory.orders)
  const historyOrder = _.find(historyOrders, historyOrder => historyOrder?.id === selectedOrder?.id)
  const hasHistoryBatches = !_.isEmpty(historyOrder?.batches)
  const { hasItemsInCart } = useCart()
  const slideIn = isTable ? (hasHistoryBatches || hasItemsInCart) : hasItemsInCart
  const classes = useStyles({ ...props, hasItemsInCart, pathname })

  const FooterAnimationContainer = React.useCallback(({ children }) => {
    if (slide) {
      return (
        <Slide direction='up' in={slideIn}>
          {children}
        </Slide>
      )
    }
    return children
  }, [slide, slideIn])

  const SubmitButtonErrorMessage = React.useCallback(() => {
    if (!submitButtonProps?.errors?.length) return null
    return (
      <Typography className={classes.errorMessage}>
        {submitButtonProps?.errors?.[0]}
      </Typography>
    )
  }, [submitButtonProps?.errors])

  return (
    <FooterAnimationContainer>
      <footer className={classes.footerBaseWrap}>
        <CheckOrderHistoryButton />
        <SubmitButtonErrorMessage />
        <div className={classes.submitButtonWrap}>
          <SubmitButton
            className={classes.submitButton}
            quantity={quantity}
            {...submitButtonProps}
          />
        </div>
        {children}
      </footer>
    </FooterAnimationContainer>
  )
}

const useStyles = makeStyles(theme => ({
  footerBaseWrap: {
    zIndex: constants.app.zIndex.FOOTER,
    position: 'fixed',
    bottom: constants.app.safeArea.bottom,
    left: 0,
    width: '100%',
    padding: theme.spacing(1, 2),
    backgroundColor: props => props.solidBackground && theme.palette.grey[50],
  },
  submitButtonWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    overflow: 'hidden',
    height: props => props.hasItemsInCart || props.pathname.includes('/pay') ? constants.app.height.FOOTER : 0,
    transition: 'height ease 300ms',
  },
  submitButton: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[25],
    transform: 'translateZ(0)', // 修正ios沒有圓角問題
  },
  errorMessage: {
    textAlign: 'center',
  },
}))
