import _ from 'lodash'
import i18n from '@/i18n'

/**
 * 拿到不重複的『我的地址n』
 * @param {IAddress[]} addressList
 * @param {number?} current 不用給，遞迴用
 * @returns {string}
 */
export default function getUniqueAddressName (addressList, current) {
  if (!current) {
    current = _.size(addressList) ? _.size(addressList) + 1 : 1
  }
  const name = i18n.t('app.page.setting_address.address.name.default', { number: current })
  const duplicateName = addressList.find(address => address.name === name)
  if (!duplicateName) return name

  return getUniqueAddressName(addressList, current + 1)
}
