import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import React from 'react'

import { createFiservForm } from '@/libs/fiserv'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import PaymentGateway from '@/constants/paymentGateway'
import colors from '@/theme/colors'
import config from '@/config'
import dimorderApi from '@/libs/api/dimorder'

const payingLottie = require('@/assets/lotties/paying.json')

export default function LaunchFiserv (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const [showButton, setShowButton] = React.useState(false)

  console.log('render LaunchFiserv')

  React.useEffect(() => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const orderId = params.get('orderId')
    const paymentId = params.get('paymentId')

    async function launchFiserv (orderId, paymentId) {
      const order = await dimorderApi.getGlobalOrder(orderId)
      if (!order) {
        // 無法前往付款，顯示返回按鈕
        setShowButton(true)
        return
      }

      const payment = order.payments.find(payment => payment.id === paymentId)
      if (!payment || payment.gateway !== PaymentGateway.PAYMENT_GATEWAY_FISERV) {
        // 無法前往付款，顯示返回按鈕
        setShowButton(true)
        return
      }

      const payload = JSON.parse(payment.payload)
      const form = createFiservForm(payload)
      form.submit()
    }

    launchFiserv(orderId, paymentId)
  }, [])

  // 5 秒後顯示返回按鈕
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Page>
      <PageContainer
        className={classes.pageContainer}
      >
        <Lottie
          options={{
            loop: true,
            animationData: payingLottie,
          }}
          width='50%'
          height='auto'
        />
        <Typography variant='h3'>{t('app.page.launchFiserv.title')}</Typography>
        <Typography variant='h4'>{t('app.page.launchFiserv.tip')}</Typography>
        <Button
          className={classes.button}
          style={{ display: showButton ? 'block' : 'none' }}
          color='primary'
          variant='contained'
          onClick={() => {
            window.location.href = config.universalBaseUrl
          }}
        >
          {t('app.page.launchFiserv.return')}
        </Button>
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  pageContainer: {
    alignItems: 'center',
  },
  button: {
    marginTop: 16,
    backgroundColor: colors.primary,
    fontSize: 14,
  },
}))
