import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import config from '@/config'

const isProduction = config.env === 'prod'

/**
 * 更改測試用日期 (store.app.test.date)
 * production 環境不顯示
 *
 * @param {{
 * onChange?: function
 * className?: string
 * }} props
 * @returns
 */
export default function TestDateInput (props) {
  const { onChange = () => {}, className } = props
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const testDate = useSelector(state => state.app.test.date)
  const handleTestDateChange = (date) => dispatch(actions.app.updateTest('date', date))

  if (isProduction) return null
  return (
    <form className={clsx(classes.form, className)}>
      <Typography
        component='label'
        color='textSecondary'
        htmlFor='testDate'
      >
        測試日期
      </Typography>
      <input
        type='date'
        id='testDate'
        value={testDate}
        onChange={(e) => {
          handleTestDateChange(e.target.value)
          onChange()
        }}
      />
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
}))
