import React from 'react'

import { DrawerStepperBase } from '@/components/Stepper/StepperBase'

/**
 * @typedef CRMRewardItemStepperProps
 * @property {number} quantity
 * @property {React.Dispatch<React.SetStateAction<number>>} setQuantity
 * @property {boolean} disableIncrement
 * @param {CRMRewardItemStepperProps} props
 * @returns
 */
export default function CRMRewardItemStepper (props) {
  const { quantity, setQuantity, disableIncrement } = props
  const handleIncrement = (e) => {
    e.stopPropagation()
    setQuantity(prev => prev + 1)
  }
  const handleDecrement = (e) => {
    e.stopPropagation()
    setQuantity(prev => prev - 1)
  }

  return (
    <DrawerStepperBase
      quantity={quantity}
      min={1}
      onIncrement={e => handleIncrement(e, 1)}
      onDecrement={e => handleDecrement(e, 1)}
      disableIncrement={disableIncrement}
    />
  )
}
