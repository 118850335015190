import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import SkeletonMenuItem from './SkeletonMenuItem'

SkeletonMenuCategory.propTypes = {
  disabled: PropTypes.bool,
}

/**
 *
 * @param {{
 * disabled: boolean
 * }} props
 * @returns
 */
export default function SkeletonMenuCategory (props) {
  const { disabled } = props
  const classes = useStyles(props)

  if (disabled) return null
  return (
    <div className={classes.skeletonMenuCategory}>
      <Skeleton animation='wave' variant='text' width='50%' height={40} />
      <ul className={classes.skeletonMenuItemList}>
        {_.map(new Array(6), (a, index) => <SkeletonMenuItem key={index} />)}
      </ul>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  skeletonMenuCategory: {
    display: 'grid',
    gap: theme.spacing(1),
  },
  skeletonMenuItemList: {
    display: 'grid',
    gap: 8,
    gridTemplateColumns: 'repeat( auto-fit, minmax(280px, 1fr) )',
  },
}))
