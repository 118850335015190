import { useTranslation } from 'react-i18next'
import InputBase from './InputBase'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function InputPhone (props) {
  const { t } = useTranslation()

  return (
    <InputBase
      inputId='phone'
      label={t('app.page.setting_address.address.phone.title')}
      placeholder={t('app.page.setting_address.address.phone.placeholder')}
      value=''
      onChange={() => {}}
    />
  )
}
