import { CircularProgress, TableCell, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

RowBase.propTypes = {
  field: PropTypes.string,
  value: PropTypes.number,
  valueType: PropTypes.oneOf(['amount', 'percent']),
  fieldColumn: PropTypes.node,
  valueColumn: PropTypes.node,
  hidden: PropTypes.bool,
  customClasses: PropTypes.object,
  isLoading: PropTypes.bool,
  discount: PropTypes.number,
}

/**
 *
 * @param {{
 * field: string
 * value: number
 * valueType?: 'amount' | 'percent'
 * fieldColumn: Node
 * valueColumn: Node
 * hidden?: boolean
 * customClasses?: object
 * isLoading?: boolean
 * discount?: number
 * }} props
 * @returns
 */
export default function RowBase (props) {
  const {
    field,
    value,
    valueType = 'amount',
    fieldColumn,
    valueColumn,
    hidden,
    customClasses,
    isLoading,
    icon,
    discount = 0,
  } = props
  const classes = useStyles({ ...props, valueType })
  const originalFee = value + discount

  if (hidden) return null
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {fieldColumn || (
          <Typography variant='body2' className={clsx(classes.field, customClasses?.field)}>
            {field}
            {icon}
          </Typography>
        )}
      </TableCell>
      <TableCell align='right' className={classes.tableCell}>
        {valueColumn || (
          isLoading
            ? <CircularProgress size={15} />
            : (
              <Typography variant='body2' className={clsx(classes.value, customClasses?.value)}>
                {discount ? <span className={classes.originalFee}>{originalFee}</span> : null}
                {value}
              </Typography>
            )
        )}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: theme.spacing(0.75, 1),
  },
  field: {
    color: theme.palette.grey[700],
  },
  value: {
    '&:before': {
      content: props => props.valueType === 'amount' && props.value != null ? '"HK$"' : '""',
      marginRight: theme.spacing(0.5),
    },
    '&:after': {
      content: props => props.valueType === 'percent' && props.value != null ? '"%"' : '""',
      marginLeft: theme.spacing(0.25),
    },
  },
  originalFee: {
    marginRight: theme.spacing(0.5),
    textDecoration: 'line-through',
  },
}))
