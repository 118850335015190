import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import InputBase from './InputBase'

InputRemark.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

/**
 *
 * @param {{
 * value: String
 * onChange: Function
 * }} props
 * @returns
 */
export default function InputRemark (props) {
  const { value, onChange } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  return (
    <InputBase
      className={classes.inputRemark}
      inputId='remark'
      label={t('app.page.setting_address.address.remark.title')}
      placeholder={t('app.page.setting_address.address.remark.placeholder')}
      value={value}
      onChange={onChange}
      textarea
      rows={3}
    />
  )
}

const useStyles = makeStyles(theme => ({
  inputRemark: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[25],
  },
}))
