import { Typography } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import { useDatetime } from '@/hooks/app'
import { useMerchant } from '@/hooks/merchant'
import constants from '@/constants'

import DrawerContent from './common/DrawerContent'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import SwipeableDrawer from '@/components/SwipeableDrawer'

/**
 *
 * @param {*} props
 * @returns
 */
export default function PreorderDrawer (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const datetime = useDatetime()
  const merchant = useMerchant()
  const isD2CWeb = useSelector(state => state.app.params.isD2CWeb)
  const drawerOpen = useSelector(state => state.app.drawers.preorder.open)
  const restaurant = useSelector(state => state.landing.restaurant)

  if (!merchant || !restaurant) {
    // api loading
    return null
  }

  const { nextStartOpening, pickerRange } = restaurant

  // 整理顯示用的 weekday 和 nextStart
  let weekday = null
  let nextStart = null
  // createShipping 之後會檢查 datetime available，若不可用會嘗試使用即時 timeslot 若沒有即時 timeslot或還是不可用就會將 datetime.date, datetime.time 改成 undefined
  // 因此沒有 datetime.time 表是 preorder drawer 會打開，原本選的時間不可用，因此在後端有給 nextStartOpening 時要準備顯示下次營業時間
  if (!datetime.time && nextStartOpening) {
    const isToday = moment(datetime.date).weekday() === nextStartOpening.weekday
    const opening = moment().hour(nextStartOpening.start).minute(nextStartOpening.startmin)
    weekday = isToday
      ? t('app.common.date_time.today')
      : moment().weekday((nextStartOpening.weekday)).format(t('app.common.date_time.format_week'))
    nextStart = opening.format(t('app.common.date_time.format_time'))
  }
  const isPreOrder = pickerRange?.preOrderDay && pickerRange?.preOrderDay > 0
  const preOrderDate = isPreOrder && moment().add(pickerRange.preOrderDay, 'days')

  const openDrawer = () => dispatch(actions.app.toggleDrawer('preorder', true))
  const closeDrawer = () => dispatch(actions.app.toggleDrawer('preorder', false))

  return (
    <SwipeableDrawer
      open={drawerOpen}
      onOpen={openDrawer}
      onClose={() => {
        closeDrawer()
        if (!restaurant?.open) {
          if (isD2CWeb) {
            history.goBack()
          } else {
            history.push('/restaurants')
          }
        }
      }}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <DrawerContent className={classes.content}>
        {isPreOrder
          ? (
            <Typography variant='h5' align='center' className={classes.title}>
              {t('app.component.drawer.preorder.preorder_delivery', { month: preOrderDate.format('MMM'), day: preOrderDate.format('D') })}
            </Typography>
          )
          : nextStartOpening && (
            <Typography variant='h5' align='center' className={classes.title}>
              {t('app.component.drawer.preorder.title', { weekday, nextStart })}
            </Typography>
          )}
        <Typography variant='body1' align='center' className={classes.subtitle}>
          {t('app.component.drawer.preorder.subtitle')}
        </Typography>
        <LetterSpacingButton
          variant='outlined'
          bold
          onClick={() => {
            closeDrawer()
            dispatch(actions.app.toggleDrawer('datetime', true))
          }}
          text={t('app.component.drawer.preorder.preorder_now')}
          className={classes.button}
        />
      </DrawerContent>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(3),
    paddingBottom: `calc(${theme.spacing(3)}px + ${constants.app.safeArea.bottom})`,
  },
  content: {
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
    '& :last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(1.5, 0),
  },
  subtitle: {
    fontWeight: 'bold',
    color: theme.palette.grey[500],
  },
  button: {
    height: 50,
  },
}))
