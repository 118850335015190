import { makeStyles, useTheme } from '@material-ui/core/styles'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

import constants from '@/constants'

import DestinationMarker from './markers/DestinationMarker'
import DriverMarker from './markers/DriverMarker'

OrderTrackingMap.propTypes = {
  zoom: PropTypes.number,
  origin: PropTypes.object,
  destination: PropTypes.object,
  center: PropTypes.object,
}

/**
 *
 * @param {{
 * zoon: Number
 * origin: ILatLng
 * destination: ILatLng
 * center: ILatLng
 * }} props
 */
export default function OrderTrackingMap (props) {
  const defaultProps = {
    zoom: 18,
    origin: { lat: 22.276183, lng: 114.1706716 },
    destination: { lat: 22.2763786, lng: 114.1722652 },
  }
  const {
    zoom = defaultProps.zoom,
    origin = defaultProps.origin,
    destination = defaultProps.destination,
    center = props.center ?? props.origin ?? defaultProps.origin,
    className,
  } = props
  const theme = useTheme()
  const classes = useStyles(props)

  return (
  // ! Important! Always set the container height explicitly
    <div className={clsx(classes.mapWrap, className)}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: constants.googleMap.API_KEY,
          libraries: ['places', 'geometry'],
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={async ({ map, maps }) => {
          if (maps.DirectionsStatus.OK) {
            const directionsService = new maps.DirectionsService()
            const request = {
              origin: origin,
              destination: destination,
              travelMode: maps.TravelMode.DRIVING,
              avoidFerries: true,
              avoidHighways: false,
              avoidTolls: true,
            }
            directionsService.route(request, (result, status) => {
              if (status === maps.DirectionsStatus.OK) {
                const overviewPolyline = result.routes[0].overview_polyline
                const decodePath = maps.geometry.encoding.decodePath(overviewPolyline)
                const geodesicPolyline = new maps.Polyline({
                  path: decodePath,
                  geodesic: true,
                  strokeColor: theme.palette.error.main,
                  strokeOpacity: 0.75,
                  strokeWeight: 4,
                })
                geodesicPolyline.setMap(map)
              } else {
                console.warn(`error fetching directions ${status}`)
              }
            })
          }
        }}
      >
        <DriverMarker lat={origin?.lat} lng={origin?.lng} />
        <DestinationMarker lat={destination?.lat} lng={destination?.lng} />
      </GoogleMapReact>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mapWrap: {
    minHeight: '50%',
    width: '100%',
  },
}))
