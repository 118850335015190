import React from 'react'

import GroupItemCheckbox from './variants/GroupItemCheckbox'
import GroupItemOptionStepper from './variants/GroupItemOptionStepper'
import GroupItemRadio from './variants/GroupItemRadio'
import GroupItemSold from './variants/GroupItemSold'
import GroupItemStepper from './variants/GroupItemStepper'

/* eslint-disable no-unused-vars */
import { IAppMenuItem } from '@/redux/menu/MenuState.d'
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

/**
 *
 * @typedef GroupItemProps
 * @property {'checkbox' | 'radio' | 'stepper' | 'optionStepper' | 'sold'} variant
 * @property {IMenuOptionItem | IAppMenuItem} item
 * @property {IMenuOptionGroup} [group]
 * @property {() => void} [onClick]
 * @property {boolean} [selected = false]
 * @property {boolean} [disabled = false]
 * @property {boolean} [disableIncrement = false]
 * @param {GroupItemProps} props
 * @returns
 */
export default function GroupItem (props) {
  const { variant, item, group, onClick, selected = false, disabled = false, disableIncrement = false } = props

  switch (variant) {
    case 'checkbox':
      return <GroupItemCheckbox item={item} onClick={onClick} selected={selected} disabled={disabled} />

    case 'radio':
      return <GroupItemRadio item={item} onClick={onClick} selected={selected} disabled={disabled} />

    case 'stepper':
      return <GroupItemStepper item={item} disabled={disabled} disableIncrement={disableIncrement} />

    case 'optionStepper':
      return <GroupItemOptionStepper item={item} group={group} disabled={disabled} disableIncrement={disableIncrement} />

    case 'sold':
      return <GroupItemSold item={item} />

    default:
      return null
  }
}
