import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

Serial.propTypes = {
  serial: PropTypes.string,
}

/**
 *
 * @param {{
 * serial: string
 * }} props
 * @returns
 */
export default function Serial (props) {
  const { title, serial } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <Typography variant='h6' component='span' className={clsx(classes.subtitle, classes.bold)}>
      {title ?? t('app.page.order_tracking.progress_card.takeaway.subtitle')}
      <Typography color='textSecondary' className={classes.bold} component='span' variant='h6'>{serial}</Typography>
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  subtitle: {
    '& > *': {
      marginLeft: theme.spacing(0.5),
    },
  },
  bold: {
    fontWeight: 600,
  },
}))
