import { IconButton } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import React from 'react'

DeleteAddressButton.propTypes = {
  hidden: PropTypes.bool,
}

/**
 *
 * @param {{
 * hidden?: boolean
 * }} props
 * @returns
 */
export default function DeleteAddressButton (props) {
  const { hidden } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  if (hidden) return null
  return (
    <IconButton onClick={() => {
      const alertConfig = {
        title: t('app.page.setting_address.delete_address_alert.title'),
        message: t('app.page.setting_address.delete_address_alert.message'),
        onConfirm: () => {
          dispatch(actions.user.deleteAddress())
          history.goBack()
        },
      }
      dispatch(actions.app.toggleAlert(alertConfig))
    }}
    >
      <DeleteIcon />
    </IconButton>
  )
}
