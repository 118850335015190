import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo } from 'react'

import { useDeliveryType } from '@/hooks/app'
import { useTranslation } from 'react-i18next'

/**
 * @typedef {TimeTagProps}
 * @property {number} time
 * @property {boolean} open
 * @property {boolean} [hidden = false]
 * @param {TimeTagProps} props
 * @returns
 */
export default function TimeTag (props) {
  const { time, open, hidden = false } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const { deliveryType, isTakeaway, isStoreDelivery } = useDeliveryType()

  const text = useMemo(() => {
    if (time > 100) return t('app.common.preorder')
    if (isTakeaway) return t('app.common.date_time.takeaway_minute', { minutes: time })
    if (isStoreDelivery) return t('app.common.date_time.minute', { minutes: time })
  }, [deliveryType, time, t])

  if (hidden) return null
  if (!time) return null
  if (!open) return null

  // TODO: 等API給最後營業時間
  // if (!restaurant.open) {
  //   text = '預約 12:00'
  // }

  return (
    <div className={classes.timeWrap}>
      <Typography variant='caption' className={classes.bold}>
        {text}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  timeWrap: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    padding: theme.spacing(0.2, 1),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[25],
  },
  bold: {
    fontWeight: '400',
  },
}))
