export default {
  INIT: 'USER/INIT',
  UPDATE_MEMBER: 'USER/UPDATE_MEMBER',
  UPDATE_ADDRESS: 'USER/UPDATE_ADDRESS',
  UPDATE_ADDRESS_LIST: 'USER/UPDATE_ADDRESS_LIST',
  ADD_ADDRESS_TEMP: 'USER/ADD_ADDRESS_TEMP',
  REST_ADDRESS_TEMP: 'USER/REST_ADDRESS_TEMP',
  UPDATE_ADDRESS_TEMP: 'USER/UPDATE_ADDRESS_TEMP',
  UPDATE_EXIT_ADDRESS_EDIT_BACK_LEVEL: 'USER/UPDATE_EXIT_ADDRESS_EDIT_BACK_LEVEL',
  UPDATE_PROMOCODES: 'USER/UPDATE_PROMOCODES',
  ADD_PROMOCODE: 'USER/ADD_PROMOCODE',
  RESET_PROMOCODES: 'USER/RESET_PROMOCODES',
  UPDATE_COUPONS: 'USER/UPDATE_COUPONS',
  ADD_COUPON: 'USER/ADD_COUPON',
}
