import { List, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import MenuItemBase from '@/pages/Member/MenuItem/MenuItemBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SupportList (props) {
  const classes = useStyles(props)
  const handleClick = (url) => window.open(url, '_blank')
  const { t } = useTranslation()

  return (
    <List className={classes.supportList}>
      <Typography className={classes.listTitle}>{t('app.page.member.support_list.title')}</Typography>
      <MenuItemBase title={t('app.page.member.support_list.customer_support')} onClick={() => handleClick('https://s.dimorder.com/cs')} />
      <MenuItemBase title={t('app.page.member.support_list.privacy_policy')} onClick={() => handleClick('https://s.dimorder.com/privacy')} />
      <MenuItemBase title={t('app.page.member.support_list.terms_and_conditions')} onClick={() => handleClick('https://s.dimorder.com/tnc')} />
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  listTitle: {
    fontWeight: 'bold',
    padding: theme.spacing(1, 1.5),
  },
  supportList: {
    display: 'grid',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(4),
  },
}))
