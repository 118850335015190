import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import _ from 'lodash'
import axios from 'axios'

import Logger from 'dimorder-orderapp-lib/dist/libs/Logger'
import config from '@/config'
import shortCode from '@/libs/shortCode'

export const logId = shortCode.generate(4)

const errorToPlainObject = (error) => {
  // 保留 AxsioError 的內容
  if (axios.isAxiosError(error)) {
    return error
  }

  if (error instanceof Error) {
    return _.pick(error, ['message', 'stack'])
  }

  return error
}

const gcpLogger = new Logger('https://orderapp-logger-ssj7hdeukq-de.a.run.app/log', {
  // buildNumber: string,
  appVersion: config.version,
  environment: config.env,
  platform: Capacitor.getPlatform(),
  client: 'CUSTOMER_APP',
  logId,
})

const nativeSupportLogLevel = [
  'debug',
  'error',
  'info',
  'log',
  'trace',
  'warn',
]

const logger = {}

nativeSupportLogLevel.forEach(level => {
  logger[level] = (...args) => {
    const [message, data] = args
    console.log(...args)

    // translate error object to plain object for better gcp log
    if (data?.error) {
      data.error = errorToPlainObject(data.error)
    }

    gcpLogger.labels.href = window.location.href

    gcpLogger.log(
      level,
      { message: message, ...data },
      new Date().toISOString(),
    )
  }
})

// 用來強制 flush log，可用在 reload 之前避免還沒送出的 log 在 reload 後消失
export const flush = async () => {
  try {
    logger.log('[logger] manual flush logs')
    await gcpLogger.asyncFlush()
  } catch (error) {
    console.log('[logger] manual flush error', error)
  }
}

// set user id after login
export function setUserId (userId) {
  gcpLogger.labels.userId = userId
  try {
    FirebaseAnalytics.setUserId({ userId })
  } catch (error) {
    logger.log('error', `firebase setUserId error ${error?.message || error}`, { error })
  }
}

async function setDeviceInfo () {
  gcpLogger.labels.userAgent = navigator.userAgent

  if (Capacitor.getPlatform() !== 'web') {
    const appInfo = await App.getInfo()
    gcpLogger.labels.buildNumber = `${appInfo?.version} (${appInfo?.build})`
    const { uuid: deviceId } = await Device.getId()
    const deviceInfo = await Device.getInfo()
    gcpLogger.labels.deviceId = deviceId
    gcpLogger.labels.model = `${deviceInfo.model} (${deviceInfo.operatingSystem} ${deviceInfo.osVersion})`
  }
}

setDeviceInfo()
export default logger
