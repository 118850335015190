import config from '@/config'

export default {
  // ! 測試環境設定為 12:00 截單讓 Ben 測試
  hour: config.env !== 'prod' ? 12 : 10,
  minute: config.env !== 'prod' ? 0 : 30,
  second: 0,
}

// DimBox 當天訂單的截止時間
// DimBox 餐廳當日截單時間為 10:30，可於當日 12:00~14:30 取餐。
// 超過 10:30 下單，取餐時間為隔日 12:00~14:30。
// DimBox餐廳24小時都能下單。例如：
// 18日23:29下單，取餐時間顯示：19日12:00~14:30。
// 19日10:29下單，取餐時間顯示：19日12:00~14:30。
// 19日10:31下單，取餐時間顯示：20日12:00~14:30。
// 19日17:00下單，取餐時間顯示：20日12:00~14:30。
