import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Cart (props) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#000' fillRule='nonzero'>
          <g>
            <path d='M9 18c-.536 0-1.031.286-1.3.75-.267.465-.267 1.037.001 1.5.268.465.764.75 1.3.75.828 0 1.5-.671 1.5-1.5S9.829 18 9 18m7.5 0c-.536 0-1.031.286-1.3.75-.267.465-.267 1.037.001 1.5.268.465.764.75 1.3.75.828 0 1.5-.671 1.5-1.5S17.329 18 16.5 18m-9.172-6L6.122 7.5H19.27L18.064 12H7.328zm13.673-5.25c0-.414-.336-.75-.75-.75H5.72l-.803-3H2.25v1.5h1.516L7.18 17.25H18.75v-1.5H8.332l-.601-2.25h11.484l.285-1.063 1.5-5.6-.018-.004c.005-.028.018-.053.018-.083z' transform='translate(-192 -2526) translate(192 2526)' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
