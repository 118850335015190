import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useDeliveryType } from '@/hooks/app'
import constants from '@/constants'

import ComingSoon from '@/components/ComingSoon'
import DeliveryTypeTabs from '@/components/Page/PageTabs/DeliveryTypeTabs'
import FavoriteRestaurantList from '@/components/RestaurantList/FavoriteRestaurantList'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType
const deliveryTypes = [TAKEAWAY, STORE_DELIVERY, TABLE]
// APPBAR 高 + DeliveryTypeTabs 高 + safeArea top
const navbarBackgroundHeight = `calc(${constants.app.height.APPBAR + 43}px + ${constants.app.safeArea.top})`

export default function Favorite (props) {
  const { t } = useTranslation()
  const classes = useStyles({ ...props })
  const { isTable } = useDeliveryType()

  return (
    <Page>
      <Navbar
        backgroundHeight={navbarBackgroundHeight}
        title={t('app.page.member.menu_list.favorite')}
      />
      <DeliveryTypeTabs
        className={classes.pageTabs}
        deliveryTypes={deliveryTypes}
      />
      <PageContainer className={classes.container}>
        {
          isTable
            ? <ComingSoon />
            : <FavoriteRestaurantList />
        }
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  pageTabs: {
    zIndex: constants.app.zIndex.NAVBAR + 100,
    marginTop: constants.app.marginTop.APPBAR,
    marginBottom: theme.spacing(1),
    marginRight: 0,
    marginLeft: 0,
  },
}))
