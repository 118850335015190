import produce from 'immer'

import ActionTypes from './ActionTypes'

/* eslint-disable no-unused-vars */
import { IMerchant } from 'dimorder-orderapp-lib/dist/types/Merchant'
import { IMerchantState } from '@/redux/merchant/MerchantState.d'
/* eslint-enable no-unused-vars */

/** @type {IMerchant} */
const initialMerchant = {}

/** @type {IMerchantState} */
export const initialState = {
  data: initialMerchant,
}

export default produce(
  /**
   * @param {IMerchantState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.UPDATE_MERCHANT: {
        const { merchant } = action.payload
        draft.data = merchant
        break
      }
      case ActionTypes.RESET_MERCHANT: {
        return initialState
      }
      default:
        break
    }
  },
  initialState,
)
