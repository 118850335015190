import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import DialogBase from '@/components/Dialogs/DialogBase'

import MenuImage from './MenuImage'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MenuDetailDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const open = useSelector(state => state.app.dialogs.menuDetail.open)
  const menu = useSelector(state => state.app.dialogs.menuDetail.data.menu) ?? {}

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('menuDetail', false))
    setTimeout(() => {
      dispatch(actions.app.toggleDialog('menuDetail', undefined, {}))
      // 200ms delay for dialog close transition duration
    }, 200)
  }

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      customClasses={{ title: classes.title }}
      title={menu.name}
      renderContent={() => (
        <>
          <Typography variant='body2'>{menu.desc}</Typography>
          <MenuImage src={menu.image} />
        </>
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({

}))
