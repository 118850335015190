import * as app from './app/actions'
import * as batchItemTemp from './batchItemTemp/actions'
import * as dimbox from './dimbox/actions'
import * as landing from './landing/actions'
import * as merchant from './merchant/actions'
import * as order from './order/actions'
import * as orderBatch from './orderBatch/actions'
import * as orderHistory from './orderHistory/actions'
import * as payment from './payment/actions'
import * as snackbar from './snackbar/actions'
import * as user from './user/actions'

export default {
  app,
  snackbar,
  user,
  merchant,
  order,
  orderBatch,
  orderHistory,
  batchItemTemp,
  payment,
  landing,
  dimbox,
}
