import { Element } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import CategoryItem from './CategoryItem'

// eslint-disable-next-line no-unused-vars
import { IAppCategory } from 'dimorder-orderapp-lib/dist/types/AppMenu'

/**
 * @param {{
 * categories: IAppCategory[]
 * isLoading: boolean
 * }} props
 */
export default function CategoryList (props) {
  const { categories, isLoading } = props
  const classes = useStyles(props)

  if (_.isEmpty(categories)) {
    return null
  }

  return (
    <ul className={classes.list}>
      {_.map(categories, (category, index) => {
        if (isLoading) {
          return null
        }

        return (
          <li key={index}>
            <Element name={category.id}>
              <CategoryItem category={category} />
            </Element>
          </li>
        )
      })}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  list: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
  },
}))
