import { makeStyles } from '@material-ui/core/styles'
import Div100vh from 'react-div-100vh'
import React from 'react'
import clsx from 'clsx'
import constants from '@/constants'

export default function Page (props) {
  const { children, className, ...rest } = props
  const classes = useStyles(props)

  return (
    <Div100vh className={clsx([classes.page, className])} {...rest}>
      {children}
    </Div100vh>
  )
}

export function SafeAreaPage (props) {
  const { children, className, ...rest } = props
  const classes = useStyles(props)
  return (
    <Page className={clsx([classes.safeArea, className])} {...rest}>
      {children}
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    position: 'relative',
  },
  safeArea: {
    paddingTop: constants.app.safeArea.top,
    paddingBottom: constants.app.safeArea.bottom,
  },
}))
