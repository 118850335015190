
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { useAddress, useIsLogin } from '@/hooks/user'
import LocationPrecisionIcon from '@/assets/icons/location_precision.svg'

import AddressItemBase from './AddressItemBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AddressItemCurrentPosition (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const address = useAddress()
  const isLogin = useIsLogin()
  const isCurrentSelected = address?.id === 'CURRENT'

  const handleClick = async () => {
    if (isCurrentSelected) {
      dispatch(actions.user.addAddressTemp(address))
      if (isLogin) {
        // 編輯現在位置
        history.push('/settings/address/edit')
      } else {
        // 直接去選擇位置
        history.push('/settings/address/map')
      }
      return
    }

    // 非現在位置改用現在位置
    dispatch(actions.user.createAddressTemp('CURRENT'))
    history.push('/settings/address/map')
  }

  return (
    <AddressItemBase
      icon={<img src={LocationPrecisionIcon} alt='location_precision_icon' />}
      title={t('app.page.setting_address.current_location.title')}
      subtitle={(isCurrentSelected && address?.address) ? address?.address : t('app.page.setting_address.current_location.subtitle')}
      selected={isCurrentSelected}
      onClick={handleClick}
    />
  )
}
