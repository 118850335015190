import { Provider as BatchItemQuantityContextProvider } from '@/hooks/contexts/BatchItemQuantityContext'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import MenuItem from './MenuItem'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

MenuItemList.propTypes = {
  list: PropTypes.array,
}

/**
 *
 * @param {{
 * categoryId: string
 * list: IAppMenuItem[] | IAppSet[]
 * }} props
 */
export default function MenuItemList (props) {
  const { list, categoryId } = props
  const classes = useStyles({ ...props })

  const isInPromotedCategory = categoryId === 'promoted-category'
  return (
    <BatchItemQuantityContextProvider>
      <ul className={classes.menuItemList}>
        {_.map(list, (menu, index) => {
          const { separatedStep } = menu
          if (isInPromotedCategory && menu.step) {
            return
          }
          if (separatedStep) {
          // 把指定步驟  (separatedStep) 裡的每個 setItem 拆出來獨立顯示成一個套餐
          // 傳 setItemMenu 到 MenuItem 裡面
            const setItemMenus = _.filter(menu.menus, menu => {
              const shouldSeperate = menu.step === separatedStep
              if (isInPromotedCategory && !menu.promoted) {
                return false
              }
              return shouldSeperate
            })
            return _.map(setItemMenus, setItemMenu => {
              return <MenuItem key={setItemMenu.id} menu={menu} setItemMenu={setItemMenu} />
            })
          }

          return <MenuItem key={index} menu={menu} />
        })}
      </ul>
    </BatchItemQuantityContextProvider>
  )
}

const useStyles = makeStyles(theme => ({
  menuItemList: {
    display: 'grid',
    gap: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat( auto-fit, minmax(280px, 1fr) )', // auto-fit  當 grid-item 數量不足以填滿整個 grid-row 時，不會保留剩餘空的 grid-column，所以 grid-item 的寬度會填滿剩餘的 row space
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat( auto-fill, minmax(320px, 1fr) )', // auto-fill 當 grid-item 數量不足以填滿整個 grid-row 時，會保留剩餘空的 grid-column，所以 grid-item 的寬度會被限制
    },
  },
}))
