import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'

VideoBanner.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  slider: PropTypes.object,
}

/**
 *
 * @param {{
 * item: IBannerItem
 * active: boolean
 * slider: object
 * }} props
 * @returns
 */
export default function VideoBanner (props) {
  const { item, active, slider } = props
  const classes = useStyles(props)

  if (!item.video) return null
  return (
    <ReactPlayer
      url={item.video.url}
      height='unset'
      width='unset'
      className={classes.reactPlayerWrapper}
      muted
      playsinline // avoid the native fullscreen video player
      playing={active}
      onPlay={() => {
        slider?.slickPause() // pause the slider when video is playing
      }}
      onPause={() => {
        slider?.slickPlay() // resume autoplay
      }}
      onEnded={() => {
        slider?.slickNext() // slide to next when video is end
      }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  reactPlayerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1024, // 最寬就是 1024px 畫面更寬就置中
    width: '100%',
    margin: theme.spacing(0, 2),
    '& video': {
      flexShrink: 0,
      borderRadius: theme.shape.borderRadius * 2,
    },
    '& iframe': {
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
}))
