import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import constants from '@/constants'
import hooks from '@/hooks'

import { makeStyles } from '@material-ui/core'
import InfoButton from '@/components/Buttons/InfoButton'
import RowBase from './RowBase'

const { STORE_DELIVERY, SHOP } = constants.deliveryType

ShippingFeeRow.propTypes = {
  baseShippingFee: PropTypes.number,
  tunnelFee: PropTypes.number,
  smallOrderFee: PropTypes.number,
  longDistanceFee: PropTypes.number,
  shippingDiscount: PropTypes.number,
  deliveryType: PropTypes.string,
}

/**
 *
 * @param {{
 * baseShippingFee: number
 * tunnelFee: number
 * smallOrderFee: number
 * longDistanceFee: number
 * shippingDiscount: number
 * deliveryType: TDeliveryType
 * }} props
 * @returns
 */
export default function ShippingFeeRow (props) {
  const {
    baseShippingFee = 0,
    tunnelFee = 0,
    smallOrderFee = 0,
    longDistanceFee = 0,
    shippingDiscount = 0,
    deliveryType,
  } = props
  const classes = useStyles({ ...props })
  const { t } = useTranslation()
  const isCreateShippingLoading = hooks.app.useLoadings().createShipping
  const display = _.includes([SHOP, STORE_DELIVERY], deliveryType)

  return (
    <>
      <RowBase
        field={t('app.component.card.payment_summary_card.shipping')}
        value={baseShippingFee}
        hidden={!display}
        isLoading={isCreateShippingLoading}
        discount={shippingDiscount}
      />
      <RowBase
        field={t('app.component.card.payment_summary_card.tunnel')}
        value={tunnelFee}
        hidden={!display || tunnelFee === 0}
        isLoading={isCreateShippingLoading}
      />
      <RowBase
        customClasses={{ field: classes.fieldRow }}
        field={t('app.component.card.payment_summary_card.smallOrder')}
        icon={<InfoButton text={t('app.component.card.payment_summary_card.smallOrderTooltip')} />}
        value={smallOrderFee}
        hidden={!display || smallOrderFee === 0}
        isLoading={isCreateShippingLoading}
      />
      <RowBase
        field={t('app.component.card.payment_summary_card.longDistance')}
        value={longDistanceFee}
        hidden={!display || longDistanceFee === 0}
        isLoading={isCreateShippingLoading}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  info_icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: '1.6rem',
  },
  fieldRow: { display: 'flex', alignItems: 'center' },
}))
