import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'

import { useDateRange, useTimeRange } from '../queries/timeslots/useTimeslotsQuery'
import { useDatetime, useDeliveryType } from '@/hooks/app'

/* eslint-disable no-unused-vars */
import { UIDate, UITime } from 'dimorder-orderapp-lib/dist/types/Timeslot'
/* eslint-enable no-unused-vars */

const DatetimePickerContext = createContext(null)
const TempDatetimeContext = createContext(null)
const TempDeliveryTypeContext = createContext(null)

export function DatetimePickerContextProvider ({ forAll = false, children }) {
  return (
    <DatetimePickerContext.Provider value={{ forAll }}>
      <TempDeliveryTypeContextProvider>
        <TempDatetimeContextProvider>
          {children}
        </TempDatetimeContextProvider>
      </TempDeliveryTypeContextProvider>
    </DatetimePickerContext.Provider>
  )
}

function TempDeliveryTypeContextProvider ({ children }) {
  const { deliveryType } = useDeliveryType()
  const [tempDeliveryType, setTempDeliveryType] = useState(deliveryType)
  return (
    <TempDeliveryTypeContext.Provider value={{ tempDeliveryType, setTempDeliveryType }}>
      {children}
    </TempDeliveryTypeContext.Provider>
  )
}

function TempDatetimeContextProvider ({ children }) {
  const [tempDatetime, setTempDatetime] = useState({ date: undefined, time: undefined, isImmediate: undefined })
  return (
    <TempDatetimeContext.Provider value={{ tempDatetime, setTempDatetime }}>
      {children}
    </TempDatetimeContext.Provider>
  )
}

/**
 * @typedef DatetimePickerContext
 * @property {boolean} forAll
 * @returns {DatetimePickerContext}
 */
export function useDatetimePickerContext () {
  const context = useContext(DatetimePickerContext)
  if (!context) {
    throw new Error('useDatetimePickerContext must be used within DatetimePickerContextProvider')
  }
  return context
}

/**
 * @typedef TempDeliveryTypeContext
 * @property {TDeliveryType} tempDeliveryType
 * @property {React.Dispatch<React.SetStateAction<TDeliveryType>>} setTempDeliveryType
 * @returns {TempDeliveryTypeContext}
 */
export function useTempDeliveryTypeContext () {
  const context = useContext(TempDeliveryTypeContext)
  if (!context) {
    throw new Error('useTempDeliveryTypeContext must be used within TempDeliveryTypeContextProvider')
  }
  return context
}

/**
 * @typedef TempDatetimeContext
 * @property {{date: string | undefined; time: string | undefined; isImmediate: boolean | undefined}} tempDatetime
 * @property {React.Dispatch<React.SetStateAction<{date: string | undefined; time: string | undefined; isImmediate: boolean | undefined}>>} setTempDatetime
 * @returns {TempDatetimeContext}
 */
export function useTempDatetimeContext () {
  const context = useContext(TempDatetimeContext)
  if (!context) {
    throw new Error('useTempDatetimeContext must be used within TempDatetimeContextProvider')
  }
  return context
}

export function useDateRangeContext () {
  const { forAll } = useDatetimePickerContext()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  return useDateRange(tempDeliveryType, { forAll })
}

export function useTimeRangeContext () {
  const { forAll } = useDatetimePickerContext()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  const { tempDatetime } = useTempDatetimeContext()
  return useTimeRange(tempDatetime.date, tempDeliveryType, { forAll })
}

/**
 *
 * @param {booelan} open
 * @param {TDeliveryType[]} deliveryTypes
 */
export function useSyncDeliveryType (open, deliveryTypes) {
  const { deliveryType } = useDeliveryType()
  const { setTempDeliveryType } = useTempDeliveryTypeContext()
  useEffect(() => {
    if (open && deliveryType) {
      setTempDeliveryType(deliveryTypes.includes(deliveryType) ? deliveryType : deliveryTypes[0])
    }
  }, [open, deliveryType])
}

/**
 *
 * @param {boolean} open
 */
export function useResetDatetime (open) {
  const datetime = useDatetime()
  const { dateRange } = useDateRangeContext()
  const { timeRange } = useTimeRangeContext()
  const { setTempDatetime } = useTempDatetimeContext()
  useEffect(() => {
    if (open && datetime) {
      const isExpired = datetime.date && datetime.time && moment(`${datetime.date} ${datetime.time}`)?.isBefore(moment())

      // 判斷已選取的日期，是否未過期且在dateRangeList中
      const isDateValid = datetime.date && !isExpired &&
      dateRange.findIndex(v => v.key === datetime.date) >= 0

      // 判斷已選取的時間，是否未過期且在timeRangeList中
      const isTimeValid = datetime.time && !isExpired &&
      timeRange.findIndex(v => v.key === datetime.time) >= 0

      // 若為無效的日期及時間，預設選取最近的日期及時間
      const defaultDatetime = {
        date: isDateValid ? datetime.date : dateRange[0]?.key,
        time: isTimeValid ? datetime.time : timeRange[0]?.key,
        isImmediate: isTimeValid ? datetime.isImmediate : timeRange[0]?.isImmediate,
      }
      setTempDatetime(defaultDatetime)
    }
  }, [open, datetime])
}

export function useAutoSelectDatetime () {
  const { dateRange, isLoading: isDateRangeLoading } = useDateRangeContext()
  const { timeRange, isLoading: isTimeRangeLoading } = useTimeRangeContext()
  const { tempDatetime, setTempDatetime } = useTempDatetimeContext()
  useEffect(() => {
    // 當 dateRange 更新時
    if (!isDateRangeLoading) {
      if (
        !tempDatetime.date || // 如果未選取日期或
        dateRange.findIndex(v => v.key === tempDatetime.date) < 0 // 已選取的日期不存在於更新後的 dateRange
      ) {
        // 則預設選取最接近的日期
        setTempDatetime((prev) => ({ ...prev, date: dateRange[0]?.key }))
      }
    }
  }, [dateRange, isDateRangeLoading])

  useEffect(() => {
    // 當 timeRange 更新時
    if (!isTimeRangeLoading) {
      if (
        !tempDatetime.time || // 如果未選取時間或
        timeRange.findIndex(v => v.key === tempDatetime.time) < 0 // 已選取的時間不存在於更新後的 timeRange
      ) {
        // 則預設選取最接近的時間
        setTempDatetime(prev => ({ ...prev, time: timeRange[0]?.key, isImmediate: timeRange[0]?.isImmediate }))
      }
    }
  }, [timeRange, isTimeRangeLoading])
}

export function useScrollActiveIntoView () {
  const activeRef = useRef(null)
  const { tempDatetime } = useTempDatetimeContext()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  useEffect(async () => {
    if (activeRef.current) {
      activeRef.current.scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }
  }, [tempDatetime, tempDeliveryType])
  return activeRef
}

/**
 *
 * @param {'vertical' | 'horizontal'} type
 * @param {UIDate[] | UITime[]} data
 */
export function useIsEndReached (type, data) {
  const scrollRef = useRef(null)
  const [isStartReached, setIsStartReached] = useState(true)
  const [isEndReached, setIsEndReached] = useState(false)
  const tar = scrollRef.current

  useEffect(() => {
    if (tar) {
      if (type === 'horizontal') {
        // DateRangeList
        const isRightReached = tar.scrollLeft + tar.offsetWidth >= tar.scrollWidth
        setIsEndReached(isRightReached)
      } else if (type === 'vertical') {
        // TimeRangeList
        const isBottomReached = tar.scrollTop + tar.offsetHeight >= tar.scrollHeight
        setIsEndReached(isBottomReached)
      }
    }
  }, [data])

  const handleScroll = (e) => {
    if (tar) {
      if (type === 'horizontal') {
        // DateRangeList
        const isLeftReached = tar.scrollLeft <= 0
        const isRightReached = tar.scrollLeft + tar.offsetWidth >= tar.scrollWidth
        setIsStartReached(isLeftReached)
        setIsEndReached(isRightReached)
      } else if (type === 'vertical') {
        // TimeRangeList
        const isTopReached = tar.scrollTop <= 0
        const isBottomReached = tar.scrollTop + tar.offsetHeight >= tar.scrollHeight
        setIsStartReached(isTopReached)
        setIsEndReached(isBottomReached)
      }
    }
  }

  return { scrollRef, isStartReached, isEndReached, handleScroll }
}
