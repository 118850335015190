import constants from '@/constants'

import { getOrderPayment } from './payments'

/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppPayment} IAppPayment */
/** @typedef {import('dimorder-orderapp-lib/dist/types/Merchant').IMerchant} IMerchant */

const cardProviderMap = {
  VISA: 'visa',
  MASTER: 'master',
  MASTERCARD: 'master',
  'AMERICAN EXPRESS': 'ae',
  'AMERICAN-EXPRESS': 'ae',
  AE: 'ae',
}

const {
  RECENT,
  VISA,
  MASTER,
  AE,
  BYPASS,
  CREDIT_CARD,
  APPLE_PAY,
  GOOGLE_PAY,
  WECHAT_PAY,
  ALI_PAY,
  FPS,
  PAY_ME,
  JCB,
  UNIONPAY,
  WECHAT,
  UNIONPAY_QR,
  OCTOPUS,
  TNG,
} = constants.paymentMethod

const {
  PAYMENT_GATEWAY_2C2P,
} = constants.paymentGateWay

const paymentMethodConfigs = {
  [BYPASS]: {
    key: BYPASS,
  },
  [RECENT]: {
    key: RECENT,
    iconSource: require('@/assets/images/payment-method/credit-cards.png').default,
  },
  [CREDIT_CARD]: {
    key: CREDIT_CARD,
    iconSource: require('@/assets/images/payment-method/credit-cards.png').default,
  },
  [APPLE_PAY]: {
    key: APPLE_PAY,
    iconSource: require('@/assets/images/payment-method/apple-pay.png').default,
  },
  [GOOGLE_PAY]: {
    key: GOOGLE_PAY,
    iconSource: require('@/assets/images/payment-method/google-pay.png').default,
  },
  [WECHAT_PAY]: {
    key: WECHAT_PAY,
    iconSource: require('@/assets/images/payment-method/wechat-pay.png').default,
  },
  [FPS]: {
    key: FPS,
    iconSource: require('@/assets/images/payment-method/fps.png').default,
  },
  [ALI_PAY]: {
    key: ALI_PAY,
    iconSource: require('@/assets/images/payment-method/alipay.png').default,
  },
  [PAY_ME]: {
    key: PAY_ME,
    iconSource: require('@/assets/images/payment-method/payme.png').default,
  },
  [VISA]: {
    key: VISA,
    iconSource: require('@/assets/images/payment-method/credit-card/visa-card.png').default,
  },
  [MASTER]: {
    key: MASTER,
    iconSource: require('@/assets/images/payment-method/credit-card/master-card.png').default,
  },
  [AE]: {
    key: AE,
    iconSource: require('@/assets/images/payment-method/credit-card/ae-card.png').default,
  },
  [JCB]: {
    key: JCB,
    iconSource: require('@/assets/images/payment-method/credit-card/jcb-card.png').default,
  },
  [UNIONPAY]: {
    key: UNIONPAY,
    iconSource: require('@/assets/images/payment-method/credit-card/unionPay-card.png').default,
  },
  [WECHAT]: {
    key: WECHAT,
    iconSource: require('@/assets/images/payment-method/wechat-pay.png').default,
  },
  [UNIONPAY_QR]: {
    key: UNIONPAY_QR,
    iconSource: require('@/assets/images/payment-method/union-pay-qr.png').default,
  },
  [OCTOPUS]: {
    key: OCTOPUS,
    iconSource: require('@/assets/images/payment-method/octopus.png').default,
  },
  [TNG]: {
    key: TNG,
    iconSource: require('@/assets/images/payment-method/tng.png').default,
  },
}

export function getPaymentMethodConfig (paymentMethod) {
  return paymentMethodConfigs[paymentMethod]
}

export function getCardProviderKey (cardProvider) {
  return cardProviderMap[cardProvider.toUpperCase()]
}

/**
 *
 * @param {IAppPayment[]} payments
 * @param {string} [memberId]
 * @returns
 */
export function get3DSUrlFor2c2p (payments, memberId) {
  const orderPayment = getOrderPayment(payments, memberId)
  return orderPayment?.gateway === PAYMENT_GATEWAY_2C2P &&
    [VISA, MASTER, AE].includes(orderPayment?.paymentMethod)
    ? orderPayment.payload
    : ''
}
