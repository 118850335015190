import { Capacitor } from '@capacitor/core'
import { matchPath } from 'react-router'

import { decryptToken } from '@/libs/crypto'
import dimorderApi from '@/libs/api/dimorder'
import fromEntries from '@/libs/fromEntries'
import logger from '@/libs/logger'

const booleanStrings = new Set(['0', '1', 'false', 'true']) // 可以被 JSON.parse 處理的 boolean strings
const booleanParams = new Set(['init', 'isD2CWeb', 'takeaway', 'storeDelivery', 'dineIn']) // 必需要是 boolean 的 params

class URLParser {
  static isValidSite (_url) {
    try {
      const url = new URL(_url)
      return url.host.includes('app.dimorder.com') ||
      // url.host.includes('link.dimorder.com') ||
      url.host.includes('dimorder.com') ||
      url.host.includes('dimorder.page.link') ||
      url.host.includes('dimorder0.page.link') ||
      url.host.includes('192.168.50.') ||
      url.host.includes('localhost') ||
      url.host.includes('ap.ngrok.io') ||
      url.host.includes('test-dimorder-customer.web.app')
    } catch (error) {
      return false
    }
  }

  /**
   * @function
   * @param {string?} url
   * @returns {URLParser}
   */
  static async create (url = window.location.href) {
    let _url = url
    // 短網址的情況需要拿到轉址後的網址才會有 query parameters
    if (url.match(/https?:\/\/s\.dimorder\.com/)) {
      try {
        const longUrl = await dimorderApi.parseShortUrl(url)
        _url = new URL(longUrl)
      } catch (error) {
        logger.error(`restore short url ${url} error ${error?.message || error}`, { error })
      }
    }

    return new URLParser(_url)
  }

  /**
   * @function
   * @param {string?} url
   * @returns {URLParser}
   */
  constructor (url = window.location.href) {
    this.url = new URL(url)
    this.searchParams = new URLSearchParams(this.url.search)
    this.isValidSite = URLParser.isValidSite(url)
    this.merchantId = null
    this.lang = null

    // 解密
    if (this.searchParams.has('x')) {
      const search = decryptToken(this.searchParams.get('x'))
      this.searchParams = new URLSearchParams(search)
    }

    // 非 web 為 APP Mode，或是可以用 localstorage 強制開啟
    const appMode = Capacitor.getPlatform() !== 'web' || localStorage.getItem('APP') || this.searchParams.get('app')

    // 語言
    if (this.searchParams.has('l')) {
      this.lang = this.searchParams.get('l')
      if (this.lang === 'zh') {
        this.lang = 'zh-HK'
      }
      if (this.lang === 'en') {
        this.lang = 'en-US'
      }
      this.searchParams.delete('l')
    }

    const paramsMerchantId = this.searchParams.get('m') || this.searchParams.get('merchantId')
    const platformMatch = matchPath(this.url.pathname, { path: '/restaurant/:id' })
    const d2cMatch = matchPath(this.url.pathname, { path: '/d2c/:id' })
    const d2cGroupMatch = matchPath(this.url.pathname, { path: '/d2c/:merchantId/membership/:groupId' })
    const groupMatch = matchPath(this.url.pathname, { path: '/membership/:groupId' })

    // CRM group id
    this.groupId = d2cGroupMatch?.params?.groupId || groupMatch?.params?.groupId
    // 餐廳 id
    this.merchantId = paramsMerchantId || platformMatch?.params?.id || d2cMatch?.params?.id || d2cGroupMatch?.params?.id
    this.searchParams.delete('m')
    if (this.merchantId) {
      this.searchParams.set('merchantId', this.merchantId)
    }

    const isD2CWeb = !appMode && Boolean(this.searchParams.get('isD2CWeb') || d2cMatch) && this.merchantId

    // isD2CWeb
    if (isD2CWeb) {
      // 如果有 d2c 的東西且有 merchantId 就是餐廳模式
      this.searchParams.set('isD2CWeb', '1')
    } else {
      this.searchParams.delete('isD2CWeb')
    }

    // 訂單 id
    if (this.searchParams.has('o')) {
      this.searchParams.set('orderId', this.searchParams.get('o'))
      // 堂食狀態將 params 改成預設 dineIn 顯示、關閉外送外帶
      this.searchParams.set('dineIn', this.searchParams.get('dineIn') ?? '1')
      this.searchParams.set('takeaway', this.searchParams.get('takeaway') ?? '0')
      this.searchParams.set('storeDelivery', this.searchParams.get('storeDelivery') ?? '0')
      this.searchParams.delete('o')
    }

    // 檯號
    if (this.searchParams.has('t')) {
      this.searchParams.set('table', this.searchParams.get('t'))
      // 堂食狀態將 params 改成預設 dineIn 顯示、關閉外送外帶
      this.searchParams.set('dineIn', this.searchParams.get('dineIn') ?? '1')
      this.searchParams.set('takeaway', this.searchParams.get('takeaway') ?? '0')
      this.searchParams.set('storeDelivery', this.searchParams.get('storeDelivery') ?? '0')
      this.searchParams.delete('t')
    }

    // deliveryType
    if (this.searchParams.has('d')) {
      this.searchParams.set('deliveryType', this.searchParams.get('d'))
      this.searchParams.delete('d')
    }

    // 清除 categoryTag
    if (this.searchParams.toString().length > 0 && !this.searchParams.has('categoryTag')) {
      // 如果是從 url 還原且沒有設定 categoryTag
      // 將 categoryTag 設為 ''，組合 params 時 categoryTag 就會變成 '' 而在 restoreParams 時被刪除
      this.searchParams.set('categoryTag', '')
    }

    if (!this.searchParams.has('deliveryType')) {
      const isTable = this.searchParams.has('orderId') || this.searchParams.has('table')
      if (isTable) {
        this.searchParams.set('deliveryType', 'table')
      }
    }

    // 路徑修正
    if (isD2CWeb) {
      if (!d2cMatch) {
        // 是餐廳模式但不在餐廳模式底下，修正到 D2C 餐廳模式首頁或會員卡頁面
        if (this.groupId) {
          this.url.pathname = `/d2c/${this.merchantId}/membership/${this.groupId}`
        } else {
          this.url.pathname = `/d2c/${this.merchantId}`
        }
      }
    } else if (this.merchantId) {
      // 非 D2C 且有 merchantId
      if (d2cMatch) {
        // 網址是 d2c 需要改回一般餐廳頁面或會員卡頁面
        if (this.groupId) {
          this.url.pathname = `/membership/${this.groupId}`
        } else {
          this.url.pathname = `/restaurant/${this.merchantId}`
        }
      }
      if (!platformMatch) {
        // 有 merchant id 但是不在餐廳頁面中，前往餐廳頁面或會員卡頁面
        if (this.groupId) {
          this.url.pathname = `/membership/${this.groupId}`
        } else {
          this.url.pathname = `/restaurant/${this.merchantId}`
        }
      }
    }
  }

  isWaiterWeb () {
    if (this.isValidSite) {
      return this.url.host.split('.').shift().includes('waiter')
    }
  }

  getParams () {
    if (this.isValidSite) {
      const params = fromEntries(this.searchParams)
      for (const key of booleanParams) {
        const value = params[key]
        if (value) { // 如果該 params 有 value 時
          if (booleanStrings.has(value)) { // 判斷該 params 的 value 是否為 boolean string
            params[key] = Boolean(JSON.parse(value)) // 若為 boolean 時將 value 改為 boolean 型別
          } else {
            delete params[key] // 若非 boolean 則從 params 中移除這個錯誤 value
          }
        }
      }
      return params
    }
    return {}
  }

  get (key) {
    if (this.isValidSite) {
      // 轉成 Boolean 後 return
      const value = this.searchParams.get(key)
      if (booleanParams.has(key)) { // 判斷該 params 是否僅限 boolean
        return booleanStrings.has(value) ? Boolean(JSON.parse(value)) : false // 若為 boolean 時將 value 改為 boolean 型別
      } else {
        return value
      }
    }
  }

  has (key) {
    if (this.isValidSite) {
      return this.searchParams.has(key)
    }
    return false
  }
}

export default URLParser
