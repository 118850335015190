import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import { checkIsDimboxOrder } from '@/libs/dimbox'
import constants from '@/constants'

import EstimatedDeliveryTime from './EstimatedDeliveryTime'
import Serial from './Serial'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType

ProgressHeader.propTypes = {
  order: PropTypes.object,
}

/**
 *
 * @param {{
 * order: IAppOrder
 * }} props
 * @returns
 */
export default function ProgressHeader (props) {
  const { order } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const isDimbox = checkIsDimboxOrder(order)

  switch (order.deliveryType) {
    case TAKEAWAY:
      return (
        <header className={classes.header}>
          <div className={classes.titleWrap}>
            <Typography variant='h5' className={classes.bold}>
              {
                isDimbox
                  ? t('app.constant.deliveryType.dimbox')
                  : t('app.page.order_tracking.progress_card.takeaway.title')
              }
            </Typography>
            <Typography variant='caption' className={classes.hint}>&nbsp;</Typography>
          </div>
          <Serial serial={order.serial} />
        </header>
      )

    case STORE_DELIVERY:
      return (
        <header className={classes.header}>
          <EstimatedDeliveryTime order={order} />
          <Serial serial={order.serial} />
        </header>
      )

    case TABLE:
      return (
        <header className={classes.header}>
          <Serial title={t('app.constant.table.table_number')} serial={order.table} />
          <Serial serial={order.serial} />
        </header>
      )

    default:
      return null
  }
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'flex-end',
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  bold: {
    fontWeight: 'bold',
  },
  hint: {
    color: theme.palette.grey[600],
  },
}))
