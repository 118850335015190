import { Checkbox, Collapse, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React from 'react'
import _ from 'lodash'

const objectDepth = (o) => Object(o) === o ? 1 + Math.max(-1, ...Object.values(o).map(objectDepth)) : 0

export default function FilterItem (props) {
  const classes = useStyles(props)
  const {
    field, // e.g. '價格'
    filterOptions, // e.g.  ['200-300', '300-400']
    selectedOptions, // e.g.  ['200-300', '300-400']
    onSelect,
  } = props

  const [expanded, setExpanded] = React.useState(null)

  /**
   * @function
   * @param {string} groupLabel
   * @returns
   */
  const toggleExpand = (groupLabel) => {
    if (expanded === groupLabel) {
      setExpanded(null)
      return
    }
    setExpanded(groupLabel)
  }

  /**
   * 之前用來 render 香港分區 折疊的大區裡面有多個小區
   * @param {string} groupLabel
   * @param {string[]} options
   * @returns
   */
  const renderFilterGroup = (groupLabel, options) => {
    const open = expanded === groupLabel

    return (
      <div key={groupLabel}>
        <ListItem onClick={() => toggleExpand(groupLabel)}>
          <ListItemText primary={groupLabel} />
          <div className={classes.expandWrap}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </ListItem>
        <Collapse in={open}>
          {_.map(options, (option, index) => renderFilter(option, groupLabel))}
        </Collapse>
      </div>
    )
  }

  /**
   * @param {string} option
   * @returns
   */
  const renderFilter = (option) => {
    const isSelected = _.includes(selectedOptions, option)

    return (
      <ListItem key={option} onClick={() => onSelect(field, option)}>
        <ListItemText primary={option} />
        <Checkbox checked={isSelected} color='primary' className={classes.check} />
      </ListItem>
    )
  }

  return _.map(filterOptions, (option, key) => {
    const depth = objectDepth(option)
    if (depth > 0) {
      const groupLabel = key
      return renderFilterGroup(groupLabel, option)
    }

    return renderFilter(option)
  })
}

const useStyles = makeStyles(theme => ({
  check: {
    color: theme.palette.primary.main,
  },
  expandWrap: {
    height: 42,
    width: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
