import { CircularProgress } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Card from '@/components/Cards/Card'

function SkeletonPayingCard (props) {
  const classes = useStyles(props)

  return (
    <Card className={classes.container}>
      <Skeleton animation='wave' variant='text' width={60} height={24} />
      <div className={classes.right}>
        <Skeleton className={classes.skeleton} animation='wave' variant='text' width={50} height={24} />
        <CircularProgress size={16} />
      </div>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: 48,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    gap: theme.spacing(2),
  },
  right: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
}))

export default React.memo(SkeletonPayingCard)
