import { List, ListSubheader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Card from '@/components/Cards/Card'
import FilterItem from './FilterItem'

/**
 *
 * @param {{
 * field: string
 * filterOptions: string[]
 * selectedOptions: string[]
 * onSelect: function
 * singleSelect: boolean
 * }} props
 * @returns
 */
export default function FilterSection (props) {
  const classes = useStyles(props)
  const {
    field, // e.g. '價格'
    filterOptions, // e.g.  ['200-300', '300-400']
    selectedOptions, // e.g.  ['200-300', '300-400']
    onSelect,
    singleSelect,
    ...rest
  } = props

  return (
    <Card className={classes.container} {...rest}>
      <List
        subheader={
          <ListSubheader className={classes.title} disableSticky>
            {field}
          </ListSubheader>
        }
      >
        <FilterItem
          field={field}
          filterOptions={filterOptions}
          selectedOptions={selectedOptions}
          onSelect={onSelect}
        />
      </List>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  title: {
    fontSize: '2rem',
    fontWeight: '600',
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '1.6rem',
    fontWeight: '600',
    color: theme.palette.text.primary,
  },
}))
