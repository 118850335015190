import React from 'react'

import { actions, useDispatch } from '@/redux'
import hooks from '@/hooks'

import InputAddress from './InputAddress'
import InputBuilding from './InputBuilding'
import InputFloorRoom from './InputFloorRoom'
import InputName from './InputName'
import InputPhone from './InputPhone'

/**
 *
 * @param {{
 * variant: String
 * }} props
 * @returns
 */
export default function Input (props) {
  const { variant } = props
  const dispatch = useDispatch()
  const addressTemp = hooks.user.useAddressTemp()
  const handleChange = (e, path) => {
    dispatch(actions.user.updateAddressTemp(path, e.target.value))
  }

  switch (variant) {
    case 'name': {
      return (
        <InputName
          value={addressTemp?.name}
          onChange={(e) => handleChange(e, 'name')}
        />
      )
    }
    case 'address': {
      return (
        <InputAddress
          address={addressTemp}
        />
      )
    }
    case 'floorRoom': {
      return (
        <InputFloorRoom
          value={addressTemp?.floorRoom}
          onChange={(e) => handleChange(e, 'floorRoom')}
        />
      )
    }
    case 'building': {
      return (
        <InputBuilding
          value={addressTemp?.building}
          onChange={(e) => handleChange(e, 'building')}
        />
      )
    }
    case 'phone': {
      // TODO member api update phone?
      return <InputPhone />
    }
    default:
      return null
  }
}
