import i18next from 'i18next'
import moment from 'moment'

import store from '@/redux/store'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * @param {string} pickupAt
 * @returns {{
 * '14~17': boolean
 * '17~21': boolean
 * }}
 */
export function poonchoiTimeslots (pickupAt) {
  return {
    '14~17': moment(pickupAt).isBetween(moment(pickupAt).set('hour', 14).set('minute', 0), moment(pickupAt).set('hour', 17).set('minute', 0), 'undefined', '[)'),
    '17~21': moment(pickupAt).isBetween(moment(pickupAt).set('hour', 17).set('minute', 0), moment(pickupAt).set('hour', 21).set('minute', 0), 'undefined', '[]'),
  }
}

/**
 * 會判斷是否有開啟 app.system.campaign.poonchoi 並判斷分類名稱是否屬於盆菜活動。例如：'冬至盆菜', '中秋盆菜', 'Winter Poon Choi' 都是盆菜活動
 * @param {string} categoryName 分類名稱
 * @returns {boolean}
 */
export function isPoonChoiCategory (categoryName) {
  if (!categoryName) return false

  const isPoonchoiEnabled = getState().app.system.campaign.poonchoi
  if (!isPoonchoiEnabled) return false

  const i18nPoonChoiKeyWord = i18next.t('app.constant.poonchoiKeyWord')
  // category 中有盆菜或PoonChoi等字樣 return true
  // 例如：冬至盆菜, 中秋盆菜, Winter Poon Choi 都算是盆菜分類，空格, _ 和 -會被忽略
  return categoryName.replace(/[\s_-]/g, '').trim().toLowerCase().includes(i18nPoonChoiKeyWord)
}

/**
 * 會判斷是否有開啟 app.system.campaign.poonchoi 並判斷 categoryTag 是否為盆菜
 * @param {string} categoryName 分類名稱
 * @returns {boolean}
 */
export function isPoonChoiCategoryTag (categoryTag) {
  const isPoonchoiEnabled = getState().app.system.campaign.poonchoi
  if (!isPoonchoiEnabled) return false

  return categoryTag === '盆菜'
}

/**
 * 會判斷是否有開啟 app.system.campaign.poonchoi 並判斷 restaurant.tags 中有沒有盆菜相關的 tag
 * @param {string[]} tags restaurant.tags
 * @returns {boolean}
 */
export function isPoonChoiRestaurant (tags) {
  if (!tags || tags.length === 0) return false

  const isPoonchoiEnabled = getState().app.system.campaign.poonchoi
  if (!isPoonchoiEnabled) return false

  const i18nPoonChoiKeyWord = i18next.t('app.constant.poonchoiKeyWord')

  return Boolean(tags.find(tag => tag?.replace(/[\s_-]/g, '').trim().toLowerCase().includes(i18nPoonChoiKeyWord)))
}
