import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import DeliveryButton from './DeliveryButton'
import TableButton from './TableButton'
import TakeawayButton from './TakeawayButton'

/**
 * 外送、自取、掃描按鈕
 * @param {*} props
 * @returns
 */
export default function DeliveryTypeButtons (props) {
  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DeliveryButton />
      </div>
      <div className={classes.row}>
        <TakeawayButton />
        <TableButton />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
}))
