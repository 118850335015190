import { DEFAULT_PER_ROW, useBaseQueryParams } from './params'
import { useDatetime, useDeliveryType } from '../app'
import { useQuery } from '@tanstack/react-query'
import { useRestaurantsRefetchInterval, useRestaurantsStaleTime } from './options'
import landingApi from '@/libs/api/landing'

/**
 *
 * @param {number} [perRow = 8]
 * @returns
 */
export function useLandingCategoriesQueryOptions (perRow = DEFAULT_PER_ROW) {
  const { deliveryType } = useDeliveryType()
  const { date, time, isImmediate } = useDatetime()
  const params = useBaseQueryParams({ limit: perRow })
  const queryKey = isImmediate
    ? ['landing', 'categories', { deliveryType, isImmediate }]
    : ['landing', 'categories', { deliveryType, date, time }]
  const queryFn = () => landingApi.fetchCategories(params)
  const select = (data) => data.filter(category => category.restaurants.length > 0) // 篩掉餐廳數量為 0 的分類，避免顯示沒有任何餐廳的分類
  const staleTime = useRestaurantsStaleTime()
  const refetchInterval = useRestaurantsRefetchInterval()
  return { queryKey, queryFn, select, staleTime, refetchInterval }
}

/**
 * 用於餐廳列表頁面
 * 顯示分類與分類之下的前幾間餐廳
 * @param {number} perRow
 * @returns
 */
export function useLandingCategoriesQuery (perRow) {
  const options = useLandingCategoriesQueryOptions(perRow)
  const query = useQuery(options)
  return query
}
