
import { makeStyles } from '@material-ui/core/styles'
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import _ from 'lodash'
import clsx from 'clsx'

import { useBanners } from '@/hooks/queries/useLandingSettingsQuery'

import BannerItem from './BannerItem'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Banner (props) {
  const classes = useStyles(props)
  const banners = useBanners()
  const sliderRef = useRef()
  const [activeSlide, setActiveSlide] = useState(0)
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: current => setActiveSlide(current),
  }
  return (
    <Slider
      ref={sliderRef}
      className={clsx(classes.slider)}
      {...settings}
    >
      {_.map(banners, (banner, index) => {
        return (
          <BannerItem
            key={index}
            item={banner}
            active={index === activeSlide}
            slider={sliderRef.current}
          />
        )
      })}
    </Slider>
  )
}

const useStyles = makeStyles(theme => ({
  slider: {
    margin: theme.spacing(0, -1, 1, -1),
  },
}))
