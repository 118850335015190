import { Capacitor } from '@capacitor/core'
import { IconButton } from '@material-ui/core'
import { Share } from '@capacitor/share'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import ShareButtonIcon from '@root/src/assets/icons/ShareButtonIcon'
import clsx from 'clsx'
import config from '@/config'
import opacity from '@/theme/opacity'

/**
 * @param {{
 * sticky: boolean
 * }} props
 * @returns
 */
export default function ShareButton (props) {
  const { sticky } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const merchantId = useSelector(state => state.merchant?.data?.id)
  const isD2CWeb = useSelector(state => state.app.params?.isD2CWeb)
  const shareUrl = `${config.universalBaseUrl}/${isD2CWeb ? 'd2c' : 'restaurant'}/${merchantId}?init=1`

  const handleShare = async () => {
    if (Capacitor.isNativePlatform()) {
      try {
        await Share.share({
          text: shareUrl,
          dialogTitle: t('app.component.dialog.share_restaurant_dialog.title'),
        })
      } catch (error) {
        console.log('share error', error)
      }
    } else {
      window.open(window.open(`https://wa.me/?text=${encodeURIComponent(shareUrl)}`))
    }
  }

  return (
    <IconButton
      id='shareButton'
      classes={{ root: clsx(classes.circleIconButton, { [classes.transparentBackground]: sticky }) }}
      onClick={handleShare}
    >
      <ShareButtonIcon className={classes.icon} />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  circleIconButton: {
    position: 'relative',
    backgroundColor: `#ffffff${opacity[80]} !important`,
    padding: theme.spacing(1),
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1,
      borderRadius: '50%',
      backdropFilter: 'blur(2px)',
    },
  },
  transparentBackground: {
    backgroundColor: 'transparent !important',
  },
  icon: {
    fontSize: '1.8rem',
  },
}))
