import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import moment from 'moment'

import { CRM_GROUP_COVER, transformation } from '@/libs/cloudinary'
import { actions, useDispatch, useSelector } from '@/redux'
import { useGroupQuery } from '@/hooks/queries/CRM/useGroupQuery'
import { useIsLogin } from '@/hooks/user'
import { useMode } from '@/hooks/useWindowSize'
import constants from '@/constants'

import { CRMRegisterButton, CRM_GRADIENT_BUTTON, LoadingComponent } from '@/pages/D2C/HomePage/CRMGradientButton'
import { SafeAreaPage } from '@/components/Page'
import CRMRegisterDialog from '@/components/Dialogs/CRMRegisterDialog'
import PageContainer from '@/components/Page/PageContainer'

import CRMNavbar from '../components/Navbar'
import Card from '../components/Card'
import Footer from './Footer'
import SectionHeader from '../components/SectionHeader'

export default function CRMHomePage (props) {
  const theme = useTheme()
  useClaimCRMPoints()

  return (
    <SafeAreaPage>
      <CRMNavbar />
      <PageContainer>
        <GroupImage />
        <GroupName />
        <CRMMemberCard style={{ margin: theme.spacing(1) }} />
        <GroupDesc />
        <Footer />
      </PageContainer>
      <CRMRegisterDialog />
    </SafeAreaPage>
  )
}

/**
 *
 * @param {React.HTMLAttributes<HTMLDivElement> & { height?: number }} props
 * @returns
 */
export function GroupImage (props) {
  const { height = 300, style, ...rest } = props
  const classes = useStyles(props)
  const { isMobile } = useMode()
  const { data: group, isLoading } = useGroupQuery()
  const url = transformation(group?.imageUrl ?? constants.app.image.EMPTY_IMAGE, { width: isMobile ? CRM_GROUP_COVER.sm.width : CRM_GROUP_COVER.md.width })

  if (isLoading) return <Skeleton variant='rect' height={height} className={classes.groupImage} />
  return <div className={classes.groupImage} style={{ ...style, height, backgroundImage: `url(${url})` }} {...rest} />
}

export function GroupName (props) {
  const { data: group, isLoading } = useGroupQuery()
  return <SectionHeader title={group?.name} loading={isLoading} />
}

function GroupDesc (props) {
  const classes = useStyles(props)
  const { data: group, isLoading } = useGroupQuery()
  if (isLoading) {
    return (
      <div className={classes.groupDesc}>
        <Skeleton variant='text' style={{ fontSize: '1.4rem' }} />
        <Skeleton variant='text' style={{ fontSize: '1.4rem' }} />
        <Skeleton variant='text' style={{ fontSize: '1.4rem' }} />
        <Skeleton variant='text' style={{ fontSize: '1.4rem' }} />
        <Skeleton variant='text' style={{ fontSize: '1.4rem' }} />
      </div>
    )
  }
  return <Typography variant='caption' className={classes.groupDesc}>{group?.desc}</Typography>
}

function CRMMemberCard (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const isLogin = useIsLogin()
  const { data: group, isCRMMember, isLoading } = useGroupQuery()
  if (isLoading) return <LoadingComponent {...props} />
  if (!isLogin || !isCRMMember) return <CRMRegisterButton isLogin={isLogin} isCRMMember={isCRMMember} {...props} />
  return (
    <Card className={classes.groupMemberCard} {...props}>
      <Typography variant='body2' className={classes.pointsTitle}>{t('app.page.crm.crm_available_points')}</Typography>
      <Typography variant='caption' className={classes.value}>{group?.points} {t('app.page.crm.crm_points')}</Typography>
      <Typography variant='caption'>{t('app.page.crm.join_date')}</Typography>
      <Typography variant='caption' className={classes.value}>{moment(group?.joinedAt).format('YYYY/MM/DD')}</Typography>
    </Card>
  )
}

/**
 * 已加入 CRM 會員且有帶 orderId 及 p 可以領回積分
 */
function useClaimCRMPoints () {
  const dispatch = useDispatch()
  const { isCRMMember, refetch } = useGroupQuery()
  const { orderId, p } = useSelector(state => state.app.params)
  useEffect(() => {
    async function claimCRMPoitns () {
      if (isCRMMember && orderId && p) {
        const isSuccess = await dispatch(actions.order.claimCRMPoints(orderId, p))
        if (isSuccess) { refetch() }
      }
    }
    claimCRMPoitns()
  }, [isCRMMember, orderId, p])
}

const useStyles = makeStyles(theme => ({
  groupImage: {
    flexShrink: 0,
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.grey[300],
  },
  groupDesc: {
    whiteSpace: 'pre-line',
    padding: theme.spacing(1.5, 1),
    margin: theme.spacing(0, 1),
    marginBottom: theme.spacing(8),
    flex: 1,
  },
  groupMemberCard: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'cetner',
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1),
    fontSize: '1.2rem',
    height: CRM_GRADIENT_BUTTON.height,
  },
  pointsTitle: {
    fontSize: '1.6rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  value: {
    textAlign: 'end',
    verticalAlign: 'center',
  },
}))
