import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'

import StepperBase from './StepperBase'

export default function BatchItemStepper (props) {
  const { menu, item } = props

  const [displayQuantity, setDisplayQuantity] = React.useState(item.quantity)

  React.useEffect(() => {
    // 當透過 dispatch action 更新餐點數量後會導致 item.quantity 更新
    // 此時應同步更新 displayQuantity
    setDisplayQuantity(item.quantity)
  }, [item.quantity])

  const dispatch = useDispatch()

  const handleIncrement = (e, quantity, number) => {
    e.stopPropagation()
    setDisplayQuantity(quantity + number)
    debouncedUpdateSelectedItem(quantity + number)
  }

  const handleDecrement = (e, quantity, number) => {
    e.stopPropagation()

    // 如果該商品的數量減少後 <= 0 則自購物車移除此商品
    if (quantity - number <= 0) {
      return dispatch(actions.orderBatch.deleteItem(item.key))
    }
    // 減少購物車內該商品的數量
    setDisplayQuantity(quantity - number)
    debouncedUpdateSelectedItem(quantity - number)
  }

  const debouncedUpdateSelectedItem = React.useMemo(
    () => _.debounce((quantity) => {
      dispatch(actions.orderBatch.updateSelectedItem(item.key, 'quantity', quantity))
    }, 250)
    , [item.key])

  return (
    <StepperBase
      quantity={displayQuantity}
      min={menu?.min}
      max={menu?.max}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
    />
  )
}
