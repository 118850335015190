import { Button, Slide, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'

import { actions, useDispatch } from '@/redux'
import appConstants from '@/constants/app'
import customerApi from '@/libs/api/customer'

import LoginPage from './LoginPage'
import Next from './Next'
import PincodeInput from './PincodeInput'

const snackerOption = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  TransitionComponent: Slide,
}

export default function StepPincode (props) {
  const { t } = useTranslation()
  const { doPrev, doNext, country, phone, countdown, setCountdown } = props

  const dispatch = useDispatch()
  const classes = useStyles(props)

  const [isLoading, setIsLoading] = useState(false)
  const [pincode, setPincode] = useState('')
  const [error, setError] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  useEffect(() => {
    if (countdown === 0) {
      sendPincode()
    }
  }, [])

  const sendPincode = useCallback(async () => {
    setCountdown(appConstants.PINCODE_COUNT_DOWN_LIMIT)
    setIsLoading(true)

    try {
      await customerApi.sendOtpPin(`+${country}${phone}`)

      setPincode('')
      setError({})
      dispatch(actions.snackbar.enqueueSnackbar({
        message: t('app.component.drawer.login_drawer.verify_code.sended'),
        options: {
          ...snackerOption,
        },
      }))
    } catch (error) {
      const message = error.message.includes('429')
        ? t('app.component.drawer.login_drawer.verify_code.error.too_fast')
        : String(error)

      dispatch(actions.snackbar.enqueueSnackbar({
        message,
        options: {
          variant: 'error',
          ...snackerOption,
        },
      }))
    }

    setIsLoading(false)
  }, [country, phone])

  const disableSubmit = !pincode || pincode.length !== 6 || disabledSubmit

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    setDisabledSubmit(true)

    try {
      // * 登入或註冊新的帳號得到正式帳號的 token
      const { data: auth } = await customerApi.loginWithOtpPin(`+${country}${phone}`, pincode)
      await dispatch(actions.user.updateCustomerApiAuth(auth))

      // * 登入後 user 就從匿名登入換人了，如果原本有在點餐需要再 joinOrder
      await dispatch(actions.order.joinOrder())

      // * 執行一次 transformowner 轉移匿名帳號的訂單至正式帳號
      await dispatch(actions.user.transferAnonymousOrders())

      doNext()
    } catch (error) {
      setError({
        pincode: t('app.component.drawer.login_drawer.verify_code.error.code_invalid'),
      })
      setDisabledSubmit(false)
    } finally {
      setIsLoading(false)
    }
  }, [country, phone, pincode])

  return (
    <LoginPage>
      <div id='nav-bar'>
        <Button onClick={doPrev}>
          <Typography>{t('app.component.drawer.login_drawer.back')}</Typography>
        </Button>
        <Button onClick={sendPincode} disabled={countdown > 0}>
          <Typography style={{ color: countdown > 0 ? '#393939' : '#1a84db' }}>
            {countdown > 0 ? countdown : t('app.component.drawer.login_drawer.verify_code.resend')}
          </Typography>
        </Button>
      </div>
      <div id='title-group'>
        <Typography id='title'>{t('app.component.drawer.login_drawer.verify_code.title')}</Typography>
        <Typography id='description'>{t('app.component.drawer.login_drawer.verify_code.subtitle')}</Typography>
      </div>
      <div className={classes.form}>
        <PincodeInput
          value={pincode}
          onChange={val => {
            setPincode(val)
            setError({})
          }}
          error={Boolean(error.pincode)}
          helperText={error.pincode}
        />
      </div>
      <Next
        onClick={handleSubmit}
        disabled={disableSubmit}
        isLoading={isLoading}
      />
    </LoginPage>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 65,

    '& > *': {
      width: '100%',
    },
  },
  inputLine: {
    marginLeft: 10,
    minWidth: 2,
    minHeight: 40,
    backgroundColor: '#e0e0e0',
  },
}))
