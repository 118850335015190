import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CardList (props) {
  const classes = useStyles(props)
  const { children } = props

  return (
    <List className={classes.list}>
      {children}
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
}))
