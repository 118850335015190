import axios from 'axios'

import config from '@/config'

// eslint-disable-next-line no-unused-vars
import { IDimbox } from '@/redux/dimbox/Dimbox.d'

class DimboxApi {
  constructor (url) {
    this.token = undefined
    this.axios = axios.create({ baseURL: url || config.api.dimbox })
  }

  /**
   *
   * @param {Date} date
   * @returns {{
   * available: number
   * boxes: IDimbox[]
   * }}
   */
  async getDimboxes (date) {
    const response = await this.axios.get(`/c/info/${date}`)
    return response.data
  }

  /**
  *
  * @param {string} cabinetId
  * @param {string} orderId
  * @param {string} boxId
  * @param {'CUSTOMER_APP' | 'CUSTOMER_WEB'} from
  * @param {Date?} testTime
  * @returns
  */
  async openDimbox (cabinetId, orderId, boxId, from, testTime) {
    const response = await this.axios.post(`/c/${cabinetId}/${orderId}/${boxId}/open`, {
      params: {
        from, testTime,
      },
    })
    return response.data
  }
}

export default new DimboxApi()
