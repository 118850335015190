import { ButtonBase, Input, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import CardList from '@/components/CardList/CardList'
import CardListItem from '@/components/CardList/CardListItem'
import Header from '@/components/Header'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import customerApi from '@/libs/api/customer'
import history from '@/libs/history'

import BadgedAvatorButton from './BadgedAvatorButton'
import EditNameDrawer from './EditNameDrawer'

const listItemPrimaryText = { variant: 'caption', color: 'textPrimary' }
const listItemSecondaryText = { variant: 'body1', color: 'textPrimary' }
const imageSizeLimit = 2 * 1024 * 1024 // 2 MB

export default function MemberDetails (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const member = useSelector(state => state.user.member)
  const fileInputRef = React.useRef()
  const [nameDrawerOpen, setNameDrawerOpen] = React.useState(false)

  const mobileText = member?.mobile ? member?.mobile.substring(0, 4) + ' ' + member?.mobile.substring(4) : ''

  const handleFiles = async (files) => {
    if (files.length === 0) return

    const file = files[0]
    // 檢查檔案類型
    if (!file.type.startsWith('image/')) {
      dispatch(actions.snackbar.enqueueSnackbar({
        message: `${file.name} 非圖檔`,
        options: {
          variant: 'warning',
        },
      }))
      return
    }
    // 檢查檔案大小
    if (file.size > imageSizeLimit) {
      dispatch(actions.snackbar.enqueueSnackbar({
        message: `${file.name} 檔案大小超過 2 MB`,
        options: {
          variant: 'warning',
        },
      }))
      return
    }

    dispatch(actions.user.uploadCustomerAvatar(file))
    const uploadResponse = await customerApi.uploadCustomerAvatar(file)
    console.log('uploadResponse', uploadResponse)
  }

  return (
    <Page>
      <Header
        onBack={() => history.goBack()}
        title={t('app.page.member_details.title')}
        style={{ minHeight: 220 }}
      >
        <section className={classes.info}>
          <BadgedAvatorButton
            buttonProps={{
              onClick: () => fileInputRef.current.click(), // click hidden file input
            }}
          />
          <Input
            inputRef={fileInputRef}
            className={classes.hide}
            type='file'
            inputProps={{ multiple: false, accept: 'image/*' }}
            disabled={false}
            onChange={(event) => handleFiles(event.target.files)}
          />
        </section>
      </Header>

      <PageContainer className={classes.container}>
        <CardList>
          <CardListItem onClick={() => setNameDrawerOpen(true)}>
            <ListItemText
              primary={t('app.page.member_details.name.title')}
              secondary={member?.name}
              primaryTypographyProps={listItemPrimaryText}
              secondaryTypographyProps={listItemSecondaryText}
              classes={{ root: classes.listItemText, primary: classes.primary }}
            />
          </CardListItem>

          <CardListItem>
            <ListItemText
              primary={t('app.page.member_details.mobile.title')}
              secondary={mobileText}
              primaryTypographyProps={listItemPrimaryText}
              secondaryTypographyProps={listItemSecondaryText}
              classes={{ root: classes.listItemText, primary: classes.primary }}
            />
          </CardListItem>
        </CardList>

        <ButtonBase
          className={classes.deleteButton}
          onClick={() => {
            dispatch(actions.user.deleteCustomerAccount())
          }}
        >
          <Typography variant='body2'>
            刪除帳號
          </Typography>
        </ButtonBase>
        <EditNameDrawer
          drawerOpen={nameDrawerOpen}
          setDrawerOpen={setNameDrawerOpen}
          member={member}
        />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    overflow: 'hidden',
    marginBottom: constants.app.marginBottom.GENERAL,
  },
  info: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
  },
  listItemText: {
    margin: 0,
  },
  hide: {
    display: 'none',
  },
  primary: {
    color: theme.palette.grey[600],
    paddingBottom: 2,
  },
  deleteButton: {
    flexShrink: 0,
    alignSelf: 'center',
    margin: theme.spacing(1, 2),
    padding: theme.spacing(1, 2),
    color: theme.palette.error.main,
    borderRadius: theme.spacing(1),
  },
}))
