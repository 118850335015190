import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios' // eslint-disable-line no-unused-vars

import config from '@/config'
import i18n from '@/i18n'

/* eslint-disable no-unused-vars */
import { ILandingRestaurant } from '@/redux/landing/Landing.d'
import { LandingApiGetRestaurantRequestParams, LandingApiSpreadSheetResponseData } from '@/libs/api/LandingApi.d'
/* eslint-enable no-unused-vars */

export class LandingApi {
  /** @type {AxiosInstance} */
  axios
  /** @type {string} */
  locale

  constructor (url) {
    this.locale = i18n.language.slice(0, 2) // 'zh-TW' => 'zh'
    this.axios = axios.create({
      baseURL: url || config.api.landing,
    })

    this.axios.interceptors.request.use(
      config => {
        config.headers['Accept-Language'] = this.locale
        return config
      },
      error => error,
    )
  }

  /**
   * @param {string} locale
   */
  setLanguage (locale) {
    this.locale = locale
  }

  /**
   * @returns {Promise<LandingApiSpreadSheetResponseData>}
   */
  fetchSpreadSheet = async () => {
    /** @type {AxiosResponse<LandingApiSpreadSheetResponseData>} */
    const { data } = await this.axios.get('/c/app')
    return data
  }

  /**
   * @param {LandingApiGetRestaurantRequestParams} params
   * @returns {Promise<ILandingRestaurant[]>}
   */
  fetchRestaurants = async (params) => {
    /** @type {AxiosResponse<ResponseData<ILandingRestaurant[]>>} */
    const { data } = await this.axios.get('/c/merchant/landing', { params })
    return data?.data
  }

  // 分類列表
  fetchCategories = async (params) => {
    const { data } = await this.axios.get('/c/search', { params })
    return data?.data
  }

  // 菜式列表
  fetchCuisines = async (params) => {
    const { data } = await this.axios.get('/c/cuisines', { params })
    return data?.data
  }

  // 搜尋餐廳
   searchRestaurants = async (params) => {
     const { data } = await this.axios.get('/c/search', { params })
     return data?.data
   }

   // 搜尋菜式餐廳
   searchCuisineRestaurants = async (payload, params) => {
     const cuisinePaylaod = encodeURIComponent(payload)
     const { data } = await this.axios.get(`/c/cuisines/${cuisinePaylaod}`, { params })
     return data?.data
   }
}

export default new LandingApi()
