import { Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import LocationMarkIcon from '@/assets/icons/location_mark.svg'

import AddressItemAdd from './AddressItemAdd'
import AddressItemBase from './AddressItemBase'
import AddressItemCurrentPosition from './AddressItemCurrentPosition'

/**
 *
 * @param {{
 * address: IAddress
 * selected: Boolean
 * variant?: 'add' | 'currentPosition'
 * }} props
 * @returns
 */
export default function AddressItem (props) {
  const { address, selected, variant } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const history = useHistory()
  const handleSelect = () => dispatch(actions.user.selectAddress(address))
  const handleClick = () => {
    dispatch(actions.user.selectAddress(address))
    dispatch(actions.user.addAddressTemp(address))
    history.push('/settings/address/edit')
  }

  const rightAction = (
    <Radio
      color='primary'
      classes={{ root: classes['Radio-root'] }}
      checked={selected}
      onClick={handleSelect}
    />
  )

  switch (variant) {
    case 'add': {
      return <AddressItemAdd />
    }
    case 'currentPosition': {
      return <AddressItemCurrentPosition />
    }
    default: {
      return (
        <AddressItemBase
          icon={<img src={LocationMarkIcon} alt='location_mark_icon' />}
          title={address?.name}
          subtitle={address?.address}
          rightAction={rightAction}
          selected={selected}
          onClick={handleClick}
        />
      )
    }
  }
}

const useStyles = makeStyles(theme => ({
  'Radio-root': {
    color: theme.palette.grey[300],
  },
}))
