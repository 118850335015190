import PropTypes from 'prop-types'
import React from 'react'

import SetItemStepper from '@/components/Groups/GroupItem/rightActions/SetItemStepper'

import GroupItemBase from './GroupItemBase'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'

GroupItemStepper.propTypes = {
  item: PropTypes.object,
  disabled: PropTypes.bool,
}

/**
 *
 * @param {{
 * item: IMenuOptionItem
 * disabled: boolean
 * disableIncrement?: boolean
 * }} props
 */
export default function GroupItemStepper (props) {
  const { item, disabled, disableIncrement } = props
  const stepper = <SetItemStepper item={item} disableIncrement={disabled || disableIncrement} disableDecrement={disabled} />

  return (
    <GroupItemBase variant='stepper' item={item} disabled={disabled} rightAction={stepper} />
  )
}
