import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import _ from 'lodash'

import { useMerchant, useAddress as useMerchantAddress } from '@/hooks/merchant'
import { useSelectedOrder } from '@/hooks/order'
import { useTable } from '@/hooks/app'
import Card from '@/components/Cards/Card'

/**
 *
 * @param {*} props
 * @returns
 */
export default function TableCard (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const merchant = useMerchant()
  const merchantAddress = useMerchantAddress()
  const table = useTable()
  const tableGroup = useMemo(() => _.findKey(merchant?.tableGroups, group => _.includes(group, table)), [merchant?.tableGroups, table])
  const tableString = [tableGroup, table].filter(o => o).join('/')
  const orderSerial = useSelectedOrder()?.serial

  return (
    <Card className={classes.tableCard}>
      <div>
        <Typography variant='h6' className={classes.merchantName}>{merchant?.name}</Typography>
        <div className={classes.row}>
          <Typography variant='caption' className={classes.addressTitle}>{t('app.page.checkout.info_card.store_address')}</Typography>
          <Typography variant='caption' className={classes.addressValue}>{merchantAddress}</Typography>
        </div>
      </div>
      <div className={classes.tableWrap}>
        <div className={classes.row}>
          <Typography variant='body1' className={classes.tableTitle}>{t('app.constant.table.table_number')}</Typography>
          <Typography variant='body1' color='secondary' className={classes.tableValue}>{tableString}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant='body1' className={classes.tableTitle}>{t('app.constant.order.order_number')}</Typography>
          <Typography variant='body1' color='secondary' className={classes.tableValue}>{orderSerial}</Typography>
        </div>
      </div>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  tableCard: {
    padding: theme.spacing(2),
  },
  merchantName: {

  },
  row: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  tableWrap: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  tableTitle: {
    fontWeight: 600,
  },
  tableValue: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  addressTitle: {
    flexShrink: 0,
  },
  addressValue: {
    color: theme.palette.info.main,
    marginLeft: theme.spacing(1),
  },
}))
