import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import constants from '@/constants'

import BackButton from './BackButton'

/**
 *
 * @param {{
 * title?: String
 * className?: String
 * onBack?: Function
 * hideBackButton?: boolean
 * color?: String
 * background?: String
 * backgroundHeight?: Number | String
 * leftColumn?: Node
 * centerColumn?: Node
 * rightColumn?: Node
 * innerRef?: object
 * backButton?: Node
 * }} props
 */
export default function Navbar (props) {
  const {
    title,
    className,
    onBack,
    hideBackButton,
    leftColumn,
    centerColumn,
    rightColumn,
    innerRef,
    backButton,
    color,
  } = props
  const classes = useStyles(props)

  return (
    <nav ref={innerRef} className={clsx(classes.navbar, className)}>
      <section className={classes.section}>
        {!hideBackButton && (backButton || <BackButton color={color} onBack={onBack} />)}
        {leftColumn}
      </section>
      <section className={clsx(classes.section, classes.center)}>
        {centerColumn || <Typography className={classes.title}>{title}</Typography>}
      </section>
      <section className={clsx(classes.section, classes.right)}>
        {rightColumn}
      </section>
    </nav>
  )
}

const useStyles = makeStyles(theme => ({
  navbar: {
    zIndex: constants.app.zIndex.NAVBAR,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    width: '100%',
    height: constants.app.marginTop.NAVBAR,
    padding: theme.spacing(0, 2),
    paddingTop: constants.app.safeArea.top,
    color: props => props.color ?? theme.palette.common.black,
    alignItems: 'center',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      width: '100%',
      height: props => props.backgroundHeight ?? '100%',
      backgroundImage: props => props.background ?? `linear-gradient(45deg, ${theme.palette.primary.customDark} 0%, ${theme.palette.primary.customLight} 100%)`,
      opacity: props => {
        if (_.isBoolean(props.sticky)) {
          return props.sticky ? 1 : 0
        }
      },
      // transition: 'opacity 500ms ease',
    },
  },
  section: {
    position: 'relative',
    minWidth: 40,
    display: 'flex',
  },
  center: {
    flexGrow: 1,
  },
  right: {
    justifyContent: 'flex-end',
  },
  title: {
    flex: 1,
    flexShrink: 0,
    fontSize: '1.8rem',
    textAlign: 'center',
  },
}))
