import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { isSetItemComplete } from '@/libs/order'
import { useBatchItemTemp } from '@/hooks/batchItemTemp'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import { useMerchant } from '@/hooks/merchant'
import constants from '@/constants'
import useTrack from '@/hooks/useTrack'

import AddToCartButton from '@/components/Drawers/common/AddToCartButton'
import DrawerContent from '@/components/Drawers/common/DrawerContent'
import MenuItem from '@/components/Drawers/common/MenuItem'
import MenuItemStepper from '@/components/Stepper/MenuItemStepper'
import StepGroup from '@/components/Groups/StepGroup'
import SwipeableDrawer from '@/components/SwipeableDrawer'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SetItemDrawer (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const merchant = useMerchant()
  const open = useSelector(state => state.app.drawers.setItem.open)
  const openDrawer = () => dispatch(actions.app.toggleDrawer('setItem', true))
  const closeDrawer = () => dispatch(actions.app.toggleDrawer('setItem', false))
  const resetDrawerData = () => {
    setTimeout(() => {
      dispatch(actions.app.toggleDrawer('setItem', false, {}))
      dispatch(actions.batchItemTemp.resetItem())
      // 200ms delay for drawer close transition duration
    }, 200)
  }
  const handleClose = () => {
    closeDrawer()
    resetDrawerData()
  }

  const batchItem = useSelector(state => state.app.drawers.setItem.data.batchItem) // 如果有 batchItem 代表在 /checkout 編輯
  const menuQuery = useMenuQuery()
  const setMenus = menuQuery.data?.sets
  const setItem = useSelector(state => state.app.drawers.setItem.data.setMenu) // 如果有 setMenu 代表在 /restaurant 點餐
  const setMenu = (
    !_.isEmpty(batchItem) && setMenus
      ? setMenus[batchItem?.menuId]
      : setItem
  ) ?? {}

  const separatedSetItemMenu = useSelector(state => state.app.drawers.setItem.data.setItemMenu) // 如果有 setItemMenu 代表點開了根據特定步驟拆開的套餐的其中一個 setItem
  const separatedStep = setMenu.separatedStep

  const { sendEvent } = useTrack()
  const batchItemTemp = useBatchItemTemp()
  // const remark = batchItemTemp.remarks ? (batchItemTemp.remarks[0] ?? '') : ''
  // const handleRemarkChange = (e) => dispatch(actions.batchItemTemp.updateItem(['remarks'], [e.target.value]))

  const isComplete = open && isSetItemComplete(batchItemTemp, setMenu)
  const handleAddItem = () => dispatch(actions.orderBatch.addItem(batchItemTemp))
  const handleReplaceItem = () => dispatch(actions.orderBatch.replaceItem(batchItemTemp))
  const handleSubmit = () => {
    handleClose()
    // 有給 batchItem 代表在 checkout 編輯，所以編輯完後要取代原有的 batchItem
    if (!_.isEmpty(batchItem)) {
      return handleReplaceItem()
    }
    handleAddItem()

    sendEvent(constants.track.EVENT.ADD_TO_CART, {
      item: batchItemTemp,
      merchant,
    })
  }

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={handleClose}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <DrawerContent>
        <header>
          <MenuItem menu={setMenu} setItemMenu={separatedSetItemMenu} />
        </header>
        <ul className={classes.groupList}>
          {_.map(setMenu?.steps, (step, index) => {
            if (step.id === separatedStep) {
              // 不顯示已經被拆開成獨立套餐的步驟
              return null
            }
            return (
              <StepGroup
                key={index}
                group={step}
                menu={setMenu}
              />
            )
          })}
          {/* 套餐不需要有remark */}
          {/* <RemarkInput value={remark} onChange={handleRemarkChange} /> */}
        </ul>
        <footer className={classes.footer}>
          <MenuItemStepper />
          <AddToCartButton
            disabled={!isComplete}
            onClick={handleSubmit}
          />
        </footer>
      </DrawerContent>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(3, 2),
    paddingBottom: `calc(${theme.spacing(3)}px + ${constants.app.safeArea.bottom})`,
  },
  groupList: {
    display: 'grid',
    gridGap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    overflowY: 'auto',
  },
  footer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
  },
}))
