import opacity from '@/theme/opacity'

export default {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  divider: '#c2c2c2',
  disabled: {
    main: '#e0e0e0',
    contrastText: '#9e9e9e',
  },
  error: {
    main: '#c54545',
  },
  success: {
    main: '#4caf50',
  },
  warning: {
    main: '#d5ad40',
  },
  info: {
    main: '#1a84db',
  },
  primary: {
    main: '#ffaf46',
    dark: '#ffa000',
    light: '#ffc400',
    contrastText: '#ffffff',
    contrastTextHighlight: '#f2d40d',
  },
  secondary: {
    main: '#f5f5f5',
    contrastText: '#71544f',
    contrastTextHighlight: '#6b514d',
  },
  text: {
    primary: {
      main: '#393939',
      light: '#494949',
      dark: '#292929',
    },
    secondary: {
      main: '#757575',
      light: '#9e9e9e',
      dark: '#616161',
    },
  },
  components: {
    stepper: {
      main: '#4a8ca1',
      disabled: `#4a8ca1${opacity[30]}`,
    },
    label: {
      item: '#ffe69b',
      time: '#565656',
      status: '#f19737',
    },
    button: {
      blue: {
        primary: {
          main: '#70d6ff',
          contrastText: '#5f5f5f',
        },
        secondary: {
          main: '#f0f0f0',
          contrastText: '#5f5f5f',
        },
      },
    },
  },
}
