import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { getStepQuantityMax, getStepQuantityMin } from '@/libs/order'
import GroupHeader from '@/components/Groups/GroupHeader'

// eslint-disable-next-line no-unused-vars
import { IAppSetStep } from '@/redux/menu/MenuState.d'

/**
 * @typedef StepGroupHeaderProps
 * @property {IAppSetStep} step
 * @param {StepGroupHeaderProps} props
 * @returns
 */
export default function StepGroupHeader (props) {
  const { step } = props
  const { t } = useTranslation()
  const max = getStepQuantityMax(step)
  const min = getStepQuantityMin(step)

  const renderMaxHint = () => {
    if (max > 1) {
      return <Typography component='span' variant='body2'>{t('app.component.group.option.max', { max: max })}</Typography>
    }
    return <Typography component='span' variant='body2'>{t('app.component.group.option.min1max1')}</Typography>
  }

  const renderMinHint = () => {
    if (min > 1) {
      return <Typography component='span' variant='body2'>{t('app.component.group.option.min', { min: min })}</Typography>
    }
    if (min === 0) {
      return <Typography component='span' variant='body2'>{t('app.component.group.option.min0')}</Typography>
    }
  }

  const renderMinMaxHint = () => {
    return (
      <>
        {renderMaxHint()}
        {renderMinHint()}
      </>
    )
  }

  return (
    <GroupHeader title={step.name} hint={renderMinMaxHint()} />
  )
}
