const merchants = {
  WING3: '3',
  KEVIN: '4',
  BBQ: '73e9c488-3dde-43b7-93b5-49666a123bb2',
  YAKINIKU: 'd7e2797b-a255-4f19-90a8-76760aea37c1',
  SOUL_CAFE: 'f2223a92-e7de-4652-aa8b-078399951dfb',
  明輝茶餐廳: '71cb02c8-fc54-46c2-9707-0fa5170aa1b4',
  安安燒: 'eeb0d8b8-fc53-4a42-b428-a108d8c09e14',
  西撚: '460bcc15-c4be-4d60-88ab-c0f0ff32eedf',
  中撚: 'f0c65ca6-2e24-4809-b461-cdb0d75a1b2a',
  西楚: 'c2dd2b19-ca0a-418d-9463-cd7bec4e8e7d',
  土楚: 'ebb6fa2e-8e2c-438d-b949-7cd5e09cdf77',
  旺撚: '1c3c142b-8cd0-4d1c-88f5-f3b462de60d5',
  荃楚: '6ad05255-c428-4b9d-a45d-02de48c3b9b5',
  WOFT: 'e27bf61e-3e4f-41d3-83f4-54a5c4da59c7',
  HKIA_VIP_LOUNGE_HK14171: '19c23fe9-c5df-4f6f-959e-33656274676f', // shortCode: 'hk14171'
  HKIA_VIP_LOUNGE_HK14695: '038fb9c7-0774-41e4-88b9-0aaeff0afe26', // shortCode: 'hk14695'
  辣媽: 'ae923bd6-0f12-4767-9e46-1202b54d7705',
}

/**
 * 機場貴賓室
 */
export const airportVipLounges = [
  merchants.HKIA_VIP_LOUNGE_HK14171,
  merchants.HKIA_VIP_LOUNGE_HK14695,
]

/**
 * 取得一些目前需使用 hardcode 的餐廳
 * @param {'hotpotland'} key
 * @returns {string[]}
 */
export const getMerchants = (key) => {
  switch (key) {
    case 'hotpotland':
      return [
        merchants.WING3,
        merchants.安安燒,
        merchants.西撚,
        merchants.中撚,
        merchants.西楚,
        merchants.土楚,
        merchants.旺撚,
        merchants.荃楚,
        merchants.WOFT,
      ]
    default:
      return []
  }
}

export default merchants
