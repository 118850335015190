const batchStorageMiddleware = store => next => action => {
  const result = next(action)

  /** @type {IRootState} */
  const nextState = store.getState()

  if (nextState.orderBatch.selectedBatch) {
    // 儲存 merchantId 和 batch
    localStorage.setItem('batchStorage', JSON.stringify({
      merchantId: nextState.order.selectedOrder?.merchantId,
      batch: nextState.orderBatch.selectedBatch,
    }))
  }

  return result
}

export default batchStorageMiddleware
