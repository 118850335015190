import { makeStyles } from '@material-ui/core/styles'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import React from 'react'

export default function DriverMarker (props) {
  const classes = useStyles(props)

  return (
    <div className={classes.driverMarkerWrap}>
      <PersonPinCircleIcon className={classes.driverMarker} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  '@keyframes ripple': {
    '0%': {
      opacity: 0,
      transform: 'scale3d(0.6, 0.3, 1)',
    },
    '50%': {
      opacity: 0.5,
      transform: 'scale3d(0.8, 0.4, 1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale3d(1, 0.5, 1)',
    },
  },
  driverMarkerWrap: {
    position: 'relative',
    transform: 'translate(-50%, -50%)',
    width: '4.5rem',
    height: '7rem',
    display: 'grid',
    placeContent: 'center',
    '&:before': {
      content: '""',
      zIndex: -1,
      position: 'absolute',
      bottom: 0,
      left: -4,
      width: '100%',
      height: '50%',
      border: '4px solid red',
      borderRadius: '50%',
      opacity: 0,
      animationName: '$ripple',
      animationDuration: '3s', // $duration
      animationDelay: '0s', // $delay
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
    '&:after': {
      content: '""',
      zIndex: -1,
      position: 'absolute',
      bottom: 0,
      left: -4,
      width: '100%',
      height: '50%',
      border: '4px solid red',
      borderRadius: '50%',
      opacity: 0,
      animationName: '$ripple',
      animationDuration: '3s',
      animationDelay: '1.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
  },
  driverMarker: {
    color: theme.palette.error.main,
    width: '4rem',
    height: '4rem',
  },
}))
