import { ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

// eslint-disable-next-line no-unused-vars
import { IAlertConfig } from '@/redux/app/AppState.d'
/**
 * @param {{
 * open?: boolean,
 * onClose: function,
 * alertConfig: IAlertConfig
 * }} props
 */
export default function Alert (props) {
  const { open = true, onClose, alertConfig } = props
  const {
    title,
    message,
    messages,
    renderMessage,
    messageAlign = 'center',
    messagesSize = '1.6rem',
    onConfirm,
    header,
    children,
    button,
    buttons,
    buttonsDirection = 'row',
  } = alertConfig
  let dialogProps = alertConfig?.dialogProps ?? {}

  const classes = useStyles({ ...props, messageAlign, messagesSize, buttonsDirection })
  const { t } = useTranslation()

  if (
    typeof onConfirm === 'function' || // 有設定 onConfirm，按鈕有作用
    typeof button?.onClick === 'function' || // 有設定 button.onClick，按鈕有作用
    buttons?.length > 1 || // 設定兩個以上按鈕，按鈕有作用
    typeof buttons?.[0]?.onClick === 'function' // 只設定一個按鈕，但有設定 onClick 按鈕有作用
  ) {
    dialogProps = produce(dialogProps, (dialogProps) => {
      // 當按鈕有作用時，預設 dialogProps: { disableBackdropClick: true } 避免 click backdrop 關閉 alert 而跳過按鈕的處理
      dialogProps.disableBackdropClick = dialogProps.disableBackdropClick ?? true
    })
  }

  const renderButtons = () => {
    if (button) {
      const { text, className, style, color, onClick, disabled, loading } = button
      return (
        <ButtonBase
          style={{ ...style, ...color && { color } }}
          className={clsx(classes.actionButton, classes['actionButton-contained'], className)}
          disabled={disabled || loading}
          onClick={() => {
            onClick && onClick()
            onClose()
          }}
        >
          {loading ? <CircularProgress /> : text}
        </ButtonBase>
      )
    }

    if (buttons) {
      return _.map(buttons, (button, index) => {
        const { text, className, style, color, onClick, disabled, loading } = button
        return (
          <ButtonBase
            key={index}
            style={{ ...style, ...color && { color } }}
            className={clsx(classes.actionButton, className)}
            disabled={disabled || loading}
            onClick={() => {
              onClick && onClick()
              onClose()
            }}
          >
            {loading ? <CircularProgress /> : text}
          </ButtonBase>
        )
      })
    }

    if (onConfirm) {
      const handleConfirm = () => {
        onConfirm()
        onClose()
      }
      return (
        <>
          <ButtonBase
            className={classes.actionButton}
            onClick={onClose}
          >{t('app.common.cancel')}
          </ButtonBase>
          <ButtonBase
            className={clsx(classes.actionButton, classes['actionButton-contained'])}
            onClick={handleConfirm}
          >{t('app.common.confirm')}
          </ButtonBase>
        </>
      )
    }

    return (
      <ButtonBase
        className={clsx(classes.actionButton, classes['actionButton-contained'])}
        onClick={onClose}
      >{t('app.common.back')}
      </ButtonBase>
    )
  }

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose} {...dialogProps}>
      {header}
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant='h5' className={classes.titleText}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.message}>
        <Typography className={classes.messageText}>
          {
            renderMessage
              ? renderMessage()
              : messages
                ? _.map(messages, (line, index) => <span className={classes.messageLine} key={index}>{line}</span>)
                : message
          }
        </Typography>
        {children}
      </DialogContent>

      <DialogActions className={classes.actionsContainer} disableSpacing>
        {renderButtons()}
      </DialogActions>

    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 320,
    borderRadius: theme.shape.borderRadius * 2,
  },
  title: {
    minWidth: 260,
    padding: theme.spacing(0.5, 3),
    marginTop: theme.spacing(3),
  },
  titleText: {
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  message: {
    padding: theme.spacing(1, 3),
  },
  messageText: {
    fontSize: props => props.messagesSize,
    color: props => props.title ? theme.palette.grey[600] : theme.palette.common.black,
    textAlign: props => props.messageAlign,
  },
  messageLine: {
    display: 'block',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: props => props.buttonsDirection,
    padding: theme.spacing(2),
  },
  actionButton: {
    flex: 1,
    position: 'relative',
    padding: theme.spacing(1, 2),
    minHeight: 40,
    minWidth: 120,
    fontSize: '1.6rem',
    color: theme.palette.common.black,
    borderRadius: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(1),
    alignSelf: props => props.buttonsDirection ? 'stretch' : 'center',
  },
  'actionButton-contained': {
    backgroundColor: theme.palette.primary.main,
  },
}))
