import { IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import Card from '@/components/Cards/Card'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import PageDrawer from '@/components/Drawers/common/PageDrawer'
import React from 'react'
import validateUserName from '@root/src/libs/validateUserName'

export default function EditNameDrawer (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { drawerOpen, setDrawerOpen, member } = props
  const openDrawer = () => setDrawerOpen(true)
  const closeDrawer = () => setDrawerOpen(false)
  const [name, setName] = React.useState('')
  const [nameError, setNameError] = React.useState('')

  React.useEffect(() => {
    if (member?.name) {
      setName(member.name)
    }
  }, [member?.name, drawerOpen])

  const clearInput = () => {
    setName((''))
    setNameError('')
  }

  const handleCloseDrawer = (event) => {
    clearInput()
    closeDrawer()
  }

  const handleChange = (event) => {
    setName(event.target.value)
  }

  const handleSubmit = () => {
    // 去除頭尾空格、將連續的空格取代成單一空格
    const trimedName = name.trim().replace(/ +(?= )/g, '')
    const { isValid, error } = validateUserName(trimedName)
    if (!isValid) {
      setNameError(error)
      return
    }
    dispatch(actions.user.updateCustomerInfo({ name: trimedName }))
    handleCloseDrawer()
  }

  return (
    <PageDrawer
      title={t('app.page.member_details.name.title')}
      open={drawerOpen}
      openDrawer={openDrawer}
      closeDrawer={handleCloseDrawer}
    >
      <Card className={classes.card}>
        <form className={classes.form} noValidate autoComplete='off'>
          <Typography variant='caption' color='textPrimary'>
            {t('app.page.member_details.name.inputlabel')}
          </Typography>
          <TextField
            required
            variant='outlined'
            size='small'
            placeholder={t('app.page.member_details.name.placeHolder')}
            value={name}
            onChange={handleChange}
            error={Boolean(nameError)}
            helperText={nameError}
            inputProps={{
              maxLength: 20,
            }}
            InputProps={{
              endAdornment: (
                name && (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={clearInput}
                      classes={{ root: classes.iconButton }}
                    >
                      <CancelOutlinedIcon fontSize='small' className={classes.icon} />
                    </IconButton>
                  </InputAdornment>)
              ),
            }}
          />
          <LetterSpacingButton
            onClick={handleSubmit}
          >
            <Typography>
              {t('app.page.member_details.name.submit')}
            </Typography>
          </LetterSpacingButton>
        </form>
      </Card>

    </PageDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '100%',
    padding: 0,
  },
  card: {
    height: '50%',
    padding: theme.spacing(1.5),
  },
  form: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    gap: theme.spacing(1),
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    color: theme.palette.grey[400],
  },
}))
