import { ButtonBase, Typography } from '@material-ui/core'
import { validate as isUUID } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useDeliveryType } from '@/hooks/app'

import RowBase from './RowBase'

/**
 *
 * @param {{
 * code: string
 * value: number
 * readonly?: boolean
 * }} props
 * @returns
 */
export default function PromoCodeRow (props) {
  const { name, code, value, readOnly, isLoading } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { isTable } = useDeliveryType()
  const classes = useStyles({ ...props, value })
  const payFirst = useSelector(state => state.merchant?.data?.setting?.payFirst)

  // 優先顯示 name 再來是非 UUID 的 code，最後顯示優惠券
  const text = name || (isUUID(code) ? t('app.component.card.payment_summary_card.coupon') : code)

  const renderFiled = () => {
    if (code) {
      return (
        <Typography
          component='span'
          variant='body2'
          className={classes.fill_promo}
          onClick={() => dispatch(actions.order.deletePromoCode())}
        >
          {text}
          <HighlightOffIcon fontSize='small' className={classes.delete_icon} />
        </Typography>
      )
    }

    return (
      <ButtonBase>
        <Typography
          component='span'
          variant='body2'
          className={classes.not_fill_promo}
          onClick={() => dispatch(actions.app.toggleDialog('promoCode'))}
        >
          {t('app.component.card.payment_summary_card.add_promo_code')}
        </Typography>
      </ButtonBase>
    )
  }

  if (
    !readOnly &&
    isTable &&
    !pathname.includes('/pay') && // 在 /checkout 的時候如果是內用則不顯示 promoCode row
    !payFirst // payFirst 顯示
  ) {
    return null
  }

  // if (!readOnly && isPoonchoi) {
  // 在 /checkout 的時候如果是盆菜活動則不顯示 promoCode row
  //   return null
  // }

  return (
    <RowBase
      field={readOnly ? text : undefined}
      fieldColumn={readOnly ? undefined : renderFiled()}
      value={value}
      isLoading={isLoading}
    />
  )
}

const useStyles = makeStyles(theme => ({
  not_fill_promo: {
    border: '1px solid #ffc42a',
    backgroundColor: '#fff1cb',
    padding: theme.spacing(0.5, 1),
    borderRadius: '6px',
    position: 'relative',
  },
  fill_promo: {
    display: 'flex',
    alignItems: 'center',
  },
  delete_icon: {
    marginLeft: theme.spacing(0.5),
    color: 'red',
    fontSize: '1.6rem',
  },
  value: {
    '&:before': {
      content: props => props.value != null ? '"HK$"' : '""',
      marginRight: theme.spacing(0.5),
    },
  },
}))
