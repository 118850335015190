export default {
  INIT: 'PAYMENT/INIT',
  UPDATE_PAYMENT_METHOD: 'PAYMENT/UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_GATEWAY: 'PAYMENT/UPDATE_PAYMENT_GATEWAY',
  UPDATE_SAVE_PAYMENT: 'PAYMENT/UPDATE_SAVE_PAYMENT',
  UPDATE_PAYING_OUTSIDE: 'PAYMENT/UPDATE_PAYING_OUTSIDE',
  RESET_PAYMENT: 'PAYMENT/RESET_PAYMENT',
  UPDATE_PAYMENT_QRCODE: 'UPDATE_PAYMENT_QRCODE',
  UPDATE_PAYMENT_QRCODE_STATUS: 'UPDATE_PAYMENT_QRCODE_STATUS',
  UPDATE_2C2P_TOKEN: 'PAYMENT/UPDATE_2C2P_TOKEN',
  UPDATE_2C2P_3DS_URL: 'PAYMENT/UPDATE_2C2P_3DS_URL',
}
