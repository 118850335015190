import { Table, TableBody } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import React from 'react'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'

import { useSelector } from '@/redux'
import Card from '@/components/Cards/Card'
import orderBatchStatus from '@/constants/orderBatchStatus'
import useDisableD2CUIPrice from '@/hooks/useDisableD2CUIPrice'

import { useDeliveryType, useParams } from '@/hooks/app'
import DividerRow from './row/DividerRow'
import ModifierList from './ModifierList'
import ShippingFeeRow from './row/ShippingFeeRow'
import SubtotalRow from './row/SubtotalRow'
import TotalRow from './row/TotalRow'
import _ from 'lodash'

// eslint-disable-next-line no-unused-vars
import { IAppOrder, IAppOrderBatch } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @typedef PaymentSummaryCard
 * @property {IAppOrder} order
 * @property {IAppOrderBatch[]} batches
 * @property {boolean} [stepper]
 * @property {boolean} [isHistory]
 * @property {object} [customClasses]
 */

/**
 *
 * @param {PaymentSummaryCardProps} props
 * @returns
 */
export default function PaymentSummaryCard (props) {
  const {
    order,
    batches = [],
    stepper,
    isHistory = false,
    customClasses,
  } = props

  const { pathname } = useLocation()
  const params = useParams()
  const { isTable } = useDeliveryType()
  const shipping = useSelector(state => state.order.shipping)
  const isPromoCodeLoading = useSelector(state => state.order.isApplyPromoCodeLoading || state.order.isAutoFillPromoCodeLoading || state.order.isDeletePromoCodeLoading)
  const payFirst = useSelector(state => state.merchant?.data?.setting?.payFirst)
  const disableD2CUIPrice = useDisableD2CUIPrice()

  const batchTotalPrice = React.useMemo(() => {
    return batches?.reduce((acc, batch) => {
      return acc + batch?.items?.reduce((acc, batchItem) => {
        const item = stepper ? orderappLibs.calculateLocalBatchItem(batchItem, order) : batchItem
        return acc + item.total
      }, 0)
    }, 0)
  }, batches)

  const shippingFee = React.useMemo(() => {
    if (_.has(order, ['shipping', 'baseShippingFee']) && order?.shipping?.baseShippingFee > 0) {
      // * 後端回來的 order（有 order.shipping.baseShippingFee 表示這 order 是從後端 response，直接用 order.shipping 即可）
      return {
        baseShippingFee: order?.shipping?.baseShippingFee,
        tunnelFee: order?.shipping?.tunnelFee,
        smallOrderFee: order?.shipping?.smallOrderFee,
        longDistanceFee: order?.shipping?.longDistanceFee,
        shippingDiscount: shipping?.shippingDiscount,
      }
    }

    return {
      baseShippingFee: shipping?.baseShippingFee,
      tunnelFee: shipping?.tunnelFee,
      smallOrderFee: shipping?.smallOrderFee,
      longDistanceFee: shipping?.longDistanceFee,
      shippingDiscount: shipping?.shippingDiscount,
    }
  }, [order?.shipping, shipping])

  const total = isHistory
    ? order.roundedTotal ?? order.total ?? 0 // history 的情況，ricecoin 已被計算到訂單中，直接使用 roundedTotal。fallback total 不知道還有沒有用，現在 history 應該都會有 roundedTotal
    : order.roundedTotalWithRiceCoinDiscount ?? order.roundedTotal ?? 0 // 非 history，（未submit）ricecoin 是在本地計算，所以要使用 roundedTotalWithRiceCoinDiscount 來顯示經過 ricecoin 折扣後的金額

  const renderContent = () => {
    if (
      (isTable && pathname.includes('/pay')) ||
      (isTable && payFirst)
    ) {
      // 內用且正在付款，顯示 ModifierList
      return (
        <TableBody>
          <SubtotalRow value={Number(((order.originalSubtotal ?? 0) - (order.discountTotal ?? 0)).toFixed(1))} />
          {/* FIXME: dinein應該沒有shippingFee? */}
          <ShippingFeeRow
            {...shippingFee}
            deliveryType={order.deliveryType || params.deliveryType}
          />
          <ModifierList modifiers={order.modifiers ?? []} readOnly={isHistory} />
          <DividerRow />
          <TotalRow value={total} isLoading={isPromoCodeLoading} />
        </TableBody>
      )
    }

    // 內用且正在付款，顯示 ModifierList 以外使用原本的邏輯，未修改
    return isTable && stepper
      ? (
        <TableBody>
          {/* 堂食加點時只計算加點金額 */}
          <SubtotalRow value={Number(batchTotalPrice.toFixed(1))} />
          <DividerRow />
          <TotalRow value={Number(batchTotalPrice.toFixed(1))} />
        </TableBody>
      )
      : order.status !== orderBatchStatus.CANCELLED
        ? (
          <TableBody>
            <SubtotalRow value={Number(((order.originalSubtotal ?? 0) - (order.discountTotal ?? 0)).toFixed(1))} />
            <ShippingFeeRow
              {...shippingFee}
              deliveryType={order.deliveryType || params.deliveryType}
            />
            <ModifierList modifiers={order.modifiers ?? []} readOnly={isHistory} />
            <DividerRow />
            <TotalRow value={total} />
          </TableBody>
        )
        : (
          <TableBody>
            <SubtotalRow value={0} />
            <ShippingFeeRow deliveryType={order.deliveryType || params.deliveryType} />
            <DividerRow />
            <TotalRow value={0} />
          </TableBody>
        )
  }

  if (disableD2CUIPrice) {
    return null
  }

  return (
    <Card className={customClasses?.card ?? ''}>
      <Table>
        {renderContent()}
      </Table>
    </Card>
  )
}
