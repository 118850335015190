/**
 * @typedef GetTokenParams
 * @property {boolean} [anonymous]
 *
 * @param {GetTokenParams} params
 * @returns {string | undefined }
 */
export default function getToken (params = {}) {
  const { anonymous } = params
  const authStorageKey = anonymous ? 'anonymousAuth' : 'customerAuth'
  const tokenStorageKey = anonymous ? 'token' : 'accessToken'
  try {
    const authJson = localStorage.getItem(authStorageKey)
    if (authJson) {
      const auth = JSON.parse(authJson) ?? {}
      return auth[tokenStorageKey]
    }
  } catch (error) {
    console.error(`getToken error, anonymous: ${anonymous}`, error)
  }
}
