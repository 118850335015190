import { Capacitor } from '@capacitor/core'
import { SyncStatus } from 'capacitor-codepush/dist/esm/syncStatus'
import _ from 'lodash'

const platform = Capacitor.getPlatform()
export const platformDeploymentKeys = require('@/code-push-deployments.json')

/** @type {{[env: TEnv]: TDeployment}} */
export const envDeployments = {
  test: 'Test',
  beta: 'Beta',
  prod: 'Production',
}

/** @type {{[deploymentKey: string]: TDeployment}} */
export const deploymentKeysToDeploymentMap = {}
_.forEach(platformDeploymentKeys.android, (deploymentKey, deployment) => {
  deploymentKeysToDeploymentMap[deploymentKey] = deployment
})
_.forEach(platformDeploymentKeys.ios, (deploymentKey, deployment) => {
  deploymentKeysToDeploymentMap[deploymentKey] = deployment
})

export const statusMessages = {
  [SyncStatus.UP_TO_DATE]: '已是最新版本',
  [SyncStatus.UPDATE_INSTALLED]: '已安裝更新',
  [SyncStatus.UPDATE_IGNORED]: '更新已被跳過',
  [SyncStatus.ERROR]: '未知錯誤',
  [SyncStatus.IN_PROGRESS]: '檢查更新中...',
  [SyncStatus.CHECKING_FOR_UPDATE]: '檢查更新中...',
  [SyncStatus.AWAITING_USER_ACTION]: '等待使用者回應...',
  [SyncStatus.DOWNLOADING_PACKAGE]: '下載更新中...',
  [SyncStatus.INSTALLING_UPDATE]: '安裝更新中...',
}

/**
 * 取得 `deployment` 和 `deploymentKey`
 * @param {TEnv} env
 * @returns {[string, string]}
 */
export function getDeployment (env) {
  /** @type {TDeployment} */
  const OVERWRITE_DEPLOYMENT = localStorage.getItem('OVERWRITE_DEPLOYMENT')
  /** @type {TDeployment} */
  const envDeployment = envDeployments[env]
  /** @type {TDeployment} */
  const deployment = platform === 'web' ? null : (OVERWRITE_DEPLOYMENT || envDeployment || 'Production')
  const deploymentKey = platform === 'web' ? null : platformDeploymentKeys[platform][deployment] || platformDeploymentKeys[platform].Production

  return [deployment, deploymentKey]
}

export default {
  envDeployments,
  platformDeploymentKeys,
  deploymentKeysToDeploymentMap,
  statusMessages,
  getDeployment,
}
