import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useTrendingTags } from '@/hooks/queries/useLandingSettingsQuery'
import SearchTag from '@/components/Search/SearchTag'

export default function QuickSearchList (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const history = useHistory()
  const dispatch = useDispatch()
  const trendingTags = useTrendingTags()

  const handleTagClick = (tag) => {
    switch (tag.action) {
      case 'MERCHANT': {
        const merchantId = tag.payload
        history.pushWithSearch('/restaurant/' + merchantId)
        break
      }

      case 'CATEGORY': {
        const categoryName = tag.payload
        dispatch(actions.landing.redirectToCategory(categoryName))
        break
      }

      case 'CUISINE': {
        const cuisinePayload = tag.payload
        dispatch(actions.landing.redirectToCuisine(cuisinePayload))
        break
      }

      case 'SEARCH': {
        const searchText = tag.payload
        dispatch(actions.landing.updateSearchText(searchText))
        break
      }

      default:
        break
    }
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant='h6'>
        {t('app.page.search.quick_search_list')}
      </Typography>

      <div className={classes.tags}>
        {_.map(trendingTags, (tag, index) => {
          return (
            <SearchTag
              key={index}
              text={tag.name}
              onClick={() => handleTagClick(tag)}
            />
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 8,
  },
  header: {
    fontWeight: '600',
    marginBottom: theme.spacing(2),
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))
