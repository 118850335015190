import PropTypes from 'prop-types'
import React from 'react'

import Radio from '@/components/Groups/GroupItem/rightActions/Radio'

import GroupItemBase from './GroupItemBase'

// eslint-disable-next-line no-unused-vars
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'

GroupItemRadio.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 *
 * @param {{
 * item: IMenuOptionItem
 * selected: Boolean
 * disabled: Boolean
 * onClick: Function
 * }} props
 */
export default function GroupItemRadio (props) {
  const { item, selected, disabled, onClick } = props
  const radio = <Radio selected={selected} disabled={disabled} onClick={onClick} />

  return (
    <GroupItemBase item={item} onClick={onClick} rightAction={radio} />
  )
}
