import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'

import { Capacitor } from '@capacitor/core'

import DeliveryTypes from '@/constants/deliveryType'

export const CUSTOMER_CANCEL_REASON = 'Customer cancel.'

export default function LuckyDrawButton (props) {
  const { order } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const merchantCampaignMap = {
    '2c2a832d-39f0-495e-b443-dbba4557bd6b': 'lX61fDUFyZZ6JWhXag5O',
    'f47893fd-2d7f-4fd6-ae5c-3f9871320d37': 'dLNNxx6oKgCil2FsdDlM',
    3: 'dLNNxx6oKgCil2FsdDlM',
  }

  if (!order) return null
  if (order.status !== 'paid') return null // 付了錢才顯示
  if (order.deliveryType !== DeliveryTypes.TABLE) return null // 內用才顯示button
  if (!merchantCampaignMap[order.merchantId]) return null // 只顯示在有抽獎campaign的店
  if (!Capacitor.isNativePlatform()) return null // 只有app可以抽獎

  const handleOnClick = () => {
    if (!order || order.status !== 'paid') return
    window.open(`https://campaign-progress-bar.web.app?campaignId=${merchantCampaignMap[order.merchantId]}&orderId=${order.id}`)
  }

  return (
    <ButtonBase
      className={classes.luckyDrawButton}
      onClick={handleOnClick}
    >
      <Typography className={classes.luckyDrawButtonText} variant='body2'>
        {t('app.page.order_tracking.buttons.lucky_draw')}
        <ArrowForwardIosIcon fontSize='small' />
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  luckyDrawButton: {
    marginTop: theme.spacing(0),
    borderRadius: theme.spacing(1),
    color: 'white',
    backgroundImage: 'url(https://res.cloudinary.com/order/image/upload/e_brightness:10,c_crop,g_west,h_843,w_1000,x_350/v1638124470/9ed5cae530aaffcf10651474df924fa7_aqs6vg.jpg)',
    backgroundSize: 'cover',
  },
  luckyDrawButtonText: {
    height: '50px',
    width: '100%',
    padding: '30px',
    margin: '0',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'brightness(60%)',
  },
}))
