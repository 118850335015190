import moment from 'moment'

import constants from '@/constants'
import dimboxCutOfTime from '@/constants/dimboxCutOfTime'
import i18n from '@/i18n'
import store from '@/redux/store'

import { useClosingTime, useOpening24h } from '@/hooks/merchant'
import { useDeliveryType } from './app'
import { useIsDimbox } from '@/hooks/dimbox'
import { useSelector } from '@/redux'

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType

/** @type {() => IRootState} */
const getState = store.getState

/**
 * @typedef {Object} IDatetimeText
 * @property {string} text
 * @property {('COLOR_NUMBER' | 'DISABLED' | undefied)} style
 */

/**
 * @param {TDeliveryType} deliveryTypeParam
 * @returns {IDatetimeText}
 */
export default function useDatetimeText (deliveryTypeParam) {
  const isOpening24h = Boolean(useOpening24h())
  const closingTime = useClosingTime()
  const { deliveryType } = useDeliveryType(deliveryTypeParam)
  const datetime = getState().app.params.datetime
  const isImmediate = datetime.isImmediate
  const selectedDatetime = datetime?.date && datetime.time ? moment(`${datetime.date} ${datetime.time}`) : moment()
  const shippingTime = getState().order.shipping?.shippingTime
  const datetimeConfig = {
    month: selectedDatetime.format('MMM'),
    date: selectedDatetime.format('D'),
    hour: selectedDatetime.format('H'),
    minute: selectedDatetime.format('mm'),
    day: selectedDatetime.format('dddd'),
  }
  const restaurant = useSelector(state => state.landing.restaurant)
  const isDimbox = useIsDimbox(deliveryType)

  let duration
  let text
  let style

  switch (deliveryType) {
    case TAKEAWAY: {
      // Dimbox 要顯示不一樣的字串
      if (isDimbox) {
        // DimBox 餐廳當日截單時間為 10:30，可於當日 12:00~14:30 取餐。
        // 超過 10:30 下單，取餐時間為隔日 12:00~14:30。
        const cutOffTime = moment().hours(dimboxCutOfTime.hour).minutes(dimboxCutOfTime.minute).seconds(dimboxCutOfTime.second)
        const pickupAt = moment().isAfter(cutOffTime)
          ? moment().hours(12).minutes(0).add(1, 'd')
          : moment().hours(12).minutes(0)
        const month = pickupAt.format('MMM')
        const date = pickupAt.format('D')
        const timeslot = '12:00~14:30'
        return { text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.dimbox', { month, date, timeslot }) }
      }
      if (isImmediate) {
        style = 'COLOR_NUMBER'
        duration = moment.duration(restaurant?.pickerRange.minMinutes ?? 0, 'minutes')
        text = duration.days()
          ? i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.takeaway_immediate_days_hours_minutes', { days: duration.days(), hours: duration.hours(), minutes: duration.minutes() })
          : duration.hours()
            ? i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.takeaway_immediate_hours_minutes', { hours: duration.hours(), minutes: duration.minutes() })
            : i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.takeaway_immediate_minutes', { minutes: duration.minutes() })
        return { text, style }
      }
      return { text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.takeaway', { ...datetimeConfig }) }
    }

    case STORE_DELIVERY: {
      if (!shippingTime) return {}
      if (shippingTime === 'NO_RESULT') return { text: i18n.t('app.component.footer.content.unable_to_deliver') }
      if (isImmediate) {
        style = 'COLOR_NUMBER'
        duration = moment.duration(restaurant?.pickerRange.minMinutes ?? 0 + shippingTime, 'minutes')
        text = duration.days()
          ? i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.storeDelivery_immediate_days_hours_minutes', { days: duration.days(), hours: duration.hours(), minutes: duration.minutes() })
          : duration.hours()
            ? i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.storeDelivery_immediate_hours_minutes', { hours: duration.hours(), minutes: duration.minutes() })
            : i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.storeDelivery_immediate_minutes', { minutes: duration.minutes() })
        return { text, style }
      }
      return { text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.storeDelivery', { ...datetimeConfig }) }
    }

    case TABLE: {
      if (isOpening24h) {
        return {
          text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.table_24hr'),
          style: 'COLOR_NUMBER',
        }
      }
      if (closingTime) {
        const formatTime = () => {
          const formatedTime = moment(datetime.date)
            .startOf('day')
            .add(closingTime.end, 'h')
            .add(closingTime.endMin, 'm')
            .format('hh:mm a')
          return formatedTime
        }
        return {
          text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.table_opened', { time: formatTime() }),
          style: 'COLOR_NUMBER',
        }
      }
      return {
        text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.table_closed'),
        style: 'DISABLED',
      }
    }

    default: {
      if (!restaurant?.pickerRange?.minMinutes == null) return { text: i18n.t('app.common.date_time.immediate') }
      return { text: i18n.t('app.page.restaurant.merchant_card.datetime_picker_button.takeaway', { minutes: restaurant?.pickerRange?.minMinutes }) }
    }
  }
}
