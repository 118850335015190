import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import customerApi from '@/libs/api/customer'
import hooks from '@/hooks'

import DialogBase from './DialogBase'

/**
 *
 * @param {*} props
 * @returns
 */
export default function RedeemCodeDialog (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const isInit = useSelector(state => state.app.isInit)
  const isLogin = useIsLogin()
  const params = hooks.app.useParams()
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const open = isInit && typeof params.REDEEM === 'string'

  useEffect(() => {
    setCode(params.REDEEM)
  }, [params.REDEEM, isLogin])

  const onConfirm = async () => {
    if (!isLogin) {
      dispatch(actions.app.toggleLoginDrawer(true))
      return
    }

    setLoading(true)
    try {
      const result = await customerApi.submitRedeemCode(code)
      setCode('')
      dispatch(actions.app.toggleAlert({
        dialogProps: { disableBackdropClick: true },
        title: t('app.page.redeem.success_alert.title'),
        message: `${t('app.page.redeem.success_alert.message_prefix')} ${result.data?.amount || ''} RiceCoin`,
        buttons: [{ text: t('app.common.confirm'), onClick: () => dispatch(actions.app.deleteRedeemCode()) }],
      }))
    } catch (error) {
      const errorCode = _.get(error.response, 'data.errors[0].title')
      setError(t(`app.page.redeem.${errorCode}`, t('app.page.redeem.error.title')))
    }
    setLoading(false)
  }

  return (
    <DialogBase
      open={open}
      onClose={() => {}}
      customClasses={{ footer: classes.customFooter }}
      title={t('app.page.redeem.title')}
      renderContent={() => (
        <TextField
          placeholder={t('app.page.redeem.input_placeholder')}
          variant='outlined'
          value={code}
          onChange={e => setCode(e.target.value)}
          error={Boolean(error)}
          helperText={error || ''}
          InputProps={{
            endAdornment: code && (
              <InputAdornment position='end'>
                <HighlightOffIcon
                  fontSize='small'
                  className={classes.clearIcon}
                  onClick={() => setCode('')}
                />
              </InputAdornment>
            ),
            classes: { input: classes.input },
          }}
          disabled={isLoading}
        />
      )}
      renderFooter={() => (
        <>
          <LetterSpacingButton
            text={t('app.common.cancel')}
            onClick={() => dispatch(actions.app.deleteRedeemCode())}
            className={classes.cancelButton}
            disabled={isLoading}
            variant='outlined'
          />
          <LetterSpacingButton
            text={t('app.common.confirm')}
            onClick={onConfirm}
            disabled={isLoading}
          />
        </>
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  input: {
    padding: theme.spacing(1.5, 1.5),
    fontSize: '1.4rem',
  },
  clearIcon: {
    color: theme.palette.grey[500],
  },
  customFooter: {
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: 'white',
  },
}))
