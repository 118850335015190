import { Button, Slide, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useTranslation } from 'react-i18next'
import LoginPage from './LoginPage'
import Next from './Next'
import customerApi from '@root/src/libs/api/customer'
import validateUserName from '@root/src/libs/validateUserName'

const snackerOption = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  TransitionComponent: Slide,
}

export default function StepUserInfo (props) {
  const { t } = useTranslation()
  const { doPrev, doNext } = props

  const dispatch = useDispatch()
  const classes = useStyles(props)
  const invite = useSelector(state => state.app.params?.invite)

  const [name, setName] = useState('')
  const [nameError, setNameError] = React.useState('')
  const [referral, setReferral] = useState(invite ?? '')
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const handleSubmit = async () => {
    setDisabledSubmit(true)

    try {
      // 去除頭尾空格、將連續的空格取代成單一空格
      const trimedName = name.trim().replace(/ +(?= )/g, '')
      const { isValid, error } = validateUserName(trimedName)
      if (!isValid) {
        setNameError(error)
        setDisabledSubmit(false)
        return
      }
      await customerApi.updateCustomerInfo({ name: trimedName, referral })
      doNext()
    } catch (error) {
      let message = ''
      const errorCode = _.get(error, 'response.data.errors[0].title')

      setDisabledSubmit(false)

      switch (errorCode) {
        case 'error.referrer.used':
          message = t('app.component.drawer.login_drawer.create_account.error.used')
          break
        case 'error.referrer.invalid':
        case 'error.referrer.create':
          message = t('app.component.drawer.login_drawer.create_account.error.invalid')
          break
        default:
          message = String(error)
          break
      }

      dispatch(actions.snackbar.enqueueSnackbar({
        message,
        options: {
          variant: 'error',
          ...snackerOption,
        },
      }))
    }
  }

  return (
    <LoginPage>
      <div id='nav-bar'>
        <Button onClick={doPrev}>
          <Typography>{t('app.component.drawer.login_drawer.back')}</Typography>
        </Button>
      </div>
      <div id='title-group'>
        <Typography id='title'>
          {t('app.component.drawer.login_drawer.create_account.title')}
        </Typography>
        <Typography id='description'>
          {t('app.component.drawer.login_drawer.create_account.subtitle')}
        </Typography>
      </div>
      <div className={classes.form}>
        <TextField
          required
          variant='outlined'
          size='small'
          placeholder={t('app.component.drawer.login_drawer.create_account.name')}
          value={name}
          onChange={e => {
            setName(e.target.value)
          }}
          error={Boolean(nameError)}
          helperText={nameError}
          inputProps={{
            maxLength: 20,
          }}
        />
        {/* TODO: remove this in 4/21 version */}
        <TextField
          size='small'
          placeholder={t('app.component.drawer.login_drawer.create_account.ref')}
          value={referral}
          onChange={e => {
            setReferral(e.target.value)
          }}
          variant='outlined'
        />
      </div>
      <Next
        onClick={handleSubmit}
        disabled={
          name.length === 0 ||
          // !(referral.length <= 10) ||
          disabledSubmit
        }
      />
    </LoginPage>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 30,

    '& > *': {
      width: '100%',
      marginBottom: 10,
    },
  },
}))
