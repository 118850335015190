import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React from 'react'

import DialogBase from './DialogBase'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function InputDialog (props) {
  const {
    open,
    isLoading,
    error,
    title,
    placeholder,
    TextFieldProps,
    inititalValue = '',
    onConfirm,
    onClose,
    onCancel,
  } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const [text, setText] = React.useState(inititalValue)

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      customClasses={{ footer: classes.customFooter }}
      title={title}
      renderContent={() => (
        <TextField
          placeholder={placeholder}
          variant='outlined'
          value={text}
          onChange={e => setText(e.target.value)}
          error={Boolean(error)}
          helperText={error || ''}
          InputProps={{
            endAdornment: text && (
              <InputAdornment position='end'>
                <HighlightOffIcon
                  fontSize='small'
                  className={classes.clearIcon}
                  onClick={() => setText('')}
                />
              </InputAdornment>
            ),
            classes: { input: classes.input },
          }}
          disabled={isLoading}
          {...TextFieldProps}
        />
      )}
      renderFooter={() => (
        <>
          <LetterSpacingButton
            text={t('app.common.cancel')}
            onClick={onCancel || onClose}
            className={classes.cancelButton}
            disabled={isLoading}
            variant='outlined'
          />
          <LetterSpacingButton
            text={t('app.common.confirm')}
            onClick={() => onConfirm(text)}
            disabled={isLoading}
          />
        </>
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  input: {
    padding: theme.spacing(1.5, 1.5),
    fontSize: '1.4rem',
  },
  clearIcon: {
    color: theme.palette.grey[500],
  },
  customFooter: {
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: 'white',
  },
}))
