import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'

import { DEFAULT_PER_PAGE } from '@/hooks/queries/params'
import { useAppLocale } from '@/hooks/app'
import { useGroupQuery } from './useGroupQuery'
import dimorderApi from '@/libs/api/dimorder'

/* eslint-disable no-unused-vars */
import { Reward, RewardItem } from 'dimorder-orderapp-lib/dist/types/CRM'
/* eslint-enable no-unused-vars */

/**
 * 用於 CRM 積分獎勵頁面的捲動載入
 * @param {string} [groupId]
 * @param {number} [perPage = 25]
 * @returns
 */
export function useGroupRewardsQueryOptions (groupId, perPage = DEFAULT_PER_PAGE) {
  const params = useParams()
  const { data: group } = useGroupQuery()
  const id = groupId || params.groupId || group?.id
  const enabled = !!id
  const queryKey = ['crm', 'rewards', `groupId:${id}`]
  const queryFn = ({ pageParam: next }) => fetchFn(next)
  async function fetchFn (next) {
    const pageData = await dimorderApi.crm.getGroupRewards(id, { after: next, limit: perPage })
    const meta = { perPage, next: pageData?.next }
    return {
      meta,
      data: pageData?.data ?? [],
      perPage,
      next: pageData?.next,
    }
  }
  const staleTime = 3 * 60 * 1000
  return { queryKey, queryFn, staleTime, enabled }
}

/**
 *
 * @param {string} [groupId]
 * @returns
 */
export function useGroupRewardsQuery (groupId) {
  const options = useGroupRewardsQueryOptions(groupId)
  const query = useQuery(options)
  return query
}

/**
 *
 * @param {string} [groupId]
 * @returns
 */
export function useGroupAllRewardsQuary (groupId) {
  const params = useParams()
  const { data: group } = useGroupQuery()
  const id = groupId || params.groupId || group?.id
  const enabled = !!id
  const queryKey = ['crm', 'all_rewards', `groupId:${id}`]
  const queryFn = () => fetchFn()
  async function fetchFn () {
    let rewards = []
    let next = ''
    do {
      const response = await dimorderApi.crm.getGroupRewards(id, { limit: 1000, after: next }) // 需一次抓取全部的 rewards（limit 後端預設為 10，建議不超過 1000）
      rewards = rewards.concat(response.data)
      next = response.next
    } while (next)
    return rewards
  }
  const staleTime = 3 * 60 * 1000
  const options = { queryKey, queryFn, staleTime, enabled }
  const query = useQuery(options)
  return { ...query, rewards: query?.data ?? [] }
}

/**
 * reward 的 name 與 desc
 * @param {Reward | RewardItem} rewardItem
 * @param {string} fallback
 * @returns
 */
export function useGroupRewardLocale (rewardItem) {
  const { rewards } = useGroupAllRewardsQuary()
  const appLocale = useAppLocale()
  if (!rewardItem) return { name: '', desc: '' }
  let locale = { name: '', desc: '' }
  if (rewardItem.locales) {
    // Reward 有 locales
    // 嘗試找出對映語言的 locale
    // 若找不到對映語言的 locale 例如 'jp' 則預設顯示 'zh' 的 locale
    const localesMap = _.keyBy(rewardItem.locales, 'locale')
    const fallback = { name: '', desc: '' }
    locale = localesMap[appLocale] ?? localesMap.zh ?? fallback
  } else if (rewardItem.name) {
    // RewardItem 沒有 locales 只有 name
    // 嘗試從所有 rewards 中找出該 reward 並嘗試找出對映語言的 locale
    // 若找不到 locale 則 fallback
    const targetLocale = _
      .chain(rewards)
      .find(r => r.id === rewardItem.id)
      .get('locales')
      .find(l => l.locale === appLocale)
      .value()
    const fallback = { name: rewardItem.name ?? '', desc: '' }
    locale = targetLocale ?? fallback
  }
  return locale
}
