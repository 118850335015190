import { FixedSizeList as List } from 'react-window'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import React from 'react'
import _ from 'lodash'

import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

import TransactionListItem from './TransactionListItem'

export default function TransactionList (props) {
  const { hasNextPage, isNextPageLoading, items, loadNextPage, isReward } = props
  const classes = useStyles(props)
  const history = useHistory()
  const { t } = useTranslation()

  const itemCount = hasNextPage ? items.length + 1 : items.length

  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage

  const isItemLoaded = index => !hasNextPage || index < items.length
  const Item = ({ index, style }) => {
    return <TransactionListItem style={style} ledger={items[index]} />
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <AutoSizer>
          {({ width, height }) => (
            <>
              {
                !isNextPageLoading && _.isEmpty(items) && (
                  <section
                    style={{ width, height }}
                    className={classes.emptyListContainer}
                  >
                    <Typography variant='body1' className={classes.emptyTitle} align='center'>
                      {isReward
                        ? t('app.page.riceCoin.no_record.reward')
                        : t('app.page.riceCoin.no_record.transaction')}
                    </Typography>
                    <LetterSpacingButton
                      text={t('app.page.riceCoin.no_record.action')}
                      onClick={() => {
                        history.goBack()
                      }}
                      className={classes.orderButton}
                      fontSize='2rem'
                    />
                  </section>
                )
              }
              <List
                style={{
                  display: _.isEmpty(items) && 'none',
                }}
                ref={ref}
                width={width}
                height={height}
                itemSize={60}
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
              >
                {Item}
              </List>
            </>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  )
}

const useStyles = makeStyles(theme => ({
  transactionList: {
    paddingBottom: theme.spacing(8),
  },
  emptyListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '25%',
    paddingBottom: theme.spacing(5),
  },
  emptyTitle: {
    fontWeight: 'bold',
  },
  orderButton: {
    width: '75%',
    margin: theme.spacing(3),
  },
}))
