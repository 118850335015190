import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import creditCardType from 'credit-card-type'

import { actions, useDispatch, useSelector } from '@/redux'
import { getCardProviderKey } from '@/libs/paymentMethod'
import { supportedIssuersFor2c2p, tokenType2c2p } from '@/constants/paymentMethod'

function SecureForm2c2p ({ onSuccess }) {
  const savePayment = useSelector(state => state.payment.savePayment)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [savePaymentState, setSavePaymentState] = useState(savePayment)
  const cvvInputRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const messageMap = {
    1: t('app.component.alert.2c2p_cc_error.card_number_required'), // Card number is required
    2: t('app.component.alert.2c2p_cc_error.card_number_invalid'), // Card number is invalid
    3: t('app.component.alert.2c2p_cc_error.expiry_month_required'), // Expiry month is required
    4: t('app.component.alert.2c2p_cc_error.expiry_month_length'), // Expiry month must be two numbers
    5: t('app.component.alert.2c2p_cc_error.expiry_year_required'), // Expiry year is required
    6: t('app.component.alert.2c2p_cc_error.expiry_year_length'), // Expiry year must be four numbers
    7: t('app.component.alert.2c2p_cc_error.expired_year'), // Card already expired(year)
    8: t('app.component.alert.2c2p_cc_error.expired_month'), // Card already expired(month)
    9: t('app.component.alert.2c2p_cc_error.expiry_month_invalid'), // Expiry month is invalid
    10: t('app.component.alert.2c2p_cc_error.cvv_invalid'), // CVV2/CVC2 is invalid
    default: t('app.component.alert.2c2p_cc_error.general'),
  }

  const storeSecurePayToken = () => {
    window.My2c2p.getEncrypted(
      '2c2p-payment-form',
      async function (encryptedData, errCode, errDesc) {
        setIsSubmitting(true)

        const {
          expMonthCardInfo,
          expYearCardInfo,
          maskedCardInfo,
        } = encryptedData
        const cardType = creditCardType(maskedCardInfo)[0]?.type

        if (errCode !== 0) {
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.2c2p_cc_error.title'),
            message: messageMap[errCode] ?? messageMap.default,
          }))
          setIsSubmitting(false)
          return
        }

        if (!supportedIssuersFor2c2p.includes(getCardProviderKey(cardType))) {
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.2c2p_cc_error.title'),
            message: t('app.component.alert.2c2p_cc_error.unsupported_card_type'),
          }))
          setIsSubmitting(false)
          return
        }

        if (![expMonthCardInfo, expYearCardInfo, maskedCardInfo].every(Boolean)) {
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.2c2p_cc_error.title'),
            message: t('app.component.alert.2c2p_cc_error.general'),
          }))
          setIsSubmitting(false)
          return
        }

        if (!cvvInputRef.current.value) {
          dispatch(actions.app.toggleAlert({
            title: t('app.component.alert.2c2p_cc_error.title'),
            message: messageMap[10],
          }))
          setIsSubmitting(false)
          return
        }

        if (errCode === 0) {
          // success
          onSuccess({
            cardType: getCardProviderKey(cardType),
            encryptedData,
            tokenType2c2p,
            saveCard: savePaymentState,
          })
        }

        setIsSubmitting(false)
      },
    )
  }

  useEffect(() => {
    dispatch(actions.app.setKeyboardMode('none'))

    return () => {
      dispatch(actions.app.setKeyboardMode('native'))
    }
  }, [])

  return (
    <div>
      <form id='2c2p-payment-form'>
        <div className={classes.container}>
          <input
            className={classes.input}
            type='text'
            data-encrypt='cardnumber'
            maxLength='16'
            placeholder={t('app.constant.paymentMethod.pan')}
          />
        </div>
        <div style={{ display: 'flex' }} className={classes.container}>
          <input
            className={classes.input}
            type='text'
            data-encrypt='month'
            maxLength='2'
            placeholder={t('app.constant.paymentMethod.exp_month')}
          />

          <div className={classes.separator} />

          <input
            className={classes.input}
            type='text'
            data-encrypt='year'
            maxLength='4'
            placeholder={t('app.constant.paymentMethod.exp_year')}
          />
        </div>
        <div className={classes.container}>
          <input
            className={classes.input}
            type='password'
            data-encrypt='cvv'
            maxLength='4'
            autoComplete='off'
            placeholder={t('app.constant.paymentMethod.cvv')}
            ref={cvvInputRef}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={savePaymentState}
              onChange={() => { setSavePaymentState(!savePaymentState) }}
              classes={{ root: classes.checkBox }}
            />
          }
          label={(
            <Typography variant='body2'>
              {t('app.constant.paymentMethod.save_card')}
            </Typography>
          )}
          classes={{ root: classes.checkBoxContainer }}
        />

        <input
          className={classes.button}
          type='button'
          value={t('app.common.confirm')}
          disabled={isSubmitting}
          onClick={storeSecurePayToken}
        />
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '6px 12px',
  },
  input: {
    width: '100%',
    padding: 12,
    backgroundColor: '#F3F3F3',
    border: '1px solid #DCDCD9',
    borderRadius: 8,
    WebkitAppearance: 'none',
    boxShadow: theme.shadows[25],
  },
  button: {
    width: 'calc(100% - 24px)',
    margin: 12,
    height: 40,
    borderRadius: 20,
    border: 'none',
    backgroundColor: '#ffc42b',
    WebkitAppearance: 'none',
    boxShadow: theme.shadows[25],
  },
  separator: {
    width: 24,
  },
  checkBoxContainer: {
    padding: theme.spacing(0, 1),
    margin: 0,
  },
  checkBox: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}))

export default SecureForm2c2p
