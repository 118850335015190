import { Collapse, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _ from 'lodash'

import OrderBatchItem from '@/components/OrderBatchItemList/OrderBatchItem'

import CollapseIconButton from './buttons/CollapseIconButton'
import ExpandButton from './buttons/ExpandButton'

// eslint-disable-next-line no-unused-vars
import { IAppBatchItem } from 'dimorder-orderapp-lib/dist/types/AppOrder'

SetItemList.propTypes = {
  item: PropTypes.object,
}

/**
 *
 * @param {{
 * item: IAppBatchItem
 * }} props
 */
export default function SetItemList (props) {
  const { item } = props
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = (e) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }
  const classes = useStyles({ ...props, expanded })

  if (_.isEmpty(item.setItems)) return null
  return (
    <section>
      <Collapse in={!expanded}>
        <ExpandButton
          onClick={toggleExpanded}
          quantity={item.setItems.length}
        />
      </Collapse>
      <Collapse in={expanded}>
        <ul className={classes.setItemList}>
          <CollapseIconButton onClick={toggleExpanded} />
          {_.map(item.setItems, (setItem, index) => {
            return (
              <OrderBatchItem
                key={index}
                item={setItem}
                rightContent={
                  <Typography className={classes.quantity}>{setItem.quantity}</Typography>
                }
              />
            )
          })}
        </ul>
      </Collapse>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  setItemList: {
    marginTop: theme.spacing(1),
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    '& > li': {
      backgroundColor: theme.palette.grey[100],
    },
    '& > li:first-of-type': {
      paddingTop: theme.spacing(4),
    },
  },
}))
