export const QR_CODE = 'QR_CODE'
export const TRACKING_MAP = 'TRACKING_MAP'
export const LOTTIES = 'LOTTIES'
export const DEFAULT = 'IMAGE'

const tableStatusMap = {
  waiting_pay: {
    index: 0,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/paying.json'),
    loop: true,
  },
  paying: {
    index: 0,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/paying.json'),
    loop: true,
  },
  waiting_accept: {
    index: 1, // For ProgressBar
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/waiting_accept.json'),
    loop: false,
  },
  paid: {
    index: 1,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/paid.json'),
    loop: true,
  },
  waiting_pick_up: {
    index: 1,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/waiting_pick_up.json'),
    loop: false,
  },
  cancel_table: {
    index: -1,
  },
  cancel_order: {
    index: -1,
  },
  delete_order: {
    index: -1,
  },
  unknown: {
    index: -1,
  },
}

const takeawayStatusMap = {
  paying: {
    index: 0,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/paying.json'),
    loop: true,
  },
  waiting_accept: {
    index: 0, // For ProgressBar
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/waiting_accept.json'),
    loop: false,
  },
  preparing_meal: {
    index: 1,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/preparing_meal.json'),
    loop: true,
  },
  waiting_pick_up: {
    index: 2,
    iconType: QR_CODE,
  },
  picked_up: {
    index: 3,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/picked_up.json'),
    loop: false,
  },
  cancel_order: {
    index: -1,
  },
  delete_order: {
    index: -1,
  },
  unknown: {
    index: -1,
  },
}

const storeDeliveryStatusMap = {
  paying: {
    index: 0,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/paying.json'),
    loop: true,
  },
  waiting_accept: {
    index: 0,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/waiting_accept.json'),
    loop: false,
  },
  preparing_meal: {
    index: 1,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/preparing_meal.json'),
    loop: true,
  },
  waiting_pick_up: {
    index: 2,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/waiting_pick_up.json'),
    loop: false,
  },
  in_delivery: {
    index: 3,
    // TODO: place tracking map
    // iconType: TRACKING_MAP,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/in_delivery.json'),
    loop: true,
  },
  complete: {
    index: 4,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/picked_up.json'),
    loop: false,
  },
  arrived: {
    index: 4,
    iconType: LOTTIES,
    iconSource: require('@/assets/lotties/picked_up.json'),
    loop: false,
  },
  cancel_order: {
    index: -1,
  },
  delete_order: {
    index: -1,
  },
  unknown: {
    index: -1,
  },
}

export const statusMap = {
  table: tableStatusMap,
  takeaway: takeawayStatusMap,
  storeDelivery: storeDeliveryStatusMap,
}

/**
 * @typedef {Object} IStatusConfig
 * @property {number} index 代表進度條中顯示幾條bar
 * @property {'QR_CODE' | 'LOTTIES' | undefined} iconType
 * @property {string?} iconSource
 * @property {boolean?} localOpen
 */

/**
 * @param {TDeliveryType} deliveryType
 * @param {TDisplayStatusKey} displayStatusKey
 * @returns {IStatusConfig}
 */
export function getStatusConfig (deliveryType, displayStatusKey) {
  const statusConfig = statusMap[deliveryType][displayStatusKey] || statusMap[deliveryType]?.unknown
  return statusConfig
}
