import { CircularProgress, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import {
  DatetimePickerContextProvider,
  useAutoSelectDatetime,
  useDateRangeContext,
  useResetDatetime,
  useSyncDeliveryType,
  useTempDatetimeContext,
  useTempDeliveryTypeContext,
  useTimeRangeContext,
} from '@/hooks/contexts/DatetimePickerContext'
import { actions, useDispatch, useSelector } from '@/redux'
import { useCart } from '@/hooks/useCart'
import { useDeliveryType, useParams, useSystem } from '@/hooks/app'
import { useMerchant } from '@/hooks/merchant'
import { useShipping } from '@/hooks/order'
import constants from '@/constants'

import ComingSoon from '@/components/ComingSoon'
import DatetimeText from '@/pages/Menu/MerchantCard/DatetimePickerButton/DatetimeText'
import DeliveryTypeTabs from '@/components/Page/PageTabs/DeliveryTypeTabs'
import DrawerContent from '@/components/Drawers/common/DrawerContent'
import SubmitButton from '@/components/Drawers/common/SubmitButton'
import appConstants from '@/constants/app'

import { DatetimeList } from './ScheduleDatetimeDrawer'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType
const deliveryTypes = [TAKEAWAY, STORE_DELIVERY, TABLE]

export default function HOC () {
  return (
    <DatetimePickerContextProvider>
      <DatetimeDrawer />
    </DatetimePickerContextProvider>
  )
}

function DatetimeDrawer (props) {
  const classes = useStyles(props)
  const open = useSelector(state => state.app.drawers.datetime.open)
  const { setTempDatetime } = useTempDatetimeContext()
  const { setTempDeliveryType } = useTempDeliveryTypeContext()
  const { onClose, onSubmit, isSubmitDisabled } = useDrawerActions()

  useSyncDeliveryType(open, deliveryTypes)
  useResetDatetime(open)
  useAutoSelectDatetime()

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='bottom'
      PaperProps={{ className: classes.drawerPaper }}
    >
      <DeliveryTypeTabs
        deliveryTypes={deliveryTypes}
        setTempDeliveryType={setTempDeliveryType}
        setTempDatetime={setTempDatetime}
      />
      <DrawerContent className={classes.drawerContent}>
        <Content />
        <SubmitButton disabled={isSubmitDisabled} onClick={onSubmit} />
      </DrawerContent>
    </Drawer>
  )
}

function Content (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const system = useSystem()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  const { table: dineInUnavailable, takeaway: takeawayUnavailable, storeDelivery: storeDeliveryUnavailable } = useDeliveryTypeUnvailable()
  const isShippingLoading = useSelector(state => state.app.loadings.createShipping)
  const isRestaurantInitLoading = useSelector(state => state.app.loadings.restaurantInit)
  const canShip = _.isNumber(useShipping()?.shippingTime)

  const colon = system[tempDeliveryType].remark === '' ? '' : ':' // remark為空時不顯示冒號

  if (!system[tempDeliveryType]?.enable) {
    return <ComingSoon text={`${t('app.common.service_unavailable')}${colon}${system[tempDeliveryType].remark}`} />
  }

  switch (tempDeliveryType) {
    case TABLE:
      if (dineInUnavailable) return <ComingSoon text={t('app.common.scan_qrcode_to_order')} />
      return (
        <div className={classes.centerBox}>
          <DatetimeText deliveryType={tempDeliveryType} />
        </div>
      )

    case TAKEAWAY:
      if (takeawayUnavailable) return <ComingSoon text={t('app.component.footer.content.takeaway_disabled')} />
      return <DatetimeList />

    case STORE_DELIVERY:
      if (storeDeliveryUnavailable) return <ComingSoon text={t('app.component.footer.content.storeDelivery_disabled')} />
      if (isShippingLoading || isRestaurantInitLoading) {
        return (
          <div className={classes.loadingWrap}>
            <CircularProgress size={32} />
          </div>
        )
      }
      if (!canShip) return <ComingSoon text={t('app.component.footer.content.unable_to_deliver')} />
      return <DatetimeList />

    default:
      return null
  }
}

function useDrawerActions () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const restaurant = useSelector(state => state.landing.restaurant)
  const system = useSystem()
  const canShip = _.isNumber(useShipping()?.shippingTime)
  const { deliveryType } = useDeliveryType()
  const { tempDatetime } = useTempDatetimeContext()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  const { dateRange, isLoading: isDateRangeLoading } = useDateRangeContext()
  const { timeRange, isLoading: isTimeRangeLoading } = useTimeRangeContext()
  const { hasItemsInCart } = useCart()
  const { isD2CWeb } = useParams()
  const { table: dineInUnavailable, takeaway: takeawayUnavailable, storeDelivery: storeDeliveryUnavailable } = useDeliveryTypeUnvailable()

  const isSubmitDisabled = Boolean(
    _.isEmpty(dateRange) ||
    _.isEmpty(timeRange) ||
    isDateRangeLoading ||
    isTimeRangeLoading ||
    !system[tempDeliveryType].enable ||
    (tempDeliveryType === STORE_DELIVERY && !canShip) ||
    (tempDeliveryType === TABLE && dineInUnavailable) ||
    (tempDeliveryType === TAKEAWAY && takeawayUnavailable) ||
    (tempDeliveryType === STORE_DELIVERY && storeDeliveryUnavailable),
  )

  const closeDrawer = () => {
    dispatch(actions.app.toggleDrawer('datetime', false))
  }

  const onClose = () => {
    if (!restaurant?.open) {
      if (isD2CWeb) {
        history.goBack()
      } else {
        history.replace('/restaurants')
      }
    }
    closeDrawer()
  }

  const onSubmit = () => {
    if (deliveryType !== tempDeliveryType) {
      // 若更改deliveryType
      if (hasItemsInCart) {
        // 且購物車內已有商品，跳出警告
        const alertConfig = {
          title: t('app.component.alert.change_delivery_type.title'),
          message: t('app.component.alert.change_delivery_type.message'),
          onConfirm: async () => {
            dispatch(actions.app.updateDeliveryType(tempDeliveryType))
            dispatch(actions.app.updateDatetime(tempDatetime))
            dispatch(actions.app.resetDrawers())
          },
        }
        dispatch(actions.app.toggleAlert(alertConfig))
        return
      }
      dispatch(actions.app.updateDeliveryType(tempDeliveryType)) // 更新deliveryType
    }
    dispatch(actions.app.updateDatetime(tempDatetime)) // 更新datetime
    closeDrawer()
  }

  return { onClose, onSubmit, isSubmitDisabled }
}

function useDeliveryTypeUnvailable () {
  const merchant = useMerchant()
  const system = useSystem()
  const { tempDeliveryType } = useTempDeliveryTypeContext()
  const { isD2CWeb, dineIn: isD2CDineInEnabled, takeaway: isD2CTakeawayEnabled, storeDelivery: isD2CStoreDeliveryEnabled } = useParams()
  const dineInUnavailable = !isD2CWeb || isD2CWebMethodDisable(isD2CDineInEnabled)
  const takeawayUnavailable = merchant.takeawayDisabled || isD2CWebMethodDisable(isD2CTakeawayEnabled) || !system[tempDeliveryType].enable
  const storeDeliveryUnavailable = !merchant.setting?.storeDelivery || merchant.deliveryDisabled || isD2CWebMethodDisable(isD2CStoreDeliveryEnabled) || !system[tempDeliveryType].enable

  // D2C 用的連結中是否開放功能
  function isD2CWebMethodDisable (enabled) {
    if (!isD2CWeb) return false // 先檢查D2C是否開放
    return !enabled
  }

  return {
    table: dineInUnavailable,
    takeaway: takeawayUnavailable,
    storeDelivery: storeDeliveryUnavailable,
  }
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    background: 'rgba(0, 0, 0, 0)',
    maxHeight: 'calc(100% - 64px)',
    overflow: 'hidden',
    padding: 0,
  },
  drawerContent: {
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(2),
    paddingBottom: `calc(${theme.spacing(2)}px + ${appConstants.safeArea.bottom})`,
    background: theme.palette.grey[50],
    minHeight: 320,
  },
  centerBox: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 320,
  },
  loadingWrap: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
