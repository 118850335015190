import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

import firebaseConfig from '@/config/firebase'

if (Capacitor.getPlatform() === 'web') {
  /**
   * Platform: Web
   * Configure and initialize the firebase app.
   * @param options - firebase web app configuration options
   * */
  FirebaseAnalytics.initializeFirebase(firebaseConfig)
}
