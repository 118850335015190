import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import OrderHistoryItem from './OrderHistoryItem'

export default function OrderHistoryItemList (props) {
  const { list } = props
  const classes = useStyles(props)

  return (
    <ul className={classes.orderHistoryItemList}>
      {_.map(list, (order, index) => {
        // 不顯示已退桌的紀錄 因為對點餐的人沒有意義
        if (!order) return null // prevent undefined error
        if (order.displayStatusKey === 'cancel_table') return null
        return <OrderHistoryItem key={index} item={order} />
      })}
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  orderHistoryItemList: {
    display: 'grid',
    gap: theme.spacing(1),
  },
}))
