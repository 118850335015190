export default {
  SHOW_NOT_COMPLETE_SETS: 'BATCH/SHOW_NOT_COMPLETE_SETS',
  SELECT_BATCH: 'BATCH/SELECT_BATCH',
  ADD_ITEM: 'BATCH/ADD_ITEM',
  UPDATE_ITEM: 'BATCH/UPDATE_ITEM',
  REPLACE_ITEM: 'BATCH/REPLACE_ITEM',
  DELETE_ITEM: 'BATCH/DELETE_ITEM',
  RESET_BATCH: 'BATCH/RESET_BATCH',
  RESTORE_BATCH: 'BATCH/RESTORE_BATCH',
  RESET_ITEMS: 'BATCH/RESET_ITEMS',
  UPDATE_BATCH_ITEMS: 'BATCH/UPDATE_BATCH_ITEMS',
  UPSERT_REWARD_ITEM: 'BATCH/UPSERT_REWARD_ITEM',
  RESET_REWARD_ITEMS: 'BATCH/RESET_REWARD_ITEMS',
}
