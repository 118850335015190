import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import LinkButton from './LinkButton'

export default function MemberActionButtons (props) {
  const classes = useStyles(props)

  return (
    <div className={classes.memberActionWrapper}>
      <LinkButton btnType='orders' />
      <LinkButton btnType='riceCoin' />
      <LinkButton btnType='favorite' />
      <LinkButton btnType='member' />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  memberActionWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    flexShrink: 0,
  },
}))
