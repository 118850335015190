import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

Remarks.propTypes = {
  remarks: PropTypes.array,
}

/**
 *
 * @param {{
 * remarks: string[]
 * }} props
 */
export default function Remarks (props) {
  const { remarks = [] } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  if (_.isEmpty(remarks) || _.every(remarks, remark => _.isEmpty(remark))) return null

  return (
    <Box className={classes.container}>
      {!_.isEmpty(remarks) && (
        <Typography className={classes.title}>
          {t('app.component.order_batch_item.remarks')}
        </Typography>
      )}
      <ul className={classes.remarks}>
        {
          _.map(remarks, (remark, index) => {
            return (
              <li key={index} className={classes.remark}>
                <Typography className={classes.remarkText} component='span'>
                  {remark}
                </Typography>
              </li>
            )
          })
        }
      </ul>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  title: {
    fontSize: '1.4rem',
    fontStyle: 'italic',
  },
  remarks: {
    display: 'grid',
    gridGap: theme.spacing(0.5),
    gridTemplateColumns: '1fr',
    padding: theme.spacing(1),
    fontSize: '1.2rem',
    color: theme.palette.info.main,
  },
  remark: {
    fontSize: 'inherit',
    marginRight: 4,
  },
  remarkText: {
    wordBreak: 'break-word',
    fontSize: 'inherit',
  },
}))
