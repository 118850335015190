import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from '@/redux'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

// eslint-disable-next-line no-unused-vars
import { IAppOrder } from 'dimorder-orderapp-lib/dist/types/AppOrder'

/**
 * @returns {IAppOrder}
 */
export function useSelectedOrderHistory () {
  const { orders, selectedOrderId } = useSelector(state => state.orderHistory)
  return orders?.find(order => order.id === selectedOrderId)
}

export function usePayingRedirectOrderTracking () {
  const history = useHistory()
  const d2cBaseUrl = useD2CBaseUrl()
  const order = useSelector(state => state.order.selectedOrder)
  useEffect(() => {
    // 處理堂食的訂單
    // 跳轉到 order tracking 的條件：order.status 不是 cancel 和 payments 中有成功的付款
    // Router 有專門處理訂單 cancel 的 useEffect 所以這裡不處理
    if (order?.deliveryStatus === 'table' && order?.status !== 'cancelled' && order?.payments?.some(payment => payment.status === 'paid')) {
      history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true })
    }
  }, [order])
}
