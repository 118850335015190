import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @typedef PromoTagProps
 * @property {string} text
 * @param {PromoTagProps} props
 * @returns
 */
export default function PromoTag (props) {
  const { text } = props
  const classes = useStyles(props)

  if (!text) return null
  return (
    <Typography component='li' className={classes.promoTag} {...props}>{text}</Typography>
  )
}

const useStyles = makeStyles(theme => ({
  promoTag: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-start',
    fontSize: 15,
    minHeight: 20,
    color: theme.palette.common.white,
    backgroundColor: '#E89105',
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 24,
    padding: theme.spacing(0, 2, 0, 2),
    boxShadow: '0 0 1px 1px lightYellow',
  },
}))
