import { Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import produce from 'immer'

import { actions, useDispatch, useSelector } from '@/redux'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import { useRestaurantFilters } from '@/hooks//queries/useLandingSettingsQuery'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import districtOptions from '@/constants/districts'

import ClearButton from './ClearButton'
import FilterSection from './FilterSection'

export default function Filter (props) {
  useOnStatusTapToPageTop({ offset: -8 })

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles(props)
  const { t } = useTranslation()

  const restaurantFilters = useRestaurantFilters()
  const groupedFilters = useMemo(() => _.groupBy(restaurantFilters, 'title'), [restaurantFilters])
  const fields = Object.keys(groupedFilters)

  const filters = useMemo(() => {
    const result = _.map(fields, (field, index) => ({
      id: index,
      field: field, // e.g. '價格'
      options: _.map(groupedFilters[field], option => option.payload), // e.g.  ['200-300', '300-400']
      singleSelect: false,
    }))

    // 加入寫死的 district filter
    result.push({
      id: _.size(result) + 1,
      field: t('app.page.filter.district.title'),
      options: _.reduce(districtOptions, (acc, value, key) => {
        acc[t(`app.page.filter.region.${key}`)] = _.map(value, district => t(`app.page.filter.district.${district}`))
        return acc
      }, {}),
      singleSelect: false,
    })

    return result
  }, [fields])

  const defaultFilter = useMemo(() => _
    .chain(fields)
    .reduce((acc, field) => {
      acc[field] = []
      return acc // e.g. { '價格': [], '菜式': [] }
    }, {})
    .set(t('app.page.filter.district.title'), []) // 加入寫死的 district filter
    .value()
  , [fields])

  const [selectedFilter, setSelectedFilter] = useState(defaultFilter)

  /**
   * Restore Selected Filter From Redux
   */
  const reduxFilter = useSelector(state => state.landing.filter)
  useEffect(() => {
    const restoredFilter = { ...defaultFilter }
    _.forEach(reduxFilter, (filter, field) => {
      restoredFilter[field] = filter
    })
    setSelectedFilter(restoredFilter)
  }, [])

  /**
   * @function
   * @param {string} field
   * @param {string} option
   */
  const onSelectFilter = (field, option) => {
    const index = selectedFilter[field].findIndex(o => o === option)
    const filter = _.filter(filters, filter => filter.field === field)[0]

    if (index >= 0) {
      setSelectedFilter(
        produce(draft => {
          draft[field].splice(index, 1)
        }))
    } else if (filter.singleSelect) {
      setSelectedFilter(
        produce(draft => {
          draft[field] = [option]
        }))
    } else {
      setSelectedFilter(produce(draft => {
        draft[field].push(option)
      }))
    }
  }

  const onClearFilters = () => {
    dispatch(actions.landing.resetFilter())
    setSelectedFilter(defaultFilter)
  }

  const onApplyFilter = () => {
    dispatch(actions.landing.applyFilter(selectedFilter))

    // 若是從搜尋頁面來，用goBack()
    if (location?.state?.prevPath === '/search') {
      history.goBack()
    } else {
      history.replace('/search')
    }
  }

  const filterNotEmpty = !_.every(selectedFilter, field => _.isEmpty(field))

  return (
    <Page>
      <Navbar
        title={t('app.page.filter.title')}
        // 只有filter不空白時才顯示 "清除選項"
        rightColumn={<ClearButton hidden={!filterNotEmpty} onClick={onClearFilters} />}
      />
      <PageContainer className={classes.container}>
        {_.map(filters, filter => {
          return (
            <FilterSection
              key={filter.id}
              field={filter.field}
              filterOptions={filter.options}
              selectedOptions={selectedFilter[filter.field]}
              onSelect={onSelectFilter}
              singleSelect={filter.singleSelect}
            />
          )
        })}
        <div className={classes.footerHolder} />
        <Slide direction='up' in={filterNotEmpty}>
          <footer className={classes.footerWrap}>
            <LetterSpacingButton
              className={classes.confirmButton}
              text={t('app.common.apply')}
              onClick={onApplyFilter}
            />
          </footer>
        </Slide>
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
  },
  footerHolder: {
    flexShrink: 0,
    minHeight: 64,
    paddingBottom: constants.app.safeArea.bottom,
  },
  footerWrap: {
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'grid',
    padding: theme.spacing(2),
  },
  confirmButton: {
    marginBottom: constants.app.safeArea.bottom,
  },
  buttonText: {
    letterSpacing: theme.spacing(1),
    fontWeight: 'bold',
  },
}))
