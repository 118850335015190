import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'

import { actions } from '@/redux'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import logger from '@/libs/logger'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

export default function Payment3DS () {
  const history = useHistory()
  const dispatch = useDispatch()
  const url3ds = useSelector(state => state.payment.url3DS2c2p)
  const d2cBaseUrl = useD2CBaseUrl()
  const classes = useStyles()

  useEffect(() => {
    logger.log('[Payment3DS] useEffect', { url3ds, d2cBaseUrl })

    if (!url3ds) {
      if (d2cBaseUrl) {
        history.replace(d2cBaseUrl)
      } else {
        history.replace('/restaurants')
      }

      return
    }

    const handlePaymentPostMessages = ({ data, origin }) => {
      const { paymentResult } = data
      const whitelistOrigins = [
        'https://sandbox-pgw-ui.2c2p.com',
        'https://pgw-ui.2c2p.com',
      ]

      if (whitelistOrigins.includes(origin)) {
        logger.log('[payment3DS] onMessage', { data, origin })
        if (paymentResult) {
          if (paymentResult.respCode === '2000') {
            dispatch(actions.payment.update2c2p3DSUrl(''))
            // 前往 /order_tracking 並結束點餐
            history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true })
          }
        }
      }
    }

    // Subscribe on post messages
    window.addEventListener('message', handlePaymentPostMessages)

    return () =>
      window.removeEventListener('message', handlePaymentPostMessages)
  }, [])

  return (
    <Page>
      <PageContainer className={classes.container}>
        <iframe
          style={{
            width: '100%',
            height: '100vh',
            border: 'none',
          }}
          referrerPolicy='no-referrer-when-downgrade'
          src={url3ds}
        />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.safeArea.top,
    padding: 0,
  },
}))
