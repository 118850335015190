import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

GroupContainer.propTypes = {
  children: PropTypes.any,
}

/**
 *
 * @param {{
 * children?: Any
 * }} props
 */
export default function GroupContainer (props) {
  const { children } = props
  const classes = useStyles(props)

  return (
    <li className={classes.container}>
      {children}
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))
