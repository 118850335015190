import { Card, CardActionArea, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import { isPoonChoiCategory } from '@/libs/poonchoi'
import { useCart } from '@/hooks/useCart'
import { useMerchant } from '@/hooks/merchant'
import { useParams } from '@/hooks/app'
import DeliveryTypes from '@/constants/deliveryType'

import Comment from './Comment'
import DeliveryFeeTag from './DeliveryFeeTag'
import Distance from './Distance'
import FavoriteIcon from './FavoriteIcon'
import FeatureTags from './FeatureTags'
import PromoTags from './PromoTags'
import RestaurantImage from './RestaurantImage'
import RestaurantLogo from './RestaurantLogo'
import TimeTag from './TimeTag'

// eslint-disable-next-line no-unused-vars
import { ILandingRestaurant } from '@/redux/landing/Landing.d'

export const RESTAURANT_CARD = {
  card: {
    sm: {
      width: 300, // 1280px 的 container 可以平衡的顯示 4 張
      borderRadius: 12,
    },
    md: {
      width: 360,
      borderRadius: 12,
    },
    gutter: 8,
    borderRadius: 12,
  },
  coverImage: {
    aspectRatio: 2.4,
  },
}

const DEFAULT_CARD_SIZE = 'md'

/**
 *
 * poonchoi 的話不顯示關店
 * @typedef RestaurantCardProps
 * @property {ILandingRestaurant} [restaurant]
 * @property {ILandingRestaurant} [item] // InfiniteQueryList 時透過 item 傳入 restaurant
 * @property {'sm' | 'md'} [size = 'md']
 * @property {boolean} [loading = false]
 * @property {CSSStyleDeclaration} [style]
 * @param {RestaurantCardProps} props
 * @returns
 */
export default function RestaurantCard (props) {
  const { restaurant, item, size = DEFAULT_CARD_SIZE, loading = false, style = {} } = props
  const data = restaurant || item || {}
  const {
    id,
    name,
    coverImage,
    logoImage,
    featuredTags,
    comment,
    open,
    deliveryTime,
    deliveryPrice,
    distance,
    nextStartOpening,
    pickerRange,
  } = data

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const params = useParams()
  const merchant = useMerchant()
  const { hasItemsInCart } = useCart()
  const categoryName = match.params.categoryName || history.location.state?.category?.name
  const isPoonchoi = isPoonChoiCategory(categoryName)
  const deliveryFee = isPoonchoi ? deliveryPrice?.poonchoi : deliveryPrice?.normal
  const cardWidth = RESTAURANT_CARD.card[size]?.width || RESTAURANT_CARD.card[DEFAULT_CARD_SIZE].width
  const cardStyle = { ...style, width: cardWidth }
  const classes = useStyles({ ...props, cardWidth })

  const handleEnterRestaurant = (isNewOrder) => {
    if (isPoonchoi) {
      if (params.table) {
        // 原本是內用，先清除 batch
        dispatch(actions.orderBatch.resetBatch())
      }
      dispatch(actions.app.updateParams({
        ...params,
        table: undefined,
        orderId: undefined,
        categoryTag: '盆菜',
        deliveryType: DeliveryTypes.STORE_DELIVERY,
      }))
    } else {
      dispatch(actions.app.updateParams({
        ...params,
        categoryTag: undefined,
      }))
    }
    dispatch(actions.app.resetDrawers())
    if (isNewOrder) {
      dispatch(actions.orderBatch.resetBatch())
      dispatch(actions.order.createLocalOrder())
    }
    history.push(`/restaurant/${id}`)
  }

  const handleCardClick = () => {
    const isDifferentMerchant = id !== merchant?.id
    const isDifferentCategoryTag = params.categoryTag !== (isPoonchoi ? '盆菜' : undefined)
    const isPreOrder = pickerRange?.preOrderDay && pickerRange?.preOrderDay > 0

    if (!isPoonchoi) {
      // 非盆菜才要檢查 open

      // 未營業且找不到下一次開門時間，不能點進去餐廳
      // 例：有開外送或外帶的功能，但是時段設定裡沒有找到任何符合的時段
      if (!open && !nextStartOpening && !isPreOrder) return
    }

    if (hasItemsInCart && isDifferentMerchant) {
      // 購物車有東西且進入了不同的餐廳
      dispatch(actions.app.toggleAlert({
        title: t('app.component.alert.difference_restaurant_reset_batch.title'),
        message: t('app.component.alert.difference_restaurant_reset_batch.message'),
        onConfirm: () => handleEnterRestaurant(true),
      }))
      return
    }
    if (hasItemsInCart && isDifferentCategoryTag) {
      // 購物車有東西但 categoryTag 不同
      dispatch(actions.app.toggleAlert({
        title: t('app.component.alert.difference_menu_reset_batch.title'),
        message: t('app.component.alert.difference_menu_reset_batch.message'),
        onConfirm: () => handleEnterRestaurant(true),
      }))
      return
    }

    handleEnterRestaurant(false)
  }

  if (loading) {
    return <RestaurantCardSkeleton style={style} size={size} />
  }

  // restaurant 為 undefined 時，顯示空 div 保持 Infinite每個 row 的數量不變
  if (!id) {
    return <div style={cardStyle} />
  }

  return (
    <Card
      classes={{ root: classes.cardRoot }}
      elevation={0}
      onClick={handleCardClick}
      style={cardStyle}
    >
      <CardActionArea className={classes.cardActionArea}>
        <RestaurantImage image={coverImage} open={isPoonchoi ? true : open} nextStartOpening={nextStartOpening} pickerRange={pickerRange} />
        <RestaurantLogo image={logoImage} open={isPoonchoi ? true : open} />
        <FavoriteIcon key={id} merchantId={id} open />
        <PromoTags merchantId={id} open={open} hidden={isPoonchoi} />
        <TimeTag time={deliveryTime} open={open} hidden={isPoonchoi} />
        <DeliveryFeeTag amount={deliveryFee} hidden={!isPoonchoi} />
      </CardActionArea>
      <section className={classes.content}>
        <Typography className={classes.title}>{name}</Typography>
        <FeatureTags tags={featuredTags} />
        <Comment comment={comment} />
        <Distance distance={distance} />
      </section>
    </Card>
  )
}

/**
 * @typedef RestaurantCardSkeletonProps
 * @property {'sm' | 'md'} size
 * @param {RestaurantCardSkeletonProps} props
 * @returns
 */
export function RestaurantCardSkeleton (props) {
  const { size = DEFAULT_CARD_SIZE } = props
  const cardWidth = RESTAURANT_CARD.card[size]?.width || RESTAURANT_CARD.card[DEFAULT_CARD_SIZE].width
  const cardHeight = cardWidth / RESTAURANT_CARD.coverImage.aspectRatio

  return (
    <div {...props}>
      <Skeleton width={cardWidth} height={cardHeight} variant='rect' style={{ borderRadius: RESTAURANT_CARD.card.md.borderRadius }} />
      <Skeleton width={260} height={32} variant='text' />
      <Skeleton width={280} height={24} variant='text' />
    </div>
  )
}

const useStyles = makeStyles((theme, props) => ({
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: RESTAURANT_CARD.card.borderRadius,
  },
  cardActionArea: {
    position: 'relative',
    borderRadius: RESTAURANT_CARD.card.borderRadius,
  },
  content: {
    display: 'grid',
    padding: 8,
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: '500',
  },
}))
