import React from 'react'
import clsx from 'clsx'
// eslint-disable-next-line no-unused-vars
import { Typography, TypographyProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useLangLetterSpacing } from '@/hooks/useLangLetterSpacing'

/**
 *
 * @param {TypographyProps & { title: string }} props
 * @returns
 */
export default function PageHeader (props) {
  const { title, className, ...rest } = props
  const classes = useStyles(props)
  const letterSpacing = useLangLetterSpacing({ zh: '0.75rem' })
  return (
    <Typography variant='h5' className={clsx(classes.pageHeader, className)} style={{ letterSpacing }} {...rest}>
      {title || props.children}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  pageHeader: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    padding: theme.spacing(2),
    textTransform: 'uppercase',
  },
}))
