
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useMode } from '@/hooks/useWindowSize'

import { RESTAURANT_CARD } from '@/components/Cards/RestaurantCard'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'

export const FIND_MORE_CARD = { width: 125 }

/**
   * 用於垂直顯示的餐廳卡片列表
   * 若該餐廳分類有更多餐廳可以查看則顯示這張卡片
   * 點擊後前往該分類的頁面查看所有屬於該分類的餐廳
   * @typedef FindMoreCard
   * @property {boolean} [hidden = false]
   * @property {() => void} [onFindOutMore]
   * @param {FindMoreCard} props
   * @returns
   */
export function FindMoreFooterButton (props) {
  const { hidden = false, onFindOutMore = () => {} } = props
  const { t } = useTranslation()
  const { isMobile } = useMode()

  if (hidden) return null
  return (
    <LetterSpacingButton
      text={t('app.common.find_more')}
      onClick={onFindOutMore}
      style={{
        width: isMobile ? RESTAURANT_CARD.card.md.width : '100%',
        marginBottom: 40, // 避免右下角的掃碼入座按鈕蓋到
      }}
    />
  )
}

/**
 * 用於水平捲動的餐廳卡片列表
 * 若該餐廳分類有更多餐廳可以查看則顯示這張卡片
 * 點擊後前往該分類的頁面查看所有屬於該分類的餐廳
 * @typedef FindMoreCard
 * @param {FindMoreCard} props
 * @property {boolean} [hidden = false]
 * @property {() => void} [onFindOutMore]
 * @property {CSSStyleDeclaration} [style]
 * @returns
 */
export function FindMoreCard (props) {
  const { hidden = false, onFindOutMore = () => {}, style = {} } = props
  const { t } = useTranslation()
  const cardWidth = RESTAURANT_CARD.card.sm.width
  const cardHeight = cardWidth / RESTAURANT_CARD.coverImage.aspectRatio

  if (hidden) return null
  return (
    <LetterSpacingButton
      text={t('app.common.find_more')}
      onClick={onFindOutMore}
      style={{
        ...style,
        minWidth: FIND_MORE_CARD.width,
        height: cardHeight,
        marginRight: 8,
      }}
    />
  )
}
