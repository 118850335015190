import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'
import _ from 'lodash'
import dimboxCutOfTime from '@/constants/dimboxCutOfTime'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { isPoonChoiCategoryTag, poonchoiTimeslots } from '@/libs/poonchoi'
import { isToday } from '@/libs/date'
import constants from '@/constants'

import { useDatetime, useDeliveryType, useLoadings, useParams, useTable } from '@/hooks/app'
import { useIsDimbox } from '@/hooks/dimbox'
import { useMerchant } from '@/hooks/merchant'
import { useShipping } from '@/hooks/order'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType

/**
 * @param {*} props
 * @returns
 */
export default function Time (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { deliveryType, isTable } = useDeliveryType()
  const merchant = useMerchant()
  const restaurant = useSelector(state => state.landing.restaurant)
  const table = useTable()
  const tableGroup = _.findKey(merchant?.tableGroups, group => _.includes(group, table))
  const shipping = useShipping()
  const { date, time, isImmediate } = useDatetime()
  const shippingTime = _.isNumber(shipping?.shippingTime) ? shipping?.shippingTime : 0
  const isLoading = useLoadings().createShipping
  const { categoryTag } = useParams()
  const isDimbox = useIsDimbox()

  const isPoonchoi = isPoonChoiCategoryTag(categoryTag)

  const renderDatetimeText = () => {
    if (shipping.shippingTime === 'NO_RESULT') return ''
    if (isDimbox) {
      // DimBox 餐廳當日截單時間為 10:30，可於當日 12:00~14:30 取餐。
      // 超過 10:30 下單，取餐時間為隔日 12:00~14:30。
      const cutOffTime = moment().hours(dimboxCutOfTime.hour).minutes(dimboxCutOfTime.minute).seconds(dimboxCutOfTime.second)
      const pickupAt = moment().isAfter(cutOffTime)
        ? moment().hours(12).minutes(0).add(1, 'd')
        : moment().hours(12).minutes(0)
      const month = pickupAt.format('MM')
      const date = pickupAt.format('DD')
      const timeslot = '12:00~14:30'
      return `${month}/${date} ${timeslot}`
    }
    if (isPoonchoi) {
      if (poonchoiTimeslots(`${date} ${time}`)['14~17']) return `${moment(date).format(t('app.common.date_time.format_date'))} ${t('app.common.date_time.afternoon')}`
      if (poonchoiTimeslots(`${date} ${time}`)['17~21']) return `${moment(date).format(t('app.common.date_time.format_date'))} ${t('app.common.date_time.night')}`
    }
    if (isImmediate) return t('app.common.date_time.immediate')
    return isToday(moment(`${date} ${time}`))
      ? moment(`${date} ${time}`).format(t('app.common.date_time.format_time'))
      : moment(`${date} ${time}`).format(t('app.common.date_time.format_date_time'))
  }

  const renderShippingImmediateMinutes = () => {
    if (shipping.shippingTime === 'NO_RESULT') return t('app.component.footer.content.unable_to_deliver')
    if (!restaurant?.pickerRange?.minMinutes == null) return t('app.component.footer.content.unable_to_deliver')
    if (!isImmediate) return null
    if (isLoading) return <CircularProgress style={{ marginLeft: 8 }} size={14} />

    const minutes = restaurant.pickerRange?.minMinutes + shippingTime
    return (
      <Typography
        style={{ marginLeft: 8 }}
        component='span'
        variant='body2'
      >
        {t('app.common.date_time.minute', { minutes })}
      </Typography>
    )
  }

  const renderTakeawayImmediateMinutes = () => {
    if (!restaurant?.pickerRange?.minMinutes == null) return null
    if (!isImmediate) return null
    if (isDimbox) return null
    return (
      <Typography
        style={{ marginLeft: 8 }}
        variant='body2'
        component='span'
      >
        {t('app.common.date_time.minute', { minutes: restaurant.pickerRange?.minMinutes })}
      </Typography>
    )
  }

  const handleClick = () => {
    if (isTable && table) return
    if (isDimbox) return // Dimbox phase 1 不能更改取餐時間
    if (isPoonchoi) {
      dispatch(actions.app.toggleDialog('specialTimePicker', true, { type: 'moon' }))
      return
    }
    dispatch(actions.app.toggleDrawer('datetime', true))
  }

  switch (deliveryType) {
    case STORE_DELIVERY:
      return (
        <section className={classes.section} onClick={handleClick}>
          <Typography className={classes.bold} variant='h6'>
            {t('app.page.checkout.info_card.time.storeDelivery')}
          </Typography>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.time} variant='body2'>
              {renderDatetimeText()}
              {renderShippingImmediateMinutes()}
            </Typography>
          </div>
          <ArrowForwardIosIcon fontSize='small' style={{ placeSelf: 'center' }} />
        </section>
      )
    case TAKEAWAY:
      return (
        <section className={classes.section} onClick={handleClick}>
          <Typography className={classes.bold} variant='h6'>
            {t('app.page.checkout.info_card.time.takeaway')}
          </Typography>
          <Typography className={classes.time} variant='body2'>
            {renderDatetimeText()}
            {renderTakeawayImmediateMinutes()}
          </Typography>
          {!isDimbox && <ArrowForwardIosIcon fontSize='small' style={{ placeSelf: 'center' }} />}

        </section>
      )
    case TABLE:
      return (
        <section className={classes.section} onClick={handleClick}>
          <Typography className={classes.bold} variant='h6'>
            {t('app.page.checkout.info_card.time.table')}
          </Typography>
          {table
            ? (
              <Typography className={classes.time} variant='body2'>
                {`${t('app.constant.table.table_number')}: ${[tableGroup, table].filter(o => o).join(' / ')}`}
              </Typography>
            )
            : <ArrowForwardIosIcon fontSize='small' style={{ placeSelf: 'center' }} />}
        </section>
      )
    default:
      break
  }
}

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 600,
  },
  time: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[700],
  },
}))
