import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useHistory } from 'react-router-dom'
import Card from '@/components/Cards/Card'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import constants from '@/constants'
import customerApi from '@/libs/api/customer'

export default function RedeemCode (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const history = useHistory()

  const [code, setCode] = React.useState('')
  const [error, setError] = React.useState(null)
  const [isLoading, setLoading] = React.useState(false)

  return (
    <Page>
      <Navbar title={t('app.page.redeem.title')} />
      <PageContainer className={classes.container}>
        <Card className={classes.card}>
          <TextField
            size='small'
            fullWidth
            placeholder={t('app.page.redeem.input_placeholder')}
            value={code}
            onChange={event => setCode(event.target.value)}
            error={Boolean(error)}
            helperText={error || ''}
            variant='outlined'
            disabled={isLoading}
          />
          <LetterSpacingButton
            fullWidth
            variant='contained'
            color='primary'
            text={t('app.page.redeem.submit')}
            onClick={async () => {
              setLoading(true)
              try {
                const result = await customerApi.submitRedeemCode(code)
                setCode('')
                dispatch(actions.app.toggleAlert({
                  dialogProps: { disableBackdropClick: true },
                  title: t('app.page.redeem.success_alert.title'),
                  message: `${t('app.page.redeem.success_alert.message_prefix')} ${result.data?.amount || ''} RiceCoin`,
                  buttons: [{ text: t('app.common.confirm'), onClick: () => history.goBack() }],
                }))
              } catch (error) {
                const errorCode = _.get(error.response, 'data.errors[0].title')
                setError(t(`app.page.redeem.${errorCode}`, t('app.page.redeem.error.title')))
              }
              setLoading(false)
            }}
            disabled={isLoading}
          />
        </Card>
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
  },
  card: {
    margin: theme.spacing(1, 0.5),
    padding: theme.spacing(2, 1.5),
    display: 'grid',
    gap: theme.spacing(1.5),
  },
}), { name: 'RedeemCode' })
