import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import Alerts from '@/components/Alerts'
import AppRouter from '@/pages/AppRouter'
import BackdropLoading from '@/components/BackdropLoading'
import Dialogs from '@/components/Dialogs'
import Drawers from '@/components/Drawers'
import Notifier from '@/pages/Notifier'
import QRCodeScanning from '@/pages/QRCodeScanning'
import Snackbars from '@/components/Snackbars'
import config from '@/config'
import constants from '@/constants'

import { useDeliveryType } from '@/hooks/app'
import { useLandingSettingsQuery } from '@/hooks/queries/useLandingSettingsQuery'
import { useMode } from '@/hooks/useWindowSize'
import useScript from '@/hooks/useScript'

export default function Main (props) {
  const { isTablet } = useMode()
  const classes = useStyles({ ...props, isTablet })
  const dispatch = useDispatch()
  const isBackdropLoading = useSelector(state => state.app.loadings.backdrop)
  const isQRCodeScanning = useSelector(state => state.app.isQRCodeScanning)
  const { deliveryType, isTable } = useDeliveryType()
  useLandingSettingsQuery()

  let jsSrcSecureField = config.my2c2p.jsSrcSecureField
  let jsSrcSecurePayment = config.my2c2p.jsSrcSecurePayment

  if (['beta', 'prod'].includes(config.env)) {
    jsSrcSecureField = config.my2c2p.jsSrcSecureFieldLive
    jsSrcSecurePayment = config.my2c2p.jsSrcSecurePaymentLive
  }

  React.useEffect(() => {
    if (!isTable) {
      // CA-1283 當客人不在香港時區時禁止使用
      dispatch(actions.app.checkTimeZone())
    }
  }, [deliveryType])

  useScript(jsSrcSecureField)
  useScript(jsSrcSecurePayment)

  if (isQRCodeScanning) {
    // Native QRCode 掃描會在 WebView 背後，因此不顯示其他 App 內容，只顯示掃描的 UI
    return <QRCodeScanning />
  }

  return (
    <SnackbarProvider
      maxSnack={5}
      classes={{
        root: classes.snackbarItemContnetRoot,
        containerRoot: classes.snackabrContainerRoot,
      }}
    >
      <Notifier />
      <Alerts />
      <Drawers />
      <Dialogs />
      <Snackbars />
      <AppRouter />
      <BackdropLoading open={isBackdropLoading} />
    </SnackbarProvider>
  )
}

const useStyles = makeStyles(theme => ({
  snackabrContainerRoot: {
    paddingTop: constants.app.safeArea.top,
    minWidth: props => props.isTablet && '60%',
  },
  snackbarItemContnetRoot: {
    // fontSize: props => props.mode.isTablet && '2.8rem',
    // '& > div': {
    //   fontSize: 'inherit',
    // },
    // '& .MuiSvgIcon-root': {
    //   fontSize: 'inherit !important',
    // },
  },
}))
