import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Card from './Card'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

RiceCoinRewardCard.propTypes = {
  reward: PropTypes.number,
  className: PropTypes.object,
}

/**
 *
 * @param {{
 *  reward: number
 *  className?: object
 * }} props
 * @returns
 */
export default function RiceCoinRewardCard (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const { reward, className } = props

  // 因為下單後獲取 riceCoin 可能會有延遲所以 ui 上如果是 0 的話不顯示
  if (!reward) return null
  return (
    <Card className={clsx(classes.conainer, className)}>
      <Typography variant='body2'>
        {t('app.component.card.riceCoin_reward_card')}
      </Typography>
      <Typography variant='body2'>
        {reward}
      </Typography>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  conainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
}))
