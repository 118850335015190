
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

/**
 * @param {*} props
 * @returns
*/
export default function ListEmptyComponent (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  return (
    <div className={classes.emptyComponent}>
      <Typography className={classes.emptyText} variant='body2'>{t('app.common.no_record')}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  emptyComponent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  emptyTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))
