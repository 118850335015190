import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import React from 'react'

import { openMap } from '@/libs/googleMap'
import { useMerchant, useAddress as useMerchantAddress } from '@/hooks/merchant'

export default function Address (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const merchant = useMerchant()
  const merchantAddress = useMerchantAddress()
  const handleOpenMap = () => {
    openMap(merchant)
  }

  return (
    <div className={classes.addressWrap}>
      <LocationOnOutlinedIcon fontSize='large' className={classes.icon} />
      <Typography className={classes.address} variant='caption'>
        {merchantAddress}
        <Link
          target='_blank'
          rel='noopener'
          variant='caption'
          color='textSecondary'
          className={classes.link}
          onClick={handleOpenMap}
        >
          {t('app.page.restaurant.merchant_card.google_map')}
        </Link>
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  addressWrap: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
  },
  icon: {
    alignSelf: 'flex-start',
    justifySelf: 'center',
    boxSizing: 'content-box',
    padding: theme.spacing(0.5),
    fontSize: '1.4rem',
  },
  address: {
    flexShrink: 0,
    color: theme.palette.grey[600],
    '& > a': {
      marginLeft: theme.spacing(1),
    },
  },
  link: {
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
