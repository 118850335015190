import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CropFreeIcon from '@material-ui/icons/CropFree'
import QrReader from 'react-qr-reader'
import React, { useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import QRCodeScanning from '@/pages/QRCodeScanning'
import SwipeableDrawer from '@/components/SwipeableDrawer'

export default function QrReaderDrawer (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const open = useSelector(state => state.app.drawers.qrReader.open)

  const [error, setError] = useState(null)

  return (
    <SwipeableDrawer
      open={open}
      onOpen={() => {}}
      onClose={() => dispatch(actions.app.closeQRCodeScanner())}
      paperClassName={classes.drawerPaper}
      anchor='left'
    >
      {error
        ? (
          <div className={classes.errorContainer}>
            <CropFreeIcon style={{ fontSize: 48 }} />
            <Typography>
              {t('app.component.drawer.qr_reader.error_message')}
            </Typography>
            <Typography color='error' variant='caption'>
              {error.message}
            </Typography>
            <LetterSpacingButton
              className={classes.errorCloseButton}
              text={t('app.common.close')}
              onClick={() => dispatch(actions.app.closeQRCodeScanner())}
            />
          </div>
        )
        : (
          <>
            <div className={classes.scannerWrap}>
              <QrReader
                className={classes.scanner}
                showViewFinder={false}
                facingMode='environment'
                delay={300}
                onError={(error) => setError(error)}
                onScan={(data) => {
                  if (data == null) return
                  dispatch(actions.app.handleQRCodeData(data))
                  dispatch(actions.app.closeQRCodeScanner())
                }}
              />
            </div>
            <QRCodeScanning />
          </>
        )}
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    width: '100%',
    padding: 0,
  },
  scannerWrap: {
    zIndex: 100,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  scanner: {
    width: '100vw',
    height: '100vh',
    '& > section': {
      paddingTop: '100vh !important',
    },
  },
  errorContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(4),
    gap: theme.spacing(2),
  },
  errorIcon: {
    alignSelf: 'center',
  },
  errorCloseButton: {
    alignSelf: 'center',
  },
}))
