import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import { useDeliveryType } from '@/hooks/app'
import PageTabsBase from '@/components/Page/PageTabs/PageTabsBase'
import constants from '@/constants'

const { TABLE } = constants.deliveryType

/**
 *
 * @typedef DeliveryTypeTabsProps
 * @property {TDeliveryType[]} deliveryTypes
* @property {React.Dispatch<React.SetStateAction<{date: string | undefined; time: string | undefined; isImmediate: boolean | undefined}>>} [setTempDatetime]
 * @property {React.Dispatch<React.SetStateAction<{date: string | undefined; time: string | undefined; isImmediate: boolean | undefined}>>} setTempDatetime
 * @property {string} [className]
 * @param {DeliveryTypeTabsProps} props
 * @returns
 */
export default function DeliveryTypeTabs (props) {
  const { t } = useTranslation()
  const { deliveryTypes, setTempDeliveryType, setTempDatetime, className } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { deliveryType } = useDeliveryType()
  const [selectedTab, setSelectedTab] = useState(0)

  const tabs = useMemo(() => {
    return _.map(deliveryTypes, deliveryType => ({
      deliveryType: deliveryType,
      title: t(`app.constant.deliveryType.${deliveryType}`),
      onClick: () => handleTabClick(deliveryType),
    }))
  }, [deliveryTypes])

  function getTabIndex (deliveryType) {
    return _.findIndex(tabs, tab => tab.deliveryType === deliveryType)
  }

  function handleTabClick (deliveryType) {
    const tabIndex = getTabIndex(deliveryType)
    setSelectedTab(tabIndex)
    if (setTempDeliveryType) {
      setTempDeliveryType(deliveryType)
      // 如果選擇內用則設定時間為即時
      if (setTempDatetime && deliveryType === TABLE) {
        const now = moment()
        setTempDatetime({
          date: now.format('YYYY-MM-DD'),
          time: now.format('HH:mm'),
          isImmediate: true,
        })
      }
    } else {
      dispatch(actions.app.updateDeliveryType(deliveryType))
    }
  }

  // 當 deliveryType 改變時同步設定 selectedTab
  useEffect(() => {
    if (deliveryType) {
      const tabIndex = getTabIndex(deliveryType)
      if (tabIndex >= 0) { setSelectedTab(tabIndex) }
    }
  }, [deliveryType])

  return (
    <PageTabsBase
      tabs={tabs}
      selectedTab={selectedTab}
      className={clsx(classes.tabs, className)}
    />
  )
}

const useStyles = makeStyles(theme => ({
  tabs: {

  },
}))
