import moment from 'moment'

import { actions } from '@/redux'
import { formatDatetimeToMoment } from '@/libs/date'
import config from '@/config'
import constants from '@/constants'
import history from '@/libs/history'
import landingApi from '@/libs/api/landing'

import ActionTypes from './ActionTypes'

const { TABLE } = constants.deliveryType

/**
 *
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    dispatch({ type: ActionTypes.INIT })

    // 若有 pendingMarketingAction 且 app 已經 init，處理後移除
    const appIsInit = getState().app.isInit
    const pendingMarketingAction = getState().app.pendingMarketingAction
    if (appIsInit && pendingMarketingAction) {
      dispatch(actions.app.handleMarketingAction(pendingMarketingAction.type, pendingMarketingAction.payload))
      dispatch(actions.app.removePendingMarketingAction())
    }
  }
}

/**
 * useLandingSettingsQuery onSuccess 時存取一份 merchantPromocodes 到 redux
 * 讓 autoFillPromocode 能使用 getState 得到資料
 * @param {*[]} merchantPromocodes
 * @returns {ThunkFunction}
 */
export function updateMerchantPromocodes (merchantPromocodes) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_MERCHANT_PROMOCODES,
      payload: { merchantPromocodes },
    })
  }
}

/**
 * @param {string} merchantId
 * @returns {ThunkFunction}
 */
export function fetchRestaurant (merchantId) {
  return async (dispatch, getState) => {
    const deliveryType = getState().app.params.deliveryType
    const latlng = getState().user.address?.latlng
    const datetime = getState().app.params.datetime
    const isImmediate = datetime.isImmediate
    const time = isImmediate ? moment().format() : formatDatetimeToMoment(datetime).format()
    const testDate = getState().app.test.date
    const testTime = getState().app.test.time
    const isInCategory = history.location.pathname.includes('/category')

    // 內用可以不用 latlng
    // /category 直接進來的可以不用 latlng
    if (deliveryType !== TABLE && !merchantId && !isInCategory && !latlng) return

    const params = {
      full: true,
      open: true,
      from: 'app',
      type: deliveryType,
      time: time,
      orderTimestamp: config.env !== 'prod' ? moment(`${testDate} ${testTime}`).format('X') : undefined,
      merchantId,
    }

    if (config.debugMode) {
      // landing 顯示測試餐廳
      params.test = 1
    }

    if (latlng) {
      params.lat = latlng.lat
      params.lng = latlng.lng
    }

    dispatch(actions.app.updateLoading('landing', true))

    try {
      const restaurants = await landingApi.fetchRestaurants(params)
      if (restaurants[0]) {
        dispatch({
          type: ActionTypes.UPDATE_RESTAURANT,
          payload: { restaurant: restaurants[0] },
        })
      }

      // 記錄最後 getRestaurants 用的 params
      dispatch({
        type: ActionTypes.UPDATE_FETCH_RESTAURANTS_PARAMS,
        payload: {
          fetchRestaurantsParams: {
            merchantId,
            deliveryType,
            latlng,
          },
        },
      })
    } catch (error) {
      console.error('get landing restaurants error', error)
    }

    dispatch(actions.app.updateLoading('landing', false))
  }
}

/**
 * 更新search text
 * @param {string} searchText
 */
export function updateSearchText (searchText) {
  return {
    type: ActionTypes.UPDATE_SEARCH_TEXT,
    payload: { searchText },
  }
}

/**
 * 清除search text
 * @param {string} searchText
 */
export function resetSearchInput () {
  return (dispatch, getState) => {
    dispatch(updateSearchText(''))
  }
}

/**
 * 套用篩選
 * @param {string} filter
 */
export function applyFilter (filter) {
  return {
    type: ActionTypes.UPDATE_FILTER,
    payload: { filter },
  }
}

/**
 * 清除篩選
 * @returns {ThunkFunction}
 */
export function resetFilter () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter: {} },
    })
  }
}

/**
 * @param {string} payload
 * @returns {ThunkFunction}
 */
export function redirectToCuisine (payload) {
  return (dispatch, getState) => {
    history.push('/cuisine', { cuisine: { payload } })
  }
}

/**
 * @param {string} name
 * @returns {ThunkFunction}
 */
export function redirectToCategory (name) {
  return (dispatch, getState) => {
    history.push('/category', { category: { name } })
  }
}
