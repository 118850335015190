import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useMerchantPromocodeBannerText } from '@/hooks/queries/useLandingSettingsQuery'

import PromoTag from './PromoTag'

/**
 * @typedef PromoTagsProps
 * @property {string} merchantId
 * @property {boolean} open
 * @property {boolean} [hidden = false]
 * @param {PromoTagsProps} props
 * @returns
 */
export default function PromoTags (props) {
  const { merchantId, open, hidden = false } = props
  const classes = useStyles(props)
  const bannerText = useMerchantPromocodeBannerText(merchantId)

  if (hidden) return null
  if (!open) return null
  return (
    <ul className={classes.promoTagsWrap}>
      <PromoTag text={bannerText} />
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  promoTagsWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(0.5),
    width: '80%',
  },
}))
