import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { isOptionsComplete } from '@/libs/order'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import OptionGroup from '@/components/Groups/OptionGroup'
import hooks from '@/hooks'

import DialogBase from './DialogBase'

export default function SetItemOptionDialog (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const menuQuery = useMenuQuery()
  const sets = menuQuery.data?.sets
  const open = useSelector(state => state.app.dialogs.setItemOption.open)
  const setMenu = useSelector(state => state.app.dialogs.setItemOption.data.setMenu) ?? {} // IAppSet
  const setItemMenu = useSelector(state => state.app.dialogs.setItemOption.data.setItemMenu) ?? {} // IAppMenuItem
  useEffect(() => { open && dispatch(actions.batchItemTemp.addSetItem(setItemMenu)) }, [setItemMenu])

  /**
   * @function
   * @param {boolean?} submit
   */
  const handleClose = (submit) => {
    // 如果點開第一步被獨立拆出來成套餐的餐點有選項群則同時會打開 setItemOption Dialog
    // 如果點 backdrop 關閉 Dialog 的話就連 setItem Drawer 一起關閉
    // 如果點 submit 的話就只要關閉 setItemOption Dialog
    if (!submit) {
      if (setItemMenu.step === setMenu?.separatedStep) {
        dispatch(actions.app.toggleDrawer('setItem', false))
        setTimeout(() => {
          dispatch(actions.app.toggleDrawer('setItem', false, {}))
          // 200ms delay for drawer close transition duration
        }, 200)
      }
    }

    dispatch(actions.app.toggleDialog('setItemOption', false))
    setTimeout(() => {
      dispatch(actions.app.toggleDialog('setItemOption', false, {}))
      // 200ms delay for dialog close transition duration
    }, 200)
  }

  // IMenuOptionGroup
  const options = setItemMenu?.options

  // IAppBatchItem
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const batchSetItemTemp = hooks.batchItemTemp.useBatchSetItemTemp()
  const setItems = batchItemTemp.setItems ? [...batchItemTemp.setItems] : []
  const isComplete = open && !_.isEmpty(batchSetItemTemp) && isOptionsComplete(batchSetItemTemp, setItemMenu)
  const isMax = useSelector(state => state.app.dialogs.setItemOption.data.isMax) ?? false

  const handleAddSetItem = () => {
    if (isMax) {
      // 判斷該步驟是否已達上限，若已達上限則移除其他同步驟的餐點
      // Radio 才會有這個情況
      // Checkbox 因為步驟 isMax 後其他未被選擇的餐點會被 disabled 沒辦法點，所以沒有需要移除的問題
      _.remove(setItems, setItem => setItem.step === setItemMenu.step)
    }
    setItems.push(batchSetItemTemp)
    dispatch(actions.batchItemTemp.updateSetItems(setItems))
    dispatch(actions.batchItemTemp.cleanUpDependentSetItems(sets))
  }

  const handleSubmit = () => {
    handleAddSetItem()
    handleClose(true)
  }

  return (
    <DialogBase
      open={open}
      onClose={() => handleClose(false)}
      title={t('app.component.dialog.setItem_option_dialog.custom')}
      renderContent={() => (
        <ul className={classes.groupList}>
          {
            _.map(options, (optionGroup, index) => (
              <OptionGroup
                key={index}
                group={optionGroup}
                menu={setItemMenu}
              />
            ))
          }
        </ul>
      )}
      renderFooter={() => (
        <LetterSpacingButton
          text={t('app.common.confirm')}
          disabled={!isComplete}
          onClick={handleSubmit}
        />
      )}
    />
  )
}

const useStyles = makeStyles(theme => ({
  groupList: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(1),
    minHeight: 160,
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    overflow: 'auto',
    '& p': {
      fontSize: '1.4rem',
    },
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}))
