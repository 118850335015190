import { Switch as MuiSwitch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Switch (props) {
  const classes = useStyles(props)

  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    transform: 'translateX(1px) translateY(1px)',
    '&$checked': {
      transform: 'translateX(17px) translateY(1px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.info.light,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.info.light,
      border: 'none',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 20 / 2,
    border: 'none',
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))
