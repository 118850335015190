import { Button, InputAdornment, TextField, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LoginPage from './LoginPage'
import Next from './Next'
import React, { useState } from 'react'
import _ from 'lodash'

export default function StepPhone (props) {
  const { t } = useTranslation()
  const { doNext, setInfo } = props

  const dispatch = useDispatch()
  const classes = useStyles(props)

  const [country] = useState('852')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState({})

  const validate = (targets) => {
    const keys = Object.keys(targets)
    const error = {}
    _.forEach(keys, key => {
      switch (key) {
        case 'phone':
          if (!/^[4-9]\d{7}/.test(targets.phone)) {
            error.phone = t('app.component.drawer.login_drawer.error_phone')
          }

          break

        default:
          break
      }
    })
    setError(error)
  }

  const disableSubmit = !phone || !_.isEmpty(error)

  const handleSubmit = () => {
    setInfo({ country, phone })
    doNext()
  }

  const handleSkip = () => dispatch(actions.app.toggleLoginDrawer(false))

  return (
    <LoginPage>
      <div id='nav-bar'>
        <Button onClick={handleSkip}>
          <Typography>{t('app.common.cancel')}</Typography>
        </Button>
      </div>
      <div id='title-group'>
        <Typography id='title'>{t('app.component.drawer.login_drawer.title')}</Typography>
        <Typography id='description'>
          {t('app.component.drawer.login_drawer.subtitle')}
        </Typography>
      </div>
      <div className={classes.form}>
        <TextField
          size='small'
          type='phone'
          placeholder={t('app.component.drawer.login_drawer.phone')}
          value={phone}
          onChange={e => {
            validate({ phone: e.target.value })
            setPhone(e.target.value)
          }}
          variant='outlined'
          inputProps={{
            maxLength: 8,
            type: 'tel',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <div style={{
                  color: '#333333',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                >
                  <span>🇭🇰 {country}</span>
                  <div className={classes.inputLine} />
                </div>
              </InputAdornment>),
          }}
          error={Boolean(error.phone)}
          helperText={error.phone}
        />
      </div>
      <Next
        onClick={handleSubmit}
        disabled={disableSubmit}
      />
    </LoginPage>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 65,

    '& > *': {
      width: '100%',
    },
  },
  inputLine: {
    marginLeft: 10,
    minWidth: 2,
    minHeight: 40,
    backgroundColor: '#e0e0e0',
  },
}))
