import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import Cart from '@/pages/Cart'
import SwipeableDrawer from '@/components/SwipeableDrawer'

export default function CartDrawer (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const open = useSelector(state => state.app.drawers.cart.open)
  const openDrawer = () => dispatch(actions.app.toggleDrawer('cart', true))
  const closeDrawer = () => dispatch(actions.app.toggleDrawer('cart', false))

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='left'
    >
      <Cart />
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '100%',
    padding: 0,
  },
}))
