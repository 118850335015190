import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import { IDatetime } from '@/redux/app/AppState.d'

export function isToday (date) {
  return moment(date).isSame(moment(), 'day')
}

/**
   * @function
   * @param {number} seconds
   * @param {'ceil' | 'floor' | 'round'} methods
   * @returns {number}
   */
export function secondsToMinutes (seconds, methods) {
  switch (methods) {
    case 'ceil':
      return Math.ceil(moment.duration(seconds * 1000).asMinutes())
    case 'floor':
      return Math.floor(moment.duration(seconds * 1000).asMinutes())
    case 'round':
      return Math.round(moment.duration(seconds * 1000).asMinutes())
    default:
      return Math.ceil(moment.duration(seconds * 1000).asMinutes())
  }
}

export function roundToNearestTimeslot (date) {
  const start = moment(date || moment())
  const remainder = 15 - (start.minute() % 15)
  return moment(start).add(remainder, 'minutes')
}

/**
 * @param {IDatetime} datetime
 * @returns {string} ISOString date
 */
export function formatDatetimeToISOString (datetime) {
  const { date, time } = datetime
  return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toISOString()
}

/**
 * @param {IDatetime} datetime
 * @returns {Moment} moment
 */
export function formatDatetimeToMoment (datetime) {
  if (!datetime?.date || !datetime?.time) return moment()
  return moment(`${datetime.date} ${datetime.time}`, 'YYYY-MM-DD HH:mm')
}

export default {
  isToday,
  secondsToMinutes,
  roundToNearestTimeslot,
  formatDatetimeToISOString,
}
