import { Collapse, List } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { calculateSetItemIsMax, calculateStepIsMax, createBatchSetItem, getStepQuantityMax, getStepQuantityMin } from '@/libs/order'
import { checkIsSold } from '@/libs/menu'
import { useBatchItemTemp } from '@/hooks/batchItemTemp'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import { useMerchant } from '@/hooks/merchant'

import ExpandButton from '@/components/Drawers/common/ExpandButton'
import GroupItem from '@/components/Groups/GroupItem'

/* eslint-disable no-unused-vars */
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'
import { IAppSetStep } from 'dimorder-orderapp-lib/dist/types/AppMenu'
/* eslint-enable no-unused-vars */

/**
 * @typedef StepItemListProps
 * @property {IAppSet} step
 * @property {IAppMenuItem[]} setMenuItems
 * @property {number} [max = 5]
 * @param {StepItemListProps} props
 * @returns
 */
export default function StepItemList (props) {
  const { step, setMenuItems, max = 5 } = props
  useAutoSelect(step, setMenuItems)
  const [expanded, setExpanded] = useState(false)

  return (
    <List>
      <StepItems step={step} setMenuItems={setMenuItems} max={max} />
      <Collapse in={expanded}>
        <StepItems step={step} setMenuItems={setMenuItems} max={max} isInCollapse />
      </Collapse>
      <Collapse in={!expanded && setMenuItems.length > max}>
        <ExpandButton number={setMenuItems.length - max} onClick={() => setExpanded(true)} />
      </Collapse>
    </List>
  )
}

/**
 *
 * @param {IAppSetStep} setStep
 * @param {IAppMenuItem[]} setMenuItems
 * @returns
 */
function useAutoSelect (setStep, setMenuItems) {
  const dispatch = useDispatch()
  // const batchSetItemTemp = hooks.batchItemTemp.useBatchSetItemTemp()
  const stepMax = getStepQuantityMax(setStep)
  const stepMin = getStepQuantityMin(setStep)
  const isMultiple = stepMax > 1
  const isInCheckoutEditMode = useSelector(state => Boolean(state.app.drawers.setItem.data.batchItem))

  const autoSelect = useCallback(() => {
    // 只有在步驟上下限相等時要執行自動選
    if (stepMax !== stepMin) return

    // radio 當品項群為單選，且群組品項只有一個
    if (!isMultiple && _.size(setMenuItems) === 1) {
      const hasOptions = !_.isEmpty(setMenuItems[0].options)
      if (!hasOptions) {
        dispatch(actions.batchItemTemp.addSetItems(setMenuItems))
        return
      } else {
        const optionGroups = setMenuItems[0].options

        // 判斷是否每個 optionGrop 都可以被自動選
        const canAutoSelectEveryOptionGrous = _.every(
          optionGroups,
          optionGroup => dispatch(actions.batchItemTemp.canAutoSelectOptions(setMenuItems[0], optionGroup)),
          valid => valid,
        )
        // 若有不能被自動選的 optionGroup 則不執行自動選
        if (!canAutoSelectEveryOptionGrous) return

        // 若能自動選則每個選項群執行自動選
        _.forEach(optionGroups, optionGroup => {
          dispatch(actions.batchItemTemp.canAutoSelectOptions(
            setMenuItems[0],
            optionGroup,
            actions.batchItemTemp.selectSetItemWithAllOptions,
          ))
        })
      }
    }

    // checkbox or stepper 當品項群為複選
    if (isMultiple) {
      // 群組品項只有一個，且該群組品項為複選
      const isStepper = _.size(setMenuItems) === 1 && (setMenuItems[0].max || setStep.max) > 1

      // stepper
      if (isStepper) {
        const hasOptions = !_.isEmpty(setMenuItems[0].options)
        if (!hasOptions) {
          dispatch(actions.batchItemTemp.addSetItems(setMenuItems, stepMin))
          return
        } else {
          const optionGroups = setMenuItems[0].options

          // 判斷是否每個 optionGrop 都可以被自動選
          const canAutoSelectEveryOptionGrous = _.every(
            optionGroups,
            optionGroup => dispatch(actions.batchItemTemp.canAutoSelectOptions(setMenuItems[0], optionGroup)),
            valid => valid,
          )
          // 若有不能被自動選的 optionGroup 則不執行自動選
          if (!canAutoSelectEveryOptionGrous) return

          // 若能自動選則每個選項群執行自動選
          _.forEach(optionGroups, optionGroup => {
            dispatch(actions.batchItemTemp.canAutoSelectOptions(
              setMenuItems[0],
              optionGroup,
              actions.batchItemTemp.selectSetItemWithAllOptions,
              stepMin,
            ))
          })
        }
        return
      }

      // checkbox
      const isCheckbox = _.size(setMenuItems) === stepMin && _.every(setMenuItems, setItem => setItem.max <= 1)
      if (isCheckbox) {
        const hasOptions = Boolean(_.find(setMenuItems, setItem => !_.isEmpty(setItem.options)))
        if (!hasOptions) {
          dispatch(actions.batchItemTemp.addSetItems(setMenuItems))
        } else {
          // 判斷是否每個 setItem (checkbox) 都可以被自動選
          const canAutoSelectEverySetItem = _.every(
            setMenuItems,
            setItem => {
              const optionGroups = setItem.options

              // 判斷是否每個 optionGrop 都可以被自動選
              return _.every(
                optionGroups,
                optionGroup => dispatch(actions.batchItemTemp.canAutoSelectOptions(setItem, optionGroup)),
                valid => valid,
              )
            },
            valid => valid,
          )
          // 若有不能被自動選的 setItem (checkbox) 則不執行自動選
          if (!canAutoSelectEverySetItem) return

          // 若能自動選則每個 setItem 執行自動選
          _.forEach(setMenuItems, setItem => {
            const optionGroups = setItem.options

            // 若有無選項群的品項混在裡面則需自動選取
            if (_.isEmpty(optionGroups)) {
              dispatch(actions.batchItemTemp.selectSetItemWithAllOptions(
                setItem,
                [],
                actions.batchItemTemp.selectSetItemWithAllOptions,
              ))
            }

            // 自動選取該品項並自動選滿其下的可自動選滿之選項群
            _.forEach(optionGroups, optionGroup => {
              dispatch(actions.batchItemTemp.canAutoSelectOptions(
                setItem,
                optionGroup,
                actions.batchItemTemp.selectSetItemWithAllOptions,
              ))
            })
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    // 自動選滿品項群底下的全部的品項（無選項）
    // 自動選滿品項群底下的全部的品項（若品項的選項群每個都可自動選滿）
    // 結帳頁可以點開 setItemDrawr 進行編輯，這時候不能執行自動選
    if (!isInCheckoutEditMode) {
      autoSelect()
    }
  }, [])

  return { autoSelect }
}

/**
 * @typedef StepItemsProps
 * @property {IAppSetStep} step
 * @property {IAppMenuItem[]} setMenuItems
 * @property {number} max
 * @property {boolean} isInCollapse
 * @param {StepItemsProps} props
 * @returns
 */
function StepItems (props) {
  const { step, setMenuItems, max, isInCollapse } = props
  const list = isInCollapse ? _.drop(setMenuItems, max) : _.take(setMenuItems, max)
  return _.map(list, (setItemMenu, index) => {
    return (
      <StepItem
        key={setItemMenu.menuId}
        step={step}
        setItemMenu={setItemMenu}
        setMenuItems={setMenuItems}
      />
    )
  })
}

/**
 * @typedef StepItemProps
 * @property {IAppSetStep} step
 * @property {IAppMenuItem[]} setMenuItems
 * @property {IAppMenuItem} setItemMenu
 * @param {StepItemProps} props
 * @returns
 */
function StepItem (props) {
  const { step, setMenuItems, setItemMenu } = props
  const dispatch = useDispatch()
  const merchant = useMerchant()
  const batchItemTemp = useBatchItemTemp()
  const setItems = batchItemTemp.setItems ? [...batchItemTemp.setItems] : []
  const batchSetItemTemp = createBatchSetItem(setItemMenu)
  const hasOptions = !_.isEmpty(setItemMenu.options)
  const stepMin = getStepQuantityMin(step)
  const stepMax = getStepQuantityMax(step)
  const isSelected = _.some(setItems, setItem => setItem.setMenuId === setItemMenu.id)
  const isMultipleStep = stepMax > 1 // step 是否為多選
  const isMultipleItem = (setItemMenu.max || step.max) > 1 // setItem 是否為多選
  const isRadio = !isMultipleStep // step 為單選時：radio
  const isCheckbox = isMultipleStep && !isMultipleItem // step 為多選，setItem 為單選時：checkbox
  const isStepper = isMultipleStep && isMultipleItem // step 為多選，setItem 為多選時：stepper
  const isStepMax = useMemo(() => calculateStepIsMax(batchItemTemp, step), [batchItemTemp.setItems])
  const isSetItemMax = useMemo(() => calculateSetItemIsMax(batchItemTemp, step, setItemMenu), [batchItemTemp.setItems])
  const isSold = checkIsSold({ merchant, menu: setItemMenu }) // setItem 是否售完
  const menuQuery = useMenuQuery()
  const sets = menuQuery.data?.sets

  const canAutoSelectOptions = useMemo(() => {
    // 判斷品項群裡的每個品項，其下的每個選項群，是否每個選項群都能被自動選取
    const optionGroups = setItemMenu.options
    return _.every(
      optionGroups,
      optionGroup => dispatch(actions.batchItemTemp.canAutoSelectOptions(setItemMenu, optionGroup)),
      valid => valid,
    )
  }, [])

  const isDisabled = useMemo(() => {
    if (!isStepMax) return false

    if (isRadio) {
      if (!hasOptions) {
        // 當群組品項只有一個時
        if (_.size(setMenuItems) === 1) return true
      } else {
        // 滿足可自動選取所有選項的條件
        return canAutoSelectOptions
      }
      return false
    }

    if (isStepper) {
      if (!hasOptions) {
        // 當步驟上下限相等，且群組品項只有一個時
        if (stepMin === stepMax && _.size(setMenuItems) === 1) return true
      } else {
        // 滿足可自動選取所有選項的條件
        return canAutoSelectOptions
      }
      return false
    }

    if (isCheckbox) {
      if (!hasOptions) {
        // 當步驟上下限相等，且步驟上限與群組品項數量相等時
        if (stepMin === stepMax && _.size(setMenuItems) === stepMax) return true
        // 將其餘的 checkbox disabled
        if (!isSelected) return true
      } else {
        // 滿足可自動選取所有選項的條件
        return canAutoSelectOptions
      }
      return false
    }
  }, [setItems])

  const handleCheckboxClick = () => {
    // 一個 step group 內最多只能選 group.max 個 setItem
    if (!isSelected) {
      // 如果沒有選過這個 stepItem 就新增
      setItems.push(batchSetItemTemp)
    } else {
      // 如果已經選擇這個 stepItem 就移除
      _.remove(setItems, setItem => setItem.setMenuId === setItemMenu.id)
    }
    // 更新並移除失去相依的餐點
    dispatch(actions.batchItemTemp.updateItem(['setItems'], setItems))
    dispatch(actions.batchItemTemp.cleanUpDependentSetItems(sets))
  }

  const handleRadioClick = () => {
    // 一個 step group 內只能選一個 setItem
    // 移除所有 step 跟目前選擇的 setItem 相同的 setItem
    _.remove(setItems, setItem => setItem.step === setItemMenu.step)
    if (!isSelected) {
      // 新增目前選擇的 stepItem
      setItems.push(batchSetItemTemp)
    }
    // 更新並移除失去相依的餐點
    dispatch(actions.batchItemTemp.updateItem(['setItems'], setItems))
    dispatch(actions.batchItemTemp.cleanUpDependentSetItems(sets))
  }

  const handleClick = () => {
    if (isDisabled) return
    if (hasOptions && !isSelected) {
      // 該 setItem 有選項，且尚未被選取，則當點擊時應打開 SetItemDeleteDialog 讓 user 選擇要什麼選項
      dispatch(actions.app.toggleDialog('setItemOption', true, { setItemMenu, isMax: isStepMax }))
    } else {
      if (isCheckbox) {
        handleCheckboxClick()
      }
      if (isRadio) {
        handleRadioClick()
      }
    }
  }

  if (isSold) {
    return <GroupItem variant='sold' item={setItemMenu} />
  }
  if (isStepper) {
    return <GroupItem variant='stepper' item={setItemMenu} disabled={isDisabled} disableIncrement={isStepMax || isSetItemMax} />
  }
  return (
    isCheckbox
      ? <GroupItem variant='checkbox' item={setItemMenu} selected={isSelected} disabled={isDisabled} onClick={handleClick} />
      : <GroupItem variant='radio' item={setItemMenu} selected={isSelected} disabled={isDisabled} onClick={handleClick} />
  )
}
