import i18n from '@/i18n'

/**
 * @typedef {Object} validationResult
 * @property {boolean} isValid
 * @property {Function} [error]
 */

/**
   * @function
   * @param {string} userName
   * @returns {validationResult}
   */
export default function validateUserName (name) {
  const MAX_NAME_BYTE = 20
  const MIN_NAME_LENGTH = 2
  const regexpLA = /[a-zA-z ]/
  const regexpCJK = /[\u4E00-\u9FFF\u3400-\u4DBF]/

  if (!name) {
    return {
      isValid: false,
      error: i18n.t('app.page.member_details.name.error.empty'),
    }
  }

  let byte = 0
  const invalidChars = []
  for (const char of name) {
    if (regexpLA.test(char)) {
      byte += 1
    } else if (regexpCJK.test(char)) {
      byte += 2
    } else {
      invalidChars.push(char)
    }
  }

  // name 只能夠是中文字或英文字母
  if (invalidChars.length > 0) {
    return {
      isValid: false,
      error: i18n.t('app.page.member_details.name.error.regex'),
    }
  }

  // 因為 DB 有 bytes 上限設定所以檢查 bytes（中文佔 2 bytes，英文佔 1 byte）
  if (byte > MAX_NAME_BYTE) {
    return {
      isValid: false,
      error: i18n.t('app.page.member_details.name.error.lengthLong', { cjk: MAX_NAME_BYTE / 2, la: MAX_NAME_BYTE }),
    }
  }

  // 因為避免使用者輸入太短，不便餐廳或CS稱呼客人，但 DB 沒有限制，所以直接用 name.length 檢查
  if (name.length < MIN_NAME_LENGTH) {
    return {
      isValid: false,
      error: i18n.t('app.page.member_details.name.error.lengthShort', { length: MIN_NAME_LENGTH }),
    }
  }

  return {
    isValid: true,
  }
}
