import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Page from '@/components/Page'
import constants from '@/constants'

export default function LoginPage (props) {
  const { children } = props

  const classes = useStyles(props)

  return (
    <Page className={classes.page}>
      <div className={classes.container}>
        {children}
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  page: {
    // background: 'linear-gradient(294.18deg, #FFC400 0%, #FFA000 100%)',
  },
  container: {
    backgroundColor: '#fff',
    padding: '25px 39px',
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '50px 50px 0px 0px',
    // marginTop: constants.app.marginTop.NAVBAR,
    height: `calc(100vh - ${constants.app.marginTop.NAVBAR})`,

    '& > #nav-bar': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      '& > *': {
        fontSize: 16,
      },
    },

    '& > #title-group': {
      marginTop: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& > #title-group > #title': {
      fontSize: 20,
      fontWeight: 'bold',
    },
    '& > #title-group > #description': {
      fontSize: 16,
    },
  },
}))
