import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import { useAddressTemp, useIsLogin } from '@/hooks/user'

import InputBase from './InputBase'

InputName.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

/**
 *
 * @param {{
 * value: String
 * onChange: Function
 * }} props
 * @returns
 */
export default function InputName (props) {
  const classes = useStyles(props)
  const { value, onChange } = props
  const { t } = useTranslation()
  const addressTemp = useAddressTemp()
  const isLogin = useIsLogin()

  const isCurrentAddress = addressTemp.id === 'CURRENT'
  const isDisabled = !isLogin && isCurrentAddress // 有登入才能修改現在位置的名稱

  return (
    <InputBase
      className={classes.inputBase}
      inputId='name'
      label={t('app.page.setting_address.address.name.title')}
      placeholder={t('app.page.setting_address.address.name.placeholder')}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
    />
  )
}

const useStyles = makeStyles(theme => ({
  inputBase: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[25],
  },
}))
