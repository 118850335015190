import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import CouponBackGroundGrayRight from '../../assets/images/couponBackground/CouponBackgroundGrayRight.png'
import CouponBackGroundWhiteRight from '../../assets/images/couponBackground/CouponBackGroundWhiteRight.png'
import InfoButton from '@/pages/Menu/MerchantCard/InfoButton'
import Tags from './components/Tags'
import moment from 'moment'

const COLOR_DISABLED = 'rgb(136 136 136)'
const DAY = 86400000
const HOUR = 3600000
const MINUTE = 60000

export default function CouponCard (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const {
    name,
    type,
    buttonText,
    buttonDisabled,
    onTncButtonClick,
    onButtonClick,
    disabled,
    discountNumber,
    discountType,
    applyTo,
    merchantId,
    minOrderAmount,
    tags,
    validFrom,
    validUntil,
    used,
  } = props

  const renderTime = () => {
    if (used) {
      return moment(validFrom).format(t('app.common.date_time.format_year_date_time')) +
          t('app.page.coupon_details.to') +
          moment(validUntil).format(t('app.common.date_time.format_year_date_time'))
    }
    // 天

    // 尚未開始
    if (Date.parse(validFrom) - Date.now() > 0) {
      return t('app.page.coupon_details.coupon_start', { day: moment(validFrom).format(t('app.common.date_time.format_year_date_time')) })
    }
    const leftSecond = Date.parse(validUntil) - Date.now()
    if (leftSecond >= DAY) {
      const dayCount = Math.floor(leftSecond / DAY)
      if (dayCount > 14) {
        return t('app.page.coupon_details.coupon_expired', { day: moment(validUntil).format(t('app.common.date_time.format_year_date_time')) })
      }
      return t('app.page.coupon_details.day_count_down', { day: dayCount })
    }

    // 小時
    let hour = Math.floor((Date.parse(validUntil) - Date.now()) / HOUR)
    let minute = Math.floor((Date.parse(validUntil) - Date.now() - (hour * HOUR)) / MINUTE)
    let second = Math.floor((Date.parse(validUntil) - Date.now() - (hour * HOUR) - (minute * MINUTE)) / 1000)
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    if (second < 10) second = '0' + second
    return t('app.page.coupon_details.hour_count_down', { time: `${hour}:${minute}:${second}` })
  }

  return (
    <div className={clsx(classes.container)}>
      <div
        className={clsx(classes.descArea, {
          [classes.availableDescArea]: !disabled,
          [classes.unavailableDescArea]: disabled,
        })}
      >
        <div className={classes.descTop}>
          <Typography className={classes.name}>
            {name}
          </Typography>
          {
            type !== 'PROMOCODE' &&
              <InfoButton onClick={onTncButtonClick} />
          }
        </div>
        <Typography className={clsx(classes.desc, {
          [classes.availableTime]: !buttonDisabled,
        })}
        >
          {renderTime()}
        </Typography>
        <Tags
          applyTo={applyTo}
          disabled={disabled}
          merchantId={merchantId}
          minOrderAmount={minOrderAmount}
          tags={tags}
        />
      </div>
      <div className={clsx(classes.rightArea)}>
        <img
          className={clsx(classes.couponBackground)}
          src={disabled ? CouponBackGroundGrayRight : CouponBackGroundWhiteRight}
          alt='coupon'
        />
        <div
          className={clsx(classes.rightForeground, {
            [classes.negativeMarginTop]: discountType === 'percent',
          })}
        >
          <Typography
            className={clsx(classes.rightText)}
            variant='h4'
            style={{ color: disabled ? COLOR_DISABLED : '#FFFFFF' }}
          >
            {discountType === 'amount' && (
              <Typography component='span' className={classes.unit}>
                $
              </Typography>
            )}
            {discountNumber}
            {discountType === 'percent' && (
              <Typography component='span' className={classes.unit}>
                %
              </Typography>
            )}
          </Typography>
          {discountType === 'percent' && (
            <Typography component='span' className={classes.off}>
              Off
            </Typography>
          )}
          <ButtonBase
            onClick={onButtonClick}
            disabled={buttonDisabled}
            className={clsx(classes.useButton, {
              [classes.disabled]: disabled,
              [classes.availableButton]: !buttonDisabled,
              [classes.unavailableButton]: buttonDisabled,
            })}
          >
            {buttonText}
          </ButtonBase>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    height: 110,
    marginTop: 5,
    marginBottom: 1,
  },
  descArea: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    marginTop: 5, // 右邊圖片透明部分
    marginBottom: 9, // 右邊圖片透明部分
    padding: '8px 8px 8px 12px',
    borderRadius: 13,
    overflow: 'hidden',
    boxShadow: '0px 1px 3px 0px rgb(0, 0, 0, 0.16)',
    '& > *': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  availableDescArea: {
    border: '2px solid rgb(255,185,0)',
  },
  unavailableDescArea: {
    backgroundColor: 'rgb(229 229 229)',
    color: 'rgb(136 136 136)',
  },
  descTop: {
    justifyContent: 'space-between',
    flex: 1,
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  desc: {
    fontWeight: 400,
    fontSize: '1.2rem',
    flex: 1,
  },
  availableTime: {
    color: 'rgb(255 107 29)',
  },
  rightArea: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
  },
  couponBackground: {
    width: 125,
    height: 110,
    marginRight: -5,
  },
  rightForeground: {
    position: 'absolute',
    zIndex: 0,
    // paddingRight: theme.spacing(1),
    textAlign: 'center',
  },
  negativeMarginTop: {
    marginTop: -6,
  },
  rightText: {
    fontWeight: 'bold',
  },
  useButton: {
    borderRadius: 16,
    fontSize: '1.4rem',
    fontWeight: 500,
    padding: 4,
    marginTop: 8,
    width: 90,
  },
  availableButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
  },
  unavailableButton: {
    backgroundColor: '#FFFFFF',
    color: '#AAAAAA',
  },
  disabled: {
    backgroundColor: '#AAAAAA',
    color: '#FFFFFF',
  },
  unit: {
    fontSize: '1.6rem',
  },
  off: {
    display: 'block',
    fontWeight: 'bold',
    lineHeight: 1,
    fontSize: '1.4rem',
  },
}))
