import produce from 'immer'

import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-unused-vars
import { IDimboxState } from '@/redux/dimbox/Dimbox.d'

/** @type {IDimboxState} */
export const initialState = {
  isInit: false,
  available: 0,
  dimboxes: [],
  selectedCabinetId: '290', // 目前只有 290 這台
}

export default produce(
  /**
   * @param {IDimboxState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }

      case ActionTypes.INIT:
        draft.isInit = true
        break

      case ActionTypes.UPDATE_DIMBOXES:
        draft.dimboxes = action.payload.boxes
        draft.available = action.payload.available
        break

      case ActionTypes.SELECT_CABINET:
        draft.selectedCabinetId = action.payload.cabinetId
        break

      default:
        return draft
    }
  },
  initialState,
)
