import { useRouteMatch } from 'react-router-dom'
import React, { useEffect } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useDatetime, useDeliveryType, useParams } from '@/hooks/app'
import { useMerchant } from '@/hooks/merchant'

import Menu from '@/pages/Menu'

/**
 * 平台模式餐廳頁面的 /restaurant，餐廳相關的 useEffect 會在這邊處理，內容直接 render Menu
 * Menu 單純顯示 UI，讓平台模式和餐廳模式能共用 Menu
 * 因為餐廳模式有餐廳首頁，在餐廳首頁就需要 restaurantInit，到 /menu 只需要看菜單即可
 * 而平台模式沒有餐廳首頁，所以到 /restaurant 才會 dispatch restaurantInit
 * @param {*} props
 * @returns
 */
export default function Restaurant (props) {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const isAppInit = useSelector(state => state.app.isInit)
  const isRestaurantLoading = useSelector(state => state.app.loadings.restaurantInit)
  const merchant = useMerchant()
  const merchantId = match.params.id
  const testDate = useSelector(state => state.app.test.date)
  const testTime = useSelector(state => state.app.test.time)
  const address = useSelector(state => state.user.address)
  const { deliveryType } = useDeliveryType()
  const datetime = useDatetime()
  const params = useParams()

  const dineInForceUsingApp = merchant?.setting?.dineInForceUsingApp
  useEffect(() => {
    dispatch(actions.app.checkDineInForceUsingAppAndRedirect())
  }, [deliveryType, dineInForceUsingApp])

  useEffect(() => {
    if (!isAppInit) return

    // * restaurant
    if (merchantId !== merchant.id) {
      // 餐廳改變要重新 restaurantInit
      dispatch(actions.app.restaurantInit(merchantId))
    }
  }, [isAppInit, merchantId])

  useEffect(() => {
    const updateRestaurant = async () => {
      if (!isAppInit) return
      if (merchantId !== merchant.id) return
      if (isRestaurantLoading) return

      // * shipping
      dispatch(actions.order.createShipping()) // address 改變，重新 createShipping

      // * preorder drawer
      // 重新抓一次 restaurant，判斷 openPreorderDrawer 才會正確
      await dispatch(actions.landing.fetchRestaurant(merchantId))
      // dispatch openPreorderDrawer，會判斷需不需要開啟
      dispatch(actions.app.openPreorderDrawer())
    }
    updateRestaurant()
  }, [isAppInit, merchantId, deliveryType, datetime.date, datetime.time, datetime.isImmediate, address, testDate, testTime, params.categoryTag])

  // 平台模式
  return (
    <Menu />
  )
}
