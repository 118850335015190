import { ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import libs from '@/libs'
import useDisableD2CUIPrice from '@/hooks/useDisableD2CUIPrice'

/* eslint-disable no-unused-vars */
import { IAppMenuItem } from '@/redux/menu/MenuState.d'
import { IMenuOptionItem } from 'dimorder-orderapp-lib/dist/types/Menu'
/* eslint-enable no-unused-vars */

/**
 * @typedef GroupItemBaseProps
 * @property {IMenuOptionItem | IAppMenuItem} item
 * @property {GroupItemVariant} [variant]
 * @property {() => void} onClick
 * @property {JSX.Element} rightAction
 *
 * @typedef {'checkbox' | 'radio' | 'stepper' | 'optionStepper' | 'sold'} GroupItemVariant
 */

/**
 *
 * @param {GroupItemBaseProps} props
 * @returns
 */
export default function GroupItemBase (props) {
  const { item, onClick, rightAction } = props
  const classes = useStyles(props)
  const disableD2CUIPrice = useDisableD2CUIPrice()

  const Price = React.useMemo(() => {
    if (disableD2CUIPrice) return null
    return item.price > 0
      ? `(+$${item.price})`
      : `(-$${Math.abs(item.price)})`
  }, [disableD2CUIPrice, item.price])

  return (
    <ListItem
      className={classes.groupItem}
      onClick={onClick}
    >
      <Typography className={classes.name}>
        {libs.string.replaceNbsp(item.name)}
        {Boolean(item.price) &&
          <Typography variant='caption' className={classes.price}>
            {Price}
          </Typography>}
      </Typography>
      <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryActionRoot }}>
        {rightAction}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  groupItem: {
    padding: props => {
      switch (props.variant) {
        case 'stepper':
        case 'optionStepper':
          return theme.spacing(1, 12, 1, 1)
        default:
          return theme.spacing(1, 6, 1, 1)
      }
    },
  },
  name: {
    fontSize: '1.4rem',
  },
  price: {
    fontSize: '1.4rem',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
  },
  listItemSecondaryActionRoot: {
    right: 0,
  },
}))
