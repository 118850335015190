import { IconButton } from '@material-ui/core'
import { components } from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import GooglePlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { findPlacesByLatLng, getCountryInGeocodeResult } from '@/libs/googleMap'
import { useAddressTemp, useIsLogin } from '@/hooks/user'
import { useTranslation } from 'react-i18next'
import MyLocationIcon from '@/assets/icons/precision.svg'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import SaveButton from '@/pages/Settings/AddressSetting/SaveButton'
import constants from '@/constants'
import geolocation from '@/libs/geolocation'

import AddressSelectMap from './AddressSelectMap'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AddressSelect (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const hasAddress = useSelector(state => state.user.address?.address)
  const addressTemp = useAddressTemp()
  const exitAddressEditBackLevel = useSelector(state => state.user.exitAddressEditBackLevel) ?? 1
  const isLogin = useIsLogin()
  const [address, setAddress] = useState(addressTemp.address)
  const [latlng, setLatlng] = useState(addressTemp.latlng)
  const [country, setCountry] = useState(null)
  const [zoom, setZoom] = useState(12)
  const [isLoading, setIsLoading] = useState(false)
  const isCurrentAddress = addressTemp.id === 'CURRENT'

  useEffect(() => {
    if (_.isEmpty(addressTemp)) {
      dispatch(actions.user.createAddressTemp('CURRENT'))
    }
    if (isCurrentAddress && !latlng) {
      getCurrentAddress()
    }
  }, [addressTemp, address])

  // 如果有地址沒country, set country
  // eg: 剛進入 /settings/address/map 時
  useEffect(() => {
    if (_.isNull(country) && address) {
      (async () => {
        const geocodeResults = await geocodeByAddress(address)
        const c = getCountryInGeocodeResult(geocodeResults[0])
        setCountry(c)
      })()
    }
  }, [country])

  const handleSave = () => {
    if (country !== 'HK') {
      dispatch(actions.app.toggleAlert({
        title: t('app.page.setting_address.service_unavailable_alert.title'),
        message: t('app.page.setting_address.service_unavailable_alert.message'),
      }))
      return
    }

    dispatch(actions.user.updateAddressTemp('address', address))
    dispatch(actions.user.updateAddressTemp('latlng', latlng))

    if (isCurrentAddress) {
      // 『現在位置』
      if (isLogin) {
        // 有登入的話去 address edit
        history.replace('/settings/address/edit')
      } else {
        // 沒登入的話直接儲存到 user.address
        dispatch(actions.user.selectAddress({
          ...addressTemp,
          address,
          latlng,
        }))
        // 沒登入的話，選完『現在位置』直接回去
        history.go(-exitAddressEditBackLevel)
      }
      return
    }

    // 非『現在位置』，會回到編輯頁面繼續編輯地址
    history.goBack()
  }

  const handleAddressSelected = async (e) => {
    setAddress(e.value.description)
    try {
      const geocodeResults = await geocodeByPlaceId(e.value.place_id)
      const latlng = await getLatLng(geocodeResults[0])
      const country = getCountryInGeocodeResult(geocodeResults[0])
      setLatlng(latlng)
      setCountry(country)
    } catch (error) {
      dispatch(actions.app.toggleAlert({
        title: t('app.page.setting_address.location_unavailable_alert.title'),
        message: t('app.page.setting_address.location_unavailable_alert.message'),
      }))
    }
  }

  const getCurrentAddress = async () => {
    try {
      setIsLoading(true)
      const position = await geolocation.getCurrentPosition()

      try {
        const latLng = { lat: position.coords.latitude, lng: position.coords.longitude }
        const request = { location: latLng }
        const geocodeResults = await findPlacesByLatLng(request)

        if (_.isEmpty(geocodeResults)) {
          setAddress('')
          setCountry(null)
          dispatch(actions.app.toggleAlert({
            title: t('app.page.setting_address.location_unavailable_alert.title'),
            message: t('app.page.setting_address.location_unavailable_alert.message'),
          }))
          return
        } else {
          // 定位成功
          const country = getCountryInGeocodeResult(geocodeResults[0])
          setCountry(country)

          setLatlng(latLng)
          setAddress(geocodeResults[0].formatted_address)
          setZoom(18)
        }
      } catch (error) {
        console.log('findPlacesByLatLng error', error)
      }
    } catch (error) {
      console.log('getCurrentPosition error', error)
      // this.rendeGetCurrentPositionTimoutAlert()
    } finally {
      setIsLoading(false)
    }
  }

  // 用空白的 div 取代 react select 的 dropdown indicator
  // 之後用 absolute 把定位 icon button 蓋上去
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div style={{ width: 16, height: 16 }} />
      </components.DropdownIndicator>
    )
  }

  return (
    <Page>
      <Navbar
        title={t('app.page.setting_address.confirm_location.title')}
        hideBackButton={!hasAddress}
      />
      <div className={classes.container}>
        <div className={classes.addressInputWrap}>
          <GooglePlacesAutocomplete
            key={address}
            debounce={300}
            selectProps={{
              isLoading: isLoading,
              components: {
                DropdownIndicator: DropdownIndicator,
                IndicatorSeparator: () => null,
              },
              placeholder: t('app.page.setting_address.confirm_location.placeholder'),
              value: address,
              onChange: handleAddressSelected,
              defaultInputValue: address,
              loadingMessage: () => {
                return <span>{t('app.common.processing')}</span>
              },
              noOptionsMessage: () => {
                if (address) {
                  return null
                }
                return <span>{t('app.common.no_result')}</span>
              },
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['hk'],
              },
            }}
          />
          <IconButton className={classes.myLocationButton} onClick={getCurrentAddress}>
            <img className={classes.myLocationIcon} src={MyLocationIcon} alt='MyLocationIcon' />
          </IconButton>
        </div>

        <AddressSelectMap
          location={latlng}
          zoom={zoom}
          setLocation={setLatlng}
          setAddress={setAddress}
          setCountry={setCountry}
          setZoom={setZoom}
        />

        <footer className={classes.footerWrap}>
          <SaveButton className={classes.saveButton} onClick={handleSave} disabled={_.isNull(country) || !address || !latlng} />
        </footer>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: constants.app.marginTop.NAVBAR,
    marginBottom: constants.app.marginBottom.GENERAL,
    padding: 0,
  },
  addressInputWrap: {
    zIndex: 1,
    position: 'fixed',
    top: constants.app.marginTop.NAVBAR,
    left: 0,
    width: '100%',
    '& > :first-child': {
      margin: theme.spacing(2),
    },
  },
  myLocationButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  myLocationIcon: {
    width: 16,
    height: 16,
  },
  footerWrap: {
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'grid',
    padding: theme.spacing(2),
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '50%',
      backdropFilter: 'blur(15px)',
    },
  },
  saveButton: {
    marginBottom: constants.app.safeArea.bottom,
  },
}))
