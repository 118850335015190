import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { useAddress, useAddressList, useIsLogin } from '@/hooks/user'

import AddressItem from './AddressItem'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AddressList (props) {
  const classes = useStyles(props)
  const list = useAddressList()
  const selectedAddress = useAddress()
  const isLogin = useIsLogin()

  return (
    <List className={classes.addressList}>
      <AddressItem variant='currentPosition' />
      {_.map(list, (address, index) => {
        const isSelected = address.id === selectedAddress.id
        return (
          <AddressItem
            key={index}
            address={address}
            selected={isSelected}
          />
        )
      })}
      {
        // 會員尚未登入不可使用新增地址按鈕
        isLogin && <AddressItem variant='add' />
      }
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  addressList: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
}))
