import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import AppConstants from '@/constants/app'
import InfoOutlinedIcon from '@material-ui/icons/HelpOutline'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import SkeletonOrderBatchItemList from '@/components/Skeletons/OrderTracking/SkeletonOrderBatchItemList'
import SkeletonPayingCard from '@/components/Skeletons/OrderTracking/SkeletonPayingCard'
import SkeletonPaymentSummaryCard from '@/components/Skeletons/OrderTracking/SkeletonPaymentSummaryCard'

import SkeletonProgressCard from './ProgressCard/Skeleton'

function Skeleton (props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { orderId } = useParams()

  const HelpButton = (
    <ButtonBase
      className={classes.helpButton}
      onClick={(e) => {
        window.open(`https://wa.me/85268262032?text=訂單編號:${orderId}，訂單追蹤無法載入`)
      }}
    >
      <InfoOutlinedIcon className={classes.helpButtonIcon} fontSize='small' />
      <Typography variant='body2'>
        {t('app.page.order_tracking.navbar.help')}
      </Typography>
    </ButtonBase>
  )

  return (
    <Page>
      <Navbar
        title={t('app.page.order_tracking.navbar.title')}
        withLeave={false}
        rightColumn={HelpButton}
      />
      <PageContainer className={classes.container}>
        <SkeletonProgressCard />
        <SkeletonPayingCard />
        <SkeletonOrderBatchItemList />
        <SkeletonPaymentSummaryCard />
      </PageContainer>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: AppConstants.marginTop.NAVBAR,
    marginBottom: AppConstants.marginBottom.GENERAL,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  helpButton: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: 'rgba(0,0,0,0.75)',
  },
}))

export default React.memo(Skeleton)
