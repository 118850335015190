// eslint-disable-next-line no-unused-vars
import { IconButton, IconButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React from 'react'

import opacity from '@/theme/opacity'

/**
 * @typedef BackButtonProps
 * @property {'solid' | 'default'} [variant='default']
 * @property {() => void} [onBack]
 * @property {string} [color]
 * @property {IconButtonProps} [buttonProps={}]
 * @param {BackButtonProps} props
 * @returns
 */
export default function BackButton (props) {
  const { variant = 'default', onBack, buttonProps = {} } = props
  const classes = useStyles(props)
  const history = useHistory()
  const isSolid = variant === 'solid'

  return (
    <IconButton
      id={'navBackButton-' + variant}
      classes={{ root: isSolid ? classes.rootSolid : classes.rootDefault }}
      onClick={onBack || (() => history.goBack())}
      {...buttonProps}
    >
      <ArrowBackIosIcon fontSize='small' className={classes.icon} />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  rootDefault: {
    padding: theme.spacing(1),
  },
  rootSolid: {
    position: 'relative',
    backgroundColor: `#ffffff${opacity[80]} !important`,
    padding: theme.spacing(1),
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1,
      borderRadius: '50%',
      backdropFilter: 'blur(2px)',
    },
  },
  icon: {
    color: props => props.color ?? theme.palette.common.black,
    fontSize: '1.8rem',
    transform: 'translateX(3px)',
  },
}))
