import { Button, Dialog, IconButton, Typography } from '@material-ui/core'
import { Capacitor } from '@capacitor/core'
import { Clipboard } from '@capacitor/clipboard'
import { Share } from '@capacitor/share'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import Zoom from '@material-ui/core/Zoom'

import { actions, useDispatch, useSelector } from '@/redux'
import config from '@/config'
import constants from '@/constants'
import hooks from '@/hooks'

const Transition = React.forwardRef((props, ref) => {
  return <Zoom in ref={ref} {...props} />
})

const iconSources = {
  background: require('@/assets/images/invite/bg.png').default,
  banner: require('@/assets/images/invite/banner.png').default,
  logo: require('@/assets/images/invite/ricecoin.png').default,
  board: require('@/assets/images/invite/wordbg-large.png').default,
  share: require('@/assets/images/invite/share_icon.png').default,
  link: require('@/assets/images/invite/link_icon.png').default,
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function InvitationDialog (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const referralCode = hooks.user.useMember()?.referral || ''
  const open = useSelector(state => state.app.dialogs.invitation.open)
  const [copied, setCopied] = React.useState(false)

  const referralLink = `${config.universalBaseUrl}?invite=${referralCode}`
  const shareText = t('app.component.dialog.invitation_dialog.share.message', { referralLink, referralCode })

  const handleClose = async () => {
    dispatch(actions.app.toggleDialog('invitation', false))
  }

  const handleCopyCode = () => {
    if (Capacitor.isNativePlatform()) {
      // Capacitor Clipboard
      Clipboard.write({ string: shareText })
        .then(() => {
          setCopied(true)
          setTimeout(() => {
            setCopied(false)
          }, 2000)
        },
        (err) => console.log(err))
      return
    }

    if (navigator.clipboard) {
      // Async Clipboard API
      navigator.clipboard.writeText(referralCode)
        .then(() => {
          setCopied(true)
          setTimeout(() => {
            setCopied(false)
          }, 2000)
        },
        (err) => console.log(err))
    }
  }

  const handleShareLink = async () => {
    const platform = Capacitor.getPlatform()
    if (platform === 'ios' || navigator.share) {
      try {
        const result = await Share.share({
          text: shareText,
          dialogTitle: t('app.component.dialog.invitation_dialog.share.title'),
        })
        console.log('share result', result)
      } catch (error) {
        console.log('share error', error)
      }
    } else {
      window.open(`https://wa.me/?text=${encodeURIComponent(shareText)}`)
    }
  }

  const ActionButton = ({ text, onClick, iconSource }) => (
    <Button
      disableElevation
      className={classes.button}
      onClick={onClick}
      fullWidth
      startIcon={<img src={iconSource} className={classes.buttonIcon} alt='' />}
    >
      <Typography variant='h6' className={classes.buttonText}>
        {text}
      </Typography>
    </Button>)

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      transitionDuration={{ enter: 600, exit: 200 }}
      keepMounted
      onClose={handleClose}
      fullScreen
      classes={{ paper: classes.root }}
    >
      <div className={classes.content}>
        <div className={classes.image} />
        <div className={classes.board}>
          <Typography className={classes.title}>
            {t('app.component.dialog.invitation_dialog.section1.title')}
          </Typography>
          <Typography className={classes.text}>
            {t('app.component.dialog.invitation_dialog.section1.message')}
          </Typography>
          <Typography className={classes.title}>
            {t('app.component.dialog.invitation_dialog.section2.title')}
          </Typography>
          <Typography className={classes.text}>
            {t('app.component.dialog.invitation_dialog.section2.message')}
          </Typography>
        </div>

        <div className={classes.actions}>
          <ActionButton
            text={t('app.component.dialog.invitation_dialog.share_with_friend')}
            onClick={handleShareLink}
            iconSource={iconSources.share}
          />
          <Typography className={classes.noteText}>
            {t('app.component.dialog.invitation_dialog.your_code')}
          </Typography>
          <ActionButton
            text={copied
              ? t('app.component.dialog.invitation_dialog.copied')
              : referralCode}
            onClick={handleCopyCode}
            iconSource={iconSources.link}
          />
          <Typography className={classes.terms}>
            {t('app.component.dialog.invitation_dialog.terms')}
          </Typography>
        </div>
      </div>
      <IconButton
        classes={{ root: classes.closeButton }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: () => window.innerHeight <= 710 ? 'flex-start' : 'center',
    height: '100%',
    backgroundImage: `url(${iconSources.background}), linear-gradient(rgba(255, 160, 0), rgba(255, 194, 0) )`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: theme.spacing(3),
    paddingTop: `calc(${theme.spacing(2)}px + ${constants.app.safeArea.top})`,
    paddingBottom: `calc(${theme.spacing(2)}px + ${constants.app.safeArea.bottom})`,
    color: 'black',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    backgroundImage: `url(${iconSources.logo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 150,
    margin: theme.spacing(2),
  },
  board: {
    flex: 1,
    backgroundImage: `url(${iconSources.board})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    paddingTop: 24,
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  text: {
    fontSize: '1.4rem',
    color: theme.palette.grey[700],
    maxWidth: 425,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  smallText: {
    fontSize: '1.2rem',
    color: theme.palette.grey[700],
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
    '& >:first-child': {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    backgroundColor: '#e14054',
    border: '3px solid #5d3100',
    borderRadius: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#e14054',
      borderColor: '#5d3100',
    },
    textTransform: 'initial',
  },
  buttonText: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  buttonIcon: {
    height: 25,
    width: 25,
  },
  noteText: {
    margin: theme.spacing(0.5, 0),
  },
  terms: {
    fontSize: '1rem',
    margin: theme.spacing(1, 0),
  },
  closeButton: {
    position: 'fixed',
    top: constants.app.safeArea.top,
    right: 0,
  },
}))
