import produce from 'immer'

import ActionTypes from './ActionTypes'

// eslint-disable-next-line no-unused-vars
import { ILandingState } from '@/redux/landing/Landing.d'

/** @type {ILandingState} */
export const initialState = {
  isInit: false,
  restaurant: null,
  merchantPromocodes: [],
  searchText: '',
  filter: {},
  fetchRestaurantsParams: {
    merchantId: null,
    deliveryType: null,
    latlng: null,
  },
}

export default produce(
  /**
   *  @param {ILandingState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_RESTAURANT: {
        const { restaurant } = action.payload
        draft.restaurant = restaurant
        break
      }
      case ActionTypes.UPDATE_FETCH_RESTAURANTS_PARAMS: {
        const { fetchRestaurantsParams } = action.payload
        draft.fetchRestaurantsParams = fetchRestaurantsParams
        break
      }
      case ActionTypes.UPDATE_MERCHANT_PROMOCODES: {
        const { merchantPromocodes } = action.payload
        draft.merchantPromocodes = merchantPromocodes
        break
      }
      case ActionTypes.UPDATE_SEARCH_TEXT: {
        draft.searchText = action.payload.searchText
        break
      }
      case ActionTypes.UPDATE_FILTER: {
        const { filter } = action.payload
        draft.filter = filter
        break
      }
      default:
        break
    }
  },
  initialState,
)
