import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'

import hooks from '@/hooks'

// eslint-disable-next-line no-unused-vars
import { INextStartOpening } from '@/redux/landing/Landing.d'

/**
 * @typedef CloseLayerProps
 * @property {boolean} open
 * @property {INextStartOpening} nextStartOpening
 * @property {IPickerRange} pickerRange
 * @param {CloseLayerProps} props
 * @returns
 */
export default function CloseLayer (props) {
  const { open, nextStartOpening, pickerRange } = props
  const { t } = useTranslation()
  const datetime = hooks.app.useDatetime()
  const isPreOrder = pickerRange?.preOrderDay && pickerRange?.preOrderDay > 0
  const classes = useStyles(props)

  if (open) return null

  if (isPreOrder && moment(datetime.date).isBefore(moment().add(pickerRange.preOrderDay, 'day'), 'day')) {
    return (
      <div className={classes.closeLayer}>
        <Typography variant='caption' className={classes.preorderButton}>{t('app.common.preorder_now')}</Typography>
        <Typography className={classes.nextOpening}>{t('app.common.preOrderDay', { preOrderDay: pickerRange.preOrderDay })}</Typography>
      </div>
    )
  }

  if (!nextStartOpening) {
    // open: false 但 nextOpening: undefined 的情況
    // 例：有開外送或外帶的功能，但是時段設定裡沒有找到任何符合的時段，顯示未營業
    return (
      <div className={classes.closeLayer}>
        <Typography className={classes.nextOpening}>{t('app.common.closed')}</Typography>
      </div>
    )
  }

  const isToday = moment(nextStartOpening.displayDate).isSame(moment(), 'day')
  const opening = moment().hour(nextStartOpening.start).minute(nextStartOpening.startmin)
  const displayWeekday = isToday
    ? t('app.common.date_time.today')
    : moment(nextStartOpening.displayDate).isSameOrAfter(moment().add(7, 'days'), 'day')
      ? moment(nextStartOpening.displayDate).format(t('app.common.date_time.format_date'))
      : moment().weekday((nextStartOpening.weekday)).format(t('app.common.date_time.format_week'))
  const formatedOpening = opening.format(t('app.common.date_time.format_time'))

  return (
    <div className={classes.closeLayer}>
      <Typography variant='caption' className={classes.preorderButton}>{t('app.common.preorder_now')}</Typography>
      <Typography className={classes.nextOpening}>{t('app.common.nextOpening', { nextOpening: displayWeekday + ' ' + formatedOpening })}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  closeLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '&:before': {
      zIndex: 100,
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.grey[900],
      opacity: 0.7,
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
  nextOpening: {
    zIndex: 200,
    display: 'grid',
    placeContent: 'center',
    color: theme.palette.grey[200],
    fontSize: 16,
    fontWeight: 'bold',
    margin: theme.spacing(0.5),
    textAlign: 'center',
  },
  preorderButton: {
    zIndex: 200,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: 4,
  },
}))
