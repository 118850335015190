import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { actions, useDispatch, useSelector } from '@/redux'
import { useAvailableCRMPoints, useCart } from '@/hooks/useCart'
import { useGroupAllRewardsQuary } from '@/hooks/queries/CRM/useGroupRewardsQuery'
import { useTranslation } from 'react-i18next'
import constants from '@/constants'

import { RewardCard } from '@/pages/CRM/RewardsPage'
import DrawerContent from '@/components/Drawers/common/DrawerContent'
import SwipeableDrawer from '@/components/SwipeableDrawer'

import CRMRewardAddToCartButton from './CRMRewardAddToCartButton'
import CRMRewardItemStepper from './CRMRewardItemStepper'

function useSelectedReward () {
  const [selectedRewardId, setSelectedRewardId] = useState('')
  const open = useSelector(state => state.app.drawers.crmRewards.open)
  const { rewards } = useGroupAllRewardsQuary()
  const selectedReward = _.find(rewards, reward => reward.id === selectedRewardId)
  const [quantity, setQuantity] = useState(1)

  // 初始選擇第一個 reward 並重設 quantity
  useEffect(() => {
    const firstReward = rewards[0]
    if (open && firstReward) {
      setSelectedRewardId(firstReward.id)
      setQuantity(1)
    }
  }, [open, rewards])
  return { selectedRewardId, setSelectedRewardId, selectedReward, quantity, setQuantity }
}

export default function CRMRewardsDrawer (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const { rewards } = useGroupAllRewardsQuary()
  const { selectedRewardId, setSelectedRewardId, selectedReward, quantity, setQuantity } = useSelectedReward()
  const { rewardItems } = useCart()
  const { availablePoints } = useAvailableCRMPoints()
  const rewardItem = _.find(rewardItems, item => item.id === selectedRewardId)
  const selectedRewardPoints = selectedReward?.points ?? 0
  const totalSelectedRewardPoints = quantity * selectedRewardPoints
  const disableIncrement = availablePoints < (selectedRewardPoints * (quantity + 1)) // user 剩餘積分不足以再增加一個當前所選的積分獎勵
  const disableSubmit = quantity === 1 && availablePoints < (selectedRewardPoints * quantity) // user 剩餘積分不足以兌換當前所選的積分獎勵

  const open = useSelector(state => state.app.drawers.crmRewards.open)
  const openDrawer = () => dispatch(actions.app.toggleDrawer('crmRewards', true))
  const closeDrawer = () => dispatch(actions.app.toggleDrawer('crmRewards', false))
  const handleSelect = (rewardId) => {
    if (rewardId !== selectedRewardId) {
      setQuantity(1)
    }
    setSelectedRewardId(rewardId)
  }
  const handleSubmit = () => {
    const qty = (rewardItem?.quantity ?? 0) + quantity
    dispatch(actions.orderBatch.updateRewardItem(selectedReward, qty))
    closeDrawer()
  }

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <DrawerContent>
        <header className={classes.header}>
          <Typography variant='h5' style={{ fontWeight: 700 }}>{t('app.page.crm.rewards')}</Typography>
          <Typography variant='caption' style={{ fontSize: '1.6rem' }}>
            {t('app.page.crm.crm_available_points') + ': '}
            <Typography variant='caption' style={{ fontSize: '1.6rem' }} color='primary'>
              {availablePoints}
            </Typography>
          </Typography>
        </header>
        <ul className={classes.groupList}>
          {_.map(rewards, reward => {
            const isSelected = reward.id === selectedRewardId
            return (
              <RewardCard
                key={reward.id}
                item={reward}
                selected={isSelected}
                onClick={() => handleSelect(reward.id)}
              />
            )
          })}
        </ul>
        <footer className={classes.footer}>
          <CRMRewardItemStepper
            quantity={quantity}
            setQuantity={setQuantity}
            disableIncrement={disableIncrement}
          />
          <CRMRewardAddToCartButton
            total={totalSelectedRewardPoints}
            disabled={disableSubmit}
            onClick={handleSubmit}
          />
        </footer>
      </DrawerContent>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(3, 2),
    paddingBottom: `calc(${theme.spacing(3)}px + ${constants.app.safeArea.bottom})`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
  },
  groupList: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    overflowY: 'auto',
  },
  footer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
  },
}))
