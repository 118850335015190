import axios from 'axios'
import config from '@/config'
import constants from '@/constants'

const url = `${config.api.lalamovePrice}/system_quotations`

function createWaypoint (params) {
  const { lat, lng, address, country } = params
  return ({
    location: {
      lat,
      lng,
    },
    addresses: {
      zh_HK: {
        displayString: address,
        country,
      },
    },
  })
}

// serviceType (HK)
// MOTORCYCLE, 40 × 40 × 40 cm, 10kg
// VAN, 182 × 121 × 121 cm, 700 - 800 kg
// TRUCK550, 450 × 195 × 195 cm, 800 - 1,200 kg
export async function getLalamoveQuotation (params, retrys = constants.shipping.DEFAULT_RETRY_TIMES) {
  const {
    serviceType = 'MOTORCYCLE',
    specialRequests = [], // optional
    // pickupAt,
    scheduleAt,
    waypoints,
    remarks = '', // Additional info about the delivery. eg. building, floor and flat. Use newline \r\n for better readability
    orderAmount = 0,
    orderTimestamp, // 1638931440
    poonchoi, // boolean
    merchantId,
  } = params

  const stops = waypoints.map(point => {
    const params = { ...point }
    return createWaypoint(params)
  })

  // Array of DeliveryInfos, stops[1] (customer contact)
  const deliveries = [{
    toStop: 1,
    toContact: {
      name: 'Chris Wong',
      phone: '37013701',
    },
    remarks: remarks, // optional
  }]

  // Contact person at pick up point aka stops[0] (merchant contact)
  const requesterContact = {
    name: 'Chris Wong',
    phone: '37013701',
  }

  const request = {
    scheduleAt, // optional Pick up time in UTC timezone and ISO 8601 format; omit this if you are placing an immediate order
    serviceType,
    stops, // <Waypoint>[]
    deliveries, // <DeliveryInfo>[]
    requesterContact, // <Contact> e.g. { name: 'Chris Wong', phone: '37013701' }, phone must pass phone validation. (by country/region)
    specialRequests, // optional e.g. ['COD', 'HELP_BUY', 'LALABAG'], HK has no available specialRequest.
    orderAmount,
    orderTimestamp, // 拿盆菜運費要帶的欄位 e.g. 1638931440
    poonchoi, // 拿盆菜運費要帶的欄位 e.g. true
    merchantId,
  }

  try {
    const res = await axios.post(url, request, {
      headers: {
        'X-LLM-Country': 'HK',
      },
    })

    return { ...res.data, scheduleAt }
  } catch (error) {
    console.log('get system quotations error', error)
    if (/district .+ not allowed/.exec(error?.response?.data?.error)) {
      // 後端回應特定區域不允許外送
      throw new Error('District not allowed')
    }
    // retry 用完，或是後端回應 district %s not allowed 就不再 retry
    if (retrys >= 0) {
      const result = await getLalamoveQuotation(params, retrys - 1)
      return result
    } else {
      throw new Error('Get quotations error')
    }
  }
}

export function findDistrict (stops, address) {
  const stop = stops.find(s => s.addresses.zh_HK.displayString === address)
  return stop.district
}

export default {
  getLalamoveQuotation,
  findDistrict,
}
