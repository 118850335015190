import { makeStyles } from '@material-ui/core/styles'
import { useRouteMatch } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import SwipeableDrawer from '@/components/SwipeableDrawer'
import constants from '@/constants'

import customerApi from '@/libs/api/customer'
import useTrack from '@/hooks/useTrack'

import StepPhone from './StepPhone'
import StepPincode from './StepPincode'
import StepRule from './StepRule'
import StepUserInfo from './StepUserInfo'

export default function LoginDrawer (props) {
  const { sendEvent } = useTrack()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const open = useSelector(state => state.app.drawers.login.open)
  const openDrawer = () => dispatch(actions.app.toggleLoginDrawer(true))
  const closeDrawer = () => dispatch(actions.app.toggleLoginDrawer(false))
  const groupMatch = useRouteMatch({ path: '/membership/:groupId', strict: true })
  const d2cGroupMatch = useRouteMatch({ path: '/d2c/:merchantId/membership/:groupId', strict: true })
  const isInMemberShipHomePage = !!groupMatch || !!d2cGroupMatch

  const [country, setCountry] = useState()
  const [phone, setPhone] = useState()
  const [countdown, setCountdown] = useState(0)

  // 0: phone, 1: pincode, 2: rule, 3: user info
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    const id = setInterval(() => {
      setCountdown(v => v > 0 ? v - 1 : v)
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [])

  useEffect(() => {
    setCurrentStep(0)
  }, [open])

  const doPrev = useCallback(() => {
    switch (currentStep) {
      case 1:
      case 2:
      case 3:
        setCurrentStep(v => v - 1)
        break
      default:
        break
    }
  }, [currentStep])

  const doNext = useCallback(async (payload) => {
    const handleLogin = async () => {
      const { data: user } = await customerApi.getCustomerInfo()
      if (user?.name) {
        // 在 getCustomerInfo 之前要完成所有匿名帳號時下的訂單的 transformowner
        // 不然正式帳號的 orderHistory 內會沒有匿名帳號時下的訂單，導致 orderTracking 頁面沒有 order
        dispatch(actions.user.getCustomerInfo()) // 包含了 dispatch(actions.orderHistory.getOrders())
        dispatch(actions.app.initPushNotification())
        closeDrawer()
        sendEvent(constants.track.EVENT.LOGIN, { method: 'phone' })
      } else {
        setCurrentStep(v => v + 1)
        sendEvent(constants.track.EVENT.SIGN_UP, { method: 'phone' })
      }
    }

    const handleSignUp = () => {
      dispatch(actions.user.getCustomerInfo())
      dispatch(actions.app.initPushNotification())
      closeDrawer()
      if (isInMemberShipHomePage) {
        dispatch(actions.app.toggleDialog('crmRegister', true))
      }
    }

    switch (currentStep) {
      case 0:
        setCurrentStep(v => v + 1)
        break
      case 1:
        handleLogin(payload)
        break
      case 2:
        await customerApi.updateCustomerInfo({
          options: {
            allowDirectMarketing: payload.allowDirectMarketing,
          },
        })
        setCurrentStep(v => v + 1)
        break
      case 3:
        handleSignUp()
        break
      default:
        break
    }
  }, [currentStep])

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
    >
      {
        currentStep === 0
          ? (
            <StepPhone
              doNext={doNext}
              setInfo={
                ({ country: newCountry, phone: newPhone }) => {
                  if (newCountry !== country || newPhone !== phone || countdown === 0) {
                    setCountdown(0)
                    setCountry(newCountry)
                    setPhone(newPhone)
                  }
                }
              }
            />
          )
          : currentStep === 1
            ? (
              <StepPincode
                doPrev={doPrev}
                doNext={doNext}
                country={country}
                phone={phone}
                countdown={countdown}
                setCountdown={setCountdown}
              />
            )
            : currentStep === 2
              ? (
                <StepRule
                  doPrev={doPrev}
                  doNext={doNext}
                />
              )
              : (
                <StepUserInfo
                  doPrev={doPrev}
                  doNext={doNext}
                />
              )
      }
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    height: `calc(100vh - ${constants.app.marginTop.NAVBAR})`,
    width: '100%',
    padding: 0,
    background: 'rgba(0,0,0,0)',
  },
}))
