import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {{
 * strokecolor: string
 * }} props
 * @returns
 */
export default function ArrowBackIcon (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 13 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.1843 20L2.14551 11.5L10.1843 3' stroke={strokecolor} strokeWidth='3' strokeLinecap='square' />
    </SvgIcon>
  )
}
