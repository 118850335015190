import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
// eslint-disable-next-line no-unused-vars
import { Button, ButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useGroupQuery } from '@/hooks/queries/CRM/useGroupQuery'
import { useIsLogin } from '@/hooks/user'
import constants from '@/constants'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

export default function Footer (props) {
  const { t } = useTranslation()
  const history = useHistory()
  const { groupId } = useParams()
  const d2cBaseUrl = useD2CBaseUrl()
  const isLogin = useIsLogin()
  const { isCRMMember, isLoading } = useGroupQuery()

  return (
    <FooterBase>
      <FooterButton
        iconVariant='forward'
        buttonProps={{
          onClick: () => {
            // 前往 CRM 會員卡積分獎勵
            history.push(`${d2cBaseUrl}/membership/${groupId}/rewards`)
          },
        }}
      >
        {t('app.page.crm.footer.go_to_rewards_page')}
      </FooterButton>
      <FooterButton
        iconVariant='forward'
        buttonProps={{
          disabled: !isLogin || !isCRMMember || isLoading,
          onClick: () => {
            // 前往 CRM 會員積分頁面
            history.push(`${d2cBaseUrl}/membership/${groupId}/points`)
          },
        }}
      >
        {t('app.page.crm.footer.go_to_points_page')}
      </FooterButton>
    </FooterBase>
  )
}

/**
 *
 * @param {React.HTMLAttributes<HTMLDivElement>} props
 * @returns
 */
function FooterBase (props) {
  const classes = useStyles(props)
  return (
    <footer className={classes.footer}>
      {props.children}
    </footer>
  )
}

/**
 * @typedef FooterButtonProps
 * @property {React.ReactNode} children
 * @property {ButtonProps} [buttonProps={}]
 * @property {'back' | 'forward' | undefined} [iconVariant]
 * @param {FooterButtonProps} props
 * @returns
 */
function FooterButton (props) {
  const { children, buttonProps = {}, iconVariant } = props
  const classes = useStyles(props)
  return (
    <Button
      className={classes.footerButton}
      startIcon={iconVariant === 'back' ? <ArrowBackIos /> : undefined}
      endIcon={iconVariant === 'forward' ? <ArrowForwardIos /> : undefined}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: constants.app.safeArea.bottom,
    left: 0,
    right: 0,
    height: 40,
    backgroundColor: theme.palette.background.default,
  },
  footerButton: {
    '& span': {
      margin: theme.spacing(0, 0.5),
    },
  },
  icon: {
    height: '1.4rem',
  },
}))
