import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

function SkeletonRestaurantInfo (props) {
  const classes = useStyles(props)

  return (
    <ul className={classes.infoList}>
      {/* Name Row */}
      <li className={classes.li}>
        <Skeleton animation='wave' variant='text' width={120} height={24} />
      </li>

      <li className={classes.li}>
        <Skeleton animation='wave' variant='text' width={60} height={24} />
        <Skeleton className={classes.address} animation='wave' variant='text' width={200} height={24} />
      </li>
    </ul>
  )
}

const useStyles = makeStyles(theme => ({
  infoList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(0, -2, -2, -2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    gap: theme.spacing(2),
  },
  address: {
    flex: 1,
    marginLeft: 32,
  },
}))

export default React.memo(SkeletonRestaurantInfo)
