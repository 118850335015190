import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import useDisableD2CUIPrice from '@/hooks/useDisableD2CUIPrice'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem, IAppSet } from '@/redux/menu/MenuState.d'

/**
 * @typedef PriceLabelProps
 * @property {IAppMenuItem | IAppSet} menu
 * @property {number} [price] 預設 menu.price
 * @property {number} [discount] 預設 menu.discount
 * @property {boolean} [isSeparated] 套餐第一步分拆出來的 setMenuItem 前綴顯示套餐
 * @property {boolean} [suffix] 當在菜單列表時，套餐顯示為 xx元起。當在 drawer 內時不需顯示起，僅需顯示當前套餐總額
 */

/**
 *
 * @param {PriceLabelProps} props
 * @returns
 */
export default function PriceLabel (props) {
  const {
    menu,
    price,
    discount,
    isSeparated,
    suffix,
  } = props

  const { t } = useTranslation()
  const classes = useStyles(props)

  const priceUndetermined = menu.priceUndetermined
  const isSet = Boolean(menu.steps)
  const _price = price || menu.price
  const _discount = discount || menu.discount
  const hasDiscount = _discount > 0
  const total = _.isNaN(_price - _discount) ? '' : _price - _discount
  const disableD2CUIPrice = useDisableD2CUIPrice()

  const Prefix = React.useMemo(() => {
    if (priceUndetermined) {
      return (
        <Typography className={classes.prefix} color='textSecondary'>
          {t('app.page.restaurant.menu_item.market_price')}
        </Typography>
      )
    }

    if (isSet || isSeparated) {
      // 1. menu 為套餐時顯示
      // 2. menu 為第一步分拆出來時顯示
      return (
        <Typography className={classes.prefix} color='textSecondary'>
          {t('app.page.restaurant.menu_item.set')}
        </Typography>
      )
    }
  }, [menu, isSeparated])

  const Price = React.useMemo(() => {
    if (priceUndetermined) return null
    if (disableD2CUIPrice) return null

    return (
      <>
        <Typography
          className={clsx(classes.price, { [classes.crossed]: hasDiscount })}
          color='textSecondary'
        >
          {_price}
        </Typography>
        {hasDiscount && (
          <Typography className={classes.price} color='textSecondary'>
            {total}
          </Typography>)}
      </>
    )
  }, [menu, price, discount])

  const Suffix = React.useMemo(() => {
    if (!suffix) return null
    if (disableD2CUIPrice) return null

    if (isSet || isSeparated) {
      return (
        <Typography className={classes.suffix} color='textSecondary'>
          {t('app.page.restaurant.menu_item.up')}
        </Typography>
      )
    }
  }, [menu])

  return (
    <div className={classes.priceLabel}>
      {Prefix}
      {Price}
      {Suffix}
    </div>
  )
}

const useStyles = makeStyles((theme, props) => ({
  priceLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  prefix: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: props => props.color,
  },
  suffix: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: props => props.color,
  },
  price: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: props => props.color,
    '&:before': {
      content: '"$"',
      marginRight: 2,
    },
  },
  crossed: {
    position: 'relative',
    fontSize: '1.3rem',
    fontStyle: 'italic',
    fontWeight: 'lighter',
    color: theme.palette.grey[500],
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '50%',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}))
