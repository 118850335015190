const paymentGateWay = {
  PAYMENT_GATEWAY_FISERV: 'fiserv', // for credit card
  PAYMENT_GATEWAY_2C2P: '2c2p', // for payme, credit card
  PAYMENT_GATEWAY_OCTOPUS: 'octopus', // for octopus
  PAYMENT_GATEWAY_QFPAY: 'qfpay', // for wechat pay, alipay
  PAYMENT_GATEWAY_FPS: 'fps', // for fps
  PAYMENT_GATEWAY_BYPASS: 'bypass',
}

export default paymentGateWay
