import { camelCase, snakeCase } from 'change-case'
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios' // eslint-disable-line no-unused-vars

import changeKeys from '@/libs/changeKeys'
import config from '@/config'
import logger from '@/libs/logger'

export class CustomerApi {
  /** @type {AxiosInstance} */
  axios
  /** @type {string} */
  refreshToken
  /** @type {(auth: CustomerApiAuthData) => void} */
  refreshTokenCallback
  /** @type {() => void} */
  logoutCallback

  constructor (url) {
    this.axios = axios.create({
      baseURL: url || config.api.customer,
    })

    this.axios.interceptors.request.use(
      config => {
        config.data = changeKeys(config.data, snakeCase)
        config.params = changeKeys(config.params, snakeCase)
        return config
      },
      error => error,
    )
    this.axios.interceptors.response.use(
      response => {
        response.data = changeKeys(response.data, camelCase)
        return response
      },
      /** @param {AxiosError} error */
      async (error) => {
        const res = error.response
        console.log('[Axios][Interceptor] Response Error', res)
        const errorMessage = res?.data?.errors?.[0].title || error.message || error

        // 不是在 refresh 且 errors.title 為 error.token.expired，進行 refresh
        if (error?.config?.url !== '/tokens' && res?.data?.errors?.[0].title === 'error.token.expired') {
          // 如果錯誤是因為 token 過期
          if (this.refreshToken != null) {
            // 取得新的 token
            try {
              const { data: auth } = await this.refresh(this.refreshToken)
              // 呼叫 callback
              this.refreshTokenCallback?.(auth)
            } catch (error) {
              // refresh 失敗，登出
              logger.log(`[customerApi] refresh error: ${errorMessage}`, { errorMessage })
              this.logoutCallback?.(errorMessage)
              return Promise.reject(error)
            }

            // 換新 token 重新 request
            error.config.headers.Authorization = this.axios.defaults.headers.common.Authorization
            return await this.axios.request(error.config)
          }
        }
        if (res?.data?.errors?.[0].title === 'error.token.invalid') {
          logger.log(`[customerApi] error: ${errorMessage}`, { errorMessage })
          // token 錯誤，登出
          this.logoutCallback?.(errorMessage)
        }
        return Promise.reject(error)
      },
    )
  }

  /**
   * @function
   * @template T
   * @param {(page: number) => Promise<PaginationResponseData<T>>} queryFunction
   * @param {{page: number}} args object include page
   * @param {T[]} data
   * @returns {T[]}
   */
  getAllPagesData = async (queryFunction, args = { page: 1 }, data = []) => {
    const response = await queryFunction(args)
    data.push(...response.data)
    if (response.meta.lastPage > response.meta.currentPage) {
      return this.getAllPagesData(queryFunction, { ...args, page: response.meta.currentPage + 1 }, data)
    }
    return data
  }

  /**
   * @param {CustomerApiAuthData} _auth
   */
  setToken = (auth) => {
    this.axios.defaults.headers.common.Authorization = `${auth.tokenType} ${auth.accessToken}`
    this.refreshToken = auth.refreshToken
  }

  /**
   * @param {(auth: CustomerApiAuthData) => void} callback
   */
  setRefreshTokenCallback = (callback) => {
    this.refreshTokenCallback = callback
  }

  /**
   * @param {() => void} callback
   */
  setLogoutCallback (callback) {
    this.logoutCallback = callback
  }

  refresh = async () => {
    /** @type {AxiosResponse<ResponseData<CustomerApiAuthData>>} */
    const response = await this.axios.post('/tokens', {
      refreshToken: this.refreshToken,
    })

    this.setToken(response.data.data)

    return response.data
  }

  // * Auth

  /**
 * @param {string} account dimorder api user id
 * @returns {Promise<ResponseData<CustomerApiAuthData>>}
 */
  loginAnonymous = async (account) => {
    /** @type {AxiosResponse<ResponseData<CustomerApiAuthData>>} */
    const response = await this.axios.post('/auth/anonymous', { account })

    this.setToken(response.data.data)

    return response.data
  }

  /**
   * @param {string} account mobile or email
   * @returns {Promise}
   */
  sendOtpPin = async (account) => {
    /** @type {AxiosResponse} */
    const response = await this.axios.get(`/auth/otp/${account}/pin`)

    return response.data
  }

  /**
   * @param {string} account mobile or email
   * @param {string} pin pin code
   * @returns {Promise}
   */
  loginWithOtpPin = async (account, pin) => {
    /** @type {AxiosResponse<ResponseData<CustomerApiAuthData>>} */
    const response = await this.axios.post('/auth/otp', {
      account,
      pin,
    })

    this.setToken(response.data.data)

    return response.data
  }

  // * FCM

  /**
   * @returns {Promise<ResponseData<Array<CustomerApiFcmToken>>>}
   */
  getFcmTokens = async () => {
    /** @type {AxiosResponse<ResponseData<Array<CustomerApiFcmToken>>>} */
    const response = await this.axios.get('/fcm')

    return response.data
  }

  /**
   * @param {string} deviceId
   * @param {string} token
   * @returns {Promise<ResponseData<CustomerApiFcmToken>>}
   */
  registerFcmToken = async (deviceId, token) => {
    /** @type {AxiosResponse<ResponseData<CustomerApiFcmToken>>} */
    const response = await this.axios.post('/fcm', {
      deviceId,
      token,
    })

    return response.data
  }

  /**
   * @param {string} deviceId
   * @returns {Promise}
   */
  deleteFcmToken = async (deviceId) => {
    /** @type {AxiosResponse} */
    const response = await this.axios.delete(`/fcm/${deviceId}`)

    return response.data
  }

  // * Me

  /**
   * @returns {Promise<ResponseData<CustomerApiCustomerInfo>>}
   */
  getCustomerInfo = async () => {
    /** @type {AxiosResponse<ResponseData<CustomerApiCustomerInfo>>} */
    const response = await this.axios.get('/me', { params: { t: +new Date() } })

    return response.data
  }

  deleteCustomerAccount = async () => {
    await this.axios.patch('/me', { destroy: true })
  }

  /**
   * @param {CustomerApiCustomerInfo} customer
   * @returns {Promise}
   */
  updateCustomerInfo = async (customer) => {
    /** @type {AxiosResponse} */
    const response = await this.axios.patch('/me', customer)

    return response.data
  }

  /**
   * @param {File} file
   * @returns {Promise}
   */
  uploadCustomerAvatar = async (file) => {
    const formData = new FormData()
    formData.append('avatar', file)

    /** @type {AxiosResponse} */
    const response = await this.axios.post('/me:upload-avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  }

  // * Ledgers

  /**
   * @param {{page: number, orderId: string, amount[gte]: number, amount[lte]: number}} params
   * @returns {Promise<PaginationResponseData<CustomerApiLedger>>}
   */
  getLedgers = async (params = { page: 1 }) => {
    /** @type {AxiosResponse<PaginationResponseData<CustomerApiLedger>>} */
    params.t = +new Date()
    const response = await this.axios.get('/ledgers', { params })
    response.data.data = response.data.data.map(ledger => changeKeys(ledger, camelCase))

    return response.data
  }

  /**
   * @returns {Promise<CustomerApiLedger[]>}
   */
  getAllLedgers = async () => {
    const data = await this.getAllPagesData(this.getLedgers)
    return data
  }

  // * Addresses

  /**
   * @param {IAddress} address
   * @returns {CustomerApiAddress}
   */
  convertToApiAddress = (address) => {
    const data = { ...address }
    delete data.id
    delete data.isComplete

    if (address.latlng != null) {
      data.lat = address.latlng.lat
      data.lng = address.latlng.lng
      delete data.latlng
    }
    if (address.deliveryOption != null) {
      data.options = address.deliveryOption
      delete data.deliveryOption
    }
    if (address.remark != null) {
      data.memo = address.remark
      delete data.remark
    }
    if (address.floorRoom != null) {
      data.floor = address.floorRoom
      delete data.floorRoom
    }

    return data
  }

  /**
   *
   * @param {CustomerApiAddress} data
   * @returns {IAddress}
   */
  convertToAppAddress = (data) => {
    const address = { ...data }
    if (data.lat != null && data.lng != null) {
      address.latlng = {
        lat: data.lat,
        lng: data.lng,
      }
      delete address.lat
      delete address.lng
    }
    if (data.options != null) {
      address.deliveryOption = data.options
      delete address.options
    }
    if (data.memo != null) {
      address.remark = data.memo
      delete address.memo
    }
    if (data.floor != null) {
      address.floorRoom = data.floor
      delete address.floor
    }
    return address
  }

  /**
   * @param {{page:number}} params
   * @returns {Promise<PaginationResponseData<IAddress>>}
   */
  getAddresses = async (params = { page: 1 }) => {
    /** @type {AxiosResponse<PaginationResponseData<CustomerApiAddress>>} */
    const response = await this.axios.get('/addresses', { params })

    return {
      ...response.data,
      data: response.data.data.map(this.convertToAppAddress),
    }
  }

  /**
   * @returns {Promise<IAddress[]>}
   */
  getAllAddresses = async () => {
    const data = await this.getAllPagesData(this.getAddresses)
    return data
  }

  /**
   * @param {IAddress} address
   * @returns {Promise<ResponseData<CustomerApiAddress>>}
   */
  createAddress = async (address) => {
    const data = this.convertToApiAddress(address)

    /** @type {AxiosResponse<ResponseData<CustomerApiAddress>>} */
    const response = await this.axios.post('/addresses', data)

    return this.convertToAppAddress(response.data.data)
  }

  /**
   * @param {IAddress} address
   * @returns {Promise<ResponseData<CustomerApiAddress>>}
   */
  updateAddress = async (address) => {
    const data = this.convertToApiAddress(address)

    /** @type {AxiosResponse<ResponseData<CustomerApiAddress>>} */
    await this.axios.patch(`/addresses/${address.id}`, data)

    // return this.convertToAppAddress(response.data.data)
  }

  /**
   * @param {string} addressId
   * @returns {Promise}
   */
  deleteAddress = async (addressId) => {
    /** @type {AxiosResponse} */
    const response = await this.axios.delete(`/addresses/${addressId}`)
    return response.data
  }

  fetchFavorites = async () => {
    const response = await this.axios.get('/favorites')
    return response.data
  }

  addFavorites = async (merchantId, deliveryType) => {
    const body = { merchantId, deliveryType }
    const response = await this.axios.post('/favorites', body)
    return response.data
  }

  deleteFavorites = async (merchantId, deliveryType) => {
    const params = { merchantId, deliveryType }
    const response = await this.axios.delete('/favorites', { params })
    return response.data
  }

  submitRedeemCode = async (code) => {
    const response = await this.axios.post('/redeem', { code })
    return response.data
  }

  // favorites 的餐廳的搜尋
  fetchFavoriteRestaurants = async (params) => {
    const { data } = await this.axios.get('c/favorites/new', { params })
    return data?.data
  }
}

export default new CustomerApi()
