import React from 'react'

import { useJoinedGroupsQueryOptions } from '@/hooks/queries/CRM/useJoinedGroupsQuery'

import { CARD, GroupCard } from './index'
import InfiniteQueryList, { DEFAULT_ROW_PADDING_TOP } from '@/components/RestaurantList/InfiniteQueryList'
import ListEmptyComponent from '../components/ListEmptyComponent'

export default function JoinedGroupList (props) {
  const queryOptions = useJoinedGroupsQueryOptions()
  const rowHeight = CARD.height + DEFAULT_ROW_PADDING_TOP
  return (
    <InfiniteQueryList
      queryOptions={queryOptions}
      rowHeight={rowHeight}
      ItemComponent={GroupCard}
      FooterComponent={() => <div />} // 多顯示一行空白的 row
      EmptyComponent={ListEmptyComponent}
    />
  )
}
