import { ButtonBase, TableCell, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import { useIsLogin } from '@/hooks/user'
import NotificationGif from '@/assets/images/checkout/notification.gif'

/**
 * @typedef LoginNotificationRowProps
 * @property {boolean} [readOnly]
 */

/**
 * 當有僅限用戶使用的優惠碼，且用戶尚未登入時，顯示提示登入按鈕增加用戶登入意願
 * @param {LoginNotificationRowProps} props
 * @returns
 */
export default function LoginNotificationRow (props) {
  const { readOnly = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const prioritizedPromoCodes = useSelector(state => state.order.prioritizedPromoCodes)
  const isLogin = useIsLogin()

  const hasBetterPriceAfterLogin = React.useMemo(() => {
    const p = prioritizedPromoCodes[0]
    return !isLogin && p?.onlyRegisteredUser
  }, [isLogin, prioritizedPromoCodes])

  if (readOnly) return null // 唯讀時不顯示
  if (!hasBetterPriceAfterLogin) return null // 最優惠的優惠碼非限定用戶使用時不顯示

  return (
    <TableRow>
      <TableCell colSpan='2' className={classes.tableCell}>
        <ButtonBase
          className={classes.button}
          onClick={() => dispatch(actions.app.toggleLoginDrawer(true))}
        >
          <img src={NotificationGif} alt='notification' className={classes.icon} />
          <Typography color='secondary' className={classes.buttonText}>
            {t('app.page.checkout.better_price_after_login')}
          </Typography>
        </ButtonBase>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: theme.spacing(0.5, 0.5),
  },
  button: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 8,
    padding: 6,
  },
  icon: {
    maxWidth: 28,
    maxHeight: 28,
  },
  buttonText: {
    fontSize: 20,
    fontWeight: 500,
  },
}))
