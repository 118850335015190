import { ButtonBase, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { useDateRangeContext, useIsEndReached, useScrollActiveIntoView, useTempDatetimeContext, useTempDeliveryTypeContext } from '@/hooks/contexts/DatetimePickerContext'
import { useSelector } from '@/redux'

/* eslint-disable no-unused-vars */
import { UIDate } from 'dimorder-orderapp-lib/dist/types/Timeslot'
/* eslint-enable no-unused-vars */

const DATE_BUTTON = { width: 84, height: 84, borderRadius: 10 }

export default function DateRangeList (props) {
  const { dateRange, isLoading: isDateRangeLoading } = useDateRangeContext()
  const { scrollRef, isStartReached, isEndReached, handleScroll } = useIsEndReached('horizontal', dateRange)
  const lang = useSelector(state => state.app.lang)
  const classes = useStyles({ ...props, isStartReached, isEndReached, lang })
  const { tempDeliveryType } = useTempDeliveryTypeContext()

  return (
    <section className={classes.dateRangeListWrap}>
      <ul
        ref={scrollRef}
        className={classes.dateRangList}
        onScroll={e => handleScroll(e)}
      >
        <SkeletonDateRangeList loading={isDateRangeLoading} />
        {_.map(dateRange, date => {
          return (
            <DateButton key={date.key + tempDeliveryType} date={date} />
          )
        })}
      </ul>
    </section>
  )
}

/**
 * @typedef DateButtonProps
 * @property {UIDate} date
 * @param {DateButtonProps} props
 * @returns
 */
function DateButton (props) {
  const { date } = props
  const { tempDatetime, setTempDatetime } = useTempDatetimeContext()
  const classes = useStyles(props)
  const isActive = date.key === tempDatetime.date
  const activeRef = useScrollActiveIntoView()

  return (
    <ButtonBase
      ref={isActive ? activeRef : undefined}
      component='li'
      className={clsx(classes.dateBlock, isActive && 'active')}
      onClick={() => {
        setTempDatetime({ ...tempDatetime, date: date.key })
      }}
    >
      <Typography>{date.week}</Typography>
      <Typography>{date.date}</Typography>
    </ButtonBase>
  )
}

/**
 * @typedef SkeletonDateRangeListProps
 * @property {boolean} loading
 * @param {SkeletonDateRangeListProps} props
 * @returns
 */
function SkeletonDateRangeList (props) {
  const { loading } = props
  if (!loading) return null
  return _.map(_.times(7), (n, i) => (
    <Skeleton
      key={i}
      variant='rect'
      width={DATE_BUTTON.width}
      height={DATE_BUTTON.height}
      style={{
        borderRadius: DATE_BUTTON.borderRadius,
        flexShrink: 0,
      }}
    />
  ))
}

const useStyles = makeStyles(theme => ({
  dateRangeListWrap: {
    flexShrink: 0,
    position: 'relative',
    margin: theme.spacing(1, 0),
    overflow: 'hidden',
    minHeight: DATE_BUTTON.height,
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 100,
      top: 0,
      left: -16,
      height: '100%',
      width: 16,
      boxShadow: `8px 0 16px 8px ${theme.palette.common.white}`,
      opacity: props => props.isStartReached ? 0 : 0.9,
      transition: 'opacity 500ms ease',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 100,
      top: 0,
      right: -16,
      height: '100%',
      width: 16,
      boxShadow: `-8px 0 16px 8px ${theme.palette.common.white}`,
      opacity: props => props.isEndReached ? 0 : 0.9,
      transition: 'opacity 500ms ease',
    },
  },
  dateRangList: {
    display: 'flex',
    gap: theme.spacing(1),
    overflow: 'auto',
  },
  dateBlock: {
    minWidth: DATE_BUTTON.width,
    minHeight: DATE_BUTTON.height,
    backgroundColor: theme.palette.grey[50],
    border: '1px solid',
    borderColor: theme.palette.grey[500],
    borderRadius: DATE_BUTTON.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&.active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
    '& > p': {
      fontSize: props => props.lang === 'en-US' ? '1.6rem' : '1.8rem',
      fontWeight: 'bold',
    },
  },
}))
