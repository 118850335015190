import { Button, Checkbox, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LoginPage from './LoginPage'
import Next from './Next'
import React from 'react'
import config from '@/config'

export default function StepRule (props) {
  const { t } = useTranslation()
  const { doPrev, doNext } = props

  const classes = useStyles(props)

  const [checked, setChecked] = React.useState({ privateRule: false, marketRule: false })

  const isCheckedAll = checked.privateRule && checked.marketRule

  return (
    <LoginPage>
      <div id='nav-bar'>
        <Button onClick={doPrev}>
          <Typography>{t('app.component.drawer.login_drawer.back')}</Typography>
        </Button>
      </div>
      <div id='title-group'>
        <Typography id='title'>{t('app.component.drawer.login_drawer.terms_conditions.title')}</Typography>
        <Typography id='description'>
          {t('app.component.drawer.login_drawer.terms_conditions.subtitle')}
        </Typography>
      </div>
      <List className={classes.form}>
        <ListItem
          disableGutters
          dense
          onClick={() => {
            setChecked(v => ({ ...v, privateRule: !isCheckedAll }))
            setChecked(v => ({ ...v, marketRule: !isCheckedAll }))
          }}
        >
          <Checkbox
            edge='start'
            checked={isCheckedAll}
            disableRipple
          />
          <ListItemText primary={t('app.component.drawer.login_drawer.terms_conditions.accept.title')} />
        </ListItem>
        <ListItem
          disableGutters
          dense
          className={classes.nested}
        >
          <Checkbox
            edge='start'
            checked={checked.privateRule}
            disableRipple
            onClick={() => setChecked(v => ({ ...v, privateRule: !v.privateRule }))}
          />
          <ListItemText
            primary={
              <div>
                {t('app.component.drawer.login_drawer.terms_conditions.accept.subtitle')}
                <a href={config.links.tnc} target='_blank' rel='noreferrer'>
                  {t('app.component.drawer.login_drawer.terms_conditions.accept.tc')}
                </a>
                {t('app.component.drawer.login_drawer.terms_conditions.accept.and')}
                <a href={config.links.privacy} target='_blank' rel='noreferrer'>
                  {t('app.component.drawer.login_drawer.terms_conditions.accept.privacy_policy')}
                </a>
              </div>
            }
          />
        </ListItem>
        <ListItem
          disableGutters
          dense
          className={classes.nested}
        >
          <Checkbox
            edge='start'
            checked={checked.marketRule}
            disableRipple
            onClick={() => setChecked(v => ({ ...v, marketRule: !v.marketRule }))}
          />
          <ListItemText
            primary={
              <div>
                {t('app.component.drawer.login_drawer.terms_conditions.accept.personal_data')}
                <a href={config.links.privacy} target='_blank' rel='noreferrer'>
                  {t('app.component.drawer.login_drawer.terms_conditions.accept.privacy_policy')}
                </a>
                {t('app.component.drawer.login_drawer.terms_conditions.accept.personal_data_end')}
              </div>
            }
          />
        </ListItem>
      </List>
      <Next
        onClick={() => doNext({ allowDirectMarketing: checked.marketRule })}
        disabled={!checked.privateRule}
      />
    </LoginPage>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 30,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}))
