import { Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'
import clsx from 'clsx'

const typeColors = {
  takeaway: '#25d69d',
  storeDelivery: '#ed631f',
  table: '#1048d2',
}

/**
 *
 * @param {{
 * className?: string
 * deliveryType?: TDeliveryType
 * }} props
 * @returns
 */
export default function DeliveryTypeLabel (props) {
  const { t } = useTranslation()
  const { className, deliveryType } = props

  if (!deliveryType) return null

  const title = t(`app.constant.deliveryType.${deliveryType}`)
  const typeColor = typeColors[deliveryType]
  const classes = useStyles({ ...props, typeColor })

  return (
    <div className={clsx(classes.container, className)}>
      <Typography variant='caption' className={classes.text} align='center'>
        {title}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 1),
    border: props => `1px solid ${props.typeColor}`,
    borderRadius: 4,
    backgroundColor: props => alpha(props.typeColor, 0.1),
    flexShrink: 0,
  },
  text: {
    color: props => props.typeColor,
  },
}))
