import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { createBatchSetItem } from '@/libs/order'
import { useBatchItemTemp } from '@/hooks/batchItemTemp'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import StepperBase from '@/components/Stepper/StepperBase'

// eslint-disable-next-line no-unused-vars
import { IAppMenuItem } from '@/redux/menu/MenuState.d'

/**
 * @typedef SetItemStepperProps
 * @property {IAppMenuItem} item
 * @property {boolean} [disableIncrement = false]
 * @property {boolean} [disableDecrement = false]
 * @param {SetItemStepperProps} props
 * @returns
 */
export default function SetItemStepper (props) {
  const { item: setItemMenu, disableIncrement = false, disableDecrement = false } = props
  const dispatch = useDispatch()

  const batchItemTemp = useBatchItemTemp()
  const menuQuery = useMenuQuery()
  const sets = menuQuery.data?.sets
  const setItems = batchItemTemp.setItems ? [...batchItemTemp.setItems] : []
  const quantity = _.reduce(batchItemTemp.setItems, (acc, setItem) => {
    return setItem.setMenuId === setItemMenu.id ? acc + setItem.quantity : acc
  }, 0)

  const handleIncrement = (e) => {
    e.stopPropagation()
    const hasOptions = !_.isEmpty(setItemMenu.options)
    // 如果 setItem 的 menu 有選項的話，打開 SetItemOptionDialog 讓 user 選擇選項
    if (hasOptions) {
      dispatch(actions.app.toggleDialog('setItemOption', true, { setItemMenu }))
    } else {
      // 如果 setItem 的 menu 沒有選項的話，新增 setItem 到 batchItemTemp.setItems
      const batchSetItemTemp = createBatchSetItem(setItemMenu)
      setItems.push(batchSetItemTemp)
      dispatch(actions.batchItemTemp.updateSetItems(setItems))
    }
  }

  const handleDecrement = (e) => {
    e.stopPropagation()
    const hasOptions = _
      .chain(setItems)
      .filter(setItem => setItem.setMenuId === setItemMenu.id)
      .some(setItem => !_.isEmpty(setItem?.options))
      .value()
    // 如果 batchItemTemp 內的 setItems 任一個有選項的話，打開刪除視窗來勾選要刪除的商品
    if (hasOptions) {
      dispatch(actions.app.toggleDialog('setItemDelete', true, { setItemMenu }))
    } else {
      // 如果 setItem 沒有選項的話，刪除最後一個 setMenuId 與 setMenu.id 相同的 setItem
      const lastFindIndex = _.findLastIndex(setItems, setItem => setItem.setMenuId === setItemMenu.id)
      _.remove(setItems, (setItem, index) => index === lastFindIndex)
      dispatch(actions.batchItemTemp.updateSetItems(setItems))
      dispatch(actions.batchItemTemp.cleanUpDependentSetItems(sets))
    }
  }

  return (
    <StepperBase
      quantity={quantity}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
      disableIncrement={disableIncrement}
      disableDecrement={disableDecrement}
    />
  )
}
