import { ButtonBase, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

// eslint-disable-next-line no-unused-vars
import { ILandingCuisine } from '@/redux/landing/Landing.d'

export const CUISINE_CARD = { width: 100, height: 75, borderRadius: 12, gutter: 8 }

/**
 * 菜式卡
 * 1. 顯示菜式圖片
 * 2. 顯示菜式名稱
 * 3. 點擊菜式卡轉頁至菜式頁面
 * @typedef CuisineCardProps
 * @property {ILandingCuisine} cuisine
 * @property {boolean} [loading = false]
 * @property {CSSStyleDeclaration} [style]
 * @param {CuisineCardProps} props
 * @returns
 */
export default function CuisineCard (props) {
  const { cuisine, loading = false, style = {} } = props
  const history = useHistory()
  const classes = useStyles(props)

  const handleClick = () => history.push('/cuisine', { cuisine })

  if (loading) return <CuisineCardSkeleton style={style} />

  return (
    <ButtonBase className={classes.cuisineCardButton} onClick={handleClick} style={{ ...style, backgroundImage: `url(${props.cuisine.imageUrl})` }}>
      <Typography variant='body2' className={classes.cuisineCardTitle}>{cuisine.name}</Typography>
    </ButtonBase>
  )
}

export function CuisineCardSkeleton (props) {
  return (
    <div {...props}>
      <Skeleton
        width={CUISINE_CARD.width}
        height={CUISINE_CARD.height}
        variant='rect'
        style={{ borderRadius: CUISINE_CARD.borderRadius }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  cuisineCardButton: {
    position: 'relative',
    overflow: 'hidden',
    minWidth: CUISINE_CARD.width,
    minHeight: CUISINE_CARD.height,
    borderRadius: CUISINE_CARD.borderRadius,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.common.white,
  },
  cuisineCardTitle: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    minHeight: 24,
    minWidth: 56,
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
}))
