import { Typography } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import LetterSpacingButton from '@/components/Buttons/LetterSpacingButton'
import SwipeableDrawer from '@/components/SwipeableDrawer'
import constants from '@/constants'

import DrawerContent from './common/DrawerContent'

/**
 *
 * @param {*} props
 * @returns
 */
export default function ScheduleDrawer (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const open = useSelector(state => state.app.drawers.schedule.open)
  const openDrawer = () => dispatch(actions.app.toggleDrawer('schedule', true))
  const closeDrawer = () => { dispatch(actions.app.toggleDrawer('schedule', false)) }

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={closeDrawer}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
      borderRadius={16}
    >
      <DrawerContent className={classes.content}>

        <Typography variant='h5' align='center' className={classes.title}>
          {t('app.component.drawer.scheduleDrawer.title')}
        </Typography>
        <Typography variant='body1' align='center' className={classes.subtitle}>
          {t('app.component.drawer.scheduleDrawer.subtitle')}
        </Typography>
        <LetterSpacingButton
          variant='outlined'
          bold
          onClick={() => {
            closeDrawer()
            dispatch(actions.app.toggleDrawer('scheduledDatetime', true))
          }}
          text={t('app.component.drawer.scheduleDrawer.edit_datetime')}
          className={classes.button}
        />
        <LetterSpacingButton
          variant='outlined'
          bold
          onClick={() => {
            closeDrawer()
            dispatch(actions.user.updateExitAddressEditBackLevel(2))
            history.push('/settings/address')
          }}
          text={t('app.component.drawer.scheduleDrawer.edit_address')}
          className={classes.button}
        />
      </DrawerContent>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 64px)',
    padding: theme.spacing(3),
    paddingBottom: `calc(${theme.spacing(3)}px + ${constants.app.safeArea.bottom})`,
  },
  content: {
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
    '& :last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(1.5, 0),
  },
  subtitle: {
    fontWeight: 'bold',
    color: theme.palette.grey[500],
  },
  button: {
    height: 50,
  },
}))
