import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'
import orderappLibs from 'dimorder-orderapp-lib/dist/libs'

import { useBatchItemTemp } from '@/hooks/batchItemTemp'
import { useSelectedOrder } from '@/hooks/order'
import useDisableD2CUIPrice from '@/hooks/useDisableD2CUIPrice'

/**
 * @typedef AddToCardButtonProps
 * @property {() => void} onClick
 * @property {boolean} [disabled]
 * @param {AddToCardButtonProps} props
 * @returns
 */
export default function AddToCartButton (props) {
  const { onClick, disabled } = props
  const selectedOrder = useSelectedOrder()
  const batchItemTemp = useBatchItemTemp()
  const disableD2CUIPrice = useDisableD2CUIPrice()

  const total = useMemo(() => {
    const calculatedItem = !_.isEmpty(batchItemTemp) && Boolean(selectedOrder)
      ? orderappLibs.calculateLocalBatchItem(batchItemTemp, selectedOrder)
      : {}
    return calculatedItem.total
  }, [batchItemTemp, selectedOrder])

  const PriceComponent = useCallback(() => {
    if (disableD2CUIPrice) return null
    return <PriceComponentBase title='HK$' total={total} />
  }, [disableD2CUIPrice, total])

  return (
    <AddToCartButtonBase
      disabled={disabled}
      onClick={onClick}
      PriceComponent={PriceComponent}
    />
  )
}

/**
 *
 * @param {AddToCardButtonProps & { PriceComponent: React.FC }} props
 * @returns
 */
export function AddToCartButtonBase (props) {
  const { PriceComponent, ...rest } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  return (
    <ButtonBase className={classes.submitItemButton} {...rest}>
      <PriceComponent />
      <div className={classes.titleWrap}>
        <Typography className={classes.title}>
          {t('app.page.restaurant.menu_item.add_to_cart')}
        </Typography>
      </div>
    </ButtonBase>
  )
}

/**
 * @typedef PriceComponentBaseProps
 * @property {string} title
 * @property {number} total
 * @param {PriceComponentBaseProps} props
 * @returns
 */
export function PriceComponentBase (props) {
  const { title, total } = props
  const classes = useStyles(props)
  return (
    <div className={classes.priceWrap}>
      <Typography variant='caption' className={classes.priceSymbol}>{title}</Typography>
      <Typography variant='h5' className={classes.price}>{total}</Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  submitItemButton: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: props => props.disabled
      ? theme.palette.disabled.main
      : theme.palette.primary.main,
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: 50,
  },
  priceWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
    minHeight: 50,
    gap: theme.spacing(0.5),
  },
  priceSymbol: {
    lineHeight: 1,
    paddingTop: theme.spacing(1),
  },
  price: {
    fontWeight: 'bold',
    lineHeight: 1,
    paddingBottom: theme.spacing(0.5),
    minWidth: theme.spacing(7),
  },
  titleWrap: {
    flex: 1,
    padding: theme.spacing(0, 1.5),
  },
  title: {
    fontSize: '1.8rem',
    color: props => props.disabled
      ? theme.palette.disabled.contrastText
      : theme.palette.text.primary,
  },
}))
