import React from 'react'

import { useRestaurantCardListSize } from '@/hooks/useRestaurantCardListSize'
import { useSearchRestaurantsQueryOptions } from '@/hooks/queries/useSearchRestaurantsQuery'

import FilterList from './FilterList'
import InfiniteQueryList from '@/components/RestaurantList/InfiniteQueryList'
import NoSearchResult from './NoSearchResult'
import RestaurantCard from '@/components/Cards/RestaurantCard'
import RestaurantCardsSkeleton from '@/components/RestaurantList/RestaurantCardsSkeleton'

export default function SearchRestaurantList (props) {
  const { rowHeight, columnWidth } = useRestaurantCardListSize()
  const queryOptions = useSearchRestaurantsQueryOptions()

  return (
    <>
      <FilterList />
      <InfiniteQueryList
        queryOptions={queryOptions}
        rowHeight={rowHeight}
        columnWidth={columnWidth}
        ItemComponent={RestaurantCard}
        FooterComponent={() => <div />} // 多顯示一行空白的 row
        LoadingComponent={RestaurantCardsSkeleton}
        LoadingIndicator={RestaurantCardsSkeleton}
        EmptyComponent={NoSearchResult}
      />
    </>
  )
}
