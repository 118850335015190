
import { useTranslation } from 'react-i18next'
import React from 'react'

import { AddToCartButtonBase, PriceComponentBase } from '@/components/Drawers/common/AddToCartButton'

/**
 * @typedef CRMRewardItemSubmitButtonProps
 * @property {number} total
 * @property {() => void} onClick
 * @property {boolean} [disabled]
 * @param {CRMRewardItemSubmitButtonProps} props
 * @returns
 */
export default function CRMRewardAddToCartButton (props) {
  const { total, onClick, disabled } = props
  const { t } = useTranslation()

  const PriceComponent = () => (
    <PriceComponentBase title={t('app.page.crm.crm_points')} total={total} />
  )

  return (
    <AddToCartButtonBase
      disabled={disabled}
      onClick={onClick}
      PriceComponent={PriceComponent}
    />
  )
}
